import { createContext } from "react";

export interface LayoutState {
  openSidebar: boolean;
  setOpenSidebar: (open: boolean) => void;
  openSignOut: boolean;
  setOpenSignOut: (open: boolean) => void;
  profile?: string;
  setProfile: (base64: string | undefined) => void;
}

export const LayoutContext = createContext<LayoutState>({
  openSidebar: false,
  setOpenSidebar: () => {},
  openSignOut: false,
  setOpenSignOut: () => {},
  profile: undefined,
  setProfile: () => {},
});
