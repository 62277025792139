import axios from "axios";
import secureLocalStorage from "../utils/secureLocalStorage";
import { OrganizationsRef, User } from "../models/User";
import alertService from "../components/alert/alertService";
import { t } from "i18next";

let isRefreshing = false;

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const storedOrganizationJson = secureLocalStorage.getItem("organization");

    const storedUser = secureLocalStorage.getItem("user");

    if (storedOrganizationJson) {
      const organization: OrganizationsRef = JSON.parse(storedOrganizationJson);
      config.headers["X-CARBONCAL-ORGID"] = `${organization.id}`;
    }

    if (storedUser) {
      const user: User = JSON.parse(storedUser);
      config.headers["Authorization"] = `Bearer ${user.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    await checkUserNotFound(error);
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise(async (resolve) => {
          resolve(apiClient(originalRequest));
        });
      }

      isRefreshing = true;

      originalRequest._retry = true;

      await refreshToken();

      return apiClient(originalRequest);
    }

    return Promise.reject(error);
  }
);

const checkUserNotFound = async (error: any) => {
  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;

    if (errorMessage === "User-Not-Found_DURING_AUTHENTIFICATION") {
      await alertService.error(`${t("User-Not-Found")}`);
      secureLocalStorage.clear();
      window.location.href = "/login";
    }
  }
};

const refreshToken = async () => {
  isRefreshing = false;

  const storedUser = secureLocalStorage.getItem("user");

  if (storedUser) {
    const user: User = JSON.parse(storedUser);

    const newAxios = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.refreshToken}`,
      },
    });

    try {
      const response = await newAxios.post(`/v1/users/${user.refId}/refresh`);

      if (response.status === 200) {
        secureLocalStorage.setItem(
          "user",
          JSON.stringify(response.data.results.user)
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        secureLocalStorage.clear();
        window.location.href = "/login";
      }
    }
  }
};

export default apiClient;
