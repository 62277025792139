import { Box } from "@mui/material";
import { ReportDataTable } from "../ReportDataTable";
import Layout from "../../../Layout";
import { ReportProvider } from "../../../contexts/ReportContext";
import { ReportMenu } from "../ReportMenu";

export const TgoReport = () => {
  return (
    <>
      <ReportProvider>
        <Layout>
          <Box
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            bgcolor={"var(--white)"}
            overflow={"auto"}
          >
            <ReportMenu />
            <Box
              p={"40px 40px"}
              display={"flex"}
              flexDirection="column"
              flex={1}
            >
              <Box flex={1} display={"flex"} width={"100%"}>
                <ReportDataTable reportDataTableType="tgo-cfo" />
              </Box>
            </Box>
          </Box>
        </Layout>
      </ReportProvider>
    </>
  );
};
