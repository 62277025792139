import { Box } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import Sidebar from "../components/sidebar";
import Topbar, { Breadcrumb } from "../components/topbar";
import { useMatches } from "react-router-dom";
import { routes } from "../routeInfo";
import { LayoutContext } from "../contexts/LayoutContext";
import { useAuth } from "../contexts/UserContext";
import { AccountProvider } from "../contexts/AccountContext";
import secureLocalStorage from "../utils/secureLocalStorage";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSignOut, setOpenSignOut] = useState(false);

  const [profile, setProfile] = useState<string | undefined>();

  let headlineImg: string | undefined = undefined;
  const matches = useMatches();
  const breadcrumbs: Breadcrumb[] = [];
  matches.forEach((match) => {
    const { pathname } = match;
    const route = routes.find((item) => item.path === pathname);
    if (route) {
      const breadcrumb = route.title;
      const img = route.image;
      if (img) {
        headlineImg = img;
      }
      breadcrumbs.push({
        text: breadcrumb,
        link: pathname,
      });
    }
  });

  const { user, logout } = useAuth();

  useEffect(() => {
    setProfile(user?.userProfile);
  }, [user]);

  const context = {
    openSidebar,
    setOpenSidebar,
    openSignOut,
    setOpenSignOut,
    profile,
    setProfile,
  };

  const beforeunload = () => {
    if (secureLocalStorage.getItem("rememberMe") === "false") {
      return logout();
    } else {
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("rememberMe") === null) {
      beforeunload();
    }
  }, []);

  return (
    <LayoutContext.Provider value={context}>
      <AccountProvider>
        <Box
          display={"flex"}
          flexDirection={"column"}
          color={"var(--white)"}
          height="100vh"
          sx={{
            backgroundColor: "#F2F2F2",
            overflowY: "hidden",
          }}
        >
          <Sidebar />

          <Box
            flexGrow={1}
            display={"flex"}
            flexDirection={"column"}
            marginLeft={{ xs: 0, md: "90px" }}
            sx={{ overflowY: "hidden" }}
          >
            <Topbar img={headlineImg} breadcrumbs={breadcrumbs} />
            <Box flexGrow={1} sx={{ overflowY: "auto" }}>
              {children}
            </Box>
          </Box>
        </Box>
      </AccountProvider>
    </LayoutContext.Provider>
  );
};

export default Layout;
