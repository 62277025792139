import { Box, Switch } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid";
import { ActivityTemplatesContext } from "../../../contexts/ActivityTemplatesContext";
import { Trans, useTranslation } from "react-i18next";
import NumberFormatterUtils from "../../../utils/numberFormatterUtils";
import { tableStyle } from "../../emission/data-table/EmissionDataTableStyle";
import alertService from "../../../components/alert/alertService";
import { TemplateActivities } from "../../../models/TemplateActivities";
import TemplateActivitiesService from "../../../service/templateActivitiesService";
import { useEffect } from "react";
import { EditActivityTemplates } from "./EditActivityTemplates";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import { useAuth } from "../../../contexts/UserContext";
import { CustomPagination } from "../../../components/datagrid/CustomPagination";
import { DROPDOWN_EMISSION_GROUP } from "../../../constants/dropdown";
import i18n from "../../../i18n";

export const ActivityTemplatesTable = () => {
  const apiRef = useGridApiRef();

  const {
    templateActivitiesList,
    transformersToDataGrid,
    getTemplateActivities,
  } = ActivityTemplatesContext();

  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { isAdmin } = useAuth();

  const columns: GridColDef<(typeof templateActivitiesList)[number]>[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      minWidth: 80,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value, row) => `AT-${value}`,
    },
    {
      field: "isMonthly",
      headerName: t("Monthly"),
      disableColumnMenu: true,
      width: 120,
      type: "boolean",
      headerAlign: "left",
      align: "center",
      renderCell: (params) => {
        return (
          <Switch
            id={"switch" + params.row._id}
            key={"switch" + params.row._id}
            checked={params.value}
            onChange={() => updateIsMonthly(params.row)}
            disabled={!isAdmin()}
          />
        );
      },
    },
    {
      field: "year",
      headerName: t("Year"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "suborganizationName",
      headerName: t("Sub-Organizations"),
      headerAlign: "left",
      minWidth: 138,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "siteName",
      headerName: t("Site"),
      headerAlign: "left",
      minWidth: 138,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "assetName",
      headerName: t("Asset"),
      minWidth: 112,
      headerAlign: "left",
      flex: 1,
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "emissionGroup",
      headerName: t("Emission-Group"),
      minWidth: 136,
      flex: 1,
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value) => {
        const item = DROPDOWN_EMISSION_GROUP.find(
          (e) => e.emissionGroup === value
        );

        return item ? t(item.groupEn) : t(value);
      },
    },
    {
      field: "activityAttributeEN",
      headerName: t("Emission-Source"),
      minWidth: 156,
      flex: 1,
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value, row) =>
        i18n.language === "en"
          ? row.activityAttributeEN
          : row.activityAttributeTh,
    },
    {
      field: "amount",
      headerName: t("Amount"),
      headerAlign: "left",
      sortable: false,
      disableColumnMenu: true,
      type: "number",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value: number) => {
        try {
          return NumberFormatterUtils.numberFormat(value);
        } catch (error) {
          return "0.00";
        }
      },
    },
    {
      field: "unit",
      headerName: t("Unit"),
      sortable: false,
      headerAlign: "left",
      disableColumnMenu: true,
    },
    {
      field: "",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      width: 70,
      getApplyQuickFilterFn: undefined,
      getActions: (params) => {
        return [
          <EditActivityTemplates row={params.row} />,
          <GridActionsCellItem
            key={"delete-" + params.id}
            id={"delete-" + params.id}
            icon={
              <img
                src="/img/delete.svg"
                alt="icon-delete"
                height={"18px"}
                width={"18px"}
              />
            }
            label="delete"
            onClick={() => deleteTemplateActivities(params.row)}
            disabled={!isAdmin()}
          />,
        ];
      },
    },
  ];

  const updateIsMonthly = async (row: TemplateActivities) => {
    const confirm = await alertService.confirm(
      t("Edit"),
      <Trans
        t={t}
        i18nKey="confirm-update-monthly-status"
        values={{ status: row.isMonthly ? t("off") : t("on"), id: row.id }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />
    );

    if (confirm) {
      const result =
        await TemplateActivitiesService.updateIsMonthlyTemplateActivity(
          row._id,
          !row.isMonthly
        );

      if (result) {
        alertService.mixin(t("Successfully"));
        transformersToDataGrid(result);
      }
    }
  };

  const deleteTemplateActivities = async (row: TemplateActivities) => {
    const confirm = await alertService.confirm(
      t("Delete"),
      <Trans
        t={t}
        i18nKey="delete-templates-model-text"
        values={{ id: row.id }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />
    );

    if (confirm) {
      const result = await TemplateActivitiesService.deleteTemplateActivity(
        row._id
      );

      if (result) {
        transformersToDataGrid(result);
        alertService.mixin(t("Successfully"));
      }
    }
  };

  useEffect(() => {
    getTemplateActivities();
  }, []);

  return (
    <>
      <Box
        width={"100%"}
        maxHeight={{ xs: "auto", md: window.innerHeight - 53 }}
      >
        <DataGrid
          rows={templateActivitiesList}
          columns={columns}
          rowHeight={60}
          disableRowSelectionOnClick
          apiRef={apiRef}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          slots={{
            toolbar: QuickSearchToolbar,
            pagination: CustomPagination,
          }}
          localeText={{
            toolbarExport: t("Export"),
            toolbarQuickFilterPlaceholder: `${t("search")}...`,
            noRowsLabel: t("noRowsLabel"),
            noResultsOverlayLabel: t("noResultsOverlayLabel"),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                variant: "outlined",
              },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            filter: {
              filterModel: {
                items: [],
              },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          sx={tableStyle()}
        />
      </Box>
    </>
  );
};
