import {
  Avatar,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { stringAvatar } from "../../utils/avatar";
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string;
  paddingLeft?: string;
  opacity?: number;
  link: string;
  text: string;
  textVariant?: Variant;
  leftImgType?: "img" | "Avatar";
  leftImg?: string;
  leftImgAlt?: string;
  leftImgSize?: number;
  leftAvatarSize?: number;
  onLeftImgClick?: () => void;
  rightImg?: string;
  rightImgClassName?: string;
  rightImgAlt?: string;
  rightImgSize?: string;
  onRightImgClick?: () => void;
  showHoverBox?: boolean;
}

const SidebarItem = ({
  leftImgType = "img",
  leftImgSize = 24,
  leftAvatarSize = 44,
  ...props
}: Props) => {
  const navigate = useNavigate();

  let leftImgMargin = (leftAvatarSize - leftImgSize) / 2;
  let leftIcon;
  if (props.leftImg && leftImgType === "img") {
    leftIcon = (
      <img
        src={props.leftImg}
        alt={props.leftImgAlt}
        style={{ width: `${leftImgSize}px` }}
      />
    );
  } else if (leftImgType === "Avatar") {
    leftIcon = (
      <Avatar
        src={
          props.leftImg ? `data:image/png;base64,${props.leftImg}` : undefined
        }
        {...stringAvatar(props.text ?? "")}
        alt="User Profile"
        sx={{ bgcolor: props.leftImg ? "var(--gray3)" : null }}
      />
    );
  }

  return (
    <ListItemButton
      onClick={
        props.link === ""
          ? props.onLeftImgClick
          : () => {
              navigate(props.link);
            }
      }
      component={Box as any}
      className={props.className}
      flexShrink={0}
      alignItems={"center"}
      display={"flex"}
      overflow={"clip"}
      sx={{
        p: 0,
        mx: leftImgType === "Avatar" ? 0 : `${leftImgMargin}px`,
        textDecoration: "none",
        flexGrow: 0,
        opacity: props.opacity ?? 1,
        transition: ".3s",
        pl: props.paddingLeft,
      }}
    >
      {props.showHoverBox && (
        <Box className="sidebar-hover-box" height={"16px"} width={"4px"} />
      )}
      {leftIcon !== undefined && (
        <ListItemIcon
          sx={{ minWidth: `24px`, pointerEvents: "none" }}
          onClick={props.onLeftImgClick}
        >
          {leftIcon}
        </ListItemIcon>
      )}
      <ListItemText
        style={{ textDecoration: "none", padding: "0px 10px", width: "100%" }}
      >
        <Typography
          noWrap
          color={"#FFF"}
          variant={props.textVariant ?? "h3"}
          textAlign={"start"}
        >
          {props.text}
        </Typography>
      </ListItemText>
      {props.rightImg && (
        <img
          className={props.rightImgClassName}
          width={props.rightImgSize}
          src={props.rightImg}
          alt={props.rightImgAlt}
          onClick={props.onRightImgClick}
        />
      )}
    </ListItemButton>
  );
};

export default SidebarItem;
