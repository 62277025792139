import { createContext, ReactNode, useContext, useState } from "react";
import { TemplateActivities } from "../models/TemplateActivities";
import TemplateActivitiesService from "../service/templateActivitiesService";

type ActivityTemplatesState = {
  templateActivitiesList: TemplateActivities[];
  transformersToDataGrid: (data: TemplateActivities[]) => void;
  getTemplateActivities: () => Promise<void>;
};

export const Context = createContext<ActivityTemplatesState>(
  {} as ActivityTemplatesState
);

export const ActivityTemplatesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [templateActivitiesList, setTemplateActivitiesList] = useState<
    TemplateActivities[]
  >([]);

  const transformersToDataGrid = (data: TemplateActivities[]) => {
    setTemplateActivitiesList(data);
  };

  const getTemplateActivities = async () => {
    const result: TemplateActivities[] | null =
      await TemplateActivitiesService.getTemplateActivities();
    if (result !== null) {
      transformersToDataGrid(result);
    }
  };

  return (
    <Context.Provider
      value={{
        templateActivitiesList,
        transformersToDataGrid,
        getTemplateActivities,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const ActivityTemplatesContext = () => useContext(Context);
