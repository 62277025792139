import { useEffect, useState } from "react";
import {
  AddEmissionContext,
  EmissionMode,
  FormValuesStepTwo,
} from "../../../contexts/AddEmissionContext";
import { Activity, GetActivities } from "../../../models/Activities";
import { DROPDOWN_EMISSION_GROUP } from "../../../constants/dropdown";
import { Box, Button, Typography } from "@mui/material";
import CustomModal from "../../../components/modal/CustomModal";
import { FormProvider } from "react-hook-form";
import { t } from "i18next";
import AddEmissionStepTwo from "../popup-add-emission/step2/AddEmissionStepTwo";
import alertService from "../../../components/alert/alertService";
import { Dayjs } from "dayjs";
import ActivitiesService from "../../../service/activitiesService";

interface Props {
  data: Activity;
  open: boolean;
  onClose: () => void;
}

export const EditEmission = (props: Props) => {
  const {
    stepOneForm,
    stepTwoForm,
    subOrganizationList,
    prepareFormDataToCreateOrDraftActivities,
    setUpActivitiesListData,
    setEmissionMode,
  } = AddEmissionContext();

  const startDate: Dayjs = props.data.startDate as Dayjs;

  const endDate: Dayjs = props.data.endDate as Dayjs;

  const [loaded, setLoaded] = useState<boolean>(false);

  const onSubmit = async (data: FormValuesStepTwo) => {
    const createActivitiesResult: GetActivities | null =
      await ActivitiesService.editActivities(
        props.data._id,
        prepareFormDataToCreateOrDraftActivities()
      );
    if (createActivitiesResult !== null) {
      setUpActivitiesListData(createActivitiesResult);
      close();
      alertService.mixin(t("edit-emission-amount-successfully"));
    }
  };

  const close = () => {
    stepTwoForm.reset();
    stepOneForm.reset();
    setLoaded(false);
    props.onClose();
  };

  const setUpForm = () => {
    // form setup

    stepOneForm.setValue("assetName", props.data.assetRef);

    stepTwoForm.setValue("startDate", startDate);

    // if (startDate.isSame(endDate, "month")) {
    //   stepTwoForm.setValue("endDate", null);
    // } else {
    //   stepTwoForm.setValue("endDate", endDate);
    // }

    stepTwoForm.setValue("endDate", endDate);

    stepTwoForm.setValue("activityGroup", props.data.activityGroupEN ?? "");

    stepTwoForm.setValue("activityType", props.data.activityTypeEn);

    stepTwoForm.setValue("activityAttribute", props.data.activityAttributeEN);

    stepTwoForm.setValue(
      "activityEmissionFactorPublisher",
      props.data.publisherName ?? ""
    );

    stepTwoForm.setValue("amount", props.data.amount.toString() ?? "0");

    stepTwoForm.setValue("remark", props.data.remark ?? "");

    stepTwoForm.setValue("unit", props.data.unit ?? "");

    stepTwoForm.setValue("vendor", props.data.vendor ?? "");

    try {
      stepTwoForm.setValue("attachment", props.data.attachment ?? "");
    } catch (error) {
      stepTwoForm.setValue("attachment", "");
    }

    if (props.data.auxiliary) {
      stepTwoForm.setValue("aux1", props.data.auxiliary.aux1 ?? "");

      stepTwoForm.setValue("aux2", props.data.auxiliary.aux2 ?? "");

      stepTwoForm.setValue("aux3", props.data.auxiliary.aux3 ?? "");

      stepTwoForm.setValue("aux4", props.data.auxiliary.aux4 ?? "");

      stepTwoForm.setValue("aux5", props.data.auxiliary.aux5 ?? "");
    }

    stepTwoForm.setValue("monthly", props.data.isMonthly ?? false);

    setLoaded(true);

    setEmissionMode(EmissionMode.AddEmission);
  };

  useEffect(() => {
    const item = subOrganizationList.find(
      (i) => i.name === props.data.suborganizationName
    );

    stepOneForm.setValue("subOrganizations", item?.id as string);

    stepOneForm.setValue(
      "location",
      `${item?.location?.address ?? ""} ${item?.location?.district ?? ""} ${
        item?.location?.city ?? ""
      }`.trim()
    );

    const emissionGroupRef: string | undefined = DROPDOWN_EMISSION_GROUP.find(
      (i) => i.emissionGroup === props.data.emissionGroup
    )?.emissionGroup;

    stepOneForm.setValue("emissionGroup", emissionGroupRef as string);

    setUpForm();
    setLoaded(true);
  }, [props.open]);

  return (
    <>
      <CustomModal
        header={<Typography variant="h1">{t("Edit-Emission")}</Typography>}
        body={
          <Box>
            {loaded ? (
              <FormProvider {...stepTwoForm}>
                <form onSubmit={stepTwoForm.handleSubmit(onSubmit)}>
                  <Box>
                    <AddEmissionStepTwo />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: "44px",
                    }}
                    rowGap={2.5}
                  >
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      type="submit"
                      name="add"
                    >
                      {t("Save")}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            ) : (
              <Box></Box>
            )}
          </Box>
        }
        open={props.open}
        onClose={close}
      />
    </>
  );
};
