import { Typography } from "@mui/material";
import InfoPopper from "../popper/InfoPopper";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  info?: string;
  required?: boolean;
}

const LabelInput = (props: Props) => {
  const { t } = useTranslation(["common", "overview"], {
    nsMode: "fallback",
  });

  return (
    <Typography variant="h3" color={"var(--dark)"}>
      {props.label}

      {props.required === true ? (
        <span style={{ color: "var(--red)" }}> * </span>
      ) : (
        <span> ({t("Optional")}) </span>
      )}

      {props.info && <InfoPopper text={props.info} />}
    </Typography>
  );
};

export default LabelInput;
