import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AddActivityTemplatesModal } from "./AddActivityTemplatesModal";
import { CustomGridToolbarQuickFilter } from "../../../components/datagrid/CustomGridToolbarQuickFilter";

export const QuickSearchToolbar = () => {
  return (
    <Box
      marginBottom={"40px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={4}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <CustomGridToolbarQuickFilter />
      <GridToolbarContainer
        sx={{
          padding: 0,
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <AddActivityTemplatesModal />
      </GridToolbarContainer>
    </Box>
  );
};
