import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  body: string;
  buttonText: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const LogoutModal = (props: Props) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        display={"flex"}
        flexDirection={"column"}
        gap={"24px"}
        p={"32px"}
        borderRadius={"8px"}
        sx={{ transform: "translate(-50%, -50%)", background: "white" }}
      >
        <Typography id="modal-modal-title" variant="h1">
          {props.title}
        </Typography>
        <Typography id="modal-modal-description" variant="h3">
          {props.body}
        </Typography>
        <Button
          fullWidth
          color="secondary"
          sx={{ color: "var(--white)" }}
          variant="contained"
          onClick={() => {
            logout();
            navigate("/login");
          }}
        >
          {props.buttonText}
        </Button>
        <Box
          onClick={props.onClose}
          sx={{ position: "absolute", right: "32px", cursor: "pointer" }}
        >
          <CloseIcon />
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
