import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "../models/User";
import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import { useTranslation } from "react-i18next";
import { useAuth } from "./UserContext";

export interface AccountState {
  user?: User;
  updateUser: (
    name: string | null,
    displayName: string | null,
    phoneNumber: string | null,
    jobTitle: string | null,
    userProfile: string | null
  ) => Promise<boolean>;
}

export const AccountContext = createContext<AccountState | undefined>(
  undefined
);

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};

export function AccountProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User>();

  const { logout, getUserInfo } = useAuth();

  const { t } = useTranslation(["common", "account"], {
    nsMode: "fallback",
  });

  const getUser = async () => {
    alertService.showLoading();
    try {
      const response = await apiClient.get("/v1/users/info");
      alertService.closeLoading();
      if (response.status === 200) {
        setUser(response.data.results.user);
      }
    } catch (error: any) {
      if (error.response.status === 500) {
        logout();
      }
      alertService.error(t("error-get-user"), t("Error-Message"));
    }
  };

  const updateUser = async (
    name: string | null,
    displayName: string | null,
    phoneNumber: string | null,
    jobTitle: string | null,
    userProfile: string | null
  ) => {
    try {
      let body: any = {
        displayName: displayName ?? user?.displayName,
        fullname: name ?? "",
        phoneNumber: phoneNumber ?? "",
        jobTitle: jobTitle ?? "",
      };

      if (userProfile) {
        body.userProfile = userProfile;
      }

      const response = await apiClient.patch(`/v1/users/info`, body);
      if (response.status === 200) {
        // await getUser();

        // getUserInfo();

        setUser(response.data.results.user);

        alertService.mixin(t("edit-successfully"));

        return true;
      }
    } catch (error: any) {
      handleLoginError(error);
    }
    return false;
  };

  const handleLoginError = (error: any, year?: number) => {
    if (error.response && error.response.data && error.response.data.code) {
      const errorMessage = error.response.data.code;

      const body: string = error.response.data.message.body ?? "";

      switch (errorMessage) {
        case "FORBIDDEN_ACCESS_ERROR":
          alertService.error(
            t("Cannot-Change-Organization-Due-To-Permission-Error")
          );
          break;
        case "VALIDATION_ERROR":
          alertService.error(
            body.toLowerCase().includes("phonenumber")
              ? `${t("Phone")}`
              : body.toLowerCase().includes("jobtitle")
              ? `${t("Job-Title")}`
              : body.toLowerCase().includes("fullname")
              ? `${t("Name")}`
              : "",

            t("validation-failed")
          );
          break;
        default:
          alertService.error(t("error-edit-user"), t("Error-Message"));
          break;
      }
    } else {
      alertService.error(t("cannot-connect-to-server"));
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const context = {
    user,
    updateUser,
  };

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
}
