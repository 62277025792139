import {
  Avatar,
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TFunction } from "i18next";
import OverviewCard from "../../../components/dashboard/OverviewCard";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DashbroadContext } from "../../../contexts/DashbroadContext";
import InfoPopper from "../../../components/popper/InfoPopper";
import { EmissionData } from "../../../models/Emission";
import {
  ChartsItemContentProps,
  PieChart,
  useDrawingArea,
} from "@mui/x-charts";
import PieTooltip from "../../../components/chart/PieTooltip";
import PieDetail from "../../../components/chart/PieDetail";
// import Group from "../../../models/Group";
import EmissionCard, {
  byChart,
} from "../../../components/dashboard/EmissionCard";
import NumberFormatterUtils from "../../../utils/numberFormatterUtils";
import { REPORT_GROUP } from "../../../constants/reportGroup";
import EmissionByCard from "../../../components/dashboard/EmissionByCard";
import EmissionByBusinessCard from "../../../components/dashboard/EmissionByBusinessCard";

function PieCenterLabel({
  t,
  value,
}: {
  t: TFunction<[string, string], undefined>;
  value: number;
}) {
  const { width, height, left, top } = useDrawingArea();
  const x = left + width / 2;
  return (
    <text
      textAnchor={"middle"}
      dominantBaseline={"central"}
      x={x}
      y={top + height / 2}
      fontFamily="Inter"
    >
      <tspan x={x} dy={"-24px"} fontSize={"12px"}>
        {t("Total")}
      </tspan>
      <tspan x={x} dy={"22px"} fontSize={"16px"}>
        {NumberFormatterUtils.numberFormat(value)}
      </tspan>
      <tspan x={x} dy={"22px"} fontSize={"12px"}>
        tC02e
      </tspan>
    </text>
  );
}

const ScopeThree = () => {
  const context = useContext(DashbroadContext);
  const { t } = useTranslation(["common", "overview", "input"], {
    nsMode: "fallback",
  });

  const scope = "Scope-3";

  const group = REPORT_GROUP.filter((item) => item.scope === scope);

  const scope1 = context.filteredEmissions.filter(
    (item) => item.scope === scope
  );
  const emissionSum = context.filteredEmissions
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);
  const scopeSum = scope1.map((item) => item.value).reduce((a, b) => a + b, 0);

  const sumGroupByType: Record<string, number> = {};
  group.forEach((type) => {
    sumGroupByType[type.id] = 0;
  });
  scope1.forEach((item) => {
    const type = item.type;
    if (type !== undefined) {
      const old = sumGroupByType[type] ?? 0;
      sumGroupByType[type] = old + item.value;
    }
  });

  const sortedList = Object.entries(sumGroupByType);

  const series = sortedList.flatMap(([key, value], index) => {
    const item = group.find((item) => item.id === key);
    if (item === undefined) return [];

    return {
      id: index,
      value: value,
      label: t(item.title),
      color: item.colors[1],
    };
  });

  const tooltip = (props: ChartsItemContentProps) => {
    return PieTooltip(props, t);
  };

  const details = series.map((item, index) => {
    return (
      <PieDetail
        key={`pieDetail${index}`}
        title={item.label ? `${index + 1}. ${t(item.label)}` : ""}
        percent={scopeSum !== 0 ? (item.value / scopeSum) * 100 : 0}
        value={item.value}
        color={item.color ?? "Outside-of-Scope"}
      />
    );
  });

  const getGroup = (emission: EmissionData) => {
    return emission.type;
  };

  const byAssetRecord: Record<string, number> = {};
  const bySourceRecord: Record<string, number> = {};
  const bySiteRecord: Record<string, number> = {};

  scope1.forEach((item) => {
    const asset = item.assetName ?? "";
    const oldAsset = byAssetRecord[asset] ?? 0;
    byAssetRecord[asset] = oldAsset + item.value;

    const source = item.attributeEn ?? "";
    const oldSource = byAssetRecord[source] ?? 0;
    bySourceRecord[source] = oldSource + item.value;

    const site = `${item.siteName} : ${item.suborganizationName}` ?? "";
    const oldSite = bySiteRecord[site] ?? 0;
    bySiteRecord[site] = oldSite + item.value;
  });

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
      {/* Overview Card */}
      <Grid container spacing={"20px"}>
        <Grid xs={12} sm="auto">
          <OverviewCard
            title={t("Scope-3")}
            info={t("Scope3.Summary-Description")}
            text1={`${
              emissionSum === 0
                ? 0
                : NumberFormatterUtils.numberFormat(
                    (scopeSum / emissionSum) * 100
                  )
            } %`}
            text2={`${NumberFormatterUtils.numberFormat(
              scopeSum
            )} / ${NumberFormatterUtils.numberFormat(emissionSum)} tCO2e`}
            icon="/img/scope3.svg"
          />
        </Grid>
        <Grid xs={12} sm>
          <Card variant="outlined" sx={{ height: "100%", borderRadius: "8px" }}>
            <CardContent sx={{ margin: "8px", px: { sm: "40px" } }}>
              <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
                <Typography variant="h2">{t("Select-Category")}</Typography>
                <Box>
                  <FormControl
                    sx={{ width: "100%", maxWidth: "260px" }}
                    size="small"
                    disabled
                  >
                    <InputLabel>{t("Category")}</InputLabel>
                    <Select label={t("Category")}>
                      <MenuItem>{t("All")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Scope 2 Categories */}
      <Card variant="outlined" sx={{ borderRadius: "8px" }}>
        <CardContent sx={{ p: "40px" }}>
          <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} gap={"4px"} alignItems={"center"}>
              <Avatar
                src={"/img/scope3.svg"}
                sx={{ width: "34px", height: "34px" }}
              />
              <Typography variant="h3" flexGrow={1}>
                {t("Scope3-Categories")}
                <InfoPopper text={t("Scope3-Categories-Description")} />
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={{
                xs: "column",
                md: "row",
              }}
              gap={{
                xs: "16px",
                md: "40px",
              }}
            >
              <Box flexShrink={0} display={"flex"}>
                <PieChart
                  series={[
                    {
                      data: series,
                      innerRadius: "50%",
                      outerRadius: "100%",
                    },
                  ]}
                  margin={{ top: 25, bottom: 25, left: 25, right: 25 }}
                  width={270}
                  height={270}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                  tooltip={{
                    trigger: "item",
                    itemContent: tooltip,
                  }}
                  slots={{
                    itemContent: tooltip,
                  }}
                >
                  <PieCenterLabel t={t} value={scopeSum} />
                </PieChart>
              </Box>
              <Box
                flexGrow={1}
                display={"flex"}
                flexDirection={{
                  xs: "column",
                  lg: "row",
                }}
                gap={{
                  xs: "12px",
                  lg: "40px",
                }}
              >
                <Box
                  flexGrow={1}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"12px"}
                >
                  <Typography variant="h3" textAlign={"right"}>
                    {t("Total-Emissions")}
                  </Typography>
                  {details.slice(0, Math.round(details.length / 2))}
                </Box>
                <Box
                  flexGrow={1}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"12px"}
                >
                  <Typography
                    display={{
                      xs: "none",
                      lg: "block",
                    }}
                    variant="h3"
                    textAlign={"right"}
                  >
                    {t("Total-Emissions")}
                  </Typography>
                  {details.slice(Math.round(details.length / 2))}
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <EmissionCard
        t={t}
        title={t("Emission")}
        defaultBy={byChart}
        groups={group}
        getGroup={getGroup}
        emissions={context.emissions}
        startDate={context.startDate}
        endDate={context.endDate}
      />

      <EmissionByBusinessCard
        groups={group}
        getGroup={getGroup}
        scope="Scope-3"
      />

      {/* Emission By Card */}
      <Grid container spacing={"20px"}>
        <Grid xs={12} md={6}>
          <EmissionByCard
            title={t("Emissions-by-Assets")}
            description={t("Emissions-by-Assets-Desc")}
            data={byAssetRecord}
            top={5}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <EmissionByCard
            title={t("Emissions-by-Sources")}
            description={t("Emissions-by-Sources-Desc")}
            data={bySourceRecord}
            top={5}
          />
        </Grid>
        {/* <Grid xs={12} md={4}>
          <EmissionByCard
            title={t("Emissions-by-Site")}
            description={t("Emissions-by-Site-Desc")}
            data={bySiteRecord}
            top={5}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default ScopeThree;
