import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import LoginLayout from "../../Layout/LoginLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import alertService from "../../components/alert/alertService";
import UsersService from "../../service/usersService";
import { useAuth } from "../../contexts/UserContext";

import Link from "@mui/material/Link";

type ActivateFormValues = {
  email: string;
  password: string;
  repassword: string;
};

const ActivatePage: React.FC = () => {
  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();

  const { logout } = useAuth();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<ActivateFormValues>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = async (values: ActivateFormValues) => {
    const formData = {
      email: values.email ?? "",
      password: values.password ?? "",
    };

    const result = await UsersService.activate(token ?? "", formData);

    if (result) {
      await alertService.success(t("activate-your-account"));

      logout();
      navigate("/login");
    }
  };

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  useEffect(() => {}, []);

  return (
    <LoginLayout>
      <Box
        maxWidth={"400px"}
        display={"flex"}
        flexDirection={"column"}
        rowGap={"20px"}
        width={"100%"}
      >
        <img
          src="/img/login/CarbonCalLogoLong.svg"
          alt="logo"
          style={{
            padding: "12px 0px",
            height: "28px",
            width: "233px",
            marginBottom: "20px",
          }}
        />

        <Typography variant="h1">{t("activate-your-account")}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%",
            }}
            padding={"24px 0px"}
          >
            <InputReactHookForm
              required
              rules={{
                required: t("Must-be-a-valid-email"),
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              }}
              name={"email"}
              control={control}
              label={t("Email")}
              info={t("Email")}
              placeholder={t("Email")}
              defaultValue={""}
            />

            <InputReactHookForm
              required
              rules={{
                required: t("Password-must-be-at-least-6-characters"),
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{6,20}$/,
                  message: t("password-fails-to-match-pattern"),
                },
              }}
              control={control}
              name={"password"}
              label={t("Password")}
              info={t("Password")}
              placeholder={t("Password")}
              type="password"
            />

            <InputReactHookForm
              required
              rules={{
                required: t("Password-must-be-at-least-6-characters"),
                validate: () => {
                  if (watch("password") !== watch("repassword")) {
                    return t("password-and-repassword-not-equal");
                  }
                  return true;
                },
              }}
              control={control}
              name={"repassword"}
              label={t("Confirm-Your-New-Password")}
              info={t("Confirm-Your-New-Password")}
              placeholder={t("Confirm-Your-New-Password")}
              type="password"
            />
          </Box>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color={"secondary"}
            disabled={!isValid}
            sx={{
              marginBottom: "20px",
            }}
          >
            {t("activated")}
          </Button>

          <Link variant="body1" href="/login" underline="hover" color="primary">
            {t("Go-To-Login-Page")}
          </Link>
        </form>
      </Box>
    </LoginLayout>
  );
};

export default ActivatePage;
