import { ThailandGeography } from "../models/ThailandGeography";

export const THAILAND_GEOGRAPHY: ThailandGeography = {
  states: [
    {
      stateNameTh: "กรุงเทพมหานคร",
      stateNameEn: "Bangkok",
      districts: [
        {
          districtNameTh: "พระนคร",
          districtNameEn: "Phra Nakhon",
          subdistricts: [
            {
              subdistrictNameTh: "พระบรมมหาราชวัง",
              subdistrictNameEn: "Phra Borom Maha Ratchawang",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "วังบูรพาภิรมย์",
              subdistrictNameEn: "Wang Burapha Phirom",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "วัดราชบพิธ",
              subdistrictNameEn: "Wat Ratchabophit",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "สำราญราษฎร์",
              subdistrictNameEn: "Samran Rat",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "ศาลเจ้าพ่อเสือ",
              subdistrictNameEn: "San Chaopho Suea",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "เสาชิงช้า",
              subdistrictNameEn: "Sao Chingcha",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "บวรนิเวศ",
              subdistrictNameEn: "Bowon Niwet",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "ตลาดยอด",
              subdistrictNameEn: "Talat Yot",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "ชนะสงคราม",
              subdistrictNameEn: "Chana Songkhram",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "บ้านพานถม",
              subdistrictNameEn: "Ban Phan Thom",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "บางขุนพรหม",
              subdistrictNameEn: "Bang Khun Phrom",
              postalCode: 10200,
            },
            {
              subdistrictNameTh: "วัดสามพระยา",
              subdistrictNameEn: "Wat Sam Phraya",
              postalCode: 10200,
            },
          ],
        },
        {
          districtNameTh: "ดุสิต",
          districtNameEn: "Dusit",
          subdistricts: [
            {
              subdistrictNameTh: "ดุสิต",
              subdistrictNameEn: "Dusit",
              postalCode: 10300,
            },
            {
              subdistrictNameTh: "วชิรพยาบาล",
              subdistrictNameEn: "Wachira Phayaban",
              postalCode: 10300,
            },
            {
              subdistrictNameTh: "สวนจิตรลดา",
              subdistrictNameEn: "Suan Chit Lada",
              postalCode: 10300,
            },
            {
              subdistrictNameTh: "สี่แยกมหานาค",
              subdistrictNameEn: "Si Yaek Maha Nak",
              postalCode: 10300,
            },
            {
              subdistrictNameTh: "ถนนนครไชยศรี",
              subdistrictNameEn: "Thanon Nakhon Chai Si",
              postalCode: 10300,
            },
          ],
        },
        {
          districtNameTh: "หนองจอก",
          districtNameEn: "Nong Chok",
          subdistricts: [
            {
              subdistrictNameTh: "กระทุ่มราย",
              subdistrictNameEn: "Krathum Rai",
              postalCode: 10530,
            },
            {
              subdistrictNameTh: "หนองจอก",
              subdistrictNameEn: "Nong Chok",
              postalCode: 10530,
            },
            {
              subdistrictNameTh: "คลองสิบ",
              subdistrictNameEn: "Khlong Sip",
              postalCode: 10530,
            },
            {
              subdistrictNameTh: "คลองสิบสอง",
              subdistrictNameEn: "Khlong Sip Song",
              postalCode: 10530,
            },
            {
              subdistrictNameTh: "โคกแฝด",
              subdistrictNameEn: "Khok Faet",
              postalCode: 10530,
            },
            {
              subdistrictNameTh: "คู้ฝั่งเหนือ",
              subdistrictNameEn: "Khu Fang Nuea",
              postalCode: 10530,
            },
            {
              subdistrictNameTh: "ลำผักชี",
              subdistrictNameEn: "Lam Phak Chi",
              postalCode: 10530,
            },
            {
              subdistrictNameTh: "ลำต้อยติ่ง",
              subdistrictNameEn: "Lam Toiting",
              postalCode: 10530,
            },
          ],
        },
        {
          districtNameTh: "บางรัก",
          districtNameEn: "Bang Rak",
          subdistricts: [
            {
              subdistrictNameTh: "มหาพฤฒาราม",
              subdistrictNameEn: "Maha Phruettharam",
              postalCode: 10500,
            },
            {
              subdistrictNameTh: "สีลม",
              subdistrictNameEn: "Si Lom",
              postalCode: 10500,
            },
            {
              subdistrictNameTh: "สุริยวงศ์",
              subdistrictNameEn: "Suriyawong",
              postalCode: 10500,
            },
            {
              subdistrictNameTh: "บางรัก",
              subdistrictNameEn: "Bang Rak",
              postalCode: 10500,
            },
            {
              subdistrictNameTh: "สี่พระยา",
              subdistrictNameEn: "Si Phraya",
              postalCode: 10500,
            },
          ],
        },
        {
          districtNameTh: "บางเขน",
          districtNameEn: "Bang Khen",
          subdistricts: [
            {
              subdistrictNameTh: "อนุสาวรีย์",
              subdistrictNameEn: "Anusawari",
              postalCode: 10220,
            },
            {
              subdistrictNameTh: "ท่าแร้ง",
              subdistrictNameEn: "Tha Raeng",
              postalCode: 10220,
            },
          ],
        },
        {
          districtNameTh: "บางกะปิ",
          districtNameEn: "Bang Kapi",
          subdistricts: [
            {
              subdistrictNameTh: "คลองจั่น",
              subdistrictNameEn: "Khlong Chan",
              postalCode: 10240,
            },
            {
              subdistrictNameTh: "หัวหมาก",
              subdistrictNameEn: "Hua Mak",
              postalCode: 10240,
            },
          ],
        },
        {
          districtNameTh: "ปทุมวัน",
          districtNameEn: "Pathum Wan",
          subdistricts: [
            {
              subdistrictNameTh: "รองเมือง",
              subdistrictNameEn: "Rong Mueang",
              postalCode: 10330,
            },
            {
              subdistrictNameTh: "วังใหม่",
              subdistrictNameEn: "Wang Mai",
              postalCode: 10330,
            },
            {
              subdistrictNameTh: "ปทุมวัน",
              subdistrictNameEn: "Pathum Wan",
              postalCode: 10330,
            },
            {
              subdistrictNameTh: "ลุมพินี",
              subdistrictNameEn: "Lumphini",
              postalCode: 10330,
            },
          ],
        },
        {
          districtNameTh: "ป้อมปราบศัตรูพ่าย",
          districtNameEn: "Pom Prap Sattruphai",
          subdistricts: [
            {
              subdistrictNameTh: "ป้อมปราบ",
              subdistrictNameEn: "Pom Prap",
              postalCode: 10100,
            },
            {
              subdistrictNameTh: "วัดเทพศิรินทร์",
              subdistrictNameEn: "Wat Thep Sirin",
              postalCode: 10100,
            },
            {
              subdistrictNameTh: "คลองมหานาค",
              subdistrictNameEn: "Khlong Maha Nak",
              postalCode: 10100,
            },
            {
              subdistrictNameTh: "บ้านบาตร",
              subdistrictNameEn: "Ban Bat",
              postalCode: 10100,
            },
            {
              subdistrictNameTh: "วัดโสมนัส",
              subdistrictNameEn: "Wat Sommanat",
              postalCode: 10100,
            },
          ],
        },
        {
          districtNameTh: "พระโขนง",
          districtNameEn: "Phra Khanong",
          subdistricts: [
            {
              subdistrictNameTh: "บางจาก",
              subdistrictNameEn: "Bang Chak",
              postalCode: 10260,
            },
            {
              subdistrictNameTh: "พระโขนงใต้",
              subdistrictNameEn: "Phra Khanong Tai",
              postalCode: 10260,
            },
          ],
        },
        {
          districtNameTh: "มีนบุรี",
          districtNameEn: "Min Buri",
          subdistricts: [
            {
              subdistrictNameTh: "มีนบุรี",
              subdistrictNameEn: "Min Buri",
              postalCode: 10510,
            },
            {
              subdistrictNameTh: "แสนแสบ",
              subdistrictNameEn: "Saen Saep",
              postalCode: 10510,
            },
          ],
        },
        {
          districtNameTh: "ลาดกระบัง",
          districtNameEn: "Lat Krabang",
          subdistricts: [
            {
              subdistrictNameTh: "ลาดกระบัง",
              subdistrictNameEn: "Lat Krabang",
              postalCode: 10520,
            },
            {
              subdistrictNameTh: "คลองสองต้นนุ่น",
              subdistrictNameEn: "Khlong Song Ton Nun",
              postalCode: 10520,
            },
            {
              subdistrictNameTh: "คลองสามประเวศ",
              subdistrictNameEn: "Khlong Sam Prawet",
              postalCode: 10520,
            },
            {
              subdistrictNameTh: "ลำปลาทิว",
              subdistrictNameEn: "Lam Pla Thio",
              postalCode: 10520,
            },
            {
              subdistrictNameTh: "ทับยาว",
              subdistrictNameEn: "Thap Yao",
              postalCode: 10520,
            },
            {
              subdistrictNameTh: "ขุมทอง",
              subdistrictNameEn: "Khum Thong",
              postalCode: 10520,
            },
          ],
        },
        {
          districtNameTh: "ยานนาวา",
          districtNameEn: "Yannawa",
          subdistricts: [
            {
              subdistrictNameTh: "ช่องนนทรี",
              subdistrictNameEn: "Chong Nonsi",
              postalCode: 10120,
            },
            {
              subdistrictNameTh: "บางโพงพาง",
              subdistrictNameEn: "Bang Phongphang",
              postalCode: 10120,
            },
          ],
        },
        {
          districtNameTh: "สัมพันธวงศ์",
          districtNameEn: "Samphanthawong",
          subdistricts: [
            {
              subdistrictNameTh: "จักรวรรดิ",
              subdistrictNameEn: "Chakkrawat",
              postalCode: 10100,
            },
            {
              subdistrictNameTh: "สัมพันธวงศ์",
              subdistrictNameEn: "Samphanthawong",
              postalCode: 10100,
            },
            {
              subdistrictNameTh: "ตลาดน้อย",
              subdistrictNameEn: "Talat Noi",
              postalCode: 10100,
            },
          ],
        },
        {
          districtNameTh: "พญาไท",
          districtNameEn: "Phaya Thai",
          subdistricts: [
            {
              subdistrictNameTh: "สามเสนใน",
              subdistrictNameEn: "Sam Sen Nai",
              postalCode: 10400,
            },
            {
              subdistrictNameTh: "พญาไท",
              subdistrictNameEn: "Phaya Thai",
              postalCode: 10400,
            },
          ],
        },
        {
          districtNameTh: "ธนบุรี",
          districtNameEn: "Thon Buri",
          subdistricts: [
            {
              subdistrictNameTh: "วัดกัลยาณ์",
              subdistrictNameEn: "Wat Kanlaya",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "หิรัญรูจี",
              subdistrictNameEn: "Hiran Ruchi",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "บางยี่เรือ",
              subdistrictNameEn: "Bang Yi Ruea",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "บุคคโล",
              subdistrictNameEn: "Bukkhalo",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "ตลาดพลู",
              subdistrictNameEn: "Talat Phlu",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "ดาวคะนอง",
              subdistrictNameEn: "Dao Khanong",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "สำเหร่",
              subdistrictNameEn: "Samre",
              postalCode: 10600,
            },
          ],
        },
        {
          districtNameTh: "บางกอกใหญ่",
          districtNameEn: "Bangkok Yai",
          subdistricts: [
            {
              subdistrictNameTh: "วัดอรุณ",
              subdistrictNameEn: "Wat Arun",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "วัดท่าพระ",
              subdistrictNameEn: "Wat Tha Phra",
              postalCode: 10600,
            },
          ],
        },
        {
          districtNameTh: "ห้วยขวาง",
          districtNameEn: "Huai Khwang",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยขวาง",
              subdistrictNameEn: "Huai Khwang",
              postalCode: 10310,
            },
            {
              subdistrictNameTh: "บางกะปิ",
              subdistrictNameEn: "Bang Kapi",
              postalCode: 10310,
            },
            {
              subdistrictNameTh: "สามเสนนอก",
              subdistrictNameEn: "Sam Sen Nok",
              postalCode: 10310,
            },
          ],
        },
        {
          districtNameTh: "คลองสาน",
          districtNameEn: "Khlong San",
          subdistricts: [
            {
              subdistrictNameTh: "สมเด็จเจ้าพระยา",
              subdistrictNameEn: "Somdet Chao Phraya",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "คลองสาน",
              subdistrictNameEn: "Khlong San",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "บางลำภูล่าง",
              subdistrictNameEn: "Bang Lamphu Lang",
              postalCode: 10600,
            },
            {
              subdistrictNameTh: "คลองต้นไทร",
              subdistrictNameEn: "Khlong Ton Sai",
              postalCode: 10600,
            },
          ],
        },
        {
          districtNameTh: "ตลิ่งชัน",
          districtNameEn: "Taling Chan",
          subdistricts: [
            {
              subdistrictNameTh: "คลองชักพระ",
              subdistrictNameEn: "Khlong Chak Phra",
              postalCode: 10170,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 10170,
            },
            {
              subdistrictNameTh: "ฉิมพลี",
              subdistrictNameEn: "Chimphli",
              postalCode: 10170,
            },
            {
              subdistrictNameTh: "บางพรม",
              subdistrictNameEn: "Bang Phrom",
              postalCode: 10170,
            },
            {
              subdistrictNameTh: "บางระมาด",
              subdistrictNameEn: "Bang Ramat",
              postalCode: 10170,
            },
            {
              subdistrictNameTh: "บางเชือกหนัง",
              subdistrictNameEn: "Bang Chueak Nang",
              postalCode: 10170,
            },
          ],
        },
        {
          districtNameTh: "บางกอกน้อย",
          districtNameEn: "Bangkok Noi",
          subdistricts: [
            {
              subdistrictNameTh: "ศิริราช",
              subdistrictNameEn: "Sirirat",
              postalCode: 10700,
            },
            {
              subdistrictNameTh: "บ้านช่างหล่อ",
              subdistrictNameEn: "Ban Chang Lo",
              postalCode: 10700,
            },
            {
              subdistrictNameTh: "บางขุนนนท์",
              subdistrictNameEn: "Bang Khun Non",
              postalCode: 10700,
            },
            {
              subdistrictNameTh: "บางขุนศรี",
              subdistrictNameEn: "Bang Khun Si",
              postalCode: 10700,
            },
            {
              subdistrictNameTh: "อรุณอมรินทร์",
              subdistrictNameEn: "Arun Ammarin",
              postalCode: 10700,
            },
          ],
        },
        {
          districtNameTh: "บางขุนเทียน",
          districtNameEn: "Bang Khun Thian",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 10150,
            },
            {
              subdistrictNameTh: "แสมดำ",
              subdistrictNameEn: "Samae Dam",
              postalCode: 10150,
            },
          ],
        },
        {
          districtNameTh: "ภาษีเจริญ",
          districtNameEn: "Phasi Charoen",
          subdistricts: [
            {
              subdistrictNameTh: "บางหว้า",
              subdistrictNameEn: "Bang Wa",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "บางด้วน",
              subdistrictNameEn: "Bang Duan",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "บางจาก",
              subdistrictNameEn: "Bang Chak",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "บางแวก",
              subdistrictNameEn: "Bang Waek",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "คลองขวาง",
              subdistrictNameEn: "Khlong Khwang",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "ปากคลองภาษีเจริญ",
              subdistrictNameEn: "Pak Khlong Phasi Charoen",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "คูหาสวรรค์",
              subdistrictNameEn: "Khuha Sawan",
              postalCode: 10160,
            },
          ],
        },
        {
          districtNameTh: "หนองแขม",
          districtNameEn: "Nong Khaem",
          subdistricts: [
            {
              subdistrictNameTh: "หนองแขม",
              subdistrictNameEn: "Nong Khaem",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "หนองค้างพลู",
              subdistrictNameEn: "Nong Khang Phlu",
              postalCode: 10160,
            },
          ],
        },
        {
          districtNameTh: "ราษฎร์บูรณะ",
          districtNameEn: "Rat Burana",
          subdistricts: [
            {
              subdistrictNameTh: "ราษฎร์บูรณะ",
              subdistrictNameEn: "Rat Burana",
              postalCode: 10140,
            },
            {
              subdistrictNameTh: "บางปะกอก",
              subdistrictNameEn: "Bang Pa Kok",
              postalCode: 10140,
            },
          ],
        },
        {
          districtNameTh: "บางพลัด",
          districtNameEn: "Bang Phlat",
          subdistricts: [
            {
              subdistrictNameTh: "บางพลัด",
              subdistrictNameEn: "Bang Phlat",
              postalCode: 10700,
            },
            {
              subdistrictNameTh: "บางอ้อ",
              subdistrictNameEn: "Bang O",
              postalCode: 10700,
            },
            {
              subdistrictNameTh: "บางบำหรุ",
              subdistrictNameEn: "Bang Bamru",
              postalCode: 10700,
            },
            {
              subdistrictNameTh: "บางยี่ขัน",
              subdistrictNameEn: "Bang Yi Khan",
              postalCode: 10700,
            },
          ],
        },
        {
          districtNameTh: "ดินแดง",
          districtNameEn: "Din Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "ดินแดง",
              subdistrictNameEn: "Din Daeng",
              postalCode: 10400,
            },
            {
              subdistrictNameTh: "รัชดาภิเษก",
              subdistrictNameEn: "Ratchadaphisek",
              postalCode: 10400,
            },
          ],
        },
        {
          districtNameTh: "บึงกุ่ม",
          districtNameEn: "Bung Kum",
          subdistricts: [
            {
              subdistrictNameTh: "คลองกุ่ม",
              subdistrictNameEn: "Khlong Kum",
              postalCode: 10240,
            },
            {
              subdistrictNameTh: "นวมินทร์",
              subdistrictNameEn: "Nawamin",
              postalCode: 10240,
            },
            {
              subdistrictNameTh: "นวลจันทร์",
              subdistrictNameEn: "Nuan Chan",
              postalCode: 10240,
            },
          ],
        },
        {
          districtNameTh: "สาทร",
          districtNameEn: "Sa Thon",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งวัดดอน",
              subdistrictNameEn: "Thung Wat Don",
              postalCode: 10120,
            },
            {
              subdistrictNameTh: "ยานนาวา",
              subdistrictNameEn: "Yan Nawa",
              postalCode: 10120,
            },
            {
              subdistrictNameTh: "ทุ่งมหาเมฆ",
              subdistrictNameEn: "Thung Maha Mek",
              postalCode: 10120,
            },
          ],
        },
        {
          districtNameTh: "บางซื่อ",
          districtNameEn: "Bang Su",
          subdistricts: [
            {
              subdistrictNameTh: "บางซื่อ",
              subdistrictNameEn: "Bang Sue",
              postalCode: 10800,
            },
            {
              subdistrictNameTh: "วงศ์สว่าง",
              subdistrictNameEn: "Wong Sawang",
              postalCode: 10800,
            },
          ],
        },
        {
          districtNameTh: "จตุจักร",
          districtNameEn: "Chatuchak",
          subdistricts: [
            {
              subdistrictNameTh: "ลาดยาว",
              subdistrictNameEn: "Lat Yao",
              postalCode: 10900,
            },
            {
              subdistrictNameTh: "เสนานิคม",
              subdistrictNameEn: "Sena Nikhom",
              postalCode: 10900,
            },
            {
              subdistrictNameTh: "จันทรเกษม",
              subdistrictNameEn: "Chan Kasem",
              postalCode: 10900,
            },
            {
              subdistrictNameTh: "จอมพล",
              subdistrictNameEn: "Chom Phon",
              postalCode: 10900,
            },
            {
              subdistrictNameTh: "จตุจักร",
              subdistrictNameEn: "Chatuchak",
              postalCode: 10900,
            },
          ],
        },
        {
          districtNameTh: "บางคอแหลม",
          districtNameEn: "Bang Kho Laem",
          subdistricts: [
            {
              subdistrictNameTh: "บางคอแหลม",
              subdistrictNameEn: "Bang Kho Laem",
              postalCode: 10120,
            },
            {
              subdistrictNameTh: "วัดพระยาไกร",
              subdistrictNameEn: "Wat Phraya Krai",
              postalCode: 10120,
            },
            {
              subdistrictNameTh: "บางโคล่",
              subdistrictNameEn: "Bang Khlo",
              postalCode: 10120,
            },
          ],
        },
        {
          districtNameTh: "ประเวศ",
          districtNameEn: "Prawet",
          subdistricts: [
            {
              subdistrictNameTh: "ประเวศ",
              subdistrictNameEn: "Prawet",
              postalCode: 10250,
            },
            {
              subdistrictNameTh: "หนองบอน",
              subdistrictNameEn: "Nong Bon",
              postalCode: 10250,
            },
            {
              subdistrictNameTh: "ดอกไม้",
              subdistrictNameEn: "Dok Mai",
              postalCode: 10250,
            },
          ],
        },
        {
          districtNameTh: "คลองเตย",
          districtNameEn: "Khlong Toei",
          subdistricts: [
            {
              subdistrictNameTh: "คลองเตย",
              subdistrictNameEn: "Khlong Toei",
              postalCode: 10110,
            },
            {
              subdistrictNameTh: "คลองตัน",
              subdistrictNameEn: "Khlong Tan",
              postalCode: 10110,
            },
            {
              subdistrictNameTh: "พระโขนง",
              subdistrictNameEn: "Phra Khanong",
              postalCode: 10110,
            },
          ],
        },
        {
          districtNameTh: "สวนหลวง",
          districtNameEn: "Suan Luang",
          subdistricts: [
            {
              subdistrictNameTh: "สวนหลวง",
              subdistrictNameEn: "Suan Luang",
              postalCode: 10250,
            },
            {
              subdistrictNameTh: "อ่อนนุช",
              subdistrictNameEn: "On Nut",
              postalCode: 10250,
            },
            {
              subdistrictNameTh: "พัฒนาการ",
              subdistrictNameEn: "Phatthanakan",
              postalCode: 10250,
            },
          ],
        },
        {
          districtNameTh: "จอมทอง",
          districtNameEn: "Chom Thong",
          subdistricts: [
            {
              subdistrictNameTh: "บางขุนเทียน",
              subdistrictNameEn: "Bang Khun Thian",
              postalCode: 10150,
            },
            {
              subdistrictNameTh: "บางค้อ",
              subdistrictNameEn: "Bang Kho",
              postalCode: 10150,
            },
            {
              subdistrictNameTh: "บางมด",
              subdistrictNameEn: "Bang Mot",
              postalCode: 10150,
            },
            {
              subdistrictNameTh: "จอมทอง",
              subdistrictNameEn: "Chom Thong",
              postalCode: 10150,
            },
          ],
        },
        {
          districtNameTh: "ดอนเมือง",
          districtNameEn: "Don Mueang",
          subdistricts: [
            {
              subdistrictNameTh: "สีกัน",
              subdistrictNameEn: "Si Kan",
              postalCode: 10210,
            },
            {
              subdistrictNameTh: "ดอนเมือง",
              subdistrictNameEn: "Don Mueang",
              postalCode: 10210,
            },
            {
              subdistrictNameTh: "สนามบิน",
              subdistrictNameEn: "Sanambin",
              postalCode: 10210,
            },
          ],
        },
        {
          districtNameTh: "ราชเทวี",
          districtNameEn: "Rat Thewi",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งพญาไท",
              subdistrictNameEn: "Thung Phaya Thai",
              postalCode: 10400,
            },
            {
              subdistrictNameTh: "ถนนพญาไท",
              subdistrictNameEn: "Thanon Phaya Thai",
              postalCode: 10400,
            },
            {
              subdistrictNameTh: "ถนนเพชรบุรี",
              subdistrictNameEn: "Thanon Phetchaburi",
              postalCode: 10400,
            },
            {
              subdistrictNameTh: "มักกะสัน",
              subdistrictNameEn: "Makkasan",
              postalCode: 10400,
            },
          ],
        },
        {
          districtNameTh: "ลาดพร้าว",
          districtNameEn: "Lat Phrao",
          subdistricts: [
            {
              subdistrictNameTh: "ลาดพร้าว",
              subdistrictNameEn: "Lat Phrao",
              postalCode: 10230,
            },
            {
              subdistrictNameTh: "จรเข้บัว",
              subdistrictNameEn: "Chorakhe Bua",
              postalCode: 10230,
            },
          ],
        },
        {
          districtNameTh: "วัฒนา",
          districtNameEn: "Watthana",
          subdistricts: [
            {
              subdistrictNameTh: "คลองเตยเหนือ",
              subdistrictNameEn: "Khlong Toei Nuea",
              postalCode: 10110,
            },
            {
              subdistrictNameTh: "คลองตันเหนือ",
              subdistrictNameEn: "Khlong Tan Nuea",
              postalCode: 10110,
            },
            {
              subdistrictNameTh: "พระโขนงเหนือ",
              subdistrictNameEn: "Phra Khanong Nuea",
              postalCode: 10110,
            },
          ],
        },
        {
          districtNameTh: "บางแค",
          districtNameEn: "Bang Khae",
          subdistricts: [
            {
              subdistrictNameTh: "บางแค",
              subdistrictNameEn: "Bang Khae",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "บางแคเหนือ",
              subdistrictNameEn: "Bang Khae Nuea",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "บางไผ่",
              subdistrictNameEn: "Bang Phai",
              postalCode: 10160,
            },
            {
              subdistrictNameTh: "หลักสอง",
              subdistrictNameEn: "Lak Song",
              postalCode: 10160,
            },
          ],
        },
        {
          districtNameTh: "หลักสี่",
          districtNameEn: "Lak Si",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งสองห้อง",
              subdistrictNameEn: "Thung Song Hong",
              postalCode: 10210,
            },
            {
              subdistrictNameTh: "ตลาดบางเขน",
              subdistrictNameEn: "Talat Bang Khen",
              postalCode: 10210,
            },
          ],
        },
        {
          districtNameTh: "สายไหม",
          districtNameEn: "Sai Mai",
          subdistricts: [
            {
              subdistrictNameTh: "สายไหม",
              subdistrictNameEn: "Sai Mai",
              postalCode: 10220,
            },
            {
              subdistrictNameTh: "ออเงิน",
              subdistrictNameEn: "O Ngoen",
              postalCode: 10220,
            },
            {
              subdistrictNameTh: "คลองถนน",
              subdistrictNameEn: "Khlong Thanon",
              postalCode: 10220,
            },
          ],
        },
        {
          districtNameTh: "คันนายาว",
          districtNameEn: "Khanna Yao",
          subdistricts: [
            {
              subdistrictNameTh: "คันนายาว",
              subdistrictNameEn: "Khan Na Yao",
              postalCode: 10230,
            },
            {
              subdistrictNameTh: "รามอินทรา",
              subdistrictNameEn: "Ram Inthra",
              postalCode: 10230,
            },
          ],
        },
        {
          districtNameTh: "สะพานสูง",
          districtNameEn: "Saphan Sung",
          subdistricts: [
            {
              subdistrictNameTh: "สะพานสูง",
              subdistrictNameEn: "Saphan Sung",
              postalCode: 10240,
            },
            {
              subdistrictNameTh: "ราษฎร์พัฒนา",
              subdistrictNameEn: "Rat Phatthana",
              postalCode: 10240,
            },
            {
              subdistrictNameTh: "ทับช้าง",
              subdistrictNameEn: "Thap Chang",
              postalCode: 10250,
            },
          ],
        },
        {
          districtNameTh: "วังทองหลาง",
          districtNameEn: "Wang Thong Lang",
          subdistricts: [
            {
              subdistrictNameTh: "วังทองหลาง",
              subdistrictNameEn: "Wang Thonglang",
              postalCode: 10310,
            },
            {
              subdistrictNameTh: "สะพานสอง",
              subdistrictNameEn: "Saphan Song",
              postalCode: 10310,
            },
            {
              subdistrictNameTh: "คลองเจ้าคุณสิงห์",
              subdistrictNameEn: "Khlong Chaokhun Sing",
              postalCode: 10310,
            },
            {
              subdistrictNameTh: "พลับพลา",
              subdistrictNameEn: "Phlapphla",
              postalCode: 10310,
            },
          ],
        },
        {
          districtNameTh: "คลองสามวา",
          districtNameEn: "Khlong Sam Wa",
          subdistricts: [
            {
              subdistrictNameTh: "สามวาตะวันตก",
              subdistrictNameEn: "Sam Wa Tawan Tok",
              postalCode: 10510,
            },
            {
              subdistrictNameTh: "สามวาตะวันออก",
              subdistrictNameEn: "Sam Wa Tawan Ok",
              postalCode: 10510,
            },
            {
              subdistrictNameTh: "บางชัน",
              subdistrictNameEn: "Bang Chan",
              postalCode: 10510,
            },
            {
              subdistrictNameTh: "ทรายกองดิน",
              subdistrictNameEn: "Sai Kong Din",
              postalCode: 10510,
            },
            {
              subdistrictNameTh: "ทรายกองดินใต้",
              subdistrictNameEn: "Sai Kong Din Tai",
              postalCode: 10510,
            },
          ],
        },
        {
          districtNameTh: "บางนา",
          districtNameEn: "Bang Na",
          subdistricts: [
            {
              subdistrictNameTh: "บางนาเหนือ",
              subdistrictNameEn: "Bang Na Nuea",
              postalCode: 10260,
            },
            {
              subdistrictNameTh: "บางนาใต้",
              subdistrictNameEn: "Bang Na Tai",
              postalCode: 10260,
            },
          ],
        },
        {
          districtNameTh: "ทวีวัฒนา",
          districtNameEn: "Thawi Watthana",
          subdistricts: [
            {
              subdistrictNameTh: "ทวีวัฒนา",
              subdistrictNameEn: "Thawi Watthana ",
              postalCode: 10170,
            },
            {
              subdistrictNameTh: "ศาลาธรรมสพน์",
              subdistrictNameEn: "Sala Thammasop",
              postalCode: 10170,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งครุ",
          districtNameEn: "Thung Khu",
          subdistricts: [
            {
              subdistrictNameTh: "บางมด",
              subdistrictNameEn: "Bang Mot",
              postalCode: 10140,
            },
            {
              subdistrictNameTh: "ทุ่งครุ",
              subdistrictNameEn: "Thung Khru",
              postalCode: 10140,
            },
          ],
        },
        {
          districtNameTh: "บางบอน",
          districtNameEn: "Bang Bon",
          subdistricts: [
            {
              subdistrictNameTh: "บางบอนเหนือ",
              subdistrictNameEn: "Bang Bon Nuea",
              postalCode: 10150,
            },
            {
              subdistrictNameTh: "บางบอนใต้",
              subdistrictNameEn: "Bang Bon Tai",
              postalCode: 10150,
            },
            {
              subdistrictNameTh: "คลองบางพราน",
              subdistrictNameEn: "Khlong Bang Phran",
              postalCode: 10150,
            },
            {
              subdistrictNameTh: "คลองบางบอน",
              subdistrictNameEn: "Khlong Bang Bon",
              postalCode: 10150,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สมุทรปราการ",
      stateNameEn: "Samut Prakan",
      districts: [
        {
          districtNameTh: "เมืองสมุทรปราการ",
          districtNameEn: "Mueang Samut Prakan",
          subdistricts: [
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "สำโรงเหนือ",
              subdistrictNameEn: "Samrong Nuea",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "บางเมือง",
              subdistrictNameEn: "Bang Mueang",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "ท้ายบ้าน",
              subdistrictNameEn: "Thai Ban",
              postalCode: 10280,
            },
            {
              subdistrictNameTh: "บางปูใหม่",
              subdistrictNameEn: "Bang Pu Mai",
              postalCode: 10280,
            },
            {
              subdistrictNameTh: "แพรกษา",
              subdistrictNameEn: "Phraek Sa",
              postalCode: 10280,
            },
            {
              subdistrictNameTh: "บางโปรง",
              subdistrictNameEn: "Bang Prong",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "บางปู",
              subdistrictNameEn: "Bang Pu",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "บางด้วน",
              subdistrictNameEn: "Bang Duan",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "บางเมืองใหม่",
              subdistrictNameEn: "Bang Mueang Mai",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "เทพารักษ์",
              subdistrictNameEn: "Thepharak",
              postalCode: 10270,
            },
            {
              subdistrictNameTh: "ท้ายบ้านใหม่",
              subdistrictNameEn: "Thai Ban Mai",
              postalCode: 10280,
            },
            {
              subdistrictNameTh: "แพรกษาใหม่",
              subdistrictNameEn: "Phraek Sa Mai",
              postalCode: 10280,
            },
          ],
        },
        {
          districtNameTh: "บางบ่อ",
          districtNameEn: "Bang Bo",
          subdistricts: [
            {
              subdistrictNameTh: "บางบ่อ",
              subdistrictNameEn: "Bang Bo",
              postalCode: 10560,
            },
            {
              subdistrictNameTh: "บ้านระกาศ",
              subdistrictNameEn: "Ban Rakat",
              postalCode: 10560,
            },
            {
              subdistrictNameTh: "บางพลีน้อย",
              subdistrictNameEn: "Bang Phli Noi",
              postalCode: 10560,
            },
            {
              subdistrictNameTh: "บางเพรียง",
              subdistrictNameEn: "Bang Phriang",
              postalCode: 10560,
            },
            {
              subdistrictNameTh: "คลองด่าน",
              subdistrictNameEn: "Khlong Dan",
              postalCode: 10550,
            },
            {
              subdistrictNameTh: "คลองสวน",
              subdistrictNameEn: "Khlong Suan",
              postalCode: 10560,
            },
            {
              subdistrictNameTh: "เปร็ง",
              subdistrictNameEn: "Preng",
              postalCode: 10560,
            },
            {
              subdistrictNameTh: "คลองนิยมยาตรา",
              subdistrictNameEn: "Khlong Niyom Yattra",
              postalCode: 10560,
            },
          ],
        },
        {
          districtNameTh: "บางพลี",
          districtNameEn: "Bang Phli",
          subdistricts: [
            {
              subdistrictNameTh: "บางพลีใหญ่",
              subdistrictNameEn: "Bang Phli Yai",
              postalCode: 10540,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 10540,
            },
            {
              subdistrictNameTh: "บางปลา",
              subdistrictNameEn: "Bang Pla",
              postalCode: 10540,
            },
            {
              subdistrictNameTh: "บางโฉลง",
              subdistrictNameEn: "Bang Chalong",
              postalCode: 10540,
            },
            {
              subdistrictNameTh: "ราชาเทวะ",
              subdistrictNameEn: "Racha Thewa",
              postalCode: 10540,
            },
            {
              subdistrictNameTh: "หนองปรือ",
              subdistrictNameEn: "Nong Prue",
              postalCode: 10540,
            },
          ],
        },
        {
          districtNameTh: "พระประแดง",
          districtNameEn: "Phra Pradaeng",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาด",
              subdistrictNameEn: "Talat",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางพึ่ง",
              subdistrictNameEn: "Bang Phueng",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางจาก",
              subdistrictNameEn: "Bang Chak",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางครุ",
              subdistrictNameEn: "Bang Khru",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางหญ้าแพรก",
              subdistrictNameEn: "Bang Ya Phraek",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางหัวเสือ",
              subdistrictNameEn: "Bang Hua Suea",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "สำโรงใต้",
              subdistrictNameEn: "Samrong Tai",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางยอ",
              subdistrictNameEn: "Bang Yo",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางกะเจ้า",
              subdistrictNameEn: "Bang Kachao",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางน้ำผึ้ง",
              subdistrictNameEn: "Bang Nam Phueng",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางกระสอบ",
              subdistrictNameEn: "Bang Krasop",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "บางกอบัว",
              subdistrictNameEn: "Bang Ko Bua",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "ทรงคนอง",
              subdistrictNameEn: "Song Khanong",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 10130,
            },
            {
              subdistrictNameTh: "สำโรงกลาง",
              subdistrictNameEn: "Samrong Klang",
              postalCode: 10130,
            },
          ],
        },
        {
          districtNameTh: "พระสมุทรเจดีย์",
          districtNameEn: "Phra Samut Chedi",
          subdistricts: [
            {
              subdistrictNameTh: "นาเกลือ",
              subdistrictNameEn: "Na Kluea",
              postalCode: 10290,
            },
            {
              subdistrictNameTh: "บ้านคลองสวน",
              subdistrictNameEn: "Ban Khlong Suan",
              postalCode: 10290,
            },
            {
              subdistrictNameTh: "แหลมฟ้าผ่า",
              subdistrictNameEn: "Laem Fa Pha",
              postalCode: 10290,
            },
            {
              subdistrictNameTh: "ปากคลองบางปลากด",
              subdistrictNameEn: "Pak Khlong Bang Pla Kot",
              postalCode: 10290,
            },
            {
              subdistrictNameTh: "ในคลองบางปลากด",
              subdistrictNameEn: "Nai Khlong Bang Pla Kot",
              postalCode: 10290,
            },
          ],
        },
        {
          districtNameTh: "บางเสาธง",
          districtNameEn: "Bang Sao Thong",
          subdistricts: [
            {
              subdistrictNameTh: "บางเสาธง",
              subdistrictNameEn: "Bang Sao Thong",
              postalCode: 10570,
            },
            {
              subdistrictNameTh: "ศีรษะจรเข้น้อย",
              subdistrictNameEn: "Sisa Chorakhe Noi",
              postalCode: 10570,
            },
            {
              subdistrictNameTh: "ศีรษะจรเข้ใหญ่",
              subdistrictNameEn: "Sisa Chorakhe Yai",
              postalCode: 10570,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นนทบุรี",
      stateNameEn: "Nonthaburi",
      districts: [
        {
          districtNameTh: "เมืองนนทบุรี",
          districtNameEn: "Mueang Nonthaburi",
          subdistricts: [
            {
              subdistrictNameTh: "สวนใหญ่",
              subdistrictNameEn: "Suan Yai",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "ตลาดขวัญ",
              subdistrictNameEn: "Talat Khwan",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "บางเขน",
              subdistrictNameEn: "Bang Khen",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "บางกระสอ",
              subdistrictNameEn: "Bang Kraso",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "ท่าทราย",
              subdistrictNameEn: "Tha Sai",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "บางไผ่",
              subdistrictNameEn: "Bang Phai",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "บางศรีเมือง",
              subdistrictNameEn: "Bang Si Mueang",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "บางกร่าง",
              subdistrictNameEn: "Bang Krang",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "ไทรม้า",
              subdistrictNameEn: "Sai Ma",
              postalCode: 11000,
            },
            {
              subdistrictNameTh: "บางรักน้อย",
              subdistrictNameEn: "Bang Rak Noi",
              postalCode: 11000,
            },
          ],
        },
        {
          districtNameTh: "บางกรวย",
          districtNameEn: "Bang Kruai",
          subdistricts: [
            {
              subdistrictNameTh: "วัดชลอ",
              subdistrictNameEn: "Wat Chalo",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "บางกรวย",
              subdistrictNameEn: "Bang Kruai",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "บางสีทอง",
              subdistrictNameEn: "Bang Si Thong",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "บางขนุน",
              subdistrictNameEn: "Bang Khanun",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "บางขุนกอง",
              subdistrictNameEn: "Bang Khun Kong",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "บางคูเวียง",
              subdistrictNameEn: "Bang Khu Wiang",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "มหาสวัสดิ์",
              subdistrictNameEn: "Mahasawat",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "ปลายบาง",
              subdistrictNameEn: "Plai Bang",
              postalCode: 11130,
            },
            {
              subdistrictNameTh: "ศาลากลาง",
              subdistrictNameEn: "Sala Klang",
              postalCode: 11130,
            },
          ],
        },
        {
          districtNameTh: "บางใหญ่",
          districtNameEn: "Bang Yai",
          subdistricts: [
            {
              subdistrictNameTh: "บางม่วง",
              subdistrictNameEn: "Bang Muang",
              postalCode: 11140,
            },
            {
              subdistrictNameTh: "บางแม่นาง",
              subdistrictNameEn: "Bang Mae Nang",
              postalCode: 11140,
            },
            {
              subdistrictNameTh: "บางเลน",
              subdistrictNameEn: "Bang Len",
              postalCode: 11140,
            },
            {
              subdistrictNameTh: "เสาธงหิน",
              subdistrictNameEn: "Sao Thong Hin",
              postalCode: 11140,
            },
            {
              subdistrictNameTh: "บางใหญ่",
              subdistrictNameEn: "Bang Yai",
              postalCode: 11140,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 11140,
            },
          ],
        },
        {
          districtNameTh: "บางบัวทอง",
          districtNameEn: "Bang Bua Thong",
          subdistricts: [
            {
              subdistrictNameTh: "โสนลอย",
              subdistrictNameEn: "Sano Loi",
              postalCode: 11110,
            },
            {
              subdistrictNameTh: "บางบัวทอง",
              subdistrictNameEn: "Bang Bua Thong",
              postalCode: 11110,
            },
            {
              subdistrictNameTh: "บางรักใหญ่",
              subdistrictNameEn: "Bang Rak Yai",
              postalCode: 11110,
            },
            {
              subdistrictNameTh: "บางคูรัด",
              subdistrictNameEn: "Bang Khu Rat",
              postalCode: 11110,
            },
            {
              subdistrictNameTh: "ละหาร",
              subdistrictNameEn: "Lahan",
              postalCode: 11110,
            },
            {
              subdistrictNameTh: "ลำโพ",
              subdistrictNameEn: "Lam Pho",
              postalCode: 11110,
            },
            {
              subdistrictNameTh: "พิมลราช",
              subdistrictNameEn: "Phimonrat",
              postalCode: 11110,
            },
            {
              subdistrictNameTh: "บางรักพัฒนา",
              subdistrictNameEn: "Bang Rak Phatthana",
              postalCode: 11110,
            },
          ],
        },
        {
          districtNameTh: "ไทรน้อย",
          districtNameEn: "Sai Noi",
          subdistricts: [
            {
              subdistrictNameTh: "ไทรน้อย",
              subdistrictNameEn: "Sai Noi",
              postalCode: 11150,
            },
            {
              subdistrictNameTh: "ราษฎร์นิยม",
              subdistrictNameEn: "Rat Niyom",
              postalCode: 11150,
            },
            {
              subdistrictNameTh: "หนองเพรางาย",
              subdistrictNameEn: "Nong Phrao Ngai",
              postalCode: 11150,
            },
            {
              subdistrictNameTh: "ไทรใหญ่",
              subdistrictNameEn: "Sai Yai",
              postalCode: 11150,
            },
            {
              subdistrictNameTh: "ขุนศรี",
              subdistrictNameEn: "Khun Si",
              postalCode: 11150,
            },
            {
              subdistrictNameTh: "คลองขวาง",
              subdistrictNameEn: "Khlong Khwang",
              postalCode: 11150,
            },
            {
              subdistrictNameTh: "ทวีวัฒนา",
              subdistrictNameEn: "Thawi Watthana",
              postalCode: 11150,
            },
          ],
        },
        {
          districtNameTh: "ปากเกร็ด",
          districtNameEn: "Pak Kret",
          subdistricts: [
            {
              subdistrictNameTh: "ปากเกร็ด",
              subdistrictNameEn: "Pak Kret",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "บางตลาด",
              subdistrictNameEn: "Bang Talat",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "บางพูด",
              subdistrictNameEn: "Bang Phut",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "บางตะไนย์",
              subdistrictNameEn: "Bang Tanai",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "คลองพระอุดม",
              subdistrictNameEn: "Khlong Phra Udom",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "ท่าอิฐ",
              subdistrictNameEn: "Tha It",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "เกาะเกร็ด",
              subdistrictNameEn: "Ko Kret",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "อ้อมเกร็ด",
              subdistrictNameEn: "Om Kret",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "คลองข่อย",
              subdistrictNameEn: "Khlong Khoi",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "บางพลับ",
              subdistrictNameEn: "Bang Phlap",
              postalCode: 11120,
            },
            {
              subdistrictNameTh: "คลองเกลือ",
              subdistrictNameEn: "Khlong Kluea",
              postalCode: 11120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ปทุมธานี",
      stateNameEn: "Pathum Thani",
      districts: [
        {
          districtNameTh: "เมืองปทุมธานี",
          districtNameEn: "Mueang Pathum Thani",
          subdistricts: [
            {
              subdistrictNameTh: "บางปรอก",
              subdistrictNameEn: "Bang Prok",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บ้านฉาง",
              subdistrictNameEn: "Ban Chang",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บ้านกระแชง",
              subdistrictNameEn: "Ban Krachaeng",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บางขะแยง",
              subdistrictNameEn: "Bang Khayaeng",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บางคูวัด",
              subdistrictNameEn: "Bang Khu Wat",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บางหลวง",
              subdistrictNameEn: "Bang Luang",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บางเดื่อ",
              subdistrictNameEn: "Bang Duea",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บางพูด",
              subdistrictNameEn: "Bang Phut",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บางพูน",
              subdistrictNameEn: "Bang Phun",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "บางกะดี",
              subdistrictNameEn: "Bang Kadi",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "สวนพริกไทย",
              subdistrictNameEn: "Suan Phrik Thai",
              postalCode: 12000,
            },
            {
              subdistrictNameTh: "หลักหก",
              subdistrictNameEn: "Lak Hok",
              postalCode: 12000,
            },
          ],
        },
        {
          districtNameTh: "คลองหลวง",
          districtNameEn: "Khlong Luang",
          subdistricts: [
            {
              subdistrictNameTh: "คลองหนึ่ง",
              subdistrictNameEn: "Khlong Nueng",
              postalCode: 12120,
            },
            {
              subdistrictNameTh: "คลองสอง",
              subdistrictNameEn: "Khlong Song",
              postalCode: 12120,
            },
            {
              subdistrictNameTh: "คลองสาม",
              subdistrictNameEn: "Khlong Sam",
              postalCode: 12120,
            },
            {
              subdistrictNameTh: "คลองสี่",
              subdistrictNameEn: "Khlong Si",
              postalCode: 12120,
            },
            {
              subdistrictNameTh: "คลองห้า",
              subdistrictNameEn: "Khlong Ha",
              postalCode: 12120,
            },
            {
              subdistrictNameTh: "คลองหก",
              subdistrictNameEn: "Khlong Hok",
              postalCode: 12120,
            },
            {
              subdistrictNameTh: "คลองเจ็ด",
              subdistrictNameEn: "Khlong Chet",
              postalCode: 12120,
            },
          ],
        },
        {
          districtNameTh: "ธัญบุรี",
          districtNameEn: "Thanyaburi",
          subdistricts: [
            {
              subdistrictNameTh: "ประชาธิปัตย์",
              subdistrictNameEn: "Pracha Thipat",
              postalCode: 12130,
            },
            {
              subdistrictNameTh: "บึงยี่โถ",
              subdistrictNameEn: "Bueng Yitho",
              postalCode: 12130,
            },
            {
              subdistrictNameTh: "รังสิต",
              subdistrictNameEn: "Rangsit",
              postalCode: 12110,
            },
            {
              subdistrictNameTh: "ลำผักกูด",
              subdistrictNameEn: "Lam Phak Kut",
              postalCode: 12110,
            },
            {
              subdistrictNameTh: "บึงสนั่น",
              subdistrictNameEn: "Bueng Sanan",
              postalCode: 12110,
            },
            {
              subdistrictNameTh: "บึงน้ำรักษ์",
              subdistrictNameEn: "Bueng Nam Rak",
              postalCode: 12110,
            },
          ],
        },
        {
          districtNameTh: "หนองเสือ",
          districtNameEn: "Nong Suea",
          subdistricts: [
            {
              subdistrictNameTh: "บึงบา",
              subdistrictNameEn: "Bueng Ba",
              postalCode: 12170,
            },
            {
              subdistrictNameTh: "บึงบอน",
              subdistrictNameEn: "Bueng Bon",
              postalCode: 12170,
            },
            {
              subdistrictNameTh: "บึงกาสาม",
              subdistrictNameEn: "Bueng Ka Sam",
              postalCode: 12170,
            },
            {
              subdistrictNameTh: "บึงชำอ้อ",
              subdistrictNameEn: "Bueng Cham O",
              postalCode: 12170,
            },
            {
              subdistrictNameTh: "หนองสามวัง",
              subdistrictNameEn: "Nong Sam Wang",
              postalCode: 12170,
            },
            {
              subdistrictNameTh: "ศาลาครุ",
              subdistrictNameEn: "Sala Khru",
              postalCode: 12170,
            },
            {
              subdistrictNameTh: "นพรัตน์",
              subdistrictNameEn: "Noppharat",
              postalCode: 12170,
            },
          ],
        },
        {
          districtNameTh: "ลาดหลุมแก้ว",
          districtNameEn: "Lat Lum Kaeo",
          subdistricts: [
            {
              subdistrictNameTh: "ระแหง",
              subdistrictNameEn: "Rahaeng",
              postalCode: 12140,
            },
            {
              subdistrictNameTh: "ลาดหลุมแก้ว",
              subdistrictNameEn: "Lat Lum Kaeo",
              postalCode: 12140,
            },
            {
              subdistrictNameTh: "คูบางหลวง",
              subdistrictNameEn: "Khu Bang Luang",
              postalCode: 12140,
            },
            {
              subdistrictNameTh: "คูขวาง",
              subdistrictNameEn: "Khu Khwang",
              postalCode: 12140,
            },
            {
              subdistrictNameTh: "คลองพระอุดม",
              subdistrictNameEn: "Khlong Phra Udom",
              postalCode: 12140,
            },
            {
              subdistrictNameTh: "บ่อเงิน",
              subdistrictNameEn: "Bo Ngoen",
              postalCode: 12140,
            },
            {
              subdistrictNameTh: "หน้าไม้",
              subdistrictNameEn: "Na Mai",
              postalCode: 12140,
            },
          ],
        },
        {
          districtNameTh: "ลำลูกกา",
          districtNameEn: "Lam Luk Ka",
          subdistricts: [
            {
              subdistrictNameTh: "คูคต",
              subdistrictNameEn: "Khu Khot",
              postalCode: 12130,
            },
            {
              subdistrictNameTh: "ลาดสวาย",
              subdistrictNameEn: "Lat Sawai",
              postalCode: 12150,
            },
            {
              subdistrictNameTh: "บึงคำพร้อย",
              subdistrictNameEn: "Bueng Kham Phoi",
              postalCode: 12150,
            },
            {
              subdistrictNameTh: "ลำลูกกา",
              subdistrictNameEn: "Lam Luk Ka",
              postalCode: 12150,
            },
            {
              subdistrictNameTh: "บึงทองหลาง",
              subdistrictNameEn: "Bueng Thong Lang",
              postalCode: 12150,
            },
            {
              subdistrictNameTh: "ลำไทร",
              subdistrictNameEn: "Lam Sai",
              postalCode: 12150,
            },
            {
              subdistrictNameTh: "บึงคอไห",
              subdistrictNameEn: "Bueng Kho Hai",
              postalCode: 12150,
            },
            {
              subdistrictNameTh: "พืชอุดม",
              subdistrictNameEn: "Phuet Udom",
              postalCode: 12150,
            },
          ],
        },
        {
          districtNameTh: "สามโคก",
          districtNameEn: "Sam Khok",
          subdistricts: [
            {
              subdistrictNameTh: "บางเตย",
              subdistrictNameEn: "Bang Toei",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "คลองควาย",
              subdistrictNameEn: "Khlong Khwai",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "สามโคก",
              subdistrictNameEn: "Sam Khok",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "กระแชง",
              subdistrictNameEn: "Krachaeng",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "บางโพธิ์เหนือ",
              subdistrictNameEn: "Bang Pho Nuea",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "เชียงรากใหญ่",
              subdistrictNameEn: "Chiang Rak Yai",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "บ้านปทุม",
              subdistrictNameEn: "Ban Pathum",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "บ้านงิ้ว",
              subdistrictNameEn: "Ban Ngio",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "เชียงรากน้อย",
              subdistrictNameEn: "Chiang Rak Noi",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "บางกระบือ",
              subdistrictNameEn: "Bang Krabue",
              postalCode: 12160,
            },
            {
              subdistrictNameTh: "ท้ายเกาะ",
              subdistrictNameEn: "Thai Ko",
              postalCode: 12160,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "พระนครศรีอยุธยา",
      stateNameEn: "Phra Nakhon Si Ayutthaya",
      districts: [
        {
          districtNameTh: "พระนครศรีอยุธยา",
          districtNameEn: "Phra Nakhon Si Ayutthaya",
          subdistricts: [
            {
              subdistrictNameTh: "ประตูชัย",
              subdistrictNameEn: "Pratu Chai",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "กะมัง",
              subdistrictNameEn: "Kamang",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "หอรัตนไชย",
              subdistrictNameEn: "Ho Rattana Chai",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "หัวรอ",
              subdistrictNameEn: "Hua Ro",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "ท่าวาสุกรี",
              subdistrictNameEn: "Tha Wasukri",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "ไผ่ลิง",
              subdistrictNameEn: "Phai Ling",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "ปากกราน",
              subdistrictNameEn: "Pak Kran",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "ภูเขาทอง",
              subdistrictNameEn: "Phu Khao Thong",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "สำเภาล่ม",
              subdistrictNameEn: "Samphao Lom",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "สวนพริก",
              subdistrictNameEn: "Suan Phrik",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "คลองตะเคียน",
              subdistrictNameEn: "Khlong Takhian",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "วัดตูม",
              subdistrictNameEn: "Wat Tum",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "หันตรา",
              subdistrictNameEn: "Han Tra",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "ลุมพลี",
              subdistrictNameEn: "Lumphli",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "บ้านเกาะ",
              subdistrictNameEn: "Ban Ko",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "คลองสวนพลู",
              subdistrictNameEn: "Khlong Suan Phu",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "คลองสระบัว",
              subdistrictNameEn: "Khlong Sa Bua",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "เกาะเรียน",
              subdistrictNameEn: "Ko Rian",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "บ้านป้อม",
              subdistrictNameEn: "Ban Pom",
              postalCode: 13000,
            },
            {
              subdistrictNameTh: "บ้านรุน",
              subdistrictNameEn: "Ban Run",
              postalCode: 13000,
            },
          ],
        },
        {
          districtNameTh: "ท่าเรือ",
          districtNameEn: "Tha Ruea",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "จำปา",
              subdistrictNameEn: "Champa",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "ท่าหลวง",
              subdistrictNameEn: "Tha Luang",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "บ้านร่อม",
              subdistrictNameEn: "Ban Rom",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "ศาลาลอย",
              subdistrictNameEn: "Sala Loi",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "วังแดง",
              subdistrictNameEn: "Wang Daeng",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "โพธิ์เอน",
              subdistrictNameEn: "Pho En",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "ปากท่า",
              subdistrictNameEn: "Pak Tha",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "หนองขนาก",
              subdistrictNameEn: "Nong Khanak",
              postalCode: 13130,
            },
            {
              subdistrictNameTh: "ท่าเจ้าสนุก",
              subdistrictNameEn: "Tha Chao Sanuk",
              postalCode: 13130,
            },
          ],
        },
        {
          districtNameTh: "นครหลวง",
          districtNameEn: "Nakhon Luang",
          subdistricts: [
            {
              subdistrictNameTh: "นครหลวง",
              subdistrictNameEn: "Nakhon Luang",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "บ่อโพง",
              subdistrictNameEn: "Bo Phong",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "บ้านชุ้ง",
              subdistrictNameEn: "Ban Chung",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "ปากจั่น",
              subdistrictNameEn: "Pak Chan",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "บางระกำ",
              subdistrictNameEn: "Bang Rakam",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "บางพระครู",
              subdistrictNameEn: "Bang Phrakhru",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "แม่ลา",
              subdistrictNameEn: "Mae La",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "หนองปลิง",
              subdistrictNameEn: "Nong Pling",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "คลองสะแก",
              subdistrictNameEn: "Khlong Sakae",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "สามไถ",
              subdistrictNameEn: "Sam Thai",
              postalCode: 13260,
            },
            {
              subdistrictNameTh: "พระนอน",
              subdistrictNameEn: "Phra Non",
              postalCode: 13260,
            },
          ],
        },
        {
          districtNameTh: "บางไทร",
          districtNameEn: "Bang Sai",
          subdistricts: [
            {
              subdistrictNameTh: "บางไทร",
              subdistrictNameEn: "Bang Sai",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "บางพลี",
              subdistrictNameEn: "Bang Phli",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "สนามชัย",
              subdistrictNameEn: "Sanam Chai",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "บ้านแป้ง",
              subdistrictNameEn: "Ban Paeng",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "หน้าไม้",
              subdistrictNameEn: "Na Mai",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "บางยี่โท",
              subdistrictNameEn: "Bang Yitho",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "แคออก",
              subdistrictNameEn: "Khae Ok",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "แคตก",
              subdistrictNameEn: "Khae Tok",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "ช่างเหล็ก",
              subdistrictNameEn: "Chang Lek",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "กระแชง",
              subdistrictNameEn: "Krachaeng",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "บ้านกลึง",
              subdistrictNameEn: "Ban Klueng",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "ช้างน้อย",
              subdistrictNameEn: "Chang Noi",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "ห่อหมก",
              subdistrictNameEn: "Ho Mok",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "ไผ่พระ",
              subdistrictNameEn: "Phai Phra",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "กกแก้วบูรพา",
              subdistrictNameEn: "Kok Kaeo Burapha",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "ไม้ตรา",
              subdistrictNameEn: "Mai Tra",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "บ้านม้า",
              subdistrictNameEn: "Ban Ma",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "บ้านเกาะ",
              subdistrictNameEn: "Ban Ko",
              postalCode: 13190,
            },
            {
              subdistrictNameTh: "ราชคราม",
              subdistrictNameEn: "Ratchakhram",
              postalCode: 13290,
            },
            {
              subdistrictNameTh: "ช้างใหญ่",
              subdistrictNameEn: "Chang Yai",
              postalCode: 13290,
            },
            {
              subdistrictNameTh: "โพแตง",
              subdistrictNameEn: "Po Tang",
              postalCode: 13290,
            },
            {
              subdistrictNameTh: "เชียงรากน้อย",
              subdistrictNameEn: "Chiang Rak Noi",
              postalCode: 13290,
            },
            {
              subdistrictNameTh: "โคกช้าง",
              subdistrictNameEn: "Khok Chang",
              postalCode: 13190,
            },
          ],
        },
        {
          districtNameTh: "บางบาล",
          districtNameEn: "Bang Ban",
          subdistricts: [
            {
              subdistrictNameTh: "บางบาล",
              subdistrictNameEn: "Bang Ban",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "วัดยม",
              subdistrictNameEn: "Wat Yom",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "ไทรน้อย",
              subdistrictNameEn: "Sai Noi",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "สะพานไทย",
              subdistrictNameEn: "Saphan Thai",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "มหาพราหมณ์",
              subdistrictNameEn: "Mahaphram",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "กบเจา",
              subdistrictNameEn: "Kop Chao",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "บ้านคลัง",
              subdistrictNameEn: "Ban Khlang",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "พระขาว",
              subdistrictNameEn: "Phra Khao",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "น้ำเต้า",
              subdistrictNameEn: "Namtao",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "ทางช้าง",
              subdistrictNameEn: "Thang Chang",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "วัดตะกู",
              subdistrictNameEn: "Wat Taku",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "บางหลวง",
              subdistrictNameEn: "Bang Luang",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "บางหลวงโดด",
              subdistrictNameEn: "Bang Luang Dot",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "บางหัก",
              subdistrictNameEn: "Bang Hak",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "บางชะนี",
              subdistrictNameEn: "Bang Chani",
              postalCode: 13250,
            },
            {
              subdistrictNameTh: "บ้านกุ่ม",
              subdistrictNameEn: "Ban Kum",
              postalCode: 13250,
            },
          ],
        },
        {
          districtNameTh: "บางปะอิน",
          districtNameEn: "Bang Pa-In",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านเลน",
              subdistrictNameEn: "Ban Len",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "เชียงรากน้อย",
              subdistrictNameEn: "Chiang Rak Noi",
              postalCode: 13180,
            },
            {
              subdistrictNameTh: "บ้านโพ",
              subdistrictNameEn: "Ban Pho",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "บ้านกรด",
              subdistrictNameEn: "Ban Krot",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "บางกระสั้น",
              subdistrictNameEn: "Bang Krasan",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "คลองจิก",
              subdistrictNameEn: "Khlong Chik",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "บ้านหว้า",
              subdistrictNameEn: "Ban Wa",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "วัดยม",
              subdistrictNameEn: "Wat Yom",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "บางประแดง",
              subdistrictNameEn: "Bang Pradaeng",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "สามเรือน",
              subdistrictNameEn: "Sam Ruean",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "เกาะเกิด",
              subdistrictNameEn: "Ko Koet",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "บ้านพลับ",
              subdistrictNameEn: "Ban Phlap",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "บ้านแป้ง",
              subdistrictNameEn: "Ban Paeng",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "คุ้งลาน",
              subdistrictNameEn: "Khung Lan",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "บ้านสร้าง",
              subdistrictNameEn: "Ban Sang",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "ตลาดเกรียบ",
              subdistrictNameEn: "Talat Kriap",
              postalCode: 13160,
            },
            {
              subdistrictNameTh: "ขนอนหลวง",
              subdistrictNameEn: "Khanon Luang",
              postalCode: 13160,
            },
          ],
        },
        {
          districtNameTh: "บางปะหัน",
          districtNameEn: "Bang Pahan",
          subdistricts: [
            {
              subdistrictNameTh: "บางปะหัน",
              subdistrictNameEn: "Bang Pahan",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "ขยาย",
              subdistrictNameEn: "Khayai",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "บางเดื่อ",
              subdistrictNameEn: "Bang Duea",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "เสาธง",
              subdistrictNameEn: "Sao Thong",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "ทางกลาง",
              subdistrictNameEn: "Thang Klang",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "บางเพลิง",
              subdistrictNameEn: "Bang Phloeng",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "หันสัง",
              subdistrictNameEn: "Han Sang",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "บางนางร้า",
              subdistrictNameEn: "Bang Nang Ra",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "ตานิม",
              subdistrictNameEn: "Tanim",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "ทับน้ำ",
              subdistrictNameEn: "Thap Nam",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "บ้านม้า",
              subdistrictNameEn: "Ban Ma",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "ขวัญเมือง",
              subdistrictNameEn: "Khwan Mueang",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "บ้านลี่",
              subdistrictNameEn: "Ban Li",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "โพธิ์สามต้น",
              subdistrictNameEn: "Pho Sam Ton",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "พุทเลา",
              subdistrictNameEn: "Phut Lao",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "ตาลเอน",
              subdistrictNameEn: "Tan En",
              postalCode: 13220,
            },
            {
              subdistrictNameTh: "บ้านขล้อ",
              subdistrictNameEn: "Ban Khlo",
              postalCode: 13220,
            },
          ],
        },
        {
          districtNameTh: "ผักไห่",
          districtNameEn: "Phak Hai",
          subdistricts: [
            {
              subdistrictNameTh: "ผักไห่",
              subdistrictNameEn: "Phak Hai",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "อมฤต",
              subdistrictNameEn: "Ammarit",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "บ้านแค",
              subdistrictNameEn: "Ban Khae",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "ลาดน้ำเค็ม",
              subdistrictNameEn: "Lat Nam Khem",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "ตาลาน",
              subdistrictNameEn: "Ta Lan",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "ท่าดินแดง",
              subdistrictNameEn: "Tha Din Daeng",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "ดอนลาน",
              subdistrictNameEn: "Don Lan",
              postalCode: 13280,
            },
            {
              subdistrictNameTh: "นาคู",
              subdistrictNameEn: "Na Khu",
              postalCode: 13280,
            },
            {
              subdistrictNameTh: "กุฎี",
              subdistrictNameEn: "Ku Di",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "ลำตะเคียน",
              subdistrictNameEn: "Lam Takhian",
              postalCode: 13280,
            },
            {
              subdistrictNameTh: "โคกช้าง",
              subdistrictNameEn: "Khok Chang",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "จักราช",
              subdistrictNameEn: "Chakkarat",
              postalCode: 13280,
            },
            {
              subdistrictNameTh: "หนองน้ำใหญ่",
              subdistrictNameEn: "Nong Nam Yai",
              postalCode: 13280,
            },
            {
              subdistrictNameTh: "ลาดชิด",
              subdistrictNameEn: "Lat Chit",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "หน้าโคก",
              subdistrictNameEn: "Na Khok",
              postalCode: 13120,
            },
            {
              subdistrictNameTh: "บ้านใหญ่",
              subdistrictNameEn: "Ban Yai",
              postalCode: 13120,
            },
          ],
        },
        {
          districtNameTh: "ภาชี",
          districtNameEn: "Phachi",
          subdistricts: [
            {
              subdistrictNameTh: "ภาชี",
              subdistrictNameEn: "Phachi",
              postalCode: 13140,
            },
            {
              subdistrictNameTh: "โคกม่วง",
              subdistrictNameEn: "Khok Muang",
              postalCode: 13140,
            },
            {
              subdistrictNameTh: "ระโสม",
              subdistrictNameEn: "Rasom",
              postalCode: 13140,
            },
            {
              subdistrictNameTh: "หนองน้ำใส",
              subdistrictNameEn: "Nong Nam Sai",
              postalCode: 13140,
            },
            {
              subdistrictNameTh: "ดอนหญ้านาง",
              subdistrictNameEn: "Don Ya Nang",
              postalCode: 13140,
            },
            {
              subdistrictNameTh: "ไผ่ล้อม",
              subdistrictNameEn: "Phai Lom",
              postalCode: 13140,
            },
            {
              subdistrictNameTh: "กระจิว",
              subdistrictNameEn: "Krachio",
              postalCode: 13140,
            },
            {
              subdistrictNameTh: "พระแก้ว",
              subdistrictNameEn: "Phra Kaeo",
              postalCode: 13140,
            },
          ],
        },
        {
          districtNameTh: "ลาดบัวหลวง",
          districtNameEn: "Lat Bua Luang",
          subdistricts: [
            {
              subdistrictNameTh: "ลาดบัวหลวง",
              subdistrictNameEn: "Lat Bua Luang",
              postalCode: 13230,
            },
            {
              subdistrictNameTh: "หลักชัย",
              subdistrictNameEn: "Lak Chai",
              postalCode: 13230,
            },
            {
              subdistrictNameTh: "สามเมือง",
              subdistrictNameEn: "Sam Mueang",
              postalCode: 13230,
            },
            {
              subdistrictNameTh: "พระยาบันลือ",
              subdistrictNameEn: "Phraya Banlue",
              postalCode: 13230,
            },
            {
              subdistrictNameTh: "สิงหนาท",
              subdistrictNameEn: "Singhanat",
              postalCode: 13230,
            },
            {
              subdistrictNameTh: "คู้สลอด",
              subdistrictNameEn: "Khu Salot",
              postalCode: 13230,
            },
            {
              subdistrictNameTh: "คลองพระยาบันลือ",
              subdistrictNameEn: "Khlong Phraya Banlue",
              postalCode: 13230,
            },
          ],
        },
        {
          districtNameTh: "วังน้อย",
          districtNameEn: "Wang Noi",
          subdistricts: [
            {
              subdistrictNameTh: "ลำตาเสา",
              subdistrictNameEn: "Lam Ta Sao",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "บ่อตาโล่",
              subdistrictNameEn: "Bo Ta Lo",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "วังน้อย",
              subdistrictNameEn: "Wang Noi",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "ลำไทร",
              subdistrictNameEn: "Lam Sai",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "สนับทึบ",
              subdistrictNameEn: "Sanap Thuep",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "พยอม",
              subdistrictNameEn: "Phayom",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "หันตะเภา",
              subdistrictNameEn: "Han Taphao",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "วังจุฬา",
              subdistrictNameEn: "Wang Chula",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "ข้าวงาม",
              subdistrictNameEn: "Khao Ngam",
              postalCode: 13170,
            },
            {
              subdistrictNameTh: "ชะแมบ",
              subdistrictNameEn: "Chamaep",
              postalCode: 13170,
            },
          ],
        },
        {
          districtNameTh: "เสนา",
          districtNameEn: "Sena",
          subdistricts: [
            {
              subdistrictNameTh: "เสนา",
              subdistrictNameEn: "Sena",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "บ้านแพน",
              subdistrictNameEn: "Ban Phaen",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "เจ้าเจ็ด",
              subdistrictNameEn: "Chao Chet",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "สามกอ",
              subdistrictNameEn: "Sam Ko",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "บางนมโค",
              subdistrictNameEn: "Bang Nom Kho",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "หัวเวียง",
              subdistrictNameEn: "Hua Wiang",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "มารวิชัย",
              subdistrictNameEn: "Man Wichai",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "บ้านโพธิ์",
              subdistrictNameEn: "Ban Pho",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "รางจรเข้",
              subdistrictNameEn: "Rang Chorakhe",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "บ้านกระทุ่ม",
              subdistrictNameEn: "Ban Krathum",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "บ้านแถว",
              subdistrictNameEn: "Ban Thaeo",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "ชายนา",
              subdistrictNameEn: "Chai Na",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "สามตุ่ม",
              subdistrictNameEn: "Sam Tum",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "ลาดงา",
              subdistrictNameEn: "Lat Nga",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "ดอนทอง",
              subdistrictNameEn: "Don Thong",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "บ้านหลวง",
              subdistrictNameEn: "Ban Luang",
              postalCode: 13110,
            },
            {
              subdistrictNameTh: "เจ้าเสด็จ",
              subdistrictNameEn: "Chao Sadet",
              postalCode: 13110,
            },
          ],
        },
        {
          districtNameTh: "บางซ้าย",
          districtNameEn: "Bang Sai",
          subdistricts: [
            {
              subdistrictNameTh: "บางซ้าย",
              subdistrictNameEn: "Bang Sai",
              postalCode: 13270,
            },
            {
              subdistrictNameTh: "แก้วฟ้า",
              subdistrictNameEn: "Kaeo Fa",
              postalCode: 13270,
            },
            {
              subdistrictNameTh: "เต่าเล่า",
              subdistrictNameEn: "Tao Lao",
              postalCode: 13270,
            },
            {
              subdistrictNameTh: "ปลายกลัด",
              subdistrictNameEn: "Plai Klat",
              postalCode: 13270,
            },
            {
              subdistrictNameTh: "เทพมงคล",
              subdistrictNameEn: "Thep Mongkhon",
              postalCode: 13270,
            },
            {
              subdistrictNameTh: "วังพัฒนา",
              subdistrictNameEn: "Wang Phatthana",
              postalCode: 13270,
            },
          ],
        },
        {
          districtNameTh: "อุทัย",
          districtNameEn: "Uthai",
          subdistricts: [
            {
              subdistrictNameTh: "คานหาม",
              subdistrictNameEn: "Khan Ham",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "บ้านช้าง",
              subdistrictNameEn: "Ban Chang",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "สามบัณฑิต",
              subdistrictNameEn: "Sam Bandit",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "บ้านหีบ",
              subdistrictNameEn: "Ban Hip",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "หนองไม้ซุง",
              subdistrictNameEn: "Nong Mai Sung",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "อุทัย",
              subdistrictNameEn: "Uthai",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "เสนา",
              subdistrictNameEn: "Sena",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "หนองน้ำส้ม",
              subdistrictNameEn: "Nong Nam Som",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "โพสาวหาญ",
              subdistrictNameEn: "Pho Sao Han",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "ธนู",
              subdistrictNameEn: "Thanu",
              postalCode: 13210,
            },
            {
              subdistrictNameTh: "ข้าวเม่า",
              subdistrictNameEn: "Khao Mao",
              postalCode: 13210,
            },
          ],
        },
        {
          districtNameTh: "มหาราช",
          districtNameEn: "Maha Rat",
          subdistricts: [
            {
              subdistrictNameTh: "หัวไผ่",
              subdistrictNameEn: "Hua Phai",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "กะทุ่ม",
              subdistrictNameEn: "Kathum",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "มหาราช",
              subdistrictNameEn: "Maharat",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "น้ำเต้า",
              subdistrictNameEn: "Namtao",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "บางนา",
              subdistrictNameEn: "Bang Na",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "โรงช้าง",
              subdistrictNameEn: "Rong Chang",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "เจ้าปลุก",
              subdistrictNameEn: "Chao Pluk",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "พิตเพียน",
              subdistrictNameEn: "Phit Phian",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "บ้านขวาง",
              subdistrictNameEn: "Ban Khwang",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "ท่าตอ",
              subdistrictNameEn: "Tha To",
              postalCode: 13150,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 13150,
            },
          ],
        },
        {
          districtNameTh: "บ้านแพรก",
          districtNameEn: "Ban Phraek",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านแพรก",
              subdistrictNameEn: "Ban Phraek",
              postalCode: 13240,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 13240,
            },
            {
              subdistrictNameTh: "สำพะเนียง",
              subdistrictNameEn: "Sam Phaniang",
              postalCode: 13240,
            },
            {
              subdistrictNameTh: "คลองน้อย",
              subdistrictNameEn: "Khlong Noi",
              postalCode: 13240,
            },
            {
              subdistrictNameTh: "สองห้อง",
              subdistrictNameEn: "Song Hong",
              postalCode: 13240,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "อ่างทอง",
      stateNameEn: "Ang Thong",
      districts: [
        {
          districtNameTh: "เมืองอ่างทอง",
          districtNameEn: "Mueang Ang Thong",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาดหลวง",
              subdistrictNameEn: "Talat Luang",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "ศาลาแดง",
              subdistrictNameEn: "Sala Daeng",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "ป่างิ้ว",
              subdistrictNameEn: "Pa Ngio",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "บ้านแห",
              subdistrictNameEn: "Ban Hae",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "ตลาดกรวด",
              subdistrictNameEn: "Talat Kruat",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "มหาดไทย",
              subdistrictNameEn: "Mahatthai",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "บ้านอิฐ",
              subdistrictNameEn: "Ban It",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "หัวไผ่",
              subdistrictNameEn: "Hua Phai",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "จำปาหล่อ",
              subdistrictNameEn: "Champa Lo",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "โพสะ",
              subdistrictNameEn: "Pho Sa",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "บ้านรี",
              subdistrictNameEn: "Ban Ri",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "คลองวัว",
              subdistrictNameEn: "Khlong Wua",
              postalCode: 14000,
            },
            {
              subdistrictNameTh: "ย่านซื่อ",
              subdistrictNameEn: "Yan Sue",
              postalCode: 14000,
            },
          ],
        },
        {
          districtNameTh: "ไชโย",
          districtNameEn: "Chaiyo",
          subdistricts: [
            {
              subdistrictNameTh: "จรเข้ร้อง",
              subdistrictNameEn: "Chorakhe Rong",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "ไชยภูมิ",
              subdistrictNameEn: "Chaiyaphum",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "ชัยฤทธิ์",
              subdistrictNameEn: "Chaiyarit",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "เทวราช",
              subdistrictNameEn: "Thewarat",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "ราชสถิตย์",
              subdistrictNameEn: "Ratsathit",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "ไชโย",
              subdistrictNameEn: "Chaiyo",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "หลักฟ้า",
              subdistrictNameEn: "Lak Fa",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "ชะไว",
              subdistrictNameEn: "Chawai",
              postalCode: 14140,
            },
            {
              subdistrictNameTh: "ตรีณรงค์",
              subdistrictNameEn: "Tri Narong",
              postalCode: 14140,
            },
          ],
        },
        {
          districtNameTh: "ป่าโมก",
          districtNameEn: "Pa Mok",
          subdistricts: [
            {
              subdistrictNameTh: "บางปลากด",
              subdistrictNameEn: "Bang Pla Kot",
              postalCode: 14130,
            },
            {
              subdistrictNameTh: "ป่าโมก",
              subdistrictNameEn: "Pa Mok",
              postalCode: 14130,
            },
            {
              subdistrictNameTh: "สายทอง",
              subdistrictNameEn: "Sai Thong",
              postalCode: 14130,
            },
            {
              subdistrictNameTh: "โรงช้าง",
              subdistrictNameEn: "Rong Chang",
              postalCode: 14130,
            },
            {
              subdistrictNameTh: "บางเสด็จ",
              subdistrictNameEn: "Bang Sadet",
              postalCode: 14130,
            },
            {
              subdistrictNameTh: "นรสิงห์",
              subdistrictNameEn: "Norasing",
              postalCode: 14130,
            },
            {
              subdistrictNameTh: "เอกราช",
              subdistrictNameEn: "Ekkarat",
              postalCode: 14130,
            },
            {
              subdistrictNameTh: "โผงเผง",
              subdistrictNameEn: "Phong Pheng",
              postalCode: 14130,
            },
          ],
        },
        {
          districtNameTh: "โพธิ์ทอง",
          districtNameEn: "Pho Thong",
          subdistricts: [
            {
              subdistrictNameTh: "อ่างแก้ว",
              subdistrictNameEn: "Ang Kaeo",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "อินทประมูล",
              subdistrictNameEn: "Inthapramun",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "บางพลับ",
              subdistrictNameEn: "Bang Phlap",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "หนองแม่ไก่",
              subdistrictNameEn: "Nong Mae Kai",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "รำมะสัก",
              subdistrictNameEn: "Ram Masak",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "บางระกำ",
              subdistrictNameEn: "Bang Rakam",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "โพธิ์รังนก",
              subdistrictNameEn: "Pho Rang Nok",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "องครักษ์",
              subdistrictNameEn: "Ongkharak",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "โคกพุทรา",
              subdistrictNameEn: "Khok Phutsa",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "ยางช้าย",
              subdistrictNameEn: "Yang Chai",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "บ่อแร่",
              subdistrictNameEn: "Bo Rae",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "ทางพระ",
              subdistrictNameEn: "Thang Phra",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "สามง่าม",
              subdistrictNameEn: "Sam Ngam",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "บางเจ้าฉ่า",
              subdistrictNameEn: "Bang Chao Cha",
              postalCode: 14120,
            },
            {
              subdistrictNameTh: "คำหยาด",
              subdistrictNameEn: "Kham Yat",
              postalCode: 14120,
            },
          ],
        },
        {
          districtNameTh: "แสวงหา",
          districtNameEn: "Sawaeng Ha",
          subdistricts: [
            {
              subdistrictNameTh: "แสวงหา",
              subdistrictNameEn: "Sawaeng Ha",
              postalCode: 14150,
            },
            {
              subdistrictNameTh: "ศรีพราน",
              subdistrictNameEn: "Si Phran",
              postalCode: 14150,
            },
            {
              subdistrictNameTh: "บ้านพราน",
              subdistrictNameEn: "Ban Phran",
              postalCode: 14150,
            },
            {
              subdistrictNameTh: "วังน้ำเย็น",
              subdistrictNameEn: "Wang Nam Yen",
              postalCode: 14150,
            },
            {
              subdistrictNameTh: "สีบัวทอง",
              subdistrictNameEn: "Si Bua Thong",
              postalCode: 14150,
            },
            {
              subdistrictNameTh: "ห้วยไผ่",
              subdistrictNameEn: "Huai Phai",
              postalCode: 14150,
            },
            {
              subdistrictNameTh: "จำลอง",
              subdistrictNameEn: "Chamlong",
              postalCode: 14150,
            },
          ],
        },
        {
          districtNameTh: "วิเศษชัยชาญ",
          districtNameEn: "Wiset Chai Chan",
          subdistricts: [
            {
              subdistrictNameTh: "ไผ่จำศิล",
              subdistrictNameEn: "Phai Chamsin",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ศาลเจ้าโรงทอง",
              subdistrictNameEn: "San Chao Rong Thong",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ไผ่ดำพัฒนา",
              subdistrictNameEn: "Phai Dam Patthana",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "สาวร้องไห้",
              subdistrictNameEn: "Sao Rong Hai",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ยี่ล้น",
              subdistrictNameEn: "Yi Lon",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "บางจัก",
              subdistrictNameEn: "Bang Chak",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ห้วยคันแหลน",
              subdistrictNameEn: "Huai Khan Laen",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "คลองขนาก",
              subdistrictNameEn: "Khlong Khanak",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ไผ่วง",
              subdistrictNameEn: "Phai Wong",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "สี่ร้อย",
              subdistrictNameEn: "Si Roi",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ม่วงเตี้ย",
              subdistrictNameEn: "Muang Tia",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "หัวตะพาน",
              subdistrictNameEn: "Hua Taphan",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "หลักแก้ว",
              subdistrictNameEn: "Lak Kaeo",
              postalCode: 14110,
            },
            {
              subdistrictNameTh: "ตลาดใหม่",
              subdistrictNameEn: "Talat Mai",
              postalCode: 14110,
            },
          ],
        },
        {
          districtNameTh: "สามโก้",
          districtNameEn: "Samko",
          subdistricts: [
            {
              subdistrictNameTh: "สามโก้",
              subdistrictNameEn: "Sam Ko",
              postalCode: 14160,
            },
            {
              subdistrictNameTh: "ราษฎรพัฒนา",
              subdistrictNameEn: "Ratsadon Phatthana",
              postalCode: 14160,
            },
            {
              subdistrictNameTh: "อบทม",
              subdistrictNameEn: "Optom",
              postalCode: 14160,
            },
            {
              subdistrictNameTh: "โพธิ์ม่วงพันธ์",
              subdistrictNameEn: "Pho Muang Phan",
              postalCode: 14160,
            },
            {
              subdistrictNameTh: "มงคลธรรมนิมิต",
              subdistrictNameEn: "Mongkhontham Nimit",
              postalCode: 14160,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ลพบุรี",
      stateNameEn: "Loburi",
      districts: [
        {
          districtNameTh: "เมืองลพบุรี",
          districtNameEn: "Mueang Lop Buri",
          subdistricts: [
            {
              subdistrictNameTh: "ทะเลชุบศร",
              subdistrictNameEn: "Thale Chup Son",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ท่าหิน",
              subdistrictNameEn: "Tha Hin",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "กกโก",
              subdistrictNameEn: "Kok Ko",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "โก่งธนู",
              subdistrictNameEn: "Kong Thanu",
              postalCode: 13240,
            },
            {
              subdistrictNameTh: "เขาพระงาม",
              subdistrictNameEn: "Khao Phra Ngam",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "เขาสามยอด",
              subdistrictNameEn: "Khao Sam Yot",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "โคกกะเทียม",
              subdistrictNameEn: "Khok Kathiam",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "โคกลำพาน",
              subdistrictNameEn: "Khok Lamphan",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "โคกตูม",
              subdistrictNameEn: "Khok Tum",
              postalCode: 15210,
            },
            {
              subdistrictNameTh: "งิ้วราย",
              subdistrictNameEn: "Ngio Rai",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ดอนโพธิ์",
              subdistrictNameEn: "Don Pho",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ตะลุง",
              subdistrictNameEn: "Talung",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ท่าแค",
              subdistrictNameEn: "Tha Khae",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ท่าศาลา",
              subdistrictNameEn: "Tha Sala",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "นิคมสร้างตนเอง",
              subdistrictNameEn: "Nikhom Sang Ton-Eng",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "บางขันหมาก",
              subdistrictNameEn: "Bang Khan Mak",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "บ้านข่อย",
              subdistrictNameEn: "Ban Khoi",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ท้ายตลาด",
              subdistrictNameEn: "Thai Talat",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ป่าตาล",
              subdistrictNameEn: "Pa Tan",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "พรหมมาสตร์",
              subdistrictNameEn: "Phrommat",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "โพธิ์เก้าต้น",
              subdistrictNameEn: "Pho Kao Ton",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "โพธิ์ตรุ",
              subdistrictNameEn: "Pho Tru",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "สี่คลอง",
              subdistrictNameEn: "Si Khlong",
              postalCode: 15000,
            },
            {
              subdistrictNameTh: "ถนนใหญ่",
              subdistrictNameEn: "Thanon Yai",
              postalCode: 15000,
            },
          ],
        },
        {
          districtNameTh: "พัฒนานิคม",
          districtNameEn: "Phatthana Nikhom",
          subdistricts: [
            {
              subdistrictNameTh: "พัฒนานิคม",
              subdistrictNameEn: "Phatthana Nikhom",
              postalCode: 15140,
            },
            {
              subdistrictNameTh: "ช่องสาริกา",
              subdistrictNameEn: "Chong Sarika",
              postalCode: 15220,
            },
            {
              subdistrictNameTh: "มะนาวหวาน",
              subdistrictNameEn: "Manao Wan",
              postalCode: 15140,
            },
            {
              subdistrictNameTh: "ดีลัง",
              subdistrictNameEn: "Di Lang",
              postalCode: 15220,
            },
            {
              subdistrictNameTh: "โคกสลุง",
              subdistrictNameEn: "Khok Salung",
              postalCode: 15140,
            },
            {
              subdistrictNameTh: "ชอนน้อย",
              subdistrictNameEn: "Chon Noi",
              postalCode: 15140,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 15140,
            },
            {
              subdistrictNameTh: "ห้วยขุนราม",
              subdistrictNameEn: "Huai Khun Ram",
              postalCode: 18220,
            },
            {
              subdistrictNameTh: "น้ำสุด",
              subdistrictNameEn: "Nam Sut",
              postalCode: 15140,
            },
          ],
        },
        {
          districtNameTh: "โคกสำโรง",
          districtNameEn: "Khok Samrong",
          subdistricts: [
            {
              subdistrictNameTh: "โคกสำโรง",
              subdistrictNameEn: "Khok Samrong",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "เกาะแก้ว",
              subdistrictNameEn: "Ko Kaeo",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "ถลุงเหล็ก",
              subdistrictNameEn: "Thalung Lek",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "หลุมข้าว",
              subdistrictNameEn: "Lum Khao",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "ห้วยโป่ง",
              subdistrictNameEn: "Huai Pong",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "คลองเกตุ",
              subdistrictNameEn: "Khlong Ket",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "สะแกราบ",
              subdistrictNameEn: "Sakae Rap",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "เพนียด",
              subdistrictNameEn: "Phaniat",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "วังเพลิง",
              subdistrictNameEn: "Wang Phloeng",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "ดงมะรุม",
              subdistrictNameEn: "Dong Marum",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "วังขอนขว้าง",
              subdistrictNameEn: "Wang Khon Khwang",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "วังจั่น",
              subdistrictNameEn: "Wang Chan",
              postalCode: 15120,
            },
            {
              subdistrictNameTh: "หนองแขม",
              subdistrictNameEn: "Nong Khaem",
              postalCode: 15120,
            },
          ],
        },
        {
          districtNameTh: "ชัยบาดาล",
          districtNameEn: "Chai Badan",
          subdistricts: [
            {
              subdistrictNameTh: "ลำนารายณ์",
              subdistrictNameEn: "Lam Narai",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "ชัยนารายณ์",
              subdistrictNameEn: "Chai Narai",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "ศิลาทิพย์",
              subdistrictNameEn: "Sila Thip",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "ห้วยหิน",
              subdistrictNameEn: "Huai Hin",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "ม่วงค่อม",
              subdistrictNameEn: "Muang Khom",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "บัวชุม",
              subdistrictNameEn: "Bua Chum",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "ท่าดินดำ",
              subdistrictNameEn: "Tha Din Dam",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "มะกอกหวาน",
              subdistrictNameEn: "Makok Wan",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "ซับตะเคียน",
              subdistrictNameEn: "Sap Takhian",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "นาโสม",
              subdistrictNameEn: "Na Som",
              postalCode: 15190,
            },
            {
              subdistrictNameTh: "หนองยายโต๊ะ",
              subdistrictNameEn: "Nong Yai To",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "เกาะรัง",
              subdistrictNameEn: "Ko Rang",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "ท่ามะนาว",
              subdistrictNameEn: "Tha Manao",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "นิคมลำนารายณ์",
              subdistrictNameEn: "Nikhom Lam Narai",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "ชัยบาดาล",
              subdistrictNameEn: "Chai Badan",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "บ้านใหม่สามัคคี",
              subdistrictNameEn: "Ban Mai Samakkhi",
              postalCode: 15130,
            },
            {
              subdistrictNameTh: "เขาแหลม",
              subdistrictNameEn: "Khao Laem",
              postalCode: 15130,
            },
          ],
        },
        {
          districtNameTh: "ท่าวุ้ง",
          districtNameEn: "Tha Wung",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าวุ้ง",
              subdistrictNameEn: "Tha Wung",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "บางคู้",
              subdistrictNameEn: "Bang Khu",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "โพตลาดแก้ว",
              subdistrictNameEn: "Pho Talat Kaeo",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "บางลี่",
              subdistrictNameEn: "Bang Li",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "บางงา",
              subdistrictNameEn: "Bang Nga",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "โคกสลุด",
              subdistrictNameEn: "Khok Salut",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "เขาสมอคอน",
              subdistrictNameEn: "Khao Samo Khon",
              postalCode: 15180,
            },
            {
              subdistrictNameTh: "หัวสำโรง",
              subdistrictNameEn: "Hua Samrong",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "ลาดสาลี่",
              subdistrictNameEn: "Lat Sali",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "บ้านเบิก",
              subdistrictNameEn: "Ban Boek",
              postalCode: 15150,
            },
            {
              subdistrictNameTh: "มุจลินท์",
              subdistrictNameEn: "Mutchalin",
              postalCode: 15150,
            },
          ],
        },
        {
          districtNameTh: "บ้านหมี่",
          districtNameEn: "Ban Mi",
          subdistricts: [
            {
              subdistrictNameTh: "ไผ่ใหญ่",
              subdistrictNameEn: "Phai Yai",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "บ้านทราย",
              subdistrictNameEn: "Ban Sai",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "บ้านกล้วย",
              subdistrictNameEn: "Ban Kluai",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "ดงพลับ",
              subdistrictNameEn: "Dong Phlap",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "บ้านชี",
              subdistrictNameEn: "Ban Chi",
              postalCode: 15180,
            },
            {
              subdistrictNameTh: "พุคา",
              subdistrictNameEn: "Phu Kha",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "หินปัก",
              subdistrictNameEn: "Hin Pak",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "บางพึ่ง",
              subdistrictNameEn: "Bang Phueng",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "หนองทรายขาว",
              subdistrictNameEn: "Nong Sai Khao",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "บางกะพี้",
              subdistrictNameEn: "Bang Kaphi",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "หนองเต่า",
              subdistrictNameEn: "Nong Tao",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "บางขาม",
              subdistrictNameEn: "Bang Kham",
              postalCode: 15180,
            },
            {
              subdistrictNameTh: "ดอนดึง",
              subdistrictNameEn: "Don Dueng",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "ชอนม่วง",
              subdistrictNameEn: "Chon Muang",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "หนองกระเบียน",
              subdistrictNameEn: "Nong Krabian",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "สายห้วยแก้ว",
              subdistrictNameEn: "Sai Huai Kaeo",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "มหาสอน",
              subdistrictNameEn: "Mahason",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "บ้านหมี่",
              subdistrictNameEn: "Ban Mi",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "เชียงงา",
              subdistrictNameEn: "Chiang Nga",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "หนองเมือง",
              subdistrictNameEn: "Nong Mueang",
              postalCode: 15110,
            },
            {
              subdistrictNameTh: "สนามแจง",
              subdistrictNameEn: "Sanam Chaeng",
              postalCode: 15110,
            },
          ],
        },
        {
          districtNameTh: "ท่าหลวง",
          districtNameEn: "Tha Luang",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าหลวง",
              subdistrictNameEn: "Tha Luang",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "แก่งผักกูด",
              subdistrictNameEn: "Kaeng Phak Kut",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "ซับจำปา",
              subdistrictNameEn: "Sap Champa",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "หนองผักแว่น",
              subdistrictNameEn: "Nong Phak Waen",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "ทะเลวังวัด",
              subdistrictNameEn: "Thale Wang Wat",
              postalCode: 15230,
            },
            {
              subdistrictNameTh: "หัวลำ",
              subdistrictNameEn: "Hua Lam",
              postalCode: 15230,
            },
          ],
        },
        {
          districtNameTh: "สระโบสถ์",
          districtNameEn: "Sa Bot",
          subdistricts: [
            {
              subdistrictNameTh: "สระโบสถ์",
              subdistrictNameEn: "Sa Bot",
              postalCode: 15240,
            },
            {
              subdistrictNameTh: "มหาโพธิ",
              subdistrictNameEn: "Mahapho",
              postalCode: 15240,
            },
            {
              subdistrictNameTh: "ทุ่งท่าช้าง",
              subdistrictNameEn: "Thung Tha Chang",
              postalCode: 15240,
            },
            {
              subdistrictNameTh: "ห้วยใหญ่",
              subdistrictNameEn: "Huai Yai",
              postalCode: 15240,
            },
            {
              subdistrictNameTh: "นิยมชัย",
              subdistrictNameEn: "Niyom Chai",
              postalCode: 15240,
            },
          ],
        },
        {
          districtNameTh: "โคกเจริญ",
          districtNameEn: "Khok Charoen",
          subdistricts: [
            {
              subdistrictNameTh: "โคกเจริญ",
              subdistrictNameEn: "Khok Charoen",
              postalCode: 15250,
            },
            {
              subdistrictNameTh: "ยางราก",
              subdistrictNameEn: "Yang Rak",
              postalCode: 15250,
            },
            {
              subdistrictNameTh: "หนองมะค่า",
              subdistrictNameEn: "Nong Makha",
              postalCode: 15250,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 15250,
            },
            {
              subdistrictNameTh: "โคกแสมสาร",
              subdistrictNameEn: "Khok Samae San",
              postalCode: 15250,
            },
          ],
        },
        {
          districtNameTh: "ลำสนธิ",
          districtNameEn: "Lam Sonthi",
          subdistricts: [
            {
              subdistrictNameTh: "ลำสนธิ",
              subdistrictNameEn: "Lam Sonthi",
              postalCode: 15190,
            },
            {
              subdistrictNameTh: "ซับสมบูรณ์",
              subdistrictNameEn: "Sap Sombun",
              postalCode: 15190,
            },
            {
              subdistrictNameTh: "หนองรี",
              subdistrictNameEn: "Nong Ri",
              postalCode: 15190,
            },
            {
              subdistrictNameTh: "กุดตาเพชร",
              subdistrictNameEn: "Kut Ta Phet",
              postalCode: 15190,
            },
            {
              subdistrictNameTh: "เขารวก",
              subdistrictNameEn: "Khao Ruak",
              postalCode: 15190,
            },
            {
              subdistrictNameTh: "เขาน้อย",
              subdistrictNameEn: "Khao Noi",
              postalCode: 15130,
            },
          ],
        },
        {
          districtNameTh: "หนองม่วง",
          districtNameEn: "Nong Muang",
          subdistricts: [
            {
              subdistrictNameTh: "หนองม่วง",
              subdistrictNameEn: "Nong Muang",
              postalCode: 15170,
            },
            {
              subdistrictNameTh: "บ่อทอง",
              subdistrictNameEn: "Bo Thong",
              postalCode: 15170,
            },
            {
              subdistrictNameTh: "ดงดินแดง",
              subdistrictNameEn: "Dong Din Daeng",
              postalCode: 15170,
            },
            {
              subdistrictNameTh: "ชอนสมบูรณ์",
              subdistrictNameEn: "Chon Sombun",
              postalCode: 15170,
            },
            {
              subdistrictNameTh: "ยางโทน",
              subdistrictNameEn: "Yang Thon",
              postalCode: 15170,
            },
            {
              subdistrictNameTh: "ชอนสารเดช",
              subdistrictNameEn: "Chon Saradet",
              postalCode: 15170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สิงห์บุรี",
      stateNameEn: "Sing Buri",
      districts: [
        {
          districtNameTh: "เมืองสิงห์บุรี",
          districtNameEn: "Mueang Sing Buri",
          subdistricts: [
            {
              subdistrictNameTh: "บางพุทรา",
              subdistrictNameEn: "Bang Phutsa",
              postalCode: 16000,
            },
            {
              subdistrictNameTh: "บางมัญ",
              subdistrictNameEn: "Bang Man",
              postalCode: 16000,
            },
            {
              subdistrictNameTh: "โพกรวม",
              subdistrictNameEn: "Phok Ruam",
              postalCode: 16000,
            },
            {
              subdistrictNameTh: "ม่วงหมู่",
              subdistrictNameEn: "Muang Mu",
              postalCode: 16000,
            },
            {
              subdistrictNameTh: "หัวไผ่",
              subdistrictNameEn: "Hua Phai",
              postalCode: 16000,
            },
            {
              subdistrictNameTh: "ต้นโพธิ์",
              subdistrictNameEn: "Ton Pho",
              postalCode: 16000,
            },
            {
              subdistrictNameTh: "จักรสีห์",
              subdistrictNameEn: "Chaksi",
              postalCode: 16000,
            },
            {
              subdistrictNameTh: "บางกระบือ",
              subdistrictNameEn: "Bang Krabue",
              postalCode: 16000,
            },
          ],
        },
        {
          districtNameTh: "บางระจัน",
          districtNameEn: "Bang Rachan",
          subdistricts: [
            {
              subdistrictNameTh: "สิงห์",
              subdistrictNameEn: "Sing",
              postalCode: 16130,
            },
            {
              subdistrictNameTh: "ไม้ดัด",
              subdistrictNameEn: "Mai Dat",
              postalCode: 16130,
            },
            {
              subdistrictNameTh: "เชิงกลัด",
              subdistrictNameEn: "Choeng Klat",
              postalCode: 16130,
            },
            {
              subdistrictNameTh: "โพชนไก่",
              subdistrictNameEn: "Pho Chon Kai",
              postalCode: 16130,
            },
            {
              subdistrictNameTh: "แม่ลา",
              subdistrictNameEn: "Mae La",
              postalCode: 16130,
            },
            {
              subdistrictNameTh: "บ้านจ่า",
              subdistrictNameEn: "Ban Cha",
              postalCode: 16130,
            },
            {
              subdistrictNameTh: "พักทัน",
              subdistrictNameEn: "Phak Than",
              postalCode: 16130,
            },
            {
              subdistrictNameTh: "สระแจง",
              subdistrictNameEn: "Sa Chaeng",
              postalCode: 16130,
            },
          ],
        },
        {
          districtNameTh: "ค่ายบางระจัน",
          districtNameEn: "Khai Bang Rachan",
          subdistricts: [
            {
              subdistrictNameTh: "โพทะเล",
              subdistrictNameEn: "Pho Thale",
              postalCode: 16150,
            },
            {
              subdistrictNameTh: "บางระจัน",
              subdistrictNameEn: "Bang Rachan",
              postalCode: 16150,
            },
            {
              subdistrictNameTh: "โพสังโฆ",
              subdistrictNameEn: "Pho Sangkho",
              postalCode: 16150,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 16150,
            },
            {
              subdistrictNameTh: "คอทราย",
              subdistrictNameEn: "Kho Sai",
              postalCode: 16150,
            },
            {
              subdistrictNameTh: "หนองกระทุ่ม",
              subdistrictNameEn: "Nong Krathum",
              postalCode: 16150,
            },
          ],
        },
        {
          districtNameTh: "พรหมบุรี",
          districtNameEn: "Phrom Buri",
          subdistricts: [
            {
              subdistrictNameTh: "พระงาม",
              subdistrictNameEn: "Phra Ngam",
              postalCode: 16120,
            },
            {
              subdistrictNameTh: "พรหมบุรี",
              subdistrictNameEn: "Phrom Buri",
              postalCode: 16160,
            },
            {
              subdistrictNameTh: "บางน้ำเชี่ยว",
              subdistrictNameEn: "Bang Nam Chiao",
              postalCode: 16120,
            },
            {
              subdistrictNameTh: "บ้านหม้อ",
              subdistrictNameEn: "Ban Mo",
              postalCode: 16120,
            },
            {
              subdistrictNameTh: "บ้านแป้ง",
              subdistrictNameEn: "Ban Paeng",
              postalCode: 16120,
            },
            {
              subdistrictNameTh: "หัวป่า",
              subdistrictNameEn: "Hua Pa",
              postalCode: 16120,
            },
            {
              subdistrictNameTh: "โรงช้าง",
              subdistrictNameEn: "Rong Chang",
              postalCode: 16120,
            },
          ],
        },
        {
          districtNameTh: "ท่าช้าง",
          districtNameEn: "Tha Chang",
          subdistricts: [
            {
              subdistrictNameTh: "ถอนสมอ",
              subdistrictNameEn: "Thon Samo",
              postalCode: 16140,
            },
            {
              subdistrictNameTh: "โพประจักษ์",
              subdistrictNameEn: "Pho Prachak",
              postalCode: 16140,
            },
            {
              subdistrictNameTh: "วิหารขาว",
              subdistrictNameEn: "Wihan Khao",
              postalCode: 16140,
            },
            {
              subdistrictNameTh: "พิกุลทอง",
              subdistrictNameEn: "Phikun Thong",
              postalCode: 16140,
            },
          ],
        },
        {
          districtNameTh: "อินทร์บุรี",
          districtNameEn: "In Buri",
          subdistricts: [
            {
              subdistrictNameTh: "อินทร์บุรี",
              subdistrictNameEn: "In Buri",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "ประศุก",
              subdistrictNameEn: "Prasuk",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "ทับยา",
              subdistrictNameEn: "Thap Ya",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "งิ้วราย",
              subdistrictNameEn: "Ngio Rai",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "ชีน้ำร้าย",
              subdistrictNameEn: "Chi Nam Rai",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "ท่างาม",
              subdistrictNameEn: "Tha Ngam",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "น้ำตาล",
              subdistrictNameEn: "Namtan",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "ทองเอน",
              subdistrictNameEn: "Thong En",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "ห้วยชัน",
              subdistrictNameEn: "Huai Chan",
              postalCode: 16110,
            },
            {
              subdistrictNameTh: "โพธิ์ชัย",
              subdistrictNameEn: "Pho Chai",
              postalCode: 16110,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ชัยนาท",
      stateNameEn: "Chai Nat",
      districts: [
        {
          districtNameTh: "เมืองชัยนาท",
          districtNameEn: "Mueang Chai Nat",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "บ้านกล้วย",
              subdistrictNameEn: "Ban Kluai",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "ท่าชัย",
              subdistrictNameEn: "Tha Chai",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "ชัยนาท",
              subdistrictNameEn: "Chai Nat",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "เขาท่าพระ",
              subdistrictNameEn: "Khao Tha Phra",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "หาดท่าเสา",
              subdistrictNameEn: "Hat Tha Sao",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "ธรรมามูล",
              subdistrictNameEn: "Thammamun",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "เสือโฮก",
              subdistrictNameEn: "Suea Hok",
              postalCode: 17000,
            },
            {
              subdistrictNameTh: "นางลือ",
              subdistrictNameEn: "Nang Lue",
              postalCode: 17000,
            },
          ],
        },
        {
          districtNameTh: "มโนรมย์",
          districtNameEn: "Manorom",
          subdistricts: [
            {
              subdistrictNameTh: "คุ้งสำเภา",
              subdistrictNameEn: "Khung Samphao",
              postalCode: 17110,
            },
            {
              subdistrictNameTh: "วัดโคก",
              subdistrictNameEn: "Wat Khok",
              postalCode: 17110,
            },
            {
              subdistrictNameTh: "ศิลาดาน",
              subdistrictNameEn: "Sila Dan",
              postalCode: 17110,
            },
            {
              subdistrictNameTh: "ท่าฉนวน",
              subdistrictNameEn: "Tha Chanuan",
              postalCode: 17110,
            },
            {
              subdistrictNameTh: "หางน้ำสาคร",
              subdistrictNameEn: "Hang Nam Sakhon",
              postalCode: 17170,
            },
            {
              subdistrictNameTh: "ไร่พัฒนา",
              subdistrictNameEn: "Rai Phatthana",
              postalCode: 17170,
            },
            {
              subdistrictNameTh: "อู่ตะเภา",
              subdistrictNameEn: "U Taphao",
              postalCode: 17170,
            },
          ],
        },
        {
          districtNameTh: "วัดสิงห์",
          districtNameEn: "Wat Sing",
          subdistricts: [
            {
              subdistrictNameTh: "วัดสิงห์",
              subdistrictNameEn: "Wat Sing",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "มะขามเฒ่า",
              subdistrictNameEn: "Makham Thao",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "หนองน้อย",
              subdistrictNameEn: "Nong Noi",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "หนองขุ่น",
              subdistrictNameEn: "Nong Khun",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "บ่อแร่",
              subdistrictNameEn: "Bo Rae",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "วังหมัน",
              subdistrictNameEn: "Wang Man",
              postalCode: 17120,
            },
          ],
        },
        {
          districtNameTh: "สรรพยา",
          districtNameEn: "Sapphaya",
          subdistricts: [
            {
              subdistrictNameTh: "สรรพยา",
              subdistrictNameEn: "Sapphaya",
              postalCode: 17150,
            },
            {
              subdistrictNameTh: "ตลุก",
              subdistrictNameEn: "Taluk",
              postalCode: 17150,
            },
            {
              subdistrictNameTh: "เขาแก้ว",
              subdistrictNameEn: "Khao Kaeo",
              postalCode: 17150,
            },
            {
              subdistrictNameTh: "โพนางดำตก",
              subdistrictNameEn: "Pho Nang Dam Tok",
              postalCode: 17150,
            },
            {
              subdistrictNameTh: "โพนางดำออก",
              subdistrictNameEn: "Pho Nang Dam Ok",
              postalCode: 17150,
            },
            {
              subdistrictNameTh: "บางหลวง",
              subdistrictNameEn: "Bang Luang",
              postalCode: 17150,
            },
            {
              subdistrictNameTh: "หาดอาษา",
              subdistrictNameEn: "Hat A Sa",
              postalCode: 17150,
            },
          ],
        },
        {
          districtNameTh: "สรรคบุรี",
          districtNameEn: "Sankhaburi",
          subdistricts: [
            {
              subdistrictNameTh: "แพรกศรีราชา",
              subdistrictNameEn: "Phraek Si Racha",
              postalCode: 17140,
            },
            {
              subdistrictNameTh: "เที่ยงแท้",
              subdistrictNameEn: "Thiang Thae",
              postalCode: 17140,
            },
            {
              subdistrictNameTh: "ห้วยกรด",
              subdistrictNameEn: "Huai Krot",
              postalCode: 17140,
            },
            {
              subdistrictNameTh: "โพงาม",
              subdistrictNameEn: "Pho Ngam",
              postalCode: 17140,
            },
            {
              subdistrictNameTh: "บางขุด",
              subdistrictNameEn: "Bang Khut",
              postalCode: 17140,
            },
            {
              subdistrictNameTh: "ดงคอน",
              subdistrictNameEn: "Dong Khon",
              postalCode: 17140,
            },
            {
              subdistrictNameTh: "ดอนกำ",
              subdistrictNameEn: "Don Kam",
              postalCode: 17140,
            },
            {
              subdistrictNameTh: "ห้วยกรดพัฒนา",
              subdistrictNameEn: "Huai Krot Phatthana",
              postalCode: 17140,
            },
          ],
        },
        {
          districtNameTh: "หันคา",
          districtNameEn: "Hankha",
          subdistricts: [
            {
              subdistrictNameTh: "หันคา",
              subdistrictNameEn: "Han Kha",
              postalCode: 17130,
            },
            {
              subdistrictNameTh: "บ้านเชี่ยน",
              subdistrictNameEn: "Ban Chian",
              postalCode: 17130,
            },
            {
              subdistrictNameTh: "ไพรนกยูง",
              subdistrictNameEn: "Phrai Nok Yung",
              postalCode: 17130,
            },
            {
              subdistrictNameTh: "หนองแซง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 17160,
            },
            {
              subdistrictNameTh: "ห้วยงู",
              subdistrictNameEn: "Huai Ngu",
              postalCode: 17160,
            },
            {
              subdistrictNameTh: "วังไก่เถื่อน",
              subdistrictNameEn: "Wang Kai Thuean",
              postalCode: 17130,
            },
            {
              subdistrictNameTh: "เด่นใหญ่",
              subdistrictNameEn: "Den Yai",
              postalCode: 17130,
            },
            {
              subdistrictNameTh: "สามง่ามท่าโบสถ์",
              subdistrictNameEn: "Sam Ngam Tha Bot",
              postalCode: 17160,
            },
          ],
        },
        {
          districtNameTh: "หนองมะโมง",
          districtNameEn: "Nong Mamong",
          subdistricts: [
            {
              subdistrictNameTh: "หนองมะโมง",
              subdistrictNameEn: "Nong Mamong",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "วังตะเคียน",
              subdistrictNameEn: "Wang Takhian",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "สะพานหิน",
              subdistrictNameEn: "Saphan Hin",
              postalCode: 17120,
            },
            {
              subdistrictNameTh: "กุดจอก",
              subdistrictNameEn: "Kut Chok",
              postalCode: 17120,
            },
          ],
        },
        {
          districtNameTh: "เนินขาม",
          districtNameEn: "Noen Kham",
          subdistricts: [
            {
              subdistrictNameTh: "เนินขาม",
              subdistrictNameEn: "Noen Kham",
              postalCode: 17130,
            },
            {
              subdistrictNameTh: "กะบกเตี้ย",
              subdistrictNameEn: "Kabok Tia",
              postalCode: 17130,
            },
            {
              subdistrictNameTh: "สุขเดือนห้า",
              subdistrictNameEn: "Suk Duean Ha",
              postalCode: 17130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สระบุรี",
      stateNameEn: "Saraburi",
      districts: [
        {
          districtNameTh: "เมืองสระบุรี",
          districtNameEn: "Mueang Saraburi",
          subdistricts: [
            {
              subdistrictNameTh: "ปากเพรียว",
              subdistrictNameEn: "Pak Phriao",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "ดาวเรือง",
              subdistrictNameEn: "Dao Rueang",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "นาโฉง",
              subdistrictNameEn: "Na Chong",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "โคกสว่าง",
              subdistrictNameEn: "Khok Sawang",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "หนองโน",
              subdistrictNameEn: "Nong No",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "หนองยาว",
              subdistrictNameEn: "Nong Yao",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "ปากข้าวสาร",
              subdistrictNameEn: "Pak Khao San",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "หนองปลาไหล",
              subdistrictNameEn: "Nong Pla Lai",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "กุดนกเปล้า",
              subdistrictNameEn: "Kut Nok Plao",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "ตะกุด",
              subdistrictNameEn: "Takut",
              postalCode: 18000,
            },
          ],
        },
        {
          districtNameTh: "แก่งคอย",
          districtNameEn: "Kaeng Khoi",
          subdistricts: [
            {
              subdistrictNameTh: "แก่งคอย",
              subdistrictNameEn: "Kaeng Khoi",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "ทับกวาง",
              subdistrictNameEn: "Thap Kwang",
              postalCode: 18260,
            },
            {
              subdistrictNameTh: "ตาลเดี่ยว",
              subdistrictNameEn: "Tan Diao",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "ห้วยแห้ง",
              subdistrictNameEn: "Huai Haeng",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "ท่าคล้อ",
              subdistrictNameEn: "Tha Khlo",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "หินซ้อน",
              subdistrictNameEn: "Hin Son",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "บ้านธาตุ",
              subdistrictNameEn: "Ban That",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "บ้านป่า",
              subdistrictNameEn: "Ban Pa",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "ท่าตูม",
              subdistrictNameEn: "Tha Tum",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "ชะอม",
              subdistrictNameEn: "Cha-Om",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "สองคอน",
              subdistrictNameEn: "Song Khon",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "เตาปูน",
              subdistrictNameEn: "Tao Pun",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "ชำผักแพว",
              subdistrictNameEn: "Cham Phak Phaeo",
              postalCode: 18110,
            },
            {
              subdistrictNameTh: "ท่ามะปราง",
              subdistrictNameEn: "Tha Maprang",
              postalCode: 18110,
            },
          ],
        },
        {
          districtNameTh: "หนองแค",
          districtNameEn: "Nong Khae",
          subdistricts: [
            {
              subdistrictNameTh: "หนองแค",
              subdistrictNameEn: "Nong Khae",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "กุ่มหัก",
              subdistrictNameEn: "Kum Hak",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "คชสิทธิ์",
              subdistrictNameEn: "Khotchasit",
              postalCode: 18250,
            },
            {
              subdistrictNameTh: "โคกตูม",
              subdistrictNameEn: "Khok Tum",
              postalCode: 18250,
            },
            {
              subdistrictNameTh: "โคกแย้",
              subdistrictNameEn: "Khok Yae",
              postalCode: 18230,
            },
            {
              subdistrictNameTh: "บัวลอย",
              subdistrictNameEn: "Bua Loi",
              postalCode: 18230,
            },
            {
              subdistrictNameTh: "ไผ่ต่ำ",
              subdistrictNameEn: "Phai Tam",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 18250,
            },
            {
              subdistrictNameTh: "ห้วยขมิ้น",
              subdistrictNameEn: "Huai Khamin",
              postalCode: 18230,
            },
            {
              subdistrictNameTh: "ห้วยทราย",
              subdistrictNameEn: "Huai Sai",
              postalCode: 18230,
            },
            {
              subdistrictNameTh: "หนองไข่น้ำ",
              subdistrictNameEn: "Nong Khai Nam",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "หนองแขม",
              subdistrictNameEn: "Nong Khaem",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "หนองจิก",
              subdistrictNameEn: "Nong Chik",
              postalCode: 18230,
            },
            {
              subdistrictNameTh: "หนองจรเข้",
              subdistrictNameEn: "Nong Chorakhe",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "หนองนาก",
              subdistrictNameEn: "Nong Nak",
              postalCode: 18230,
            },
            {
              subdistrictNameTh: "หนองปลาหมอ",
              subdistrictNameEn: "Nong Pla Mo",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "หนองปลิง",
              subdistrictNameEn: "Nong Pling",
              postalCode: 18140,
            },
            {
              subdistrictNameTh: "หนองโรง",
              subdistrictNameEn: "Nong Rong",
              postalCode: 18140,
            },
          ],
        },
        {
          districtNameTh: "วิหารแดง",
          districtNameEn: "Wihan Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "หนองหมู",
              subdistrictNameEn: "Nong Mu",
              postalCode: 18150,
            },
            {
              subdistrictNameTh: "บ้านลำ",
              subdistrictNameEn: "Ban Lam",
              postalCode: 18150,
            },
            {
              subdistrictNameTh: "คลองเรือ",
              subdistrictNameEn: "Khlong Ruea",
              postalCode: 18150,
            },
            {
              subdistrictNameTh: "วิหารแดง",
              subdistrictNameEn: "Wihan Daeng",
              postalCode: 18150,
            },
            {
              subdistrictNameTh: "หนองสรวง",
              subdistrictNameEn: "Nong Suang",
              postalCode: 18150,
            },
            {
              subdistrictNameTh: "เจริญธรรม",
              subdistrictNameEn: "Charoen Tham",
              postalCode: 18150,
            },
          ],
        },
        {
          districtNameTh: "หนองแซง",
          districtNameEn: "Nong Saeng",
          subdistricts: [
            {
              subdistrictNameTh: "หนองแซง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "หนองควายโซ",
              subdistrictNameEn: "Nong Khwai So",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "หนองหัวโพ",
              subdistrictNameEn: "Nong Hua Pho",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "หนองสีดา",
              subdistrictNameEn: "Nong Sida",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "หนองกบ",
              subdistrictNameEn: "Nong Kop",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "ไก่เส่า",
              subdistrictNameEn: "Kai Sao",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "ม่วงหวาน",
              subdistrictNameEn: "Muang Wan",
              postalCode: 18170,
            },
            {
              subdistrictNameTh: "เขาดิน",
              subdistrictNameEn: "Khao Din",
              postalCode: 18170,
            },
          ],
        },
        {
          districtNameTh: "บ้านหมอ",
          districtNameEn: "Ban Mo",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านหมอ",
              subdistrictNameEn: "Ban Mo",
              postalCode: 18130,
            },
            {
              subdistrictNameTh: "บางโขมด",
              subdistrictNameEn: "Bang Khamot",
              postalCode: 18130,
            },
            {
              subdistrictNameTh: "สร่างโศก",
              subdistrictNameEn: "Sang Sok",
              postalCode: 18130,
            },
            {
              subdistrictNameTh: "ตลาดน้อย",
              subdistrictNameEn: "Talat Noi",
              postalCode: 18130,
            },
            {
              subdistrictNameTh: "หรเทพ",
              subdistrictNameEn: "Horathep",
              postalCode: 18130,
            },
            {
              subdistrictNameTh: "โคกใหญ่",
              subdistrictNameEn: "Khok Yai",
              postalCode: 18130,
            },
            {
              subdistrictNameTh: "ไผ่ขวาง",
              subdistrictNameEn: "Phai Khwang",
              postalCode: 18130,
            },
            {
              subdistrictNameTh: "บ้านครัว",
              subdistrictNameEn: "Ban Khrua",
              postalCode: 18270,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 18130,
            },
          ],
        },
        {
          districtNameTh: "ดอนพุด",
          districtNameEn: "Don Phut",
          subdistricts: [
            {
              subdistrictNameTh: "ดอนพุด",
              subdistrictNameEn: "Don Phut",
              postalCode: 18210,
            },
            {
              subdistrictNameTh: "ไผ่หลิ่ว",
              subdistrictNameEn: "Phai Lio",
              postalCode: 18210,
            },
            {
              subdistrictNameTh: "บ้านหลวง",
              subdistrictNameEn: "Ban Luang",
              postalCode: 18210,
            },
            {
              subdistrictNameTh: "ดงตะงาว",
              subdistrictNameEn: "Dong Ta-Ngao",
              postalCode: 18210,
            },
          ],
        },
        {
          districtNameTh: "หนองโดน",
          districtNameEn: "Nong Don",
          subdistricts: [
            {
              subdistrictNameTh: "หนองโดน",
              subdistrictNameEn: "Nong Don",
              postalCode: 18190,
            },
            {
              subdistrictNameTh: "บ้านกลับ",
              subdistrictNameEn: "Ban Klap",
              postalCode: 18190,
            },
            {
              subdistrictNameTh: "ดอนทอง",
              subdistrictNameEn: "Don Thong",
              postalCode: 18190,
            },
            {
              subdistrictNameTh: "บ้านโปร่ง",
              subdistrictNameEn: "Ban Prong",
              postalCode: 18190,
            },
          ],
        },
        {
          districtNameTh: "พระพุทธบาท",
          districtNameEn: "Phra Phutthabat",
          subdistricts: [
            {
              subdistrictNameTh: "พระพุทธบาท",
              subdistrictNameEn: "Phraphutthabat",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "ขุนโขลน",
              subdistrictNameEn: "Khun Khlon",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "ธารเกษม",
              subdistrictNameEn: "Than Kasem",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "นายาว",
              subdistrictNameEn: "Na Yao",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "พุคำจาน",
              subdistrictNameEn: "Phu Kham Chan",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "เขาวง",
              subdistrictNameEn: "Khao Wong",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "ห้วยป่าหวาย",
              subdistrictNameEn: "Huai Pa Wai",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "พุกร่าง",
              subdistrictNameEn: "Phu Krang",
              postalCode: 18120,
            },
            {
              subdistrictNameTh: "หนองแก",
              subdistrictNameEn: "Nong Kae",
              postalCode: 18120,
            },
          ],
        },
        {
          districtNameTh: "เสาไห้",
          districtNameEn: "Sao Hai",
          subdistricts: [
            {
              subdistrictNameTh: "เสาไห้",
              subdistrictNameEn: "Sao Hai",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "หัวปลวก",
              subdistrictNameEn: "Hua Pluak",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "งิ้วงาม",
              subdistrictNameEn: "Ngio Ngam",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "ศาลารีไทย",
              subdistrictNameEn: "Sala Ri Thai",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "ต้นตาล",
              subdistrictNameEn: "Ton Tan",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "พระยาทด",
              subdistrictNameEn: "Phraya Thot",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "ม่วงงาม",
              subdistrictNameEn: "Muang Ngam",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "เริงราง",
              subdistrictNameEn: "Roeng Rang",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "เมืองเก่า",
              subdistrictNameEn: "Mueang Kao",
              postalCode: 18160,
            },
            {
              subdistrictNameTh: "สวนดอกไม้",
              subdistrictNameEn: "Suan Dokmai",
              postalCode: 18160,
            },
          ],
        },
        {
          districtNameTh: "มวกเหล็ก",
          districtNameEn: "Muak Lek",
          subdistricts: [
            {
              subdistrictNameTh: "มวกเหล็ก",
              subdistrictNameEn: "Muak Lek",
              postalCode: 18180,
            },
            {
              subdistrictNameTh: "มิตรภาพ",
              subdistrictNameEn: "Mittraphap",
              postalCode: 18180,
            },
            {
              subdistrictNameTh: "หนองย่างเสือ",
              subdistrictNameEn: "Nong Yang Suea",
              postalCode: 18180,
            },
            {
              subdistrictNameTh: "ลำสมพุง",
              subdistrictNameEn: "Lam Somphung",
              postalCode: 18180,
            },
            {
              subdistrictNameTh: "ลำพญากลาง",
              subdistrictNameEn: "Lam Phaya Klang",
              postalCode: 18180,
            },
            {
              subdistrictNameTh: "ซับสนุ่น",
              subdistrictNameEn: "Sap Sanun",
              postalCode: 18220,
            },
          ],
        },
        {
          districtNameTh: "วังม่วง",
          districtNameEn: "Wang Muang",
          subdistricts: [
            {
              subdistrictNameTh: "แสลงพัน",
              subdistrictNameEn: "Salaeng Phan",
              postalCode: 18220,
            },
            {
              subdistrictNameTh: "คำพราน",
              subdistrictNameEn: "Kham Phran",
              postalCode: 18220,
            },
            {
              subdistrictNameTh: "วังม่วง",
              subdistrictNameEn: "Wang Muang",
              postalCode: 18220,
            },
          ],
        },
        {
          districtNameTh: "เฉลิมพระเกียรติ",
          districtNameEn: "Chaloem Phra Kiet",
          subdistricts: [
            {
              subdistrictNameTh: "เขาดินพัฒนา",
              subdistrictNameEn: "Khao Din Phatthana",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "บ้านแก้ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "ผึ้งรวง",
              subdistrictNameEn: "Phueng Ruang",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "พุแค",
              subdistrictNameEn: "Phu Khae",
              postalCode: 18240,
            },
            {
              subdistrictNameTh: "ห้วยบง",
              subdistrictNameEn: "Huai Bong",
              postalCode: 18000,
            },
            {
              subdistrictNameTh: "หน้าพระลาน",
              subdistrictNameEn: "Na Phralan",
              postalCode: 18240,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ชลบุรี",
      stateNameEn: "Chon Buri",
      districts: [
        {
          districtNameTh: "เมืองชลบุรี",
          districtNameEn: "Mueang Chon Buri",
          subdistricts: [
            {
              subdistrictNameTh: "บางปลาสร้อย",
              subdistrictNameEn: "Bang Pla Soi",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "มะขามหย่ง",
              subdistrictNameEn: "Makham Yong",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "บ้านโขด",
              subdistrictNameEn: "Ban Khot",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "แสนสุข",
              subdistrictNameEn: "Saen Suk",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "บ้านสวน",
              subdistrictNameEn: "Ban Suan",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "หนองรี",
              subdistrictNameEn: "Nong Ri",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "นาป่า",
              subdistrictNameEn: "Na Pa",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "หนองข้างคอก",
              subdistrictNameEn: "Nong Khang Khok",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "ดอนหัวฬ่อ",
              subdistrictNameEn: "Don Hua Lo",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "หนองไม้แดง",
              subdistrictNameEn: "Nong Mai Daeng",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "บางทราย",
              subdistrictNameEn: "Bang Sai",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "คลองตำหรุ",
              subdistrictNameEn: "Khlong Tamru",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "เหมือง",
              subdistrictNameEn: "Mueang",
              postalCode: 20130,
            },
            {
              subdistrictNameTh: "บ้านปึก",
              subdistrictNameEn: "Ban Puek",
              postalCode: 20130,
            },
            {
              subdistrictNameTh: "ห้วยกะปิ",
              subdistrictNameEn: "Huai Kapi",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "เสม็ด",
              subdistrictNameEn: "Samet",
              postalCode: 20130,
            },
            {
              subdistrictNameTh: "อ่างศิลา",
              subdistrictNameEn: "Ang Sila",
              postalCode: 20000,
            },
            {
              subdistrictNameTh: "สำนักบก",
              subdistrictNameEn: "Samnak Bok",
              postalCode: 20000,
            },
          ],
        },
        {
          districtNameTh: "บ้านบึง",
          districtNameEn: "Ban Bueng",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านบึง",
              subdistrictNameEn: "Ban Bueng",
              postalCode: 20170,
            },
            {
              subdistrictNameTh: "คลองกิ่ว",
              subdistrictNameEn: "Khlong Kio",
              postalCode: 20220,
            },
            {
              subdistrictNameTh: "มาบไผ่",
              subdistrictNameEn: "Map Phai",
              postalCode: 20170,
            },
            {
              subdistrictNameTh: "หนองซ้ำซาก",
              subdistrictNameEn: "Nong Samsak",
              postalCode: 20170,
            },
            {
              subdistrictNameTh: "หนองบอนแดง",
              subdistrictNameEn: "Nong Bon Daeng",
              postalCode: 20170,
            },
            {
              subdistrictNameTh: "หนองชาก",
              subdistrictNameEn: "Nong Chak",
              postalCode: 20170,
            },
            {
              subdistrictNameTh: "หนองอิรุณ",
              subdistrictNameEn: "Nong I Run",
              postalCode: 20220,
            },
            {
              subdistrictNameTh: "หนองไผ่แก้ว",
              subdistrictNameEn: "Nong Phai Kaeo",
              postalCode: 20220,
            },
          ],
        },
        {
          districtNameTh: "หนองใหญ่",
          districtNameEn: "Nong Yai",
          subdistricts: [
            {
              subdistrictNameTh: "หนองใหญ่",
              subdistrictNameEn: "Nong Yai",
              postalCode: 20190,
            },
            {
              subdistrictNameTh: "คลองพลู",
              subdistrictNameEn: "Khlong Phlu",
              postalCode: 20190,
            },
            {
              subdistrictNameTh: "หนองเสือช้าง",
              subdistrictNameEn: "Nong Suea Chang",
              postalCode: 20190,
            },
            {
              subdistrictNameTh: "ห้างสูง",
              subdistrictNameEn: "Hang Sung",
              postalCode: 20190,
            },
            {
              subdistrictNameTh: "เขาซก",
              subdistrictNameEn: "Khao Sok",
              postalCode: 20190,
            },
          ],
        },
        {
          districtNameTh: "บางละมุง",
          districtNameEn: "Bang Lamung",
          subdistricts: [
            {
              subdistrictNameTh: "บางละมุง",
              subdistrictNameEn: "Bang Lamung",
              postalCode: 20150,
            },
            {
              subdistrictNameTh: "หนองปรือ",
              subdistrictNameEn: "Nong Prue",
              postalCode: 20150,
            },
            {
              subdistrictNameTh: "หนองปลาไหล",
              subdistrictNameEn: "Nong Pla Lai",
              postalCode: 20150,
            },
            {
              subdistrictNameTh: "โป่ง",
              subdistrictNameEn: "Pong",
              postalCode: 20150,
            },
            {
              subdistrictNameTh: "เขาไม้แก้ว",
              subdistrictNameEn: "Khao Mai Kaeo",
              postalCode: 20150,
            },
            {
              subdistrictNameTh: "ห้วยใหญ่",
              subdistrictNameEn: "Huai Yai",
              postalCode: 20150,
            },
            {
              subdistrictNameTh: "ตะเคียนเตี้ย",
              subdistrictNameEn: "Takhian Tia",
              postalCode: 20150,
            },
            {
              subdistrictNameTh: "นาเกลือ",
              subdistrictNameEn: "Na Kluea",
              postalCode: 20150,
            },
          ],
        },
        {
          districtNameTh: "พานทอง",
          districtNameEn: "Phan Thong",
          subdistricts: [
            {
              subdistrictNameTh: "พานทอง",
              subdistrictNameEn: "Phan Thong",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "หนองตำลึง",
              subdistrictNameEn: "Nong Tamlueng",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "มาบโป่ง",
              subdistrictNameEn: "Map Pong",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "หนองกะขะ",
              subdistrictNameEn: "Nong Kakha",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "หนองหงษ์",
              subdistrictNameEn: "Nong Hong",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "โคกขี้หนอน",
              subdistrictNameEn: "Khok Khi Non",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "บ้านเก่า",
              subdistrictNameEn: "Ban Kao",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "หน้าประดู่",
              subdistrictNameEn: "Na Pradu",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "บางนาง",
              subdistrictNameEn: "Bang Nang",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "เกาะลอย",
              subdistrictNameEn: "Ko Loi",
              postalCode: 20160,
            },
            {
              subdistrictNameTh: "บางหัก",
              subdistrictNameEn: "Bang Hak",
              postalCode: 20160,
            },
          ],
        },
        {
          districtNameTh: "พนัสนิคม",
          districtNameEn: "Phanat Nikhom",
          subdistricts: [
            {
              subdistrictNameTh: "พนัสนิคม",
              subdistrictNameEn: "Phanat Nikhom",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "หน้าพระธาตุ",
              subdistrictNameEn: "Na Phrathat",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "วัดหลวง",
              subdistrictNameEn: "Wat Luang",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "บ้านเซิด",
              subdistrictNameEn: "Ban Soet",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "นาเริก",
              subdistrictNameEn: "Na Roek",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "หมอนนาง",
              subdistrictNameEn: "Mon Nang",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "สระสี่เหลี่ยม",
              subdistrictNameEn: "Sa Si Liam",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "วัดโบสถ์",
              subdistrictNameEn: "Wat Bot",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "กุฎโง้ง",
              subdistrictNameEn: "Kut Ngong",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "หัวถนน",
              subdistrictNameEn: "Hua Thanon",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "หนองปรือ",
              subdistrictNameEn: "Nong Prue",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "หนองขยาด",
              subdistrictNameEn: "Nong Khayat",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "ทุ่งขวาง",
              subdistrictNameEn: "Thung Khwang",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "หนองเหียง",
              subdistrictNameEn: "Nong Hiang",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "นาวังหิน",
              subdistrictNameEn: "Na Wang Hin",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "บ้านช้าง",
              subdistrictNameEn: "Ban Chang",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "โคกเพลาะ",
              subdistrictNameEn: "Khok Phlo",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "ไร่หลักทอง",
              subdistrictNameEn: "Rai Lak Thong",
              postalCode: 20140,
            },
            {
              subdistrictNameTh: "นามะตูม",
              subdistrictNameEn: "Na Matum",
              postalCode: 20140,
            },
          ],
        },
        {
          districtNameTh: "ศรีราชา",
          districtNameEn: "Si Racha",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีราชา",
              subdistrictNameEn: "Si Racha",
              postalCode: 20110,
            },
            {
              subdistrictNameTh: "สุรศักดิ์",
              subdistrictNameEn: "Surasak",
              postalCode: 20110,
            },
            {
              subdistrictNameTh: "ทุ่งสุขลา",
              subdistrictNameEn: "Thung Sukhla",
              postalCode: 20230,
            },
            {
              subdistrictNameTh: "บึง",
              subdistrictNameEn: "Bueng",
              postalCode: 20230,
            },
            {
              subdistrictNameTh: "หนองขาม",
              subdistrictNameEn: "Nong Kham",
              postalCode: 20110,
            },
            {
              subdistrictNameTh: "เขาคันทรง",
              subdistrictNameEn: "Khao Khan Song",
              postalCode: 20110,
            },
            {
              subdistrictNameTh: "บางพระ",
              subdistrictNameEn: "Bang Phra",
              postalCode: 20110,
            },
            {
              subdistrictNameTh: "บ่อวิน",
              subdistrictNameEn: "Bo Win",
              postalCode: 20230,
            },
          ],
        },
        {
          districtNameTh: "เกาะสีชัง",
          districtNameEn: "Ko Sichang",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าเทววงษ์",
              subdistrictNameEn: "Tha Thewawong",
              postalCode: 20120,
            },
          ],
        },
        {
          districtNameTh: "สัตหีบ",
          districtNameEn: "Sattahip",
          subdistricts: [
            {
              subdistrictNameTh: "สัตหีบ",
              subdistrictNameEn: "Sattahip",
              postalCode: 20180,
            },
            {
              subdistrictNameTh: "นาจอมเทียน",
              subdistrictNameEn: "Na Chom Thian",
              postalCode: 20250,
            },
            {
              subdistrictNameTh: "พลูตาหลวง",
              subdistrictNameEn: "Phlu Ta Luang",
              postalCode: 20180,
            },
            {
              subdistrictNameTh: "บางเสร่",
              subdistrictNameEn: "Bang Sare",
              postalCode: 20250,
            },
            {
              subdistrictNameTh: "แสมสาร",
              subdistrictNameEn: "Samae San",
              postalCode: 20180,
            },
          ],
        },
        {
          districtNameTh: "บ่อทอง",
          districtNameEn: "Bo Thong",
          subdistricts: [
            {
              subdistrictNameTh: "บ่อทอง",
              subdistrictNameEn: "Bo Thong",
              postalCode: 20270,
            },
            {
              subdistrictNameTh: "วัดสุวรรณ",
              subdistrictNameEn: "Wat Suwan",
              postalCode: 20270,
            },
            {
              subdistrictNameTh: "บ่อกวางทอง",
              subdistrictNameEn: "Bo Kwang Thong",
              postalCode: 20270,
            },
            {
              subdistrictNameTh: "ธาตุทอง",
              subdistrictNameEn: "That Thong",
              postalCode: 20270,
            },
            {
              subdistrictNameTh: "เกษตรสุวรรณ",
              subdistrictNameEn: "Kaset Suwan",
              postalCode: 20270,
            },
            {
              subdistrictNameTh: "พลวงทอง",
              subdistrictNameEn: "Phluang Thong",
              postalCode: 20270,
            },
          ],
        },
        {
          districtNameTh: "เกาะจันทร์",
          districtNameEn: "Ko Chan",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะจันทร์",
              subdistrictNameEn: "Ko Chan",
              postalCode: 20240,
            },
            {
              subdistrictNameTh: "ท่าบุญมี",
              subdistrictNameEn: "Tha Bunmi",
              postalCode: 20240,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ระยอง",
      stateNameEn: "Rayong",
      districts: [
        {
          districtNameTh: "เมืองระยอง",
          districtNameEn: "Mueang Rayong",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าประดู่",
              subdistrictNameEn: "Tha Pradu",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "เชิงเนิน",
              subdistrictNameEn: "Choeng Noen",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "ตะพง",
              subdistrictNameEn: "Taphong",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "เพ",
              subdistrictNameEn: "Phe",
              postalCode: 21160,
            },
            {
              subdistrictNameTh: "แกลง",
              subdistrictNameEn: "Klaeng",
              postalCode: 21160,
            },
            {
              subdistrictNameTh: "บ้านแลง",
              subdistrictNameEn: "Ban Laeng",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "นาตาขวัญ",
              subdistrictNameEn: "Na Ta Khwan",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "เนินพระ",
              subdistrictNameEn: "Noen Phra",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "กะเฉด",
              subdistrictNameEn: "Kachet",
              postalCode: 21100,
            },
            {
              subdistrictNameTh: "ทับมา",
              subdistrictNameEn: "Thap Ma",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "น้ำคอก",
              subdistrictNameEn: "Nam Khok",
              postalCode: 21000,
            },
            {
              subdistrictNameTh: "ห้วยโป่ง",
              subdistrictNameEn: "Huai Pong",
              postalCode: 21150,
            },
            {
              subdistrictNameTh: "มาบตาพุด",
              subdistrictNameEn: "Maptaphut",
              postalCode: 21150,
            },
            {
              subdistrictNameTh: "สำนักทอง",
              subdistrictNameEn: "Samnak Thong",
              postalCode: 21100,
            },
          ],
        },
        {
          districtNameTh: "บ้านฉาง",
          districtNameEn: "Ban Chang",
          subdistricts: [
            {
              subdistrictNameTh: "สำนักท้อน",
              subdistrictNameEn: "Samnak Thon",
              postalCode: 21130,
            },
            {
              subdistrictNameTh: "พลา",
              subdistrictNameEn: "Phla",
              postalCode: 21130,
            },
            {
              subdistrictNameTh: "บ้านฉาง",
              subdistrictNameEn: "Ban Chang",
              postalCode: 21130,
            },
          ],
        },
        {
          districtNameTh: "แกลง",
          districtNameEn: "Klaeng",
          subdistricts: [
            {
              subdistrictNameTh: "ทางเกวียน",
              subdistrictNameEn: "Thang Kwian",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "วังหว้า",
              subdistrictNameEn: "Wang Wa",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "ชากโดน",
              subdistrictNameEn: "Chak Don",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "เนินฆ้อ",
              subdistrictNameEn: "Noen Kho",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "กร่ำ",
              subdistrictNameEn: "Kram",
              postalCode: 21190,
            },
            {
              subdistrictNameTh: "ชากพง",
              subdistrictNameEn: "Chak Phong",
              postalCode: 21190,
            },
            {
              subdistrictNameTh: "กระแสบน",
              subdistrictNameEn: "Krasae Bon",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "ทุ่งควายกิน",
              subdistrictNameEn: "Thung Khwai Kin",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "กองดิน",
              subdistrictNameEn: "Kong Din",
              postalCode: 22160,
            },
            {
              subdistrictNameTh: "คลองปูน",
              subdistrictNameEn: "Khlong Pun",
              postalCode: 21170,
            },
            {
              subdistrictNameTh: "พังราด",
              subdistrictNameEn: "Phang Rat",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "ปากน้ำกระแส",
              subdistrictNameEn: "Pak Nam Krasae",
              postalCode: 21170,
            },
            {
              subdistrictNameTh: "ห้วยยาง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "สองสลึง",
              subdistrictNameEn: "Song Salueng",
              postalCode: 21110,
            },
          ],
        },
        {
          districtNameTh: "วังจันทร์",
          districtNameEn: "Wang Chan",
          subdistricts: [
            {
              subdistrictNameTh: "วังจันทร์",
              subdistrictNameEn: "Wang Chan",
              postalCode: 21210,
            },
            {
              subdistrictNameTh: "ชุมแสง",
              subdistrictNameEn: "Chum Saeng",
              postalCode: 21210,
            },
            {
              subdistrictNameTh: "ป่ายุบใน",
              subdistrictNameEn: "Pa Yup Nai",
              postalCode: 21210,
            },
            {
              subdistrictNameTh: "พลงตาเอี่ยม",
              subdistrictNameEn: "Phong Ta Iam",
              postalCode: 21210,
            },
          ],
        },
        {
          districtNameTh: "บ้านค่าย",
          districtNameEn: "Ban Khai",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านค่าย",
              subdistrictNameEn: "Ban Khai",
              postalCode: 21120,
            },
            {
              subdistrictNameTh: "หนองละลอก",
              subdistrictNameEn: "Nong Lalok",
              postalCode: 21120,
            },
            {
              subdistrictNameTh: "หนองตะพาน",
              subdistrictNameEn: "Nong Taphan",
              postalCode: 21120,
            },
            {
              subdistrictNameTh: "ตาขัน",
              subdistrictNameEn: "Ta Khan",
              postalCode: 21120,
            },
            {
              subdistrictNameTh: "บางบุตร",
              subdistrictNameEn: "Bang But",
              postalCode: 21120,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 21120,
            },
            {
              subdistrictNameTh: "ชากบก",
              subdistrictNameEn: "Chak Bok",
              postalCode: 21120,
            },
          ],
        },
        {
          districtNameTh: "ปลวกแดง",
          districtNameEn: "Pluak Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "ปลวกแดง",
              subdistrictNameEn: "Pluak Daeng",
              postalCode: 21140,
            },
            {
              subdistrictNameTh: "ตาสิทธิ์",
              subdistrictNameEn: "Ta Sit",
              postalCode: 21140,
            },
            {
              subdistrictNameTh: "ละหาร",
              subdistrictNameEn: "Lahan",
              postalCode: 21140,
            },
            {
              subdistrictNameTh: "แม่น้ำคู้",
              subdistrictNameEn: "Maenam Khu",
              postalCode: 21140,
            },
            {
              subdistrictNameTh: "มาบยางพร",
              subdistrictNameEn: "Map Yang Phon",
              postalCode: 21140,
            },
            {
              subdistrictNameTh: "หนองไร่",
              subdistrictNameEn: "Nong Rai",
              postalCode: 21140,
            },
          ],
        },
        {
          districtNameTh: "เขาชะเมา",
          districtNameEn: "Khao Chamao",
          subdistricts: [
            {
              subdistrictNameTh: "น้ำเป็น",
              subdistrictNameEn: "Nam Pen",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "ห้วยทับมอญ",
              subdistrictNameEn: "Huai Thap Mon",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "ชำฆ้อ",
              subdistrictNameEn: "Cham Noi",
              postalCode: 21110,
            },
            {
              subdistrictNameTh: "เขาน้อย",
              subdistrictNameEn: "Khao Noi",
              postalCode: 21110,
            },
          ],
        },
        {
          districtNameTh: "นิคมพัฒนา",
          districtNameEn: "Nikhom Phatthana",
          subdistricts: [
            {
              subdistrictNameTh: "นิคมพัฒนา",
              subdistrictNameEn: "Nikhom Phatthana",
              postalCode: 21180,
            },
            {
              subdistrictNameTh: "มาบข่า",
              subdistrictNameEn: "Map Kha",
              postalCode: 21180,
            },
            {
              subdistrictNameTh: "พนานิคม",
              subdistrictNameEn: "Phana Nikhom",
              postalCode: 21180,
            },
            {
              subdistrictNameTh: "มะขามคู่",
              subdistrictNameEn: "Makham Khu",
              postalCode: 21180,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "จันทบุรี",
      stateNameEn: "Chanthaburi",
      districts: [
        {
          districtNameTh: "เมืองจันทบุรี",
          districtNameEn: "Mueang Chanthaburi",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาด",
              subdistrictNameEn: "Talat",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "วัดใหม่",
              subdistrictNameEn: "Wat Mai",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "คลองนารายณ์",
              subdistrictNameEn: "Khlong Narai",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "เกาะขวาง",
              subdistrictNameEn: "Ko Khwang",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "คมบาง",
              subdistrictNameEn: "Kom Bang",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "จันทนิมิต",
              subdistrictNameEn: "Chanthanimit",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "บางกะจะ",
              subdistrictNameEn: "Bang Kacha",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "แสลง",
              subdistrictNameEn: "Salaeng",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 22000,
            },
            {
              subdistrictNameTh: "พลับพลา",
              subdistrictNameEn: "Phlapphla",
              postalCode: 22000,
            },
          ],
        },
        {
          districtNameTh: "ขลุง",
          districtNameEn: "Khlung",
          subdistricts: [
            {
              subdistrictNameTh: "ขลุง",
              subdistrictNameEn: "Khlung",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "บ่อ",
              subdistrictNameEn: "Bo",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "เกวียนหัก",
              subdistrictNameEn: "Kwian Hak",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "ตะปอน",
              subdistrictNameEn: "Tapon",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "บางชัน",
              subdistrictNameEn: "Bang Chan",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "วันยาว",
              subdistrictNameEn: "Wan Yao",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "ซึ้ง",
              subdistrictNameEn: "Sueng",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "มาบไพ",
              subdistrictNameEn: "Map Phai",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "วังสรรพรส",
              subdistrictNameEn: "Wang Sappharot",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "ตรอกนอง",
              subdistrictNameEn: "Trok Nong",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "ตกพรม",
              subdistrictNameEn: "Tok Phrom",
              postalCode: 22110,
            },
            {
              subdistrictNameTh: "บ่อเวฬุ",
              subdistrictNameEn: "Bo Weru",
              postalCode: 22150,
            },
          ],
        },
        {
          districtNameTh: "ท่าใหม่",
          districtNameEn: "Tha Mai",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าใหม่",
              subdistrictNameEn: "Tha Mai",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "ยายร้า",
              subdistrictNameEn: "Yai Ra",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "สีพยา",
              subdistrictNameEn: "Si Phaya",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "บ่อพุ",
              subdistrictNameEn: "Bo Phu",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "พลอยแหวน",
              subdistrictNameEn: "Phloi Waen",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "เขาวัว",
              subdistrictNameEn: "Khao Wua",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "เขาบายศรี",
              subdistrictNameEn: "Khao Baisi",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "สองพี่น้อง",
              subdistrictNameEn: "Song Phi Nong",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "ทุ่งเบญจา",
              subdistrictNameEn: "Thung Bencha",
              postalCode: 22170,
            },
            {
              subdistrictNameTh: "รำพัน",
              subdistrictNameEn: "Ramphan",
              postalCode: 22170,
            },
            {
              subdistrictNameTh: "โขมง",
              subdistrictNameEn: "Khamong",
              postalCode: 22170,
            },
            {
              subdistrictNameTh: "ตะกาดเง้า",
              subdistrictNameEn: "Takat Ngao",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "คลองขุด",
              subdistrictNameEn: "Khlong Khut",
              postalCode: 22120,
            },
            {
              subdistrictNameTh: "เขาแก้ว",
              subdistrictNameEn: "Khao Kaeo",
              postalCode: 22170,
            },
          ],
        },
        {
          districtNameTh: "โป่งน้ำร้อน",
          districtNameEn: "Pong Nam Ron",
          subdistricts: [
            {
              subdistrictNameTh: "ทับไทร",
              subdistrictNameEn: "Thap Sai",
              postalCode: 22140,
            },
            {
              subdistrictNameTh: "โป่งน้ำร้อน",
              subdistrictNameEn: "Pong Nam Ron",
              postalCode: 22140,
            },
            {
              subdistrictNameTh: "หนองตาคง",
              subdistrictNameEn: "Nong Ta Khong",
              postalCode: 22140,
            },
            {
              subdistrictNameTh: "เทพนิมิต",
              subdistrictNameEn: "Thep Nimit",
              postalCode: 22140,
            },
            {
              subdistrictNameTh: "คลองใหญ่",
              subdistrictNameEn: "Khlong Yai",
              postalCode: 22140,
            },
          ],
        },
        {
          districtNameTh: "มะขาม",
          districtNameEn: "Makham",
          subdistricts: [
            {
              subdistrictNameTh: "มะขาม",
              subdistrictNameEn: "Makham",
              postalCode: 22150,
            },
            {
              subdistrictNameTh: "ท่าหลวง",
              subdistrictNameEn: "Tha Luang",
              postalCode: 22150,
            },
            {
              subdistrictNameTh: "ปัถวี",
              subdistrictNameEn: "Patthawi",
              postalCode: 22150,
            },
            {
              subdistrictNameTh: "วังแซ้ม",
              subdistrictNameEn: "Wang Saem",
              postalCode: 22150,
            },
            {
              subdistrictNameTh: "ฉมัน",
              subdistrictNameEn: "Cha Man",
              postalCode: 22150,
            },
            {
              subdistrictNameTh: "อ่างคีรี",
              subdistrictNameEn: "Ang Khiri",
              postalCode: 22150,
            },
          ],
        },
        {
          districtNameTh: "แหลมสิงห์",
          districtNameEn: "Laem Sing",
          subdistricts: [
            {
              subdistrictNameTh: "ปากน้ำแหลมสิงห์",
              subdistrictNameEn: "Pak Nam Laem Sing",
              postalCode: 22130,
            },
            {
              subdistrictNameTh: "เกาะเปริด",
              subdistrictNameEn: "Ko Proet",
              postalCode: 22130,
            },
            {
              subdistrictNameTh: "หนองชิ่ม",
              subdistrictNameEn: "Nong Chim",
              postalCode: 22130,
            },
            {
              subdistrictNameTh: "พลิ้ว",
              subdistrictNameEn: "Phlio",
              postalCode: 22190,
            },
            {
              subdistrictNameTh: "คลองน้ำเค็ม",
              subdistrictNameEn: "Khlong Nam Khem",
              postalCode: 22190,
            },
            {
              subdistrictNameTh: "บางสระเก้า",
              subdistrictNameEn: "Bang Sa Kao",
              postalCode: 22190,
            },
            {
              subdistrictNameTh: "บางกะไชย",
              subdistrictNameEn: "Bang Kachai",
              postalCode: 22120,
            },
          ],
        },
        {
          districtNameTh: "สอยดาว",
          districtNameEn: "Soi Dao",
          subdistricts: [
            {
              subdistrictNameTh: "ปะตง",
              subdistrictNameEn: "Patong",
              postalCode: 22180,
            },
            {
              subdistrictNameTh: "ทุ่งขนาน",
              subdistrictNameEn: "Thung Khanan",
              postalCode: 22180,
            },
            {
              subdistrictNameTh: "ทับช้าง",
              subdistrictNameEn: "Thap Chang",
              postalCode: 22180,
            },
            {
              subdistrictNameTh: "ทรายขาว",
              subdistrictNameEn: "Sai Khao",
              postalCode: 22180,
            },
            {
              subdistrictNameTh: "สะตอน",
              subdistrictNameEn: "Saton",
              postalCode: 22180,
            },
          ],
        },
        {
          districtNameTh: "แก่งหางแมว",
          districtNameEn: "Kaeng Hang Maeo",
          subdistricts: [
            {
              subdistrictNameTh: "แก่งหางแมว",
              subdistrictNameEn: "Kaeng Hang Maeo",
              postalCode: 22160,
            },
            {
              subdistrictNameTh: "ขุนซ่อง",
              subdistrictNameEn: "Khun Song",
              postalCode: 22160,
            },
            {
              subdistrictNameTh: "สามพี่น้อง",
              subdistrictNameEn: "Sam Phi Nong",
              postalCode: 22160,
            },
            {
              subdistrictNameTh: "พวา",
              subdistrictNameEn: "Phawa",
              postalCode: 22160,
            },
            {
              subdistrictNameTh: "เขาวงกต",
              subdistrictNameEn: "Khao Wongkot",
              postalCode: 22160,
            },
          ],
        },
        {
          districtNameTh: "นายายอาม",
          districtNameEn: "Na Yai Am",
          subdistricts: [
            {
              subdistrictNameTh: "นายายอาม",
              subdistrictNameEn: "Na Yai Am",
              postalCode: 22160,
            },
            {
              subdistrictNameTh: "วังโตนด",
              subdistrictNameEn: "Wang Tanot",
              postalCode: 22170,
            },
            {
              subdistrictNameTh: "กระแจะ",
              subdistrictNameEn: "Krachae",
              postalCode: 22170,
            },
            {
              subdistrictNameTh: "สนามไชย",
              subdistrictNameEn: "Sanam Chai",
              postalCode: 22170,
            },
            {
              subdistrictNameTh: "ช้างข้าม",
              subdistrictNameEn: "Chang Kham",
              postalCode: 22160,
            },
            {
              subdistrictNameTh: "วังใหม่",
              subdistrictNameEn: "Wang Mai",
              postalCode: 22170,
            },
          ],
        },
        {
          districtNameTh: "เขาคิชฌกูฏ",
          districtNameEn: "Khao Khitchakut",
          subdistricts: [
            {
              subdistrictNameTh: "ชากไทย",
              subdistrictNameEn: "Chak Thai",
              postalCode: 22210,
            },
            {
              subdistrictNameTh: "พลวง",
              subdistrictNameEn: "Phluang",
              postalCode: 22210,
            },
            {
              subdistrictNameTh: "ตะเคียนทอง",
              subdistrictNameEn: "Takhian Thong",
              postalCode: 22210,
            },
            {
              subdistrictNameTh: "คลองพลู",
              subdistrictNameEn: "Khlong Phlu",
              postalCode: 22210,
            },
            {
              subdistrictNameTh: "จันทเขลม",
              subdistrictNameEn: "Chanthakhlem",
              postalCode: 22210,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ตราด",
      stateNameEn: "Trat",
      districts: [
        {
          districtNameTh: "เมืองตราด",
          districtNameEn: "Mueang Trat",
          subdistricts: [
            {
              subdistrictNameTh: "บางพระ",
              subdistrictNameEn: "Bang Phra",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "หนองเสม็ด",
              subdistrictNameEn: "Nong Samet",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "หนองโสน",
              subdistrictNameEn: "Nong Sano",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "หนองคันทรง",
              subdistrictNameEn: "Nong Khan Song",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "ห้วงน้ำขาว",
              subdistrictNameEn: "Huang Nam Khao",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "อ่าวใหญ่",
              subdistrictNameEn: "Ao Yai",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "วังกระแจะ",
              subdistrictNameEn: "Wang Krachae",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "ห้วยแร้ง",
              subdistrictNameEn: "Huai Raeng",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "เนินทราย",
              subdistrictNameEn: "Noen Sai",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "ท่าพริก",
              subdistrictNameEn: "Tha Phrik",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "ท่ากุ่ม",
              subdistrictNameEn: "Tha Kum",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "ตะกาง",
              subdistrictNameEn: "Takang",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "ชำราก",
              subdistrictNameEn: "Cham Rak",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "แหลมกลัด",
              subdistrictNameEn: "Laem Klat",
              postalCode: 23000,
            },
          ],
        },
        {
          districtNameTh: "คลองใหญ่",
          districtNameEn: "Khlong Yai",
          subdistricts: [
            {
              subdistrictNameTh: "คลองใหญ่",
              subdistrictNameEn: "Khlong Yai",
              postalCode: 23110,
            },
            {
              subdistrictNameTh: "ไม้รูด",
              subdistrictNameEn: "Mai Rut",
              postalCode: 23110,
            },
            {
              subdistrictNameTh: "หาดเล็ก",
              subdistrictNameEn: "Hat Lek",
              postalCode: 23110,
            },
          ],
        },
        {
          districtNameTh: "เขาสมิง",
          districtNameEn: "Khao Saming",
          subdistricts: [
            {
              subdistrictNameTh: "เขาสมิง",
              subdistrictNameEn: "Khao Saming",
              postalCode: 23130,
            },
            {
              subdistrictNameTh: "แสนตุ้ง",
              subdistrictNameEn: "Saen Tung",
              postalCode: 23150,
            },
            {
              subdistrictNameTh: "วังตะเคียน",
              subdistrictNameEn: "Wang Takhian",
              postalCode: 23130,
            },
            {
              subdistrictNameTh: "ท่าโสม",
              subdistrictNameEn: "Tha Som",
              postalCode: 23150,
            },
            {
              subdistrictNameTh: "สะตอ",
              subdistrictNameEn: "Sato",
              postalCode: 23150,
            },
            {
              subdistrictNameTh: "ประณีต",
              subdistrictNameEn: "Pranit",
              postalCode: 23150,
            },
            {
              subdistrictNameTh: "เทพนิมิต",
              subdistrictNameEn: "Thep Nimit",
              postalCode: 23150,
            },
            {
              subdistrictNameTh: "ทุ่งนนทรี",
              subdistrictNameEn: "Thung Nonsi",
              postalCode: 23130,
            },
          ],
        },
        {
          districtNameTh: "บ่อไร่",
          districtNameEn: "Bo Rai",
          subdistricts: [
            {
              subdistrictNameTh: "บ่อพลอย",
              subdistrictNameEn: "Bo Phloi",
              postalCode: 23140,
            },
            {
              subdistrictNameTh: "ช้างทูน",
              subdistrictNameEn: "Chang Thun",
              postalCode: 23140,
            },
            {
              subdistrictNameTh: "ด่านชุมพล",
              subdistrictNameEn: "Dan Chumphon",
              postalCode: 23140,
            },
            {
              subdistrictNameTh: "หนองบอน",
              subdistrictNameEn: "Nong Bon",
              postalCode: 23140,
            },
            {
              subdistrictNameTh: "นนทรีย์",
              subdistrictNameEn: "Nonsi",
              postalCode: 23140,
            },
          ],
        },
        {
          districtNameTh: "แหลมงอบ",
          districtNameEn: "Laem Ngop",
          subdistricts: [
            {
              subdistrictNameTh: "แหลมงอบ",
              subdistrictNameEn: "Laem Ngop",
              postalCode: 23120,
            },
            {
              subdistrictNameTh: "น้ำเชี่ยว",
              subdistrictNameEn: "Nam Chiao",
              postalCode: 23120,
            },
            {
              subdistrictNameTh: "บางปิด",
              subdistrictNameEn: "Bang Pit",
              postalCode: 23120,
            },
            {
              subdistrictNameTh: "คลองใหญ่",
              subdistrictNameEn: "Khlong Yai",
              postalCode: 23120,
            },
          ],
        },
        {
          districtNameTh: "เกาะกูด",
          districtNameEn: "Ko Kut",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะหมาก",
              subdistrictNameEn: "Ko Mak",
              postalCode: 23000,
            },
            {
              subdistrictNameTh: "เกาะกูด",
              subdistrictNameEn: "Ko Kut",
              postalCode: 23000,
            },
          ],
        },
        {
          districtNameTh: "เกาะช้าง",
          districtNameEn: "Ko Chang",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะช้าง",
              subdistrictNameEn: "Ko Chang",
              postalCode: 23170,
            },
            {
              subdistrictNameTh: "เกาะช้างใต้",
              subdistrictNameEn: "Ko Chang Tai",
              postalCode: 23170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ฉะเชิงเทรา",
      stateNameEn: "Chachoengsao",
      districts: [
        {
          districtNameTh: "เมืองฉะเชิงเทรา",
          districtNameEn: "Mueang Chachoengsao",
          subdistricts: [
            {
              subdistrictNameTh: "หน้าเมือง",
              subdistrictNameEn: "Na Mueang",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "ท่าไข่",
              subdistrictNameEn: "Tha Khai",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "คลองนา",
              subdistrictNameEn: "Khlong Na",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางตีนเป็ด",
              subdistrictNameEn: "Bang Tin Pet",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางไผ่",
              subdistrictNameEn: "Bang Phai",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "คลองจุกกระเฌอ",
              subdistrictNameEn: "Khlong Chuk Khachoe",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางขวัญ",
              subdistrictNameEn: "Bang Khwan",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "คลองนครเนื่องเขต",
              subdistrictNameEn: "Khlong Nakhon Nueang Khet",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "วังตะเคียน",
              subdistrictNameEn: "Wang Takhian",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "โสธร",
              subdistrictNameEn: "Sothon",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางพระ",
              subdistrictNameEn: "Bang Phra",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางกะไห",
              subdistrictNameEn: "Bang Kahai",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "หนามแดง",
              subdistrictNameEn: "Nam Daeng",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "คลองเปรง",
              subdistrictNameEn: "Khlong Preng",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "คลองอุดมชลจร",
              subdistrictNameEn: "Khlong Udom Chonlachon",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "คลองหลวงแพ่ง",
              subdistrictNameEn: "Khlong Luang Phaeng",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางเตย",
              subdistrictNameEn: "Bang Toei",
              postalCode: 24000,
            },
          ],
        },
        {
          districtNameTh: "บางคล้า",
          districtNameEn: "Bang Khla",
          subdistricts: [
            {
              subdistrictNameTh: "บางคล้า",
              subdistrictNameEn: "Bang Khla",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "บางสวน",
              subdistrictNameEn: "Bang Suan",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "บางกระเจ็ด",
              subdistrictNameEn: "Bang Krachet",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "ท่าทองหลาง",
              subdistrictNameEn: "Tha Thong Lang",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "สาวชะโงก",
              subdistrictNameEn: "Sao Cha-Ngo",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "เสม็ดเหนือ",
              subdistrictNameEn: "Samet Nuea",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "เสม็ดใต้",
              subdistrictNameEn: "Samet Tai",
              postalCode: 24110,
            },
            {
              subdistrictNameTh: "หัวไทร",
              subdistrictNameEn: "Hua Sai",
              postalCode: 24110,
            },
          ],
        },
        {
          districtNameTh: "บางน้ำเปรี้ยว",
          districtNameEn: "Bang Nam Priao",
          subdistricts: [
            {
              subdistrictNameTh: "บางน้ำเปรี้ยว",
              subdistrictNameEn: "Bang Nam Priao",
              postalCode: 24150,
            },
            {
              subdistrictNameTh: "บางขนาก",
              subdistrictNameEn: "Bang Khanak",
              postalCode: 24150,
            },
            {
              subdistrictNameTh: "สิงโตทอง",
              subdistrictNameEn: "Singto Thong",
              postalCode: 24150,
            },
            {
              subdistrictNameTh: "หมอนทอง",
              subdistrictNameEn: "Mon Thong",
              postalCode: 24150,
            },
            {
              subdistrictNameTh: "บึงน้ำรักษ์",
              subdistrictNameEn: "Bueng Nam Rak",
              postalCode: 24170,
            },
            {
              subdistrictNameTh: "ดอนเกาะกา",
              subdistrictNameEn: "Don Ko Ka",
              postalCode: 24170,
            },
            {
              subdistrictNameTh: "โยธะกา",
              subdistrictNameEn: "Yothaka",
              postalCode: 24150,
            },
            {
              subdistrictNameTh: "ดอนฉิมพลี",
              subdistrictNameEn: "Don Chimphli",
              postalCode: 24170,
            },
            {
              subdistrictNameTh: "ศาลาแดง",
              subdistrictNameEn: "Sala Daeng",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "โพรงอากาศ",
              subdistrictNameEn: "Phrong Akat",
              postalCode: 24150,
            },
          ],
        },
        {
          districtNameTh: "บางปะกง",
          districtNameEn: "Bang Pakong",
          subdistricts: [
            {
              subdistrictNameTh: "บางปะกง",
              subdistrictNameEn: "Bang Pakong",
              postalCode: 24130,
            },
            {
              subdistrictNameTh: "ท่าสะอ้าน",
              subdistrictNameEn: "Tha Sa-An",
              postalCode: 24130,
            },
            {
              subdistrictNameTh: "บางวัว",
              subdistrictNameEn: "Bang Wua",
              postalCode: 24180,
            },
            {
              subdistrictNameTh: "บางสมัคร",
              subdistrictNameEn: "Bang Samak",
              postalCode: 24180,
            },
            {
              subdistrictNameTh: "บางผึ้ง",
              subdistrictNameEn: "Bang Phueng",
              postalCode: 24130,
            },
            {
              subdistrictNameTh: "บางเกลือ",
              subdistrictNameEn: "Bang Kluea",
              postalCode: 24180,
            },
            {
              subdistrictNameTh: "สองคลอง",
              subdistrictNameEn: "Song Khlong",
              postalCode: 24130,
            },
            {
              subdistrictNameTh: "หนองจอก",
              subdistrictNameEn: "Nong Chok",
              postalCode: 24130,
            },
            {
              subdistrictNameTh: "พิมพา",
              subdistrictNameEn: "Phimpha",
              postalCode: 24130,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 24130,
            },
            {
              subdistrictNameTh: "หอมศีล",
              subdistrictNameEn: "Hom Sin",
              postalCode: 24180,
            },
            {
              subdistrictNameTh: "เขาดิน",
              subdistrictNameEn: "Khao Din",
              postalCode: 24130,
            },
          ],
        },
        {
          districtNameTh: "บ้านโพธิ์",
          districtNameEn: "Ban Pho",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านโพธิ์",
              subdistrictNameEn: "Ban Pho",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "เกาะไร่",
              subdistrictNameEn: "Ko Rai",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "คลองขุด",
              subdistrictNameEn: "Khlong Khut",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "คลองบ้านโพธิ์",
              subdistrictNameEn: "Khlong Ban Pho",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "คลองประเวศ",
              subdistrictNameEn: "Khlong Prawet",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "ดอนทราย",
              subdistrictNameEn: "Don Sai",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "เทพราช",
              subdistrictNameEn: "Theppharat",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "ท่าพลับ",
              subdistrictNameEn: "Tha Phlap",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "หนองตีนนก",
              subdistrictNameEn: "Nong Tin Nok",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "บางซ่อน",
              subdistrictNameEn: "Bang Son",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "บางกรูด",
              subdistrictNameEn: "Bang Krut",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "แหลมประดู่",
              subdistrictNameEn: "Laem Pradu",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "ลาดขวาง",
              subdistrictNameEn: "Lat Khwang",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "สนามจันทร์",
              subdistrictNameEn: "Sanam Chan",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "แสนภูดาษ",
              subdistrictNameEn: "Saen Phu Dat",
              postalCode: 24140,
            },
            {
              subdistrictNameTh: "สิบเอ็ดศอก",
              subdistrictNameEn: "Sip Et Sok",
              postalCode: 24140,
            },
          ],
        },
        {
          districtNameTh: "พนมสารคาม",
          districtNameEn: "Phanom Sarakham",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะขนุน",
              subdistrictNameEn: "Ko Khanun",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "บ้านซ่อง",
              subdistrictNameEn: "Ban Song",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "พนมสารคาม",
              subdistrictNameEn: "Phanom Sarakham",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "เมืองเก่า",
              subdistrictNameEn: "Mueang Kao",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "หนองยาว",
              subdistrictNameEn: "Nong Yao",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "ท่าถ่าน",
              subdistrictNameEn: "Tha Than",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "หนองแหน",
              subdistrictNameEn: "Nong Nae",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "เขาหินซ้อน",
              subdistrictNameEn: "Khao Hin Son",
              postalCode: 24120,
            },
          ],
        },
        {
          districtNameTh: "ราชสาส์น",
          districtNameEn: "Ratchasan",
          subdistricts: [
            {
              subdistrictNameTh: "บางคา",
              subdistrictNameEn: "Bang Kha",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "เมืองใหม่",
              subdistrictNameEn: "Mueang Mai",
              postalCode: 24120,
            },
            {
              subdistrictNameTh: "ดงน้อย",
              subdistrictNameEn: "Dong Noi",
              postalCode: 24120,
            },
          ],
        },
        {
          districtNameTh: "สนามชัยเขต",
          districtNameEn: "Sanam Chai Khet",
          subdistricts: [
            {
              subdistrictNameTh: "คู้ยายหมี",
              subdistrictNameEn: "Khu Yai Mi",
              postalCode: 24160,
            },
            {
              subdistrictNameTh: "ท่ากระดาน",
              subdistrictNameEn: "Tha Kradan",
              postalCode: 24160,
            },
            {
              subdistrictNameTh: "ทุ่งพระยา",
              subdistrictNameEn: "Thung Phraya",
              postalCode: 24160,
            },
            {
              subdistrictNameTh: "ลาดกระทิง",
              subdistrictNameEn: "Lat Krathing",
              postalCode: 24160,
            },
          ],
        },
        {
          districtNameTh: "แปลงยาว",
          districtNameEn: "Pleang Yao",
          subdistricts: [
            {
              subdistrictNameTh: "แปลงยาว",
              subdistrictNameEn: "Plaeng Yao",
              postalCode: 24190,
            },
            {
              subdistrictNameTh: "วังเย็น",
              subdistrictNameEn: "Wang Yen",
              postalCode: 24190,
            },
            {
              subdistrictNameTh: "หัวสำโรง",
              subdistrictNameEn: "Hua Samrong",
              postalCode: 24190,
            },
            {
              subdistrictNameTh: "หนองไม้แก่น",
              subdistrictNameEn: "Nong Mai Kaen",
              postalCode: 24190,
            },
          ],
        },
        {
          districtNameTh: "ท่าตะเกียบ",
          districtNameEn: "Tha Takiap",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าตะเกียบ",
              subdistrictNameEn: "Tha Takiap",
              postalCode: 24160,
            },
            {
              subdistrictNameTh: "คลองตะเกรา",
              subdistrictNameEn: "Khlong Takrao",
              postalCode: 24160,
            },
          ],
        },
        {
          districtNameTh: "คลองเขื่อน",
          districtNameEn: "Khlong Khuean",
          subdistricts: [
            {
              subdistrictNameTh: "ก้อนแก้ว",
              subdistrictNameEn: "Kon Kaeo",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "คลองเขื่อน",
              subdistrictNameEn: "Khlong Khuean",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางเล่า",
              subdistrictNameEn: "Bang Lao",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางโรง",
              subdistrictNameEn: "Bang Rong",
              postalCode: 24000,
            },
            {
              subdistrictNameTh: "บางตลาด",
              subdistrictNameEn: "Bang Talat",
              postalCode: 24110,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ปราจีนบุรี",
      stateNameEn: "Prachin Buri",
      districts: [
        {
          districtNameTh: "เมืองปราจีนบุรี",
          districtNameEn: "Mueang Prachin Buri",
          subdistricts: [
            {
              subdistrictNameTh: "หน้าเมือง",
              subdistrictNameEn: "Na Mueang",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "รอบเมือง",
              subdistrictNameEn: "Rop Mueang",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "วัดโบสถ์",
              subdistrictNameEn: "Wat Bot",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "บางเดชะ",
              subdistrictNameEn: "Bang Decha",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "ท่างาม",
              subdistrictNameEn: "Tha Ngam",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "บางบริบูรณ์",
              subdistrictNameEn: "Bang Boribun",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "ดงพระราม",
              subdistrictNameEn: "Dong Phraram",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "บ้านพระ",
              subdistrictNameEn: "Ban Phra",
              postalCode: 25230,
            },
            {
              subdistrictNameTh: "โคกไม้ลาย",
              subdistrictNameEn: "Khok Mai Lai",
              postalCode: 25230,
            },
            {
              subdistrictNameTh: "ไม้เค็ด",
              subdistrictNameEn: "Mai Khet",
              postalCode: 25230,
            },
            {
              subdistrictNameTh: "ดงขี้เหล็ก",
              subdistrictNameEn: "Dong Khi Lek",
              postalCode: 25000,
            },
            {
              subdistrictNameTh: "เนินหอม",
              subdistrictNameEn: "Noen Hom",
              postalCode: 25230,
            },
            {
              subdistrictNameTh: "โนนห้อม",
              subdistrictNameEn: "Non Hom",
              postalCode: 25000,
            },
          ],
        },
        {
          districtNameTh: "กบินทร์บุรี",
          districtNameEn: "Kabin Buri",
          subdistricts: [
            {
              subdistrictNameTh: "กบินทร์",
              subdistrictNameEn: "Kabin",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "เมืองเก่า",
              subdistrictNameEn: "Mueang Kao",
              postalCode: 25240,
            },
            {
              subdistrictNameTh: "วังดาล",
              subdistrictNameEn: "Wang Dan",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "นนทรี",
              subdistrictNameEn: "Nonsi",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "ย่านรี",
              subdistrictNameEn: "Yan Ri",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "วังตะเคียน",
              subdistrictNameEn: "Wang Takhian",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "หาดนางแก้ว",
              subdistrictNameEn: "Hat Nang Kaeo",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "ลาดตะเคียน",
              subdistrictNameEn: "Lat Takhian",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "บ่อทอง",
              subdistrictNameEn: "Bo Thong",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "หนองกี่",
              subdistrictNameEn: "Nong Ki",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "นาแขม",
              subdistrictNameEn: "Na Khaem",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "เขาไม้แก้ว",
              subdistrictNameEn: "Khao Mai Kaeo",
              postalCode: 25110,
            },
            {
              subdistrictNameTh: "วังท่าช้าง",
              subdistrictNameEn: "Wang Tha Chang",
              postalCode: 25110,
            },
          ],
        },
        {
          districtNameTh: "นาดี",
          districtNameEn: "Na Di",
          subdistricts: [
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 25220,
            },
            {
              subdistrictNameTh: "สำพันตา",
              subdistrictNameEn: "Sam Phan Ta",
              postalCode: 25220,
            },
            {
              subdistrictNameTh: "สะพานหิน",
              subdistrictNameEn: "Saphan Hin",
              postalCode: 25220,
            },
            {
              subdistrictNameTh: "ทุ่งโพธิ์",
              subdistrictNameEn: "Thung Pho",
              postalCode: 25220,
            },
            {
              subdistrictNameTh: "แก่งดินสอ",
              subdistrictNameEn: "Kaeng Dinso",
              postalCode: 25220,
            },
            {
              subdistrictNameTh: "บุพราหมณ์",
              subdistrictNameEn: "Bu Phram",
              postalCode: 25220,
            },
          ],
        },
        {
          districtNameTh: "บ้านสร้าง",
          districtNameEn: "Ban Sang",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านสร้าง",
              subdistrictNameEn: "Ban Sang",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "บางกระเบา",
              subdistrictNameEn: "Bang Krabao",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "บางเตย",
              subdistrictNameEn: "Bang Toei",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "บางยาง",
              subdistrictNameEn: "Bang Yang",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "บางแตน",
              subdistrictNameEn: "Bang Taen",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "บางพลวง",
              subdistrictNameEn: "Bang Phluang",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "บางปลาร้า",
              subdistrictNameEn: "Bang Pla Ra",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "บางขาม",
              subdistrictNameEn: "Bang Kham",
              postalCode: 25150,
            },
            {
              subdistrictNameTh: "กระทุ่มแพ้ว",
              subdistrictNameEn: "Krathum Phaeo",
              postalCode: 25150,
            },
          ],
        },
        {
          districtNameTh: "ประจันตคาม",
          districtNameEn: "Prachantakham",
          subdistricts: [
            {
              subdistrictNameTh: "ประจันตคาม",
              subdistrictNameEn: "Prachantakham",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "เกาะลอย",
              subdistrictNameEn: "Ko Loi",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "บ้านหอย",
              subdistrictNameEn: "Ban Hoi",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "หนองแสง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "ดงบัง",
              subdistrictNameEn: "Dong Bang",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "คำโตนด",
              subdistrictNameEn: "Kham Tanot",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "บุฝ้าย",
              subdistrictNameEn: "Bu Fai",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "หนองแก้ว",
              subdistrictNameEn: "Nong Kaeo",
              postalCode: 25130,
            },
            {
              subdistrictNameTh: "โพธิ์งาม",
              subdistrictNameEn: "Pho Ngam",
              postalCode: 25130,
            },
          ],
        },
        {
          districtNameTh: "ศรีมหาโพธิ",
          districtNameEn: "Si Maha Phot",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีมหาโพธิ",
              subdistrictNameEn: "Si Mahapho",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "สัมพันธ์",
              subdistrictNameEn: "Samphan",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "บ้านทาม",
              subdistrictNameEn: "Ban Tham",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "ท่าตูม",
              subdistrictNameEn: "Tha Tum",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "บางกุ้ง",
              subdistrictNameEn: "Bang Kung",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "ดงกระทงยาม",
              subdistrictNameEn: "Dong Krathong Yam",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "หนองโพรง",
              subdistrictNameEn: "Nong Phrong",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "หัวหว้า",
              subdistrictNameEn: "Hua Wa",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "หาดยาง",
              subdistrictNameEn: "Hat Yang",
              postalCode: 25140,
            },
            {
              subdistrictNameTh: "กรอกสมบูรณ์",
              subdistrictNameEn: "Krok Sombun",
              postalCode: 25140,
            },
          ],
        },
        {
          districtNameTh: "ศรีมโหสถ",
          districtNameEn: "Si Mahosot",
          subdistricts: [
            {
              subdistrictNameTh: "โคกปีบ",
              subdistrictNameEn: "Khok Pip",
              postalCode: 25190,
            },
            {
              subdistrictNameTh: "โคกไทย",
              subdistrictNameEn: "Khok Thai",
              postalCode: 25190,
            },
            {
              subdistrictNameTh: "คู้ลำพัน",
              subdistrictNameEn: "Khu Lamphan",
              postalCode: 25190,
            },
            {
              subdistrictNameTh: "ไผ่ชะเลือด",
              subdistrictNameEn: "Phai Chalueat",
              postalCode: 25190,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นครนายก",
      stateNameEn: "Nakhon Nayok",
      districts: [
        {
          districtNameTh: "เมืองนครนายก",
          districtNameEn: "Mueang Nakhon Nayok",
          subdistricts: [
            {
              subdistrictNameTh: "นครนายก",
              subdistrictNameEn: "Nakhon Nayok",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "บ้านใหญ่",
              subdistrictNameEn: "Ban Yai",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "วังกระโจม",
              subdistrictNameEn: "Wang Krachom",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "ท่าทราย",
              subdistrictNameEn: "Tha Sai",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "ดอนยอ",
              subdistrictNameEn: "Don Yo",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "ศรีจุฬา",
              subdistrictNameEn: "Si Chula",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "ดงละคร",
              subdistrictNameEn: "Dong Lakhon",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "ศรีนาวา",
              subdistrictNameEn: "Si Nawa",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "สาริกา",
              subdistrictNameEn: "Sarika",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "หินตั้ง",
              subdistrictNameEn: "Hin Tang",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "เขาพระ",
              subdistrictNameEn: "Khao Phra",
              postalCode: 26000,
            },
            {
              subdistrictNameTh: "พรหมณี",
              subdistrictNameEn: "Phrommani",
              postalCode: 26000,
            },
          ],
        },
        {
          districtNameTh: "ปากพลี",
          districtNameEn: "Pak Phli",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะหวาย",
              subdistrictNameEn: "Ko Wai",
              postalCode: 26130,
            },
            {
              subdistrictNameTh: "เกาะโพธิ์",
              subdistrictNameEn: "Ko Pho",
              postalCode: 26130,
            },
            {
              subdistrictNameTh: "ปากพลี",
              subdistrictNameEn: "Pak Phli",
              postalCode: 26130,
            },
            {
              subdistrictNameTh: "โคกกรวด",
              subdistrictNameEn: "Khok Kruat",
              postalCode: 26130,
            },
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 26130,
            },
            {
              subdistrictNameTh: "หนองแสง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 26130,
            },
            {
              subdistrictNameTh: "นาหินลาด",
              subdistrictNameEn: "Na Hin Lat",
              postalCode: 26130,
            },
          ],
        },
        {
          districtNameTh: "บ้านนา",
          districtNameEn: "Ban Na",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "บ้านพร้าว",
              subdistrictNameEn: "Ban Phrao",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "บ้านพริก",
              subdistrictNameEn: "Ban Phrik",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "อาษา",
              subdistrictNameEn: "A Sa",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "ทองหลาง",
              subdistrictNameEn: "Thong Lang",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "บางอ้อ",
              subdistrictNameEn: "Bang O",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "พิกุลออก",
              subdistrictNameEn: "Phikun Ok",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "ป่าขะ",
              subdistrictNameEn: "Pa Kha",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "เขาเพิ่ม",
              subdistrictNameEn: "Khao Phoem",
              postalCode: 26110,
            },
            {
              subdistrictNameTh: "ศรีกะอาง",
              subdistrictNameEn: "Si Ka-Ang",
              postalCode: 26110,
            },
          ],
        },
        {
          districtNameTh: "องครักษ์",
          districtNameEn: "Ongkharak",
          subdistricts: [
            {
              subdistrictNameTh: "พระอาจารย์",
              subdistrictNameEn: "Phra Achan",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "บึงศาล",
              subdistrictNameEn: "Bueng San",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "ศีรษะกระบือ",
              subdistrictNameEn: "Sisa Krabue",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "โพธิ์แทน",
              subdistrictNameEn: "Pho Thaen",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "บางสมบูรณ์",
              subdistrictNameEn: "Bang Sombun",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "ทรายมูล",
              subdistrictNameEn: "Sai Mun",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "บางปลากด",
              subdistrictNameEn: "Bang Pla Kot",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "บางลูกเสือ",
              subdistrictNameEn: "Bang Luk Suea",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "องครักษ์",
              subdistrictNameEn: "Ongkharak",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "ชุมพล",
              subdistrictNameEn: "Chum Phon",
              postalCode: 26120,
            },
            {
              subdistrictNameTh: "คลองใหญ่",
              subdistrictNameEn: "Khlong Yai",
              postalCode: 26120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สระแก้ว",
      stateNameEn: "Sa Kaeo",
      districts: [
        {
          districtNameTh: "เมืองสระแก้ว",
          districtNameEn: "Mueang Sa Kaeo",
          subdistricts: [
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "บ้านแก้ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "ศาลาลำดวน",
              subdistrictNameEn: "Sala Lamduan",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "โคกปี่ฆ้อง",
              subdistrictNameEn: "Khok Pi Khong",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "ท่าแยก",
              subdistrictNameEn: "Tha Yaek",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "ท่าเกษม",
              subdistrictNameEn: "Tha Kasem",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "สระขวัญ",
              subdistrictNameEn: "Sa Khwan",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "หนองบอน",
              subdistrictNameEn: "Nong Bon",
              postalCode: 27000,
            },
          ],
        },
        {
          districtNameTh: "คลองหาด",
          districtNameEn: "Khlong Hat",
          subdistricts: [
            {
              subdistrictNameTh: "คลองหาด",
              subdistrictNameEn: "Khlong Hat",
              postalCode: 27260,
            },
            {
              subdistrictNameTh: "ไทยอุดม",
              subdistrictNameEn: "Thai Udom",
              postalCode: 27260,
            },
            {
              subdistrictNameTh: "ซับมะกรูด",
              subdistrictNameEn: "Sap Makrut",
              postalCode: 27260,
            },
            {
              subdistrictNameTh: "ไทรเดี่ยว",
              subdistrictNameEn: "Sai Diao",
              postalCode: 27260,
            },
            {
              subdistrictNameTh: "คลองไก่เถื่อน",
              subdistrictNameEn: "Khlong Kai Thuean",
              postalCode: 27260,
            },
            {
              subdistrictNameTh: "เบญจขร",
              subdistrictNameEn: "Benchakhon",
              postalCode: 27260,
            },
            {
              subdistrictNameTh: "ไทรทอง",
              subdistrictNameEn: "Sai Thong",
              postalCode: 27260,
            },
          ],
        },
        {
          districtNameTh: "ตาพระยา",
          districtNameEn: "Ta Phraya",
          subdistricts: [
            {
              subdistrictNameTh: "ตาพระยา",
              subdistrictNameEn: "Ta Phraya",
              postalCode: 27180,
            },
            {
              subdistrictNameTh: "ทัพเสด็จ",
              subdistrictNameEn: "Thap Sadet",
              postalCode: 27180,
            },
            {
              subdistrictNameTh: "ทัพราช",
              subdistrictNameEn: "Thappharat",
              postalCode: 27180,
            },
            {
              subdistrictNameTh: "ทัพไทย",
              subdistrictNameEn: "Thap Thai",
              postalCode: 27180,
            },
            {
              subdistrictNameTh: "โคคลาน",
              subdistrictNameEn: "Khot Lan",
              postalCode: 27180,
            },
          ],
        },
        {
          districtNameTh: "วังน้ำเย็น",
          districtNameEn: "Wang Nam Yen",
          subdistricts: [
            {
              subdistrictNameTh: "วังน้ำเย็น",
              subdistrictNameEn: "Wang Nam Yen",
              postalCode: 27210,
            },
            {
              subdistrictNameTh: "ตาหลังใน",
              subdistrictNameEn: "Ta Lang Nai",
              postalCode: 27210,
            },
            {
              subdistrictNameTh: "คลองหินปูน",
              subdistrictNameEn: "Khlong Hin Pun",
              postalCode: 27210,
            },
            {
              subdistrictNameTh: "ทุ่งมหาเจริญ",
              subdistrictNameEn: "Thung Mahacharoen",
              postalCode: 27210,
            },
          ],
        },
        {
          districtNameTh: "วัฒนานคร",
          districtNameEn: "Watthana Nakhon",
          subdistricts: [
            {
              subdistrictNameTh: "วัฒนานคร",
              subdistrictNameEn: "Watthana Nakhon",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "ท่าเกวียน",
              subdistrictNameEn: "Tha Kwian",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "ผักขะ",
              subdistrictNameEn: "Phak Kha",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "โนนหมากเค็ง",
              subdistrictNameEn: "Non Mak Kheng",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "หนองน้ำใส",
              subdistrictNameEn: "Nong Nam Sai",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "ช่องกุ่ม",
              subdistrictNameEn: "Chong Kum",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "แซร์ออ",
              subdistrictNameEn: "Sae-O",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "หนองหมากฝ้าย",
              subdistrictNameEn: "Nong Mak Fai",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "หนองตะเคียนบอน",
              subdistrictNameEn: "Nong Takhian Bon",
              postalCode: 27160,
            },
            {
              subdistrictNameTh: "ห้วยโจด",
              subdistrictNameEn: "Huai Chot",
              postalCode: 27160,
            },
          ],
        },
        {
          districtNameTh: "อรัญประเทศ",
          districtNameEn: "Aranyaprathet",
          subdistricts: [
            {
              subdistrictNameTh: "อรัญประเทศ",
              subdistrictNameEn: "Aranya Prathet",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "เมืองไผ่",
              subdistrictNameEn: "Mueang Phai",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "หันทราย",
              subdistrictNameEn: "Han Sai",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "คลองน้ำใส",
              subdistrictNameEn: "Khlong Nam Sai",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "ป่าไร่",
              subdistrictNameEn: "Pa Rai",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "ทับพริก",
              subdistrictNameEn: "Thap Phrik",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "บ้านใหม่หนองไทร",
              subdistrictNameEn: "Ban Mai Nong Sai",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "ผ่านศึก",
              subdistrictNameEn: "Phan Suek",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "หนองสังข์",
              subdistrictNameEn: "Nong Sang",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "คลองทับจันทร์",
              subdistrictNameEn: "Khlong Thap Chan",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "ฟากห้วย",
              subdistrictNameEn: "Fak Huai",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "บ้านด่าน",
              subdistrictNameEn: "Ban Dan",
              postalCode: 27120,
            },
          ],
        },
        {
          districtNameTh: "เขาฉกรรจ์",
          districtNameEn: "Khao Chakan",
          subdistricts: [
            {
              subdistrictNameTh: "เขาฉกรรจ์",
              subdistrictNameEn: "Khao Chakan",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "หนองหว้า",
              subdistrictNameEn: "Nong Wa",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "พระเพลิง",
              subdistrictNameEn: "Phra Phloeng",
              postalCode: 27000,
            },
            {
              subdistrictNameTh: "เขาสามสิบ",
              subdistrictNameEn: "Khao Sam Sip",
              postalCode: 27000,
            },
          ],
        },
        {
          districtNameTh: "โคกสูง",
          districtNameEn: "Khok Sung",
          subdistricts: [
            {
              subdistrictNameTh: "โคกสูง",
              subdistrictNameEn: "Khok Sung",
              postalCode: 27120,
            },
            {
              subdistrictNameTh: "หนองม่วง",
              subdistrictNameEn: "Nong Muang",
              postalCode: 27180,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 27180,
            },
            {
              subdistrictNameTh: "โนนหมากมุ่น",
              subdistrictNameEn: "Non Mak Mun",
              postalCode: 27120,
            },
          ],
        },
        {
          districtNameTh: "วังสมบูรณ์",
          districtNameEn: "Wang Sombun",
          subdistricts: [
            {
              subdistrictNameTh: "วังสมบูรณ์",
              subdistrictNameEn: "Wang Sombun",
              postalCode: 27250,
            },
            {
              subdistrictNameTh: "วังใหม่",
              subdistrictNameEn: "Wang Mai",
              postalCode: 27250,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 27250,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นครราชสีมา",
      stateNameEn: "Nakhon Ratchasima",
      districts: [
        {
          districtNameTh: "เมืองนครราชสีมา",
          districtNameEn: "Mueang Nakhon Ratchasima",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "โพธิ์กลาง",
              subdistrictNameEn: "Pho Klang",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หนองจะบก",
              subdistrictNameEn: "Nong Chabok",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "โคกสูง",
              subdistrictNameEn: "Khok Sung",
              postalCode: 30310,
            },
            {
              subdistrictNameTh: "มะเริง",
              subdistrictNameEn: "Maroeng",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หนองระเวียง",
              subdistrictNameEn: "Nong Rawiang",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "ปรุใหญ่",
              subdistrictNameEn: "Pru Yai",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หมื่นไวย",
              subdistrictNameEn: "Muen Wai",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "พลกรัง",
              subdistrictNameEn: "Phon Krang",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หนองไผ่ล้อม",
              subdistrictNameEn: "Nong Phai Lom",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หัวทะเล",
              subdistrictNameEn: "Hua Thale",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "บ้านเกาะ",
              subdistrictNameEn: "Ban Ko",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "พุดซา",
              subdistrictNameEn: "Phut Sa",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "บ้านโพธิ์",
              subdistrictNameEn: "Ban Pho",
              postalCode: 30310,
            },
            {
              subdistrictNameTh: "จอหอ",
              subdistrictNameEn: "Cho Ho",
              postalCode: 30310,
            },
            {
              subdistrictNameTh: "โคกกรวด",
              subdistrictNameEn: "Khok Kruat",
              postalCode: 30280,
            },
            {
              subdistrictNameTh: "ไชยมงคล",
              subdistrictNameEn: "Chai Mongkhon",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หนองบัวศาลา",
              subdistrictNameEn: "Nong Bua Sala",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "สุรนารี",
              subdistrictNameEn: "Suranari",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "สีมุม",
              subdistrictNameEn: "Si Mum",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "ตลาด",
              subdistrictNameEn: "Talat",
              postalCode: 30310,
            },
            {
              subdistrictNameTh: "พะเนา",
              subdistrictNameEn: "Phanao",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หนองกระทุ่ม",
              subdistrictNameEn: "Nong Krathum",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หนองไข่น้ำ",
              subdistrictNameEn: "Nong Khai Nam",
              postalCode: 30310,
            },
          ],
        },
        {
          districtNameTh: "ครบุรี",
          districtNameEn: "Khon Buri",
          subdistricts: [
            {
              subdistrictNameTh: "แชะ",
              subdistrictNameEn: "Chae",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "เฉลียง",
              subdistrictNameEn: "Chaliang",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "ครบุรี",
              subdistrictNameEn: "Khon Buri",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "โคกกระชาย",
              subdistrictNameEn: "Khok Krachai",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "จระเข้หิน",
              subdistrictNameEn: "Chorakhe Hin",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "มาบตะโกเอน",
              subdistrictNameEn: "Map Tako En",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "อรพิมพ์",
              subdistrictNameEn: "Oraphim",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "ลำเพียก",
              subdistrictNameEn: "Lamphiak",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "ครบุรีใต้",
              subdistrictNameEn: "Khon Buri Tai",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "ตะแบกบาน",
              subdistrictNameEn: "Tabaek Ban",
              postalCode: 30250,
            },
            {
              subdistrictNameTh: "สระว่านพระยา",
              subdistrictNameEn: "Sa Wan Phraya",
              postalCode: 30250,
            },
          ],
        },
        {
          districtNameTh: "เสิงสาง",
          districtNameEn: "Soeng Sang",
          subdistricts: [
            {
              subdistrictNameTh: "เสิงสาง",
              subdistrictNameEn: "Soeng Sang",
              postalCode: 30330,
            },
            {
              subdistrictNameTh: "สระตะเคียน",
              subdistrictNameEn: "Sa Takhian",
              postalCode: 30330,
            },
            {
              subdistrictNameTh: "โนนสมบูรณ์",
              subdistrictNameEn: "Non Sombun",
              postalCode: 30330,
            },
            {
              subdistrictNameTh: "กุดโบสถ์",
              subdistrictNameEn: "Kut Bot",
              postalCode: 30330,
            },
            {
              subdistrictNameTh: "สุขไพบูลย์",
              subdistrictNameEn: "Suk Phaibun",
              postalCode: 30330,
            },
            {
              subdistrictNameTh: "บ้านราษฎร์",
              subdistrictNameEn: "Ban Rat",
              postalCode: 30330,
            },
          ],
        },
        {
          districtNameTh: "คง",
          districtNameEn: "Khong",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองคง",
              subdistrictNameEn: "Mueang Khong",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "คูขาด",
              subdistrictNameEn: "Khu Khat",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "เทพาลัย",
              subdistrictNameEn: "Thephalai",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "ตาจั่น",
              subdistrictNameEn: "Ta Chan",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "บ้านปรางค์",
              subdistrictNameEn: "Ban Prang",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "หนองมะนาว",
              subdistrictNameEn: "Nong Manao",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "โนนเต็ง",
              subdistrictNameEn: "Non Teng",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "ดอนใหญ่",
              subdistrictNameEn: "Don Yai",
              postalCode: 30260,
            },
            {
              subdistrictNameTh: "ขามสมบูรณ์",
              subdistrictNameEn: "Kham Sombun",
              postalCode: 30260,
            },
          ],
        },
        {
          districtNameTh: "บ้านเหลื่อม",
          districtNameEn: "Ban Lueam",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านเหลื่อม",
              subdistrictNameEn: "Ban Lueam",
              postalCode: 30350,
            },
            {
              subdistrictNameTh: "วังโพธิ์",
              subdistrictNameEn: "Wang Pho",
              postalCode: 30350,
            },
            {
              subdistrictNameTh: "โคกกระเบื้อง",
              subdistrictNameEn: "Khok Krabueang",
              postalCode: 30350,
            },
            {
              subdistrictNameTh: "ช่อระกา",
              subdistrictNameEn: "Cho Raka",
              postalCode: 30350,
            },
          ],
        },
        {
          districtNameTh: "จักราช",
          districtNameEn: "Chakkarat",
          subdistricts: [
            {
              subdistrictNameTh: "จักราช",
              subdistrictNameEn: "Chakkarat",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "ทองหลาง",
              subdistrictNameEn: "Thong Lang",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "สีสุก",
              subdistrictNameEn: "Si Suk",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "หนองขาม",
              subdistrictNameEn: "Nong Kham",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "หนองพลวง",
              subdistrictNameEn: "Nong Phluang",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "ศรีละกอ",
              subdistrictNameEn: "Si Lako",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "คลองเมือง",
              subdistrictNameEn: "Khlong Mueang",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "หินโคน",
              subdistrictNameEn: "Hin Khon",
              postalCode: 30230,
            },
          ],
        },
        {
          districtNameTh: "โชคชัย",
          districtNameEn: "Chok Chai",
          subdistricts: [
            {
              subdistrictNameTh: "กระโทก",
              subdistrictNameEn: "Krathok",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "พลับพลา",
              subdistrictNameEn: "Phlapphla",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "ท่าอ่าง",
              subdistrictNameEn: "Tha Ang",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "ทุ่งอรุณ",
              subdistrictNameEn: "Thung Arun",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "ท่าลาดขาว",
              subdistrictNameEn: "Tha Lat Khao",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "ท่าจะหลุง",
              subdistrictNameEn: "Tha Chalung",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "ท่าเยี่ยม",
              subdistrictNameEn: "Tha Yiam",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "โชคชัย",
              subdistrictNameEn: "Chok Chai",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "ละลมใหม่พัฒนา",
              subdistrictNameEn: "Lalom Mai Phatthana",
              postalCode: 30190,
            },
            {
              subdistrictNameTh: "ด่านเกวียน",
              subdistrictNameEn: "Dan Kwian",
              postalCode: 30190,
            },
          ],
        },
        {
          districtNameTh: "ด่านขุนทด",
          districtNameEn: "Dan Khun Thot",
          subdistricts: [
            {
              subdistrictNameTh: "กุดพิมาน",
              subdistrictNameEn: "Kut Phiman",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "ด่านขุนทด",
              subdistrictNameEn: "Dan Khun Thot",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "ด่านนอก",
              subdistrictNameEn: "Dan Nok",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "ด่านใน",
              subdistrictNameEn: "Dan Nai",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "ตะเคียน",
              subdistrictNameEn: "Takhian",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "บ้านเก่า",
              subdistrictNameEn: "Ban Kao",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "บ้านแปรง",
              subdistrictNameEn: "Ban Praeng",
              postalCode: 36220,
            },
            {
              subdistrictNameTh: "พันชนะ",
              subdistrictNameEn: "Phan Chana",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "สระจรเข้",
              subdistrictNameEn: "Sa Chorakhe",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "หนองกราด",
              subdistrictNameEn: "Nong Krat",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "หนองบัวตะเกียด",
              subdistrictNameEn: "Nong Bua Takiat",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "หนองบัวละคร",
              subdistrictNameEn: "Nong Bua Lakhon",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "หินดาด",
              subdistrictNameEn: "Hin Dat",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "ห้วยบง",
              subdistrictNameEn: "Huai Bong",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "โนนเมืองพัฒนา",
              subdistrictNameEn: "Non Mueang Phatthana",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "หนองไทร",
              subdistrictNameEn: "Nong Sai",
              postalCode: 36220,
            },
          ],
        },
        {
          districtNameTh: "โนนไทย",
          districtNameEn: "Non Thai",
          subdistricts: [
            {
              subdistrictNameTh: "โนนไทย",
              subdistrictNameEn: "Non Thai",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "ด่านจาก",
              subdistrictNameEn: "Dan Chak",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "กำปัง",
              subdistrictNameEn: "Kampang",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "ค้างพลู",
              subdistrictNameEn: "Khang Phlu",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "บ้านวัง",
              subdistrictNameEn: "Ban Wang",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "บัลลังก์",
              subdistrictNameEn: "Banlang",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "สายออ",
              subdistrictNameEn: "Sai-O",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "ถนนโพธิ์",
              subdistrictNameEn: "Thanon Pho",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "มะค่า",
              subdistrictNameEn: "Makha",
              postalCode: 30220,
            },
          ],
        },
        {
          districtNameTh: "โนนสูง",
          districtNameEn: "Non Sung",
          subdistricts: [
            {
              subdistrictNameTh: "โนนสูง",
              subdistrictNameEn: "Non Sung",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ใหม่",
              subdistrictNameEn: "Mai",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "โตนด",
              subdistrictNameEn: "Tanot",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "บิง",
              subdistrictNameEn: "Bing",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ดอนชมพู",
              subdistrictNameEn: "Don Chomphu",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ธารปราสาท",
              subdistrictNameEn: "Than Prasat",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "หลุมข้าว",
              subdistrictNameEn: "Lum Khao",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "มะค่า",
              subdistrictNameEn: "Makha",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "พลสงคราม",
              subdistrictNameEn: "Phon Songkhram",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "จันอัด",
              subdistrictNameEn: "Chan-At",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ขามเฒ่า",
              subdistrictNameEn: "Kham Thao",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ด่านคล้า",
              subdistrictNameEn: "Dan Khla",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ลำคอหงษ์",
              subdistrictNameEn: "Lam Kho Hong",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "เมืองปราสาท",
              subdistrictNameEn: "Mueang Prasat",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ดอนหวาย",
              subdistrictNameEn: "Don Wai",
              postalCode: 30160,
            },
            {
              subdistrictNameTh: "ลำมูล",
              subdistrictNameEn: "Lammun",
              postalCode: 30160,
            },
          ],
        },
        {
          districtNameTh: "ขามสะแกแสง",
          districtNameEn: "Kham Sakaesaeng",
          subdistricts: [
            {
              subdistrictNameTh: "ขามสะแกแสง",
              subdistrictNameEn: "Kham Sakae Saeng",
              postalCode: 30290,
            },
            {
              subdistrictNameTh: "โนนเมือง",
              subdistrictNameEn: "Non Mueang",
              postalCode: 30290,
            },
            {
              subdistrictNameTh: "เมืองนาท",
              subdistrictNameEn: "Mueang Nat",
              postalCode: 30290,
            },
            {
              subdistrictNameTh: "ชีวึก",
              subdistrictNameEn: "Chiwuek",
              postalCode: 30290,
            },
            {
              subdistrictNameTh: "พะงาด",
              subdistrictNameEn: "Phangat",
              postalCode: 30290,
            },
            {
              subdistrictNameTh: "หนองหัวฟาน",
              subdistrictNameEn: "Nong Hua Fan",
              postalCode: 30290,
            },
            {
              subdistrictNameTh: "เมืองเกษตร",
              subdistrictNameEn: "Mueang Kaset",
              postalCode: 30290,
            },
          ],
        },
        {
          districtNameTh: "บัวใหญ่",
          districtNameEn: "Bua Yai",
          subdistricts: [
            {
              subdistrictNameTh: "บัวใหญ่",
              subdistrictNameEn: "Bua Yai",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "ห้วยยาง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "เสมาใหญ่",
              subdistrictNameEn: "Sema Yai",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "ดอนตะหนิน",
              subdistrictNameEn: "Don Tanin",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "หนองบัวสะอาด",
              subdistrictNameEn: "Nong Bua Sa-At",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "โนนทองหลาง",
              subdistrictNameEn: "Non Thong Lang",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "กุดจอก",
              subdistrictNameEn: "Kut Chok",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "ด่านช้าง",
              subdistrictNameEn: "Dan Chang",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "ขุนทอง",
              subdistrictNameEn: "Khun Thong",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "หนองแจ้งใหญ่",
              subdistrictNameEn: "Nong Chaeng Yai",
              postalCode: 30120,
            },
          ],
        },
        {
          districtNameTh: "ประทาย",
          districtNameEn: "Prathai",
          subdistricts: [
            {
              subdistrictNameTh: "ประทาย",
              subdistrictNameEn: "Prathai",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "กระทุ่มราย",
              subdistrictNameEn: "Krathum Rai",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "วังไม้แดง",
              subdistrictNameEn: "Wang Mai Daeng",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "ตลาดไทร",
              subdistrictNameEn: "Talat Sai",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "หนองพลวง",
              subdistrictNameEn: "Nong Phluang",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "หนองค่าย",
              subdistrictNameEn: "Nong Khai",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "หันห้วยทราย",
              subdistrictNameEn: "Han Huai Sai",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "ดอนมัน",
              subdistrictNameEn: "Don Man",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "นางรำ",
              subdistrictNameEn: "Nang Ram",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "โนนเพ็ด",
              subdistrictNameEn: "Non Phet",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "ทุ่งสว่าง",
              subdistrictNameEn: "Thung Sawang",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "โคกกลาง",
              subdistrictNameEn: "Khok Klang",
              postalCode: 30180,
            },
            {
              subdistrictNameTh: "เมืองโดน",
              subdistrictNameEn: "Mueang Don",
              postalCode: 30180,
            },
          ],
        },
        {
          districtNameTh: "ปักธงชัย",
          districtNameEn: "Pak Thong Chai",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองปัก",
              subdistrictNameEn: "Mueang Pak",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "ตะคุ",
              subdistrictNameEn: "Takhu",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "โคกไทย",
              subdistrictNameEn: "Khok Thai",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "ตะขบ",
              subdistrictNameEn: "Takhop",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "นกออก",
              subdistrictNameEn: "Nok Ok",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "ดอน",
              subdistrictNameEn: "Don",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "ตูม",
              subdistrictNameEn: "Tum",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "งิ้ว",
              subdistrictNameEn: "Ngio",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "สะแกราช",
              subdistrictNameEn: "Sakae Rat",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "ลำนางแก้ว",
              subdistrictNameEn: "Lam Nang Kaeo",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "ภูหลวง",
              subdistrictNameEn: "Phu Luang",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "ธงชัยหนือ",
              subdistrictNameEn: "Thong Chai Nuea",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "สุขเกษม",
              subdistrictNameEn: "Suk Kasem",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "เกษมทรัพย์",
              subdistrictNameEn: "Kasem Sap",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "บ่อปลาทอง",
              subdistrictNameEn: "Bo Pla Thong",
              postalCode: 30150,
            },
          ],
        },
        {
          districtNameTh: "พิมาย",
          districtNameEn: "Phimai",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "สัมฤทธิ์",
              subdistrictNameEn: "Samrit",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "โบสถ์",
              subdistrictNameEn: "Bot",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "กระเบื้องใหญ่",
              subdistrictNameEn: "Krabueang Yai",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "ท่าหลวง",
              subdistrictNameEn: "Tha Luang",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "รังกาใหญ่",
              subdistrictNameEn: "Rang Ka Yai",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "ชีวาน",
              subdistrictNameEn: "Chiwan",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "นิคมสร้างตนเอง",
              subdistrictNameEn: "Nikhom Sang Ton-Eng",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "กระชอน",
              subdistrictNameEn: "Krachon",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "ดงใหญ่",
              subdistrictNameEn: "Dong Yai",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "ธารละหลอด",
              subdistrictNameEn: "Than Lalot",
              postalCode: 30110,
            },
            {
              subdistrictNameTh: "หนองระเวียง",
              subdistrictNameEn: "Nong Rawiang",
              postalCode: 30110,
            },
          ],
        },
        {
          districtNameTh: "ห้วยแถลง",
          districtNameEn: "Huai Thalaeng",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยแถลง",
              subdistrictNameEn: "Huai Thalaeng",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "ทับสวาย",
              subdistrictNameEn: "Thap Sawai",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "เมืองพลับพลา",
              subdistrictNameEn: "Mueang Phlapphla",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "หลุ่งตะเคียน",
              subdistrictNameEn: "Lung Takhian",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "หินดาด",
              subdistrictNameEn: "Hin Dat",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "งิ้ว",
              subdistrictNameEn: "Ngio",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "กงรถ",
              subdistrictNameEn: "Kong Rot",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "หลุ่งประดู่",
              subdistrictNameEn: "Lung Pradu",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "ตะโก",
              subdistrictNameEn: "Tako",
              postalCode: 30240,
            },
            {
              subdistrictNameTh: "ห้วยแคน",
              subdistrictNameEn: "Huai Khaen",
              postalCode: 30240,
            },
          ],
        },
        {
          districtNameTh: "ชุมพวง",
          districtNameEn: "Chum Phuang",
          subdistricts: [
            {
              subdistrictNameTh: "ชุมพวง",
              subdistrictNameEn: "Chum Phuang",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "ประสุข",
              subdistrictNameEn: "Prasuk",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "ท่าลาด",
              subdistrictNameEn: "Tha Lat",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "สาหร่าย",
              subdistrictNameEn: "Sarai",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "ตลาดไทร",
              subdistrictNameEn: "Talat Sai",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "โนนรัง",
              subdistrictNameEn: "Non Rang",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "หนองหลัก",
              subdistrictNameEn: "Nong Lak",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "โนนตูม",
              subdistrictNameEn: "Non Tum",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "โนนยอ",
              subdistrictNameEn: "Non Yo",
              postalCode: 30270,
            },
          ],
        },
        {
          districtNameTh: "สูงเนิน",
          districtNameEn: "Sung Noen",
          subdistricts: [
            {
              subdistrictNameTh: "สูงเนิน",
              subdistrictNameEn: "Sung Noen",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "เสมา",
              subdistrictNameEn: "Sema",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "โคราช",
              subdistrictNameEn: "Khorat",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "บุ่งขี้เหล็ก",
              subdistrictNameEn: "Bung Khi Lek",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "โนนค่า",
              subdistrictNameEn: "Non Kha",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "โค้งยาง",
              subdistrictNameEn: "Khong Yang",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "มะเกลือเก่า",
              subdistrictNameEn: "Makluea Kao",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "มะเกลือใหม่",
              subdistrictNameEn: "Makluea Mai",
              postalCode: 30170,
            },
            {
              subdistrictNameTh: "นากลาง",
              subdistrictNameEn: "Na Klang",
              postalCode: 30380,
            },
            {
              subdistrictNameTh: "หนองตะไก้",
              subdistrictNameEn: "Nong Takai",
              postalCode: 30380,
            },
            {
              subdistrictNameTh: "กุดจิก",
              subdistrictNameEn: "Kut Chik",
              postalCode: 30380,
            },
          ],
        },
        {
          districtNameTh: "ขามทะเลสอ",
          districtNameEn: "Kham Thale So",
          subdistricts: [
            {
              subdistrictNameTh: "ขามทะเลสอ",
              subdistrictNameEn: "Kham Thale So",
              postalCode: 30280,
            },
            {
              subdistrictNameTh: "โป่งแดง",
              subdistrictNameEn: "Pong Daeng",
              postalCode: 30280,
            },
            {
              subdistrictNameTh: "พันดุง",
              subdistrictNameEn: "Phan Dung",
              postalCode: 30280,
            },
            {
              subdistrictNameTh: "หนองสรวง",
              subdistrictNameEn: "Nong Suang",
              postalCode: 30280,
            },
            {
              subdistrictNameTh: "บึงอ้อ",
              subdistrictNameEn: "Bueng O",
              postalCode: 30280,
            },
          ],
        },
        {
          districtNameTh: "สีคิ้ว",
          districtNameEn: "Si Khio",
          subdistricts: [
            {
              subdistrictNameTh: "สีคิ้ว",
              subdistrictNameEn: "Sikhio",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "บ้านหัน",
              subdistrictNameEn: "Ban Han",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "กฤษณา",
              subdistrictNameEn: "Kritsana",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "ลาดบัวขาว",
              subdistrictNameEn: "Lat Bua Khao",
              postalCode: 30340,
            },
            {
              subdistrictNameTh: "หนองหญ้าขาว",
              subdistrictNameEn: "Nong Ya Khao",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "กุดน้อย",
              subdistrictNameEn: "Kut Noi",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "หนองน้ำใส",
              subdistrictNameEn: "Nong Nam Sai",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "วังโรงใหญ่",
              subdistrictNameEn: "Wang Rong Yai",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "มิตรภาพ",
              subdistrictNameEn: "Mittraphap",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "คลองไผ่",
              subdistrictNameEn: "Khlong Phai",
              postalCode: 30340,
            },
            {
              subdistrictNameTh: "ดอนเมือง",
              subdistrictNameEn: "Don Mueang",
              postalCode: 30140,
            },
            {
              subdistrictNameTh: "หนองบัวน้อย",
              subdistrictNameEn: "Nong Bua Noi",
              postalCode: 30140,
            },
          ],
        },
        {
          districtNameTh: "ปากช่อง",
          districtNameEn: "Pak Chong",
          subdistricts: [
            {
              subdistrictNameTh: "ปากช่อง",
              subdistrictNameEn: "Pak Chong",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "กลางดง",
              subdistrictNameEn: "Klang Dong",
              postalCode: 30320,
            },
            {
              subdistrictNameTh: "จันทึก",
              subdistrictNameEn: "Chan Thuek",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "วังกะทะ",
              subdistrictNameEn: "Wang Katha",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "หมูสี",
              subdistrictNameEn: "Mu Si",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "หนองสาหร่าย",
              subdistrictNameEn: "Nong Sarai",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "ขนงพระ",
              subdistrictNameEn: "Khanong Phra",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "โป่งตาลอง",
              subdistrictNameEn: "Pong Ta Long",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "คลองม่วง",
              subdistrictNameEn: "Khlong Muang",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "หนองน้ำแดง",
              subdistrictNameEn: "Nong Nam Daeng",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "วังไทร",
              subdistrictNameEn: "Wang Sai",
              postalCode: 30130,
            },
            {
              subdistrictNameTh: "พญาเย็น",
              subdistrictNameEn: "Phaya Yen",
              postalCode: 30320,
            },
          ],
        },
        {
          districtNameTh: "หนองบุญมาก",
          districtNameEn: "Nong Bunmak",
          subdistricts: [
            {
              subdistrictNameTh: "หนองบุนนาก",
              subdistrictNameEn: "Nong Bunnak",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "สารภี",
              subdistrictNameEn: "Saraphi",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "ไทยเจริญ",
              subdistrictNameEn: "Thai Charoen",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "หนองหัวแรต",
              subdistrictNameEn: "Nong Hua Raet",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "แหลมทอง",
              subdistrictNameEn: "Laem Thong",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "หนองตะไก้",
              subdistrictNameEn: "Nong Takai",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "ลุงเขว้า",
              subdistrictNameEn: "Lung Khwao",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "หนองไม้ไผ่",
              subdistrictNameEn: "Nong Mai Phai",
              postalCode: 30410,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 30410,
            },
          ],
        },
        {
          districtNameTh: "แก้งสนามนาง",
          districtNameEn: "Kaeng Sanam Nang",
          subdistricts: [
            {
              subdistrictNameTh: "แก้งสนามนาง",
              subdistrictNameEn: "Kaeng Sanam Nang",
              postalCode: 30440,
            },
            {
              subdistrictNameTh: "โนนสำราญ",
              subdistrictNameEn: "Non Samran",
              postalCode: 30440,
            },
            {
              subdistrictNameTh: "บึงพะไล",
              subdistrictNameEn: "Bueng Phalai",
              postalCode: 30440,
            },
            {
              subdistrictNameTh: "สีสุก",
              subdistrictNameEn: "Si Suk",
              postalCode: 30440,
            },
            {
              subdistrictNameTh: "บึงสำโรง",
              subdistrictNameEn: "Bueng Samrong",
              postalCode: 30440,
            },
          ],
        },
        {
          districtNameTh: "โนนแดง",
          districtNameEn: "Non Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "โนนแดง",
              subdistrictNameEn: "Non Daeng",
              postalCode: 30360,
            },
            {
              subdistrictNameTh: "โนนตาเถร",
              subdistrictNameEn: "Non Ta Then",
              postalCode: 30360,
            },
            {
              subdistrictNameTh: "สำพะเนียง",
              subdistrictNameEn: "Sam Phaniang",
              postalCode: 30360,
            },
            {
              subdistrictNameTh: "วังหิน",
              subdistrictNameEn: "Wang Hin",
              postalCode: 30360,
            },
            {
              subdistrictNameTh: "ดอนยาวใหญ่",
              subdistrictNameEn: "Don Yao Yai",
              postalCode: 30360,
            },
          ],
        },
        {
          districtNameTh: "วังน้ำเขียว",
          districtNameEn: "Wang Nam Khiao",
          subdistricts: [
            {
              subdistrictNameTh: "วังน้ำเขียว",
              subdistrictNameEn: "Wang Nam Khiao",
              postalCode: 30370,
            },
            {
              subdistrictNameTh: "วังหมี",
              subdistrictNameEn: "Wang Mi",
              postalCode: 30370,
            },
            {
              subdistrictNameTh: "ระเริง",
              subdistrictNameEn: "Raroeng",
              postalCode: 30150,
            },
            {
              subdistrictNameTh: "อุดมทรัพย์",
              subdistrictNameEn: "Udom Sap",
              postalCode: 30370,
            },
            {
              subdistrictNameTh: "ไทยสามัคคี",
              subdistrictNameEn: "Thai Samakkhi",
              postalCode: 30370,
            },
          ],
        },
        {
          districtNameTh: "เทพารักษ์",
          districtNameEn: "Thepharak",
          subdistricts: [
            {
              subdistrictNameTh: "สำนักตะคร้อ",
              subdistrictNameEn: "Samnak Takhro",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "บึงปรือ",
              subdistrictNameEn: "Bueng Prue",
              postalCode: 30210,
            },
            {
              subdistrictNameTh: "วังยายทอง",
              subdistrictNameEn: "Wang Yai Thong",
              postalCode: 30210,
            },
          ],
        },
        {
          districtNameTh: "เมืองยาง",
          districtNameEn: "Mueang Yang",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองยาง",
              subdistrictNameEn: "Mueang Yang",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "กระเบื้องนอก",
              subdistrictNameEn: "Krabueang Nok",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "ละหานปลาค้าว",
              subdistrictNameEn: "Lahan Pla Khao",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "โนนอุดม",
              subdistrictNameEn: "Non Udom",
              postalCode: 30270,
            },
          ],
        },
        {
          districtNameTh: "พระทองคำ",
          districtNameEn: "Phra Thong Kham",
          subdistricts: [
            {
              subdistrictNameTh: "สระพระ",
              subdistrictNameEn: "Sa Phra",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "มาบกราด",
              subdistrictNameEn: "Map Krat",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "พังเทียม",
              subdistrictNameEn: "Phang Thiam",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "ทัพรั้ง",
              subdistrictNameEn: "Thap Rang",
              postalCode: 30220,
            },
            {
              subdistrictNameTh: "หนองหอย",
              subdistrictNameEn: "Nong Hoi",
              postalCode: 30220,
            },
          ],
        },
        {
          districtNameTh: "ลำทะเมนชัย",
          districtNameEn: "Lamtaman Chai",
          subdistricts: [
            {
              subdistrictNameTh: "ขุย",
              subdistrictNameEn: "Khui",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "ช่องแมว",
              subdistrictNameEn: "Chong Maeo",
              postalCode: 30270,
            },
            {
              subdistrictNameTh: "ไพล",
              subdistrictNameEn: "Phrai",
              postalCode: 30270,
            },
          ],
        },
        {
          districtNameTh: "บัวลาย",
          districtNameEn: "Bua Lai",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองพะไล",
              subdistrictNameEn: "Mueang Phalai",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "โนนจาน",
              subdistrictNameEn: "Non Chan",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "บัวลาย",
              subdistrictNameEn: "Bua Lai",
              postalCode: 30120,
            },
            {
              subdistrictNameTh: "หนองหว้า",
              subdistrictNameEn: "Nong Wa",
              postalCode: 30120,
            },
          ],
        },
        {
          districtNameTh: "สีดา",
          districtNameEn: "Sida",
          subdistricts: [
            {
              subdistrictNameTh: "สีดา",
              subdistrictNameEn: "Sida",
              postalCode: 30430,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 30430,
            },
            {
              subdistrictNameTh: "โนนประดู่",
              subdistrictNameEn: "Non Pradu",
              postalCode: 30430,
            },
            {
              subdistrictNameTh: "สามเมือง",
              subdistrictNameEn: "Sam Mueang",
              postalCode: 30430,
            },
            {
              subdistrictNameTh: "หนองตาดใหญ่",
              subdistrictNameEn: "Nong Tat Yai",
              postalCode: 30430,
            },
          ],
        },
        {
          districtNameTh: "เฉลิมพระเกียรติ",
          districtNameEn: "Chaloem Phra Kiet",
          subdistricts: [
            {
              subdistrictNameTh: "ช้างทอง",
              subdistrictNameEn: "Chang Thong",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "พระพุทธ",
              subdistrictNameEn: "Phraphut",
              postalCode: 30230,
            },
            {
              subdistrictNameTh: "หนองงูเหลือม",
              subdistrictNameEn: "Nong Ngu Lueam",
              postalCode: 30000,
            },
            {
              subdistrictNameTh: "หนองยาง",
              subdistrictNameEn: "Nong Yang",
              postalCode: 30230,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "บุรีรัมย์",
      stateNameEn: "Buri Ram",
      districts: [
        {
          districtNameTh: "เมืองบุรีรัมย์",
          districtNameEn: "Mueang Buri Ram",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "อิสาณ",
              subdistrictNameEn: "I San",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "เสม็ด",
              subdistrictNameEn: "Samet",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "บ้านบัว",
              subdistrictNameEn: "Ban Bua",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "สะแกโพรง",
              subdistrictNameEn: "Sakae Phrong",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "สวายจีก",
              subdistrictNameEn: "Sawai Chik",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "พระครู",
              subdistrictNameEn: "Phrakhru",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "ถลุงเหล็ก",
              subdistrictNameEn: "Thalung Lek",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "หนองตาด",
              subdistrictNameEn: "Nong Tat",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "ลุมปุ๊ก",
              subdistrictNameEn: "Lumpuk",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "สองห้อง",
              subdistrictNameEn: "Song Hong",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "บัวทอง",
              subdistrictNameEn: "Bua Thong",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "ชุมเห็ด",
              subdistrictNameEn: "Chum Het",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "หลักเขต",
              subdistrictNameEn: "Lak Khet",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "สะแกซำ",
              subdistrictNameEn: "Sakae Sam",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "กลันทา",
              subdistrictNameEn: "Kalantha",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "กระสัง",
              subdistrictNameEn: "Krasang",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "เมืองฝาง",
              subdistrictNameEn: "Mueang Fang",
              postalCode: 31000,
            },
          ],
        },
        {
          districtNameTh: "คูเมือง",
          districtNameEn: "Khu Mueang",
          subdistricts: [
            {
              subdistrictNameTh: "คูเมือง",
              subdistrictNameEn: "Khu Mueang",
              postalCode: 31190,
            },
            {
              subdistrictNameTh: "ปะเคียบ",
              subdistrictNameEn: "Pakhiap",
              postalCode: 31190,
            },
            {
              subdistrictNameTh: "บ้านแพ",
              subdistrictNameEn: "Ban Phae",
              postalCode: 31190,
            },
            {
              subdistrictNameTh: "พรสำราญ",
              subdistrictNameEn: "Phon Samran",
              postalCode: 31190,
            },
            {
              subdistrictNameTh: "หินเหล็กไฟ",
              subdistrictNameEn: "Hin Lek Fai",
              postalCode: 31190,
            },
            {
              subdistrictNameTh: "ตูมใหญ่",
              subdistrictNameEn: "Tum Yai",
              postalCode: 31190,
            },
            {
              subdistrictNameTh: "หนองขมาร",
              subdistrictNameEn: "Nong Khaman",
              postalCode: 31190,
            },
          ],
        },
        {
          districtNameTh: "กระสัง",
          districtNameEn: "Krasang",
          subdistricts: [
            {
              subdistrictNameTh: "กระสัง",
              subdistrictNameEn: "Krasang",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "ลำดวน",
              subdistrictNameEn: "Lamduan",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "สองชั้น",
              subdistrictNameEn: "Song Chan",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "สูงเนิน",
              subdistrictNameEn: "Sung Noen",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "หนองเต็ง",
              subdistrictNameEn: "Nong Teng",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "เมืองไผ่",
              subdistrictNameEn: "Mueang Phai",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "ชุมแสง",
              subdistrictNameEn: "Chum Saeng",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "บ้านปรือ",
              subdistrictNameEn: "Ban Prue",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "ห้วยสำราญ",
              subdistrictNameEn: "Huai Samran",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "กันทรารมย์",
              subdistrictNameEn: "Kanthararom",
              postalCode: 31160,
            },
            {
              subdistrictNameTh: "ศรีภูมิ",
              subdistrictNameEn: "Si Phum",
              postalCode: 31160,
            },
          ],
        },
        {
          districtNameTh: "นางรอง",
          districtNameEn: "Nang Rong",
          subdistricts: [
            {
              subdistrictNameTh: "นางรอง",
              subdistrictNameEn: "Nang Rong",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "สะเดา",
              subdistrictNameEn: "Sadao",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ชุมแสง",
              subdistrictNameEn: "Chum Saeng",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "หนองโบสถ์",
              subdistrictNameEn: "Nong Bot",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "หนองกง",
              subdistrictNameEn: "Nong Kong",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ถนนหัก",
              subdistrictNameEn: "Thanon Hak",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "หนองไทร",
              subdistrictNameEn: "Nong Sai",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ก้านเหลือง",
              subdistrictNameEn: "Kan Lueang",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "บ้านสิงห์",
              subdistrictNameEn: "Ban Sing",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ลำไทรโยง",
              subdistrictNameEn: "Lam Sai Yong",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ทรัพย์พระยา",
              subdistrictNameEn: "Sap Phraya",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "หนองยายพิมพ์",
              subdistrictNameEn: "Nong Yai Phim",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "หัวถนน",
              subdistrictNameEn: "Hua Thanon",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ทุ่งแสงทอง",
              subdistrictNameEn: "Thung Saeng Thong",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "หนองโสน",
              subdistrictNameEn: "Nong Sano",
              postalCode: 31110,
            },
          ],
        },
        {
          districtNameTh: "หนองกี่",
          districtNameEn: "Nong Ki",
          subdistricts: [
            {
              subdistrictNameTh: "หนองกี่",
              subdistrictNameEn: "Nong Ki",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "เย้ยปราสาท",
              subdistrictNameEn: "Yoei Prasat",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "เมืองไผ่",
              subdistrictNameEn: "Mueang Phai",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "ดอนอะราง",
              subdistrictNameEn: "Don Arang",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "โคกสว่าง",
              subdistrictNameEn: "Khok Sawang",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "ทุ่งกระตาดพัฒนา",
              subdistrictNameEn: "Thung Kratat Phatthana",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "ทุ่งกระเต็น",
              subdistrictNameEn: "Thung Kraten",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "ท่าโพธิ์ชัย",
              subdistrictNameEn: "Tha Pho Chai",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "โคกสูง",
              subdistrictNameEn: "Khok Sung",
              postalCode: 31210,
            },
            {
              subdistrictNameTh: "บุกระสัง",
              subdistrictNameEn: "Bu Krasang",
              postalCode: 31210,
            },
          ],
        },
        {
          districtNameTh: "ละหานทราย",
          districtNameEn: "Lahan Sai",
          subdistricts: [
            {
              subdistrictNameTh: "ละหานทราย",
              subdistrictNameEn: "Lahan Sai",
              postalCode: 31170,
            },
            {
              subdistrictNameTh: "ตาจง",
              subdistrictNameEn: "Ta Chong",
              postalCode: 31170,
            },
            {
              subdistrictNameTh: "สำโรงใหม่",
              subdistrictNameEn: "Samrong Mai",
              postalCode: 31170,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 31170,
            },
            {
              subdistrictNameTh: "หนองตะครอง",
              subdistrictNameEn: "Nong Takhrong",
              postalCode: 31170,
            },
            {
              subdistrictNameTh: "โคกว่าน",
              subdistrictNameEn: "Khok Wan",
              postalCode: 31170,
            },
          ],
        },
        {
          districtNameTh: "ประโคนชัย",
          districtNameEn: "Prakhon Chai",
          subdistricts: [
            {
              subdistrictNameTh: "ประโคนชัย",
              subdistrictNameEn: "Prakhon Chai",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "แสลงโทน",
              subdistrictNameEn: "Salaeng Thon",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "บ้านไทร",
              subdistrictNameEn: "Ban Sai",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "ละเวี้ย",
              subdistrictNameEn: "Lawia",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "จรเข้มาก",
              subdistrictNameEn: "Chorakhe Mak",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "ปังกู",
              subdistrictNameEn: "Pang Ku",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "โคกย่าง",
              subdistrictNameEn: "Khok Yang",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "โคกม้า",
              subdistrictNameEn: "Khok Ma",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "ไพศาล",
              subdistrictNameEn: "Phaisan",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "ตะโกตาพิ",
              subdistrictNameEn: "Tako Taphi",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "เขาคอก",
              subdistrictNameEn: "Khao Khok",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "หนองบอน",
              subdistrictNameEn: "Nong Bon",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "โคกมะขาม",
              subdistrictNameEn: "Khok Makham",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "โคกตูม",
              subdistrictNameEn: "Khok Tum",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "ประทัดบุ",
              subdistrictNameEn: "Prathat Bu",
              postalCode: 31140,
            },
            {
              subdistrictNameTh: "สี่เหลี่ยม",
              subdistrictNameEn: "Si Liam",
              postalCode: 31140,
            },
          ],
        },
        {
          districtNameTh: "บ้านกรวด",
          districtNameEn: "Ban Kruat",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านกรวด",
              subdistrictNameEn: "Ban Kruat",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "โนนเจริญ",
              subdistrictNameEn: "Non Charoen",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "หนองไม้งาม",
              subdistrictNameEn: "Nong Mai Ngam",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "ปราสาท",
              subdistrictNameEn: "Prasat",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "สายตะกู",
              subdistrictNameEn: "Sai Taku",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "หินลาด",
              subdistrictNameEn: "Hin Lat",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "บึงเจริญ",
              subdistrictNameEn: "Bueng Charoen",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "จันทบเพชร",
              subdistrictNameEn: "Chanthabophet",
              postalCode: 31180,
            },
            {
              subdistrictNameTh: "เขาดินเหนือ",
              subdistrictNameEn: "Khao Din Nuea",
              postalCode: 31180,
            },
          ],
        },
        {
          districtNameTh: "พุทไธสง",
          districtNameEn: "Phutthaisong",
          subdistricts: [
            {
              subdistrictNameTh: "พุทไธสง",
              subdistrictNameEn: "Phutthaisong",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "มะเฟือง",
              subdistrictNameEn: "Mafueang",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "บ้านจาน",
              subdistrictNameEn: "Ban Chan",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "บ้านเป้า",
              subdistrictNameEn: "Ban Pao",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "บ้านแวง",
              subdistrictNameEn: "Ban Waeng",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "หายโศก",
              subdistrictNameEn: "Hai Sok",
              postalCode: 31120,
            },
          ],
        },
        {
          districtNameTh: "ลำปลายมาศ",
          districtNameEn: "Lam Plai Mat",
          subdistricts: [
            {
              subdistrictNameTh: "ลำปลายมาศ",
              subdistrictNameEn: "Lam Plai Mat",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "หนองคู",
              subdistrictNameEn: "Nong Khu",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "แสลงพัน",
              subdistrictNameEn: "Salaeng Phan",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "ทะเมนชัย",
              subdistrictNameEn: "Thamenchai",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "ตลาดโพธิ์",
              subdistrictNameEn: "Talat Pho",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "หนองกะทิง",
              subdistrictNameEn: "Nong Kathing",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "โคกกลาง",
              subdistrictNameEn: "Khok Klang",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "เมืองแฝก",
              subdistrictNameEn: "Mueang Faek",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "ผไทรินทร์",
              subdistrictNameEn: "Phathai Rin",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "โคกล่าม",
              subdistrictNameEn: "Khok Lam",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "หินโคน",
              subdistrictNameEn: "Hin Khon",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "หนองบัวโคก",
              subdistrictNameEn: "Nong Bua Khok",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "บุโพธิ์",
              subdistrictNameEn: "Bu Pho",
              postalCode: 31130,
            },
            {
              subdistrictNameTh: "หนองโดน",
              subdistrictNameEn: "Nong Don",
              postalCode: 31130,
            },
          ],
        },
        {
          districtNameTh: "สตึก",
          districtNameEn: "Satuek",
          subdistricts: [
            {
              subdistrictNameTh: "สตึก",
              subdistrictNameEn: "Satuek",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "นิคม",
              subdistrictNameEn: "Nikhom",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "ทุ่งวัง",
              subdistrictNameEn: "Thung Wang",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "เมืองแก",
              subdistrictNameEn: "Mueang Kae",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "หนองใหญ่",
              subdistrictNameEn: "Nong Yai",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "ร่อนทอง",
              subdistrictNameEn: "Ron Thong",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "ดอนมนต์",
              subdistrictNameEn: "Don Mon",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "ชุมแสง",
              subdistrictNameEn: "Chum Saeng",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "ท่าม่วง",
              subdistrictNameEn: "Tha Muang",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "สะแก",
              subdistrictNameEn: "Sakae",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "สนามชัย",
              subdistrictNameEn: "Sanam Chai",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "กระสัง",
              subdistrictNameEn: "Krasang",
              postalCode: 31150,
            },
          ],
        },
        {
          districtNameTh: "ปะคำ",
          districtNameEn: "Pakham",
          subdistricts: [
            {
              subdistrictNameTh: "ปะคำ",
              subdistrictNameEn: "Pakham",
              postalCode: 31220,
            },
            {
              subdistrictNameTh: "ไทยเจริญ",
              subdistrictNameEn: "Thai Charoen",
              postalCode: 31220,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 31220,
            },
            {
              subdistrictNameTh: "โคกมะม่วง",
              subdistrictNameEn: "Khok Mamuang",
              postalCode: 31220,
            },
            {
              subdistrictNameTh: "หูทำนบ",
              subdistrictNameEn: "Hu Thamnop",
              postalCode: 31220,
            },
          ],
        },
        {
          districtNameTh: "นาโพธิ์",
          districtNameEn: "Na Pho",
          subdistricts: [
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 31230,
            },
            {
              subdistrictNameTh: "บ้านคู",
              subdistrictNameEn: "Ban Khu",
              postalCode: 31230,
            },
            {
              subdistrictNameTh: "บ้านดู่",
              subdistrictNameEn: "Ban Du",
              postalCode: 31230,
            },
            {
              subdistrictNameTh: "ดอนกอก",
              subdistrictNameEn: "Don Kok",
              postalCode: 31230,
            },
            {
              subdistrictNameTh: "ศรีสว่าง",
              subdistrictNameEn: "Si Sawang",
              postalCode: 31230,
            },
          ],
        },
        {
          districtNameTh: "หนองหงส์",
          districtNameEn: "Nong Hong",
          subdistricts: [
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 31240,
            },
            {
              subdistrictNameTh: "ห้วยหิน",
              subdistrictNameEn: "Huai Hin",
              postalCode: 31240,
            },
            {
              subdistrictNameTh: "ไทยสามัคคี",
              subdistrictNameEn: "Thai Samakkhi",
              postalCode: 31240,
            },
            {
              subdistrictNameTh: "หนองชัยศรี",
              subdistrictNameEn: "Nong Chai Si",
              postalCode: 31240,
            },
            {
              subdistrictNameTh: "เสาเดียว",
              subdistrictNameEn: "Sao Diao",
              postalCode: 31240,
            },
            {
              subdistrictNameTh: "เมืองฝ้าย",
              subdistrictNameEn: "Mueang Fai",
              postalCode: 31240,
            },
            {
              subdistrictNameTh: "สระทอง",
              subdistrictNameEn: "Sa Thong",
              postalCode: 31240,
            },
          ],
        },
        {
          districtNameTh: "พลับพลาชัย",
          districtNameEn: "Phlapphla Chai",
          subdistricts: [
            {
              subdistrictNameTh: "จันดุม",
              subdistrictNameEn: "Chan Dum",
              postalCode: 31250,
            },
            {
              subdistrictNameTh: "โคกขมิ้น",
              subdistrictNameEn: "Khok Khamin",
              postalCode: 31250,
            },
            {
              subdistrictNameTh: "ป่าชัน",
              subdistrictNameEn: "Pa Chan",
              postalCode: 31250,
            },
            {
              subdistrictNameTh: "สะเดา",
              subdistrictNameEn: "Sadao",
              postalCode: 31250,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 31250,
            },
          ],
        },
        {
          districtNameTh: "ห้วยราช",
          districtNameEn: "Huai Rat",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยราช",
              subdistrictNameEn: "Huai Rat",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "สามแวง",
              subdistrictNameEn: "Sam Waeng",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "ตาเสา",
              subdistrictNameEn: "Ta Sao",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "บ้านตะโก",
              subdistrictNameEn: "Ban Tako",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "สนวน",
              subdistrictNameEn: "Sanuan",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "โคกเหล็ก",
              subdistrictNameEn: "Khok Lek",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "เมืองโพธิ์",
              subdistrictNameEn: "Mueang Pho",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "ห้วยราชา",
              subdistrictNameEn: "Huai Racha",
              postalCode: 31000,
            },
          ],
        },
        {
          districtNameTh: "โนนสุวรรณ",
          districtNameEn: "Non Din Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "โนนสุวรรณ",
              subdistrictNameEn: "Non Suwan",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ทุ่งจังหัน",
              subdistrictNameEn: "Thung Changhan",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "โกรกแก้ว",
              subdistrictNameEn: "Krok Kaeo",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ดงอีจาน",
              subdistrictNameEn: "Dong I Chan",
              postalCode: 31110,
            },
          ],
        },
        {
          districtNameTh: "ชำนิ",
          districtNameEn: "Chamni",
          subdistricts: [
            {
              subdistrictNameTh: "ชำนิ",
              subdistrictNameEn: "Chamni",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "หนองปล่อง",
              subdistrictNameEn: "Nong Plong",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "เมืองยาง",
              subdistrictNameEn: "Mueang Yang",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ช่อผกา",
              subdistrictNameEn: "Cho Phaka",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ละลวด",
              subdistrictNameEn: "Laluat",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "โคกสนวน",
              subdistrictNameEn: "Khok Sanuan",
              postalCode: 31110,
            },
          ],
        },
        {
          districtNameTh: "บ้านใหม่ไชยพจน์",
          districtNameEn: "Ban Mai Chaiyaphot",
          subdistricts: [
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "ทองหลาง",
              subdistrictNameEn: "Thong Lang",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "แดงใหญ่",
              subdistrictNameEn: "Daeng Yai",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "กู่สวนแตง",
              subdistrictNameEn: "Ku Suan Taeng",
              postalCode: 31120,
            },
            {
              subdistrictNameTh: "หนองเยือง",
              subdistrictNameEn: "Nong Yueang",
              postalCode: 31120,
            },
          ],
        },
        {
          districtNameTh: "โนนดินแดง",
          districtNameEn: "Non Din Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "โนนดินแดง",
              subdistrictNameEn: "Non Din Daeng",
              postalCode: 31260,
            },
            {
              subdistrictNameTh: "ส้มป่อย",
              subdistrictNameEn: "Som Poi",
              postalCode: 31260,
            },
            {
              subdistrictNameTh: "ลำนางรอง",
              subdistrictNameEn: "Lam Nang Rong",
              postalCode: 31260,
            },
          ],
        },
        {
          districtNameTh: "บ้านด่าน",
          districtNameEn: "Ban Dan",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านด่าน",
              subdistrictNameEn: "Ban Dan",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "ปราสาท",
              subdistrictNameEn: "Prasat",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "วังเหนือ",
              subdistrictNameEn: "Wang Nuea",
              postalCode: 31000,
            },
            {
              subdistrictNameTh: "โนนขวาง",
              subdistrictNameEn: "Non Khwang",
              postalCode: 31000,
            },
          ],
        },
        {
          districtNameTh: "แคนดง",
          districtNameEn: "Khaen Dong",
          subdistricts: [
            {
              subdistrictNameTh: "แคนดง",
              subdistrictNameEn: "Khaen Dong",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "ดงพลอง",
              subdistrictNameEn: "Dong Phlong",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "สระบัว",
              subdistrictNameEn: "Sa Bua",
              postalCode: 31150,
            },
            {
              subdistrictNameTh: "หัวฝาย",
              subdistrictNameEn: "Hua Fai",
              postalCode: 31150,
            },
          ],
        },
        {
          districtNameTh: "เฉลิมพระเกียรติ",
          districtNameEn: "Chaloem Phra Kiet",
          subdistricts: [
            {
              subdistrictNameTh: "เจริญสุข",
              subdistrictNameEn: "Charoen Suk",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ตาเป๊ก",
              subdistrictNameEn: "Ta Pek",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "อีสานเขต",
              subdistrictNameEn: "Isan Khet",
              postalCode: 31110,
            },
            {
              subdistrictNameTh: "ถาวร",
              subdistrictNameEn: "Thawon",
              postalCode: 31170,
            },
            {
              subdistrictNameTh: "ยายแย้มวัฒนา",
              subdistrictNameEn: "Yai Yaem Watthana",
              postalCode: 31170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สุรินทร์",
      stateNameEn: "Surin",
      districts: [
        {
          districtNameTh: "เมืองสุรินทร์",
          districtNameEn: "Mueang Surin",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "ตั้งใจ",
              subdistrictNameEn: "Tang Chai",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "เพี้ยราม",
              subdistrictNameEn: "Phia Ram",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "ท่าสว่าง",
              subdistrictNameEn: "Tha Sawang",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "สลักได",
              subdistrictNameEn: "Salak Dai",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "ตาอ็อง",
              subdistrictNameEn: "Ta-Ong",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "แกใหญ่",
              subdistrictNameEn: "Kae Yai",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "นอกเมือง",
              subdistrictNameEn: "Nok Mueang",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "คอโค",
              subdistrictNameEn: "Kho Kho",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "สวาย",
              subdistrictNameEn: "Sawat",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "เฉนียง",
              subdistrictNameEn: "Chaniang",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "เทนมีย์",
              subdistrictNameEn: "Then Mi",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "นาบัว",
              subdistrictNameEn: "Na Bua",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "เมืองที",
              subdistrictNameEn: "Mueang Thi",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "ราม",
              subdistrictNameEn: "Ram",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "บุฤาษี",
              subdistrictNameEn: "Bu Ruesi",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "ตระแสง",
              subdistrictNameEn: "Trasaeng",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "แสลงพันธ์",
              subdistrictNameEn: "Salaeng Phan",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "กาเกาะ",
              subdistrictNameEn: "Ka Ko",
              postalCode: 32000,
            },
          ],
        },
        {
          districtNameTh: "ชุมพลบุรี",
          districtNameEn: "Chumphon Buri",
          subdistricts: [
            {
              subdistrictNameTh: "ชุมพลบุรี",
              subdistrictNameEn: "Chumphon Buri",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "นาหนองไผ่",
              subdistrictNameEn: "Na Nong Phai",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "ไพรขลา",
              subdistrictNameEn: "Phrai Khla",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "ศรีณรงค์",
              subdistrictNameEn: "Si Narong",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "ยะวึก",
              subdistrictNameEn: "Yawuek",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "เมืองบัว",
              subdistrictNameEn: "Mueang Bua",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "สระขุด",
              subdistrictNameEn: "Sa Khut",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "กระเบื้อง",
              subdistrictNameEn: "Krabueang",
              postalCode: 32190,
            },
            {
              subdistrictNameTh: "หนองเรือ",
              subdistrictNameEn: "Nong Ruea",
              postalCode: 32190,
            },
          ],
        },
        {
          districtNameTh: "ท่าตูม",
          districtNameEn: "Tha Tum",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าตูม",
              subdistrictNameEn: "Tha Tum",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "กระโพ",
              subdistrictNameEn: "Krapho",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "พรมเทพ",
              subdistrictNameEn: "Phrom Thep",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "โพนครก",
              subdistrictNameEn: "Phon Khrok",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "เมืองแก",
              subdistrictNameEn: "Mueang Kae",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "บะ",
              subdistrictNameEn: "Ba",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "บัวโคก",
              subdistrictNameEn: "Bua Khok",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "หนองเมธี",
              subdistrictNameEn: "Nong Methi",
              postalCode: 32120,
            },
            {
              subdistrictNameTh: "ทุ่งกุลา",
              subdistrictNameEn: "Thung Kula",
              postalCode: 32120,
            },
          ],
        },
        {
          districtNameTh: "จอมพระ",
          districtNameEn: "Chom Phra",
          subdistricts: [
            {
              subdistrictNameTh: "จอมพระ",
              subdistrictNameEn: "Chom Phra",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "เมืองลีง",
              subdistrictNameEn: "Mueang Lueng",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "กระหาด",
              subdistrictNameEn: "Krahat",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "บุแกรง",
              subdistrictNameEn: "Bu Kraeng",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "หนองสนิท",
              subdistrictNameEn: "Nong Sanit",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "บ้านผือ",
              subdistrictNameEn: "Ban Phue",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "ลุ่มระวี",
              subdistrictNameEn: "Lum Rawi",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "ชุมแสง",
              subdistrictNameEn: "Chum Saeng",
              postalCode: 32180,
            },
            {
              subdistrictNameTh: "เป็นสุข",
              subdistrictNameEn: "Pen Suk",
              postalCode: 32180,
            },
          ],
        },
        {
          districtNameTh: "ปราสาท",
          districtNameEn: "Prasat",
          subdistricts: [
            {
              subdistrictNameTh: "กังแอน",
              subdistrictNameEn: "Kang Aen",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ทมอ",
              subdistrictNameEn: "Thamo",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ไพล",
              subdistrictNameEn: "Phrai",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ปรือ",
              subdistrictNameEn: "Prue",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ทุ่งมน",
              subdistrictNameEn: "Thung Mon",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ตาเบา",
              subdistrictNameEn: "Ta Bao",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "หนองใหญ่",
              subdistrictNameEn: "Nong Yai",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "โคกยาง",
              subdistrictNameEn: "Khok Yang",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "บ้านไทร",
              subdistrictNameEn: "Ban Sai",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "โชคนาสาม",
              subdistrictNameEn: "Chok Na Sam",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "เชื้อเพลิง",
              subdistrictNameEn: "Chue Phloeng",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ปราสาททนง",
              subdistrictNameEn: "Prasat Thanong",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ตานี",
              subdistrictNameEn: "Tani",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "บ้านพลวง",
              subdistrictNameEn: "Ban Phluang",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "กันตวจระมวล",
              subdistrictNameEn: "Kanta Wacharamuan",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "สมุด",
              subdistrictNameEn: "Samut",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ประทัดบุ",
              subdistrictNameEn: "Prathat Bu",
              postalCode: 32140,
            },
          ],
        },
        {
          districtNameTh: "กาบเชิง",
          districtNameEn: "Kap Choeng",
          subdistricts: [
            {
              subdistrictNameTh: "กาบเชิง",
              subdistrictNameEn: "Kap Choeng",
              postalCode: 32210,
            },
            {
              subdistrictNameTh: "คูตัน",
              subdistrictNameEn: "Khu Tan",
              postalCode: 32210,
            },
            {
              subdistrictNameTh: "ด่าน",
              subdistrictNameEn: "Dan",
              postalCode: 32210,
            },
            {
              subdistrictNameTh: "แนงมุด",
              subdistrictNameEn: "Naeng Mut",
              postalCode: 32210,
            },
            {
              subdistrictNameTh: "โคกตะเคียน",
              subdistrictNameEn: "Khok Takhian",
              postalCode: 32210,
            },
            {
              subdistrictNameTh: "ตะเคียน",
              subdistrictNameEn: "Takhian",
              postalCode: 32210,
            },
          ],
        },
        {
          districtNameTh: "รัตนบุรี",
          districtNameEn: "Rattanaburi",
          subdistricts: [
            {
              subdistrictNameTh: "รัตนบุรี",
              subdistrictNameEn: "Rattana Buri",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "ธาตุ",
              subdistrictNameEn: "That",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "แก",
              subdistrictNameEn: "Kae",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "ดอนแรด",
              subdistrictNameEn: "Don Raet",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "หนองบัวทอง",
              subdistrictNameEn: "Nong Bua Thong",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "หนองบัวบาน",
              subdistrictNameEn: "Nong Bua Ban",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "ไผ่",
              subdistrictNameEn: "Phai",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "เบิด",
              subdistrictNameEn: "Boet",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "น้ำเขียว",
              subdistrictNameEn: "Nam Khiao",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "กุดขาคีม",
              subdistrictNameEn: "Kut Kha Khim",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "ยางสว่าง",
              subdistrictNameEn: "Yang Sawang",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "ทับใหญ่",
              subdistrictNameEn: "Thap Yai",
              postalCode: 32130,
            },
          ],
        },
        {
          districtNameTh: "สนม",
          districtNameEn: "Sanom",
          subdistricts: [
            {
              subdistrictNameTh: "สนม",
              subdistrictNameEn: "Sanom",
              postalCode: 32160,
            },
            {
              subdistrictNameTh: "โพนโก",
              subdistrictNameEn: "Phon Ko",
              postalCode: 32160,
            },
            {
              subdistrictNameTh: "หนองระฆัง",
              subdistrictNameEn: "Nong Rakhang",
              postalCode: 32160,
            },
            {
              subdistrictNameTh: "นานวน",
              subdistrictNameEn: "Na Nuan",
              postalCode: 32160,
            },
            {
              subdistrictNameTh: "แคน",
              subdistrictNameEn: "Khaen",
              postalCode: 32160,
            },
            {
              subdistrictNameTh: "หัวงัว",
              subdistrictNameEn: "Hua Ngua",
              postalCode: 32160,
            },
            {
              subdistrictNameTh: "หนองอียอ",
              subdistrictNameEn: "Nong I Yo",
              postalCode: 32160,
            },
          ],
        },
        {
          districtNameTh: "ศีขรภูมิ",
          districtNameEn: "Sikhoraphum",
          subdistricts: [
            {
              subdistrictNameTh: "ระแงง",
              subdistrictNameEn: "Ra-Ngaeng",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "ตรึม",
              subdistrictNameEn: "Truem",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "จารพัต",
              subdistrictNameEn: "Charaphat",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "ยาง",
              subdistrictNameEn: "Yang",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "แตล",
              subdistrictNameEn: "Tlae",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "คาละแมะ",
              subdistrictNameEn: "Khalamae",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "หนองเหล็ก",
              subdistrictNameEn: "Nong Lek",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "หนองขวาว",
              subdistrictNameEn: "Nong Khwao",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "ช่างปี่",
              subdistrictNameEn: "Chang Pi",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "กุดหวาย",
              subdistrictNameEn: "Kut Wai",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "ขวาวใหญ่",
              subdistrictNameEn: "Khwao Yai",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "นารุ่ง",
              subdistrictNameEn: "Na Rung",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "ตรมไพร",
              subdistrictNameEn: "Trom Phrai",
              postalCode: 32110,
            },
            {
              subdistrictNameTh: "ผักไหม",
              subdistrictNameEn: "Phak Mai",
              postalCode: 32110,
            },
          ],
        },
        {
          districtNameTh: "สังขะ",
          districtNameEn: "Sangkha",
          subdistricts: [
            {
              subdistrictNameTh: "สังขะ",
              subdistrictNameEn: "Sangkha",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "ขอนแตก",
              subdistrictNameEn: "Khon Taek",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "ดม",
              subdistrictNameEn: "Dom",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "พระแก้ว",
              subdistrictNameEn: "Phra Kaeo",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "บ้านจารย์",
              subdistrictNameEn: "Ban Chan",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "กระเทียม",
              subdistrictNameEn: "Krathiam",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "สะกาด",
              subdistrictNameEn: "Sakat",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "ตาตุม",
              subdistrictNameEn: "Tatum",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "ทับทัน",
              subdistrictNameEn: "Thapthan",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "ตาคง",
              subdistrictNameEn: "Ta Khong",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "บ้านชบ",
              subdistrictNameEn: "Ban Chop",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "เทพรักษา",
              subdistrictNameEn: "Thep Raksa",
              postalCode: 32150,
            },
          ],
        },
        {
          districtNameTh: "ลำดวน",
          districtNameEn: "Lamduan",
          subdistricts: [
            {
              subdistrictNameTh: "ลำดวน",
              subdistrictNameEn: "Lamduan",
              postalCode: 32220,
            },
            {
              subdistrictNameTh: "โชคเหนือ",
              subdistrictNameEn: "Chok Nuea",
              postalCode: 32220,
            },
            {
              subdistrictNameTh: "อู่โลก",
              subdistrictNameEn: "U Lok",
              postalCode: 32220,
            },
            {
              subdistrictNameTh: "ตรำดม",
              subdistrictNameEn: "Tramdom",
              postalCode: 32220,
            },
            {
              subdistrictNameTh: "ตระเปียงเตีย",
              subdistrictNameEn: "Trapiang Tia",
              postalCode: 32220,
            },
          ],
        },
        {
          districtNameTh: "สำโรงทาบ",
          districtNameEn: "Samrong Thap",
          subdistricts: [
            {
              subdistrictNameTh: "สำโรงทาบ",
              subdistrictNameEn: "Samrong Thap",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "หนองไผ่ล้อม",
              subdistrictNameEn: "Nong Phai Lom",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "กระออม",
              subdistrictNameEn: "Kra-Om",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "หนองฮะ",
              subdistrictNameEn: "Nong Ha",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "ศรีสุข",
              subdistrictNameEn: "Si Suk",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "เกาะแก้ว",
              subdistrictNameEn: "Ko Kaeo",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "หมื่นศรี",
              subdistrictNameEn: "Muen Si",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "เสม็จ",
              subdistrictNameEn: "Samet",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "สะโน",
              subdistrictNameEn: "Sano",
              postalCode: 32170,
            },
            {
              subdistrictNameTh: "ประดู่",
              subdistrictNameEn: "Pradu",
              postalCode: 32170,
            },
          ],
        },
        {
          districtNameTh: "บัวเชด",
          districtNameEn: "Buachet",
          subdistricts: [
            {
              subdistrictNameTh: "บัวเชด",
              subdistrictNameEn: "Bua Chet",
              postalCode: 32230,
            },
            {
              subdistrictNameTh: "สะเดา",
              subdistrictNameEn: "Sadao",
              postalCode: 32230,
            },
            {
              subdistrictNameTh: "จรัส",
              subdistrictNameEn: "Charat",
              postalCode: 32230,
            },
            {
              subdistrictNameTh: "ตาวัง",
              subdistrictNameEn: "Ta Wang",
              postalCode: 32230,
            },
            {
              subdistrictNameTh: "อาโพน",
              subdistrictNameEn: "A Phon",
              postalCode: 32230,
            },
            {
              subdistrictNameTh: "สำเภาลูน",
              subdistrictNameEn: "Samphao Lun",
              postalCode: 32230,
            },
          ],
        },
        {
          districtNameTh: "พนมดงรัก",
          districtNameEn: "Phanom Dong Rak",
          subdistricts: [
            {
              subdistrictNameTh: "บักได",
              subdistrictNameEn: "Bak Dai",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "โคกกลาง",
              subdistrictNameEn: "Khok Klang",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "จีกแดก",
              subdistrictNameEn: "Chik Daek",
              postalCode: 32140,
            },
            {
              subdistrictNameTh: "ตาเมียง",
              subdistrictNameEn: "Ta Miang",
              postalCode: 32140,
            },
          ],
        },
        {
          districtNameTh: "ศรีณรงค์",
          districtNameEn: "Si Narong",
          subdistricts: [
            {
              subdistrictNameTh: "ณรงค์",
              subdistrictNameEn: "Narong",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "แจนแวน",
              subdistrictNameEn: "Chaen Waen",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "ตรวจ",
              subdistrictNameEn: "Truat",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 32150,
            },
            {
              subdistrictNameTh: "ศรีสุข",
              subdistrictNameEn: "Si Suk",
              postalCode: 32150,
            },
          ],
        },
        {
          districtNameTh: "เขวาสินรินทร์",
          districtNameEn: "Khwao Sinarin",
          subdistricts: [
            {
              subdistrictNameTh: "เขวาสินรินทร์",
              subdistrictNameEn: "Khewa Sinarin",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "บึง",
              subdistrictNameEn: "Bueng",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "ตากูก",
              subdistrictNameEn: "Ta Kuk",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "ปราสาททอง",
              subdistrictNameEn: "Prasat Thong",
              postalCode: 32000,
            },
            {
              subdistrictNameTh: "บ้านแร่",
              subdistrictNameEn: "Ban Rae",
              postalCode: 32000,
            },
          ],
        },
        {
          districtNameTh: "โนนนารายณ์",
          districtNameEn: "Non Narai",
          subdistricts: [
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "คำผง",
              subdistrictNameEn: "Kham Phong",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "โนน",
              subdistrictNameEn: "Non",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "ระเวียง",
              subdistrictNameEn: "Rawiang",
              postalCode: 32130,
            },
            {
              subdistrictNameTh: "หนองเทพ",
              subdistrictNameEn: "Nong Thep",
              postalCode: 32130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ศรีสะเกษ",
      stateNameEn: "Si Sa Ket",
      districts: [
        {
          districtNameTh: "เมืองศรีสะเกษ",
          districtNameEn: "Mueang Si Sa Ket",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองเหนือ",
              subdistrictNameEn: "Mueang Nuea",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "เมืองใต้",
              subdistrictNameEn: "Mueang Tai",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "คูซอด",
              subdistrictNameEn: "Khu Sot",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "ซำ",
              subdistrictNameEn: "Sam",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "จาน",
              subdistrictNameEn: "Chan",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "ตะดอบ",
              subdistrictNameEn: "Tadop",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "หนองครก",
              subdistrictNameEn: "Nong Khrok",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "โพนข่า",
              subdistrictNameEn: "Phon Kha",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "โพนค้อ",
              subdistrictNameEn: "Phon Kho",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "โพนเขวา",
              subdistrictNameEn: "Phon Khwao",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "หญ้าปล้อง",
              subdistrictNameEn: "Ya Plong",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "ทุ่ม",
              subdistrictNameEn: "Thum",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "หนองไฮ",
              subdistrictNameEn: "Nong Hai",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "หนองแก้ว",
              subdistrictNameEn: "Nong Kaeo",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "น้ำคำ",
              subdistrictNameEn: "Nam Kham",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "โพธิ์",
              subdistrictNameEn: "Pho",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "หมากเขียบ",
              subdistrictNameEn: "Mak Khiap",
              postalCode: 33000,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 33000,
            },
          ],
        },
        {
          districtNameTh: "ยางชุมน้อย",
          districtNameEn: "Yang Chum Noi",
          subdistricts: [
            {
              subdistrictNameTh: "ยางชุมน้อย",
              subdistrictNameEn: "Yang Chum Noi",
              postalCode: 33190,
            },
            {
              subdistrictNameTh: "ลิ้นฟ้า",
              subdistrictNameEn: "Lin Fa",
              postalCode: 33190,
            },
            {
              subdistrictNameTh: "คอนกาม",
              subdistrictNameEn: "Khon Kam",
              postalCode: 33190,
            },
            {
              subdistrictNameTh: "โนนคูณ",
              subdistrictNameEn: "Non Khun",
              postalCode: 33190,
            },
            {
              subdistrictNameTh: "กุดเมืองฮาม",
              subdistrictNameEn: "Kut Mueang Ham",
              postalCode: 33190,
            },
            {
              subdistrictNameTh: "บึงบอน",
              subdistrictNameEn: "Bueng Bon",
              postalCode: 33190,
            },
            {
              subdistrictNameTh: "ยางชุมใหญ่",
              subdistrictNameEn: "Yang Chum Yai",
              postalCode: 33190,
            },
          ],
        },
        {
          districtNameTh: "กันทรารมย์",
          districtNameEn: "Kanthararom",
          subdistricts: [
            {
              subdistrictNameTh: "ดูน",
              subdistrictNameEn: "Dun",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "โนนสัง",
              subdistrictNameEn: "Non Sang",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "หนองหัวช้าง",
              subdistrictNameEn: "Nong Hua Chang",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "ยาง",
              subdistrictNameEn: "Yang",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "หนองแก้ว",
              subdistrictNameEn: "Nong Kaeo",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "ทาม",
              subdistrictNameEn: "Tham",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "ละทาย",
              subdistrictNameEn: "Lathai",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "เมืองน้อย",
              subdistrictNameEn: "Mueang Noi",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "อีปาด",
              subdistrictNameEn: "I Pat",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "บัวน้อย",
              subdistrictNameEn: "Bua Noi",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "ดู่",
              subdistrictNameEn: "Du",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "ผักแพว",
              subdistrictNameEn: "Phak Phaeo",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "จาน",
              subdistrictNameEn: "Chan",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "คำเนียม",
              subdistrictNameEn: "Kham Niam",
              postalCode: 33130,
            },
          ],
        },
        {
          districtNameTh: "กันทรลักษ์",
          districtNameEn: "Kantharalak",
          subdistricts: [
            {
              subdistrictNameTh: "บึงมะลู",
              subdistrictNameEn: "Bueng Malu",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "กุดเสลา",
              subdistrictNameEn: "Kut Salao",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "เมือง",
              subdistrictNameEn: "Mueang",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "สังเม็ก",
              subdistrictNameEn: "Sang Mek",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "น้ำอ้อม",
              subdistrictNameEn: "Nam Om",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ละลาย",
              subdistrictNameEn: "Lalai",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "รุง",
              subdistrictNameEn: "Rung",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ตระกาจ",
              subdistrictNameEn: "Trakat",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "จานใหญ่",
              subdistrictNameEn: "Chan Yai",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ภูเงิน",
              subdistrictNameEn: "Phu Ngoen",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ชำ",
              subdistrictNameEn: "Cham",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "กระแชง",
              subdistrictNameEn: "Krachaeng",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "โนนสำราญ",
              subdistrictNameEn: "Non Samran",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "หนองหญ้าลาด",
              subdistrictNameEn: "Nong Ya Lat",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "เสาธงชัย",
              subdistrictNameEn: "Sao Thong Chai",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ขนุน",
              subdistrictNameEn: "Khanun",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "สวนกล้วย",
              subdistrictNameEn: "Suan Kluai",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "เวียงเหนือ",
              subdistrictNameEn: "Wiang Nuea",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ทุ่งใหญ่",
              subdistrictNameEn: "Thung Yai",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ภูผาหมอก",
              subdistrictNameEn: "Phu Pha Mok",
              postalCode: 33110,
            },
          ],
        },
        {
          districtNameTh: "ขุขันธ์",
          districtNameEn: "Khukhan",
          subdistricts: [
            {
              subdistrictNameTh: "กันทรารมย์",
              subdistrictNameEn: "Kanthararom",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "จะกง",
              subdistrictNameEn: "Cha Kong",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ใจดี",
              subdistrictNameEn: "Chai Di",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ดองกำเม็ด",
              subdistrictNameEn: "Dong Kammet",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "โสน",
              subdistrictNameEn: "Sano",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ปรือใหญ่",
              subdistrictNameEn: "Prue Yai",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "สะเดาใหญ่",
              subdistrictNameEn: "Sadao Yai",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ตาอุด",
              subdistrictNameEn: "Ta Ut",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ห้วยเหนือ",
              subdistrictNameEn: "Huai Nuea",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ห้วยใต้",
              subdistrictNameEn: "Huai Tai",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "หัวเสือ",
              subdistrictNameEn: "Hua Suea",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ตะเคียน",
              subdistrictNameEn: "Takhian",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "นิคมพัฒนา",
              subdistrictNameEn: "Nikhom Phatthana",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "โคกเพชร",
              subdistrictNameEn: "Khok Phet",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ปราสาท",
              subdistrictNameEn: "Prasat",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "สำโรงตาเจ็น",
              subdistrictNameEn: "Samrong Ta Chen",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ห้วยสำราญ",
              subdistrictNameEn: "Huai Samran",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "กฤษณา",
              subdistrictNameEn: "Kritsana",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ลมศักดิ์",
              subdistrictNameEn: "Lom Sak",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "หนองฉลอง",
              subdistrictNameEn: "Nong Chalong",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ศรีตระกูล",
              subdistrictNameEn: "Si Trakun",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ศรีสะอาด",
              subdistrictNameEn: "Si Sa-At",
              postalCode: 33140,
            },
          ],
        },
        {
          districtNameTh: "ไพรบึง",
          districtNameEn: "Phrai Bueng",
          subdistricts: [
            {
              subdistrictNameTh: "ไพรบึง",
              subdistrictNameEn: "Phrai Bueng",
              postalCode: 33180,
            },
            {
              subdistrictNameTh: "ดินแดง",
              subdistrictNameEn: "Din Daeng",
              postalCode: 33180,
            },
            {
              subdistrictNameTh: "ปราสาทเยอ",
              subdistrictNameEn: "Prasat Yoe",
              postalCode: 33180,
            },
            {
              subdistrictNameTh: "สำโรงพลัน",
              subdistrictNameEn: "Samrong Phlan",
              postalCode: 33180,
            },
            {
              subdistrictNameTh: "สุขสวัสดิ์",
              subdistrictNameEn: "Suk Sawat",
              postalCode: 33180,
            },
            {
              subdistrictNameTh: "โนนปูน",
              subdistrictNameEn: "Non Pun",
              postalCode: 33180,
            },
          ],
        },
        {
          districtNameTh: "ปรางค์กู่",
          districtNameEn: "Prang Ku",
          subdistricts: [
            {
              subdistrictNameTh: "พิมาย",
              subdistrictNameEn: "Phimai",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "กู่",
              subdistrictNameEn: "Ku",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "หนองเชียงทูน",
              subdistrictNameEn: "Nong Chiang Thun",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "ตูม",
              subdistrictNameEn: "Tum",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "สมอ",
              subdistrictNameEn: "Samo",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "โพธิ์ศรี",
              subdistrictNameEn: "Pho Si",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "สำโรงปราสาท",
              subdistrictNameEn: "Samrong Prasat",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "ดู่",
              subdistrictNameEn: "Du",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "สวาย",
              subdistrictNameEn: "Sawat",
              postalCode: 33170,
            },
            {
              subdistrictNameTh: "พิมายเหนือ",
              subdistrictNameEn: "Phimai Nuea",
              postalCode: 33170,
            },
          ],
        },
        {
          districtNameTh: "ขุนหาญ",
          districtNameEn: "Khun Han",
          subdistricts: [
            {
              subdistrictNameTh: "สิ",
              subdistrictNameEn: "Si",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "บักดอง",
              subdistrictNameEn: "Bak Dong",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "พราน",
              subdistrictNameEn: "Phran",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "โพธิ์วงศ์",
              subdistrictNameEn: "Pho Wong",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "ไพร",
              subdistrictNameEn: "Phrai",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "กระหวัน",
              subdistrictNameEn: "Krawan",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "ขุนหาญ",
              subdistrictNameEn: "Khun Han",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "โนนสูง",
              subdistrictNameEn: "Non Sung",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "กันทรอม",
              subdistrictNameEn: "Kan Throm",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "ภูฝ้าย",
              subdistrictNameEn: "Phu Fai",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "โพธิ์กระสังข์",
              subdistrictNameEn: "Pho Krasang",
              postalCode: 33150,
            },
            {
              subdistrictNameTh: "ห้วยจันทร์",
              subdistrictNameEn: "Huai Chan",
              postalCode: 33150,
            },
          ],
        },
        {
          districtNameTh: "ราษีไศล",
          districtNameEn: "Rasi Salai",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองคง",
              subdistrictNameEn: "Mueang Khong",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "เมืองแคน",
              subdistrictNameEn: "Mueang Khaen",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "หนองแค",
              subdistrictNameEn: "Nong Khae",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "จิกสังข์ทอง",
              subdistrictNameEn: "Chik Sang Thong",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "ด่าน",
              subdistrictNameEn: "Dan",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "ดู่",
              subdistrictNameEn: "Du",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "หนองอึ่ง",
              subdistrictNameEn: "Nong Ueng",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "บัวหุ่ง",
              subdistrictNameEn: "Bua Hung",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "ไผ่",
              subdistrictNameEn: "Phai",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "ส้มป่อย",
              subdistrictNameEn: "Som Poi",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "หนองหมี",
              subdistrictNameEn: "Nong Mi",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "หว้านคำ",
              subdistrictNameEn: "Wan Kham",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "สร้างปี่",
              subdistrictNameEn: "Sang Pi",
              postalCode: 33160,
            },
          ],
        },
        {
          districtNameTh: "อุทุมพรพิสัย",
          districtNameEn: "Uthumphon Phisai",
          subdistricts: [
            {
              subdistrictNameTh: "กำแพง",
              subdistrictNameEn: "Kamphaeng",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "อี่หล่ำ",
              subdistrictNameEn: "I Lam",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "ก้านเหลือง",
              subdistrictNameEn: "Kan Lueang",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "ทุ่งไชย",
              subdistrictNameEn: "Thung Chai",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "แขม",
              subdistrictNameEn: "Khaem",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "หนองไฮ",
              subdistrictNameEn: "Nong Hai",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "ขะยูง",
              subdistrictNameEn: "Khayung",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "ตาเกษ",
              subdistrictNameEn: "Ta Kat",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "หัวช้าง",
              subdistrictNameEn: "Hua Chang",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "รังแร้ง",
              subdistrictNameEn: "Rang Raeng",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "แต้",
              subdistrictNameEn: "Tae",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "แข้",
              subdistrictNameEn: "Khae",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "โพธิ์ชัย",
              subdistrictNameEn: "Pho Chai",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "ปะอาว",
              subdistrictNameEn: "Pa-Ao",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "หนองห้าง",
              subdistrictNameEn: "Nong Hang",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "สระกำแพงใหญ่",
              subdistrictNameEn: "Sa Kamphaeng Yai",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "โคกหล่าม",
              subdistrictNameEn: "Khok Lam",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "โคกจาน",
              subdistrictNameEn: "Khok Chan",
              postalCode: 33120,
            },
          ],
        },
        {
          districtNameTh: "บึงบูรพ์",
          districtNameEn: "Bueng Bun",
          subdistricts: [
            {
              subdistrictNameTh: "เป๊าะ",
              subdistrictNameEn: "Po",
              postalCode: 33220,
            },
            {
              subdistrictNameTh: "บึงบูรพ์",
              subdistrictNameEn: "Bueng Bun",
              postalCode: 33220,
            },
          ],
        },
        {
          districtNameTh: "ห้วยทับทัน",
          districtNameEn: "Huai Thap Than",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยทับทัน",
              subdistrictNameEn: "Huai Thapthan",
              postalCode: 33210,
            },
            {
              subdistrictNameTh: "เมืองหลวง",
              subdistrictNameEn: "Mueang Luang",
              postalCode: 33210,
            },
            {
              subdistrictNameTh: "กล้วยกว้าง",
              subdistrictNameEn: "Kluai Kwang",
              postalCode: 33210,
            },
            {
              subdistrictNameTh: "ผักไหม",
              subdistrictNameEn: "Phak Mai",
              postalCode: 33210,
            },
            {
              subdistrictNameTh: "จานแสนไชย",
              subdistrictNameEn: "Chan Saen Chai",
              postalCode: 33210,
            },
            {
              subdistrictNameTh: "ปราสาท",
              subdistrictNameEn: "Prasat",
              postalCode: 33210,
            },
          ],
        },
        {
          districtNameTh: "โนนคูณ",
          districtNameEn: "Non Khun",
          subdistricts: [
            {
              subdistrictNameTh: "โนนค้อ",
              subdistrictNameEn: "Non Kho",
              postalCode: 33250,
            },
            {
              subdistrictNameTh: "บก",
              subdistrictNameEn: "Bok",
              postalCode: 33250,
            },
            {
              subdistrictNameTh: "โพธิ์",
              subdistrictNameEn: "Pho",
              postalCode: 33250,
            },
            {
              subdistrictNameTh: "หนองกุง",
              subdistrictNameEn: "Nong Kung",
              postalCode: 33250,
            },
            {
              subdistrictNameTh: "เหล่ากวาง",
              subdistrictNameEn: "Lao Kwang",
              postalCode: 33250,
            },
          ],
        },
        {
          districtNameTh: "ศรีรัตนะ",
          districtNameEn: "Si Rattana",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีแก้ว",
              subdistrictNameEn: "Si Kaeo",
              postalCode: 33240,
            },
            {
              subdistrictNameTh: "พิงพวย",
              subdistrictNameEn: "Phing Phuai",
              postalCode: 33240,
            },
            {
              subdistrictNameTh: "สระเยาว์",
              subdistrictNameEn: "Sa Yao",
              postalCode: 33240,
            },
            {
              subdistrictNameTh: "ตูม",
              subdistrictNameEn: "Tum",
              postalCode: 33240,
            },
            {
              subdistrictNameTh: "เสื่องข้าว",
              subdistrictNameEn: "Sueang Khao",
              postalCode: 33240,
            },
            {
              subdistrictNameTh: "ศรีโนนงาม",
              subdistrictNameEn: "Si Non Ngam",
              postalCode: 33240,
            },
            {
              subdistrictNameTh: "สะพุง",
              subdistrictNameEn: "Saphung",
              postalCode: 33240,
            },
          ],
        },
        {
          districtNameTh: "น้ำเกลี้ยง",
          districtNameEn: "Nam Kliang",
          subdistricts: [
            {
              subdistrictNameTh: "น้ำเกลี้ยง",
              subdistrictNameEn: "Nam Kliang",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "ละเอาะ",
              subdistrictNameEn: "La-O",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "ตองปิด",
              subdistrictNameEn: "Tong Pit",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "เขิน",
              subdistrictNameEn: "Khoen",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "รุ่งระวี",
              subdistrictNameEn: "Rung Rawi",
              postalCode: 33130,
            },
            {
              subdistrictNameTh: "คูบ",
              subdistrictNameEn: "Khup",
              postalCode: 33130,
            },
          ],
        },
        {
          districtNameTh: "วังหิน",
          districtNameEn: "Wang Hin",
          subdistricts: [
            {
              subdistrictNameTh: "บุสูง",
              subdistrictNameEn: "Bu Sung",
              postalCode: 33270,
            },
            {
              subdistrictNameTh: "ธาตุ",
              subdistrictNameEn: "That",
              postalCode: 33270,
            },
            {
              subdistrictNameTh: "ดวนใหญ่",
              subdistrictNameEn: "Duan Yai",
              postalCode: 33270,
            },
            {
              subdistrictNameTh: "บ่อแก้ว",
              subdistrictNameEn: "Bo Kaeo",
              postalCode: 33270,
            },
            {
              subdistrictNameTh: "ศรีสำราญ",
              subdistrictNameEn: "Si Samran",
              postalCode: 33270,
            },
            {
              subdistrictNameTh: "ทุ่งสว่าง",
              subdistrictNameEn: "Thung Sawang",
              postalCode: 33270,
            },
            {
              subdistrictNameTh: "วังหิน",
              subdistrictNameEn: "Wang Hin",
              postalCode: 33270,
            },
            {
              subdistrictNameTh: "โพนยาง",
              subdistrictNameEn: "Phon Yang",
              postalCode: 33270,
            },
          ],
        },
        {
          districtNameTh: "ภูสิงห์",
          districtNameEn: "Phu Sing",
          subdistricts: [
            {
              subdistrictNameTh: "โคกตาล",
              subdistrictNameEn: "Khok Tan",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ห้วยตามอญ",
              subdistrictNameEn: "Huai Ta Mon",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ห้วยตึ๊กชู",
              subdistrictNameEn: "Huai Tuek Chu",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ละลม",
              subdistrictNameEn: "Lalom",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ตะเคียนราม",
              subdistrictNameEn: "Takhian Ram",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ดงรัก",
              subdistrictNameEn: "Dong Rak",
              postalCode: 33140,
            },
            {
              subdistrictNameTh: "ไพรพัฒนา",
              subdistrictNameEn: "Phrai Phatthana",
              postalCode: 33140,
            },
          ],
        },
        {
          districtNameTh: "เมืองจันทร์",
          districtNameEn: "Mueang Chan",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองจันทร์",
              subdistrictNameEn: "Mueang Chan",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "ตาโกน",
              subdistrictNameEn: "Ta Kon",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "หนองใหญ่",
              subdistrictNameEn: "Nong Yai",
              postalCode: 33120,
            },
          ],
        },
        {
          districtNameTh: "เบญจลักษ์",
          districtNameEn: "Benchalak",
          subdistricts: [
            {
              subdistrictNameTh: "เสียว",
              subdistrictNameEn: "Siao",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "หนองหว้า",
              subdistrictNameEn: "Nong Wa",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "หนองงูเหลือม",
              subdistrictNameEn: "Nong Ngu Lueam",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "หนองฮาง",
              subdistrictNameEn: "Nong Hang",
              postalCode: 33110,
            },
            {
              subdistrictNameTh: "ท่าคล้อ",
              subdistrictNameEn: "Tha Khlo",
              postalCode: 33110,
            },
          ],
        },
        {
          districtNameTh: "พยุห์",
          districtNameEn: "Phayu",
          subdistricts: [
            {
              subdistrictNameTh: "พยุห์",
              subdistrictNameEn: "Phayu",
              postalCode: 33230,
            },
            {
              subdistrictNameTh: "พรหมสวัสดิ์",
              subdistrictNameEn: "Phrom Sawat",
              postalCode: 33230,
            },
            {
              subdistrictNameTh: "ตำแย",
              subdistrictNameEn: "Tamyae",
              postalCode: 33230,
            },
            {
              subdistrictNameTh: "โนนเพ็ก",
              subdistrictNameEn: "Non Phek",
              postalCode: 33230,
            },
            {
              subdistrictNameTh: "หนองค้า",
              subdistrictNameEn: "Nong Kha",
              postalCode: 33230,
            },
          ],
        },
        {
          districtNameTh: "โพธิ์ศรีสุวรรณ",
          districtNameEn: "Pho Si Suwan",
          subdistricts: [
            {
              subdistrictNameTh: "โดด",
              subdistrictNameEn: "Dot",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "เสียว",
              subdistrictNameEn: "Siao",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "หนองม้า",
              subdistrictNameEn: "Nong Ma",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "ผือใหญ่",
              subdistrictNameEn: "Phue Yai",
              postalCode: 33120,
            },
            {
              subdistrictNameTh: "อีเซ",
              subdistrictNameEn: "I Se",
              postalCode: 33120,
            },
          ],
        },
        {
          districtNameTh: "ศิลาลาด",
          districtNameEn: "Sila Lat",
          subdistricts: [
            {
              subdistrictNameTh: "กุง",
              subdistrictNameEn: "Kung",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "คลีกลิ้ง",
              subdistrictNameEn: "Khli Kling",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "หนองบัวดง",
              subdistrictNameEn: "Nong Bua Dong",
              postalCode: 33160,
            },
            {
              subdistrictNameTh: "โจดม่วง",
              subdistrictNameEn: "Chot Muang",
              postalCode: 33160,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "อุบลราชธานี",
      stateNameEn: "Ubon Ratchathani",
      districts: [
        {
          districtNameTh: "เมืองอุบลราชธานี",
          districtNameEn: "Mueang Ubon Ratchathani",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "หัวเรือ",
              subdistrictNameEn: "Hua Ruea",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "หนองขอน",
              subdistrictNameEn: "Nong Khon",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "ปทุม",
              subdistrictNameEn: "Pathum",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "ขามใหญ่",
              subdistrictNameEn: "Kham Yai",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "แจระแม",
              subdistrictNameEn: "Chaeramae",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "หนองบ่อ",
              subdistrictNameEn: "Nong Bo",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "ไร่น้อย",
              subdistrictNameEn: "Rai Noi",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "กระโสบ",
              subdistrictNameEn: "Krasop",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "กุดลาด",
              subdistrictNameEn: "Kut Lat",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "ขี้เหล็ก",
              subdistrictNameEn: "Khi Lek",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "ปะอาว",
              subdistrictNameEn: "Pa-Ao",
              postalCode: 34000,
            },
          ],
        },
        {
          districtNameTh: "ศรีเมืองใหม่",
          districtNameEn: "Si Mueang Mai",
          subdistricts: [
            {
              subdistrictNameTh: "นาคำ",
              subdistrictNameEn: "Na Kham",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "แก้งกอก",
              subdistrictNameEn: "Kaeng Kok",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "เอือดใหญ่",
              subdistrictNameEn: "Ueat Yai",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "วาริน",
              subdistrictNameEn: "Warin",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "ลาดควาย",
              subdistrictNameEn: "Lat Khwai",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "สงยาง",
              subdistrictNameEn: "Song Yang",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "ตะบ่าย",
              subdistrictNameEn: "Tabai",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "คำไหล",
              subdistrictNameEn: "Kham Lai",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "หนามแท่ง",
              subdistrictNameEn: "Nam Thaeng",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "นาเลิน",
              subdistrictNameEn: "Na Loen",
              postalCode: 34250,
            },
            {
              subdistrictNameTh: "ดอนใหญ่",
              subdistrictNameEn: "Don Yai",
              postalCode: 34250,
            },
          ],
        },
        {
          districtNameTh: "โขงเจียม",
          districtNameEn: "Khong Chiam",
          subdistricts: [
            {
              subdistrictNameTh: "โขงเจียม",
              subdistrictNameEn: "Khong Chiam",
              postalCode: 34220,
            },
            {
              subdistrictNameTh: "ห้วยยาง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 34220,
            },
            {
              subdistrictNameTh: "นาโพธิ์กลาง",
              subdistrictNameEn: "Na Pho Klang",
              postalCode: 34220,
            },
            {
              subdistrictNameTh: "หนองแสงใหญ่",
              subdistrictNameEn: "Nong Saeng Yai",
              postalCode: 34220,
            },
            {
              subdistrictNameTh: "ห้วยไผ่",
              subdistrictNameEn: "Huai Phai",
              postalCode: 34220,
            },
          ],
        },
        {
          districtNameTh: "เขื่องใน",
          districtNameEn: "Khueang Nai",
          subdistricts: [
            {
              subdistrictNameTh: "เขื่องใน",
              subdistrictNameEn: "Khueang Nai",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "สร้างถ่อ",
              subdistrictNameEn: "Sang Tho",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "ค้อทอง",
              subdistrictNameEn: "Kho Thong",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "ก่อเอ้",
              subdistrictNameEn: "Ko-E",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "หัวดอน",
              subdistrictNameEn: "Hua Don",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "ชีทวน",
              subdistrictNameEn: "Chi Tuan",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "ท่าไห",
              subdistrictNameEn: "Tha Hai",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "นาคำใหญ่",
              subdistrictNameEn: "Na Kham Yai",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "แดงหม้อ",
              subdistrictNameEn: "Daeng Mo",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "ธาตุน้อย",
              subdistrictNameEn: "That Noi",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "บ้านไทย",
              subdistrictNameEn: "Ban Thai",
              postalCode: 34320,
            },
            {
              subdistrictNameTh: "บ้านกอก",
              subdistrictNameEn: "Ban Kok",
              postalCode: 34320,
            },
            {
              subdistrictNameTh: "กลางใหญ่",
              subdistrictNameEn: "Klang Yai",
              postalCode: 34320,
            },
            {
              subdistrictNameTh: "โนนรัง",
              subdistrictNameEn: "Non Rang",
              postalCode: 34320,
            },
            {
              subdistrictNameTh: "ยางขี้นก",
              subdistrictNameEn: "Yang Khi Nok",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "ศรีสุข",
              subdistrictNameEn: "Si Suk",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "สหธาตุ",
              subdistrictNameEn: "Sahathat",
              postalCode: 34150,
            },
            {
              subdistrictNameTh: "หนองเหล่า",
              subdistrictNameEn: "Nong Lao",
              postalCode: 34150,
            },
          ],
        },
        {
          districtNameTh: "เขมราฐ",
          districtNameEn: "Khemarat",
          subdistricts: [
            {
              subdistrictNameTh: "เขมราฐ",
              subdistrictNameEn: "Khemarat",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "ขามป้อม",
              subdistrictNameEn: "Kham Pom",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "เจียด",
              subdistrictNameEn: "Chiat",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "หนองผือ",
              subdistrictNameEn: "Nong Phue",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "นาแวง",
              subdistrictNameEn: "Na Waeng",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "แก้งเหนือ",
              subdistrictNameEn: "Kaeng Nuea",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "หนองนกทา",
              subdistrictNameEn: "Nong Nok Tha",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "หนองสิม",
              subdistrictNameEn: "Nong Sim",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "หัวนา",
              subdistrictNameEn: "Hua Na",
              postalCode: 34170,
            },
          ],
        },
        {
          districtNameTh: "เดชอุดม",
          districtNameEn: "Det Udom",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองเดช",
              subdistrictNameEn: "Mueang Det",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "นาส่วง",
              subdistrictNameEn: "Na Suang",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "นาเจริญ",
              subdistrictNameEn: "Na Charoen",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "ทุ่งเทิง",
              subdistrictNameEn: "Thung Thoeng",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "สมสะอาด",
              subdistrictNameEn: "Som Sa-At",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "กุดประทาย",
              subdistrictNameEn: "Kut Prathai",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "ตบหู",
              subdistrictNameEn: "Top Hu",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "กลาง",
              subdistrictNameEn: "Klang",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "แก้ง",
              subdistrictNameEn: "Kaeng",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "ท่าโพธิ์ศรี",
              subdistrictNameEn: "Tha Pho Si",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "บัวงาม",
              subdistrictNameEn: "Bua Ngam",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "คำครั่ง",
              subdistrictNameEn: "Kham Khrang",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "นากระแซง",
              subdistrictNameEn: "Na Krasaeng",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "ป่าโมง",
              subdistrictNameEn: "Pa Mong",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "โนนสมบูรณ์",
              subdistrictNameEn: "Non Sombun",
              postalCode: 34160,
            },
          ],
        },
        {
          districtNameTh: "นาจะหลวย",
          districtNameEn: "Na Chaluai",
          subdistricts: [
            {
              subdistrictNameTh: "นาจะหลวย",
              subdistrictNameEn: "Na Chaluai",
              postalCode: 34280,
            },
            {
              subdistrictNameTh: "โนนสมบูรณ์",
              subdistrictNameEn: "Non Sombun",
              postalCode: 34280,
            },
            {
              subdistrictNameTh: "พรสวรรค์",
              subdistrictNameEn: "Phon Sawan",
              postalCode: 34280,
            },
            {
              subdistrictNameTh: "บ้านตูม",
              subdistrictNameEn: "Ban Tum",
              postalCode: 34280,
            },
            {
              subdistrictNameTh: "โสกแสง",
              subdistrictNameEn: "Sok Saeng",
              postalCode: 34280,
            },
            {
              subdistrictNameTh: "โนนสวรรค์",
              subdistrictNameEn: "Non Sawan",
              postalCode: 34280,
            },
          ],
        },
        {
          districtNameTh: "น้ำยืน",
          districtNameEn: "Nam Yuen",
          subdistricts: [
            {
              subdistrictNameTh: "โซง",
              subdistrictNameEn: "Song",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "ยาง",
              subdistrictNameEn: "Yang",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "โดมประดิษฐ์",
              subdistrictNameEn: "Dom Pradit",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "บุเปือย",
              subdistrictNameEn: "Bu Pueai",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "สีวิเชียร",
              subdistrictNameEn: "Si Wichian",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "ยางใหญ่",
              subdistrictNameEn: "Yang Yai",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "เก่าขาม",
              subdistrictNameEn: "Kao Kham",
              postalCode: 34260,
            },
          ],
        },
        {
          districtNameTh: "บุณฑริก",
          districtNameEn: "Boontharik",
          subdistricts: [
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 34230,
            },
            {
              subdistrictNameTh: "ห้วยข่า",
              subdistrictNameEn: "Huai Kha",
              postalCode: 34230,
            },
            {
              subdistrictNameTh: "คอแลน",
              subdistrictNameEn: "Kho Laen",
              postalCode: 34230,
            },
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 34230,
            },
            {
              subdistrictNameTh: "หนองสะโน",
              subdistrictNameEn: "Nong Sano",
              postalCode: 34230,
            },
            {
              subdistrictNameTh: "โนนค้อ",
              subdistrictNameEn: "Non Kho",
              postalCode: 34230,
            },
            {
              subdistrictNameTh: "บัวงาม",
              subdistrictNameEn: "Bua Ngam",
              postalCode: 34230,
            },
            {
              subdistrictNameTh: "บ้านแมด",
              subdistrictNameEn: "Ban Maet",
              postalCode: 34230,
            },
          ],
        },
        {
          districtNameTh: "ตระการพืชผล",
          districtNameEn: "Trakan Phuet Phon",
          subdistricts: [
            {
              subdistrictNameTh: "ขุหลุ",
              subdistrictNameEn: "Khulu",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "กระเดียน",
              subdistrictNameEn: "Kradian",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "เกษม",
              subdistrictNameEn: "Kasem",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "กุศกร",
              subdistrictNameEn: "Kusakon",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "ขามเปี้ย",
              subdistrictNameEn: "Kham Pia",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "คอนสาย",
              subdistrictNameEn: "Khon Sai",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "โคกจาน",
              subdistrictNameEn: "Khok Chan",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "นาพิน",
              subdistrictNameEn: "Na Phin",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "นาสะไม",
              subdistrictNameEn: "Na Samai",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "โนนกุง",
              subdistrictNameEn: "Non Kung",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "ตระการ",
              subdistrictNameEn: "Trakan",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "ตากแดด",
              subdistrictNameEn: "Tak Daet",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "ไหล่ทุ่ง",
              subdistrictNameEn: "Lai Thung",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "เป้า",
              subdistrictNameEn: "Pao",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "เซเป็ด",
              subdistrictNameEn: "Se Pet",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "สะพือ",
              subdistrictNameEn: "Saphue",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "หนองเต่า",
              subdistrictNameEn: "Nong Tao",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "ถ้ำแข้",
              subdistrictNameEn: "Tham Khae",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "ท่าหลวง",
              subdistrictNameEn: "Tha Luang",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "ห้วยฝ้ายพัฒนา",
              subdistrictNameEn: "Huai Fai Phatthana",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "กุดยาลวน",
              subdistrictNameEn: "Kut Ya Luan",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "บ้านแดง",
              subdistrictNameEn: "Ban Daeng",
              postalCode: 34130,
            },
            {
              subdistrictNameTh: "คำเจริญ",
              subdistrictNameEn: "Kham Charoen",
              postalCode: 34130,
            },
          ],
        },
        {
          districtNameTh: "กุดข้าวปุ้น",
          districtNameEn: "Kut Khaopun",
          subdistricts: [
            {
              subdistrictNameTh: "ข้าวปุ้น",
              subdistrictNameEn: "Khao Pun",
              postalCode: 34270,
            },
            {
              subdistrictNameTh: "โนนสวาง",
              subdistrictNameEn: "Non Sawang",
              postalCode: 34270,
            },
            {
              subdistrictNameTh: "แก่งเค็ง",
              subdistrictNameEn: "Kaeng Kheng",
              postalCode: 34270,
            },
            {
              subdistrictNameTh: "กาบิน",
              subdistrictNameEn: "Kabin",
              postalCode: 34270,
            },
            {
              subdistrictNameTh: "หนองทันน้ำ",
              subdistrictNameEn: "Nong Than Nam",
              postalCode: 34270,
            },
          ],
        },
        {
          districtNameTh: "ม่วงสามสิบ",
          districtNameEn: "Muang Sam Sip",
          subdistricts: [
            {
              subdistrictNameTh: "ม่วงสามสิบ",
              subdistrictNameEn: "Muang Sam Sip",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "เหล่าบก",
              subdistrictNameEn: "Lao Bok",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "ดุมใหญ่",
              subdistrictNameEn: "Dum Yai",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "หนองช้างใหญ่",
              subdistrictNameEn: "Nong Chang Yai",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "หนองเมือง",
              subdistrictNameEn: "Nong Mueang",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "เตย",
              subdistrictNameEn: "Toei",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "ยางสักกระโพหลุ่ม",
              subdistrictNameEn: "Yang Sak Krapho Lum",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "หนองไข่นก",
              subdistrictNameEn: "Nong Khai Nok",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "หนองเหล่า",
              subdistrictNameEn: "Nong Lao",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "หนองฮาง",
              subdistrictNameEn: "Nong Hang",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "ยางโยภาพ",
              subdistrictNameEn: "Yang Yo Phap",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "ไผ่ใหญ่",
              subdistrictNameEn: "Phai Yai",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "นาเลิง",
              subdistrictNameEn: "Na Loeng",
              postalCode: 34140,
            },
            {
              subdistrictNameTh: "โพนแพง",
              subdistrictNameEn: "Phon Phaeng",
              postalCode: 34140,
            },
          ],
        },
        {
          districtNameTh: "วารินชำราบ",
          districtNameEn: "Warin Chamrap",
          subdistricts: [
            {
              subdistrictNameTh: "วารินชำราบ",
              subdistrictNameEn: "Warin Chamrap",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "ธาตุ",
              subdistrictNameEn: "That",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "ท่าลาด",
              subdistrictNameEn: "Tha Lat",
              postalCode: 34310,
            },
            {
              subdistrictNameTh: "โนนโหนน",
              subdistrictNameEn: "Non Non",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "คูเมือง",
              subdistrictNameEn: "Khu Mueang",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "สระสมิง",
              subdistrictNameEn: "Sa Saming",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "คำน้ำแซบ",
              subdistrictNameEn: "Kham Nam Sap",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "บุ่งหวาย",
              subdistrictNameEn: "Bung Wai",
              postalCode: 34310,
            },
            {
              subdistrictNameTh: "คำขวาง",
              subdistrictNameEn: "Kham Khwang",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "โพธิ์ใหญ่",
              subdistrictNameEn: "Pho Yai",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "แสนสุข",
              subdistrictNameEn: "Saen Suk",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "หนองกินเพล",
              subdistrictNameEn: "Nong Kin Phlen",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "โนนผึ้ง",
              subdistrictNameEn: "Non Phueng",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "เมืองศรีไค",
              subdistrictNameEn: "Mueang Si Khai",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "ห้วยขะยุง",
              subdistrictNameEn: "Huai Khayung",
              postalCode: 34310,
            },
            {
              subdistrictNameTh: "บุ่งไหม",
              subdistrictNameEn: "Bung Mai",
              postalCode: 34190,
            },
          ],
        },
        {
          districtNameTh: "พิบูลมังสาหาร",
          districtNameEn: "Phibun Mangsahan",
          subdistricts: [
            {
              subdistrictNameTh: "พิบูล",
              subdistrictNameEn: "Phibun",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "กุดชมภู",
              subdistrictNameEn: "Kut Chomphu",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "ดอนจิก",
              subdistrictNameEn: "Don Chik",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "ทรายมูล",
              subdistrictNameEn: "Sai Mun",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "โนนกลาง",
              subdistrictNameEn: "Non Klang",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "โพธิ์ไทร",
              subdistrictNameEn: "Pho Sai",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "โพธิ์ศรี",
              subdistrictNameEn: "Pho Si",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "ระเว",
              subdistrictNameEn: "Rawe",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "ไร่ใต้",
              subdistrictNameEn: "Rai Tai",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "หนองบัวฮี",
              subdistrictNameEn: "Nong Bua Hi",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "อ่างศิลา",
              subdistrictNameEn: "Ang Sila",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "โนนกาหลง",
              subdistrictNameEn: "Non Kalong",
              postalCode: 34110,
            },
            {
              subdistrictNameTh: "บ้านแขม",
              subdistrictNameEn: "Ban Khaem",
              postalCode: 34110,
            },
          ],
        },
        {
          districtNameTh: "ตาลสุม",
          districtNameEn: "Tan Sum",
          subdistricts: [
            {
              subdistrictNameTh: "ตาลสุม",
              subdistrictNameEn: "Tan Sum",
              postalCode: 34330,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 34330,
            },
            {
              subdistrictNameTh: "จิกเทิง",
              subdistrictNameEn: "Chik Thoeng",
              postalCode: 34330,
            },
            {
              subdistrictNameTh: "หนองกุง",
              subdistrictNameEn: "Nong Kung",
              postalCode: 34330,
            },
            {
              subdistrictNameTh: "นาคาย",
              subdistrictNameEn: "Na Khai",
              postalCode: 34330,
            },
            {
              subdistrictNameTh: "คำหว้า",
              subdistrictNameEn: "Kham Wa",
              postalCode: 34330,
            },
          ],
        },
        {
          districtNameTh: "โพธิ์ไทร",
          districtNameEn: "Pho Sai",
          subdistricts: [
            {
              subdistrictNameTh: "โพธิ์ไทร",
              subdistrictNameEn: "Pho Sai",
              postalCode: 34340,
            },
            {
              subdistrictNameTh: "ม่วงใหญ่",
              subdistrictNameEn: "Muang Yai",
              postalCode: 34340,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 34340,
            },
            {
              subdistrictNameTh: "สองคอน",
              subdistrictNameEn: "Song Khon",
              postalCode: 34340,
            },
            {
              subdistrictNameTh: "สารภี",
              subdistrictNameEn: "Saraphi",
              postalCode: 34340,
            },
            {
              subdistrictNameTh: "เหล่างาม",
              subdistrictNameEn: "Lao Ngam",
              postalCode: 34340,
            },
          ],
        },
        {
          districtNameTh: "สำโรง",
          districtNameEn: "Samrong",
          subdistricts: [
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "โคกก่อง",
              subdistrictNameEn: "Khok Kong",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "หนองไฮ",
              subdistrictNameEn: "Nong Hai",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "ค้อน้อย",
              subdistrictNameEn: "Kho Noi",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "โนนกาเล็น",
              subdistrictNameEn: "Non Kalen",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "โคกสว่าง",
              subdistrictNameEn: "Khok Sawang",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "โนนกลาง",
              subdistrictNameEn: "Non Klang",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "บอน",
              subdistrictNameEn: "Bon",
              postalCode: 34360,
            },
            {
              subdistrictNameTh: "ขามป้อม",
              subdistrictNameEn: "Kham Pom",
              postalCode: 34360,
            },
          ],
        },
        {
          districtNameTh: "ดอนมดแดง",
          districtNameEn: "Don Mot Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "ดอนมดแดง",
              subdistrictNameEn: "Don Mot Daeng",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "เหล่าแดง",
              subdistrictNameEn: "Lao Daeng",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "ท่าเมือง",
              subdistrictNameEn: "Tha Mueang",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "คำไฮใหญ่",
              subdistrictNameEn: "Kham Hai Yai",
              postalCode: 34000,
            },
          ],
        },
        {
          districtNameTh: "สิรินธร",
          districtNameEn: "Sirindhorn",
          subdistricts: [
            {
              subdistrictNameTh: "คันไร่",
              subdistrictNameEn: "Khan Rai",
              postalCode: 34350,
            },
            {
              subdistrictNameTh: "ช่องเม็ก",
              subdistrictNameEn: "Chong Mek",
              postalCode: 34350,
            },
            {
              subdistrictNameTh: "โนนก่อ",
              subdistrictNameEn: "Non Ko",
              postalCode: 34350,
            },
            {
              subdistrictNameTh: "นิคมสร้างตนเองลำโดมน้อย",
              subdistrictNameEn: "Nikhom Lam Dom Noi",
              postalCode: 34350,
            },
            {
              subdistrictNameTh: "ฝางคำ",
              subdistrictNameEn: "Fang Kham",
              postalCode: 34350,
            },
            {
              subdistrictNameTh: "คำเขื่อนแก้ว",
              subdistrictNameEn: "Kham Khuean Kaeo",
              postalCode: 34350,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งศรีอุดม",
          districtNameEn: "Thung Si Udom",
          subdistricts: [
            {
              subdistrictNameTh: "หนองอ้ม",
              subdistrictNameEn: "Nong Om",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "นาเกษม",
              subdistrictNameEn: "Na Kasem",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "กุดเรือ",
              subdistrictNameEn: "Kut Ruea",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "โคกชำแระ",
              subdistrictNameEn: "Khok Chamrae",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "นาห่อม",
              subdistrictNameEn: "Na Hom",
              postalCode: 34160,
            },
          ],
        },
        {
          districtNameTh: "นาเยีย",
          districtNameEn: "Na Yia",
          subdistricts: [
            {
              subdistrictNameTh: "นาเยีย",
              subdistrictNameEn: "Na Yia",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 34160,
            },
            {
              subdistrictNameTh: "นาเรือง",
              subdistrictNameEn: "Na Rueang",
              postalCode: 34160,
            },
          ],
        },
        {
          districtNameTh: "นาตาล",
          districtNameEn: "Na Tan",
          subdistricts: [
            {
              subdistrictNameTh: "นาตาล",
              subdistrictNameEn: "Na Tan",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "พะลาน",
              subdistrictNameEn: "Phalan",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "กองโพน",
              subdistrictNameEn: "Kong Phon",
              postalCode: 34170,
            },
            {
              subdistrictNameTh: "พังเคน",
              subdistrictNameEn: "Phang Khen",
              postalCode: 34170,
            },
          ],
        },
        {
          districtNameTh: "เหล่าเสือโก้ก",
          districtNameEn: "Lao Suea Kok",
          subdistricts: [
            {
              subdistrictNameTh: "เหล่าเสือโก้ก",
              subdistrictNameEn: "Lao Suea Kok",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "โพนเมือง",
              subdistrictNameEn: "Phon Mueang",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "แพงใหญ่",
              subdistrictNameEn: "Phaeng Yai",
              postalCode: 34000,
            },
            {
              subdistrictNameTh: "หนองบก",
              subdistrictNameEn: "Nong Bok",
              postalCode: 34000,
            },
          ],
        },
        {
          districtNameTh: "สว่างวีระวงศ์",
          districtNameEn: "Sawang Wirawong",
          subdistricts: [
            {
              subdistrictNameTh: "แก่งโดม",
              subdistrictNameEn: "Kaeng Dom",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "บุ่งมะแลง",
              subdistrictNameEn: "Bung Malaeng",
              postalCode: 34190,
            },
            {
              subdistrictNameTh: "สว่าง",
              subdistrictNameEn: "Sawang",
              postalCode: 34190,
            },
          ],
        },
        {
          districtNameTh: "น้ำขุ่น",
          districtNameEn: "Nam Khun",
          subdistricts: [
            {
              subdistrictNameTh: "ตาเกา",
              subdistrictNameEn: "Ta Kao",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "ไพบูลย์",
              subdistrictNameEn: "Phaibun",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "ขี้เหล็ก",
              subdistrictNameEn: "Khi Lek",
              postalCode: 34260,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 34260,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ยโสธร",
      stateNameEn: "Yasothon",
      districts: [
        {
          districtNameTh: "เมืองยโสธร",
          districtNameEn: "Mueang Yasothon",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "น้ำคำใหญ่",
              subdistrictNameEn: "Nam Kham Yai",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "ตาดทอง",
              subdistrictNameEn: "Tat Thong",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "สำราญ",
              subdistrictNameEn: "Samran",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "ค้อเหนือ",
              subdistrictNameEn: "Kho Nuea",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "ดู่ทุ่ง",
              subdistrictNameEn: "Du Thung",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "เดิด",
              subdistrictNameEn: "Doet",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "ขั้นไดใหญ่",
              subdistrictNameEn: "Khan Dai Yai",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "ทุ่งแต้",
              subdistrictNameEn: "Thung Tae",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "สิงห์",
              subdistrictNameEn: "Sing",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "นาสะไมย์",
              subdistrictNameEn: "Na Samai",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "เขื่องคำ",
              subdistrictNameEn: "Khueang Kham",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "หนองหิน",
              subdistrictNameEn: "Nong Hin",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "หนองคู",
              subdistrictNameEn: "Nong Khu",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "ขุมเงิน",
              subdistrictNameEn: "Khum Ngoen",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "ทุ่งนางโอก",
              subdistrictNameEn: "Thung Nang Ok",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "หนองเรือ",
              subdistrictNameEn: "Nong Ruea",
              postalCode: 35000,
            },
            {
              subdistrictNameTh: "หนองเป็ด",
              subdistrictNameEn: "Nong Pet",
              postalCode: 35000,
            },
          ],
        },
        {
          districtNameTh: "ทรายมูล",
          districtNameEn: "Sai Mun",
          subdistricts: [
            {
              subdistrictNameTh: "ทรายมูล",
              subdistrictNameEn: "Sai Mun",
              postalCode: 35170,
            },
            {
              subdistrictNameTh: "ดู่ลาด",
              subdistrictNameEn: "Du Lat",
              postalCode: 35170,
            },
            {
              subdistrictNameTh: "ดงมะไฟ",
              subdistrictNameEn: "Dong Mafai",
              postalCode: 35170,
            },
            {
              subdistrictNameTh: "นาเวียง",
              subdistrictNameEn: "Na Wiang",
              postalCode: 35170,
            },
            {
              subdistrictNameTh: "ไผ่",
              subdistrictNameEn: "Phai",
              postalCode: 35170,
            },
          ],
        },
        {
          districtNameTh: "กุดชุม",
          districtNameEn: "Kut Chum",
          subdistricts: [
            {
              subdistrictNameTh: "กุดชุม",
              subdistrictNameEn: "Kut Chum",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "โนนเปือย",
              subdistrictNameEn: "Non Pueai",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "กำแมด",
              subdistrictNameEn: "Kammaet",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "นาโส่",
              subdistrictNameEn: "Na So",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "ห้วยแก้ง",
              subdistrictNameEn: "Huai Kaeng",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "หนองหมี",
              subdistrictNameEn: "Nong Mi",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "คำน้ำสร้าง",
              subdistrictNameEn: "Kham Nam Sang",
              postalCode: 35140,
            },
            {
              subdistrictNameTh: "หนองแหน",
              subdistrictNameEn: "Nong Nae",
              postalCode: 35140,
            },
          ],
        },
        {
          districtNameTh: "คำเขื่อนแก้ว",
          districtNameEn: "Kham Khuean Kaeo",
          subdistricts: [
            {
              subdistrictNameTh: "ลุมพุก",
              subdistrictNameEn: "Lumphuk",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "ย่อ",
              subdistrictNameEn: "Yo",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "สงเปือย",
              subdistrictNameEn: "Song Pueai",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "โพนทัน",
              subdistrictNameEn: "Phon Than",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "ทุ่งมน",
              subdistrictNameEn: "Thung Mon",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "นาคำ",
              subdistrictNameEn: "Na Kham",
              postalCode: 35180,
            },
            {
              subdistrictNameTh: "ดงแคนใหญ่",
              subdistrictNameEn: "Dong Khaen Yai",
              postalCode: 35180,
            },
            {
              subdistrictNameTh: "กู่จาน",
              subdistrictNameEn: "Ku Chan",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "นาแก",
              subdistrictNameEn: "Na Kae",
              postalCode: 35180,
            },
            {
              subdistrictNameTh: "กุดกุง",
              subdistrictNameEn: "Kut Kung",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "เหล่าไฮ",
              subdistrictNameEn: "Lao Hai",
              postalCode: 35110,
            },
            {
              subdistrictNameTh: "แคนน้อย",
              subdistrictNameEn: "Khaen Noi",
              postalCode: 35180,
            },
            {
              subdistrictNameTh: "ดงเจริญ",
              subdistrictNameEn: "Dong Charoen",
              postalCode: 35110,
            },
          ],
        },
        {
          districtNameTh: "ป่าติ้ว",
          districtNameEn: "Pa Tio",
          subdistricts: [
            {
              subdistrictNameTh: "โพธิ์ไทร",
              subdistrictNameEn: "Pho Sai",
              postalCode: 35150,
            },
            {
              subdistrictNameTh: "กระจาย",
              subdistrictNameEn: "Krachai",
              postalCode: 35150,
            },
            {
              subdistrictNameTh: "โคกนาโก",
              subdistrictNameEn: "Khok Na Ko",
              postalCode: 35150,
            },
            {
              subdistrictNameTh: "เชียงเพ็ง",
              subdistrictNameEn: "Chiang Pheng",
              postalCode: 35150,
            },
            {
              subdistrictNameTh: "ศรีฐาน",
              subdistrictNameEn: "Si Than",
              postalCode: 35150,
            },
          ],
        },
        {
          districtNameTh: "มหาชนะชัย",
          districtNameEn: "Maha Chana Chai",
          subdistricts: [
            {
              subdistrictNameTh: "ฟ้าหยาด",
              subdistrictNameEn: "Fa Yat",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "หัวเมือง",
              subdistrictNameEn: "Hua Mueang",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "คูเมือง",
              subdistrictNameEn: "Khu Mueang",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "ผือฮี",
              subdistrictNameEn: "Phue Hi",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "บากเรือ",
              subdistrictNameEn: "Bak Ruea",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "ม่วง",
              subdistrictNameEn: "Muang",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "โนนทราย",
              subdistrictNameEn: "Non Sai",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "บึงแก",
              subdistrictNameEn: "Bueng Kae",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "พระเสาร์",
              subdistrictNameEn: "Phra Sao",
              postalCode: 35130,
            },
            {
              subdistrictNameTh: "สงยาง",
              subdistrictNameEn: "Song Yang",
              postalCode: 35130,
            },
          ],
        },
        {
          districtNameTh: "ค้อวัง",
          districtNameEn: "Kho Wang",
          subdistricts: [
            {
              subdistrictNameTh: "ฟ้าห่วน",
              subdistrictNameEn: "Fa Huan",
              postalCode: 35160,
            },
            {
              subdistrictNameTh: "กุดน้ำใส",
              subdistrictNameEn: "Kut Nam Sai",
              postalCode: 35160,
            },
            {
              subdistrictNameTh: "น้ำอ้อม",
              subdistrictNameEn: "Nam Om",
              postalCode: 35160,
            },
            {
              subdistrictNameTh: "ค้อวัง",
              subdistrictNameEn: "Kho Wang",
              postalCode: 35160,
            },
          ],
        },
        {
          districtNameTh: "เลิงนกทา",
          districtNameEn: "Loeng Nok Tha",
          subdistricts: [
            {
              subdistrictNameTh: "บุ่งค้า",
              subdistrictNameEn: "Bung Kha",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "สวาท",
              subdistrictNameEn: "Sawat",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "ห้องแซง",
              subdistrictNameEn: "Hong Saeng",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "สามัคคี",
              subdistrictNameEn: "Samakkhi",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "กุดเชียงหมี",
              subdistrictNameEn: "Kut Chiang Mi",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "สามแยก",
              subdistrictNameEn: "Sam Yaek",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "กุดแห่",
              subdistrictNameEn: "Kut Hae",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "โคกสำราญ",
              subdistrictNameEn: "Khok Samran",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "สร้างมิ่ง",
              subdistrictNameEn: "Sang Ming",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "ศรีแก้ว",
              subdistrictNameEn: "Si Kaeo",
              postalCode: 35120,
            },
          ],
        },
        {
          districtNameTh: "ไทยเจริญ",
          districtNameEn: "Thai Charoen",
          subdistricts: [
            {
              subdistrictNameTh: "ไทยเจริญ",
              subdistrictNameEn: "Thai Charoen",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "น้ำคำ",
              subdistrictNameEn: "Nam Kham",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "ส้มผ่อ",
              subdistrictNameEn: "Som Pho",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "คำเตย",
              subdistrictNameEn: "Kham Toei",
              postalCode: 35120,
            },
            {
              subdistrictNameTh: "คำไผ่",
              subdistrictNameEn: "Kham Phai",
              postalCode: 35120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ชัยภูมิ",
      stateNameEn: "Chaiyaphum",
      districts: [
        {
          districtNameTh: "เมืองชัยภูมิ",
          districtNameEn: "Mueang Chaiyaphum",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "รอบเมือง",
              subdistrictNameEn: "Rop Mueang",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "นาฝาย",
              subdistrictNameEn: "Na Fai",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "บ้านค่าย",
              subdistrictNameEn: "Ban Khai",
              postalCode: 36240,
            },
            {
              subdistrictNameTh: "กุดตุ้ม",
              subdistrictNameEn: "Kut Tum",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "ชีลอง",
              subdistrictNameEn: "Chilong",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "บ้านเล่า",
              subdistrictNameEn: "Ban Lao",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "นาเสียว",
              subdistrictNameEn: "Na Siao",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "หนองนาแซง",
              subdistrictNameEn: "Nong Na Saeng",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "ลาดใหญ่",
              subdistrictNameEn: "Lat Yai",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 36240,
            },
            {
              subdistrictNameTh: "ท่าหินโงม",
              subdistrictNameEn: "Tha Hin Ngom",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "ห้วยต้อน",
              subdistrictNameEn: "Huai Ton",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "ห้วยบง",
              subdistrictNameEn: "Huai Bong",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "โนนสำราญ",
              subdistrictNameEn: "Non Samran",
              postalCode: 36240,
            },
            {
              subdistrictNameTh: "โคกสูง",
              subdistrictNameEn: "Khok Sung",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "บุ่งคล้า",
              subdistrictNameEn: "Bung Khla",
              postalCode: 36000,
            },
            {
              subdistrictNameTh: "ซับสีทอง",
              subdistrictNameEn: "Sap Si Thong",
              postalCode: 36000,
            },
          ],
        },
        {
          districtNameTh: "บ้านเขว้า",
          districtNameEn: "Ban Khwao",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านเขว้า",
              subdistrictNameEn: "Ban Khwao",
              postalCode: 36170,
            },
            {
              subdistrictNameTh: "ตลาดแร้ง",
              subdistrictNameEn: "Talat Raeng",
              postalCode: 36170,
            },
            {
              subdistrictNameTh: "ลุ่มลำชี",
              subdistrictNameEn: "Lum Lam Chi",
              postalCode: 36170,
            },
            {
              subdistrictNameTh: "ชีบน",
              subdistrictNameEn: "Chi Bon",
              postalCode: 36170,
            },
            {
              subdistrictNameTh: "ภูแลนคา",
              subdistrictNameEn: "Phu Laen Kha",
              postalCode: 36170,
            },
            {
              subdistrictNameTh: "โนนแดง",
              subdistrictNameEn: "Non Daeng",
              postalCode: 36170,
            },
          ],
        },
        {
          districtNameTh: "คอนสวรรค์",
          districtNameEn: "Khon Sawan",
          subdistricts: [
            {
              subdistrictNameTh: "คอนสวรรค์",
              subdistrictNameEn: "Khon Sawan",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "ยางหวาย",
              subdistrictNameEn: "Yang Wai",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "ช่องสามหมอ",
              subdistrictNameEn: "Chong Sam Mo",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "ห้วยไร่",
              subdistrictNameEn: "Huai Rai",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "บ้านโสก",
              subdistrictNameEn: "Ban Sok",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "โคกมั่งงอย",
              subdistrictNameEn: "Khok Mang Ngoi",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "หนองขาม",
              subdistrictNameEn: "Nong Kham",
              postalCode: 36140,
            },
            {
              subdistrictNameTh: "ศรีสำราญ",
              subdistrictNameEn: "Si Samran",
              postalCode: 36140,
            },
          ],
        },
        {
          districtNameTh: "เกษตรสมบูรณ์",
          districtNameEn: "Kaset Sombun",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "บ้านหัน",
              subdistrictNameEn: "Ban Han",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "บ้านเดื่อ",
              subdistrictNameEn: "Ban Duea",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "บ้านเป้า",
              subdistrictNameEn: "Ban Pao",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "กุดเลาะ",
              subdistrictNameEn: "Kut Lo",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "โนนกอก",
              subdistrictNameEn: "Non Kok",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "สระโพนทอง",
              subdistrictNameEn: "Sa Phon Thong",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "หนองข่า",
              subdistrictNameEn: "Nong Kha",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "หนองโพนงาม",
              subdistrictNameEn: "Nong Phon Ngam",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "บ้านบัว",
              subdistrictNameEn: "Ban Bua",
              postalCode: 36120,
            },
            {
              subdistrictNameTh: "โนนทอง",
              subdistrictNameEn: "Non Thong",
              postalCode: 36120,
            },
          ],
        },
        {
          districtNameTh: "หนองบัวแดง",
          districtNameEn: "Nong Bua Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "หนองบัวแดง",
              subdistrictNameEn: "Nong Bua Daeng",
              postalCode: 36210,
            },
            {
              subdistrictNameTh: "กุดชุมแสง",
              subdistrictNameEn: "Kut Chum Saeng",
              postalCode: 36210,
            },
            {
              subdistrictNameTh: "ถ้ำวัวแดง",
              subdistrictNameEn: "Tham Wua Daeng",
              postalCode: 36210,
            },
            {
              subdistrictNameTh: "นางแดด",
              subdistrictNameEn: "Nang Daet",
              postalCode: 36210,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 36210,
            },
            {
              subdistrictNameTh: "คูเมือง",
              subdistrictNameEn: "Khu Mueang",
              postalCode: 36210,
            },
            {
              subdistrictNameTh: "ท่าใหญ่",
              subdistrictNameEn: "Tha Yai",
              postalCode: 36210,
            },
            {
              subdistrictNameTh: "วังชมภู",
              subdistrictNameEn: "Wang Chomphu",
              postalCode: 36210,
            },
          ],
        },
        {
          districtNameTh: "จัตุรัส",
          districtNameEn: "Chatturat",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านกอก",
              subdistrictNameEn: "Ban Kok",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "หนองบัวบาน",
              subdistrictNameEn: "Nong Bua Ban",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "บ้านขาม",
              subdistrictNameEn: "Ban Kham",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "กุดน้ำใส",
              subdistrictNameEn: "Kut Nam Sai",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "หนองโดน",
              subdistrictNameEn: "Nong Don",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "ละหาน",
              subdistrictNameEn: "Lahan",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "หนองบัวใหญ่",
              subdistrictNameEn: "Nong Bua Yai",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "หนองบัวโคก",
              subdistrictNameEn: "Nong Bua Khok",
              postalCode: 36220,
            },
            {
              subdistrictNameTh: "ส้มป่อย",
              subdistrictNameEn: "Som Poi",
              postalCode: 36130,
            },
          ],
        },
        {
          districtNameTh: "บำเหน็จณรงค์",
          districtNameEn: "Bamnet Narong",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านชวน",
              subdistrictNameEn: "Ban Chuan",
              postalCode: 36160,
            },
            {
              subdistrictNameTh: "บ้านเพชร",
              subdistrictNameEn: "Ban Phet",
              postalCode: 36160,
            },
            {
              subdistrictNameTh: "บ้านตาล",
              subdistrictNameEn: "Ban Tan",
              postalCode: 36220,
            },
            {
              subdistrictNameTh: "หัวทะเล",
              subdistrictNameEn: "Hua Thale",
              postalCode: 36220,
            },
            {
              subdistrictNameTh: "โคกเริงรมย์",
              subdistrictNameEn: "Khok Roeng Rom",
              postalCode: 36160,
            },
            {
              subdistrictNameTh: "เกาะมะนาว",
              subdistrictNameEn: "Ko Manao",
              postalCode: 36160,
            },
            {
              subdistrictNameTh: "โคกเพชรพัฒนา",
              subdistrictNameEn: "Khok Phet Phatthana",
              postalCode: 36160,
            },
          ],
        },
        {
          districtNameTh: "หนองบัวระเหว",
          districtNameEn: "Nong Bua Rawe",
          subdistricts: [
            {
              subdistrictNameTh: "หนองบัวระเหว",
              subdistrictNameEn: "Nong Bua Rawe",
              postalCode: 36250,
            },
            {
              subdistrictNameTh: "วังตะเฆ่",
              subdistrictNameEn: "Wang Takhe",
              postalCode: 36250,
            },
            {
              subdistrictNameTh: "ห้วยแย้",
              subdistrictNameEn: "Huai Yae",
              postalCode: 36250,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 36250,
            },
            {
              subdistrictNameTh: "โสกปลาดุก",
              subdistrictNameEn: "Sok Pla Duk",
              postalCode: 36250,
            },
          ],
        },
        {
          districtNameTh: "เทพสถิต",
          districtNameEn: "Thep Sathit",
          subdistricts: [
            {
              subdistrictNameTh: "วะตะแบก",
              subdistrictNameEn: "Wa Tabaek",
              postalCode: 36230,
            },
            {
              subdistrictNameTh: "ห้วยยายจิ๋ว",
              subdistrictNameEn: "Huai Yai Chio",
              postalCode: 36230,
            },
            {
              subdistrictNameTh: "นายางกลัก",
              subdistrictNameEn: "Na Yang Klak",
              postalCode: 36230,
            },
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 36230,
            },
            {
              subdistrictNameTh: "โป่งนก",
              subdistrictNameEn: "Pong Nok",
              postalCode: 36230,
            },
          ],
        },
        {
          districtNameTh: "ภูเขียว",
          districtNameEn: "Phu Khiao",
          subdistricts: [
            {
              subdistrictNameTh: "ผักปัง",
              subdistrictNameEn: "Phak Pang",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "กวางโจน",
              subdistrictNameEn: "Kwang Chon",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "หนองคอนไทย",
              subdistrictNameEn: "Nong Khon Thai",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "บ้านแก้ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "กุดยม",
              subdistrictNameEn: "Kut Yom",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "บ้านเพชร",
              subdistrictNameEn: "Ban Phet",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "หนองตูม",
              subdistrictNameEn: "Nong Tum",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "โอโล",
              subdistrictNameEn: "O Lo",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "ธาตุทอง",
              subdistrictNameEn: "That Thong",
              postalCode: 36110,
            },
            {
              subdistrictNameTh: "บ้านดอน",
              subdistrictNameEn: "Ban Don",
              postalCode: 36110,
            },
          ],
        },
        {
          districtNameTh: "บ้านแท่น",
          districtNameEn: "Ban Thaen",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านแท่น",
              subdistrictNameEn: "Ban Thaen",
              postalCode: 36190,
            },
            {
              subdistrictNameTh: "สามสวน",
              subdistrictNameEn: "Sam Suan",
              postalCode: 36190,
            },
            {
              subdistrictNameTh: "สระพัง",
              subdistrictNameEn: "Sa Phang",
              postalCode: 36190,
            },
            {
              subdistrictNameTh: "บ้านเต่า",
              subdistrictNameEn: "Ban Tao",
              postalCode: 36190,
            },
            {
              subdistrictNameTh: "หนองคู",
              subdistrictNameEn: "Nong Khu",
              postalCode: 36190,
            },
          ],
        },
        {
          districtNameTh: "แก้งคร้อ",
          districtNameEn: "Kaeng Khro",
          subdistricts: [
            {
              subdistrictNameTh: "ช่องสามหมอ",
              subdistrictNameEn: "Chong Sam Mo",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "หนองขาม",
              subdistrictNameEn: "Nong Kham",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "นาหนองทุ่ม",
              subdistrictNameEn: "Na Nong Thum",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "บ้านแก้ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "หนองสังข์",
              subdistrictNameEn: "Nong Sang",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "หลุบคา",
              subdistrictNameEn: "Lup Kha",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "โคกกุง",
              subdistrictNameEn: "Khok Kung",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "เก่าย่าดี",
              subdistrictNameEn: "Kao Ya Di",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "ท่ามะไฟหวาน",
              subdistrictNameEn: "Tha Mafai Wan",
              postalCode: 36150,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 36150,
            },
          ],
        },
        {
          districtNameTh: "คอนสาร",
          districtNameEn: "Khon San",
          subdistricts: [
            {
              subdistrictNameTh: "คอนสาร",
              subdistrictNameEn: "Khon San",
              postalCode: 36180,
            },
            {
              subdistrictNameTh: "ทุ่งพระ",
              subdistrictNameEn: "Thung Phra",
              postalCode: 36180,
            },
            {
              subdistrictNameTh: "โนนคูณ",
              subdistrictNameEn: "Non Khun",
              postalCode: 36180,
            },
            {
              subdistrictNameTh: "ห้วยยาง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 36180,
            },
            {
              subdistrictNameTh: "ทุ่งลุยลาย",
              subdistrictNameEn: "Thung Lui Lai",
              postalCode: 36180,
            },
            {
              subdistrictNameTh: "ดงบัง",
              subdistrictNameEn: "Dong Bang",
              postalCode: 36180,
            },
            {
              subdistrictNameTh: "ทุ่งนาเลา",
              subdistrictNameEn: "Thung Na Lao",
              postalCode: 36180,
            },
            {
              subdistrictNameTh: "ดงกลาง",
              subdistrictNameEn: "Dong Klang",
              postalCode: 36180,
            },
          ],
        },
        {
          districtNameTh: "ภักดีชุมพล",
          districtNameEn: "Phakdi Chumphon",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านเจียง",
              subdistrictNameEn: "Ban Chiang",
              postalCode: 36260,
            },
            {
              subdistrictNameTh: "เจาทอง",
              subdistrictNameEn: "Chao Thong",
              postalCode: 36260,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 36260,
            },
            {
              subdistrictNameTh: "แหลมทอง",
              subdistrictNameEn: "Laem Thong",
              postalCode: 36260,
            },
          ],
        },
        {
          districtNameTh: "เนินสง่า",
          districtNameEn: "Noen Sa-Nga",
          subdistricts: [
            {
              subdistrictNameTh: "หนองฉิม",
              subdistrictNameEn: "Nong Chim",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "ตาเนิน",
              subdistrictNameEn: "Ta Noen",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "กะฮาด",
              subdistrictNameEn: "Kahat",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "รังงาม",
              subdistrictNameEn: "Rang Ngam",
              postalCode: 36130,
            },
          ],
        },
        {
          districtNameTh: "ซับใหญ่",
          districtNameEn: "Sap Yai",
          subdistricts: [
            {
              subdistrictNameTh: "ซับใหญ่",
              subdistrictNameEn: "Sap Yai",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "ท่ากูบ",
              subdistrictNameEn: "Tha Kup",
              postalCode: 36130,
            },
            {
              subdistrictNameTh: "ตะโกทอง",
              subdistrictNameEn: "Tako Thong",
              postalCode: 36130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "อำนาจเจริญ",
      stateNameEn: "Amnat Charoen",
      districts: [
        {
          districtNameTh: "เมืองอำนาจเจริญ",
          districtNameEn: "Mueang Amnat Charoen",
          subdistricts: [
            {
              subdistrictNameTh: "บุ่ง",
              subdistrictNameEn: "Bung",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "ไก่คำ",
              subdistrictNameEn: "Kai Kham",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "นาจิก",
              subdistrictNameEn: "Na Chik",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "ปลาค้าว",
              subdistrictNameEn: "Pla Khao",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "เหล่าพรวน",
              subdistrictNameEn: "Lao Phruan",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "สร้างนกทา",
              subdistrictNameEn: "Sang Nok Tha",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "คึมใหญ่",
              subdistrictNameEn: "Khuem Yai",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "นาผือ",
              subdistrictNameEn: "Na Phue",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "น้ำปลีก",
              subdistrictNameEn: "Nam Plik",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "นาวัง",
              subdistrictNameEn: "Na Wang",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "นาหมอม้า",
              subdistrictNameEn: "Na Mo Ma",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "โนนโพธิ์",
              subdistrictNameEn: "Non Pho",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "โนนหนามแท่ง",
              subdistrictNameEn: "Non Nam Thaeng",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "ห้วยไร่",
              subdistrictNameEn: "Huai Rai",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "หนองมะแซว",
              subdistrictNameEn: "Nong Masaeo",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "กุดปลาดุก",
              subdistrictNameEn: "Kut Pla Duk",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "ดอนเมย",
              subdistrictNameEn: "Don Moei",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "นายม",
              subdistrictNameEn: "Na Yom",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "นาแต้",
              subdistrictNameEn: "Na Tae",
              postalCode: 37000,
            },
          ],
        },
        {
          districtNameTh: "ชานุมาน",
          districtNameEn: "Chanuman",
          subdistricts: [
            {
              subdistrictNameTh: "ชานุมาน",
              subdistrictNameEn: "Chanuman",
              postalCode: 37210,
            },
            {
              subdistrictNameTh: "โคกสาร",
              subdistrictNameEn: "Khok San",
              postalCode: 37210,
            },
            {
              subdistrictNameTh: "คำเขื่อนแก้ว",
              subdistrictNameEn: "Kham Khuean Kaeo",
              postalCode: 37210,
            },
            {
              subdistrictNameTh: "โคกก่ง",
              subdistrictNameEn: "Khok Kong",
              postalCode: 37210,
            },
            {
              subdistrictNameTh: "ป่าก่อ",
              subdistrictNameEn: "Pa Ko",
              postalCode: 37210,
            },
          ],
        },
        {
          districtNameTh: "ปทุมราชวงศา",
          districtNameEn: "Pathum Ratchawongsa",
          subdistricts: [
            {
              subdistrictNameTh: "หนองข่า",
              subdistrictNameEn: "Nong Kha",
              postalCode: 37110,
            },
            {
              subdistrictNameTh: "คำโพน",
              subdistrictNameEn: "Kham Phon",
              postalCode: 37110,
            },
            {
              subdistrictNameTh: "นาหว้า",
              subdistrictNameEn: "Na Wa",
              postalCode: 37110,
            },
            {
              subdistrictNameTh: "ลือ",
              subdistrictNameEn: "Lue",
              postalCode: 37110,
            },
            {
              subdistrictNameTh: "ห้วย",
              subdistrictNameEn: "Huai",
              postalCode: 37110,
            },
            {
              subdistrictNameTh: "โนนงาม",
              subdistrictNameEn: "Non Ngam",
              postalCode: 37110,
            },
            {
              subdistrictNameTh: "นาป่าแซง",
              subdistrictNameEn: "Na Pa Saeng",
              postalCode: 37110,
            },
          ],
        },
        {
          districtNameTh: "พนา",
          districtNameEn: "Phana",
          subdistricts: [
            {
              subdistrictNameTh: "พนา",
              subdistrictNameEn: "Phana",
              postalCode: 37180,
            },
            {
              subdistrictNameTh: "จานลาน",
              subdistrictNameEn: "Chan Lan",
              postalCode: 37180,
            },
            {
              subdistrictNameTh: "ไม้กลอน",
              subdistrictNameEn: "Mai Klon",
              postalCode: 37180,
            },
            {
              subdistrictNameTh: "พระเหลา",
              subdistrictNameEn: "Phra Lao",
              postalCode: 37180,
            },
          ],
        },
        {
          districtNameTh: "เสนางคนิคม",
          districtNameEn: "Senangkhanikhom",
          subdistricts: [
            {
              subdistrictNameTh: "เสนางคนิคม",
              subdistrictNameEn: "Senangkha Nikhom",
              postalCode: 37290,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 37290,
            },
            {
              subdistrictNameTh: "ไร่สีสุก",
              subdistrictNameEn: "Rai Si Suk",
              postalCode: 37290,
            },
            {
              subdistrictNameTh: "นาเวียง",
              subdistrictNameEn: "Na Wiang",
              postalCode: 37290,
            },
            {
              subdistrictNameTh: "หนองไฮ",
              subdistrictNameEn: "Nong Hai",
              postalCode: 37290,
            },
            {
              subdistrictNameTh: "หนองสามสี",
              subdistrictNameEn: "Nong Sam Si",
              postalCode: 37290,
            },
          ],
        },
        {
          districtNameTh: "หัวตะพาน",
          districtNameEn: "Hua Taphan",
          subdistricts: [
            {
              subdistrictNameTh: "หัวตะพาน",
              subdistrictNameEn: "Hua Taphan",
              postalCode: 37240,
            },
            {
              subdistrictNameTh: "คำพระ",
              subdistrictNameEn: "Kham Phra",
              postalCode: 37240,
            },
            {
              subdistrictNameTh: "เค็งใหญ่",
              subdistrictNameEn: "Kheng Yai",
              postalCode: 37240,
            },
            {
              subdistrictNameTh: "หนองแก้ว",
              subdistrictNameEn: "Nong Kaeo",
              postalCode: 37240,
            },
            {
              subdistrictNameTh: "โพนเมืองน้อย",
              subdistrictNameEn: "Phon Mueang Noi",
              postalCode: 37240,
            },
            {
              subdistrictNameTh: "สร้างถ่อน้อย",
              subdistrictNameEn: "Sang Tho Noi",
              postalCode: 37240,
            },
            {
              subdistrictNameTh: "จิกดู่",
              subdistrictNameEn: "Chik Du",
              postalCode: 37240,
            },
            {
              subdistrictNameTh: "รัตนวารี",
              subdistrictNameEn: "Rattana Wari",
              postalCode: 37240,
            },
          ],
        },
        {
          districtNameTh: "ลืออำนาจ",
          districtNameEn: "Lue Amnat",
          subdistricts: [
            {
              subdistrictNameTh: "อำนาจ",
              subdistrictNameEn: "Amnat",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "ดงมะยาง",
              subdistrictNameEn: "Dong Mayang",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "เปือย",
              subdistrictNameEn: "Pueai",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "ดงบัง",
              subdistrictNameEn: "Dong Bang",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "ไร่ขี",
              subdistrictNameEn: "Rai Khi",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "แมด",
              subdistrictNameEn: "Maet",
              postalCode: 37000,
            },
            {
              subdistrictNameTh: "โคกกลาง",
              subdistrictNameEn: "Khok Klang",
              postalCode: 37000,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "บึงกาฬ",
      stateNameEn: "Bueng Kan",
      districts: [
        {
          districtNameTh: "เมืองบึงกาฬ",
          districtNameEn: "Bueng Kan",
          subdistricts: [
            {
              subdistrictNameTh: "บึงกาฬ",
              subdistrictNameEn: "Seka",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "โนนสมบูรณ์",
              subdistrictNameEn: "Sang ",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "โนนสว่าง",
              subdistrictNameEn: "Tha Kok Daeng",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "หอคำ",
              subdistrictNameEn: "Ban Tong",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "หนองเลิง",
              subdistrictNameEn: "Pong Hai",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "โคกก่อง",
              subdistrictNameEn: "Nam Chan",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "นาสวรรค์",
              subdistrictNameEn: "Tha Sa-At",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "ไคสี",
              subdistrictNameEn: "Nong Thum",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "ชัยพร",
              subdistrictNameEn: "Sok Kam",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "วิศิษฐ์",
              subdistrictNameEn: "Wisit",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "คำนาดี",
              subdistrictNameEn: "Kham Na Di",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "โป่งเปือย",
              subdistrictNameEn: "Pong Pueai",
              postalCode: 38000,
            },
          ],
        },
        {
          districtNameTh: "พรเจริญ",
          districtNameEn: "Phon Charoen",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีชมภู",
              subdistrictNameEn: "Si Chomphu",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "ดอนหญ้านาง",
              subdistrictNameEn: "Don Ya Nang ",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "พรเจริญ",
              subdistrictNameEn: "Phon Charoen",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "หนองหัวช้าง",
              subdistrictNameEn: "Nong Hua Chang",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "วังชมภู",
              subdistrictNameEn: "Wang Chomphu",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "ป่าแฝก",
              subdistrictNameEn: "Pa Faek",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "ศรีสำราญ",
              subdistrictNameEn: "Si Samran",
              postalCode: 38150,
            },
          ],
        },
        {
          districtNameTh: "โซ่พิสัย",
          districtNameEn: "So Phisai",
          subdistricts: [
            {
              subdistrictNameTh: "โซ่",
              subdistrictNameEn: "So",
              postalCode: 38170,
            },
            {
              subdistrictNameTh: "หนองพันทา",
              subdistrictNameEn: "Nong Phan Tha",
              postalCode: 38170,
            },
            {
              subdistrictNameTh: "ศรีชมภู",
              subdistrictNameEn: "Si Chomphu",
              postalCode: 38170,
            },
            {
              subdistrictNameTh: "คำแก้ว",
              subdistrictNameEn: "Kham Kaeo",
              postalCode: 38170,
            },
            {
              subdistrictNameTh: "บัวตูม",
              subdistrictNameEn: "Bua Tum",
              postalCode: 38170,
            },
            {
              subdistrictNameTh: "ถ้ำเจริญ",
              subdistrictNameEn: "Tham Charoen",
              postalCode: 38170,
            },
            {
              subdistrictNameTh: "เหล่าทอง",
              subdistrictNameEn: "Lao Thong",
              postalCode: 38170,
            },
          ],
        },
        {
          districtNameTh: "เซกา",
          districtNameEn: "Seka",
          subdistricts: [
            {
              subdistrictNameTh: "เซกา",
              subdistrictNameEn: "Pak Khat",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "ซาง",
              subdistrictNameEn: "Nong Yong",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "ท่ากกแดง",
              subdistrictNameEn: "Na Kang",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "บ้านต้อง",
              subdistrictNameEn: "Non Sila",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "ป่งไฮ",
              subdistrictNameEn: "Som Sanuk",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "น้ำจั้น",
              subdistrictNameEn: "Na Dong",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "ท่าสะอาด",
              subdistrictNameEn: "Tha Sa-At",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "หนองทุ่ม",
              subdistrictNameEn: "Nong Thum",
              postalCode: 38150,
            },
            {
              subdistrictNameTh: "โสกก่าม",
              subdistrictNameEn: "Sok Kam",
              postalCode: 38150,
            },
          ],
        },
        {
          districtNameTh: "ปากคาด",
          districtNameEn: "Pak Khat",
          subdistricts: [
            {
              subdistrictNameTh: "ปากคาด",
              subdistrictNameEn: "Bueng Khong Long",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "หนองยอง",
              subdistrictNameEn: "Pho Mak Khaeng",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "นากั้ง",
              subdistrictNameEn: "Dong Bang",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "โนนศิลา",
              subdistrictNameEn: "Tha Dok Kham",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "สมสนุก",
              subdistrictNameEn: "Som Sanook",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "นาดง",
              subdistrictNameEn: "Na Dong",
              postalCode: 38190,
            },
          ],
        },
        {
          districtNameTh: "บึงโขงหลง",
          districtNameEn: "Bueng Khong Long",
          subdistricts: [
            {
              subdistrictNameTh: "บึงโขงหลง",
              subdistrictNameEn: "Bueng Khong Long",
              postalCode: 38220,
            },
            {
              subdistrictNameTh: "โพธิ์หมากแข้ง",
              subdistrictNameEn: "Pho Mak Khaeng",
              postalCode: 38220,
            },
            {
              subdistrictNameTh: "ดงบัง",
              subdistrictNameEn: "Dong Bang",
              postalCode: 38220,
            },
            {
              subdistrictNameTh: "ท่าดอกคำ",
              subdistrictNameEn: "Tha Dok Kham",
              postalCode: 38220,
            },
          ],
        },
        {
          districtNameTh: "ศรีวิไล",
          districtNameEn: "Si Wilai",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีวิไล",
              subdistrictNameEn: "Si Wilai",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "ชุมภูพร",
              subdistrictNameEn: "Chumphu Phon",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "นาแสง",
              subdistrictNameEn: "Na Saeng",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "นาสะแบง",
              subdistrictNameEn: "Na Sabaeng",
              postalCode: 38190,
            },
            {
              subdistrictNameTh: "นาสิงห์",
              subdistrictNameEn: "Na Sing",
              postalCode: 38190,
            },
          ],
        },
        {
          districtNameTh: "บุ่งคล้า",
          districtNameEn: "Bung Khla",
          subdistricts: [
            {
              subdistrictNameTh: "บุ่งคล้า",
              subdistrictNameEn: "Bung Khla",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "หนองเดิ่น",
              subdistrictNameEn: "Nong Doen",
              postalCode: 38000,
            },
            {
              subdistrictNameTh: "โคกกว้าง",
              subdistrictNameEn: "Khok Kwang",
              postalCode: 38000,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "หนองบัวลำภู",
      stateNameEn: "Nong Bua Lam Phu",
      districts: [
        {
          districtNameTh: "เมืองหนองบัวลำภู",
          districtNameEn: "Mueang Nong Bua Lam Phu",
          subdistricts: [
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "หนองภัยศูนย์",
              subdistrictNameEn: "Nong Phai Sun",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "โพธิ์ชัย",
              subdistrictNameEn: "Pho Chai",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "หนองสวรรค์",
              subdistrictNameEn: "Nong Sawan",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "หัวนา",
              subdistrictNameEn: "Hua Na",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "บ้านขาม",
              subdistrictNameEn: "Ban Kham",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "นามะเฟือง",
              subdistrictNameEn: "Na Mafueang",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "บ้านพร้าว",
              subdistrictNameEn: "Ban Phrao",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "โนนขมิ้น",
              subdistrictNameEn: "Non Khamin",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "ลำภู",
              subdistrictNameEn: "Lamphu",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "กุดจิก",
              subdistrictNameEn: "Kut Chik",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "โนนทัน",
              subdistrictNameEn: "Non Than",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "นาคำไฮ",
              subdistrictNameEn: "Na Kham Hai",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "ป่าไม้งาม",
              subdistrictNameEn: "Pa Mai Ngam",
              postalCode: 39000,
            },
            {
              subdistrictNameTh: "หนองหว้า",
              subdistrictNameEn: "Nong Wa",
              postalCode: 39000,
            },
          ],
        },
        {
          districtNameTh: "นากลาง",
          districtNameEn: "Na Klang",
          subdistricts: [
            {
              subdistrictNameTh: "นากลาง",
              subdistrictNameEn: "Na Klang",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "ด่านช้าง",
              subdistrictNameEn: "Dan Chang",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "กุดดินจี่",
              subdistrictNameEn: "Kut Din Chi",
              postalCode: 39350,
            },
            {
              subdistrictNameTh: "ฝั่งแดง",
              subdistrictNameEn: "Fang Daeng",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "เก่ากลอย",
              subdistrictNameEn: "Kao Kloi",
              postalCode: 39350,
            },
            {
              subdistrictNameTh: "โนนเมือง",
              subdistrictNameEn: "Non Mueang",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "อุทัยสวรรค์",
              subdistrictNameEn: "Uthai Sawan",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "ดงสวรรค์",
              subdistrictNameEn: "Dong Sawan",
              postalCode: 39350,
            },
            {
              subdistrictNameTh: "กุดแห่",
              subdistrictNameEn: "Kut Hae",
              postalCode: 39170,
            },
          ],
        },
        {
          districtNameTh: "โนนสัง",
          districtNameEn: "Non Sang",
          subdistricts: [
            {
              subdistrictNameTh: "โนนสัง",
              subdistrictNameEn: "Non Sang",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "บ้านถิ่น",
              subdistrictNameEn: "Ban Thin",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "หนองเรือ",
              subdistrictNameEn: "Nong Ruea",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "กุดดู่",
              subdistrictNameEn: "Kut Du",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "บ้านค้อ",
              subdistrictNameEn: "Ban Kho",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "โนนเมือง",
              subdistrictNameEn: "Non Mueang",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "โคกใหญ่",
              subdistrictNameEn: "Khok Yai",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "โคกม่วง",
              subdistrictNameEn: "Khok Muang",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "นิคมพัฒนา",
              subdistrictNameEn: "Nikhom Phatthana",
              postalCode: 39140,
            },
            {
              subdistrictNameTh: "ปางกู่",
              subdistrictNameEn: "Pang Ku",
              postalCode: 39140,
            },
          ],
        },
        {
          districtNameTh: "ศรีบุญเรือง",
          districtNameEn: "Si Bun Rueang",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองใหม่",
              subdistrictNameEn: "Mueang Mai",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "ศรีบุญเรือง",
              subdistrictNameEn: "Si Bunrueang",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "หนองบัวใต้",
              subdistrictNameEn: "Nong Bua Tai",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "กุดสะเทียน",
              subdistrictNameEn: "Kut Sathian",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "นากอก",
              subdistrictNameEn: "Na Kok",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "ยางหล่อ",
              subdistrictNameEn: "Yang Lo",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "โนนม่วง",
              subdistrictNameEn: "Non Muang",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "หนองกุงแก้ว",
              subdistrictNameEn: "Nong Kung Kaeo",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "หนองแก",
              subdistrictNameEn: "Nong Kae",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "ทรายทอง",
              subdistrictNameEn: "Sai Thong",
              postalCode: 39180,
            },
            {
              subdistrictNameTh: "หันนางาม",
              subdistrictNameEn: "Han Na Ngam",
              postalCode: 39180,
            },
          ],
        },
        {
          districtNameTh: "สุวรรณคูหา",
          districtNameEn: "Suwannakhuha",
          subdistricts: [
            {
              subdistrictNameTh: "นาสี",
              subdistrictNameEn: "Na Si",
              postalCode: 39270,
            },
            {
              subdistrictNameTh: "บ้านโคก",
              subdistrictNameEn: "Ban Khok",
              postalCode: 39270,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 39270,
            },
            {
              subdistrictNameTh: "นาด่าน",
              subdistrictNameEn: "Na Dan",
              postalCode: 39270,
            },
            {
              subdistrictNameTh: "ดงมะไฟ",
              subdistrictNameEn: "Dong Mafai",
              postalCode: 39270,
            },
            {
              subdistrictNameTh: "สุวรรณคูหา",
              subdistrictNameEn: "Suwanna Khuha",
              postalCode: 39270,
            },
            {
              subdistrictNameTh: "บุญทัน",
              subdistrictNameEn: "Bunthan",
              postalCode: 39270,
            },
            {
              subdistrictNameTh: "กุดผึ้ง",
              subdistrictNameEn: "Kut Phueng",
              postalCode: 39270,
            },
          ],
        },
        {
          districtNameTh: "นาวัง",
          districtNameEn: "Na Wang",
          subdistricts: [
            {
              subdistrictNameTh: "นาเหล่า",
              subdistrictNameEn: "Na Lao",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "นาแก",
              subdistrictNameEn: "Na Kae",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "วังปลาป้อม",
              subdistrictNameEn: "Wang Pla Pom",
              postalCode: 39170,
            },
            {
              subdistrictNameTh: "เทพคีรี",
              subdistrictNameEn: "Thep Khiri",
              postalCode: 39170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ขอนแก่น",
      stateNameEn: "Khon Kaen",
      districts: [
        {
          districtNameTh: "เมืองขอนแก่น",
          districtNameEn: "Mueang Khon Kaen",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "สำราญ",
              subdistrictNameEn: "Samran",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "โคกสี",
              subdistrictNameEn: "Khok Si",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "ท่าพระ",
              subdistrictNameEn: "Tha Phra",
              postalCode: 40260,
            },
            {
              subdistrictNameTh: "บ้านทุ่ม",
              subdistrictNameEn: "Ban Thum",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "เมืองเก่า",
              subdistrictNameEn: "Mueang Kao",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "พระลับ",
              subdistrictNameEn: "Phra Lap",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "สาวะถี",
              subdistrictNameEn: "Sawathi",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "บ้านหว้า",
              subdistrictNameEn: "Ban Wa",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "บ้านค้อ",
              subdistrictNameEn: "Ban Kho",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "แดงใหญ่",
              subdistrictNameEn: "Daeng Yai",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "ดอนช้าง",
              subdistrictNameEn: "Don Chang",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "ดอนหัน",
              subdistrictNameEn: "Don Han",
              postalCode: 40260,
            },
            {
              subdistrictNameTh: "ศิลา",
              subdistrictNameEn: "Sila",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "บ้านเป็ด",
              subdistrictNameEn: "Ban Pet",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "หนองตูม",
              subdistrictNameEn: "Nong Tum",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "บึงเนียม",
              subdistrictNameEn: "Bueng Niam",
              postalCode: 40000,
            },
            {
              subdistrictNameTh: "โนนท่อน",
              subdistrictNameEn: "Non Thon",
              postalCode: 40000,
            },
          ],
        },
        {
          districtNameTh: "บ้านฝาง",
          districtNameEn: "Ban Fang",
          subdistricts: [
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 40270,
            },
            {
              subdistrictNameTh: "ป่าหวายนั่ง",
              subdistrictNameEn: "Pa Wai Nang",
              postalCode: 40270,
            },
            {
              subdistrictNameTh: "โนนฆ้อง",
              subdistrictNameEn: "Non Khong",
              postalCode: 40270,
            },
            {
              subdistrictNameTh: "บ้านเหล่า",
              subdistrictNameEn: "Ban Lao",
              postalCode: 40270,
            },
            {
              subdistrictNameTh: "ป่ามะนาว",
              subdistrictNameEn: "Pa Manao",
              postalCode: 40270,
            },
            {
              subdistrictNameTh: "บ้านฝาง",
              subdistrictNameEn: "Ban Fang",
              postalCode: 40270,
            },
            {
              subdistrictNameTh: "โคกงาม",
              subdistrictNameEn: "Khok Ngam",
              postalCode: 40270,
            },
          ],
        },
        {
          districtNameTh: "พระยืน",
          districtNameEn: "Phra Yuen",
          subdistricts: [
            {
              subdistrictNameTh: "พระยืน",
              subdistrictNameEn: "Phra Yuen",
              postalCode: 40320,
            },
            {
              subdistrictNameTh: "พระบุ",
              subdistrictNameEn: "Phra Bu",
              postalCode: 40320,
            },
            {
              subdistrictNameTh: "บ้านโต้น",
              subdistrictNameEn: "Ban Ton",
              postalCode: 40320,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 40320,
            },
            {
              subdistrictNameTh: "ขามป้อม",
              subdistrictNameEn: "Kham Pom",
              postalCode: 40320,
            },
          ],
        },
        {
          districtNameTh: "หนองเรือ",
          districtNameEn: "Nong Ruea",
          subdistricts: [
            {
              subdistrictNameTh: "หนองเรือ",
              subdistrictNameEn: "Nong Ruea",
              postalCode: 40210,
            },
            {
              subdistrictNameTh: "บ้านเม็ง",
              subdistrictNameEn: "Ban Meng",
              postalCode: 40210,
            },
            {
              subdistrictNameTh: "บ้านกง",
              subdistrictNameEn: "Ban Kong",
              postalCode: 40240,
            },
            {
              subdistrictNameTh: "ยางคำ",
              subdistrictNameEn: "Yang Kham",
              postalCode: 40240,
            },
            {
              subdistrictNameTh: "จระเข้",
              subdistrictNameEn: "Chorakhe",
              postalCode: 40240,
            },
            {
              subdistrictNameTh: "โนนทอง",
              subdistrictNameEn: "Non Thong",
              postalCode: 40210,
            },
            {
              subdistrictNameTh: "กุดกว้าง",
              subdistrictNameEn: "Kut Kwang",
              postalCode: 40210,
            },
            {
              subdistrictNameTh: "โนนทัน",
              subdistrictNameEn: "Non Than",
              postalCode: 40210,
            },
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 40210,
            },
            {
              subdistrictNameTh: "บ้านผือ",
              subdistrictNameEn: "Ban Phue",
              postalCode: 40240,
            },
          ],
        },
        {
          districtNameTh: "ชุมแพ",
          districtNameEn: "Chum Phae",
          subdistricts: [
            {
              subdistrictNameTh: "ชุมแพ",
              subdistrictNameEn: "Chum Phae",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "โนนหัน",
              subdistrictNameEn: "Non Han",
              postalCode: 40290,
            },
            {
              subdistrictNameTh: "นาหนองทุ่ม",
              subdistrictNameEn: "Na Nong Thum",
              postalCode: 40290,
            },
            {
              subdistrictNameTh: "โนนอุดม",
              subdistrictNameEn: "Non Udom",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "ขัวเรียง",
              subdistrictNameEn: "Khua Riang",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "ไชยสอ",
              subdistrictNameEn: "Chai So",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "วังหินลาด",
              subdistrictNameEn: "Wang Hin Lat",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "นาเพียง",
              subdistrictNameEn: "Na Phiang",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "หนองเขียด",
              subdistrictNameEn: "Nong Khiat",
              postalCode: 40290,
            },
            {
              subdistrictNameTh: "หนองเสาเล้า",
              subdistrictNameEn: "Nong Sao Lao",
              postalCode: 40130,
            },
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 40290,
            },
          ],
        },
        {
          districtNameTh: "สีชมพู",
          districtNameEn: "Si Chomphu",
          subdistricts: [
            {
              subdistrictNameTh: "สีชมพู",
              subdistrictNameEn: "Si Chomphu",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "ศรีสุข",
              subdistrictNameEn: "Si Suk",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "นาจาน",
              subdistrictNameEn: "Na Chan",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "วังเพิ่ม",
              subdistrictNameEn: "Wang Phoem",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "ซำยาง",
              subdistrictNameEn: "Sam Yang",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "หนองแดง",
              subdistrictNameEn: "Nong Daeng",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "ดงลาน",
              subdistrictNameEn: "Dong Lan",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "บริบูรณ์",
              subdistrictNameEn: "Boribun",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 40220,
            },
            {
              subdistrictNameTh: "ภูห่าน",
              subdistrictNameEn: "Phu Han",
              postalCode: 40220,
            },
          ],
        },
        {
          districtNameTh: "น้ำพอง",
          districtNameEn: "Nam Phong",
          subdistricts: [
            {
              subdistrictNameTh: "น้ำพอง",
              subdistrictNameEn: "Nam Phong",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "วังชัย",
              subdistrictNameEn: "Wang Chai",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "หนองกุง",
              subdistrictNameEn: "Nong Kung",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "บัวใหญ่",
              subdistrictNameEn: "Bua Yai",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "สะอาด",
              subdistrictNameEn: "Sa-At",
              postalCode: 40310,
            },
            {
              subdistrictNameTh: "ม่วงหวาน",
              subdistrictNameEn: "Muang Wan",
              postalCode: 40310,
            },
            {
              subdistrictNameTh: "บ้านขาม",
              subdistrictNameEn: "Ban Kham",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "บัวเงิน",
              subdistrictNameEn: "Bua Ngoen",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "ทรายมูล",
              subdistrictNameEn: "Sai Mun",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "ท่ากระเสริม",
              subdistrictNameEn: "Tha Krasoem",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "พังทุย",
              subdistrictNameEn: "Phang Thui",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "กุดน้ำใส",
              subdistrictNameEn: "Kut Nam Sai",
              postalCode: 40140,
            },
          ],
        },
        {
          districtNameTh: "อุบลรัตน์",
          districtNameEn: "Ubolratana",
          subdistricts: [
            {
              subdistrictNameTh: "โคกสูง",
              subdistrictNameEn: "Khok Sung",
              postalCode: 40250,
            },
            {
              subdistrictNameTh: "บ้านดง",
              subdistrictNameEn: "Ban Dong",
              postalCode: 40250,
            },
            {
              subdistrictNameTh: "เขื่อนอุบลรัตน์",
              subdistrictNameEn: "Khuean Ubonrat",
              postalCode: 40250,
            },
            {
              subdistrictNameTh: "นาคำ",
              subdistrictNameEn: "Na Kham",
              postalCode: 40250,
            },
            {
              subdistrictNameTh: "ศรีสุขสำราญ",
              subdistrictNameEn: "Si Suk Samran",
              postalCode: 40250,
            },
            {
              subdistrictNameTh: "ทุ่งโป่ง",
              subdistrictNameEn: "Thung Pong",
              postalCode: 40250,
            },
          ],
        },
        {
          districtNameTh: "กระนวน",
          districtNameEn: "Kranuan",
          subdistricts: [
            {
              subdistrictNameTh: "หนองโก",
              subdistrictNameEn: "Nong Ko",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "หนองกุงใหญ่",
              subdistrictNameEn: "Nong Kung Yai",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "ห้วยโจด",
              subdistrictNameEn: "Huai Chot",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "ห้วยยาง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "บ้านฝาง",
              subdistrictNameEn: "Ban Fang",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "ดูนสาด",
              subdistrictNameEn: "Dun Sat",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "หนองโน",
              subdistrictNameEn: "Nong No",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "น้ำอ้อม",
              subdistrictNameEn: "Nam Om",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "หัวนาคำ",
              subdistrictNameEn: "Hua Na Kham",
              postalCode: 40170,
            },
          ],
        },
        {
          districtNameTh: "บ้านไผ่",
          districtNameEn: "Ban Phai",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านไผ่",
              subdistrictNameEn: "Ban Phai",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "เมืองเพีย",
              subdistrictNameEn: "Mueang Phia",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "บ้านลาน",
              subdistrictNameEn: "Ban Lan",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "แคนเหนือ",
              subdistrictNameEn: "Khaen Nuea",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "ภูเหล็ก",
              subdistrictNameEn: "Phu Lek",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "ป่าปอ",
              subdistrictNameEn: "Pa Po",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "หินตั้ง",
              subdistrictNameEn: "Hin Tang",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "หนองน้ำใส",
              subdistrictNameEn: "Nong Nam Sai",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "หัวหนอง",
              subdistrictNameEn: "Hua Nong",
              postalCode: 40110,
            },
          ],
        },
        {
          districtNameTh: "เปือยน้อย",
          districtNameEn: "Pueai Noi",
          subdistricts: [
            {
              subdistrictNameTh: "เปือยน้อย",
              subdistrictNameEn: "Pueai Noi",
              postalCode: 40340,
            },
            {
              subdistrictNameTh: "วังม่วง",
              subdistrictNameEn: "Wang Muang",
              postalCode: 40340,
            },
            {
              subdistrictNameTh: "ขามป้อม",
              subdistrictNameEn: "Kham Pom",
              postalCode: 40340,
            },
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 40340,
            },
          ],
        },
        {
          districtNameTh: "พล",
          districtNameEn: "Phon",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองพล",
              subdistrictNameEn: "Mueang Phon",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "โจดหนองแก",
              subdistrictNameEn: "Chot Nong Kae",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "เก่างิ้ว",
              subdistrictNameEn: "Kao Ngio",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "หนองมะเขือ",
              subdistrictNameEn: "Nong Makhuea",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "หนองแวงโสกพระ",
              subdistrictNameEn: "Nong Waeng Sok Phra",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "เพ็กใหญ่",
              subdistrictNameEn: "Phek Yai",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "โคกสง่า",
              subdistrictNameEn: "Khok Sa-Nga",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "หนองแวงนางเบ้า",
              subdistrictNameEn: "Nong Waeng Nang Bao",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "ลอมคอม",
              subdistrictNameEn: "Lom Khom",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "โนนข่า",
              subdistrictNameEn: "Non Kha",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "โสกนกเต็น",
              subdistrictNameEn: "Sok Nok Ten",
              postalCode: 40120,
            },
            {
              subdistrictNameTh: "หัวทุ่ง",
              subdistrictNameEn: "Hua Thung",
              postalCode: 40120,
            },
          ],
        },
        {
          districtNameTh: "แวงใหญ่",
          districtNameEn: "Waeng Yai",
          subdistricts: [
            {
              subdistrictNameTh: "คอนฉิม",
              subdistrictNameEn: "Khon Chim",
              postalCode: 40330,
            },
            {
              subdistrictNameTh: "ใหม่นาเพียง",
              subdistrictNameEn: "Mai Na Phiang",
              postalCode: 40330,
            },
            {
              subdistrictNameTh: "โนนทอง",
              subdistrictNameEn: "Non Thong",
              postalCode: 40330,
            },
            {
              subdistrictNameTh: "แวงใหญ่",
              subdistrictNameEn: "Waeng Yai",
              postalCode: 40330,
            },
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 40330,
            },
          ],
        },
        {
          districtNameTh: "แวงน้อย",
          districtNameEn: "Waeng Noi",
          subdistricts: [
            {
              subdistrictNameTh: "แวงน้อย",
              subdistrictNameEn: "Waeng Noi",
              postalCode: 40230,
            },
            {
              subdistrictNameTh: "ก้านเหลือง",
              subdistrictNameEn: "Kan Lueang",
              postalCode: 40230,
            },
            {
              subdistrictNameTh: "ท่านางแนว",
              subdistrictNameEn: "Tha Nang Naeo",
              postalCode: 40230,
            },
            {
              subdistrictNameTh: "ละหานนา",
              subdistrictNameEn: "Lahan Na",
              postalCode: 40230,
            },
            {
              subdistrictNameTh: "ท่าวัด",
              subdistrictNameEn: "Tha Wat",
              postalCode: 40230,
            },
            {
              subdistrictNameTh: "ทางขวาง",
              subdistrictNameEn: "Thang Khwang",
              postalCode: 40230,
            },
          ],
        },
        {
          districtNameTh: "หนองสองห้อง",
          districtNameEn: "Nong Song Hong",
          subdistricts: [
            {
              subdistrictNameTh: "หนองสองห้อง",
              subdistrictNameEn: "Nong Song Hong",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "คึมชาด",
              subdistrictNameEn: "Khuem Chat",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "โนนธาตุ",
              subdistrictNameEn: "Non That",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "ตะกั่วป่า",
              subdistrictNameEn: "Takua Pa",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "หนองเม็ก",
              subdistrictNameEn: "Nong Mek",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "ดอนดู่",
              subdistrictNameEn: "Don Du",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "ดงเค็ง",
              subdistrictNameEn: "Dong Kheng",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "หันโจด",
              subdistrictNameEn: "Han Chot",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "ดอนดั่ง",
              subdistrictNameEn: "Don Dang",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "วังหิน",
              subdistrictNameEn: "Wang Hin",
              postalCode: 40190,
            },
            {
              subdistrictNameTh: "หนองไผ่ล้อม",
              subdistrictNameEn: "Nong Phai Lom",
              postalCode: 40190,
            },
          ],
        },
        {
          districtNameTh: "ภูเวียง",
          districtNameEn: "Phu Wiang",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านเรือ",
              subdistrictNameEn: "Ban Ruea",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "หว้าทอง",
              subdistrictNameEn: "Wa Thong",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "กุดขอนแก่น",
              subdistrictNameEn: "Kut Khon Kaen",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "นาชุมแสง",
              subdistrictNameEn: "Na Chum Saeng",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "นาหว้า",
              subdistrictNameEn: "Na Wa",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "หนองกุงธนสาร",
              subdistrictNameEn: "Nong Kung Thanasan",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "หนองกุงเซิน",
              subdistrictNameEn: "Nong Kung Soen",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "สงเปือย",
              subdistrictNameEn: "Song Pueai",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "ทุ่งชมพู",
              subdistrictNameEn: "Thung Chomphu",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "ดินดำ",
              subdistrictNameEn: "Din Dam",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "ภูเวียง",
              subdistrictNameEn: "Phu Wiang",
              postalCode: 40150,
            },
          ],
        },
        {
          districtNameTh: "มัญจาคีรี",
          districtNameEn: "Mancha Khiri",
          subdistricts: [
            {
              subdistrictNameTh: "กุดเค้า",
              subdistrictNameEn: "Kut Khao",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "สวนหม่อน",
              subdistrictNameEn: "Suan Mon",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "หนองแปน",
              subdistrictNameEn: "Nong Paen",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "โพนเพ็ก",
              subdistrictNameEn: "Phon Phek",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "คำแคน",
              subdistrictNameEn: "Kham Khaen",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "นาข่า",
              subdistrictNameEn: "Nakha",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "นางาม",
              subdistrictNameEn: "Na Ngam",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "ท่าศาลา",
              subdistrictNameEn: "Tha Sala",
              postalCode: 40160,
            },
          ],
        },
        {
          districtNameTh: "ชนบท",
          districtNameEn: "Chonnabot",
          subdistricts: [
            {
              subdistrictNameTh: "ชนบท",
              subdistrictNameEn: "Chonnabot",
              postalCode: 40180,
            },
            {
              subdistrictNameTh: "กุดเพียขอม",
              subdistrictNameEn: "Kut Phia Khom",
              postalCode: 40180,
            },
            {
              subdistrictNameTh: "วังแสง",
              subdistrictNameEn: "Wang Saeng",
              postalCode: 40180,
            },
            {
              subdistrictNameTh: "ห้วยแก",
              subdistrictNameEn: "Huai Kae",
              postalCode: 40180,
            },
            {
              subdistrictNameTh: "บ้านแท่น",
              subdistrictNameEn: "Ban Thaen",
              postalCode: 40180,
            },
            {
              subdistrictNameTh: "ศรีบุญเรือง",
              subdistrictNameEn: "Si Bunrueang",
              postalCode: 40180,
            },
            {
              subdistrictNameTh: "โนนพะยอม",
              subdistrictNameEn: "Non Phayom",
              postalCode: 40180,
            },
            {
              subdistrictNameTh: "ปอแดง",
              subdistrictNameEn: "Po Daeng",
              postalCode: 40180,
            },
          ],
        },
        {
          districtNameTh: "เขาสวนกวาง",
          districtNameEn: "Khao Suan Kwang",
          subdistricts: [
            {
              subdistrictNameTh: "เขาสวนกวาง",
              subdistrictNameEn: "Khao Suan Kwang",
              postalCode: 40280,
            },
            {
              subdistrictNameTh: "ดงเมืองแอม",
              subdistrictNameEn: "Dong Mueang Am",
              postalCode: 40280,
            },
            {
              subdistrictNameTh: "นางิ้ว",
              subdistrictNameEn: "Na Ngio",
              postalCode: 40280,
            },
            {
              subdistrictNameTh: "โนนสมบูรณ์",
              subdistrictNameEn: "Non Sombun",
              postalCode: 40280,
            },
            {
              subdistrictNameTh: "คำม่วง",
              subdistrictNameEn: "Kham Muang",
              postalCode: 40280,
            },
          ],
        },
        {
          districtNameTh: "ภูผาม่าน",
          districtNameEn: "Phu Phaman",
          subdistricts: [
            {
              subdistrictNameTh: "โนนคอม",
              subdistrictNameEn: "Non Khom",
              postalCode: 40350,
            },
            {
              subdistrictNameTh: "นาฝาย",
              subdistrictNameEn: "Na Fai",
              postalCode: 40350,
            },
            {
              subdistrictNameTh: "ภูผาม่าน",
              subdistrictNameEn: "Phu Pha Man",
              postalCode: 40350,
            },
            {
              subdistrictNameTh: "วังสวาบ",
              subdistrictNameEn: "Wang Sawap",
              postalCode: 40350,
            },
            {
              subdistrictNameTh: "ห้วยม่วง",
              subdistrictNameEn: "Huai Muang",
              postalCode: 40350,
            },
          ],
        },
        {
          districtNameTh: "ซำสูง",
          districtNameEn: "Sam Sung",
          subdistricts: [
            {
              subdistrictNameTh: "กระนวน",
              subdistrictNameEn: "Kranuan",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "คำแมด",
              subdistrictNameEn: "Kham Maet",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "บ้านโนน",
              subdistrictNameEn: "Ban Non",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "คูคำ",
              subdistrictNameEn: "Khu Kham",
              postalCode: 40170,
            },
            {
              subdistrictNameTh: "ห้วยเตย",
              subdistrictNameEn: "Huai Toei",
              postalCode: 40170,
            },
          ],
        },
        {
          districtNameTh: "โคกโพธิ์ไชย",
          districtNameEn: "Khok Pho Chai",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านโคก",
              subdistrictNameEn: "Ban Khok",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "โพธิ์ไชย",
              subdistrictNameEn: "Pho Chai",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "ซับสมบูรณ์",
              subdistrictNameEn: "Sap Sombun",
              postalCode: 40160,
            },
            {
              subdistrictNameTh: "นาแพง",
              subdistrictNameEn: "Na Phaeng",
              postalCode: 40160,
            },
          ],
        },
        {
          districtNameTh: "หนองนาคำ",
          districtNameEn: "Nong Na Kham",
          subdistricts: [
            {
              subdistrictNameTh: "กุดธาตุ",
              subdistrictNameEn: "Kut That",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "บ้านโคก",
              subdistrictNameEn: "Ban Khok",
              postalCode: 40150,
            },
            {
              subdistrictNameTh: "ขนวน",
              subdistrictNameEn: "Khanuan",
              postalCode: 40150,
            },
          ],
        },
        {
          districtNameTh: "บ้านแฮด",
          districtNameEn: "Ban Haet",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านแฮด",
              subdistrictNameEn: "Ban Haet",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "โคกสำราญ",
              subdistrictNameEn: "Khok Samran",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "โนนสมบูรณ์",
              subdistrictNameEn: "Non Sombun",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "หนองแซง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 40110,
            },
          ],
        },
        {
          districtNameTh: "โนนศิลา",
          districtNameEn: "Non Sila",
          subdistricts: [
            {
              subdistrictNameTh: "โนนศิลา",
              subdistrictNameEn: "Non Sila",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "หนองปลาหมอ",
              subdistrictNameEn: "Nong Pla Mo",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "บ้านหัน",
              subdistrictNameEn: "Ban Han",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "เปือยใหญ่",
              subdistrictNameEn: "Pueai Yai",
              postalCode: 40110,
            },
            {
              subdistrictNameTh: "โนนแดง",
              subdistrictNameEn: "Non Daeng",
              postalCode: 40110,
            },
          ],
        },
        {
          districtNameTh: "เวียงเก่า",
          districtNameEn: "Wiang Kao",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "เมืองเก่าพัฒนา",
              subdistrictNameEn: "Mueang Kao Phatthana",
              postalCode: 40140,
            },
            {
              subdistrictNameTh: "เขาน้อย",
              subdistrictNameEn: "Khao Noi",
              postalCode: 40140,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "อุดรธานี",
      stateNameEn: "Udon Thani",
      districts: [
        {
          districtNameTh: "เมืองอุดรธานี",
          districtNameEn: "Mueang Udon Thani",
          subdistricts: [
            {
              subdistrictNameTh: "หมากแข้ง",
              subdistrictNameEn: "Mak Khaeng",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "นิคมสงเคราะห์",
              subdistrictNameEn: "Nikhom Songkhro",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "บ้านขาว",
              subdistrictNameEn: "Ban Khao",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "บ้านตาด",
              subdistrictNameEn: "Ban Tat",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "โนนสูง",
              subdistrictNameEn: "Non Sung",
              postalCode: 41330,
            },
            {
              subdistrictNameTh: "หมูม่น",
              subdistrictNameEn: "Mu Mon",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "เชียงยืน",
              subdistrictNameEn: "Chiang Yuen",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "หนองนาคำ",
              subdistrictNameEn: "Nong Na Kham",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "กุดสระ",
              subdistrictNameEn: "Kut Sa",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "บ้านเลื่อม",
              subdistrictNameEn: "Ban Lueam",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "เชียงพิณ",
              subdistrictNameEn: "Chiang Phin",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "สามพร้าว",
              subdistrictNameEn: "Sam Phrao",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "หนองไฮ",
              subdistrictNameEn: "Nong Hai",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "นาข่า",
              subdistrictNameEn: "Na Kha",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "บ้านจั่น",
              subdistrictNameEn: "Ban Chan",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "หนองขอนกว้าง",
              subdistrictNameEn: "Nong Khon Kwang",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "นากว้าง",
              subdistrictNameEn: "Na Kwang",
              postalCode: 41000,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 41330,
            },
          ],
        },
        {
          districtNameTh: "กุดจับ",
          districtNameEn: "Kut Chap",
          subdistricts: [
            {
              subdistrictNameTh: "กุดจับ",
              subdistrictNameEn: "Kut Chap",
              postalCode: 41250,
            },
            {
              subdistrictNameTh: "ปะโค",
              subdistrictNameEn: "Pakho",
              postalCode: 41250,
            },
            {
              subdistrictNameTh: "ขอนยูง",
              subdistrictNameEn: "Khon Yung",
              postalCode: 41250,
            },
            {
              subdistrictNameTh: "เชียงเพ็ง",
              subdistrictNameEn: "Chiang Pheng",
              postalCode: 41250,
            },
            {
              subdistrictNameTh: "สร้างก่อ",
              subdistrictNameEn: "Sang Ko",
              postalCode: 41250,
            },
            {
              subdistrictNameTh: "เมืองเพีย",
              subdistrictNameEn: "Mueang Phia",
              postalCode: 41250,
            },
            {
              subdistrictNameTh: "ตาลเลียน",
              subdistrictNameEn: "Tan Lian",
              postalCode: 41250,
            },
          ],
        },
        {
          districtNameTh: "หนองวัวซอ",
          districtNameEn: "Nong Wua So",
          subdistricts: [
            {
              subdistrictNameTh: "หมากหญ้า",
              subdistrictNameEn: "Mak Ya",
              postalCode: 41360,
            },
            {
              subdistrictNameTh: "หนองอ้อ",
              subdistrictNameEn: "Nong O",
              postalCode: 41220,
            },
            {
              subdistrictNameTh: "อูบมุง",
              subdistrictNameEn: "Up Mung",
              postalCode: 41220,
            },
            {
              subdistrictNameTh: "กุดหมากไฟ",
              subdistrictNameEn: "Kut Mak Fai",
              postalCode: 41220,
            },
            {
              subdistrictNameTh: "น้ำพ่น",
              subdistrictNameEn: "Nam Phon",
              postalCode: 41360,
            },
            {
              subdistrictNameTh: "หนองบัวบาน",
              subdistrictNameEn: "Nong Bua Ban",
              postalCode: 41360,
            },
            {
              subdistrictNameTh: "โนนหวาย",
              subdistrictNameEn: "Non Wai",
              postalCode: 41220,
            },
            {
              subdistrictNameTh: "หนองวัวซอ",
              subdistrictNameEn: "Nong Wua So",
              postalCode: 41360,
            },
          ],
        },
        {
          districtNameTh: "กุมภวาปี",
          districtNameEn: "Kumphawapi",
          subdistricts: [
            {
              subdistrictNameTh: "ตูมใต้",
              subdistrictNameEn: "Tum Tai",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "พันดอน",
              subdistrictNameEn: "Phan Don",
              postalCode: 41370,
            },
            {
              subdistrictNameTh: "เวียงคำ",
              subdistrictNameEn: "Wiang Kham",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "แชแล",
              subdistrictNameEn: "Chae Lae",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "เชียงแหว",
              subdistrictNameEn: "Chiang Wae",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "ห้วยเกิ้ง",
              subdistrictNameEn: "Huai Koeng",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "เสอเพลอ",
              subdistrictNameEn: "Soe Phloe",
              postalCode: 41370,
            },
            {
              subdistrictNameTh: "สีออ",
              subdistrictNameEn: "Si-O",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "ปะโค",
              subdistrictNameEn: "Pakho",
              postalCode: 41370,
            },
            {
              subdistrictNameTh: "ผาสุก",
              subdistrictNameEn: "Pha Suk",
              postalCode: 41370,
            },
            {
              subdistrictNameTh: "ท่าลี่",
              subdistrictNameEn: "Tha Li",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "กุมภวาปี",
              subdistrictNameEn: "Kumphawapi",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "หนองหว้า",
              subdistrictNameEn: "Nong Wa",
              postalCode: 41110,
            },
          ],
        },
        {
          districtNameTh: "โนนสะอาด",
          districtNameEn: "Non Sa-At",
          subdistricts: [
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 41240,
            },
            {
              subdistrictNameTh: "บุ่งแก้ว",
              subdistrictNameEn: "Bung Kaeo",
              postalCode: 41240,
            },
            {
              subdistrictNameTh: "โพธิ์ศรีสำราญ",
              subdistrictNameEn: "Pho Si Samran",
              postalCode: 41240,
            },
            {
              subdistrictNameTh: "ทมนางาม",
              subdistrictNameEn: "Thom Na Ngam",
              postalCode: 41240,
            },
            {
              subdistrictNameTh: "หนองกุงศรี",
              subdistrictNameEn: "Nong Kung Si",
              postalCode: 41240,
            },
            {
              subdistrictNameTh: "โคกกลาง",
              subdistrictNameEn: "Khok Klang",
              postalCode: 41240,
            },
          ],
        },
        {
          districtNameTh: "หนองหาน",
          districtNameEn: "Nong Saeng",
          subdistricts: [
            {
              subdistrictNameTh: "หนองหาน",
              subdistrictNameEn: "Nong Han",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "หนองเม็ก",
              subdistrictNameEn: "Nong Mek",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "พังงู",
              subdistrictNameEn: "Phang Ngu",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "สะแบง",
              subdistrictNameEn: "Sabaeng",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "สร้อยพร้าว",
              subdistrictNameEn: "Soi Phrao",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "บ้านเชียง",
              subdistrictNameEn: "Ban Chiang",
              postalCode: 41320,
            },
            {
              subdistrictNameTh: "บ้านยา",
              subdistrictNameEn: "Ban Ya",
              postalCode: 41320,
            },
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "ผักตบ",
              subdistrictNameEn: "Phak Top",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "ดอนหายโศก",
              subdistrictNameEn: "Don Hai Sok",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "หนองสระปลา",
              subdistrictNameEn: "Nong Sa Pla",
              postalCode: 41320,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งฝน",
          districtNameEn: "Thung Fon",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งฝน",
              subdistrictNameEn: "Thung Fon",
              postalCode: 41310,
            },
            {
              subdistrictNameTh: "ทุ่งใหญ่",
              subdistrictNameEn: "Thung Yai",
              postalCode: 41310,
            },
            {
              subdistrictNameTh: "นาชุมแสง",
              subdistrictNameEn: "Na Chum Saeng",
              postalCode: 41310,
            },
            {
              subdistrictNameTh: "นาทม",
              subdistrictNameEn: "Na Thom",
              postalCode: 41310,
            },
          ],
        },
        {
          districtNameTh: "ไชยวาน",
          districtNameEn: "Chai Wan",
          subdistricts: [
            {
              subdistrictNameTh: "ไชยวาน",
              subdistrictNameEn: "Chaiwan",
              postalCode: 41290,
            },
            {
              subdistrictNameTh: "หนองหลัก",
              subdistrictNameEn: "Nong Lak",
              postalCode: 41290,
            },
            {
              subdistrictNameTh: "คำเลาะ",
              subdistrictNameEn: "Kham Lo",
              postalCode: 41290,
            },
            {
              subdistrictNameTh: "โพนสูง",
              subdistrictNameEn: "Phon Sung",
              postalCode: 41290,
            },
          ],
        },
        {
          districtNameTh: "ศรีธาตุ",
          districtNameEn: "Si That",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีธาตุ",
              subdistrictNameEn: "Si That",
              postalCode: 41230,
            },
            {
              subdistrictNameTh: "จำปี",
              subdistrictNameEn: "Champi",
              postalCode: 41230,
            },
            {
              subdistrictNameTh: "บ้านโปร่ง",
              subdistrictNameEn: "Ban Prong",
              postalCode: 41230,
            },
            {
              subdistrictNameTh: "หัวนาคำ",
              subdistrictNameEn: "Hua Na Kham",
              postalCode: 41230,
            },
            {
              subdistrictNameTh: "หนองนกเขียน",
              subdistrictNameEn: "Nong Nok Khian",
              postalCode: 41230,
            },
            {
              subdistrictNameTh: "นายูง",
              subdistrictNameEn: "Na Yung",
              postalCode: 41230,
            },
            {
              subdistrictNameTh: "ตาดทอง",
              subdistrictNameEn: "Tat Thong",
              postalCode: 41230,
            },
          ],
        },
        {
          districtNameTh: "วังสามหมอ",
          districtNameEn: "Wang Sam Mo",
          subdistricts: [
            {
              subdistrictNameTh: "หนองกุงทับม้า",
              subdistrictNameEn: "Nong Kung Thap Ma",
              postalCode: 41280,
            },
            {
              subdistrictNameTh: "หนองหญ้าไซ",
              subdistrictNameEn: "Nong Ya Sai",
              postalCode: 41280,
            },
            {
              subdistrictNameTh: "บะยาว",
              subdistrictNameEn: "Bayao",
              postalCode: 41280,
            },
            {
              subdistrictNameTh: "ผาสุก",
              subdistrictNameEn: "Pha Suk",
              postalCode: 41280,
            },
            {
              subdistrictNameTh: "คำโคกสูง",
              subdistrictNameEn: "Kham Khok Sung",
              postalCode: 41280,
            },
            {
              subdistrictNameTh: "วังสามหมอ",
              subdistrictNameEn: "Wang Sam Mo",
              postalCode: 41280,
            },
          ],
        },
        {
          districtNameTh: "บ้านดุง",
          districtNameEn: "Ban Dung",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีสุทโธ",
              subdistrictNameEn: "Si Suttho",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "บ้านดุง",
              subdistrictNameEn: "Ban Dung",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "ดงเย็น",
              subdistrictNameEn: "Dong Yen",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "โพนสูง",
              subdistrictNameEn: "Phon Sung",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "อ้อมกอ",
              subdistrictNameEn: "Om Ko",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "บ้านจันทน์",
              subdistrictNameEn: "Ban Chan",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "บ้านชัย",
              subdistrictNameEn: "Ban Chai",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "นาไหม",
              subdistrictNameEn: "Na Mai",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "ถ่อนนาลับ",
              subdistrictNameEn: "Thon Na Lap",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "บ้านม่วง",
              subdistrictNameEn: "Ban Muang",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "บ้านตาด",
              subdistrictNameEn: "Ban Tat",
              postalCode: 41190,
            },
            {
              subdistrictNameTh: "นาคำ",
              subdistrictNameEn: "Na Kham",
              postalCode: 41190,
            },
          ],
        },
        {
          districtNameTh: "บ้านผือ",
          districtNameEn: "Ban Phue",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านผือ",
              subdistrictNameEn: "Ban Phue",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "หายโศก",
              subdistrictNameEn: "Hai Sok",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "เขือน้ำ",
              subdistrictNameEn: "Khuea Nam",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "คำบง",
              subdistrictNameEn: "Kham Bong",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "โนนทอง",
              subdistrictNameEn: "Non Thong",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "ข้าวสาร",
              subdistrictNameEn: "Khao San",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "จำปาโมง",
              subdistrictNameEn: "Champa Mok",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "กลางใหญ่",
              subdistrictNameEn: "Klang Yai",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "เมืองพาน",
              subdistrictNameEn: "Mueang Phan",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "คำด้วง",
              subdistrictNameEn: "Kham Duang",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "หนองหัวคู",
              subdistrictNameEn: "Nong Hua Khu",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "บ้านค้อ",
              subdistrictNameEn: "Ban Kho",
              postalCode: 41160,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 41160,
            },
          ],
        },
        {
          districtNameTh: "น้ำโสม",
          districtNameEn: "Nam Som",
          subdistricts: [
            {
              subdistrictNameTh: "นางัว",
              subdistrictNameEn: "Na Ngua",
              postalCode: 41210,
            },
            {
              subdistrictNameTh: "น้ำโสม",
              subdistrictNameEn: "Nam Som",
              postalCode: 41210,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 41210,
            },
            {
              subdistrictNameTh: "บ้านหยวก",
              subdistrictNameEn: "Ban Yuak",
              postalCode: 41210,
            },
            {
              subdistrictNameTh: "โสมเยี่ยม",
              subdistrictNameEn: "Som Yiam",
              postalCode: 41210,
            },
            {
              subdistrictNameTh: "ศรีสำราญ",
              subdistrictNameEn: "Si Samran",
              postalCode: 41210,
            },
            {
              subdistrictNameTh: "สามัคคี",
              subdistrictNameEn: "Samakkhi",
              postalCode: 41210,
            },
          ],
        },
        {
          districtNameTh: "เพ็ญ",
          districtNameEn: "Phen",
          subdistricts: [
            {
              subdistrictNameTh: "เพ็ญ",
              subdistrictNameEn: "Phen",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "บ้านธาตุ",
              subdistrictNameEn: "Ban That",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "นาพู่",
              subdistrictNameEn: "Na Phu",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "เชียงหวาง",
              subdistrictNameEn: "Chiang Wang",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "สุมเส้า",
              subdistrictNameEn: "Sum Sao",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "นาบัว",
              subdistrictNameEn: "Na Bua",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "บ้านเหล่า",
              subdistrictNameEn: "Ban Lao",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "จอมศรี",
              subdistrictNameEn: "Chom Si",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "เตาไห",
              subdistrictNameEn: "Tao Hai",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "โคกกลาง",
              subdistrictNameEn: "Khok Klang",
              postalCode: 41150,
            },
            {
              subdistrictNameTh: "สร้างแป้น",
              subdistrictNameEn: "Sang Paen",
              postalCode: 41150,
            },
          ],
        },
        {
          districtNameTh: "สร้างคอม",
          districtNameEn: "Sang Khom",
          subdistricts: [
            {
              subdistrictNameTh: "สร้างคอม",
              subdistrictNameEn: "Sang Khom",
              postalCode: 41260,
            },
            {
              subdistrictNameTh: "เชียงดา",
              subdistrictNameEn: "Chiang Da",
              postalCode: 41260,
            },
            {
              subdistrictNameTh: "บ้านยวด",
              subdistrictNameEn: "Ban Yuat",
              postalCode: 41260,
            },
            {
              subdistrictNameTh: "บ้านโคก",
              subdistrictNameEn: "Ban Khok",
              postalCode: 41260,
            },
            {
              subdistrictNameTh: "นาสะอาด",
              subdistrictNameEn: "Na Sa-At",
              postalCode: 41260,
            },
            {
              subdistrictNameTh: "บ้านหินโงม",
              subdistrictNameEn: "Ban Hin Ngom",
              postalCode: 41260,
            },
          ],
        },
        {
          districtNameTh: "หนองแสง",
          districtNameEn: "Nong Saeng",
          subdistricts: [
            {
              subdistrictNameTh: "หนองแสง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 41340,
            },
            {
              subdistrictNameTh: "แสงสว่าง",
              subdistrictNameEn: "Saeng Sawang",
              postalCode: 41340,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 41340,
            },
            {
              subdistrictNameTh: "ทับกุง",
              subdistrictNameEn: "Thap Kung",
              postalCode: 41340,
            },
          ],
        },
        {
          districtNameTh: "นายูง",
          districtNameEn: "Na Yung",
          subdistricts: [
            {
              subdistrictNameTh: "นายูง",
              subdistrictNameEn: "Na Yung",
              postalCode: 41380,
            },
            {
              subdistrictNameTh: "บ้านก้อง",
              subdistrictNameEn: "Ban Kong",
              postalCode: 41380,
            },
            {
              subdistrictNameTh: "นาแค",
              subdistrictNameEn: "Na Khae",
              postalCode: 41380,
            },
            {
              subdistrictNameTh: "โนนทอง",
              subdistrictNameEn: "Non Thong",
              postalCode: 41380,
            },
          ],
        },
        {
          districtNameTh: "พิบูลย์รักษ์",
          districtNameEn: "Phibun Rak",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านแดง",
              subdistrictNameEn: "Ban Daeng",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "นาทราย",
              subdistrictNameEn: "Na Sai",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "ดอนกลอย",
              subdistrictNameEn: "Don Kloi",
              postalCode: 41130,
            },
          ],
        },
        {
          districtNameTh: "กู่แก้ว",
          districtNameEn: "Ku Kaeo",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านจีต",
              subdistrictNameEn: "Ban Chiet",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "โนนทองอินทร์",
              subdistrictNameEn: "Non Thong In",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "ค้อใหญ่",
              subdistrictNameEn: "Kho Yai",
              postalCode: 41130,
            },
            {
              subdistrictNameTh: "คอนสาย",
              subdistrictNameEn: "Khon Sai",
              postalCode: 41130,
            },
          ],
        },
        {
          districtNameTh: "ประจักษ์ศิลปาคม",
          districtNameEn: "Prachaksinlapakhom",
          subdistricts: [
            {
              subdistrictNameTh: "นาม่วง",
              subdistrictNameEn: "Na Muang",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "ห้วยสามพาด",
              subdistrictNameEn: "Huai Sam Phat",
              postalCode: 41110,
            },
            {
              subdistrictNameTh: "อุ่มจาน",
              subdistrictNameEn: "Um Chan",
              postalCode: 41110,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "เลย",
      stateNameEn: "Loei",
      districts: [
        {
          districtNameTh: "เมืองเลย",
          districtNameEn: "Mueang Loei",
          subdistricts: [
            {
              subdistrictNameTh: "กุดป่อง",
              subdistrictNameEn: "Kut Pong",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "เมือง",
              subdistrictNameEn: "Mueang",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "นาอ้อ",
              subdistrictNameEn: "Na O",
              postalCode: 42100,
            },
            {
              subdistrictNameTh: "กกดู่",
              subdistrictNameEn: "Kok Du",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "น้ำหมาน",
              subdistrictNameEn: "Nam Man",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "เสี้ยว",
              subdistrictNameEn: "Siao",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "นาอาน",
              subdistrictNameEn: "Na An",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "นาโป่ง",
              subdistrictNameEn: "Na Pong",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "นาดินดำ",
              subdistrictNameEn: "Na Din Dam",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "น้ำสวย",
              subdistrictNameEn: "Nam Suai",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "ชัยพฤกษ์",
              subdistrictNameEn: "Chaiyaphruek",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "นาแขม",
              subdistrictNameEn: "Na Khaem",
              postalCode: 42000,
            },
            {
              subdistrictNameTh: "ศรีสองรัก",
              subdistrictNameEn: "Si Song Rak",
              postalCode: 42100,
            },
            {
              subdistrictNameTh: "กกทอง",
              subdistrictNameEn: "Kok Thong",
              postalCode: 42000,
            },
          ],
        },
        {
          districtNameTh: "นาด้วง",
          districtNameEn: "Na Duang",
          subdistricts: [
            {
              subdistrictNameTh: "นาด้วง",
              subdistrictNameEn: "Na Duang",
              postalCode: 42210,
            },
            {
              subdistrictNameTh: "นาดอกคำ",
              subdistrictNameEn: "Na Dok Kham",
              postalCode: 42210,
            },
            {
              subdistrictNameTh: "ท่าสะอาด",
              subdistrictNameEn: "Tha Sa-At",
              postalCode: 42210,
            },
            {
              subdistrictNameTh: "ท่าสวรรค์",
              subdistrictNameEn: "Tha Sawan",
              postalCode: 42210,
            },
          ],
        },
        {
          districtNameTh: "เชียงคาน",
          districtNameEn: "Chiang Khan",
          subdistricts: [
            {
              subdistrictNameTh: "เชียงคาน",
              subdistrictNameEn: "Chiang Khan",
              postalCode: 42110,
            },
            {
              subdistrictNameTh: "ธาตุ",
              subdistrictNameEn: "That",
              postalCode: 42110,
            },
            {
              subdistrictNameTh: "นาซ่าว",
              subdistrictNameEn: "Na Sao",
              postalCode: 42110,
            },
            {
              subdistrictNameTh: "เขาแก้ว",
              subdistrictNameEn: "Khao Kaeo",
              postalCode: 42110,
            },
            {
              subdistrictNameTh: "ปากตม",
              subdistrictNameEn: "Pak Tom",
              postalCode: 42110,
            },
            {
              subdistrictNameTh: "บุฮม",
              subdistrictNameEn: "Bu Hom",
              postalCode: 42110,
            },
            {
              subdistrictNameTh: "จอมศรี",
              subdistrictNameEn: "Chom Si",
              postalCode: 42110,
            },
            {
              subdistrictNameTh: "หาดทรายขาว",
              subdistrictNameEn: "Hat Sai Khao",
              postalCode: 42110,
            },
          ],
        },
        {
          districtNameTh: "ปากชม",
          districtNameEn: "Pak Chom",
          subdistricts: [
            {
              subdistrictNameTh: "ปากชม",
              subdistrictNameEn: "Pak Chom",
              postalCode: 42150,
            },
            {
              subdistrictNameTh: "เชียงกลม",
              subdistrictNameEn: "Chiang Klom",
              postalCode: 42150,
            },
            {
              subdistrictNameTh: "หาดคัมภีร์",
              subdistrictNameEn: "Hat Khamphi",
              postalCode: 42150,
            },
            {
              subdistrictNameTh: "ห้วยบ่อซืน",
              subdistrictNameEn: "Huai Bo Suen",
              postalCode: 42150,
            },
            {
              subdistrictNameTh: "ห้วยพิชัย",
              subdistrictNameEn: "Huai Phichai",
              postalCode: 42150,
            },
            {
              subdistrictNameTh: "ชมเจริญ",
              subdistrictNameEn: "Chom Charoen",
              postalCode: 42150,
            },
          ],
        },
        {
          districtNameTh: "ด่านซ้าย",
          districtNameEn: "Dan Sai",
          subdistricts: [
            {
              subdistrictNameTh: "ด่านซ้าย",
              subdistrictNameEn: "Dan Sai",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "ปากหมัน",
              subdistrictNameEn: "Pak Man",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "โคกงาม",
              subdistrictNameEn: "Khok Ngam",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "โพนสูง",
              subdistrictNameEn: "Phon Sung",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "อิปุ่ม",
              subdistrictNameEn: "I Pum",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "กกสะทอน",
              subdistrictNameEn: "Kok Sathon",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "โป่ง",
              subdistrictNameEn: "Pong",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "วังยาว",
              subdistrictNameEn: "Wang Yao",
              postalCode: 42120,
            },
            {
              subdistrictNameTh: "นาหอ",
              subdistrictNameEn: "Na Ho",
              postalCode: 42120,
            },
          ],
        },
        {
          districtNameTh: "นาแห้ว",
          districtNameEn: "Na Haeo",
          subdistricts: [
            {
              subdistrictNameTh: "นาแห้ว",
              subdistrictNameEn: "Na Haeo",
              postalCode: 42170,
            },
            {
              subdistrictNameTh: "แสงภา",
              subdistrictNameEn: "Saeng Pha",
              postalCode: 42170,
            },
            {
              subdistrictNameTh: "นาพึง",
              subdistrictNameEn: "Na Phueng",
              postalCode: 42170,
            },
            {
              subdistrictNameTh: "นามาลา",
              subdistrictNameEn: "Na Mala",
              postalCode: 42170,
            },
            {
              subdistrictNameTh: "เหล่ากอหก",
              subdistrictNameEn: "Lao Ko Hok",
              postalCode: 42170,
            },
          ],
        },
        {
          districtNameTh: "ภูเรือ",
          districtNameEn: "Phu Ruea",
          subdistricts: [
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 42160,
            },
            {
              subdistrictNameTh: "ท่าศาลา",
              subdistrictNameEn: "Tha Sala",
              postalCode: 42160,
            },
            {
              subdistrictNameTh: "ร่องจิก",
              subdistrictNameEn: "Rong Chik",
              postalCode: 42160,
            },
            {
              subdistrictNameTh: "ปลาบ่า",
              subdistrictNameEn: "Pla Ba",
              postalCode: 42160,
            },
            {
              subdistrictNameTh: "ลาดค่าง",
              subdistrictNameEn: "Lat Khang",
              postalCode: 42160,
            },
            {
              subdistrictNameTh: "สานตม",
              subdistrictNameEn: "Santom",
              postalCode: 42160,
            },
          ],
        },
        {
          districtNameTh: "ท่าลี่",
          districtNameEn: "Tha Li",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าลี่",
              subdistrictNameEn: "Tha Li",
              postalCode: 42140,
            },
            {
              subdistrictNameTh: "หนองผือ",
              subdistrictNameEn: "Nong Phue",
              postalCode: 42140,
            },
            {
              subdistrictNameTh: "อาฮี",
              subdistrictNameEn: "A Hi",
              postalCode: 42140,
            },
            {
              subdistrictNameTh: "น้ำแคม",
              subdistrictNameEn: "Nam Khaem",
              postalCode: 42140,
            },
            {
              subdistrictNameTh: "โคกใหญ่",
              subdistrictNameEn: "Khok Yai",
              postalCode: 42140,
            },
            {
              subdistrictNameTh: "น้ำทูน",
              subdistrictNameEn: "Nam Thun",
              postalCode: 42140,
            },
          ],
        },
        {
          districtNameTh: "วังสะพุง",
          districtNameEn: "Wang Saphung",
          subdistricts: [
            {
              subdistrictNameTh: "วังสะพุง",
              subdistrictNameEn: "Wang Saphung",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "ทรายขาว",
              subdistrictNameEn: "Sai Khao",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "หนองหญ้าปล้อง",
              subdistrictNameEn: "Nong Ya Plong",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "หนองงิ้ว",
              subdistrictNameEn: "Nong Ngio",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "ปากปวน",
              subdistrictNameEn: "Pak Puan",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "ผาน้อย",
              subdistrictNameEn: "Pha Noi",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "ผาบิ้ง",
              subdistrictNameEn: "Pha Bing",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "เขาหลวง",
              subdistrictNameEn: "Khao Luang",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "โคกขมิ้น",
              subdistrictNameEn: "Khok Khamin",
              postalCode: 42130,
            },
            {
              subdistrictNameTh: "ศรีสงคราม",
              subdistrictNameEn: "Si Songkhram",
              postalCode: 42130,
            },
          ],
        },
        {
          districtNameTh: "ภูกระดึง",
          districtNameEn: "Phu Kradueng",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีฐาน",
              subdistrictNameEn: "Si Than",
              postalCode: 42180,
            },
            {
              subdistrictNameTh: "ผานกเค้า",
              subdistrictNameEn: "Pha Nok Khao",
              postalCode: 42180,
            },
            {
              subdistrictNameTh: "ภูกระดึง",
              subdistrictNameEn: "Phu Kradueng",
              postalCode: 42180,
            },
            {
              subdistrictNameTh: "ห้วยส้ม",
              subdistrictNameEn: "Huai Som",
              postalCode: 42180,
            },
          ],
        },
        {
          districtNameTh: "ภูหลวง",
          districtNameEn: "Phu Luang",
          subdistricts: [
            {
              subdistrictNameTh: "ภูหอ",
              subdistrictNameEn: "Phu Ho",
              postalCode: 42230,
            },
            {
              subdistrictNameTh: "หนองคัน",
              subdistrictNameEn: "Nong Khan",
              postalCode: 42230,
            },
            {
              subdistrictNameTh: "ห้วยสีเสียด",
              subdistrictNameEn: "Huai Si Siat",
              postalCode: 42230,
            },
            {
              subdistrictNameTh: "เลยวังไสย์",
              subdistrictNameEn: "Loei Wang Sai",
              postalCode: 42230,
            },
            {
              subdistrictNameTh: "แก่งศรีภูมิ",
              subdistrictNameEn: "Kaeng Si Phum",
              postalCode: 42230,
            },
          ],
        },
        {
          districtNameTh: "ผาขาว",
          districtNameEn: "Pha Khao",
          subdistricts: [
            {
              subdistrictNameTh: "ผาขาว",
              subdistrictNameEn: "Pha Khao",
              postalCode: 42240,
            },
            {
              subdistrictNameTh: "ท่าช้างคล้อง",
              subdistrictNameEn: "Tha Chang Khlong",
              postalCode: 42240,
            },
            {
              subdistrictNameTh: "โนนปอแดง",
              subdistrictNameEn: "Non Po Daeng",
              postalCode: 42240,
            },
            {
              subdistrictNameTh: "โนนป่าซาง",
              subdistrictNameEn: "Non Pa Sang",
              postalCode: 42240,
            },
            {
              subdistrictNameTh: "บ้านเพิ่ม",
              subdistrictNameEn: "Ban Phoem",
              postalCode: 42240,
            },
          ],
        },
        {
          districtNameTh: "เอราวัณ",
          districtNameEn: "Erawan",
          subdistricts: [
            {
              subdistrictNameTh: "เอราวัณ",
              subdistrictNameEn: "Erawan",
              postalCode: 42220,
            },
            {
              subdistrictNameTh: "ผาอินทร์แปลง",
              subdistrictNameEn: "Pha In Plaeng",
              postalCode: 42220,
            },
            {
              subdistrictNameTh: "ผาสามยอด",
              subdistrictNameEn: "Pha Sam Yot",
              postalCode: 42220,
            },
            {
              subdistrictNameTh: "ทรัพย์ไพวัลย์",
              subdistrictNameEn: "Sap Phaiwan",
              postalCode: 42220,
            },
          ],
        },
        {
          districtNameTh: "หนองหิน",
          districtNameEn: "Nong Hin",
          subdistricts: [
            {
              subdistrictNameTh: "หนองหิน",
              subdistrictNameEn: "Nong Hin",
              postalCode: 42190,
            },
            {
              subdistrictNameTh: "ตาดข่า",
              subdistrictNameEn: "Tat Kha",
              postalCode: 42190,
            },
            {
              subdistrictNameTh: "ปวนพุ",
              subdistrictNameEn: "Puan Phu",
              postalCode: 42190,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "หนองคาย",
      stateNameEn: "Nong Khai",
      districts: [
        {
          districtNameTh: "เมืองหนองคาย",
          districtNameEn: "Mueang Nong Khai",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "มีชัย",
              subdistrictNameEn: "Mi Chai",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "โพธิ์ชัย",
              subdistrictNameEn: "Pho Chai",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "กวนวัน",
              subdistrictNameEn: "Kuan Wan",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "เวียงคุก",
              subdistrictNameEn: "Wiang Khuk",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "วัดธาตุ",
              subdistrictNameEn: "Wat That",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "หาดคำ",
              subdistrictNameEn: "Hat Kham",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "หินโงม",
              subdistrictNameEn: "Hin Ngom",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "บ้านเดื่อ",
              subdistrictNameEn: "Ban Duea",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "ค่ายบกหวาน",
              subdistrictNameEn: "Khai Bok Wan",
              postalCode: 43100,
            },
            {
              subdistrictNameTh: "โพนสว่าง",
              subdistrictNameEn: "Phon Sawang",
              postalCode: 43100,
            },
            {
              subdistrictNameTh: "พระธาตุบังพวน",
              subdistrictNameEn: "Phrathat Bang Phuan",
              postalCode: 43100,
            },
            {
              subdistrictNameTh: "หนองกอมเกาะ",
              subdistrictNameEn: "Nong Kom Ko",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "ปะโค",
              subdistrictNameEn: "Pakho",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "เมืองหมี",
              subdistrictNameEn: "Mueang Mi",
              postalCode: 43000,
            },
            {
              subdistrictNameTh: "สีกาย",
              subdistrictNameEn: "Si Kai",
              postalCode: 43000,
            },
          ],
        },
        {
          districtNameTh: "ท่าบ่อ",
          districtNameEn: "Tha Bo",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าบ่อ",
              subdistrictNameEn: "Tha Bo",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "น้ำโมง",
              subdistrictNameEn: "Nam Mong",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "กองนาง",
              subdistrictNameEn: "Kong Nang",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "โคกคอน",
              subdistrictNameEn: "Khok Khon",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "บ้านเดื่อ",
              subdistrictNameEn: "Ban Duea",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "บ้านถ่อน",
              subdistrictNameEn: "Ban Thon",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "บ้านว่าน",
              subdistrictNameEn: "Ban Wan",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "นาข่า",
              subdistrictNameEn: "Nakha",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "โพนสา",
              subdistrictNameEn: "Phon Sa",
              postalCode: 43110,
            },
            {
              subdistrictNameTh: "หนองนาง",
              subdistrictNameEn: "Nong Nang",
              postalCode: 43110,
            },
          ],
        },
        {
          districtNameTh: "โพนพิสัย",
          districtNameEn: "Phon Phisai",
          subdistricts: [
            {
              subdistrictNameTh: "จุมพล",
              subdistrictNameEn: "Chum Phon",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "วัดหลวง",
              subdistrictNameEn: "Wat Luang",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "กุดบง",
              subdistrictNameEn: "Kut Bong",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "ชุมช้าง",
              subdistrictNameEn: "Chum Chang",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "ทุ่งหลวง",
              subdistrictNameEn: "Thung Luang",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "เหล่าต่างคำ",
              subdistrictNameEn: "Lao Tang Kham",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "นาหนัง",
              subdistrictNameEn: "Na Nang",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "เซิม",
              subdistrictNameEn: "Some",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "บ้านโพธิ์",
              subdistrictNameEn: "Ban Pho",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "บ้านผือ",
              subdistrictNameEn: "Ban Phue",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "สร้างนางขาว",
              subdistrictNameEn: "Sang Nang Khao",
              postalCode: 43120,
            },
          ],
        },
        {
          districtNameTh: "ศรีเชียงใหม่",
          districtNameEn: "Si Chiang Mai",
          subdistricts: [
            {
              subdistrictNameTh: "พานพร้าว",
              subdistrictNameEn: "Phan Phrao",
              postalCode: 43130,
            },
            {
              subdistrictNameTh: "บ้านหม้อ",
              subdistrictNameEn: "Ban Mo",
              postalCode: 43130,
            },
            {
              subdistrictNameTh: "พระพุทธบาท",
              subdistrictNameEn: "Phraphutthabat",
              postalCode: 43130,
            },
            {
              subdistrictNameTh: "หนองปลาปาก",
              subdistrictNameEn: "Nong Pla Pak",
              postalCode: 43130,
            },
          ],
        },
        {
          districtNameTh: "สังคม",
          districtNameEn: "Sangkhom",
          subdistricts: [
            {
              subdistrictNameTh: "แก้งไก่",
              subdistrictNameEn: "Kaeng Kai",
              postalCode: 43160,
            },
            {
              subdistrictNameTh: "ผาตั้ง",
              subdistrictNameEn: "Pha Tang",
              postalCode: 43160,
            },
            {
              subdistrictNameTh: "บ้านม่วง",
              subdistrictNameEn: "Ban Muang",
              postalCode: 43160,
            },
            {
              subdistrictNameTh: "นางิ้ว",
              subdistrictNameEn: "Na Ngio",
              postalCode: 43160,
            },
            {
              subdistrictNameTh: "สังคม",
              subdistrictNameEn: "Sangkhom",
              postalCode: 43160,
            },
          ],
        },
        {
          districtNameTh: "สระใคร",
          districtNameEn: "Sakhrai",
          subdistricts: [
            {
              subdistrictNameTh: "สระใคร",
              subdistrictNameEn: "Sa Khrai",
              postalCode: 43100,
            },
            {
              subdistrictNameTh: "คอกช้าง",
              subdistrictNameEn: "Khok Chang",
              postalCode: 43100,
            },
            {
              subdistrictNameTh: "บ้านฝาง",
              subdistrictNameEn: "Ban Fang",
              postalCode: 43100,
            },
          ],
        },
        {
          districtNameTh: "เฝ้าไร่",
          districtNameEn: "Fao Rai",
          subdistricts: [
            {
              subdistrictNameTh: "เฝ้าไร่",
              subdistrictNameEn: "Fao Rai",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "วังหลวง",
              subdistrictNameEn: "Wang Luang",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "อุดมพร",
              subdistrictNameEn: "Udom Phon",
              postalCode: 43120,
            },
          ],
        },
        {
          districtNameTh: "รัตนวาปี",
          districtNameEn: "Rattanawapi",
          subdistricts: [
            {
              subdistrictNameTh: "รัตนวาปี",
              subdistrictNameEn: "Rattana Wapi",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "นาทับไฮ",
              subdistrictNameEn: "Na Thap Hai",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "บ้านต้อน",
              subdistrictNameEn: "Ban Ton",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "พระบาทนาสิงห์",
              subdistrictNameEn: "Phrabat Na Sing",
              postalCode: 43120,
            },
            {
              subdistrictNameTh: "โพนแพง",
              subdistrictNameEn: "Phon Phaeng",
              postalCode: 43120,
            },
          ],
        },
        {
          districtNameTh: "โพธิ์ตาก",
          districtNameEn: "Pho Tak",
          subdistricts: [
            {
              subdistrictNameTh: "โพธิ์ตาก",
              subdistrictNameEn: "Pho Tak",
              postalCode: 43130,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 43130,
            },
            {
              subdistrictNameTh: "ด่านศรีสุข",
              subdistrictNameEn: "Dan Si Suk",
              postalCode: 43130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "มหาสารคาม",
      stateNameEn: "Maha Sarakham",
      districts: [
        {
          districtNameTh: "เมืองมหาสารคาม",
          districtNameEn: "Mueang Maha Sarakham",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาด",
              subdistrictNameEn: "Talat",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "เขวา",
              subdistrictNameEn: "Khewa",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "ท่าตูม",
              subdistrictNameEn: "Tha Tum",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "แวงน่าง",
              subdistrictNameEn: "Waeng Nang",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "โคกก่อ",
              subdistrictNameEn: "Khok Ko",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "ดอนหว่าน",
              subdistrictNameEn: "Don Wan",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "เกิ้ง",
              subdistrictNameEn: "Koeng",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "แก่งเลิงจาน",
              subdistrictNameEn: "Kaeng Loeng Chan",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "ท่าสองคอน",
              subdistrictNameEn: "Tha Song Khon",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "ลาดพัฒนา",
              subdistrictNameEn: "Lat Phatthana",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "หนองปลิง",
              subdistrictNameEn: "Nong Pling",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "ห้วยแอ่ง",
              subdistrictNameEn: "Huai Aeng",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "หนองโน",
              subdistrictNameEn: "Nong No",
              postalCode: 44000,
            },
            {
              subdistrictNameTh: "บัวค้อ",
              subdistrictNameEn: "Bua Kho",
              postalCode: 44000,
            },
          ],
        },
        {
          districtNameTh: "แกดำ",
          districtNameEn: "Kae Dam",
          subdistricts: [
            {
              subdistrictNameTh: "แกดำ",
              subdistrictNameEn: "Kae Dam",
              postalCode: 44190,
            },
            {
              subdistrictNameTh: "วังแสง",
              subdistrictNameEn: "Wang Saeng",
              postalCode: 44190,
            },
            {
              subdistrictNameTh: "มิตรภาพ",
              subdistrictNameEn: "Mittraphap",
              postalCode: 44190,
            },
            {
              subdistrictNameTh: "หนองกุง",
              subdistrictNameEn: "Nong Kung",
              postalCode: 44190,
            },
            {
              subdistrictNameTh: "โนนภิบาล",
              subdistrictNameEn: "Non Phiban",
              postalCode: 44190,
            },
          ],
        },
        {
          districtNameTh: "โกสุมพิสัย",
          districtNameEn: "Kosum Phisai",
          subdistricts: [
            {
              subdistrictNameTh: "หัวขวาง",
              subdistrictNameEn: "Hua Khwang",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "ยางน้อย",
              subdistrictNameEn: "Yang Noi",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "วังยาว",
              subdistrictNameEn: "Wang Yao",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "เขวาไร่",
              subdistrictNameEn: "Khewa Rai",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "แพง",
              subdistrictNameEn: "Phaeng",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "แก้งแก",
              subdistrictNameEn: "Kaeng Kae",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "หนองเหล็ก",
              subdistrictNameEn: "Nong Lek",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "เหล่า",
              subdistrictNameEn: "Lao",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "เขื่อน",
              subdistrictNameEn: "Khuean",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "หนองบอน",
              subdistrictNameEn: "Nong Bon",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "ยางท่าแจ้ง",
              subdistrictNameEn: "Yang Tha Chaeng",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "แห่ใต้",
              subdistrictNameEn: "Hae Tai",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "หนองกุงสวรรค์",
              subdistrictNameEn: "Nong Kung Sawan",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "เลิงใต้",
              subdistrictNameEn: "Loeng Tai",
              postalCode: 44140,
            },
            {
              subdistrictNameTh: "ดอนกลาง",
              subdistrictNameEn: "Don Klang",
              postalCode: 44140,
            },
          ],
        },
        {
          districtNameTh: "กันทรวิชัย",
          districtNameEn: "Kantharawichai",
          subdistricts: [
            {
              subdistrictNameTh: "โคกพระ",
              subdistrictNameEn: "Khok Phra",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "คันธารราษฎร์",
              subdistrictNameEn: "Khan Than Rat",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "มะค่า",
              subdistrictNameEn: "Makha",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "ท่าขอนยาง",
              subdistrictNameEn: "Tha Khon Yang",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "นาสีนวน",
              subdistrictNameEn: "Na Si Nuan",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "ขามเรียง",
              subdistrictNameEn: "Kham Riang",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "เขวาใหญ่",
              subdistrictNameEn: "Khewa Yai",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "ศรีสุข",
              subdistrictNameEn: "Si Suk",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "กุดใส้จ่อ",
              subdistrictNameEn: "Kut Sai Cho",
              postalCode: 44150,
            },
            {
              subdistrictNameTh: "ขามเฒ่าพัฒนา",
              subdistrictNameEn: "Kham Thao Phatthana",
              postalCode: 44150,
            },
          ],
        },
        {
          districtNameTh: "เชียงยืน",
          districtNameEn: "Chiang Yuen",
          subdistricts: [
            {
              subdistrictNameTh: "เชียงยืน",
              subdistrictNameEn: "Chiang Yuen",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "หนองซอน",
              subdistrictNameEn: "Nong Son",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "ดอนเงิน",
              subdistrictNameEn: "Don Ngoen",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "กู่ทอง",
              subdistrictNameEn: "Ku Thong",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "นาทอง",
              subdistrictNameEn: "Na Thong",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "เสือเฒ่า",
              subdistrictNameEn: "Suea Thao",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "เหล่าบัวบาน",
              subdistrictNameEn: "Lao Bua Ban",
              postalCode: 44160,
            },
          ],
        },
        {
          districtNameTh: "บรบือ",
          districtNameEn: "Borabue",
          subdistricts: [
            {
              subdistrictNameTh: "บรบือ",
              subdistrictNameEn: "Borabue",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "บ่อใหญ่",
              subdistrictNameEn: "Bo Yai",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "วังไชย",
              subdistrictNameEn: "Wang Chai",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "หนองม่วง",
              subdistrictNameEn: "Nong Muang",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "กำพี้",
              subdistrictNameEn: "Kamphi",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "โนนราษี",
              subdistrictNameEn: "Non Rasi",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "โนนแดง",
              subdistrictNameEn: "Non Daeng",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "หนองจิก",
              subdistrictNameEn: "Nong Chik",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "บัวมาศ",
              subdistrictNameEn: "Bua Mat",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "หนองคูขาด",
              subdistrictNameEn: "Nong Khu Khat",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "วังใหม่",
              subdistrictNameEn: "Wang Mai",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "ยาง",
              subdistrictNameEn: "Yang",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "หนองสิม",
              subdistrictNameEn: "Nong Sim",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "หนองโก",
              subdistrictNameEn: "Nong Ko",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "ดอนงัว",
              subdistrictNameEn: "Don Ngua",
              postalCode: 44130,
            },
          ],
        },
        {
          districtNameTh: "นาเชือก",
          districtNameEn: "Na Chueak",
          subdistricts: [
            {
              subdistrictNameTh: "นาเชือก",
              subdistrictNameEn: "Na Chueak",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "สำโรง",
              subdistrictNameEn: "Samrong",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "หนองแดง",
              subdistrictNameEn: "Nong Daeng",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "เขวาไร่",
              subdistrictNameEn: "Khewa Rai",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "หนองโพธิ์",
              subdistrictNameEn: "Nong Pho",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "ปอพาน",
              subdistrictNameEn: "Po Phan",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "หนองเม็ก",
              subdistrictNameEn: "Nong Mek",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "หนองเรือ",
              subdistrictNameEn: "Nong Ruea",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "หนองกุง",
              subdistrictNameEn: "Nong Kung",
              postalCode: 44170,
            },
            {
              subdistrictNameTh: "สันป่าตอง",
              subdistrictNameEn: "San Patong",
              postalCode: 44170,
            },
          ],
        },
        {
          districtNameTh: "พยัคฆภูมิพิสัย",
          districtNameEn: "Phayakkhaphum Phisai",
          subdistricts: [
            {
              subdistrictNameTh: "ปะหลาน",
              subdistrictNameEn: "Palan",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "ก้ามปู",
              subdistrictNameEn: "Kam Pu",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "เวียงสะอาด",
              subdistrictNameEn: "Wiang Sa-At",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "เม็กดำ",
              subdistrictNameEn: "Mek Dam",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "นาสีนวล",
              subdistrictNameEn: "Na Si Nuan",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "ราษฎร์เจริญ",
              subdistrictNameEn: "Rat Charoen",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "หนองบัวแก้ว",
              subdistrictNameEn: "Nong Bua Kaeo",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "เมืองเตา",
              subdistrictNameEn: "Mueang Tao",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "ลานสะแก",
              subdistrictNameEn: "Lan Sakae",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "เวียงชัย",
              subdistrictNameEn: "Wiang Chai",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "ราษฎร์พัฒนา",
              subdistrictNameEn: "Rat Phatthana",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "เมืองเสือ",
              subdistrictNameEn: "Mueang Suea",
              postalCode: 44110,
            },
            {
              subdistrictNameTh: "ภารแอ่น",
              subdistrictNameEn: "Phara-Aen",
              postalCode: 44110,
            },
          ],
        },
        {
          districtNameTh: "วาปีปทุม",
          districtNameEn: "Wapi Pathum",
          subdistricts: [
            {
              subdistrictNameTh: "หนองแสง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "ขามป้อม",
              subdistrictNameEn: "Kham Pom",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "เสือโก้ก",
              subdistrictNameEn: "Suea Kok",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "ดงใหญ่",
              subdistrictNameEn: "Dong Yai",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "โพธิ์ชัย",
              subdistrictNameEn: "Pho Chai",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "หัวเรือ",
              subdistrictNameEn: "Hua Ruea",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "แคน",
              subdistrictNameEn: "Khaen",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "งัวบา",
              subdistrictNameEn: "Ngua Ba",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "นาข่า",
              subdistrictNameEn: "Nakha",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "บ้านหวาย",
              subdistrictNameEn: "Ban Wai",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "หนองไฮ",
              subdistrictNameEn: "Nong Hai",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "ประชาพัฒนา",
              subdistrictNameEn: "Pracha Phatthana",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "หนองทุ่ม",
              subdistrictNameEn: "Nong Thum",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "หนองแสน",
              subdistrictNameEn: "Nong Saen",
              postalCode: 44120,
            },
            {
              subdistrictNameTh: "โคกสีทองหลาง",
              subdistrictNameEn: "Khok Si Thong Lang",
              postalCode: 44120,
            },
          ],
        },
        {
          districtNameTh: "นาดูน",
          districtNameEn: "Na Dun",
          subdistricts: [
            {
              subdistrictNameTh: "นาดูน",
              subdistrictNameEn: "Na Dun",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "หนองคู",
              subdistrictNameEn: "Nong Khu",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "ดงบัง",
              subdistrictNameEn: "Dong Bang",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "ดงดวน",
              subdistrictNameEn: "Dong Duan",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "หัวดง",
              subdistrictNameEn: "Hua Dong",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "ดงยาง",
              subdistrictNameEn: "Dong Yang",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "กู่สันตรัตน์",
              subdistrictNameEn: "Ku Santarat",
              postalCode: 44180,
            },
            {
              subdistrictNameTh: "พระธาตุ",
              subdistrictNameEn: "Phrathat",
              postalCode: 44180,
            },
          ],
        },
        {
          districtNameTh: "ยางสีสุราช",
          districtNameEn: "Yang Sisurat",
          subdistricts: [
            {
              subdistrictNameTh: "ยางสีสุราช",
              subdistrictNameEn: "Yang Sisurat",
              postalCode: 44210,
            },
            {
              subdistrictNameTh: "นาภู",
              subdistrictNameEn: "Na Phu",
              postalCode: 44210,
            },
            {
              subdistrictNameTh: "แวงดง",
              subdistrictNameEn: "Waeng Dong",
              postalCode: 44210,
            },
            {
              subdistrictNameTh: "บ้านกู่",
              subdistrictNameEn: "Ban Ku",
              postalCode: 44210,
            },
            {
              subdistrictNameTh: "ดงเมือง",
              subdistrictNameEn: "Dong Mueang",
              postalCode: 44210,
            },
            {
              subdistrictNameTh: "สร้างแซ่ง",
              subdistrictNameEn: "Kham Rian",
              postalCode: 44210,
            },
            {
              subdistrictNameTh: "หนองบัวสันตุ",
              subdistrictNameEn: "Nong Bua Santu",
              postalCode: 44210,
            },
          ],
        },
        {
          districtNameTh: "กุดรัง",
          districtNameEn: "Kut Rang",
          subdistricts: [
            {
              subdistrictNameTh: "กุดรัง",
              subdistrictNameEn: "Kut Rang",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "เลิงแฝก",
              subdistrictNameEn: "Loeng Faek",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 44130,
            },
            {
              subdistrictNameTh: "ห้วยเตย",
              subdistrictNameEn: "Huai Toei",
              postalCode: 44130,
            },
          ],
        },
        {
          districtNameTh: "ชื่นชม",
          districtNameEn: "Chuen Chom",
          subdistricts: [
            {
              subdistrictNameTh: "ชื่นชม",
              subdistrictNameEn: "Chuen Chom",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "กุดปลาดุก",
              subdistrictNameEn: "Kut Pla Duk",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "เหล่าดอกไม้",
              subdistrictNameEn: "Lao Dokmai",
              postalCode: 44160,
            },
            {
              subdistrictNameTh: "หนองกุง",
              subdistrictNameEn: "Nong Kung",
              postalCode: 44160,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ร้อยเอ็ด",
      stateNameEn: "Roi Et",
      districts: [
        {
          districtNameTh: "เมืองร้อยเอ็ด",
          districtNameEn: "Mueang Roi Et",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "รอบเมือง",
              subdistrictNameEn: "Rop Mueang",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "เหนือเมือง",
              subdistrictNameEn: "Nuea Mueang",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ขอนแก่น",
              subdistrictNameEn: "Khon Kaen",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "สะอาดสมบูรณ์",
              subdistrictNameEn: "Sa-At Sombun",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "สีแก้ว",
              subdistrictNameEn: "Si Kaeo",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ปอภาร",
              subdistrictNameEn: "Po Phan",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "โนนรัง",
              subdistrictNameEn: "Non Rang",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "หนองแก้ว",
              subdistrictNameEn: "Nong Kaeo",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ดงลาน",
              subdistrictNameEn: "Dong Lan",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "แคนใหญ่",
              subdistrictNameEn: "Khaen Yai",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "โนนตาล",
              subdistrictNameEn: "Non Tan",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "เมืองทอง",
              subdistrictNameEn: "Mueang Thong",
              postalCode: 45000,
            },
          ],
        },
        {
          districtNameTh: "เกษตรวิสัย",
          districtNameEn: "Kaset Wisai",
          subdistricts: [
            {
              subdistrictNameTh: "เกษตรวิสัย",
              subdistrictNameEn: "Kaset Wisai",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "เมืองบัว",
              subdistrictNameEn: "Mueang Bua",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "เหล่าหลวง",
              subdistrictNameEn: "Lao Luang",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "สิงห์โคก",
              subdistrictNameEn: "Sing Khok",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "ดงครั่งใหญ่",
              subdistrictNameEn: "Dong Khrang Yai",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "บ้านฝาง",
              subdistrictNameEn: "Ban Fang",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "กำแพง",
              subdistrictNameEn: "Kamphaeng",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "กู่กาสิงห์",
              subdistrictNameEn: "Ku Ka Sing",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "น้ำอ้อม",
              subdistrictNameEn: "Nam Om",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "โนนสว่าง",
              subdistrictNameEn: "Non Sawang",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "ทุ่งทอง",
              subdistrictNameEn: "Thung Thong",
              postalCode: 45150,
            },
            {
              subdistrictNameTh: "ดงครั่งน้อย",
              subdistrictNameEn: "Dong Khrang Noi",
              postalCode: 45150,
            },
          ],
        },
        {
          districtNameTh: "ปทุมรัตต์",
          districtNameEn: "Pathum Rat",
          subdistricts: [
            {
              subdistrictNameTh: "บัวแดง",
              subdistrictNameEn: "Bua Daeng",
              postalCode: 45190,
            },
            {
              subdistrictNameTh: "ดอกล้ำ",
              subdistrictNameEn: "Dok Lam",
              postalCode: 45190,
            },
            {
              subdistrictNameTh: "หนองแคน",
              subdistrictNameEn: "Nong Khaen",
              postalCode: 45190,
            },
            {
              subdistrictNameTh: "โพนสูง",
              subdistrictNameEn: "Phon Sung",
              postalCode: 45190,
            },
            {
              subdistrictNameTh: "โนนสวรรค์",
              subdistrictNameEn: "Non Sawan",
              postalCode: 45190,
            },
            {
              subdistrictNameTh: "สระบัว",
              subdistrictNameEn: "Sa Bua",
              postalCode: 45190,
            },
            {
              subdistrictNameTh: "โนนสง่า",
              subdistrictNameEn: "Non Sa-Nga",
              postalCode: 45190,
            },
            {
              subdistrictNameTh: "ขี้เหล็ก",
              subdistrictNameEn: "Khi Lek",
              postalCode: 45190,
            },
          ],
        },
        {
          districtNameTh: "จตุรพักตรพิมาน",
          districtNameEn: "Chaturaphak Phiman",
          subdistricts: [
            {
              subdistrictNameTh: "หัวช้าง",
              subdistrictNameEn: "Hua Chang",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "หนองผือ",
              subdistrictNameEn: "Nong Phue",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "เมืองหงส์",
              subdistrictNameEn: "Mueang Hong",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "โคกล่าม",
              subdistrictNameEn: "Khok Lam",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "น้ำใส",
              subdistrictNameEn: "Nam Sai",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "ดงแดง",
              subdistrictNameEn: "Dong Daeng",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "ดงกลาง",
              subdistrictNameEn: "Dong Klang",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "ป่าสังข์",
              subdistrictNameEn: "Pa Sang",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "อีง่อง",
              subdistrictNameEn: "I Ngong",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "ลิ้นฟ้า",
              subdistrictNameEn: "Lin Fa",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "ดู่น้อย",
              subdistrictNameEn: "Du Noi",
              postalCode: 45180,
            },
            {
              subdistrictNameTh: "ศรีโคตร",
              subdistrictNameEn: "Si Khot",
              postalCode: 45180,
            },
          ],
        },
        {
          districtNameTh: "ธวัชบุรี",
          districtNameEn: "Thawat Buri",
          subdistricts: [
            {
              subdistrictNameTh: "นิเวศน์",
              subdistrictNameEn: "Niwet",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "ธงธานี",
              subdistrictNameEn: "Thong Thani",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "ธวัชบุรี",
              subdistrictNameEn: "Thawat Buri",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "อุ่มเม้า",
              subdistrictNameEn: "Um Mao",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "มะอึ",
              subdistrictNameEn: "Ma-Ue",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "เขวาทุ่ง",
              subdistrictNameEn: "Khewa Thung",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "ไพศาล",
              subdistrictNameEn: "Phaisan",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "เมืองน้อย",
              subdistrictNameEn: "Mueang Noi",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "บึงนคร",
              subdistrictNameEn: "Bueng Nakhon",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "ราชธานี",
              subdistrictNameEn: "Ratchathani",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "หนองพอก",
              subdistrictNameEn: "Nong Phok",
              postalCode: 45170,
            },
          ],
        },
        {
          districtNameTh: "พนมไพร",
          districtNameEn: "Phanom Phrai",
          subdistricts: [
            {
              subdistrictNameTh: "พนมไพร",
              subdistrictNameEn: "Phanom Phrai",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "แสนสุข",
              subdistrictNameEn: "Saen Suk",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "กุดน้ำใส",
              subdistrictNameEn: "Kut Nam Sai",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "หนองทัพไทย",
              subdistrictNameEn: "Nong Thap Thai",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "โพธิ์ใหญ่",
              subdistrictNameEn: "Pho Yai",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "วารีสวัสดิ์",
              subdistrictNameEn: "Wari Sawat",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "โคกสว่าง",
              subdistrictNameEn: "Khok Sawang",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "โพธิ์ชัย",
              subdistrictNameEn: "Pho Chai",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "นานวล",
              subdistrictNameEn: "Na Nuan",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "คำไฮ",
              subdistrictNameEn: "Kham Hai",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "ค้อใหญ่",
              subdistrictNameEn: "Kho Yai",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "ชานุวรรณ",
              subdistrictNameEn: "Chanuwan",
              postalCode: 45140,
            },
          ],
        },
        {
          districtNameTh: "โพนทอง",
          districtNameEn: "Phon Thong",
          subdistricts: [
            {
              subdistrictNameTh: "แวง",
              subdistrictNameEn: "Waeng",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "โคกกกม่วง",
              subdistrictNameEn: "Khok Kok Muang",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "นาอุดม",
              subdistrictNameEn: "Na Udom",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "สว่าง",
              subdistrictNameEn: "Sawang",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "หนองใหญ่",
              subdistrictNameEn: "Nong Yai",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "โพธิ์ทอง",
              subdistrictNameEn: "Pho Thong",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "โนนชัยศรี",
              subdistrictNameEn: "Non Chai Si",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "โพธิ์ศรีสว่าง",
              subdistrictNameEn: "Pho Si Sawang",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "อุ่มเม่า",
              subdistrictNameEn: "Um Mao",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "คำนาดี",
              subdistrictNameEn: "Kham Na Di",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "พรมสวรรค์",
              subdistrictNameEn: "Phrom Sawan",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "สระนกแก้ว",
              subdistrictNameEn: "Sa Nok Kaeo",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "วังสามัคคี",
              subdistrictNameEn: "Wang Samakkhi",
              postalCode: 45110,
            },
            {
              subdistrictNameTh: "โคกสูง",
              subdistrictNameEn: "Khok Sung",
              postalCode: 45110,
            },
          ],
        },
        {
          districtNameTh: "โพธิ์ชัย",
          districtNameEn: "Pho Chai",
          subdistricts: [
            {
              subdistrictNameTh: "ขามเปี้ย",
              subdistrictNameEn: "Kham Pia",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "เชียงใหม่",
              subdistrictNameEn: "Chiang Mai",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "บัวคำ",
              subdistrictNameEn: "Bua Kham",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "อัคคะคำ",
              subdistrictNameEn: "Ak Khakham",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "สะอาด",
              subdistrictNameEn: "Sa-At",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "คำพอุง",
              subdistrictNameEn: "Kham Pha-Ung",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "หนองตาไก้",
              subdistrictNameEn: "Nong Ta Kai",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "ดอนโอง",
              subdistrictNameEn: "Don Ong",
              postalCode: 45230,
            },
            {
              subdistrictNameTh: "โพธิ์ศรี",
              subdistrictNameEn: "Pho Si",
              postalCode: 45230,
            },
          ],
        },
        {
          districtNameTh: "หนองพอก",
          districtNameEn: "Nong Phok",
          subdistricts: [
            {
              subdistrictNameTh: "หนองพอก",
              subdistrictNameEn: "Nong Phok",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "บึงงาม",
              subdistrictNameEn: "Bueng Ngam",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "ภูเขาทอง",
              subdistrictNameEn: "Phu Khao Thong",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "กกโพธิ์",
              subdistrictNameEn: "Kok Pho",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "โคกสว่าง",
              subdistrictNameEn: "Khok Sawang",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "หนองขุ่นใหญ่",
              subdistrictNameEn: "Nong Khun Yai",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "รอบเมือง",
              subdistrictNameEn: "Rop Mueang",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "ผาน้ำย้อย",
              subdistrictNameEn: "Pha Nam Yoi",
              postalCode: 45210,
            },
            {
              subdistrictNameTh: "ท่าสีดา",
              subdistrictNameEn: "Tha Si Da",
              postalCode: 45210,
            },
          ],
        },
        {
          districtNameTh: "เสลภูมิ",
          districtNameEn: "Selaphum",
          subdistricts: [
            {
              subdistrictNameTh: "กลาง",
              subdistrictNameEn: "Klang",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "นางาม",
              subdistrictNameEn: "Na Ngam",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "เมืองไพร",
              subdistrictNameEn: "Mueang Phrai",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "นาแซง",
              subdistrictNameEn: "Na Saeng",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "นาเมือง",
              subdistrictNameEn: "Na Mueang",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "วังหลวง",
              subdistrictNameEn: "Wang Luang",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "ท่าม่วง",
              subdistrictNameEn: "Tha Muang",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "ขวาว",
              subdistrictNameEn: "Khwao",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "โพธิ์ทอง",
              subdistrictNameEn: "Pho Thong",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "ภูเงิน",
              subdistrictNameEn: "Phu Ngoen",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "เกาะแก้ว",
              subdistrictNameEn: "Ko Kaeo",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "นาเลิง",
              subdistrictNameEn: "Na Loeng",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "เหล่าน้อย",
              subdistrictNameEn: "Lao Noi",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "ศรีวิลัย",
              subdistrictNameEn: "Si Wilai",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "พรสวรรค์",
              subdistrictNameEn: "Phon Sawan",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "ขวัญเมือง",
              subdistrictNameEn: "Khwan Mueang",
              postalCode: 45120,
            },
            {
              subdistrictNameTh: "บึงเกลือ",
              subdistrictNameEn: "Bueng Kluea",
              postalCode: 45120,
            },
          ],
        },
        {
          districtNameTh: "สุวรรณภูมิ",
          districtNameEn: "Suwannaphum",
          subdistricts: [
            {
              subdistrictNameTh: "สระคู",
              subdistrictNameEn: "Sa Khu",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "ดอกไม้",
              subdistrictNameEn: "Dokmai",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "นาใหญ่",
              subdistrictNameEn: "Na Yai",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "หินกอง",
              subdistrictNameEn: "Hin Kong",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "เมืองทุ่ง",
              subdistrictNameEn: "Mueang Thung",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "หัวโทน",
              subdistrictNameEn: "Hua Thon",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "บ่อพันขัน",
              subdistrictNameEn: "Bo Phan Khan",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "ทุ่งหลวง",
              subdistrictNameEn: "Thung Luang",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "หัวช้าง",
              subdistrictNameEn: "Hua Chang",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "น้ำคำ",
              subdistrictNameEn: "Nam Kham",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "ห้วยหินลาด",
              subdistrictNameEn: "Huai Hin Lat",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "ช้างเผือก",
              subdistrictNameEn: "Chang Phueak",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "ทุ่งกุลา",
              subdistrictNameEn: "Thung Kula",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "ทุ่งศรีเมือง",
              subdistrictNameEn: "Thung Si Mueang",
              postalCode: 45130,
            },
            {
              subdistrictNameTh: "จำปาขัน",
              subdistrictNameEn: "Champa Khan",
              postalCode: 45130,
            },
          ],
        },
        {
          districtNameTh: "เมืองสรวง",
          districtNameEn: "Mueang Suang",
          subdistricts: [
            {
              subdistrictNameTh: "หนองผือ",
              subdistrictNameEn: "Nong Phue",
              postalCode: 45220,
            },
            {
              subdistrictNameTh: "หนองหิน",
              subdistrictNameEn: "Nong Hin",
              postalCode: 45220,
            },
            {
              subdistrictNameTh: "คูเมือง",
              subdistrictNameEn: "Khu Mueang",
              postalCode: 45220,
            },
            {
              subdistrictNameTh: "กกกุง",
              subdistrictNameEn: "Kok Kung",
              postalCode: 45220,
            },
            {
              subdistrictNameTh: "เมืองสรวง",
              subdistrictNameEn: "Mueang Suang",
              postalCode: 45220,
            },
          ],
        },
        {
          districtNameTh: "โพนทราย",
          districtNameEn: "Phon Sai",
          subdistricts: [
            {
              subdistrictNameTh: "โพนทราย",
              subdistrictNameEn: "Phon Sai",
              postalCode: 45240,
            },
            {
              subdistrictNameTh: "สามขา",
              subdistrictNameEn: "Sam Kha",
              postalCode: 45240,
            },
            {
              subdistrictNameTh: "ศรีสว่าง",
              subdistrictNameEn: "Si Sawang",
              postalCode: 45240,
            },
            {
              subdistrictNameTh: "ยางคำ",
              subdistrictNameEn: "Yang Kham",
              postalCode: 45240,
            },
            {
              subdistrictNameTh: "ท่าหาดยาว",
              subdistrictNameEn: "Tha Hat Yao",
              postalCode: 45240,
            },
          ],
        },
        {
          districtNameTh: "อาจสามารถ",
          districtNameEn: "At Samat",
          subdistricts: [
            {
              subdistrictNameTh: "อาจสามารถ",
              subdistrictNameEn: "At Samat",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "โพนเมือง",
              subdistrictNameEn: "Phon Mueang",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "บ้านแจ้ง",
              subdistrictNameEn: "Ban Chaeng",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "หน่อม",
              subdistrictNameEn: "Nom",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "หนองหมื่นถ่าน",
              subdistrictNameEn: "Nong Muen Than",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "หนองขาม",
              subdistrictNameEn: "Nong Kham",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "โหรา",
              subdistrictNameEn: "Hora",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "ขี้เหล็ก",
              subdistrictNameEn: "Khi Lek",
              postalCode: 45160,
            },
            {
              subdistrictNameTh: "บ้านดู่",
              subdistrictNameEn: "Ban Du",
              postalCode: 45160,
            },
          ],
        },
        {
          districtNameTh: "เมยวดี",
          districtNameEn: "Moei Wadi",
          subdistricts: [
            {
              subdistrictNameTh: "เมยวดี",
              subdistrictNameEn: "Moei Wadi",
              postalCode: 45250,
            },
            {
              subdistrictNameTh: "ชุมพร",
              subdistrictNameEn: "Chumphon",
              postalCode: 45250,
            },
            {
              subdistrictNameTh: "บุ่งเลิศ",
              subdistrictNameEn: "Bung Loet",
              postalCode: 45250,
            },
            {
              subdistrictNameTh: "ชมสะอาด",
              subdistrictNameEn: "Chom Sa-At",
              postalCode: 45250,
            },
          ],
        },
        {
          districtNameTh: "ศรีสมเด็จ",
          districtNameEn: "Si Somdet",
          subdistricts: [
            {
              subdistrictNameTh: "โพธิ์ทอง",
              subdistrictNameEn: "Pho Thong",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ศรีสมเด็จ",
              subdistrictNameEn: "Si Somdet",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "เมืองเปลือย",
              subdistrictNameEn: "Mueang Plueai",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "หนองใหญ่",
              subdistrictNameEn: "Nong Yai",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "สวนจิก",
              subdistrictNameEn: "Suan Chik",
              postalCode: 45280,
            },
            {
              subdistrictNameTh: "โพธิ์สัย",
              subdistrictNameEn: "Pho Sai",
              postalCode: 45280,
            },
            {
              subdistrictNameTh: "หนองแวงควง",
              subdistrictNameEn: "Nong Waeng Khuang",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "บ้านบาก",
              subdistrictNameEn: "Ban Bak",
              postalCode: 45000,
            },
          ],
        },
        {
          districtNameTh: "จังหาร",
          districtNameEn: "Changhan",
          subdistricts: [
            {
              subdistrictNameTh: "ดินดำ",
              subdistrictNameEn: "Din Dam",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ปาฝา",
              subdistrictNameEn: "Pa Fa",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ม่วงลาด",
              subdistrictNameEn: "Muang Lat",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "จังหาร",
              subdistrictNameEn: "Changhan",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ดงสิงห์",
              subdistrictNameEn: "Dong Sing",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ยางใหญ่",
              subdistrictNameEn: "Yang Yai",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "ผักแว่น",
              subdistrictNameEn: "Phak Waen",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "แสนชาติ",
              subdistrictNameEn: "Saen Chat",
              postalCode: 45000,
            },
          ],
        },
        {
          districtNameTh: "เชียงขวัญ",
          districtNameEn: "Chiang Khwan",
          subdistricts: [
            {
              subdistrictNameTh: "เชียงขวัญ",
              subdistrictNameEn: "Chiang Khwan",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "พลับพลา",
              subdistrictNameEn: "Phlapphla",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "พระธาตุ",
              subdistrictNameEn: "Phrathat",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "พระเจ้า",
              subdistrictNameEn: "Phrachao",
              postalCode: 45000,
            },
            {
              subdistrictNameTh: "หมูม้น",
              subdistrictNameEn: "Mu Mon",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "บ้านเขือง",
              subdistrictNameEn: "Ban Khueang",
              postalCode: 45000,
            },
          ],
        },
        {
          districtNameTh: "หนองฮี",
          districtNameEn: "Nong Hi",
          subdistricts: [
            {
              subdistrictNameTh: "หนองฮี",
              subdistrictNameEn: "Nong Hi",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "สาวแห",
              subdistrictNameEn: "Sao Hae",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "ดูกอึ่ง",
              subdistrictNameEn: "Duk Ueng",
              postalCode: 45140,
            },
            {
              subdistrictNameTh: "เด่นราษฎร์",
              subdistrictNameEn: "Den Rat",
              postalCode: 45140,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งเขาหลวง",
          districtNameEn: "Thung Khao Luang",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งเขาหลวง",
              subdistrictNameEn: "Thung Khao Luang",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "เทอดไทย",
              subdistrictNameEn: "Thoet Thai",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "บึงงาม",
              subdistrictNameEn: "Bueng Ngam",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "มะบ้า",
              subdistrictNameEn: "Maba",
              postalCode: 45170,
            },
            {
              subdistrictNameTh: "เหล่า",
              subdistrictNameEn: "Lao",
              postalCode: 45170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "กาฬสินธุ์",
      stateNameEn: "Kalasin",
      districts: [
        {
          districtNameTh: "เมืองกาฬสินธุ์",
          districtNameEn: "Mueang Kalasin",
          subdistricts: [
            {
              subdistrictNameTh: "กาฬสินธุ์",
              subdistrictNameEn: "Kalasin",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "เหนือ",
              subdistrictNameEn: "Nuea",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "หลุบ",
              subdistrictNameEn: "Lup",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ไผ่",
              subdistrictNameEn: "Phai",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ลำปาว",
              subdistrictNameEn: "Lampao",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ลำพาน",
              subdistrictNameEn: "Lamphan",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "เชียงเครือ",
              subdistrictNameEn: "Chiang Khruea",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "บึงวิชัย",
              subdistrictNameEn: "Bueng Wichai",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ห้วยโพธิ์",
              subdistrictNameEn: "Huai Pho",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ภูปอ",
              subdistrictNameEn: "Phu Po",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ภูดิน",
              subdistrictNameEn: "Phu Din",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "หนองกุง",
              subdistrictNameEn: "Nong Kung",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "กลางหมื่น",
              subdistrictNameEn: "Klang Muen",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ขมิ้น",
              subdistrictNameEn: "Khamin",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "นาจารย์",
              subdistrictNameEn: "Na Chan",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ลำคลอง",
              subdistrictNameEn: "Lam Khlong",
              postalCode: 46000,
            },
          ],
        },
        {
          districtNameTh: "นามน",
          districtNameEn: "Na Mon",
          subdistricts: [
            {
              subdistrictNameTh: "นามน",
              subdistrictNameEn: "Na Mon",
              postalCode: 46230,
            },
            {
              subdistrictNameTh: "ยอดแกง",
              subdistrictNameEn: "Yot Kaeng",
              postalCode: 46230,
            },
            {
              subdistrictNameTh: "สงเปลือย",
              subdistrictNameEn: "Song Plueai",
              postalCode: 46230,
            },
            {
              subdistrictNameTh: "หลักเหลี่ยม",
              subdistrictNameEn: "Lak Liam",
              postalCode: 46230,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 46230,
            },
          ],
        },
        {
          districtNameTh: "กมลาไสย",
          districtNameEn: "Kamalasai",
          subdistricts: [
            {
              subdistrictNameTh: "กมลาไสย",
              subdistrictNameEn: "Kamalasai",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "หลักเมือง",
              subdistrictNameEn: "Lak Mueang",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "ดงลิง",
              subdistrictNameEn: "Dong Ling",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "ธัญญา",
              subdistrictNameEn: "Thanya",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "หนองแปน",
              subdistrictNameEn: "Nong Paen",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "เจ้าท่า",
              subdistrictNameEn: "Chao Tha",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "โคกสมบูรณ์",
              subdistrictNameEn: "Khok Sombun",
              postalCode: 46130,
            },
          ],
        },
        {
          districtNameTh: "ร่องคำ",
          districtNameEn: "Rong Kham",
          subdistricts: [
            {
              subdistrictNameTh: "ร่องคำ",
              subdistrictNameEn: "Rong Kham",
              postalCode: 46210,
            },
            {
              subdistrictNameTh: "สามัคคี",
              subdistrictNameEn: "Samakkhi",
              postalCode: 46210,
            },
            {
              subdistrictNameTh: "เหล่าอ้อย",
              subdistrictNameEn: "Lao Oi",
              postalCode: 46210,
            },
          ],
        },
        {
          districtNameTh: "กุฉินารายณ์",
          districtNameEn: "Kuchinarai",
          subdistricts: [
            {
              subdistrictNameTh: "บัวขาว",
              subdistrictNameEn: "Bua Khao",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "แจนแลน",
              subdistrictNameEn: "Chaen Laen",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "เหล่าใหญ่",
              subdistrictNameEn: "Lao Yai",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "จุมจัง",
              subdistrictNameEn: "Chum Chang",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "เหล่าไฮงาม",
              subdistrictNameEn: "Lao Hai Ngam",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "กุดหว้า",
              subdistrictNameEn: "Kut Wa",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "สามขา",
              subdistrictNameEn: "Sam Kha",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "นาขาม",
              subdistrictNameEn: "Nakham",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "หนองห้าง",
              subdistrictNameEn: "Nong Hang",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "นาโก",
              subdistrictNameEn: "Nako",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "สมสะอาด",
              subdistrictNameEn: "Som Sa-At",
              postalCode: 46110,
            },
            {
              subdistrictNameTh: "กุดค้าว",
              subdistrictNameEn: "Kut Khao",
              postalCode: 46110,
            },
          ],
        },
        {
          districtNameTh: "เขาวง",
          districtNameEn: "Khao Wong",
          subdistricts: [
            {
              subdistrictNameTh: "คุ้มเก่า",
              subdistrictNameEn: "Khum Kao",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "สงเปลือย",
              subdistrictNameEn: "Song Plueai",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "หนองผือ",
              subdistrictNameEn: "Nong Phue",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "กุดสิมคุ้มใหม่",
              subdistrictNameEn: "Kut Sinkhum Mai",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "สระพังทอง",
              subdistrictNameEn: "Sa Phang Thong",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "กุดปลาค้าว",
              subdistrictNameEn: "Kut Pla Khao",
              postalCode: 46160,
            },
          ],
        },
        {
          districtNameTh: "ยางตลาด",
          districtNameEn: "Yang Talat",
          subdistricts: [
            {
              subdistrictNameTh: "ยางตลาด",
              subdistrictNameEn: "Yang Talat",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "หัวงัว",
              subdistrictNameEn: "Hua Ngua",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "อุ่มเม่า",
              subdistrictNameEn: "Um Mao",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "บัวบาน",
              subdistrictNameEn: "Bua Ban",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "เว่อ",
              subdistrictNameEn: "Woe",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "อิตื้อ",
              subdistrictNameEn: "I Tue",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "หัวนาคำ",
              subdistrictNameEn: "Hua Na Kham",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "หนองอิเฒ่า",
              subdistrictNameEn: "Nong I Thao",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "ดอนสมบูรณ์",
              subdistrictNameEn: "Don Sombun",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "นาเชือก",
              subdistrictNameEn: "Na Chueak",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "คลองขาม",
              subdistrictNameEn: "Khlong Kham",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "เขาพระนอน",
              subdistrictNameEn: "Khao Phra Non",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "โนนสูง",
              subdistrictNameEn: "Non Sung",
              postalCode: 46120,
            },
            {
              subdistrictNameTh: "หนองตอกแป้น",
              subdistrictNameEn: "Nong Tok Paen",
              postalCode: 46120,
            },
          ],
        },
        {
          districtNameTh: "ห้วยเม็ก",
          districtNameEn: "Huai Mek",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยเม็ก",
              subdistrictNameEn: "Huai Mek",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "คำใหญ่",
              subdistrictNameEn: "Kham Yai",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "กุดโดน",
              subdistrictNameEn: "Kut Don",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "บึงนาเรียง",
              subdistrictNameEn: "Bueng Na Riang",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "หัวหิน",
              subdistrictNameEn: "Hua Hin",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "พิมูล",
              subdistrictNameEn: "Phimun",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "คำเหมือดแก้ว",
              subdistrictNameEn: "Kham Mueat Kaeo",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 46170,
            },
            {
              subdistrictNameTh: "ทรายทอง",
              subdistrictNameEn: "Sai Thong",
              postalCode: 46170,
            },
          ],
        },
        {
          districtNameTh: "สหัสขันธ์",
          districtNameEn: "Sahatsakhan",
          subdistricts: [
            {
              subdistrictNameTh: "ภูสิงห์",
              subdistrictNameEn: "Phu Sing",
              postalCode: 46140,
            },
            {
              subdistrictNameTh: "สหัสขันธ์",
              subdistrictNameEn: "Sahatsakhan",
              postalCode: 46140,
            },
            {
              subdistrictNameTh: "นามะเขือ",
              subdistrictNameEn: "Na Makhuea",
              postalCode: 46140,
            },
            {
              subdistrictNameTh: "โนนศิลา",
              subdistrictNameEn: "Non Sila",
              postalCode: 46140,
            },
            {
              subdistrictNameTh: "นิคม",
              subdistrictNameEn: "Nikhom",
              postalCode: 46140,
            },
            {
              subdistrictNameTh: "โนนแหลมทอง",
              subdistrictNameEn: "Non Laem Thong",
              postalCode: 46140,
            },
            {
              subdistrictNameTh: "โนนบุรี",
              subdistrictNameEn: "Non Buri",
              postalCode: 46140,
            },
            {
              subdistrictNameTh: "โนนน้ำเกลี้ยง",
              subdistrictNameEn: "Non Nam Kliang",
              postalCode: 46140,
            },
          ],
        },
        {
          districtNameTh: "คำม่วง",
          districtNameEn: "Kham Muang",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งคลอง",
              subdistrictNameEn: "Thung Khlong",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "โพน",
              subdistrictNameEn: "Phon",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "ดินจี่",
              subdistrictNameEn: "Din Chi",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "นาบอน",
              subdistrictNameEn: "Na Bon",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "นาทัน",
              subdistrictNameEn: "Na Than",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "เนินยาง",
              subdistrictNameEn: "Noen Yang",
              postalCode: 46180,
            },
          ],
        },
        {
          districtNameTh: "ท่าคันโท",
          districtNameEn: "Tha Khantho",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าคันโท",
              subdistrictNameEn: "Tha Khantho",
              postalCode: 46190,
            },
            {
              subdistrictNameTh: "กุงเก่า",
              subdistrictNameEn: "Kung Kao",
              postalCode: 46190,
            },
            {
              subdistrictNameTh: "ยางอู้ม",
              subdistrictNameEn: "Yang Um",
              postalCode: 46190,
            },
            {
              subdistrictNameTh: "กุดจิก",
              subdistrictNameEn: "Kut Chik",
              postalCode: 46190,
            },
            {
              subdistrictNameTh: "นาตาล",
              subdistrictNameEn: "Na Tan",
              postalCode: 46190,
            },
            {
              subdistrictNameTh: "ดงสมบูรณ์",
              subdistrictNameEn: "Dong Sombun",
              postalCode: 46190,
            },
          ],
        },
        {
          districtNameTh: "หนองกุงศรี",
          districtNameEn: "Nong Kung Si",
          subdistricts: [
            {
              subdistrictNameTh: "หนองกุงศรี",
              subdistrictNameEn: "Nong Kung Si",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "โคกเครือ",
              subdistrictNameEn: "Khok Khruea",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "หนองสรวง",
              subdistrictNameEn: "Nong Suang",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "เสาเล้า",
              subdistrictNameEn: "Sao Lao",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "หนองใหญ่",
              subdistrictNameEn: "Nong Yai",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "ดงมูล",
              subdistrictNameEn: "Dong Mun",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "ลำหนองแสน",
              subdistrictNameEn: "Lam Nong Saen",
              postalCode: 46220,
            },
            {
              subdistrictNameTh: "หนองหิน",
              subdistrictNameEn: "Nong Hin",
              postalCode: 46220,
            },
          ],
        },
        {
          districtNameTh: "สมเด็จ",
          districtNameEn: "Somdet",
          subdistricts: [
            {
              subdistrictNameTh: "สมเด็จ",
              subdistrictNameEn: "Somdet",
              postalCode: 46150,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 46150,
            },
            {
              subdistrictNameTh: "แซงบาดาล",
              subdistrictNameEn: "Saeng Badan",
              postalCode: 46150,
            },
            {
              subdistrictNameTh: "มหาไชย",
              subdistrictNameEn: "Mahachai",
              postalCode: 46150,
            },
            {
              subdistrictNameTh: "หมูม่น",
              subdistrictNameEn: "Mu Mon",
              postalCode: 46150,
            },
            {
              subdistrictNameTh: "ผาเสวย",
              subdistrictNameEn: "Pha Sawoei",
              postalCode: 46150,
            },
            {
              subdistrictNameTh: "ศรีสมเด็จ",
              subdistrictNameEn: "Si Somdet",
              postalCode: 46150,
            },
            {
              subdistrictNameTh: "ลำห้วยหลัว",
              subdistrictNameEn: "Lam Huai Lua",
              postalCode: 46150,
            },
          ],
        },
        {
          districtNameTh: "ห้วยผึ้ง",
          districtNameEn: "Huai Phueng",
          subdistricts: [
            {
              subdistrictNameTh: "คำบง",
              subdistrictNameEn: "Kham Bong",
              postalCode: 46240,
            },
            {
              subdistrictNameTh: "ไค้นุ่น",
              subdistrictNameEn: "Khai Nun",
              postalCode: 46240,
            },
            {
              subdistrictNameTh: "นิคมห้วยผึ้ง",
              subdistrictNameEn: "Nikhom Huai Phueng",
              postalCode: 46240,
            },
            {
              subdistrictNameTh: "หนองอีบุตร",
              subdistrictNameEn: "Nong I But",
              postalCode: 46240,
            },
          ],
        },
        {
          districtNameTh: "สามชัย",
          districtNameEn: "Sam Chai",
          subdistricts: [
            {
              subdistrictNameTh: "สำราญ",
              subdistrictNameEn: "Samran",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "สำราญใต้",
              subdistrictNameEn: "Samran Tai",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "คำสร้างเที่ยง",
              subdistrictNameEn: "Kham Sang Thiang",
              postalCode: 46180,
            },
            {
              subdistrictNameTh: "หนองช้าง",
              subdistrictNameEn: "Nong Chang",
              postalCode: 46180,
            },
          ],
        },
        {
          districtNameTh: "นาคู",
          districtNameEn: "Na Khu",
          subdistricts: [
            {
              subdistrictNameTh: "นาคู",
              subdistrictNameEn: "Na Khu",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "สายนาวัง",
              subdistrictNameEn: "Sai Na Wang",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "โนนนาจาน",
              subdistrictNameEn: "Non Na Chan",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "บ่อแก้ว",
              subdistrictNameEn: "Bo Kaeo",
              postalCode: 46160,
            },
            {
              subdistrictNameTh: "ภูแล่นช้าง",
              subdistrictNameEn: "Phu Laen Chang",
              postalCode: 46160,
            },
          ],
        },
        {
          districtNameTh: "ดอนจาน",
          districtNameEn: "Don Chan",
          subdistricts: [
            {
              subdistrictNameTh: "ดอนจาน",
              subdistrictNameEn: "Don Chan",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "สะอาดไชยศรี",
              subdistrictNameEn: "Sa-At Chai Si",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ดงพยุง",
              subdistrictNameEn: "Dong Phayung",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "ม่วงนา",
              subdistrictNameEn: "Muang Na",
              postalCode: 46000,
            },
            {
              subdistrictNameTh: "นาจำปา",
              subdistrictNameEn: "Na Champa",
              postalCode: 46000,
            },
          ],
        },
        {
          districtNameTh: "ฆ้องชัย",
          districtNameEn: "Khong Chai",
          subdistricts: [
            {
              subdistrictNameTh: "ฆ้องชัยพัฒนา",
              subdistrictNameEn: "Khong Chai Phatthana",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "เหล่ากลาง",
              subdistrictNameEn: "Lao Klang",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "โนนศิลาเลิง",
              subdistrictNameEn: "Non Sila Loeng",
              postalCode: 46130,
            },
            {
              subdistrictNameTh: "ลำชี",
              subdistrictNameEn: "Lam Chi",
              postalCode: 46130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สกลนคร",
      stateNameEn: "Sakon Nakhon",
      districts: [
        {
          districtNameTh: "เมืองสกลนคร",
          districtNameEn: "Mueang Sakon Nakhon",
          subdistricts: [
            {
              subdistrictNameTh: "ธาตุเชิงชุม",
              subdistrictNameEn: "That Choengchum",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "ขมิ้น",
              subdistrictNameEn: "Khamin",
              postalCode: 47220,
            },
            {
              subdistrictNameTh: "งิ้วด่อน",
              subdistrictNameEn: "Ngio Don",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "โนนหอม",
              subdistrictNameEn: "Non Hom",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "เชียงเครือ",
              subdistrictNameEn: "Chiang Khruea",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "ท่าแร่",
              subdistrictNameEn: "Tha Rae",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "ม่วงลาย",
              subdistrictNameEn: "Muang Lai",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "ดงชน",
              subdistrictNameEn: "Dong Chon",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "ห้วยยาง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "พังขว้าง",
              subdistrictNameEn: "Phang Khwang",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "ดงมะไฟ",
              subdistrictNameEn: "Dong Mafai",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "ธาตุนาเวง",
              subdistrictNameEn: "That Naweng",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "เหล่าปอแดง",
              subdistrictNameEn: "Lao Po Daeng",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "หนองลาด",
              subdistrictNameEn: "Nong Lat",
              postalCode: 47220,
            },
            {
              subdistrictNameTh: "ฮางโฮง",
              subdistrictNameEn: "Hang Hong",
              postalCode: 47000,
            },
            {
              subdistrictNameTh: "โคกก่อง",
              subdistrictNameEn: "Khok Kong",
              postalCode: 47000,
            },
          ],
        },
        {
          districtNameTh: "กุสุมาลย์",
          districtNameEn: "Kusuman",
          subdistricts: [
            {
              subdistrictNameTh: "กุสุมาลย์",
              subdistrictNameEn: "Kusuman",
              postalCode: 47210,
            },
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 47210,
            },
            {
              subdistrictNameTh: "นาเพียง",
              subdistrictNameEn: "Na Phiang",
              postalCode: 47230,
            },
            {
              subdistrictNameTh: "โพธิไพศาล",
              subdistrictNameEn: "Pho Phisan",
              postalCode: 47210,
            },
            {
              subdistrictNameTh: "อุ่มจาน",
              subdistrictNameEn: "Um Chan",
              postalCode: 47230,
            },
          ],
        },
        {
          districtNameTh: "กุดบาก",
          districtNameEn: "Kut Bak",
          subdistricts: [
            {
              subdistrictNameTh: "กุดบาก",
              subdistrictNameEn: "Kut Bak",
              postalCode: 47180,
            },
            {
              subdistrictNameTh: "นาม่อง",
              subdistrictNameEn: "Na Mong",
              postalCode: 47180,
            },
            {
              subdistrictNameTh: "กุดไห",
              subdistrictNameEn: "Kut Hai",
              postalCode: 47180,
            },
          ],
        },
        {
          districtNameTh: "พรรณานิคม",
          districtNameEn: "Phanna Nikhom",
          subdistricts: [
            {
              subdistrictNameTh: "พรรณา",
              subdistrictNameEn: "Phanna",
              postalCode: 47130,
            },
            {
              subdistrictNameTh: "วังยาง",
              subdistrictNameEn: "Wang Yang",
              postalCode: 47130,
            },
            {
              subdistrictNameTh: "พอกน้อย",
              subdistrictNameEn: "Phok Noi",
              postalCode: 47220,
            },
            {
              subdistrictNameTh: "นาหัวบ่อ",
              subdistrictNameEn: "Na Hua Bo",
              postalCode: 47220,
            },
            {
              subdistrictNameTh: "ไร่",
              subdistrictNameEn: "Rai",
              postalCode: 47130,
            },
            {
              subdistrictNameTh: "ช้างมิ่ง",
              subdistrictNameEn: "Chang Ming",
              postalCode: 47130,
            },
            {
              subdistrictNameTh: "นาใน",
              subdistrictNameEn: "Na Nai",
              postalCode: 47130,
            },
            {
              subdistrictNameTh: "สว่าง",
              subdistrictNameEn: "Sawang",
              postalCode: 47130,
            },
            {
              subdistrictNameTh: "บะฮี",
              subdistrictNameEn: "Bahi",
              postalCode: 47130,
            },
            {
              subdistrictNameTh: "เชิงชุม",
              subdistrictNameEn: "Choeng Chum",
              postalCode: 47130,
            },
          ],
        },
        {
          districtNameTh: "พังโคน",
          districtNameEn: "Phanh Khon",
          subdistricts: [
            {
              subdistrictNameTh: "พังโคน",
              subdistrictNameEn: "Phang Khon",
              postalCode: 47160,
            },
            {
              subdistrictNameTh: "ม่วงไข่",
              subdistrictNameEn: "Muang Khai",
              postalCode: 47160,
            },
            {
              subdistrictNameTh: "แร่",
              subdistrictNameEn: "Rae",
              postalCode: 47160,
            },
            {
              subdistrictNameTh: "ไฮหย่อง",
              subdistrictNameEn: "Hai Yong",
              postalCode: 47160,
            },
            {
              subdistrictNameTh: "ต้นผึ้ง",
              subdistrictNameEn: "Ton Phueng",
              postalCode: 47160,
            },
          ],
        },
        {
          districtNameTh: "วาริชภูมิ",
          districtNameEn: "Waritchaphum",
          subdistricts: [
            {
              subdistrictNameTh: "วาริชภูมิ",
              subdistrictNameEn: "Waritchaphum",
              postalCode: 47150,
            },
            {
              subdistrictNameTh: "ปลาโหล",
              subdistrictNameEn: "Pla Lo",
              postalCode: 47150,
            },
            {
              subdistrictNameTh: "หนองลาด",
              subdistrictNameEn: "Nong Lat",
              postalCode: 47150,
            },
            {
              subdistrictNameTh: "คำบ่อ",
              subdistrictNameEn: "Kham Bo",
              postalCode: 47150,
            },
            {
              subdistrictNameTh: "ค้อเขียว",
              subdistrictNameEn: "Kho Khiao",
              postalCode: 47150,
            },
          ],
        },
        {
          districtNameTh: "นิคมน้ำอูน",
          districtNameEn: "Nikhom Nam Un",
          subdistricts: [
            {
              subdistrictNameTh: "นิคมน้ำอูน",
              subdistrictNameEn: "Nikhom Nam Un",
              postalCode: 47270,
            },
            {
              subdistrictNameTh: "หนองปลิง",
              subdistrictNameEn: "Nong Pling",
              postalCode: 47270,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 47270,
            },
            {
              subdistrictNameTh: "สุวรรณคาม",
              subdistrictNameEn: "Suwanakham",
              postalCode: 47270,
            },
          ],
        },
        {
          districtNameTh: "วานรนิวาส",
          districtNameEn: "Wanon Niwat",
          subdistricts: [
            {
              subdistrictNameTh: "วานรนิวาส",
              subdistrictNameEn: "Wanon Niwat",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "เดื่อศรีคันไชย",
              subdistrictNameEn: "Duea Si Khan Chai",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "ขัวก่าย",
              subdistrictNameEn: "Khua Kai",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "หนองสนม",
              subdistrictNameEn: "Nong Sanom",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "คูสะคาม",
              subdistrictNameEn: "Khu Sakham",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "ธาตุ",
              subdistrictNameEn: "That",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "ศรีวิชัย",
              subdistrictNameEn: "Si Wichai",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "นาซอ",
              subdistrictNameEn: "Na So",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "อินทร์แปลง",
              subdistrictNameEn: "In Plaeng",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "นาคำ",
              subdistrictNameEn: "Na Kham",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "คอนสวรรค์",
              subdistrictNameEn: "Khon Sawan",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "กุดเรือคำ",
              subdistrictNameEn: "Kut Ruea Kham",
              postalCode: 47120,
            },
            {
              subdistrictNameTh: "หนองแวงใต้",
              subdistrictNameEn: "Nong Waeng Tai",
              postalCode: 47120,
            },
          ],
        },
        {
          districtNameTh: "คำตากล้า",
          districtNameEn: "Kham Ta Kla",
          subdistricts: [
            {
              subdistrictNameTh: "คำตากล้า",
              subdistrictNameEn: "Kham Ta Kla",
              postalCode: 47250,
            },
            {
              subdistrictNameTh: "หนองบัวสิม",
              subdistrictNameEn: "Nong Bua Sim",
              postalCode: 47250,
            },
            {
              subdistrictNameTh: "นาแต้",
              subdistrictNameEn: "Na Tae",
              postalCode: 47250,
            },
            {
              subdistrictNameTh: "แพด",
              subdistrictNameEn: "Phaet",
              postalCode: 47250,
            },
          ],
        },
        {
          districtNameTh: "บ้านม่วง",
          districtNameEn: "Ban Muang",
          subdistricts: [
            {
              subdistrictNameTh: "ม่วง",
              subdistrictNameEn: "Muang",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "มาย",
              subdistrictNameEn: "Mai",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "ดงหม้อทอง",
              subdistrictNameEn: "Dong Mo Thong",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "ดงเหนือ",
              subdistrictNameEn: "Dong Nuea",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "ดงหม้อทองใต้",
              subdistrictNameEn: "Dong Mo Thong Tai",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "ห้วยหลัว",
              subdistrictNameEn: "Huai Lua",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "โนนสะอาด",
              subdistrictNameEn: "Non Sa-At",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "หนองกวั่ง",
              subdistrictNameEn: "Nong Kwang",
              postalCode: 47140,
            },
            {
              subdistrictNameTh: "บ่อแก้ว",
              subdistrictNameEn: "Bo Kaeo",
              postalCode: 47140,
            },
          ],
        },
        {
          districtNameTh: "อากาศอำนวย",
          districtNameEn: "Akat Amnuai",
          subdistricts: [
            {
              subdistrictNameTh: "อากาศ",
              subdistrictNameEn: "Akat",
              postalCode: 47170,
            },
            {
              subdistrictNameTh: "โพนแพง",
              subdistrictNameEn: "Phon Phaeng",
              postalCode: 47170,
            },
            {
              subdistrictNameTh: "วาใหญ่",
              subdistrictNameEn: "Wa Yai",
              postalCode: 47170,
            },
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 47170,
            },
            {
              subdistrictNameTh: "ท่าก้อน",
              subdistrictNameEn: "Tha Kon",
              postalCode: 47170,
            },
            {
              subdistrictNameTh: "นาฮี",
              subdistrictNameEn: "Na Hi",
              postalCode: 47170,
            },
            {
              subdistrictNameTh: "บะหว้า",
              subdistrictNameEn: "Bawa",
              postalCode: 47170,
            },
            {
              subdistrictNameTh: "สามัคคีพัฒนา",
              subdistrictNameEn: "Samakkhi Phatthana",
              postalCode: 47170,
            },
          ],
        },
        {
          districtNameTh: "สว่างแดนดิน",
          districtNameEn: "Sawang Daen Din",
          subdistricts: [
            {
              subdistrictNameTh: "สว่างแดนดิน",
              subdistrictNameEn: "Sawang Daen Din",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "คำสะอาด",
              subdistrictNameEn: "Kham Sa-At",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "บ้านต้าย",
              subdistrictNameEn: "Ban Tai",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "บงเหนือ",
              subdistrictNameEn: "Bong Nuea",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "โพนสูง",
              subdistrictNameEn: "Phon Sung",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "โคกสี",
              subdistrictNameEn: "Khok Si",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "บงใต้",
              subdistrictNameEn: "Bong Tai",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "ค้อใต้",
              subdistrictNameEn: "Kho Tai",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "พันนา",
              subdistrictNameEn: "Phan Na",
              postalCode: 47240,
            },
            {
              subdistrictNameTh: "แวง",
              subdistrictNameEn: "Waeng",
              postalCode: 47240,
            },
            {
              subdistrictNameTh: "ทรายมูล",
              subdistrictNameEn: "Sai Mun",
              postalCode: 47110,
            },
            {
              subdistrictNameTh: "ตาลโกน",
              subdistrictNameEn: "Tan Kon",
              postalCode: 47240,
            },
            {
              subdistrictNameTh: "ตาลเนิ้ง",
              subdistrictNameEn: "Tan Noeng",
              postalCode: 47240,
            },
            {
              subdistrictNameTh: "ธาตุทอง",
              subdistrictNameEn: "That Thong",
              postalCode: 47240,
            },
            {
              subdistrictNameTh: "บ้านถ่อน",
              subdistrictNameEn: "Ban Thon",
              postalCode: 47110,
            },
          ],
        },
        {
          districtNameTh: "ส่องดาว",
          districtNameEn: "Song Dao",
          subdistricts: [
            {
              subdistrictNameTh: "ส่องดาว",
              subdistrictNameEn: "Song Dao",
              postalCode: 47190,
            },
            {
              subdistrictNameTh: "ท่าศิลา",
              subdistrictNameEn: "Tha Sila",
              postalCode: 47190,
            },
            {
              subdistrictNameTh: "วัฒนา",
              subdistrictNameEn: "Watthana",
              postalCode: 47190,
            },
            {
              subdistrictNameTh: "ปทุมวาปี",
              subdistrictNameEn: "Pathum Wapi",
              postalCode: 47190,
            },
          ],
        },
        {
          districtNameTh: "เต่างอย",
          districtNameEn: "Tao Ngoi",
          subdistricts: [
            {
              subdistrictNameTh: "เต่างอย",
              subdistrictNameEn: "Tao Ngoi",
              postalCode: 47260,
            },
            {
              subdistrictNameTh: "บึงทวาย",
              subdistrictNameEn: "Bueng Thawai",
              postalCode: 47260,
            },
            {
              subdistrictNameTh: "นาตาล",
              subdistrictNameEn: "Na Tan",
              postalCode: 47260,
            },
            {
              subdistrictNameTh: "จันทร์เพ็ญ",
              subdistrictNameEn: "Chan Phen",
              postalCode: 47260,
            },
          ],
        },
        {
          districtNameTh: "โคกศรีสุพรรณ",
          districtNameEn: "Khok Si Suphan",
          subdistricts: [
            {
              subdistrictNameTh: "ตองโขบ",
              subdistrictNameEn: "Tong Khop",
              postalCode: 47280,
            },
            {
              subdistrictNameTh: "เหล่าโพนค้อ",
              subdistrictNameEn: "Lao Phon Kho",
              postalCode: 47280,
            },
            {
              subdistrictNameTh: "ด่านม่วงคำ",
              subdistrictNameEn: "Dan Muang Kham",
              postalCode: 47280,
            },
            {
              subdistrictNameTh: "แมดนาท่ม",
              subdistrictNameEn: "Maet Na Thom",
              postalCode: 47280,
            },
          ],
        },
        {
          districtNameTh: "เจริญศิลป์",
          districtNameEn: "Charoen Sin",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านเหล่า",
              subdistrictNameEn: "Ban Lao",
              postalCode: 47290,
            },
            {
              subdistrictNameTh: "เจริญศิลป์",
              subdistrictNameEn: "Charoen Sin",
              postalCode: 47290,
            },
            {
              subdistrictNameTh: "ทุ่งแก",
              subdistrictNameEn: "Thung Kae",
              postalCode: 47290,
            },
            {
              subdistrictNameTh: "โคกศิลา",
              subdistrictNameEn: "Khok Sila",
              postalCode: 47290,
            },
            {
              subdistrictNameTh: "หนองแปน",
              subdistrictNameEn: "Nong Paen",
              postalCode: 47290,
            },
          ],
        },
        {
          districtNameTh: "โพนนาแก้ว",
          districtNameEn: "Phon Na Kaeo",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านโพน",
              subdistrictNameEn: "Ban Phon",
              postalCode: 47230,
            },
            {
              subdistrictNameTh: "นาแก้ว",
              subdistrictNameEn: "Na Kaeo",
              postalCode: 47230,
            },
            {
              subdistrictNameTh: "นาตงวัฒนา",
              subdistrictNameEn: "Na Tong Watthana",
              postalCode: 47230,
            },
            {
              subdistrictNameTh: "บ้านแป้น",
              subdistrictNameEn: "Ban Paen",
              postalCode: 47230,
            },
            {
              subdistrictNameTh: "เชียงสือ",
              subdistrictNameEn: "Chiang Sue",
              postalCode: 47230,
            },
          ],
        },
        {
          districtNameTh: "ภูพาน",
          districtNameEn: "Phu Phan",
          subdistricts: [
            {
              subdistrictNameTh: "สร้างค้อ",
              subdistrictNameEn: "Sang Kho",
              postalCode: 47180,
            },
            {
              subdistrictNameTh: "หลุบเลา",
              subdistrictNameEn: "Luplao",
              postalCode: 47180,
            },
            {
              subdistrictNameTh: "โคกภู",
              subdistrictNameEn: "Khok Phu",
              postalCode: 47180,
            },
            {
              subdistrictNameTh: "กกปลาซิว",
              subdistrictNameEn: "Kok Pla Sio",
              postalCode: 47180,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นครพนม",
      stateNameEn: "Nakhon Phanom",
      districts: [
        {
          districtNameTh: "เมืองนครพนม",
          districtNameEn: "Mueang Nakhon Phanom",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "หนองแสง",
              subdistrictNameEn: "Nong Saeng",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "นาทราย",
              subdistrictNameEn: "Na Sai",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "นาราชควาย",
              subdistrictNameEn: "Na Ratchakhwai",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "กุรุคุ",
              subdistrictNameEn: "Kurukhu",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "บ้านผึ้ง",
              subdistrictNameEn: "Ban Phueng",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "อาจสามารถ",
              subdistrictNameEn: "At Samat",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "ขามเฒ่า",
              subdistrictNameEn: "Kham Thao",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "ท่าค้อ",
              subdistrictNameEn: "Tha Kho",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "คำเตย",
              subdistrictNameEn: "Kham Toei",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "หนองญาติ",
              subdistrictNameEn: "Nong Yat",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "ดงขวาง",
              subdistrictNameEn: "Dong Khwang",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "วังตามัว",
              subdistrictNameEn: "Wang Ta Mua",
              postalCode: 48000,
            },
            {
              subdistrictNameTh: "โพธิ์ตาก",
              subdistrictNameEn: "Pho Tak",
              postalCode: 48000,
            },
          ],
        },
        {
          districtNameTh: "ปลาปาก",
          districtNameEn: "Pla Pak",
          subdistricts: [
            {
              subdistrictNameTh: "ปลาปาก",
              subdistrictNameEn: "Pla Pak",
              postalCode: 48160,
            },
            {
              subdistrictNameTh: "หนองฮี",
              subdistrictNameEn: "Nong Hi",
              postalCode: 48160,
            },
            {
              subdistrictNameTh: "กุตาไก้",
              subdistrictNameEn: "Kut Takai",
              postalCode: 48160,
            },
            {
              subdistrictNameTh: "โคกสว่าง",
              subdistrictNameEn: "Khok Sawang",
              postalCode: 48160,
            },
            {
              subdistrictNameTh: "โคกสูง",
              subdistrictNameEn: "Khok Sung",
              postalCode: 48160,
            },
            {
              subdistrictNameTh: "มหาชัย",
              subdistrictNameEn: "Mahachai",
              postalCode: 48160,
            },
            {
              subdistrictNameTh: "นามะเขือ",
              subdistrictNameEn: "Na Makhuea",
              postalCode: 48160,
            },
            {
              subdistrictNameTh: "หนองเทาใหญ่",
              subdistrictNameEn: "Nong Thao Yai",
              postalCode: 48160,
            },
          ],
        },
        {
          districtNameTh: "ท่าอุเทน",
          districtNameEn: "Tha Uthen",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าอุเทน",
              subdistrictNameEn: "Tha Uthen",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "โนนตาล",
              subdistrictNameEn: "Non Tan",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "ท่าจำปา",
              subdistrictNameEn: "Tha Champa",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "ไชยบุรี",
              subdistrictNameEn: "Chai Buri",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "พนอม",
              subdistrictNameEn: "Phanom",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "พะทาย",
              subdistrictNameEn: "Phathai",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "เวินพระบาท",
              subdistrictNameEn: "Woen Phrabat",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "รามราช",
              subdistrictNameEn: "Rammarat",
              postalCode: 48120,
            },
            {
              subdistrictNameTh: "หนองเทา",
              subdistrictNameEn: "Nong Thao",
              postalCode: 48120,
            },
          ],
        },
        {
          districtNameTh: "บ้านแพง",
          districtNameEn: "Ban Phaeng",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านแพง",
              subdistrictNameEn: "Ban Phaeng",
              postalCode: 48140,
            },
            {
              subdistrictNameTh: "ไผ่ล้อม",
              subdistrictNameEn: "Phai Lom",
              postalCode: 48140,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 48140,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 48140,
            },
            {
              subdistrictNameTh: "นางัว",
              subdistrictNameEn: "Na Ngua",
              postalCode: 48140,
            },
            {
              subdistrictNameTh: "นาเข",
              subdistrictNameEn: "Na Khe",
              postalCode: 48140,
            },
          ],
        },
        {
          districtNameTh: "ธาตุพนม",
          districtNameEn: "That Phanom",
          subdistricts: [
            {
              subdistrictNameTh: "ธาตุพนม",
              subdistrictNameEn: "That Phanom",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "ฝั่งแดง",
              subdistrictNameEn: "Fang Daeng",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "โพนแพง",
              subdistrictNameEn: "Phon Phaeng",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "พระกลางทุ่ง",
              subdistrictNameEn: "Phra Klang Thung",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "นาถ่อน",
              subdistrictNameEn: "Na Thon",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "แสนพัน",
              subdistrictNameEn: "Saen Phan",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "ดอนนางหงส์",
              subdistrictNameEn: "Don Nang Hong",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "น้ำก่ำ",
              subdistrictNameEn: "Nam Kam",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "อุ่มเหม้า",
              subdistrictNameEn: "Um Mao",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "นาหนาด",
              subdistrictNameEn: "Na Nat",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "กุดฉิม",
              subdistrictNameEn: "Kut Chim",
              postalCode: 48110,
            },
            {
              subdistrictNameTh: "ธาตุพนมเหนือ",
              subdistrictNameEn: "That Phanom Nuea",
              postalCode: 48110,
            },
          ],
        },
        {
          districtNameTh: "เรณูนคร",
          districtNameEn: "Renu Nakhon",
          subdistricts: [
            {
              subdistrictNameTh: "เรณู",
              subdistrictNameEn: "Renu",
              postalCode: 48170,
            },
            {
              subdistrictNameTh: "โพนทอง",
              subdistrictNameEn: "Phon Thong",
              postalCode: 48170,
            },
            {
              subdistrictNameTh: "ท่าลาด",
              subdistrictNameEn: "Tha Lat",
              postalCode: 48170,
            },
            {
              subdistrictNameTh: "นางาม",
              subdistrictNameEn: "Na Ngam",
              postalCode: 48170,
            },
            {
              subdistrictNameTh: "โคกหินแฮ่",
              subdistrictNameEn: "Khok Hin Hae",
              postalCode: 48170,
            },
            {
              subdistrictNameTh: "หนองย่างชิ้น",
              subdistrictNameEn: "Nong Yang Chin",
              postalCode: 48170,
            },
            {
              subdistrictNameTh: "เรณูใต้",
              subdistrictNameEn: "Renu Tai",
              postalCode: 48170,
            },
            {
              subdistrictNameTh: "นาขาม",
              subdistrictNameEn: "Nakham",
              postalCode: 48170,
            },
          ],
        },
        {
          districtNameTh: "นาแก",
          districtNameEn: "Na Kae",
          subdistricts: [
            {
              subdistrictNameTh: "นาแก",
              subdistrictNameEn: "Na Kae",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "พระซอง",
              subdistrictNameEn: "Phra Song",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "หนองสังข์",
              subdistrictNameEn: "Nong Sang",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "นาคู่",
              subdistrictNameEn: "Na Khu",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "พิมาน",
              subdistrictNameEn: "Phiman",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "พุ่มแก",
              subdistrictNameEn: "Phum Kae",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "ก้านเหลือง",
              subdistrictNameEn: "Kan Lueang",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "หนองบ่อ",
              subdistrictNameEn: "Nong Bo",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "นาเลียง",
              subdistrictNameEn: "Na Liang",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "บ้านแก้ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "คำพี้",
              subdistrictNameEn: "Kham Phi",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "สีชมพู",
              subdistrictNameEn: "Si Chomphu",
              postalCode: 48130,
            },
          ],
        },
        {
          districtNameTh: "ศรีสงคราม",
          districtNameEn: "Si Songkhram",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีสงคราม",
              subdistrictNameEn: "Si Songkhram",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "นาเดื่อ",
              subdistrictNameEn: "Na Duea",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "บ้านเอื้อง",
              subdistrictNameEn: "Ban Ueang",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "สามผง",
              subdistrictNameEn: "Sam Phong",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "ท่าบ่อสงคราม",
              subdistrictNameEn: "Tha Bo Songkhram",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "บ้านข่า",
              subdistrictNameEn: "Ban Kha",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "นาคำ",
              subdistrictNameEn: "Na Kham",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "โพนสว่าง",
              subdistrictNameEn: "Phon Sawang",
              postalCode: 48150,
            },
            {
              subdistrictNameTh: "หาดแพง",
              subdistrictNameEn: "Hat Phaeng",
              postalCode: 48150,
            },
          ],
        },
        {
          districtNameTh: "นาหว้า",
          districtNameEn: "Na Wa",
          subdistricts: [
            {
              subdistrictNameTh: "นาหว้า",
              subdistrictNameEn: "Na Wa",
              postalCode: 48180,
            },
            {
              subdistrictNameTh: "นางัว",
              subdistrictNameEn: "Na Ngua",
              postalCode: 48180,
            },
            {
              subdistrictNameTh: "บ้านเสียว",
              subdistrictNameEn: "Ban Siao",
              postalCode: 48180,
            },
            {
              subdistrictNameTh: "นาคูณใหญ่",
              subdistrictNameEn: "Na Khun Yai",
              postalCode: 48180,
            },
            {
              subdistrictNameTh: "เหล่าพัฒนา",
              subdistrictNameEn: "Lao Phathana",
              postalCode: 48180,
            },
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 48180,
            },
          ],
        },
        {
          districtNameTh: "โพนสวรรค์",
          districtNameEn: "Phon Sawan",
          subdistricts: [
            {
              subdistrictNameTh: "โพนสวรรค์",
              subdistrictNameEn: "Phon Sawan",
              postalCode: 48190,
            },
            {
              subdistrictNameTh: "นาหัวบ่อ",
              subdistrictNameEn: "Na Hua Bo",
              postalCode: 48190,
            },
            {
              subdistrictNameTh: "นาขมิ้น",
              subdistrictNameEn: "Na Khamin",
              postalCode: 48190,
            },
            {
              subdistrictNameTh: "โพนบก",
              subdistrictNameEn: "Phon Bok",
              postalCode: 48190,
            },
            {
              subdistrictNameTh: "บ้านค้อ",
              subdistrictNameEn: "Ban Kho",
              postalCode: 48190,
            },
            {
              subdistrictNameTh: "โพนจาน",
              subdistrictNameEn: "Phon Chan",
              postalCode: 48190,
            },
            {
              subdistrictNameTh: "นาใน",
              subdistrictNameEn: "Na Nai",
              postalCode: 48190,
            },
          ],
        },
        {
          districtNameTh: "นาทม",
          districtNameEn: "Na Thom",
          subdistricts: [
            {
              subdistrictNameTh: "นาทม",
              subdistrictNameEn: "Na Thom",
              postalCode: 48140,
            },
            {
              subdistrictNameTh: "หนองซน",
              subdistrictNameEn: "Nong Son",
              postalCode: 48140,
            },
            {
              subdistrictNameTh: "ดอนเตย",
              subdistrictNameEn: "Don Toei",
              postalCode: 48140,
            },
          ],
        },
        {
          districtNameTh: "วังยาง",
          districtNameEn: "Wang Yang",
          subdistricts: [
            {
              subdistrictNameTh: "วังยาง",
              subdistrictNameEn: "Wang Yang",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "โคกสี",
              subdistrictNameEn: "Khok Si",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "ยอดชาด",
              subdistrictNameEn: "Yot Chat",
              postalCode: 48130,
            },
            {
              subdistrictNameTh: "หนองโพธิ์",
              subdistrictNameEn: "Nong Pho",
              postalCode: 48130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "มุกดาหาร",
      stateNameEn: "Mukdahan",
      districts: [
        {
          districtNameTh: "เมืองมุกดาหาร",
          districtNameEn: "Mueang Mukdahan",
          subdistricts: [
            {
              subdistrictNameTh: "มุกดาหาร",
              subdistrictNameEn: "Mukdahan",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "ศรีบุญเรือง",
              subdistrictNameEn: "Si Bunrueang",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "บ้านโคก",
              subdistrictNameEn: "Ban Khok",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "บางทรายใหญ่",
              subdistrictNameEn: "Bang Sai Yai",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "โพนทราย",
              subdistrictNameEn: "Phon Sai",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "ผึ่งแดด",
              subdistrictNameEn: "Phueng Daet",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "นาโสก",
              subdistrictNameEn: "Na Sok",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "นาสีนวน",
              subdistrictNameEn: "Na Si Nuan",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "คำป่าหลาย",
              subdistrictNameEn: "Kham Pa Lai",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "คำอาฮวน",
              subdistrictNameEn: "Kham A Huan",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "ดงเย็น",
              subdistrictNameEn: "Dong Yen",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "ดงมอน",
              subdistrictNameEn: "Dong Mon",
              postalCode: 49000,
            },
            {
              subdistrictNameTh: "กุดแข้",
              subdistrictNameEn: "Kut Khae",
              postalCode: 49000,
            },
          ],
        },
        {
          districtNameTh: "นิคมคำสร้อย",
          districtNameEn: "Nikhom Kham Soi",
          subdistricts: [
            {
              subdistrictNameTh: "นิคมคำสร้อย",
              subdistrictNameEn: "Nikhom Kham Soi",
              postalCode: 49130,
            },
            {
              subdistrictNameTh: "นากอก",
              subdistrictNameEn: "Na Kok",
              postalCode: 49130,
            },
            {
              subdistrictNameTh: "หนองแวง",
              subdistrictNameEn: "Nong Waeng",
              postalCode: 49130,
            },
            {
              subdistrictNameTh: "กกแดง",
              subdistrictNameEn: "Kok Daeng",
              postalCode: 49130,
            },
            {
              subdistrictNameTh: "นาอุดม",
              subdistrictNameEn: "Na Udom",
              postalCode: 49130,
            },
            {
              subdistrictNameTh: "โชคชัย",
              subdistrictNameEn: "Chok Chai",
              postalCode: 49130,
            },
            {
              subdistrictNameTh: "ร่มเกล้า",
              subdistrictNameEn: "Romklao",
              postalCode: 49130,
            },
          ],
        },
        {
          districtNameTh: "ดอนตาล",
          districtNameEn: "Don Tan",
          subdistricts: [
            {
              subdistrictNameTh: "ดอนตาล",
              subdistrictNameEn: "Don Tan",
              postalCode: 49120,
            },
            {
              subdistrictNameTh: "โพธิ์ไทร",
              subdistrictNameEn: "Pho Sai",
              postalCode: 49120,
            },
            {
              subdistrictNameTh: "ป่าไร่",
              subdistrictNameEn: "Pa Rai",
              postalCode: 49120,
            },
            {
              subdistrictNameTh: "เหล่าหมี",
              subdistrictNameEn: "Lao Mi",
              postalCode: 49120,
            },
            {
              subdistrictNameTh: "บ้านบาก",
              subdistrictNameEn: "Ban Bak",
              postalCode: 49120,
            },
            {
              subdistrictNameTh: "นาสะเม็ง",
              subdistrictNameEn: "Na Sameng",
              postalCode: 49120,
            },
            {
              subdistrictNameTh: "บ้านแก้ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 49120,
            },
          ],
        },
        {
          districtNameTh: "ดงหลวง",
          districtNameEn: "Don Tan",
          subdistricts: [
            {
              subdistrictNameTh: "ดงหลวง",
              subdistrictNameEn: "Dong Luang",
              postalCode: 49140,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 49140,
            },
            {
              subdistrictNameTh: "กกตูม",
              subdistrictNameEn: "Kok Tum",
              postalCode: 49140,
            },
            {
              subdistrictNameTh: "หนองแคน",
              subdistrictNameEn: "Nong Khaen",
              postalCode: 49140,
            },
            {
              subdistrictNameTh: "ชะโนดน้อย",
              subdistrictNameEn: "Chanot Noi",
              postalCode: 49140,
            },
            {
              subdistrictNameTh: "พังแดง",
              subdistrictNameEn: "Phang Daeng",
              postalCode: 49140,
            },
          ],
        },
        {
          districtNameTh: "คำชะอี",
          districtNameEn: "Khamcha-I",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านซ่ง",
              subdistrictNameEn: "Ban Shong",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "คำชะอี",
              subdistrictNameEn: "Kham Cha-I",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "หนองเอี่ยน",
              subdistrictNameEn: "Nong Ian",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "บ้านค้อ",
              subdistrictNameEn: "Ban Kho",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "บ้านเหล่า",
              subdistrictNameEn: "Ban Lao",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "โพนงาม",
              subdistrictNameEn: "Phon Ngam",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "เหล่าสร้างถ่อ",
              subdistrictNameEn: "Lao Sang Tho",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "คำบก",
              subdistrictNameEn: "Kham Bok",
              postalCode: 49110,
            },
            {
              subdistrictNameTh: "น้ำเที่ยง",
              subdistrictNameEn: "Nam Thiang",
              postalCode: 49110,
            },
          ],
        },
        {
          districtNameTh: "หว้านใหญ่",
          districtNameEn: "Wan Yai",
          subdistricts: [
            {
              subdistrictNameTh: "หว้านใหญ่",
              subdistrictNameEn: "Wan Yai",
              postalCode: 49150,
            },
            {
              subdistrictNameTh: "ป่งขาม",
              subdistrictNameEn: "Pong Kham",
              postalCode: 49150,
            },
            {
              subdistrictNameTh: "บางทรายน้อย",
              subdistrictNameEn: "Bang Sai Noi",
              postalCode: 49150,
            },
            {
              subdistrictNameTh: "ชะโนด",
              subdistrictNameEn: "Chanot",
              postalCode: 49150,
            },
            {
              subdistrictNameTh: "ดงหมู",
              subdistrictNameEn: "Dong Mu",
              postalCode: 49150,
            },
          ],
        },
        {
          districtNameTh: "หนองสูง",
          districtNameEn: "Nong Sung",
          subdistricts: [
            {
              subdistrictNameTh: "หนองสูง",
              subdistrictNameEn: "Nong Sung",
              postalCode: 49160,
            },
            {
              subdistrictNameTh: "โนนยาง",
              subdistrictNameEn: "Non Yang",
              postalCode: 49160,
            },
            {
              subdistrictNameTh: "ภูวง",
              subdistrictNameEn: "Phu Wong",
              postalCode: 49160,
            },
            {
              subdistrictNameTh: "บ้านเป้า",
              subdistrictNameEn: "Ban Pao",
              postalCode: 49160,
            },
            {
              subdistrictNameTh: "หนองสูงใต้",
              subdistrictNameEn: "Nong Sung Tai",
              postalCode: 49160,
            },
            {
              subdistrictNameTh: "หนองสูงเหนือ",
              subdistrictNameEn: "Nong Sung Nuea",
              postalCode: 49160,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "เชียงใหม่",
      stateNameEn: "Chiang Mai",
      districts: [
        {
          districtNameTh: "เมืองเชียงใหม่",
          districtNameEn: "Mueang Chiang Mai",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีภูมิ",
              subdistrictNameEn: "Si Phum",
              postalCode: 50200,
            },
            {
              subdistrictNameTh: "พระสิงห์",
              subdistrictNameEn: "Phra Sing",
              postalCode: 50200,
            },
            {
              subdistrictNameTh: "หายยา",
              subdistrictNameEn: "Hai Ya",
              postalCode: 50100,
            },
            {
              subdistrictNameTh: "ช้างม่อย",
              subdistrictNameEn: "Chang Moi",
              postalCode: 50300,
            },
            {
              subdistrictNameTh: "ช้างคลาน",
              subdistrictNameEn: "Chang Khlan",
              postalCode: 50100,
            },
            {
              subdistrictNameTh: "วัดเกต",
              subdistrictNameEn: "Wat Ket",
              postalCode: 50000,
            },
            {
              subdistrictNameTh: "ช้างเผือก",
              subdistrictNameEn: "Chang Phueak",
              postalCode: 50300,
            },
            {
              subdistrictNameTh: "สุเทพ",
              subdistrictNameEn: "Su Thep",
              postalCode: 50200,
            },
            {
              subdistrictNameTh: "แม่เหียะ",
              subdistrictNameEn: "Mae Hia",
              postalCode: 50100,
            },
            {
              subdistrictNameTh: "ป่าแดด",
              subdistrictNameEn: "Pa Daet",
              postalCode: 50100,
            },
            {
              subdistrictNameTh: "หนองหอย",
              subdistrictNameEn: "Nong Hoi",
              postalCode: 50000,
            },
            {
              subdistrictNameTh: "ท่าศาลา",
              subdistrictNameEn: "Tha Sala",
              postalCode: 50000,
            },
            {
              subdistrictNameTh: "หนองป่าครั่ง",
              subdistrictNameEn: "Nong Pa Khrang",
              postalCode: 50000,
            },
            {
              subdistrictNameTh: "ฟ้าฮ่าม",
              subdistrictNameEn: "Fa Ham",
              postalCode: 50000,
            },
            {
              subdistrictNameTh: "ป่าตัน",
              subdistrictNameEn: "Pa Tan",
              postalCode: 50300,
            },
            {
              subdistrictNameTh: "สันผีเสื้อ",
              subdistrictNameEn: "San Phisuea",
              postalCode: 50300,
            },
          ],
        },
        {
          districtNameTh: "จอมทอง",
          districtNameEn: "Chom Thong",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านหลวง",
              subdistrictNameEn: "Ban Luang",
              postalCode: 50160,
            },
            {
              subdistrictNameTh: "ข่วงเปา",
              subdistrictNameEn: "Khuang Pao",
              postalCode: 50160,
            },
            {
              subdistrictNameTh: "สบเตี๊ยะ",
              subdistrictNameEn: "Sop Tia",
              postalCode: 50160,
            },
            {
              subdistrictNameTh: "บ้านแปะ",
              subdistrictNameEn: "Ban Pae",
              postalCode: 50240,
            },
            {
              subdistrictNameTh: "ดอยแก้ว",
              subdistrictNameEn: "Doi Kaeo",
              postalCode: 50160,
            },
            {
              subdistrictNameTh: "แม่สอย",
              subdistrictNameEn: "Mae Soi",
              postalCode: 50240,
            },
          ],
        },
        {
          districtNameTh: "แม่แจ่ม",
          districtNameEn: "Mae Chaem",
          subdistricts: [
            {
              subdistrictNameTh: "ช่างเคิ่ง",
              subdistrictNameEn: "Chang Khoeng",
              postalCode: 50270,
            },
            {
              subdistrictNameTh: "ท่าผา",
              subdistrictNameEn: "Tha Pha",
              postalCode: 50270,
            },
            {
              subdistrictNameTh: "บ้านทับ",
              subdistrictNameEn: "Ban Thap",
              postalCode: 50270,
            },
            {
              subdistrictNameTh: "แม่ศึก",
              subdistrictNameEn: "Mae Suek",
              postalCode: 50270,
            },
            {
              subdistrictNameTh: "แม่นาจร",
              subdistrictNameEn: "Mae Na Chon",
              postalCode: 50270,
            },
            {
              subdistrictNameTh: "ปางหินฝน",
              subdistrictNameEn: "Pang Hin Fon",
              postalCode: 50270,
            },
            {
              subdistrictNameTh: "กองแขก",
              subdistrictNameEn: "Kong Khaek",
              postalCode: 50270,
            },
          ],
        },
        {
          districtNameTh: "เชียงดาว",
          districtNameEn: "Chiang Dao",
          subdistricts: [
            {
              subdistrictNameTh: "เชียงดาว",
              subdistrictNameEn: "Chiang Dao",
              postalCode: 50170,
            },
            {
              subdistrictNameTh: "เมืองนะ",
              subdistrictNameEn: "Mueang Na",
              postalCode: 50170,
            },
            {
              subdistrictNameTh: "เมืองงาย",
              subdistrictNameEn: "Mueang Ngai",
              postalCode: 50170,
            },
            {
              subdistrictNameTh: "แม่นะ",
              subdistrictNameEn: "Mae Na",
              postalCode: 50170,
            },
            {
              subdistrictNameTh: "เมืองคอง",
              subdistrictNameEn: "Mueang Khong",
              postalCode: 50170,
            },
            {
              subdistrictNameTh: "ปิงโค้ง",
              subdistrictNameEn: "Ping Khong",
              postalCode: 50170,
            },
            {
              subdistrictNameTh: "ทุ่งข้าวพวง",
              subdistrictNameEn: "Thung Khao Phuang",
              postalCode: 50170,
            },
          ],
        },
        {
          districtNameTh: "ดอยสะเก็ด",
          districtNameEn: "Doi Saket",
          subdistricts: [
            {
              subdistrictNameTh: "เชิงดอย",
              subdistrictNameEn: "Choeng Doi",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "สันปูเลย",
              subdistrictNameEn: "San Pu Loei",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "ลวงเหนือ",
              subdistrictNameEn: "Luang Nuea",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "ป่าป้อง",
              subdistrictNameEn: "Pa Pong",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "สง่าบ้าน",
              subdistrictNameEn: "Sa-Nga Ban",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "ป่าลาน",
              subdistrictNameEn: "Pa Lan",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "ตลาดขวัญ",
              subdistrictNameEn: "Talat Khwan",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "สำราญราษฎร์",
              subdistrictNameEn: "Samran Rat",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "แม่คือ",
              subdistrictNameEn: "Mae Khue",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "ตลาดใหญ่",
              subdistrictNameEn: "Talat Yai",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "แม่ฮ้อยเงิน",
              subdistrictNameEn: "Mae Hoi Ngoen",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "แม่โป่ง",
              subdistrictNameEn: "Mae Pong",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "ป่าเมี่ยง",
              subdistrictNameEn: "Pa Miang",
              postalCode: 50220,
            },
            {
              subdistrictNameTh: "เทพเสด็จ",
              subdistrictNameEn: "Thep Sadet",
              postalCode: 50220,
            },
          ],
        },
        {
          districtNameTh: "แม่แตง",
          districtNameEn: "Mae Taeng",
          subdistricts: [
            {
              subdistrictNameTh: "สันมหาพน",
              subdistrictNameEn: "San Mahaphon",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "แม่แตง",
              subdistrictNameEn: "Mae Taeng",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "ขี้เหล็ก",
              subdistrictNameEn: "Khi Lek",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "ช่อแล",
              subdistrictNameEn: "Cho Lae",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "แม่หอพระ",
              subdistrictNameEn: "Mae Ho Phra",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "สบเปิง",
              subdistrictNameEn: "Sop Poeng",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "บ้านเป้า",
              subdistrictNameEn: "Ban Pao",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "สันป่ายาง",
              subdistrictNameEn: "San Pa Yang",
              postalCode: 50330,
            },
            {
              subdistrictNameTh: "ป่าแป๋",
              subdistrictNameEn: "Pa Pae",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "เมืองก๋าย",
              subdistrictNameEn: "Mueang Kai",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "บ้านช้าง",
              subdistrictNameEn: "Ban Chang",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "กื้ดช้าง",
              subdistrictNameEn: "Kuet Chang",
              postalCode: 50150,
            },
            {
              subdistrictNameTh: "อินทขิล",
              subdistrictNameEn: "Inthakhin",
              postalCode: 50150,
            },
          ],
        },
        {
          districtNameTh: "แม่ริม",
          districtNameEn: "Mae Rim",
          subdistricts: [
            {
              subdistrictNameTh: "ริมใต้",
              subdistrictNameEn: "Rim Tai",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "ริมเหนือ",
              subdistrictNameEn: "Rim Nuea",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "สันโป่ง",
              subdistrictNameEn: "San Pong",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "ขี้เหล็ก",
              subdistrictNameEn: "Khi Lek",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "สะลวง",
              subdistrictNameEn: "Saluang",
              postalCode: 50330,
            },
            {
              subdistrictNameTh: "ห้วยทราย",
              subdistrictNameEn: "Huai Sai",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "แม่แรม",
              subdistrictNameEn: "Mae Raem",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "โป่งแยง",
              subdistrictNameEn: "Pong Yaeng",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "แม่สา",
              subdistrictNameEn: "Mae Sa",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "ดอนแก้ว",
              subdistrictNameEn: "Don Kaeo",
              postalCode: 50180,
            },
            {
              subdistrictNameTh: "เหมืองแก้ว",
              subdistrictNameEn: "Mueang Kaeo",
              postalCode: 50180,
            },
          ],
        },
        {
          districtNameTh: "สะเมิง",
          districtNameEn: "Samoeng",
          subdistricts: [
            {
              subdistrictNameTh: "สะเมิงใต้",
              subdistrictNameEn: "Samoeng Tai",
              postalCode: 50250,
            },
            {
              subdistrictNameTh: "สะเมิงเหนือ",
              subdistrictNameEn: "Samoeng Nuea",
              postalCode: 50250,
            },
            {
              subdistrictNameTh: "แม่สาบ",
              subdistrictNameEn: "Mae Sap",
              postalCode: 50250,
            },
            {
              subdistrictNameTh: "บ่อแก้ว",
              subdistrictNameEn: "Bo Kaeo",
              postalCode: 50250,
            },
            {
              subdistrictNameTh: "ยั้งเมิน",
              subdistrictNameEn: "Yung Moen",
              postalCode: 50250,
            },
          ],
        },
        {
          districtNameTh: "ฝาง",
          districtNameEn: "Fang",
          subdistricts: [
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 50110,
            },
            {
              subdistrictNameTh: "ม่อนปิ่น",
              subdistrictNameEn: "Mon Pin",
              postalCode: 50110,
            },
            {
              subdistrictNameTh: "แม่งอน",
              subdistrictNameEn: "Mae Ngon",
              postalCode: 50320,
            },
            {
              subdistrictNameTh: "แม่สูน",
              subdistrictNameEn: "Mae Soon",
              postalCode: 50110,
            },
            {
              subdistrictNameTh: "สันทราย",
              subdistrictNameEn: "San Sai",
              postalCode: 50110,
            },
            {
              subdistrictNameTh: "แม่คะ",
              subdistrictNameEn: "Mae Kha",
              postalCode: 50110,
            },
            {
              subdistrictNameTh: "แม่ข่า",
              subdistrictNameEn: "Mae Kha",
              postalCode: 50320,
            },
            {
              subdistrictNameTh: "โป่งน้ำร้อน",
              subdistrictNameEn: "Pong Nam Ron",
              postalCode: 50110,
            },
          ],
        },
        {
          districtNameTh: "แม่อาย",
          districtNameEn: "Mae Ai",
          subdistricts: [
            {
              subdistrictNameTh: "แม่อาย",
              subdistrictNameEn: "Mae Ai",
              postalCode: 50280,
            },
            {
              subdistrictNameTh: "แม่สาว",
              subdistrictNameEn: "Mae Sao",
              postalCode: 50280,
            },
            {
              subdistrictNameTh: "สันต้นหมื้อ",
              subdistrictNameEn: "San Ton Mue",
              postalCode: 50280,
            },
            {
              subdistrictNameTh: "แม่นาวาง",
              subdistrictNameEn: "Mae Na Wang",
              postalCode: 50280,
            },
            {
              subdistrictNameTh: "ท่าตอน",
              subdistrictNameEn: "Tha Ton",
              postalCode: 50280,
            },
            {
              subdistrictNameTh: "บ้านหลวง",
              subdistrictNameEn: "Ban Luang",
              postalCode: 50280,
            },
            {
              subdistrictNameTh: "มะลิกา",
              subdistrictNameEn: "Malika",
              postalCode: 50280,
            },
          ],
        },
        {
          districtNameTh: "พร้าว",
          districtNameEn: "Phrao",
          subdistricts: [
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "ทุ่งหลวง",
              subdistrictNameEn: "Thung Luang",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "ป่าตุ้ม",
              subdistrictNameEn: "Pa Tum",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "ป่าไหน่",
              subdistrictNameEn: "Pa Nai",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "สันทราย",
              subdistrictNameEn: "San Sai",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "บ้านโป่ง",
              subdistrictNameEn: "Ban Pong",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "น้ำแพร่",
              subdistrictNameEn: "Nam Phrae",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "เขื่อนผาก",
              subdistrictNameEn: "Khuean Phak",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "แม่แวน",
              subdistrictNameEn: "Mae Waen",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "แม่ปั๋ง",
              subdistrictNameEn: "Mae Pang",
              postalCode: 50190,
            },
            {
              subdistrictNameTh: "โหล่งขอด",
              subdistrictNameEn: "Long Khot",
              postalCode: 50190,
            },
          ],
        },
        {
          districtNameTh: "สันป่าตอง",
          districtNameEn: "San Pa Tong",
          subdistricts: [
            {
              subdistrictNameTh: "ยุหว่า",
              subdistrictNameEn: "Yu Wa",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "สันกลาง",
              subdistrictNameEn: "San Klang",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "ท่าวังพร้าว",
              subdistrictNameEn: "Tha Wang Phrao",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "มะขามหลวง",
              subdistrictNameEn: "Makham Luang",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "แม่ก๊า",
              subdistrictNameEn: "Mae Ka",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "บ้านแม",
              subdistrictNameEn: "Ban Mae",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "ทุ่งสะโตก",
              subdistrictNameEn: "Thung Satok",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "ทุ่งต้อม",
              subdistrictNameEn: "Thung Tom",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "น้ำบ่อหลวง",
              subdistrictNameEn: "Nam Bo Luang",
              postalCode: 50120,
            },
            {
              subdistrictNameTh: "มะขุนหวาน",
              subdistrictNameEn: "Makhun Wan",
              postalCode: 50120,
            },
          ],
        },
        {
          districtNameTh: "สันกำแพง",
          districtNameEn: "San Kamphaeng",
          subdistricts: [
            {
              subdistrictNameTh: "สันกำแพง",
              subdistrictNameEn: "San Kamphaeng",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ทรายมูล",
              subdistrictNameEn: "Sai Mun",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ร้องวัวแดง",
              subdistrictNameEn: "Rong Wua Daeng",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "บวกค้าง",
              subdistrictNameEn: "Buak Khang",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "แช่ช้าง",
              subdistrictNameEn: "Chae Chang",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ออนใต้",
              subdistrictNameEn: "On Tai",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "แม่ปูคา",
              subdistrictNameEn: "Mae Pu Kha",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ห้วยทราย",
              subdistrictNameEn: "Huai Sai",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ต้นเปา",
              subdistrictNameEn: "Ton Pao",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "สันกลาง",
              subdistrictNameEn: "San Klang",
              postalCode: 50130,
            },
          ],
        },
        {
          districtNameTh: "สันทราย",
          districtNameEn: "San Sai",
          subdistricts: [
            {
              subdistrictNameTh: "สันทรายหลวง",
              subdistrictNameEn: "San Sai Luang",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "สันทรายน้อย",
              subdistrictNameEn: "San Sai Noi",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "สันพระเนตร",
              subdistrictNameEn: "San Phranet",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "สันนาเม็ง",
              subdistrictNameEn: "San Na Meng",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "สันป่าเปา",
              subdistrictNameEn: "San Pa Pao",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "หนองแหย่ง",
              subdistrictNameEn: "Nong Yaeng",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "หนองจ๊อม",
              subdistrictNameEn: "Nong Chom",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "หนองหาร",
              subdistrictNameEn: "Nong Han",
              postalCode: 50290,
            },
            {
              subdistrictNameTh: "แม่แฝก",
              subdistrictNameEn: "Mae Faek",
              postalCode: 50290,
            },
            {
              subdistrictNameTh: "แม่แฝกใหม่",
              subdistrictNameEn: "Mae Faek Mai",
              postalCode: 50290,
            },
            {
              subdistrictNameTh: "เมืองเล็น",
              subdistrictNameEn: "Mueang Len",
              postalCode: 50210,
            },
            {
              subdistrictNameTh: "ป่าไผ่",
              subdistrictNameEn: "Pa Phai",
              postalCode: 50210,
            },
          ],
        },
        {
          districtNameTh: "หางดง",
          districtNameEn: "Hang Dong",
          subdistricts: [
            {
              subdistrictNameTh: "หางดง",
              subdistrictNameEn: "Hang Dong",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "หนองแก๋ว",
              subdistrictNameEn: "Nong Kaeo",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "หารแก้ว",
              subdistrictNameEn: "Han Kaeo",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "หนองตอง",
              subdistrictNameEn: "Nong Tong",
              postalCode: 50340,
            },
            {
              subdistrictNameTh: "ขุนคง",
              subdistrictNameEn: "Khun Khong",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "สบแม่ข่า",
              subdistrictNameEn: "Sop Mae Kha",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "บ้านแหวน",
              subdistrictNameEn: "Ban Waen",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "สันผักหวาน",
              subdistrictNameEn: "San Phak Wan",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "หนองควาย",
              subdistrictNameEn: "Nong Khwai",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "บ้านปง",
              subdistrictNameEn: "Ban Pong",
              postalCode: 50230,
            },
            {
              subdistrictNameTh: "น้ำแพร่",
              subdistrictNameEn: "Nam Phrae",
              postalCode: 50230,
            },
          ],
        },
        {
          districtNameTh: "ฮอด",
          districtNameEn: "Hot",
          subdistricts: [
            {
              subdistrictNameTh: "หางดง",
              subdistrictNameEn: "Hang Dong",
              postalCode: 50240,
            },
            {
              subdistrictNameTh: "ฮอด",
              subdistrictNameEn: "Hot",
              postalCode: 50240,
            },
            {
              subdistrictNameTh: "บ้านตาล",
              subdistrictNameEn: "Ban Tan",
              postalCode: 50240,
            },
            {
              subdistrictNameTh: "บ่อหลวง",
              subdistrictNameEn: "Bo Luang",
              postalCode: 50240,
            },
            {
              subdistrictNameTh: "บ่อสลี",
              subdistrictNameEn: "Bo Sali",
              postalCode: 50240,
            },
            {
              subdistrictNameTh: "นาคอเรือ",
              subdistrictNameEn: "Na Kho Ruea",
              postalCode: 50240,
            },
          ],
        },
        {
          districtNameTh: "ดอยเต่า",
          districtNameEn: "Doi Tao",
          subdistricts: [
            {
              subdistrictNameTh: "ดอยเต่า",
              subdistrictNameEn: "Doi Tao",
              postalCode: 50260,
            },
            {
              subdistrictNameTh: "ท่าเดื่อ",
              subdistrictNameEn: "Tha Duea",
              postalCode: 50260,
            },
            {
              subdistrictNameTh: "มืดกา",
              subdistrictNameEn: "Muet Ka",
              postalCode: 50260,
            },
            {
              subdistrictNameTh: "บ้านแอ่น",
              subdistrictNameEn: "Ban Aen",
              postalCode: 50260,
            },
            {
              subdistrictNameTh: "บงตัน",
              subdistrictNameEn: "Bong Tan",
              postalCode: 50260,
            },
            {
              subdistrictNameTh: "โปงทุ่ง",
              subdistrictNameEn: "Pong Thung",
              postalCode: 50260,
            },
          ],
        },
        {
          districtNameTh: "อมก๋อย",
          districtNameEn: "Omkoi",
          subdistricts: [
            {
              subdistrictNameTh: "อมก๋อย",
              subdistrictNameEn: "Omkoi",
              postalCode: 50310,
            },
            {
              subdistrictNameTh: "ยางเปียง",
              subdistrictNameEn: "Yang Piang",
              postalCode: 50310,
            },
            {
              subdistrictNameTh: "แม่ตื่น",
              subdistrictNameEn: "Mae Tuen",
              postalCode: 50310,
            },
            {
              subdistrictNameTh: "ม่อนจอง",
              subdistrictNameEn: "Mon Chong",
              postalCode: 50310,
            },
            {
              subdistrictNameTh: "แม่หลอง",
              subdistrictNameEn: "Sop Khong",
              postalCode: 50310,
            },
            {
              subdistrictNameTh: "นาเกียน",
              subdistrictNameEn: "Na Kian",
              postalCode: 50310,
            },
          ],
        },
        {
          districtNameTh: "สารภี",
          districtNameEn: "Saraphi",
          subdistricts: [
            {
              subdistrictNameTh: "ยางเนิ้ง",
              subdistrictNameEn: "Yang Noeng",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "สารภี",
              subdistrictNameEn: "Saraphi",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "ชมภู",
              subdistrictNameEn: "Chomphu",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "ไชยสถาน",
              subdistrictNameEn: "Chaiya Sathan",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "ขัวมุง",
              subdistrictNameEn: "Khua Mung",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "หนองแฝก",
              subdistrictNameEn: "Nong Faek",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "หนองผึ้ง",
              subdistrictNameEn: "Nong Phueng",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "ท่ากว้าง",
              subdistrictNameEn: "Tha Kwang",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "ดอนแก้ว",
              subdistrictNameEn: "Don Kaeo",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "ท่าวังตาล",
              subdistrictNameEn: "Tha Wang Tan",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "สันทราย",
              subdistrictNameEn: "San Sai",
              postalCode: 50140,
            },
            {
              subdistrictNameTh: "ป่าบง",
              subdistrictNameEn: "Pa Bong",
              postalCode: 50140,
            },
          ],
        },
        {
          districtNameTh: "เวียงแหง",
          districtNameEn: "Wiang Haeng",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองแหง",
              subdistrictNameEn: "Mueang Haeng",
              postalCode: 50350,
            },
            {
              subdistrictNameTh: "เปียงหลวง",
              subdistrictNameEn: "Piang Luang",
              postalCode: 50350,
            },
            {
              subdistrictNameTh: "แสนไห",
              subdistrictNameEn: "Saen Hai",
              postalCode: 50350,
            },
          ],
        },
        {
          districtNameTh: "ไชยปราการ",
          districtNameEn: "Chai Prakan",
          subdistricts: [
            {
              subdistrictNameTh: "ปงตำ",
              subdistrictNameEn: "Pong Tam",
              postalCode: 50320,
            },
            {
              subdistrictNameTh: "ศรีดงเย็น",
              subdistrictNameEn: "Si Dong Yen",
              postalCode: 50320,
            },
            {
              subdistrictNameTh: "แม่ทะลบ",
              subdistrictNameEn: "Mae Thalop",
              postalCode: 50320,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 50320,
            },
          ],
        },
        {
          districtNameTh: "แม่วาง",
          districtNameEn: "Mae Wang",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านกาด",
              subdistrictNameEn: "Ban Kat",
              postalCode: 50360,
            },
            {
              subdistrictNameTh: "ทุ่งปี๊",
              subdistrictNameEn: "Thung Pi",
              postalCode: 50360,
            },
            {
              subdistrictNameTh: "ทุ่งรวงทอง",
              subdistrictNameEn: "Thung Ruang Thong",
              postalCode: 50360,
            },
            {
              subdistrictNameTh: "แม่วิน",
              subdistrictNameEn: "Mae Win",
              postalCode: 50360,
            },
            {
              subdistrictNameTh: "ดอนเปา",
              subdistrictNameEn: "Don Pao",
              postalCode: 50360,
            },
          ],
        },
        {
          districtNameTh: "แม่ออน",
          districtNameEn: "Mae On",
          subdistricts: [
            {
              subdistrictNameTh: "ออนเหนือ",
              subdistrictNameEn: "On Nuea",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ออนกลาง",
              subdistrictNameEn: "On Klang",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "บ้านสหกรณ์",
              subdistrictNameEn: "Ban Sahakon",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ห้วยแก้ว",
              subdistrictNameEn: "Huai Kaeo",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "แม่ทา",
              subdistrictNameEn: "Mae Tha",
              postalCode: 50130,
            },
            {
              subdistrictNameTh: "ทาเหนือ",
              subdistrictNameEn: "Tha Nuea",
              postalCode: 50130,
            },
          ],
        },
        {
          districtNameTh: "ดอยหล่อ",
          districtNameEn: "Doi Lo",
          subdistricts: [
            {
              subdistrictNameTh: "ดอยหล่อ",
              subdistrictNameEn: "Doi Lo",
              postalCode: 50160,
            },
            {
              subdistrictNameTh: "สองแคว",
              subdistrictNameEn: "Song Khwae",
              postalCode: 50160,
            },
            {
              subdistrictNameTh: "ยางคราม",
              subdistrictNameEn: "Yang Khram",
              postalCode: 50160,
            },
            {
              subdistrictNameTh: "สันติสุข",
              subdistrictNameEn: "Santi Suk",
              postalCode: 50160,
            },
          ],
        },
        {
          districtNameTh: "กัลยาณิวัฒนา",
          districtNameEn: "Kanlayaniwatthana",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านจันทร์",
              subdistrictNameEn: "Ban Chan",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "แม่แดด",
              subdistrictNameEn: "Mae Dad",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "แจ่มหลวง",
              subdistrictNameEn: "Jam Luang",
              postalCode: 58130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ลำพูน",
      stateNameEn: "Lamphun",
      districts: [
        {
          districtNameTh: "เมืองลำพูน",
          districtNameEn: "Mueang Lamphun",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "เหมืองง่า",
              subdistrictNameEn: "Mueang Nga",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "อุโมงค์",
              subdistrictNameEn: "Umong",
              postalCode: 51150,
            },
            {
              subdistrictNameTh: "หนองช้างคืน",
              subdistrictNameEn: "Nong Chang Khuen",
              postalCode: 51150,
            },
            {
              subdistrictNameTh: "ประตูป่า",
              subdistrictNameEn: "Pratu Pa",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "ริมปิง",
              subdistrictNameEn: "Rim Ping",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "ต้นธง",
              subdistrictNameEn: "Ton Thong",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "บ้านแป้น",
              subdistrictNameEn: "Ban Paen",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "เหมืองจี้",
              subdistrictNameEn: "Mueang Chi",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "ป่าสัก",
              subdistrictNameEn: "Pa Sak",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "เวียงยอง",
              subdistrictNameEn: "Wiang Yong",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "มะเขือแจ้",
              subdistrictNameEn: "Makhuea Chae",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "ศรีบัวบาน",
              subdistrictNameEn: "Si Bua Ban",
              postalCode: 51000,
            },
            {
              subdistrictNameTh: "หนองหนาม",
              subdistrictNameEn: "Nong Nam",
              postalCode: 51000,
            },
          ],
        },
        {
          districtNameTh: "แม่ทา",
          districtNameEn: "Mae Tha",
          subdistricts: [
            {
              subdistrictNameTh: "ทาปลาดุก",
              subdistrictNameEn: "Tha Pla Duk",
              postalCode: 51140,
            },
            {
              subdistrictNameTh: "ทาสบเส้า",
              subdistrictNameEn: "Tha Sopsao",
              postalCode: 51140,
            },
            {
              subdistrictNameTh: "ทากาศ",
              subdistrictNameEn: "Tha Kat",
              postalCode: 51170,
            },
            {
              subdistrictNameTh: "ทาขุมเงิน",
              subdistrictNameEn: "Tha Khum Ngoen",
              postalCode: 51170,
            },
            {
              subdistrictNameTh: "ทาทุ่งหลวง",
              subdistrictNameEn: "Tha Thung Luang",
              postalCode: 51170,
            },
            {
              subdistrictNameTh: "ทาแม่ลอบ",
              subdistrictNameEn: "Tha Mae Lop",
              postalCode: 51170,
            },
          ],
        },
        {
          districtNameTh: "บ้านโฮ่ง",
          districtNameEn: "Ban Hong",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านโฮ่ง",
              subdistrictNameEn: "Ban Hong",
              postalCode: 51130,
            },
            {
              subdistrictNameTh: "ป่าพลู",
              subdistrictNameEn: "Pa Phlu",
              postalCode: 51130,
            },
            {
              subdistrictNameTh: "เหล่ายาว",
              subdistrictNameEn: "Lao Yao",
              postalCode: 51130,
            },
            {
              subdistrictNameTh: "ศรีเตี้ย",
              subdistrictNameEn: "Si Tia",
              postalCode: 51130,
            },
            {
              subdistrictNameTh: "หนองปลาสะวาย",
              subdistrictNameEn: "Nong Pla Sawai",
              postalCode: 51130,
            },
          ],
        },
        {
          districtNameTh: "ลี้",
          districtNameEn: "Li",
          subdistricts: [
            {
              subdistrictNameTh: "ลี้",
              subdistrictNameEn: "Li",
              postalCode: 51110,
            },
            {
              subdistrictNameTh: "แม่ตืน",
              subdistrictNameEn: "Mae Tuen",
              postalCode: 51110,
            },
            {
              subdistrictNameTh: "นาทราย",
              subdistrictNameEn: "Na Sai",
              postalCode: 51110,
            },
            {
              subdistrictNameTh: "ดงดำ",
              subdistrictNameEn: "Dong Dam",
              postalCode: 51110,
            },
            {
              subdistrictNameTh: "ก้อ",
              subdistrictNameEn: "Ko",
              postalCode: 51110,
            },
            {
              subdistrictNameTh: "แม่ลาน",
              subdistrictNameEn: "Mae Lan",
              postalCode: 51110,
            },
            {
              subdistrictNameTh: "ป่าไผ่",
              subdistrictNameEn: "Pa Phai",
              postalCode: 51110,
            },
            {
              subdistrictNameTh: "ศรีวิชัย",
              subdistrictNameEn: "Si Wichai",
              postalCode: 51110,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งหัวช้าง",
          districtNameEn: "Thung Hua Chang",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งหัวช้าง",
              subdistrictNameEn: "Thung Hua Chang",
              postalCode: 51160,
            },
            {
              subdistrictNameTh: "บ้านปวง",
              subdistrictNameEn: "Ban Puang",
              postalCode: 51160,
            },
            {
              subdistrictNameTh: "ตะเคียนปม",
              subdistrictNameEn: "Takhian Pom",
              postalCode: 51160,
            },
          ],
        },
        {
          districtNameTh: "ป่าซาง",
          districtNameEn: "Pa Sang",
          subdistricts: [
            {
              subdistrictNameTh: "ปากบ่อง",
              subdistrictNameEn: "Pak Bong",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "ป่าซาง",
              subdistrictNameEn: "Pa Sang",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "แม่แรง",
              subdistrictNameEn: "Mae Raeng",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "ม่วงน้อย",
              subdistrictNameEn: "Muang Noi",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "บ้านเรือน",
              subdistrictNameEn: "Ban Ruean",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "มะกอก",
              subdistrictNameEn: "Makok",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "ท่าตุ้ม",
              subdistrictNameEn: "Tha Tum",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "น้ำดิบ",
              subdistrictNameEn: "Nam Dip",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "นครเจดีย์",
              subdistrictNameEn: "Nakhon Chedi",
              postalCode: 51120,
            },
          ],
        },
        {
          districtNameTh: "บ้านธิ",
          districtNameEn: "Ban Thi",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านธิ",
              subdistrictNameEn: "Ban Thi",
              postalCode: 51180,
            },
            {
              subdistrictNameTh: "ห้วยยาบ",
              subdistrictNameEn: "Huai Yap",
              postalCode: 51180,
            },
          ],
        },
        {
          districtNameTh: "เวียงหนองล่อง",
          districtNameEn: "Wiang Nong Long",
          subdistricts: [
            {
              subdistrictNameTh: "หนองล่อง",
              subdistrictNameEn: "Nong Long",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "หนองยวง",
              subdistrictNameEn: "Nong Yuang",
              postalCode: 51120,
            },
            {
              subdistrictNameTh: "วังผาง",
              subdistrictNameEn: "Wang Phang",
              postalCode: 51120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ลำปาง",
      stateNameEn: "Lampang",
      districts: [
        {
          districtNameTh: "เมืองลำปาง",
          districtNameEn: "Mueang Lampang",
          subdistricts: [
            {
              subdistrictNameTh: "เวียงเหนือ",
              subdistrictNameEn: "Wiang Nuea",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "หัวเวียง",
              subdistrictNameEn: "Hua Wiang",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "สวนดอก",
              subdistrictNameEn: "Suan Dok",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "สบตุ๋ย",
              subdistrictNameEn: "Sop Tui",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "พระบาท",
              subdistrictNameEn: "Phrabat",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "ชมพู",
              subdistrictNameEn: "Chomphu",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "กล้วยแพะ",
              subdistrictNameEn: "Kluai Phae",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "ปงแสนทอง",
              subdistrictNameEn: "Pong Saen Thong",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "บ้านแลง",
              subdistrictNameEn: "Ban Laeng",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "บ้านเสด็จ",
              subdistrictNameEn: "Ban Sadet",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "พิชัย",
              subdistrictNameEn: "Phichai",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "ทุ่งฝาย",
              subdistrictNameEn: "Thung Fai",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "บ้านเอื้อม",
              subdistrictNameEn: "Ban Ueam",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "บ้านเป้า",
              subdistrictNameEn: "Ban Pao",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "บ้านค่า",
              subdistrictNameEn: "Ban Kha",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "บ่อแฮ้ว",
              subdistrictNameEn: "Bo Haeo",
              postalCode: 52100,
            },
            {
              subdistrictNameTh: "ต้นธงชัย",
              subdistrictNameEn: "Ton Thong Chai",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "นิคมพัฒนา",
              subdistrictNameEn: "Nikhom Phatthana",
              postalCode: 52000,
            },
            {
              subdistrictNameTh: "บุญนาคพัฒนา",
              subdistrictNameEn: "Bunnak Phatthana",
              postalCode: 52000,
            },
          ],
        },
        {
          districtNameTh: "แม่เมาะ",
          districtNameEn: "Mae Mo",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านดง",
              subdistrictNameEn: "Ban Dong",
              postalCode: 52220,
            },
            {
              subdistrictNameTh: "นาสัก",
              subdistrictNameEn: "Na Sak",
              postalCode: 52220,
            },
            {
              subdistrictNameTh: "จางเหนือ",
              subdistrictNameEn: "Chang Nuea",
              postalCode: 52220,
            },
            {
              subdistrictNameTh: "แม่เมาะ",
              subdistrictNameEn: "Mae Mo",
              postalCode: 52220,
            },
            {
              subdistrictNameTh: "สบป้าด",
              subdistrictNameEn: "Sop Pat",
              postalCode: 52220,
            },
          ],
        },
        {
          districtNameTh: "เกาะคา",
          districtNameEn: "Ko Kha",
          subdistricts: [
            {
              subdistrictNameTh: "ลำปางหลวง",
              subdistrictNameEn: "Lampang Luang",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "นาแก้ว",
              subdistrictNameEn: "Na Kaeo",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "ไหล่หิน",
              subdistrictNameEn: "Lai Hin",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "วังพร้าว",
              subdistrictNameEn: "Wang Phrao",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "ศาลา",
              subdistrictNameEn: "Sala",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "เกาะคา",
              subdistrictNameEn: "Ko Kha",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "นาแส่ง",
              subdistrictNameEn: "Na Saeng",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "ท่าผา",
              subdistrictNameEn: "Tha Pha",
              postalCode: 52130,
            },
            {
              subdistrictNameTh: "ใหม่พัฒนา",
              subdistrictNameEn: "Mai Phatthana",
              postalCode: 52130,
            },
          ],
        },
        {
          districtNameTh: "เสริมงาม",
          districtNameEn: "Soem Ngam",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งงาม",
              subdistrictNameEn: "Thung Ngam",
              postalCode: 52210,
            },
            {
              subdistrictNameTh: "เสริมขวา",
              subdistrictNameEn: "Soem Khwa",
              postalCode: 52210,
            },
            {
              subdistrictNameTh: "เสริมซ้าย",
              subdistrictNameEn: "Soem Sai",
              postalCode: 52210,
            },
            {
              subdistrictNameTh: "เสริมกลาง",
              subdistrictNameEn: "Soem Klang",
              postalCode: 52210,
            },
          ],
        },
        {
          districtNameTh: "งาว",
          districtNameEn: "Ngao",
          subdistricts: [
            {
              subdistrictNameTh: "หลวงเหนือ",
              subdistrictNameEn: "Luang Nuea",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "หลวงใต้",
              subdistrictNameEn: "Luang Tai",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "บ้านโป่ง",
              subdistrictNameEn: "Ban Pong",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "บ้านร้อง",
              subdistrictNameEn: "Ban Rong",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "ปงเตา",
              subdistrictNameEn: "Pong Tao",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "นาแก",
              subdistrictNameEn: "Na Kae",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "บ้านอ้อน",
              subdistrictNameEn: "Ban On",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "บ้านแหง",
              subdistrictNameEn: "Ban Haeng",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "บ้านหวด",
              subdistrictNameEn: "Ban Huat",
              postalCode: 52110,
            },
            {
              subdistrictNameTh: "แม่ตีบ",
              subdistrictNameEn: "Mae Tip",
              postalCode: 52110,
            },
          ],
        },
        {
          districtNameTh: "แจ้ห่ม",
          districtNameEn: "Chae Hom",
          subdistricts: [
            {
              subdistrictNameTh: "แจ้ห่ม",
              subdistrictNameEn: "Chae Hom",
              postalCode: 52120,
            },
            {
              subdistrictNameTh: "บ้านสา",
              subdistrictNameEn: "Ban Sa",
              postalCode: 52120,
            },
            {
              subdistrictNameTh: "ปงดอน",
              subdistrictNameEn: "Pong Don",
              postalCode: 52120,
            },
            {
              subdistrictNameTh: "แม่สุก",
              subdistrictNameEn: "Mae Suk",
              postalCode: 52120,
            },
            {
              subdistrictNameTh: "เมืองมาย",
              subdistrictNameEn: "Mueang Mai",
              postalCode: 52120,
            },
            {
              subdistrictNameTh: "ทุ่งผึ้ง",
              subdistrictNameEn: "Thung Phueng",
              postalCode: 52120,
            },
            {
              subdistrictNameTh: "วิเชตนคร",
              subdistrictNameEn: "Wichet Nakhon",
              postalCode: 52120,
            },
          ],
        },
        {
          districtNameTh: "วังเหนือ",
          districtNameEn: "Wang Nuea",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งฮั้ว",
              subdistrictNameEn: "Thung Hua",
              postalCode: 52140,
            },
            {
              subdistrictNameTh: "วังเหนือ",
              subdistrictNameEn: "Wang Nuea",
              postalCode: 52140,
            },
            {
              subdistrictNameTh: "วังใต้",
              subdistrictNameEn: "Wang Tai",
              postalCode: 52140,
            },
            {
              subdistrictNameTh: "ร่องเคาะ",
              subdistrictNameEn: "Rong Kho",
              postalCode: 52140,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 52140,
            },
            {
              subdistrictNameTh: "วังซ้าย",
              subdistrictNameEn: "Wang Sai",
              postalCode: 52140,
            },
            {
              subdistrictNameTh: "วังแก้ว",
              subdistrictNameEn: "Wang Kaeo",
              postalCode: 52140,
            },
            {
              subdistrictNameTh: "วังทรายคำ",
              subdistrictNameEn: "Wang Sai Thong",
              postalCode: 52140,
            },
          ],
        },
        {
          districtNameTh: "เถิน",
          districtNameEn: "Thoen",
          subdistricts: [
            {
              subdistrictNameTh: "ล้อมแรด",
              subdistrictNameEn: "Lom Raet",
              postalCode: 52160,
            },
            {
              subdistrictNameTh: "แม่วะ",
              subdistrictNameEn: "Mae Wa",
              postalCode: 52230,
            },
            {
              subdistrictNameTh: "แม่ปะ",
              subdistrictNameEn: "Mae Pa",
              postalCode: 52160,
            },
            {
              subdistrictNameTh: "แม่มอก",
              subdistrictNameEn: "Mae Mok",
              postalCode: 52160,
            },
            {
              subdistrictNameTh: "เวียงมอก",
              subdistrictNameEn: "Wiang Mok",
              postalCode: 52160,
            },
            {
              subdistrictNameTh: "นาโป่ง",
              subdistrictNameEn: "Na Pong",
              postalCode: 52160,
            },
            {
              subdistrictNameTh: "แม่ถอด",
              subdistrictNameEn: "Mae Thot",
              postalCode: 52160,
            },
            {
              subdistrictNameTh: "เถินบุรี",
              subdistrictNameEn: "Thoen Buri",
              postalCode: 52160,
            },
          ],
        },
        {
          districtNameTh: "แม่พริก",
          districtNameEn: "Mae Phrik",
          subdistricts: [
            {
              subdistrictNameTh: "แม่พริก",
              subdistrictNameEn: "Mae Phrik",
              postalCode: 52180,
            },
            {
              subdistrictNameTh: "ผาปัง",
              subdistrictNameEn: "Pha Pang",
              postalCode: 52180,
            },
            {
              subdistrictNameTh: "แม่ปุ",
              subdistrictNameEn: "Mae Pu",
              postalCode: 52180,
            },
            {
              subdistrictNameTh: "พระบาทวังตวง",
              subdistrictNameEn: "Phrabat Wang Tuang",
              postalCode: 52180,
            },
          ],
        },
        {
          districtNameTh: "แม่ทะ",
          districtNameEn: "Mae Tha",
          subdistricts: [
            {
              subdistrictNameTh: "แม่ทะ",
              subdistrictNameEn: "Mae Tha",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "นาครัว",
              subdistrictNameEn: "Na Khrua",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "ป่าตัน",
              subdistrictNameEn: "Pa Tan",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "บ้านกิ่ว",
              subdistrictNameEn: "Ban Kio",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "บ้านบอม",
              subdistrictNameEn: "Ban Bom",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "น้ำโจ้",
              subdistrictNameEn: "Nam Cho",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "ดอนไฟ",
              subdistrictNameEn: "Don Fai",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "หัวเสือ",
              subdistrictNameEn: "Hua Suea",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "วังเงิน",
              subdistrictNameEn: "Wang Ngoen",
              postalCode: 52150,
            },
            {
              subdistrictNameTh: "สันดอนแก้ว",
              subdistrictNameEn: "San Don Kaeo",
              postalCode: 52150,
            },
          ],
        },
        {
          districtNameTh: "สบปราบ",
          districtNameEn: "Sop Prap",
          subdistricts: [
            {
              subdistrictNameTh: "สบปราบ",
              subdistrictNameEn: "Sop Prap",
              postalCode: 52170,
            },
            {
              subdistrictNameTh: "สมัย",
              subdistrictNameEn: "Samai",
              postalCode: 52170,
            },
            {
              subdistrictNameTh: "แม่กัวะ",
              subdistrictNameEn: "Mae Kua",
              postalCode: 52170,
            },
            {
              subdistrictNameTh: "นายาง",
              subdistrictNameEn: "Na Yang",
              postalCode: 52170,
            },
          ],
        },
        {
          districtNameTh: "ห้างฉัตร",
          districtNameEn: "Hang Chat",
          subdistricts: [
            {
              subdistrictNameTh: "ห้างฉัตร",
              subdistrictNameEn: "Hang Chat",
              postalCode: 52190,
            },
            {
              subdistrictNameTh: "หนองหล่ม",
              subdistrictNameEn: "Nong Lom",
              postalCode: 52190,
            },
            {
              subdistrictNameTh: "เมืองยาว",
              subdistrictNameEn: "Mueang Yao",
              postalCode: 52190,
            },
            {
              subdistrictNameTh: "ปงยางคก",
              subdistrictNameEn: "Pong Yang Khok",
              postalCode: 52190,
            },
            {
              subdistrictNameTh: "เวียงตาล",
              subdistrictNameEn: "Wiang Tan",
              postalCode: 52190,
            },
            {
              subdistrictNameTh: "แม่สัน",
              subdistrictNameEn: "Mae San",
              postalCode: 52190,
            },
            {
              subdistrictNameTh: "วอแก้ว",
              subdistrictNameEn: "Wo Kaeo",
              postalCode: 52190,
            },
          ],
        },
        {
          districtNameTh: "เมืองปาน",
          districtNameEn: "Mueang Pan",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองปาน",
              subdistrictNameEn: "Mueang Pan",
              postalCode: 52240,
            },
            {
              subdistrictNameTh: "บ้านขอ",
              subdistrictNameEn: "Ban Kho",
              postalCode: 52240,
            },
            {
              subdistrictNameTh: "ทุ่งกว๋าว",
              subdistrictNameEn: "Thung Khwao",
              postalCode: 52240,
            },
            {
              subdistrictNameTh: "แจ้ซ้อน",
              subdistrictNameEn: "Chae Son",
              postalCode: 52240,
            },
            {
              subdistrictNameTh: "หัวเมือง",
              subdistrictNameEn: "Hua Mueang",
              postalCode: 52240,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "อุตรดิตถ์",
      stateNameEn: "Uttaradit",
      districts: [
        {
          districtNameTh: "เมืองอุตรดิตถ์",
          districtNameEn: "Mueang Uttaradit",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าอิฐ",
              subdistrictNameEn: "Tha It",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "ท่าเสา",
              subdistrictNameEn: "Tha Sao",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "บ้านเกาะ",
              subdistrictNameEn: "Ban Ko",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "ป่าเซ่า",
              subdistrictNameEn: "Pa Sao",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "คุ้งตะเภา",
              subdistrictNameEn: "Khung Taphao",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "วังกะพี้",
              subdistrictNameEn: "Wang Kaphi",
              postalCode: 53170,
            },
            {
              subdistrictNameTh: "หาดกรวด",
              subdistrictNameEn: "Hat Kruat",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "น้ำริด",
              subdistrictNameEn: "Nam Rit",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "งิ้วงาม",
              subdistrictNameEn: "Ngio Ngam",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "บ้านด่านนาขาม",
              subdistrictNameEn: "Ban Dan Na Kham",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "บ้านด่าน",
              subdistrictNameEn: "Ban Dan",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "ผาจุก",
              subdistrictNameEn: "Pha Chuk",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "วังดิน",
              subdistrictNameEn: "Wang Din",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "แสนตอ",
              subdistrictNameEn: "Saen To",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "หาดงิ้ว",
              subdistrictNameEn: "Hat Ngio",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "ขุนฝาง",
              subdistrictNameEn: "Khun Fang",
              postalCode: 53000,
            },
            {
              subdistrictNameTh: "ถ้ำฉลอง",
              subdistrictNameEn: "Tham Chalong",
              postalCode: 53000,
            },
          ],
        },
        {
          districtNameTh: "ตรอน",
          districtNameEn: "Tron",
          subdistricts: [
            {
              subdistrictNameTh: "วังแดง",
              subdistrictNameEn: "Wang Daeng",
              postalCode: 53140,
            },
            {
              subdistrictNameTh: "บ้านแก่ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 53140,
            },
            {
              subdistrictNameTh: "หาดสองแคว",
              subdistrictNameEn: "Hat Song Khwae",
              postalCode: 53140,
            },
            {
              subdistrictNameTh: "น้ำอ่าง",
              subdistrictNameEn: "Nam Ang",
              postalCode: 53140,
            },
            {
              subdistrictNameTh: "ข่อยสูง",
              subdistrictNameEn: "Khoi Sung",
              postalCode: 53140,
            },
          ],
        },
        {
          districtNameTh: "ท่าปลา",
          districtNameEn: "Tha Pla",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าปลา",
              subdistrictNameEn: "Tha Pla",
              postalCode: 53150,
            },
            {
              subdistrictNameTh: "หาดล้า",
              subdistrictNameEn: "Hat La",
              postalCode: 53150,
            },
            {
              subdistrictNameTh: "ผาเลือด",
              subdistrictNameEn: "Pha Lueat",
              postalCode: 53190,
            },
            {
              subdistrictNameTh: "จริม",
              subdistrictNameEn: "Charim",
              postalCode: 53150,
            },
            {
              subdistrictNameTh: "น้ำหมัน",
              subdistrictNameEn: "Nam Man",
              postalCode: 53150,
            },
            {
              subdistrictNameTh: "นางพญา",
              subdistrictNameEn: "Nang Phaya",
              postalCode: 53150,
            },
            {
              subdistrictNameTh: "ร่วมจิต",
              subdistrictNameEn: "Ruam Chit",
              postalCode: 53190,
            },
          ],
        },
        {
          districtNameTh: "น้ำปาด",
          districtNameEn: "Nam Pat",
          subdistricts: [
            {
              subdistrictNameTh: "แสนตอ",
              subdistrictNameEn: "Saen To",
              postalCode: 53110,
            },
            {
              subdistrictNameTh: "บ้านฝาย",
              subdistrictNameEn: "Ban Fai",
              postalCode: 53110,
            },
            {
              subdistrictNameTh: "เด่นเหล็ก",
              subdistrictNameEn: "Den Lek",
              postalCode: 53110,
            },
            {
              subdistrictNameTh: "น้ำไคร้",
              subdistrictNameEn: "Nam Khrai",
              postalCode: 53110,
            },
            {
              subdistrictNameTh: "น้ำไผ่",
              subdistrictNameEn: "Nam Phai",
              postalCode: 53110,
            },
            {
              subdistrictNameTh: "ห้วยมุ่น",
              subdistrictNameEn: "Huai Mun",
              postalCode: 53110,
            },
            {
              subdistrictNameTh: "ท่าแฝก",
              subdistrictNameEn: "Tha Faek",
              postalCode: 53110,
            },
          ],
        },
        {
          districtNameTh: "ฟากท่า",
          districtNameEn: "Fak Tha",
          subdistricts: [
            {
              subdistrictNameTh: "ฟากท่า",
              subdistrictNameEn: "Fak Tha",
              postalCode: 53160,
            },
            {
              subdistrictNameTh: "สองคอน",
              subdistrictNameEn: "Song Khon",
              postalCode: 53160,
            },
            {
              subdistrictNameTh: "บ้านเสี้ยว",
              subdistrictNameEn: "Ban Siao",
              postalCode: 53160,
            },
            {
              subdistrictNameTh: "สองห้อง",
              subdistrictNameEn: "Song Hong",
              postalCode: 53160,
            },
          ],
        },
        {
          districtNameTh: "บ้านโคก",
          districtNameEn: "Ban Khok",
          subdistricts: [
            {
              subdistrictNameTh: "ม่วงเจ็ดต้น",
              subdistrictNameEn: "Muang Chet Ton",
              postalCode: 53180,
            },
            {
              subdistrictNameTh: "บ้านโคก",
              subdistrictNameEn: "Ban Khok",
              postalCode: 53180,
            },
            {
              subdistrictNameTh: "นาขุม",
              subdistrictNameEn: "Na Khum",
              postalCode: 53180,
            },
            {
              subdistrictNameTh: "บ่อเบี้ย",
              subdistrictNameEn: "Bo Bia",
              postalCode: 53180,
            },
          ],
        },
        {
          districtNameTh: "พิชัย",
          districtNameEn: "Phichai",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "บ้านดารา",
              subdistrictNameEn: "Ban Dara",
              postalCode: 53220,
            },
            {
              subdistrictNameTh: "ไร่อ้อย",
              subdistrictNameEn: "Rai Oi",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "ท่าสัก",
              subdistrictNameEn: "Tha Sak",
              postalCode: 53220,
            },
            {
              subdistrictNameTh: "คอรุม",
              subdistrictNameEn: "Kho Rum",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "บ้านหม้อ",
              subdistrictNameEn: "Ban Mo",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "ท่ามะเฟือง",
              subdistrictNameEn: "Tha Mafueang",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "บ้านโคน",
              subdistrictNameEn: "Ban Khon",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "พญาแมน",
              subdistrictNameEn: "Phaya Maen",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "นาอิน",
              subdistrictNameEn: "Na In",
              postalCode: 53120,
            },
            {
              subdistrictNameTh: "นายาง",
              subdistrictNameEn: "Na Yang",
              postalCode: 53120,
            },
          ],
        },
        {
          districtNameTh: "ลับแล",
          districtNameEn: "Laplae",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีพนมมาศ",
              subdistrictNameEn: "Si Phanom Mat",
              postalCode: 53130,
            },
            {
              subdistrictNameTh: "แม่พูล",
              subdistrictNameEn: "Mae Phun",
              postalCode: 53130,
            },
            {
              subdistrictNameTh: "นานกกก",
              subdistrictNameEn: "Na Nok Kok",
              postalCode: 53130,
            },
            {
              subdistrictNameTh: "ฝายหลวง",
              subdistrictNameEn: "Fai Luang",
              postalCode: 53130,
            },
            {
              subdistrictNameTh: "ชัยจุมพล",
              subdistrictNameEn: "Chai Chumphon",
              postalCode: 53130,
            },
            {
              subdistrictNameTh: "ไผ่ล้อม",
              subdistrictNameEn: "Phai Lom",
              postalCode: 53210,
            },
            {
              subdistrictNameTh: "ทุ่งยั้ง",
              subdistrictNameEn: "Thung Yang",
              postalCode: 53210,
            },
            {
              subdistrictNameTh: "ด่านแม่คำมัน",
              subdistrictNameEn: "Dan Mae Kham Man",
              postalCode: 53210,
            },
          ],
        },
        {
          districtNameTh: "ทองแสนขัน",
          districtNameEn: "Thong Saen Khan",
          subdistricts: [
            {
              subdistrictNameTh: "ผักขวง",
              subdistrictNameEn: "Phak Khuang",
              postalCode: 53230,
            },
            {
              subdistrictNameTh: "บ่อทอง",
              subdistrictNameEn: "Bo Thong",
              postalCode: 53230,
            },
            {
              subdistrictNameTh: "ป่าคาย",
              subdistrictNameEn: "Pa Khai",
              postalCode: 53230,
            },
            {
              subdistrictNameTh: "น้ำพี้",
              subdistrictNameEn: "Namphi",
              postalCode: 53230,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "แพร่",
      stateNameEn: "Phrae",
      districts: [
        {
          districtNameTh: "เมืองแพร่",
          districtNameEn: "Mueang Phrae",
          subdistricts: [
            {
              subdistrictNameTh: "ในเวียง",
              subdistrictNameEn: "Nai Wiang",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "นาจักร",
              subdistrictNameEn: "Na Chak",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "น้ำชำ",
              subdistrictNameEn: "Nam Cham",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ป่าแดง",
              subdistrictNameEn: "Pa Daeng",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ทุ่งโฮ้ง",
              subdistrictNameEn: "Thung Hong",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "เหมืองหม้อ",
              subdistrictNameEn: "Mueang Mo",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "วังธง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "แม่หล่าย",
              subdistrictNameEn: "Mae Lai",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ห้วยม้า",
              subdistrictNameEn: "Huai Ma",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ป่าแมต",
              subdistrictNameEn: "Pa Maet",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "บ้านถิ่น",
              subdistrictNameEn: "Ban Thin",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "สวนเขื่อน",
              subdistrictNameEn: "Suan Khuean",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "วังหงส์",
              subdistrictNameEn: "Wang Hong",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "แม่คำมี",
              subdistrictNameEn: "Mae Kham Mi",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ทุ่งกวาว",
              subdistrictNameEn: "Thung Kwao",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "แม่ยม",
              subdistrictNameEn: "Mae Yom",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ช่อแฮ",
              subdistrictNameEn: "Cho Hae",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "ร่องฟอง",
              subdistrictNameEn: "Rong Fong",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "กาญจนา",
              subdistrictNameEn: "Kanchana",
              postalCode: 54000,
            },
          ],
        },
        {
          districtNameTh: "ร้องกวาง",
          districtNameEn: "Mueang Phrae",
          subdistricts: [
            {
              subdistrictNameTh: "ร้องกวาง",
              subdistrictNameEn: "Rong Kwang",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "ร้องเข็ม",
              subdistrictNameEn: "Si Narong",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "น้ำเลา",
              subdistrictNameEn: "Nam Lao",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "บ้านเวียง",
              subdistrictNameEn: "Ban Wiang",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "ทุ่งศรี",
              subdistrictNameEn: "Rong Khem",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "แม่ยางตาล",
              subdistrictNameEn: "Mae Yang Tan",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "แม่ยางฮ่อ",
              subdistrictNameEn: "Mae Yang Ho",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "ไผ่โทน",
              subdistrictNameEn: "Phai Thon",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "ห้วยโรง",
              subdistrictNameEn: "Huai Rong",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "แม่ทราย",
              subdistrictNameEn: "Mae Sai",
              postalCode: 54140,
            },
            {
              subdistrictNameTh: "แม่ยางร้อง",
              subdistrictNameEn: "Mae Yang Rong",
              postalCode: 54140,
            },
          ],
        },
        {
          districtNameTh: "ลอง",
          districtNameEn: "Long",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยอ้อ",
              subdistrictNameEn: "Huai O",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "บ้านปิน",
              subdistrictNameEn: "Ban Pin",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "ต้าผามอก",
              subdistrictNameEn: "Ta Pha Mok",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "เวียงต้า",
              subdistrictNameEn: "Wiang Ta",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "ปากกาง",
              subdistrictNameEn: "Pak Kang",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "หัวทุ่ง",
              subdistrictNameEn: "Hua Thung",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "ทุ่งแล้ง",
              subdistrictNameEn: "Thung Laeng",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "บ่อเหล็กลอง",
              subdistrictNameEn: "Bo Lek Long",
              postalCode: 54150,
            },
            {
              subdistrictNameTh: "แม่ปาน",
              subdistrictNameEn: "Mae Pan",
              postalCode: 54150,
            },
          ],
        },
        {
          districtNameTh: "สูงเม่น",
          districtNameEn: "Sung Men",
          subdistricts: [
            {
              subdistrictNameTh: "สูงเม่น",
              subdistrictNameEn: "Sung Men",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "น้ำชำ",
              subdistrictNameEn: "Nam Cham",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "หัวฝาย",
              subdistrictNameEn: "Hua Fai",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "ดอนมูล",
              subdistrictNameEn: "Don Mun",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "บ้านเหล่า",
              subdistrictNameEn: "Ban Lao",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "บ้านกวาง",
              subdistrictNameEn: "Ban Kwang",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "บ้านปง",
              subdistrictNameEn: "Ban Pong",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "บ้านกาศ",
              subdistrictNameEn: "Ban Kat",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "ร่องกาศ",
              subdistrictNameEn: "Rong Kat",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "สบสาย",
              subdistrictNameEn: "Sop Sai",
              postalCode: 54130,
            },
            {
              subdistrictNameTh: "เวียงทอง",
              subdistrictNameEn: "Wiang Thong",
              postalCode: 54000,
            },
            {
              subdistrictNameTh: "พระหลวง",
              subdistrictNameEn: "Phra Luang",
              postalCode: 54130,
            },
          ],
        },
        {
          districtNameTh: "เด่นชัย",
          districtNameEn: "Den Chai",
          subdistricts: [
            {
              subdistrictNameTh: "เด่นชัย",
              subdistrictNameEn: "Den Chai",
              postalCode: 54110,
            },
            {
              subdistrictNameTh: "แม่จั๊วะ",
              subdistrictNameEn: "Mae Chua",
              postalCode: 54110,
            },
            {
              subdistrictNameTh: "ไทรย้อย",
              subdistrictNameEn: "Sai Yoi",
              postalCode: 54110,
            },
            {
              subdistrictNameTh: "ห้วยไร่",
              subdistrictNameEn: "Huai Rai",
              postalCode: 54110,
            },
            {
              subdistrictNameTh: "ปงป่าหวาย",
              subdistrictNameEn: "Pong Pa Wai",
              postalCode: 54110,
            },
          ],
        },
        {
          districtNameTh: "สอง",
          districtNameEn: "Song",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านหนุน",
              subdistrictNameEn: "Ban Nun",
              postalCode: 54120,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 54120,
            },
            {
              subdistrictNameTh: "ห้วยหม้าย",
              subdistrictNameEn: "Huai Mai",
              postalCode: 54120,
            },
            {
              subdistrictNameTh: "เตาปูน",
              subdistrictNameEn: "Tao Pun",
              postalCode: 54120,
            },
            {
              subdistrictNameTh: "หัวเมือง",
              subdistrictNameEn: "Hua Mueang",
              postalCode: 54120,
            },
            {
              subdistrictNameTh: "สะเอียบ",
              subdistrictNameEn: "Sa-Iap",
              postalCode: 54120,
            },
            {
              subdistrictNameTh: "แดนชุมพล",
              subdistrictNameEn: "Daen Chumphon",
              postalCode: 54120,
            },
            {
              subdistrictNameTh: "ทุ่งน้าว",
              subdistrictNameEn: "Thung Nao",
              postalCode: 54120,
            },
          ],
        },
        {
          districtNameTh: "วังชิ้น",
          districtNameEn: "Wang Chin",
          subdistricts: [
            {
              subdistrictNameTh: "วังชิ้น",
              subdistrictNameEn: "Wang Chin",
              postalCode: 54160,
            },
            {
              subdistrictNameTh: "สรอย",
              subdistrictNameEn: "Soi",
              postalCode: 54160,
            },
            {
              subdistrictNameTh: "แม่ป้าก",
              subdistrictNameEn: "Mae Pak",
              postalCode: 54160,
            },
            {
              subdistrictNameTh: "นาพูน",
              subdistrictNameEn: "Na Phun",
              postalCode: 54160,
            },
            {
              subdistrictNameTh: "แม่พุง",
              subdistrictNameEn: "Mae Phung",
              postalCode: 54160,
            },
            {
              subdistrictNameTh: "ป่าสัก",
              subdistrictNameEn: "Pa Sak",
              postalCode: 54160,
            },
            {
              subdistrictNameTh: "แม่เกิ๋ง",
              subdistrictNameEn: "Mae Koeng",
              postalCode: 54160,
            },
          ],
        },
        {
          districtNameTh: "หนองม่วงไข่",
          districtNameEn: "Nong Muang Khai",
          subdistricts: [
            {
              subdistrictNameTh: "แม่คำมี",
              subdistrictNameEn: "Mae Kham Mi",
              postalCode: 54170,
            },
            {
              subdistrictNameTh: "หนองม่วงไข่",
              subdistrictNameEn: "Nong Muang Khai",
              postalCode: 54170,
            },
            {
              subdistrictNameTh: "น้ำรัด",
              subdistrictNameEn: "Nam Rat",
              postalCode: 54170,
            },
            {
              subdistrictNameTh: "วังหลวง",
              subdistrictNameEn: "Wang Luang",
              postalCode: 54170,
            },
            {
              subdistrictNameTh: "ตำหนักธรรม",
              subdistrictNameEn: "Tamnak Tham",
              postalCode: 54170,
            },
            {
              subdistrictNameTh: "ทุ่งแค้ว",
              subdistrictNameEn: "Thung Khaeo",
              postalCode: 54170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "น่าน",
      stateNameEn: "Nan",
      districts: [
        {
          districtNameTh: "เมืองน่าน",
          districtNameEn: "Mueang Nan",
          subdistricts: [
            {
              subdistrictNameTh: "ในเวียง",
              subdistrictNameEn: "Nai Wiang",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "บ่อ",
              subdistrictNameEn: "Bo",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "ผาสิงห์",
              subdistrictNameEn: "Pha Sing",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "ไชยสถาน",
              subdistrictNameEn: "Chaiya Sathan",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "ถืมตอง",
              subdistrictNameEn: "Thuem Thong",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "เรือง",
              subdistrictNameEn: "Rueang",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "นาซาว",
              subdistrictNameEn: "Na Sao",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "ดู่ใต้",
              subdistrictNameEn: "Du Tai",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "กองควาย",
              subdistrictNameEn: "Kong Khwai",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "บ่อสวก",
              subdistrictNameEn: "Suak",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "สะเนียน",
              subdistrictNameEn: "Sanian",
              postalCode: 55000,
            },
          ],
        },
        {
          districtNameTh: "แม่จริม",
          districtNameEn: "Mae Charim",
          subdistricts: [
            {
              subdistrictNameTh: "หนองแดง",
              subdistrictNameEn: "Nong Daeng",
              postalCode: 55170,
            },
            {
              subdistrictNameTh: "หมอเมือง",
              subdistrictNameEn: "Mo Mueang",
              postalCode: 55170,
            },
            {
              subdistrictNameTh: "น้ำพาง",
              subdistrictNameEn: "Nam Phang",
              postalCode: 55170,
            },
            {
              subdistrictNameTh: "น้ำปาย",
              subdistrictNameEn: "Nam Pai",
              postalCode: 55170,
            },
            {
              subdistrictNameTh: "แม่จริม",
              subdistrictNameEn: "Mae Charim",
              postalCode: 55170,
            },
          ],
        },
        {
          districtNameTh: "บ้านหลวง",
          districtNameEn: "Ban Luang",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านฟ้า",
              subdistrictNameEn: "Ban Fa",
              postalCode: 55190,
            },
            {
              subdistrictNameTh: "ป่าคาหลวง",
              subdistrictNameEn: "Pa Kha Luang",
              postalCode: 55190,
            },
            {
              subdistrictNameTh: "สวด",
              subdistrictNameEn: "Suat",
              postalCode: 55190,
            },
            {
              subdistrictNameTh: "บ้านพี้",
              subdistrictNameEn: "Ban Phi",
              postalCode: 55190,
            },
          ],
        },
        {
          districtNameTh: "นาน้อย",
          districtNameEn: "Na Noi",
          subdistricts: [
            {
              subdistrictNameTh: "นาน้อย",
              subdistrictNameEn: "Na Noi",
              postalCode: 55150,
            },
            {
              subdistrictNameTh: "เชียงของ",
              subdistrictNameEn: "Chiang Khong",
              postalCode: 55150,
            },
            {
              subdistrictNameTh: "ศรีษะเกษ",
              subdistrictNameEn: "Sisa Ket",
              postalCode: 55150,
            },
            {
              subdistrictNameTh: "สถาน",
              subdistrictNameEn: "Sathan",
              postalCode: 55150,
            },
            {
              subdistrictNameTh: "สันทะ",
              subdistrictNameEn: "Santha",
              postalCode: 55150,
            },
            {
              subdistrictNameTh: "บัวใหญ่",
              subdistrictNameEn: "Bua Yai",
              postalCode: 55150,
            },
            {
              subdistrictNameTh: "น้ำตก",
              subdistrictNameEn: "Namtok",
              postalCode: 55150,
            },
          ],
        },
        {
          districtNameTh: "ปัว",
          districtNameEn: "Pua",
          subdistricts: [
            {
              subdistrictNameTh: "ปัว",
              subdistrictNameEn: "Pua",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "แงง",
              subdistrictNameEn: "Ngaeng",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "สถาน",
              subdistrictNameEn: "Sathan",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "ศิลาแลง",
              subdistrictNameEn: "Sila Laeng",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "ศิลาเพชร",
              subdistrictNameEn: "Sila Phet",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "อวน",
              subdistrictNameEn: "Ouan",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "ไชยวัฒนา",
              subdistrictNameEn: "Chai Watthana",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "เจดีย์ชัย",
              subdistrictNameEn: "Chedi Chai",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "ภูคา",
              subdistrictNameEn: "Phu Kha",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "สกาด",
              subdistrictNameEn: "Sakat",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "ป่ากลาง",
              subdistrictNameEn: "Pa Klang",
              postalCode: 55120,
            },
            {
              subdistrictNameTh: "วรนคร",
              subdistrictNameEn: "Wara Nakhon",
              postalCode: 55120,
            },
          ],
        },
        {
          districtNameTh: "ท่าวังผา",
          districtNameEn: "Tha Wang Pha",
          subdistricts: [
            {
              subdistrictNameTh: "ริม",
              subdistrictNameEn: "Rim",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "ป่าคา",
              subdistrictNameEn: "Pa Kha",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "ผาตอ",
              subdistrictNameEn: "Pha To",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "ยม",
              subdistrictNameEn: "Yom",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "ตาลชุม",
              subdistrictNameEn: "Tan Chum",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "ศรีภูมิ",
              subdistrictNameEn: "Si Phum",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "จอมพระ",
              subdistrictNameEn: "Chom Phra",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "แสนทอง",
              subdistrictNameEn: "Saen Thong",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "ท่าวังผา",
              subdistrictNameEn: "Tha Wang Pha",
              postalCode: 55140,
            },
            {
              subdistrictNameTh: "ผาทอง",
              subdistrictNameEn: "Pha Thong",
              postalCode: 55140,
            },
          ],
        },
        {
          districtNameTh: "เวียงสา",
          districtNameEn: "Wiang Sa",
          subdistricts: [
            {
              subdistrictNameTh: "กลางเวียง",
              subdistrictNameEn: "Klang Wiang",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ขึ่ง",
              subdistrictNameEn: "Khueng",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ไหล่น่าน",
              subdistrictNameEn: "Lai Nan",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ตาลชุม",
              subdistrictNameEn: "Tan Chum",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "นาเหลือง",
              subdistrictNameEn: "Na Lueang",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ส้าน",
              subdistrictNameEn: "San",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "น้ำมวบ",
              subdistrictNameEn: "Nam Muap",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "น้ำปั้ว",
              subdistrictNameEn: "Nam Pua",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ยาบหัวนา",
              subdistrictNameEn: "Yap Hua Na",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ปงสนุก",
              subdistrictNameEn: "Pong Sanuk",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "อ่ายนาไลย",
              subdistrictNameEn: "I Na Lai",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ส้านนาหนองใหม่",
              subdistrictNameEn: "Sanna Nong Mai",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "แม่ขะนิง",
              subdistrictNameEn: "Mae Khaning",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "แม่สาคร",
              subdistrictNameEn: "Mae Sakhon",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "จอมจันทร์",
              subdistrictNameEn: "Chom Chan",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "แม่สา",
              subdistrictNameEn: "Mae Sa",
              postalCode: 55110,
            },
            {
              subdistrictNameTh: "ทุ่งศรีทอง",
              subdistrictNameEn: "Thung Si Thong",
              postalCode: 55110,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งช้าง",
          districtNameEn: "Thung Chang",
          subdistricts: [
            {
              subdistrictNameTh: "ปอน",
              subdistrictNameEn: "Pon",
              postalCode: 55130,
            },
            {
              subdistrictNameTh: "งอบ",
              subdistrictNameEn: "Ngop",
              postalCode: 55130,
            },
            {
              subdistrictNameTh: "และ",
              subdistrictNameEn: "Lae",
              postalCode: 55130,
            },
            {
              subdistrictNameTh: "ทุ่งช้าง",
              subdistrictNameEn: "Thung Chang",
              postalCode: 55130,
            },
          ],
        },
        {
          districtNameTh: "เชียงกลาง",
          districtNameEn: "Chiang Klang",
          subdistricts: [
            {
              subdistrictNameTh: "เชียงกลาง",
              subdistrictNameEn: "Chiang Klang",
              postalCode: 55160,
            },
            {
              subdistrictNameTh: "เปือ",
              subdistrictNameEn: "Puea",
              postalCode: 55160,
            },
            {
              subdistrictNameTh: "เชียงคาน",
              subdistrictNameEn: "Chiang Khan",
              postalCode: 55160,
            },
            {
              subdistrictNameTh: "พระธาตุ",
              subdistrictNameEn: "Phrathat",
              postalCode: 55160,
            },
            {
              subdistrictNameTh: "พญาแก้ว",
              subdistrictNameEn: "Phaya Kaeo",
              postalCode: 55160,
            },
            {
              subdistrictNameTh: "พระพุทธบาท",
              subdistrictNameEn: "Phraphutthabat",
              postalCode: 55160,
            },
          ],
        },
        {
          districtNameTh: "นาหมื่น",
          districtNameEn: "Na Muen",
          subdistricts: [
            {
              subdistrictNameTh: "นาทะนุง",
              subdistrictNameEn: "Na Thanung",
              postalCode: 55180,
            },
            {
              subdistrictNameTh: "บ่อแก้ว",
              subdistrictNameEn: "Bo Kaeo",
              postalCode: 55180,
            },
            {
              subdistrictNameTh: "เมืองลี",
              subdistrictNameEn: "Mueang Li",
              postalCode: 55180,
            },
            {
              subdistrictNameTh: "ปิงหลวง",
              subdistrictNameEn: "Ping Luang",
              postalCode: 55180,
            },
          ],
        },
        {
          districtNameTh: "สันติสุข",
          districtNameEn: "Santi Suk",
          subdistricts: [
            {
              subdistrictNameTh: "ดู่พงษ์",
              subdistrictNameEn: "Du Phong",
              postalCode: 55210,
            },
            {
              subdistrictNameTh: "ป่าแลวหลวง",
              subdistrictNameEn: "Pa Laeo Luang",
              postalCode: 55210,
            },
            {
              subdistrictNameTh: "พงษ์",
              subdistrictNameEn: "Phong",
              postalCode: 55210,
            },
          ],
        },
        {
          districtNameTh: "บ่อเกลือ",
          districtNameEn: "Bo Kluea",
          subdistricts: [
            {
              subdistrictNameTh: "บ่อเกลือเหนือ",
              subdistrictNameEn: "Bo Kluea Nuea",
              postalCode: 55220,
            },
            {
              subdistrictNameTh: "บ่อเกลือใต้",
              subdistrictNameEn: "Bo Kluea Tai",
              postalCode: 55220,
            },
            {
              subdistrictNameTh: "ภูฟ้า",
              subdistrictNameEn: "Phu Fa",
              postalCode: 55220,
            },
            {
              subdistrictNameTh: "ดงพญา",
              subdistrictNameEn: "Dong Phaya",
              postalCode: 55220,
            },
          ],
        },
        {
          districtNameTh: "สองแคว",
          districtNameEn: "Song Khwae",
          subdistricts: [
            {
              subdistrictNameTh: "นาไร่หลวง",
              subdistrictNameEn: "Na Rai Luang",
              postalCode: 55160,
            },
            {
              subdistrictNameTh: "ชนแดน",
              subdistrictNameEn: "Chon Daen",
              postalCode: 55160,
            },
            {
              subdistrictNameTh: "ยอด",
              subdistrictNameEn: "Yot",
              postalCode: 55160,
            },
          ],
        },
        {
          districtNameTh: "ภูเพียง",
          districtNameEn: "Phu Phiang",
          subdistricts: [
            {
              subdistrictNameTh: "ม่วงตึ๊ด",
              subdistrictNameEn: "Muang Tuet",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "นาปัง",
              subdistrictNameEn: "Na Pang",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "น้ำแก่น",
              subdistrictNameEn: "Nam Kaen",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "น้ำเกี๋ยน",
              subdistrictNameEn: "Nam Kian",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "เมืองจัง",
              subdistrictNameEn: "Mueang Chang",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "ท่าน้าว",
              subdistrictNameEn: "Tha Nao",
              postalCode: 55000,
            },
            {
              subdistrictNameTh: "ฝายแก้ว",
              subdistrictNameEn: "Fai Kaeo",
              postalCode: 55000,
            },
          ],
        },
        {
          districtNameTh: "เฉลิมพระเกียรติ",
          districtNameEn: "Chaloem Phra Kiet",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยโก๋น",
              subdistrictNameEn: "Huai Kon",
              postalCode: 55130,
            },
            {
              subdistrictNameTh: "ขุนน่าน",
              subdistrictNameEn: "Khun Nan",
              postalCode: 55130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "พะเยา",
      stateNameEn: "Phayao",
      districts: [
        {
          districtNameTh: "เมืองพะเยา",
          districtNameEn: "Mueang Phayao",
          subdistricts: [
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "แม่ต๋ำ",
              subdistrictNameEn: "Mae Tam",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "แม่นาเรือ",
              subdistrictNameEn: "Mae Na Ruea",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "บ้านตุ่น",
              subdistrictNameEn: "Ban Tun",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "บ้านต๊ำ",
              subdistrictNameEn: "Ban Tam",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "บ้านต๋อม",
              subdistrictNameEn: "Ban Tom",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "แม่ปืม",
              subdistrictNameEn: "Mae Puem",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "แม่กา",
              subdistrictNameEn: "Mae Ka",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "จำป่าหวาย",
              subdistrictNameEn: "Champa Wai",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "ท่าวังทอง",
              subdistrictNameEn: "Tha Wang Thong",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "แม่ใส",
              subdistrictNameEn: "Mae Sai",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "บ้านสาง",
              subdistrictNameEn: "Ban Sang",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "ท่าจำปี",
              subdistrictNameEn: "Tha Champi",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "สันป่าม่วง",
              subdistrictNameEn: "San Pa Muang",
              postalCode: 56000,
            },
          ],
        },
        {
          districtNameTh: "จุน",
          districtNameEn: "Chun",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยข้าวก่ำ",
              subdistrictNameEn: "Huai Khao Kam",
              postalCode: 56150,
            },
            {
              subdistrictNameTh: "จุน",
              subdistrictNameEn: "Chun",
              postalCode: 56150,
            },
            {
              subdistrictNameTh: "ลอ",
              subdistrictNameEn: "Lo",
              postalCode: 56150,
            },
            {
              subdistrictNameTh: "หงส์หิน",
              subdistrictNameEn: "Hong Hin",
              postalCode: 56150,
            },
            {
              subdistrictNameTh: "ทุ่งรวงทอง",
              subdistrictNameEn: "Thung Ruang Thong",
              postalCode: 56150,
            },
            {
              subdistrictNameTh: "ห้วยยางขาม",
              subdistrictNameEn: "Huai Yang Kham",
              postalCode: 56150,
            },
            {
              subdistrictNameTh: "พระธาตุขิงแกง",
              subdistrictNameEn: "Phrathat Khing Kaeng",
              postalCode: 56150,
            },
          ],
        },
        {
          districtNameTh: "เชียงคำ",
          districtNameEn: "Chiang Kham",
          subdistricts: [
            {
              subdistrictNameTh: "หย่วน",
              subdistrictNameEn: "Yuan",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "น้ำแวน",
              subdistrictNameEn: "Nam Waen",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "ฝายกวาง",
              subdistrictNameEn: "Fai Kwang",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "เจดีย์คำ",
              subdistrictNameEn: "Chedi Kham",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "ร่มเย็น",
              subdistrictNameEn: "Rom Yen",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "เชียงบาน",
              subdistrictNameEn: "Chiang Ban",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "แม่ลาว",
              subdistrictNameEn: "Mae Lao",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "อ่างทอง",
              subdistrictNameEn: "Ang Thong",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "ทุ่งผาสุข",
              subdistrictNameEn: "Thung Pha Suk",
              postalCode: 56110,
            },
          ],
        },
        {
          districtNameTh: "เชียงม่วน",
          districtNameEn: "Chiang Muan",
          subdistricts: [
            {
              subdistrictNameTh: "เชียงม่วน",
              subdistrictNameEn: "Chiang Muan",
              postalCode: 56160,
            },
            {
              subdistrictNameTh: "บ้านมาง",
              subdistrictNameEn: "Ban Mang",
              postalCode: 56160,
            },
            {
              subdistrictNameTh: "สระ",
              subdistrictNameEn: "Sa",
              postalCode: 56160,
            },
          ],
        },
        {
          districtNameTh: "ดอกคำใต้",
          districtNameEn: "Dok Khamtai",
          subdistricts: [
            {
              subdistrictNameTh: "ดอกคำใต้",
              subdistrictNameEn: "Dok Khamtai",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "ดอนศรีชุม",
              subdistrictNameEn: "Don Si Chum",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "บ้านถ้ำ",
              subdistrictNameEn: "Ban Tham",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "บ้านปิน",
              subdistrictNameEn: "Ban Pin",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "ห้วยลาน",
              subdistrictNameEn: "Huai Lan",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "สันโค้ง",
              subdistrictNameEn: "San Khong",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "ป่าซาง",
              subdistrictNameEn: "Pa Sang",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "หนองหล่ม",
              subdistrictNameEn: "Nong Lom",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "ดงสุวรรณ",
              subdistrictNameEn: "Dong Suwan",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "บุญเกิด",
              subdistrictNameEn: "Bunkoet",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "สว่างอารมณ์",
              subdistrictNameEn: "Sawang Arom",
              postalCode: 56120,
            },
            {
              subdistrictNameTh: "คือเวียง",
              subdistrictNameEn: "Khue Wiang",
              postalCode: 56120,
            },
          ],
        },
        {
          districtNameTh: "ปง",
          districtNameEn: "Pong",
          subdistricts: [
            {
              subdistrictNameTh: "ปง",
              subdistrictNameEn: "Pong",
              postalCode: 56140,
            },
            {
              subdistrictNameTh: "ควร",
              subdistrictNameEn: "Khuan",
              postalCode: 56140,
            },
            {
              subdistrictNameTh: "ออย",
              subdistrictNameEn: "Oi",
              postalCode: 56140,
            },
            {
              subdistrictNameTh: "งิม",
              subdistrictNameEn: "Ngim",
              postalCode: 56140,
            },
            {
              subdistrictNameTh: "ผาช้างน้อย",
              subdistrictNameEn: "Pha Chang Noi",
              postalCode: 56140,
            },
            {
              subdistrictNameTh: "นาปรัง",
              subdistrictNameEn: "Na Prang",
              postalCode: 56140,
            },
            {
              subdistrictNameTh: "ขุนควร",
              subdistrictNameEn: "Khun Khuan",
              postalCode: 56140,
            },
          ],
        },
        {
          districtNameTh: "แม่ใจ",
          districtNameEn: "Mae Chai",
          subdistricts: [
            {
              subdistrictNameTh: "แม่ใจ",
              subdistrictNameEn: "Mae Chai",
              postalCode: 56130,
            },
            {
              subdistrictNameTh: "ศรีถ้อย",
              subdistrictNameEn: "Si Thoi",
              postalCode: 56130,
            },
            {
              subdistrictNameTh: "แม่สุก",
              subdistrictNameEn: "Mae Suk",
              postalCode: 56130,
            },
            {
              subdistrictNameTh: "ป่าแฝก",
              subdistrictNameEn: "Pa Faek",
              postalCode: 56130,
            },
            {
              subdistrictNameTh: "บ้านเหล่า",
              subdistrictNameEn: "Ban Lao",
              postalCode: 56130,
            },
            {
              subdistrictNameTh: "เจริญราษฎร์",
              subdistrictNameEn: "Charoen Rat",
              postalCode: 56130,
            },
          ],
        },
        {
          districtNameTh: "ภูซาง",
          districtNameEn: "Phu Sang",
          subdistricts: [
            {
              subdistrictNameTh: "ภูซาง",
              subdistrictNameEn: "Phu Sang",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "ป่าสัก",
              subdistrictNameEn: "Pa Sak",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "ทุ่งกล้วย",
              subdistrictNameEn: "Thung Kluai",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "เชียงแรง",
              subdistrictNameEn: "Chiang Raeng",
              postalCode: 56110,
            },
            {
              subdistrictNameTh: "สบบง",
              subdistrictNameEn: "Sop Bong",
              postalCode: 56110,
            },
          ],
        },
        {
          districtNameTh: "ภูกามยาว",
          districtNameEn: "Phu Kamyao",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยแก้ว",
              subdistrictNameEn: "Huai Kaeo",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "ดงเจน",
              subdistrictNameEn: "Dong Chen",
              postalCode: 56000,
            },
            {
              subdistrictNameTh: "แม่อิง",
              subdistrictNameEn: "Mae Ing",
              postalCode: 56000,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "เชียงราย",
      stateNameEn: "Chiang Rai",
      districts: [
        {
          districtNameTh: "เมืองเชียงราย",
          districtNameEn: "Mueang Chiang Rai",
          subdistricts: [
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "รอบเวียง",
              subdistrictNameEn: "Rop Wiang",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "บ้านดู่",
              subdistrictNameEn: "Ban Du",
              postalCode: 57100,
            },
            {
              subdistrictNameTh: "นางแล",
              subdistrictNameEn: "Nang Lae",
              postalCode: 57100,
            },
            {
              subdistrictNameTh: "แม่ข้าวต้ม",
              subdistrictNameEn: "Mae Khao Tom",
              postalCode: 57100,
            },
            {
              subdistrictNameTh: "แม่ยาว",
              subdistrictNameEn: "Mae Yao",
              postalCode: 57100,
            },
            {
              subdistrictNameTh: "สันทราย",
              subdistrictNameEn: "San Sai",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "แม่กรณ์",
              subdistrictNameEn: "Mae Kon",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "ห้วยชมภู",
              subdistrictNameEn: "Huai Chomphu",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "ห้วยสัก",
              subdistrictNameEn: "Huai Sak",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "ริมกก",
              subdistrictNameEn: "Rim Kok",
              postalCode: 57100,
            },
            {
              subdistrictNameTh: "ดอยลาน",
              subdistrictNameEn: "Doi Lan",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "ป่าอ้อดอนชัย",
              subdistrictNameEn: "Pa O Don Chai",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "ท่าสาย",
              subdistrictNameEn: "Tha Sai",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "ดอยฮาง",
              subdistrictNameEn: "Doi Hang",
              postalCode: 57000,
            },
            {
              subdistrictNameTh: "ท่าสุด",
              subdistrictNameEn: "Tha Sut",
              postalCode: 57100,
            },
          ],
        },
        {
          districtNameTh: "เวียงชัย",
          districtNameEn: "Wiang Chai",
          subdistricts: [
            {
              subdistrictNameTh: "เวียงชัย",
              subdistrictNameEn: "Wiang Chai",
              postalCode: 57210,
            },
            {
              subdistrictNameTh: "ผางาม",
              subdistrictNameEn: "Pha Ngam",
              postalCode: 57210,
            },
            {
              subdistrictNameTh: "เวียงเหนือ",
              subdistrictNameEn: "Wiang Nuea",
              postalCode: 57210,
            },
            {
              subdistrictNameTh: "ดอนศิลา",
              subdistrictNameEn: "Don Sila",
              postalCode: 57210,
            },
            {
              subdistrictNameTh: "เมืองชุม",
              subdistrictNameEn: "Mueang Chum",
              postalCode: 57210,
            },
          ],
        },
        {
          districtNameTh: "เชียงของ",
          districtNameEn: "Chiang Khong",
          subdistricts: [
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 57140,
            },
            {
              subdistrictNameTh: "สถาน",
              subdistrictNameEn: "Sathan",
              postalCode: 57140,
            },
            {
              subdistrictNameTh: "ครึ่ง",
              subdistrictNameEn: "Khrueng",
              postalCode: 57140,
            },
            {
              subdistrictNameTh: "บุญเรือง",
              subdistrictNameEn: "Bunrueang",
              postalCode: 57140,
            },
            {
              subdistrictNameTh: "ห้วยซ้อ",
              subdistrictNameEn: "Huai So",
              postalCode: 57140,
            },
            {
              subdistrictNameTh: "ศรีดอนชัย",
              subdistrictNameEn: "Si Don Chai",
              postalCode: 57230,
            },
            {
              subdistrictNameTh: "ริมโขง",
              subdistrictNameEn: "Rim Khong",
              postalCode: 57140,
            },
          ],
        },
        {
          districtNameTh: "เทิง",
          districtNameEn: "Thoeng",
          subdistricts: [
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 57160,
            },
            {
              subdistrictNameTh: "งิ้ว",
              subdistrictNameEn: "Ngio",
              postalCode: 57160,
            },
            {
              subdistrictNameTh: "ปล้อง",
              subdistrictNameEn: "Plong",
              postalCode: 57230,
            },
            {
              subdistrictNameTh: "แม่ลอย",
              subdistrictNameEn: "Mae Loi",
              postalCode: 57230,
            },
            {
              subdistrictNameTh: "เชียงเคี่ยน",
              subdistrictNameEn: "Chiang Khian",
              postalCode: 57230,
            },
            {
              subdistrictNameTh: "ตับเต่า",
              subdistrictNameEn: "Tap Tao",
              postalCode: 57160,
            },
            {
              subdistrictNameTh: "หงาว",
              subdistrictNameEn: "Ngao",
              postalCode: 57160,
            },
            {
              subdistrictNameTh: "สันทรายงาม",
              subdistrictNameEn: "San Sai Ngam",
              postalCode: 57160,
            },
            {
              subdistrictNameTh: "ศรีดอนไชย",
              subdistrictNameEn: "Si Don Chai",
              postalCode: 57160,
            },
            {
              subdistrictNameTh: "หนองแรด",
              subdistrictNameEn: "Nong Raet",
              postalCode: 57160,
            },
          ],
        },
        {
          districtNameTh: "พาน",
          districtNameEn: "Phan",
          subdistricts: [
            {
              subdistrictNameTh: "สันมะเค็ด",
              subdistrictNameEn: "San Makhet",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "แม่อ้อ",
              subdistrictNameEn: "Mae O",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "ธารทอง",
              subdistrictNameEn: "Than Thong",
              postalCode: 57250,
            },
            {
              subdistrictNameTh: "สันติสุข",
              subdistrictNameEn: "Santi Suk",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "ดอยงาม",
              subdistrictNameEn: "Doi Ngam",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "หัวง้ม",
              subdistrictNameEn: "Hua Ngom",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "เจริญเมือง",
              subdistrictNameEn: "Charoen Mueang",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "ป่าหุ่ง",
              subdistrictNameEn: "Pa Hung",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "ม่วงคำ",
              subdistrictNameEn: "Muang Kham",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "ทรายขาว",
              subdistrictNameEn: "Sai Khao",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "สันกลาง",
              subdistrictNameEn: "San Klang",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "แม่เย็น",
              subdistrictNameEn: "Mae Yen",
              postalCode: 57280,
            },
            {
              subdistrictNameTh: "เมืองพาน",
              subdistrictNameEn: "Mueang Phan",
              postalCode: 57120,
            },
            {
              subdistrictNameTh: "ทานตะวัน",
              subdistrictNameEn: "Than Tawan",
              postalCode: 57280,
            },
            {
              subdistrictNameTh: "เวียงห้าว",
              subdistrictNameEn: "Wiang Hao",
              postalCode: 57120,
            },
          ],
        },
        {
          districtNameTh: "ป่าแดด",
          districtNameEn: "Pa Daet",
          subdistricts: [
            {
              subdistrictNameTh: "ป่าแดด",
              subdistrictNameEn: "Pa Daet",
              postalCode: 57190,
            },
            {
              subdistrictNameTh: "ป่าแงะ",
              subdistrictNameEn: "Pa Ngae",
              postalCode: 57190,
            },
            {
              subdistrictNameTh: "สันมะค่า",
              subdistrictNameEn: "San Makha",
              postalCode: 57190,
            },
            {
              subdistrictNameTh: "โรงช้าง",
              subdistrictNameEn: "Rong Chang",
              postalCode: 57190,
            },
            {
              subdistrictNameTh: "ศรีโพธิ์เงิน",
              subdistrictNameEn: "Si Pho Ngoen",
              postalCode: 57190,
            },
          ],
        },
        {
          districtNameTh: "แม่จัน",
          districtNameEn: "Mae Chan",
          subdistricts: [
            {
              subdistrictNameTh: "แม่จัน",
              subdistrictNameEn: "Mae Chan",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "จันจว้า",
              subdistrictNameEn: "Chan Chawa",
              postalCode: 57270,
            },
            {
              subdistrictNameTh: "แม่คำ",
              subdistrictNameEn: "Mae Kham",
              postalCode: 57240,
            },
            {
              subdistrictNameTh: "ป่าซาง",
              subdistrictNameEn: "Pa Sang",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "สันทราย",
              subdistrictNameEn: "San Sai",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "ท่าข้าวเปลือก",
              subdistrictNameEn: "Tha Khao Plueak",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "ป่าตึง",
              subdistrictNameEn: "Pa Tueng",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "แม่ไร่",
              subdistrictNameEn: "Mae Rai",
              postalCode: 57240,
            },
            {
              subdistrictNameTh: "ศรีค้ำ",
              subdistrictNameEn: "Si Kham",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "จันจว้าใต้",
              subdistrictNameEn: "Chan Chawa Tai",
              postalCode: 57270,
            },
            {
              subdistrictNameTh: "จอมสวรรค์",
              subdistrictNameEn: "Chom Sawan",
              postalCode: 57110,
            },
          ],
        },
        {
          districtNameTh: "เชียงแสน",
          districtNameEn: "Chiang Saen",
          subdistricts: [
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 57150,
            },
            {
              subdistrictNameTh: "ป่าสัก",
              subdistrictNameEn: "Pa Sak",
              postalCode: 57150,
            },
            {
              subdistrictNameTh: "บ้านแซว",
              subdistrictNameEn: "Ban Saeo",
              postalCode: 57150,
            },
            {
              subdistrictNameTh: "ศรีดอนมูล",
              subdistrictNameEn: "Si Don Mun",
              postalCode: 57150,
            },
            {
              subdistrictNameTh: "แม่เงิน",
              subdistrictNameEn: "Mae Ngoen",
              postalCode: 57150,
            },
            {
              subdistrictNameTh: "โยนก",
              subdistrictNameEn: "Yonok",
              postalCode: 57150,
            },
          ],
        },
        {
          districtNameTh: "แม่สาย",
          districtNameEn: "Mae Sai",
          subdistricts: [
            {
              subdistrictNameTh: "แม่สาย",
              subdistrictNameEn: "Mae Sai",
              postalCode: 57130,
            },
            {
              subdistrictNameTh: "ห้วยไคร้",
              subdistrictNameEn: "Huai Khrai",
              postalCode: 57220,
            },
            {
              subdistrictNameTh: "เกาะช้าง",
              subdistrictNameEn: "Ko Chang",
              postalCode: 57130,
            },
            {
              subdistrictNameTh: "โป่งผา",
              subdistrictNameEn: "Pong Pha",
              postalCode: 57130,
            },
            {
              subdistrictNameTh: "ศรีเมืองชุม",
              subdistrictNameEn: "Si Mueang Chum",
              postalCode: 57130,
            },
            {
              subdistrictNameTh: "เวียงพางคำ",
              subdistrictNameEn: "Wiang Phang Kham",
              postalCode: 57130,
            },
            {
              subdistrictNameTh: "บ้านด้าย",
              subdistrictNameEn: "Ban Dai",
              postalCode: 57220,
            },
            {
              subdistrictNameTh: "โป่งงาม",
              subdistrictNameEn: "Pong Ngam",
              postalCode: 57130,
            },
          ],
        },
        {
          districtNameTh: "แม่สรวย",
          districtNameEn: "Mae Suai",
          subdistricts: [
            {
              subdistrictNameTh: "แม่สรวย",
              subdistrictNameEn: "Mae Saruai",
              postalCode: 57180,
            },
            {
              subdistrictNameTh: "ป่าแดด",
              subdistrictNameEn: "Pa Daet",
              postalCode: 57180,
            },
            {
              subdistrictNameTh: "แม่พริก",
              subdistrictNameEn: "Mae Phrik",
              postalCode: 57180,
            },
            {
              subdistrictNameTh: "ศรีถ้อย",
              subdistrictNameEn: "Si Thoi",
              postalCode: 57180,
            },
            {
              subdistrictNameTh: "ท่าก๊อ",
              subdistrictNameEn: "Tha Ko",
              postalCode: 57180,
            },
            {
              subdistrictNameTh: "วาวี",
              subdistrictNameEn: "Wawi",
              postalCode: 57180,
            },
            {
              subdistrictNameTh: "เจดีย์หลวง",
              subdistrictNameEn: "Chedi Luang",
              postalCode: 57180,
            },
          ],
        },
        {
          districtNameTh: "เวียงป่าเป้า",
          districtNameEn: "Wiang Pa Pao",
          subdistricts: [
            {
              subdistrictNameTh: "สันสลี",
              subdistrictNameEn: "San Sali",
              postalCode: 57170,
            },
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 57170,
            },
            {
              subdistrictNameTh: "บ้านโป่ง",
              subdistrictNameEn: "Ban Pong",
              postalCode: 57170,
            },
            {
              subdistrictNameTh: "ป่างิ้ว",
              subdistrictNameEn: "Pa Ngio",
              postalCode: 57170,
            },
            {
              subdistrictNameTh: "เวียงกาหลง",
              subdistrictNameEn: "Wiang Kalong",
              postalCode: 57260,
            },
            {
              subdistrictNameTh: "แม่เจดีย์",
              subdistrictNameEn: "Mae Chedi",
              postalCode: 57260,
            },
            {
              subdistrictNameTh: "แม่เจดีย์ใหม่",
              subdistrictNameEn: "Mae Chedi Mai",
              postalCode: 57260,
            },
          ],
        },
        {
          districtNameTh: "พญาเม็งราย",
          districtNameEn: "Phaya Mengrai",
          subdistricts: [
            {
              subdistrictNameTh: "แม่เปา",
              subdistrictNameEn: "Mae Pao",
              postalCode: 57290,
            },
            {
              subdistrictNameTh: "แม่ต๋ำ",
              subdistrictNameEn: "Mae Tam",
              postalCode: 57290,
            },
            {
              subdistrictNameTh: "ไม้ยา",
              subdistrictNameEn: "Mai Ya",
              postalCode: 57290,
            },
            {
              subdistrictNameTh: "เม็งราย",
              subdistrictNameEn: "Mengrai",
              postalCode: 57290,
            },
            {
              subdistrictNameTh: "ตาดควัน",
              subdistrictNameEn: "Tat Khwan",
              postalCode: 57290,
            },
          ],
        },
        {
          districtNameTh: "เวียงแก่น",
          districtNameEn: "Wiang Kaen",
          subdistricts: [
            {
              subdistrictNameTh: "ม่วงยาย",
              subdistrictNameEn: "Muang Yai",
              postalCode: 57310,
            },
            {
              subdistrictNameTh: "ปอ",
              subdistrictNameEn: "Po",
              postalCode: 57310,
            },
            {
              subdistrictNameTh: "หล่ายงาว",
              subdistrictNameEn: "Lai Ngao",
              postalCode: 57310,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 57310,
            },
          ],
        },
        {
          districtNameTh: "ขุนตาล",
          districtNameEn: "Khun Tan",
          subdistricts: [
            {
              subdistrictNameTh: "ต้า",
              subdistrictNameEn: "Ta",
              postalCode: 57340,
            },
            {
              subdistrictNameTh: "ป่าตาล",
              subdistrictNameEn: "Pa Tan",
              postalCode: 57340,
            },
            {
              subdistrictNameTh: "ยางฮอม",
              subdistrictNameEn: "Yang Hom",
              postalCode: 57340,
            },
          ],
        },
        {
          districtNameTh: "แม่ฟ้าหลวง",
          districtNameEn: "Mae Fa Luang",
          subdistricts: [
            {
              subdistrictNameTh: "เทอดไทย",
              subdistrictNameEn: "Thoet Thai",
              postalCode: 57240,
            },
            {
              subdistrictNameTh: "แม่สลองใน",
              subdistrictNameEn: "Mae Salong Nai",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "แม่สลองนอก",
              subdistrictNameEn: "Mae Salong Nok",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "แม่ฟ้าหลวง",
              subdistrictNameEn: "Mae Fa Luang",
              postalCode: 57240,
            },
          ],
        },
        {
          districtNameTh: "แม่ลาว",
          districtNameEn: "Mae Lao",
          subdistricts: [
            {
              subdistrictNameTh: "ดงมะดะ",
              subdistrictNameEn: "Dong Mada",
              postalCode: 57250,
            },
            {
              subdistrictNameTh: "จอมหมอกแก้ว",
              subdistrictNameEn: "Chom Mok Kaeo",
              postalCode: 57250,
            },
            {
              subdistrictNameTh: "บัวสลี",
              subdistrictNameEn: "Bua Sali",
              postalCode: 57250,
            },
            {
              subdistrictNameTh: "ป่าก่อดำ",
              subdistrictNameEn: "Pa Ko Dam",
              postalCode: 57250,
            },
            {
              subdistrictNameTh: "โป่งแพร่",
              subdistrictNameEn: "Pong Phrae",
              postalCode: 57000,
            },
          ],
        },
        {
          districtNameTh: "เวียงเชียงรุ้ง",
          districtNameEn: "Wiang Chiang Rung",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งก่อ",
              subdistrictNameEn: "Thung Ko",
              postalCode: 57210,
            },
            {
              subdistrictNameTh: "ดงมหาวัน",
              subdistrictNameEn: "Dong Mahawan",
              postalCode: 57210,
            },
            {
              subdistrictNameTh: "ป่าซาง",
              subdistrictNameEn: "Pa Sang",
              postalCode: 57210,
            },
          ],
        },
        {
          districtNameTh: "ดอยหลวง",
          districtNameEn: "Doi Luang",
          subdistricts: [
            {
              subdistrictNameTh: "ปงน้อย",
              subdistrictNameEn: "Pong Noi",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "โชคชัย",
              subdistrictNameEn: "Chok Chai",
              postalCode: 57110,
            },
            {
              subdistrictNameTh: "หนองป่าก่อ",
              subdistrictNameEn: "Nong Pa Ko",
              postalCode: 57110,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "แม่ฮ่องสอน",
      stateNameEn: "Mae Hong Son",
      districts: [
        {
          districtNameTh: "เมืองแม่ฮ่องสอน",
          districtNameEn: "Mueang Mae Hong Son",
          subdistricts: [
            {
              subdistrictNameTh: "จองคำ",
              subdistrictNameEn: "Chong Kham",
              postalCode: 58000,
            },
            {
              subdistrictNameTh: "ห้วยโป่ง",
              subdistrictNameEn: "Huai Pong",
              postalCode: 58000,
            },
            {
              subdistrictNameTh: "ผาบ่อง",
              subdistrictNameEn: "Pha Bong",
              postalCode: 58000,
            },
            {
              subdistrictNameTh: "ปางหมู",
              subdistrictNameEn: "Pang Mu",
              postalCode: 58000,
            },
            {
              subdistrictNameTh: "หมอกจำแป่",
              subdistrictNameEn: "Mok Champae",
              postalCode: 58000,
            },
            {
              subdistrictNameTh: "ห้วยผา",
              subdistrictNameEn: "Huai Pha",
              postalCode: 58000,
            },
            {
              subdistrictNameTh: "ห้วยปูลิง",
              subdistrictNameEn: "Huai Pu Ling",
              postalCode: 58000,
            },
          ],
        },
        {
          districtNameTh: "ขุนยวม",
          districtNameEn: "Khun Yuam",
          subdistricts: [
            {
              subdistrictNameTh: "ขุนยวม",
              subdistrictNameEn: "Khun Yuam",
              postalCode: 58140,
            },
            {
              subdistrictNameTh: "แม่เงา",
              subdistrictNameEn: "Mae Ngao",
              postalCode: 58140,
            },
            {
              subdistrictNameTh: "เมืองปอน",
              subdistrictNameEn: "Mueang Pon",
              postalCode: 58140,
            },
            {
              subdistrictNameTh: "แม่ยวมน้อย",
              subdistrictNameEn: "Mae Yuam Noi",
              postalCode: 58140,
            },
            {
              subdistrictNameTh: "แม่กิ๊",
              subdistrictNameEn: "Mae Ki",
              postalCode: 58140,
            },
            {
              subdistrictNameTh: "แม่อูคอ",
              subdistrictNameEn: "Mae U Kho",
              postalCode: 58140,
            },
          ],
        },
        {
          districtNameTh: "ปาย",
          districtNameEn: "Pai",
          subdistricts: [
            {
              subdistrictNameTh: "เวียงใต้",
              subdistrictNameEn: "Wiang Tai",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "เวียงเหนือ",
              subdistrictNameEn: "Wiang Nuea",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "แม่นาเติง",
              subdistrictNameEn: "Mae Na Toeng",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "แม่ฮี้",
              subdistrictNameEn: "Mae Hi",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "ทุ่งยาว",
              subdistrictNameEn: "Thung Yao",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "เมืองแปง",
              subdistrictNameEn: "Mueang Paeng",
              postalCode: 58130,
            },
            {
              subdistrictNameTh: "โป่งสา",
              subdistrictNameEn: "Pong Sa",
              postalCode: 58130,
            },
          ],
        },
        {
          districtNameTh: "แม่สะเรียง",
          districtNameEn: "Mae Sariang",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านกาศ",
              subdistrictNameEn: "Ban Kat",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "แม่สะเรียง",
              subdistrictNameEn: "Mae Sariang",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "แม่คง",
              subdistrictNameEn: "Mae Khong",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "แม่เหาะ",
              subdistrictNameEn: "Mae Ho",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "แม่ยวม",
              subdistrictNameEn: "Mae Yuam",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "เสาหิน",
              subdistrictNameEn: "Sao Hin",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "ป่าแป๋",
              subdistrictNameEn: "Pa Pae",
              postalCode: 58110,
            },
          ],
        },
        {
          districtNameTh: "แม่ลาน้อย",
          districtNameEn: "Mae La Noi",
          subdistricts: [
            {
              subdistrictNameTh: "แม่ลาน้อย",
              subdistrictNameEn: "Mae La Noi",
              postalCode: 58120,
            },
            {
              subdistrictNameTh: "แม่ลาหลวง",
              subdistrictNameEn: "Mae La Luang",
              postalCode: 58120,
            },
            {
              subdistrictNameTh: "ท่าผาปุ้ม",
              subdistrictNameEn: "Tha Pha Pum",
              postalCode: 58120,
            },
            {
              subdistrictNameTh: "แม่โถ",
              subdistrictNameEn: "Mae Tho",
              postalCode: 58120,
            },
            {
              subdistrictNameTh: "ห้วยห้อม",
              subdistrictNameEn: "Huai Hom",
              postalCode: 58120,
            },
            {
              subdistrictNameTh: "แม่นาจาง",
              subdistrictNameEn: "Mae Na Chang",
              postalCode: 58120,
            },
            {
              subdistrictNameTh: "สันติคีรี",
              subdistrictNameEn: "Santi Khiri",
              postalCode: 58120,
            },
            {
              subdistrictNameTh: "ขุนแม่ลาน้อย",
              subdistrictNameEn: "Khun Mae La Noi",
              postalCode: 58120,
            },
          ],
        },
        {
          districtNameTh: "สบเมย",
          districtNameEn: "Sop Moei",
          subdistricts: [
            {
              subdistrictNameTh: "สบเมย",
              subdistrictNameEn: "Sop Moei",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "แม่คะตวน",
              subdistrictNameEn: "Mae Khatuan",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "กองก๋อย",
              subdistrictNameEn: "Kong Koi",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "แม่สวด",
              subdistrictNameEn: "Mae Suat",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "ป่าโปง",
              subdistrictNameEn: "Pa Pong",
              postalCode: 58110,
            },
            {
              subdistrictNameTh: "แม่สามแลบ",
              subdistrictNameEn: "Mae Sam Laep",
              postalCode: 58110,
            },
          ],
        },
        {
          districtNameTh: "ปางมะผ้า",
          districtNameEn: "Pang Mapha",
          subdistricts: [
            {
              subdistrictNameTh: "สบป่อง",
              subdistrictNameEn: "Sop Pong",
              postalCode: 58150,
            },
            {
              subdistrictNameTh: "ปางมะผ้า",
              subdistrictNameEn: "Pang Mapha",
              postalCode: 58150,
            },
            {
              subdistrictNameTh: "ถ้ำลอด",
              subdistrictNameEn: "Tham Lot",
              postalCode: 58150,
            },
            {
              subdistrictNameTh: "นาปู่ป้อม",
              subdistrictNameEn: "Na Pu Pom",
              postalCode: 58150,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นครสวรรค์",
      stateNameEn: "Nakhon Sawan",
      districts: [
        {
          districtNameTh: "เมืองนครสวรรค์",
          districtNameEn: "Mueang Nakhon Sawan",
          subdistricts: [
            {
              subdistrictNameTh: "ปากน้ำโพ",
              subdistrictNameEn: "Pak Nam Pho",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "กลางแดด",
              subdistrictNameEn: "Klang Daet",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "เกรียงไกร",
              subdistrictNameEn: "Kriang Krai",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "แควใหญ่",
              subdistrictNameEn: "Khwae Yai",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "ตะเคียนเลื่อน",
              subdistrictNameEn: "Takhian Luean",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "นครสวรรค์ตก",
              subdistrictNameEn: "Nakhon Sawan Tok",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "นครสวรรค์ออก",
              subdistrictNameEn: "Nakhon Sawan Ok",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "บางพระหลวง",
              subdistrictNameEn: "Bang Phra Luang",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "บางม่วง",
              subdistrictNameEn: "Bang Muang",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "บ้านมะเกลือ",
              subdistrictNameEn: "Ban Makluea",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "บ้านแก่ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "พระนอน",
              subdistrictNameEn: "Phra Non",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "วัดไทรย์",
              subdistrictNameEn: "Wat Sai",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "หนองกรด",
              subdistrictNameEn: "Nong Krot",
              postalCode: 60240,
            },
            {
              subdistrictNameTh: "หนองกระโดน",
              subdistrictNameEn: "Nong Kradon",
              postalCode: 60240,
            },
            {
              subdistrictNameTh: "หนองปลิง",
              subdistrictNameEn: "Nong Pling",
              postalCode: 60000,
            },
            {
              subdistrictNameTh: "บึงเสนาท",
              subdistrictNameEn: "Bueng Senat",
              postalCode: 60000,
            },
          ],
        },
        {
          districtNameTh: "โกรกพระ",
          districtNameEn: "Krok Phra",
          subdistricts: [
            {
              subdistrictNameTh: "โกรกพระ",
              subdistrictNameEn: "Krok Phra",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "ยางตาล",
              subdistrictNameEn: "Yang Tan",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "บางมะฝ่อ",
              subdistrictNameEn: "Bang Mafo",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "บางประมุง",
              subdistrictNameEn: "Bang Pramung",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "นากลาง",
              subdistrictNameEn: "Na Klang",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "ศาลาแดง",
              subdistrictNameEn: "Sala Daeng",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "เนินกว้าว",
              subdistrictNameEn: "Noen Kwao",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "เนินศาลา",
              subdistrictNameEn: "Noen Sala",
              postalCode: 60170,
            },
            {
              subdistrictNameTh: "หาดสูง",
              subdistrictNameEn: "Hat Sung",
              postalCode: 60170,
            },
          ],
        },
        {
          districtNameTh: "ชุมแสง",
          districtNameEn: "Chum Saeng",
          subdistricts: [
            {
              subdistrictNameTh: "ชุมแสง",
              subdistrictNameEn: "Chum Saeng",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "ทับกฤช",
              subdistrictNameEn: "Thap Krit",
              postalCode: 60250,
            },
            {
              subdistrictNameTh: "พิกุล",
              subdistrictNameEn: "Phikun",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "เกยไชย",
              subdistrictNameEn: "Koei Chai",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "ท่าไม้",
              subdistrictNameEn: "Tha Mai",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "บางเคียน",
              subdistrictNameEn: "Bang Khian",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "หนองกระเจา",
              subdistrictNameEn: "Nong Krachao",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "พันลาน",
              subdistrictNameEn: "Phan Lan",
              postalCode: 60250,
            },
            {
              subdistrictNameTh: "โคกหม้อ",
              subdistrictNameEn: "Khok Mo",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "ไผ่สิงห์",
              subdistrictNameEn: "Phai Sing",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "ฆะมัง",
              subdistrictNameEn: "Kha Mang",
              postalCode: 60120,
            },
            {
              subdistrictNameTh: "ทับกฤชใต้",
              subdistrictNameEn: "Thap Krit Tai",
              postalCode: 60250,
            },
          ],
        },
        {
          districtNameTh: "หนองบัว",
          districtNameEn: "Nong Bua",
          subdistricts: [
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "หนองกลับ",
              subdistrictNameEn: "Nong Klap",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "ธารทหาร",
              subdistrictNameEn: "Than Thahan",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "ห้วยร่วม",
              subdistrictNameEn: "Huai Ruam",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "ห้วยถั่วใต้",
              subdistrictNameEn: "Huai Thua Tai",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "ห้วยถั่วเหนือ",
              subdistrictNameEn: "Huai Thua Nuea",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "ห้วยใหญ่",
              subdistrictNameEn: "Huai Yai",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "ทุ่งทอง",
              subdistrictNameEn: "Thung Thong",
              postalCode: 60110,
            },
            {
              subdistrictNameTh: "วังบ่อ",
              subdistrictNameEn: "Wang Bo",
              postalCode: 60110,
            },
          ],
        },
        {
          districtNameTh: "บรรพตพิสัย",
          districtNameEn: "Banphot Phisai",
          subdistricts: [
            {
              subdistrictNameTh: "ท่างิ้ว",
              subdistrictNameEn: "Tha Ngio",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "บางตาหงาย",
              subdistrictNameEn: "Bang Ta Ngai",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "หูกวาง",
              subdistrictNameEn: "Hu Kwang",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "อ่างทอง",
              subdistrictNameEn: "Ang Thong",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "บ้านแดน",
              subdistrictNameEn: "Ban Daen",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "ตาขีด",
              subdistrictNameEn: "Ta Khit",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "ตาสัง",
              subdistrictNameEn: "Ta Sang",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "ด่านช้าง",
              subdistrictNameEn: "Dan Chang",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "หนองกรด",
              subdistrictNameEn: "Nong Krot",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "หนองตางู",
              subdistrictNameEn: "Nong Ta Ngu",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "บึงปลาทู",
              subdistrictNameEn: "Bueng Pla Thu",
              postalCode: 60180,
            },
            {
              subdistrictNameTh: "เจริญผล",
              subdistrictNameEn: "Charoen Phon",
              postalCode: 60180,
            },
          ],
        },
        {
          districtNameTh: "เก้าเลี้ยว",
          districtNameEn: "Kao Liao",
          subdistricts: [
            {
              subdistrictNameTh: "มหาโพธิ",
              subdistrictNameEn: "Mahapho",
              postalCode: 60230,
            },
            {
              subdistrictNameTh: "เก้าเลี้ยว",
              subdistrictNameEn: "Kao Liao",
              postalCode: 60230,
            },
            {
              subdistrictNameTh: "หนองเต่า",
              subdistrictNameEn: "Nong Tao",
              postalCode: 60230,
            },
            {
              subdistrictNameTh: "เขาดิน",
              subdistrictNameEn: "Khao Din",
              postalCode: 60230,
            },
            {
              subdistrictNameTh: "หัวดง",
              subdistrictNameEn: "Hua Dong",
              postalCode: 60230,
            },
          ],
        },
        {
          districtNameTh: "ตาคลี",
          districtNameEn: "Takhli",
          subdistricts: [
            {
              subdistrictNameTh: "ตาคลี",
              subdistrictNameEn: "Ta Khli",
              postalCode: 60140,
            },
            {
              subdistrictNameTh: "ช่องแค",
              subdistrictNameEn: "Chong Khae",
              postalCode: 60210,
            },
            {
              subdistrictNameTh: "จันเสน",
              subdistrictNameEn: "Chan Sen",
              postalCode: 60260,
            },
            {
              subdistrictNameTh: "ห้วยหอม",
              subdistrictNameEn: "Huai Hom",
              postalCode: 60210,
            },
            {
              subdistrictNameTh: "หัวหวาย",
              subdistrictNameEn: "Hua Wai",
              postalCode: 60140,
            },
            {
              subdistrictNameTh: "หนองโพ",
              subdistrictNameEn: "Nong Pho",
              postalCode: 60140,
            },
            {
              subdistrictNameTh: "หนองหม้อ",
              subdistrictNameEn: "Nong Mo",
              postalCode: 60140,
            },
            {
              subdistrictNameTh: "สร้อยทอง",
              subdistrictNameEn: "Soi Thong",
              postalCode: 60210,
            },
            {
              subdistrictNameTh: "ลาดทิพรส",
              subdistrictNameEn: "Lat Thippharot",
              postalCode: 60260,
            },
            {
              subdistrictNameTh: "พรหมนิมิต",
              subdistrictNameEn: "Phrom Nimit",
              postalCode: 60210,
            },
          ],
        },
        {
          districtNameTh: "ท่าตะโก",
          districtNameEn: "Tha Tako",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าตะโก",
              subdistrictNameEn: "Tha Tako",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "พนมรอก",
              subdistrictNameEn: "Phanom Rok",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "หัวถนน",
              subdistrictNameEn: "Hua Thanon",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "สายลำโพง",
              subdistrictNameEn: "Sai Lamphong",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "วังมหากร",
              subdistrictNameEn: "Wang Mahakon",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "ดอนคา",
              subdistrictNameEn: "Don Kha",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "ทำนบ",
              subdistrictNameEn: "Tham Nop",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "วังใหญ่",
              subdistrictNameEn: "Wang Yai",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "พนมเศษ",
              subdistrictNameEn: "Phanom Set",
              postalCode: 60160,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 60160,
            },
          ],
        },
        {
          districtNameTh: "ไพศาลี",
          districtNameEn: "Phaisali",
          subdistricts: [
            {
              subdistrictNameTh: "โคกเดื่อ",
              subdistrictNameEn: "Khok Duea",
              postalCode: 60220,
            },
            {
              subdistrictNameTh: "สำโรงชัย",
              subdistrictNameEn: "Samrong Chai",
              postalCode: 60220,
            },
            {
              subdistrictNameTh: "วังน้ำลัด",
              subdistrictNameEn: "Wang Nam Lat",
              postalCode: 60220,
            },
            {
              subdistrictNameTh: "ตะคร้อ",
              subdistrictNameEn: "Takhro",
              postalCode: 60220,
            },
            {
              subdistrictNameTh: "โพธิ์ประสาท",
              subdistrictNameEn: "Pho Prasat",
              postalCode: 60220,
            },
            {
              subdistrictNameTh: "วังข่อย",
              subdistrictNameEn: "Wang Khoi",
              postalCode: 60220,
            },
            {
              subdistrictNameTh: "นาขอม",
              subdistrictNameEn: "Na Khom",
              postalCode: 60220,
            },
            {
              subdistrictNameTh: "ไพศาลี",
              subdistrictNameEn: "Phaisali",
              postalCode: 60220,
            },
          ],
        },
        {
          districtNameTh: "พยุหะคีรี",
          districtNameEn: "Phayuha Hkiri",
          subdistricts: [
            {
              subdistrictNameTh: "พยุหะ",
              subdistrictNameEn: "Phayuha",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "เนินมะกอก",
              subdistrictNameEn: "Noen Makok",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "นิคมเขาบ่อแก้ว",
              subdistrictNameEn: "Nikhom Khao Bo Kaeo",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "ม่วงหัก",
              subdistrictNameEn: "Muang Hak",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "ยางขาว",
              subdistrictNameEn: "Yang Khao",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "ย่านมัทรี",
              subdistrictNameEn: "Yan Matsi",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "เขาทอง",
              subdistrictNameEn: "Khao Thong",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "ท่าน้ำอ้อย",
              subdistrictNameEn: "Tha Nam Oi",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "น้ำทรง",
              subdistrictNameEn: "Nam Song",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "เขากะลา",
              subdistrictNameEn: "Khao Kala",
              postalCode: 60130,
            },
            {
              subdistrictNameTh: "สระทะเล",
              subdistrictNameEn: "Sa Thale",
              postalCode: 60130,
            },
          ],
        },
        {
          districtNameTh: "ลาดยาว",
          districtNameEn: "Lat Yao",
          subdistricts: [
            {
              subdistrictNameTh: "ลาดยาว",
              subdistrictNameEn: "Lat Yao",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "ห้วยน้ำหอม",
              subdistrictNameEn: "Huai Nam Hom",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "วังม้า",
              subdistrictNameEn: "Wang Ma",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "วังเมือง",
              subdistrictNameEn: "Wang Mueang",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "สร้อยละคร",
              subdistrictNameEn: "Soi Lakhon",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "มาบแก",
              subdistrictNameEn: "Map Kae",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "หนองยาว",
              subdistrictNameEn: "Nong Yao",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "หนองนมวัว",
              subdistrictNameEn: "Nong Nom Wua",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "เนินขี้เหล็ก",
              subdistrictNameEn: "Noen Khi Lek",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "ศาลเจ้าไก่ต่อ",
              subdistrictNameEn: "San Chao Kai To",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 60150,
            },
          ],
        },
        {
          districtNameTh: "ตากฟ้า",
          districtNameEn: "Tak Fa",
          subdistricts: [
            {
              subdistrictNameTh: "ตากฟ้า",
              subdistrictNameEn: "Tak Fa",
              postalCode: 60190,
            },
            {
              subdistrictNameTh: "ลำพยนต์",
              subdistrictNameEn: "Lam Phayon",
              postalCode: 60190,
            },
            {
              subdistrictNameTh: "สุขสำราญ",
              subdistrictNameEn: "Suk Samran",
              postalCode: 60190,
            },
            {
              subdistrictNameTh: "หนองพิกุล",
              subdistrictNameEn: "Nong Phikun",
              postalCode: 60190,
            },
            {
              subdistrictNameTh: "พุนกยูง",
              subdistrictNameEn: "Phu Nok Yung",
              postalCode: 60190,
            },
            {
              subdistrictNameTh: "อุดมธัญญา",
              subdistrictNameEn: "Udom Thanya",
              postalCode: 60190,
            },
            {
              subdistrictNameTh: "เขาชายธง",
              subdistrictNameEn: "Khao Chai Thong",
              postalCode: 60190,
            },
          ],
        },
        {
          districtNameTh: "แม่วงก์",
          districtNameEn: "Mae Wong",
          subdistricts: [
            {
              subdistrictNameTh: "แม่วงก์",
              subdistrictNameEn: "Mae Wong",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "แม่เล่ย์",
              subdistrictNameEn: "Mae Le",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "วังซ่าน",
              subdistrictNameEn: "Wang San",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "เขาชนกัน",
              subdistrictNameEn: "Khao Chon Kan",
              postalCode: 60150,
            },
          ],
        },
        {
          districtNameTh: "แม่เปิน",
          districtNameEn: "Mae Pern",
          subdistricts: [
            {
              subdistrictNameTh: "แม่เปิน",
              subdistrictNameEn: "Mae Poen",
              postalCode: 60150,
            },
          ],
        },
        {
          districtNameTh: "ชุมตาบง",
          districtNameEn: "Chum Ta Bong",
          subdistricts: [
            {
              subdistrictNameTh: "ชุมตาบง",
              subdistrictNameEn: "Chum Ta Bong",
              postalCode: 60150,
            },
            {
              subdistrictNameTh: "ปางสวรรค์",
              subdistrictNameEn: "Pang Sawan",
              postalCode: 60150,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "อุทัยธานี",
      stateNameEn: "Uthai Thani",
      districts: [
        {
          districtNameTh: "เมืองอุทัยธานี",
          districtNameEn: "Mueang Uthai Thani",
          subdistricts: [
            {
              subdistrictNameTh: "อุทัยใหม่",
              subdistrictNameEn: "Uthai Mai",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "น้ำซึม",
              subdistrictNameEn: "Nam Suem",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "สะแกกรัง",
              subdistrictNameEn: "Sakae Krang",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "ดอนขวาง",
              subdistrictNameEn: "Don Khwang",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "หาดทนง",
              subdistrictNameEn: "Hat Thanong",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "เกาะเทโพ",
              subdistrictNameEn: "Ko Thepho",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "ท่าซุง",
              subdistrictNameEn: "Tha Sung",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "หนองแก",
              subdistrictNameEn: "Nong Kae",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "โนนเหล็ก",
              subdistrictNameEn: "Non Lek",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "หนองเต่า",
              subdistrictNameEn: "Nong Tao",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "หนองไผ่แบน",
              subdistrictNameEn: "Nong Phai Baen",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "หนองพังค่า",
              subdistrictNameEn: "Nong Phang Kha",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "ทุ่งใหญ่",
              subdistrictNameEn: "Thung Yai",
              postalCode: 61000,
            },
            {
              subdistrictNameTh: "เนินแจง",
              subdistrictNameEn: "Noen Chaeng",
              postalCode: 61000,
            },
          ],
        },
        {
          districtNameTh: "ทัพทัน",
          districtNameEn: "Thap Than",
          subdistricts: [
            {
              subdistrictNameTh: "ทัพทัน",
              subdistrictNameEn: "Thapthan",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "ทุ่งนาไทย",
              subdistrictNameEn: "Thung Na Thai",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "เขาขี้ฝอย",
              subdistrictNameEn: "Khao Khi Foi",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "หนองหญ้าปล้อง",
              subdistrictNameEn: "Nong Ya Plong",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "โคกหม้อ",
              subdistrictNameEn: "Khok Mo",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "หนองยายดา",
              subdistrictNameEn: "Nong Yai Da",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "หนองกลางดง",
              subdistrictNameEn: "Nong Klang Dong",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "หนองกระทุ่ม",
              subdistrictNameEn: "Nong Krathum",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "หนองสระ",
              subdistrictNameEn: "Nong Sa",
              postalCode: 61120,
            },
            {
              subdistrictNameTh: "ตลุกดู่",
              subdistrictNameEn: "Taluk Duk",
              postalCode: 61120,
            },
          ],
        },
        {
          districtNameTh: "สว่างอารมณ์",
          districtNameEn: "Sawang Arom",
          subdistricts: [
            {
              subdistrictNameTh: "สว่างอารมณ์",
              subdistrictNameEn: "Sawang Arom",
              postalCode: 61150,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 61150,
            },
            {
              subdistrictNameTh: "พลวงสองนาง",
              subdistrictNameEn: "Phluang Song Nang",
              postalCode: 61150,
            },
            {
              subdistrictNameTh: "ไผ่เขียว",
              subdistrictNameEn: "Phai Khiao",
              postalCode: 61150,
            },
            {
              subdistrictNameTh: "บ่อยาง",
              subdistrictNameEn: "Bo Yang",
              postalCode: 61150,
            },
          ],
        },
        {
          districtNameTh: "หนองฉาง",
          districtNameEn: "Nong Chang",
          subdistricts: [
            {
              subdistrictNameTh: "หนองฉาง",
              subdistrictNameEn: "Nong Chang",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "หนองยาง",
              subdistrictNameEn: "Nong Yang",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "หนองนางนวล",
              subdistrictNameEn: "Nong Nang Nuan",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "หนองสรวง",
              subdistrictNameEn: "Nong Suang",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "บ้านเก่า",
              subdistrictNameEn: "Ban Kao",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "อุทัยเก่า",
              subdistrictNameEn: "Uthai Kao",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "ทุ่งโพ",
              subdistrictNameEn: "Thung Pho",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "ทุ่งพง",
              subdistrictNameEn: "Thung Phong",
              postalCode: 61110,
            },
            {
              subdistrictNameTh: "เขาบางแกรก",
              subdistrictNameEn: "Khao Bang Kraek",
              postalCode: 61170,
            },
            {
              subdistrictNameTh: "เขากวางทอง",
              subdistrictNameEn: "Khao Kwang Thong",
              postalCode: 61110,
            },
          ],
        },
        {
          districtNameTh: "หนองขาหย่าง",
          districtNameEn: "Nong Khayang",
          subdistricts: [
            {
              subdistrictNameTh: "หนองขาหย่าง",
              subdistrictNameEn: "Nong Kha Yang",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "ดอนกลอย",
              subdistrictNameEn: "Don Kloi",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "ห้วยรอบ",
              subdistrictNameEn: "Huai Rop",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "ทุ่งพึ่ง",
              subdistrictNameEn: "Thung Phueng",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "ท่าโพ",
              subdistrictNameEn: "Tha Pho",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "หมกแถว",
              subdistrictNameEn: "Mok Thaeo",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "หลุมเข้า",
              subdistrictNameEn: "Lum Khao",
              postalCode: 61130,
            },
            {
              subdistrictNameTh: "ดงขวาง",
              subdistrictNameEn: "Dong Khwang",
              postalCode: 61130,
            },
          ],
        },
        {
          districtNameTh: "บ้านไร่",
          districtNameEn: "Ban Rai",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 61140,
            },
            {
              subdistrictNameTh: "ทัพหลวง",
              subdistrictNameEn: "Thap Luang",
              postalCode: 61140,
            },
            {
              subdistrictNameTh: "ห้วยแห้ง",
              subdistrictNameEn: "Huai Haeng",
              postalCode: 61140,
            },
            {
              subdistrictNameTh: "คอกควาย",
              subdistrictNameEn: "Khok Khwai",
              postalCode: 61140,
            },
            {
              subdistrictNameTh: "วังหิน",
              subdistrictNameEn: "Wang Hin",
              postalCode: 61180,
            },
            {
              subdistrictNameTh: "เมืองการุ้ง",
              subdistrictNameEn: "Mueang Karung",
              postalCode: 61180,
            },
            {
              subdistrictNameTh: "แก่นมะกรูด",
              subdistrictNameEn: "Kaen Makrut",
              postalCode: 61140,
            },
            {
              subdistrictNameTh: "หนองจอก",
              subdistrictNameEn: "Nong Chok",
              postalCode: 61180,
            },
            {
              subdistrictNameTh: "หูช้าง",
              subdistrictNameEn: "Hu Chang",
              postalCode: 61180,
            },
            {
              subdistrictNameTh: "บ้านบึง",
              subdistrictNameEn: "Ban Bueng",
              postalCode: 61140,
            },
            {
              subdistrictNameTh: "บ้านใหม่คลองเคียน",
              subdistrictNameEn: "Ban Mai Khlong Khian",
              postalCode: 61180,
            },
            {
              subdistrictNameTh: "หนองบ่มกล้วย",
              subdistrictNameEn: "Nong Bom Kluai",
              postalCode: 61180,
            },
            {
              subdistrictNameTh: "เจ้าวัด",
              subdistrictNameEn: "Chao Wat",
              postalCode: 61140,
            },
          ],
        },
        {
          districtNameTh: "ลานสัก",
          districtNameEn: "Lan Sak",
          subdistricts: [
            {
              subdistrictNameTh: "ลานสัก",
              subdistrictNameEn: "Lan Sak",
              postalCode: 61160,
            },
            {
              subdistrictNameTh: "ประดู่ยืน",
              subdistrictNameEn: "Pradu Yuen",
              postalCode: 61160,
            },
            {
              subdistrictNameTh: "ป่าอ้อ",
              subdistrictNameEn: "Pa O",
              postalCode: 61160,
            },
            {
              subdistrictNameTh: "ระบำ",
              subdistrictNameEn: "Rabam",
              postalCode: 61160,
            },
            {
              subdistrictNameTh: "น้ำรอบ",
              subdistrictNameEn: "Nam Rop",
              postalCode: 61160,
            },
            {
              subdistrictNameTh: "ทุ่งนางาม",
              subdistrictNameEn: "Thung Na Ngam",
              postalCode: 61160,
            },
          ],
        },
        {
          districtNameTh: "ห้วยคต",
          districtNameEn: "Huai Khot",
          subdistricts: [
            {
              subdistrictNameTh: "สุขฤทัย",
              subdistrictNameEn: "Suk Ruethai",
              postalCode: 61170,
            },
            {
              subdistrictNameTh: "ทองหลาง",
              subdistrictNameEn: "Thong Lang",
              postalCode: 61170,
            },
            {
              subdistrictNameTh: "ห้วยคต",
              subdistrictNameEn: "Huai Khot",
              postalCode: 61170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "กำแพงเพชร",
      stateNameEn: "Kamphaeng Phet",
      districts: [
        {
          districtNameTh: "เมืองกำแพงเพชร",
          districtNameEn: "Mueang Kamphaeng Phet",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "ไตรตรึงษ์",
              subdistrictNameEn: "Trai Trueng",
              postalCode: 62160,
            },
            {
              subdistrictNameTh: "อ่างทอง",
              subdistrictNameEn: "Ang Thong",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "นาบ่อคำ",
              subdistrictNameEn: "Na Bo Kham",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "นครชุม",
              subdistrictNameEn: "Nakhon Chum",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "ทรงธรรม",
              subdistrictNameEn: "Songtham",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "ลานดอกไม้",
              subdistrictNameEn: "Lan Dokmai",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "หนองปลิง",
              subdistrictNameEn: "Nong Pling",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "คณฑี",
              subdistrictNameEn: "Kon Ti",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "นิคมทุ่งโพธิ์ทะเล",
              subdistrictNameEn: "Nikhom Thung Pho Thale",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "เทพนคร",
              subdistrictNameEn: "Thep Nakhon",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "ท่าขุนราม",
              subdistrictNameEn: "Tha Khun Ram",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "คลองแม่ลาย",
              subdistrictNameEn: "Khlong Mae Lai",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "ธำมรงค์",
              subdistrictNameEn: "Thammarong",
              postalCode: 62160,
            },
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 62000,
            },
          ],
        },
        {
          districtNameTh: "ไทรงาม",
          districtNameEn: "Sai Ngam",
          subdistricts: [
            {
              subdistrictNameTh: "ไทรงาม",
              subdistrictNameEn: "Sai Ngam",
              postalCode: 62150,
            },
            {
              subdistrictNameTh: "หนองคล้า",
              subdistrictNameEn: "Nong Khla",
              postalCode: 62150,
            },
            {
              subdistrictNameTh: "หนองทอง",
              subdistrictNameEn: "Nong Thong",
              postalCode: 62150,
            },
            {
              subdistrictNameTh: "หนองไม้กอง",
              subdistrictNameEn: "Nong Mai Kong",
              postalCode: 62150,
            },
            {
              subdistrictNameTh: "มหาชัย",
              subdistrictNameEn: "Mahachai",
              postalCode: 62150,
            },
            {
              subdistrictNameTh: "พานทอง",
              subdistrictNameEn: "Phan Thong",
              postalCode: 62150,
            },
            {
              subdistrictNameTh: "หนองแม่แตง",
              subdistrictNameEn: "Nong Mae Taeng",
              postalCode: 62150,
            },
          ],
        },
        {
          districtNameTh: "คลองลาน",
          districtNameEn: "Khlong Lan",
          subdistricts: [
            {
              subdistrictNameTh: "คลองน้ำไหล",
              subdistrictNameEn: "Khlong Nam Lai",
              postalCode: 62180,
            },
            {
              subdistrictNameTh: "โป่งน้ำร้อน",
              subdistrictNameEn: "Pong Nam Ron",
              postalCode: 62180,
            },
            {
              subdistrictNameTh: "คลองลานพัฒนา",
              subdistrictNameEn: "Khlong Lan Phatthana",
              postalCode: 62180,
            },
            {
              subdistrictNameTh: "สักงาม",
              subdistrictNameEn: "Sak Ngam",
              postalCode: 62180,
            },
          ],
        },
        {
          districtNameTh: "ขาณุวรลักษบุรี",
          districtNameEn: "Khanu Woralaksaburi",
          subdistricts: [
            {
              subdistrictNameTh: "ยางสูง",
              subdistrictNameEn: "Yang Sung",
              postalCode: 62130,
            },
            {
              subdistrictNameTh: "ป่าพุทรา",
              subdistrictNameEn: "Pa Phutsa",
              postalCode: 62130,
            },
            {
              subdistrictNameTh: "แสนตอ",
              subdistrictNameEn: "Saen To",
              postalCode: 62130,
            },
            {
              subdistrictNameTh: "สลกบาตร",
              subdistrictNameEn: "Salok Bat",
              postalCode: 62140,
            },
            {
              subdistrictNameTh: "บ่อถ้ำ",
              subdistrictNameEn: "Bo Tham",
              postalCode: 62140,
            },
            {
              subdistrictNameTh: "ดอนแตง",
              subdistrictNameEn: "Don Taeng",
              postalCode: 62140,
            },
            {
              subdistrictNameTh: "วังชะพลู",
              subdistrictNameEn: "Wang Chaphlu",
              postalCode: 62140,
            },
            {
              subdistrictNameTh: "โค้งไผ่",
              subdistrictNameEn: "Khong Phai",
              postalCode: 62140,
            },
            {
              subdistrictNameTh: "ปางมะค่า",
              subdistrictNameEn: "Pang Makha",
              postalCode: 62140,
            },
            {
              subdistrictNameTh: "วังหามแห",
              subdistrictNameEn: "Wang Ham Hae",
              postalCode: 62140,
            },
            {
              subdistrictNameTh: "เกาะตาล",
              subdistrictNameEn: "Ko Tan",
              postalCode: 62130,
            },
          ],
        },
        {
          districtNameTh: "คลองขลุง",
          districtNameEn: "Khlong Khlung",
          subdistricts: [
            {
              subdistrictNameTh: "คลองขลุง",
              subdistrictNameEn: "Khlong Khlung",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "ท่ามะเขือ",
              subdistrictNameEn: "Tha Makhuea",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "ท่าพุทรา",
              subdistrictNameEn: "Tha Phutsa",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "แม่ลาด",
              subdistrictNameEn: "Mae Lat",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "วังยาง",
              subdistrictNameEn: "Wang Yang",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "วังแขม",
              subdistrictNameEn: "Wang Khaem",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "หัวถนน",
              subdistrictNameEn: "Hua Thanon",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "วังไทร",
              subdistrictNameEn: "Wang Sai",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "วังบัว",
              subdistrictNameEn: "Wang Bua",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "คลองสมบูรณ์",
              subdistrictNameEn: "Khlong Sombun",
              postalCode: 62120,
            },
          ],
        },
        {
          districtNameTh: "พรานกระต่าย",
          districtNameEn: "Phran Kratai",
          subdistricts: [
            {
              subdistrictNameTh: "พรานกระต่าย",
              subdistrictNameEn: "Phran Kratai",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "หนองหัววัว",
              subdistrictNameEn: "Nong Hua Wua",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "ท่าไม้",
              subdistrictNameEn: "Tha Mai",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "วังควง",
              subdistrictNameEn: "Wang Khuang",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "วังตะแบก",
              subdistrictNameEn: "Wang Tabaek",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "เขาคีริส",
              subdistrictNameEn: "Khao Khirit",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "คุยบ้านโอง",
              subdistrictNameEn: "Khui Ban Ong",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "คลองพิไกร",
              subdistrictNameEn: "Khlong Phikrai",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "ถ้ำกระต่ายทอง",
              subdistrictNameEn: "Tham Kratai Thong",
              postalCode: 62110,
            },
            {
              subdistrictNameTh: "ห้วยยั้ง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 62110,
            },
          ],
        },
        {
          districtNameTh: "ลานกระบือ",
          districtNameEn: "Lan Krabue",
          subdistricts: [
            {
              subdistrictNameTh: "ลานกระบือ",
              subdistrictNameEn: "Lan Krabue",
              postalCode: 62170,
            },
            {
              subdistrictNameTh: "ช่องลม",
              subdistrictNameEn: "Chong Lom",
              postalCode: 62170,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 62170,
            },
            {
              subdistrictNameTh: "โนนพลวง",
              subdistrictNameEn: "Non Phluang",
              postalCode: 62170,
            },
            {
              subdistrictNameTh: "ประชาสุขสันต์",
              subdistrictNameEn: "Pracha Suksan",
              postalCode: 62170,
            },
            {
              subdistrictNameTh: "บึงทับแรต",
              subdistrictNameEn: "Bueng Thap Raet",
              postalCode: 62170,
            },
            {
              subdistrictNameTh: "จันทิมา",
              subdistrictNameEn: "Chanthima",
              postalCode: 62170,
            },
          ],
        },
        {
          districtNameTh: "ทรายทองวัฒนา",
          districtNameEn: "Sai Thong Watthana",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งทราย",
              subdistrictNameEn: "Thung Sai",
              postalCode: 62190,
            },
            {
              subdistrictNameTh: "ทุ่งทอง",
              subdistrictNameEn: "Thung Thong",
              postalCode: 62190,
            },
            {
              subdistrictNameTh: "ถาวรวัฒนา",
              subdistrictNameEn: "Thawon Watthana",
              postalCode: 62190,
            },
          ],
        },
        {
          districtNameTh: "ปางศิลาทอง",
          districtNameEn: "Pang Sila Thong",
          subdistricts: [
            {
              subdistrictNameTh: "โพธิ์ทอง",
              subdistrictNameEn: "Pho Thong",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "หินดาต",
              subdistrictNameEn: "Hin Dat",
              postalCode: 62120,
            },
            {
              subdistrictNameTh: "ปางตาไว",
              subdistrictNameEn: "Pang Ta Wai",
              postalCode: 62120,
            },
          ],
        },
        {
          districtNameTh: "บึงสามัคคี",
          districtNameEn: "Bueng Samakkhi",
          subdistricts: [
            {
              subdistrictNameTh: "บึงสามัคคี",
              subdistrictNameEn: "Bueng Samakkhi",
              postalCode: 62210,
            },
            {
              subdistrictNameTh: "วังชะโอน",
              subdistrictNameEn: "Wang Cha-On",
              postalCode: 62210,
            },
            {
              subdistrictNameTh: "ระหาน",
              subdistrictNameEn: "Rahan",
              postalCode: 62210,
            },
            {
              subdistrictNameTh: "เทพนิมิต",
              subdistrictNameEn: "Thep Nimit",
              postalCode: 62210,
            },
          ],
        },
        {
          districtNameTh: "โกสัมพีนคร",
          districtNameEn: "Kosamphi Nakhon",
          subdistricts: [
            {
              subdistrictNameTh: "โกสัมพี",
              subdistrictNameEn: "Kosamphi",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "เพชรชมภู",
              subdistrictNameEn: "Phet Chomphu",
              postalCode: 62000,
            },
            {
              subdistrictNameTh: "ลานดอกไม้ตก",
              subdistrictNameEn: "Lan Dokmai Tok",
              postalCode: 62000,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ตาก",
      stateNameEn: "Tak",
      districts: [
        {
          districtNameTh: "เมืองตาก",
          districtNameEn: "Mueang Tak",
          subdistricts: [
            {
              subdistrictNameTh: "ระแหง",
              subdistrictNameEn: "Rahaeng",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "เชียงเงิน",
              subdistrictNameEn: "Chiang Ngoen",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "หัวเดียด",
              subdistrictNameEn: "Hua Diat",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "หนองบัวเหนือ",
              subdistrictNameEn: "Nong Bua Nuea",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "ไม้งาม",
              subdistrictNameEn: "Mai Ngam",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "โป่งแดง",
              subdistrictNameEn: "Pong Daeng",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "น้ำรึม",
              subdistrictNameEn: "Nam Ruem",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "วังหิน",
              subdistrictNameEn: "Wang Hin",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "แม่ท้อ",
              subdistrictNameEn: "Mae Tho",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "ป่ามะม่วง",
              subdistrictNameEn: "Pa Mamuang",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "หนองบัวใต้",
              subdistrictNameEn: "Nong Bua Tai",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "วังประจบ",
              subdistrictNameEn: "Wang Prachop",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "ตลุกกลางทุ่ง",
              subdistrictNameEn: "Taluk Klang Thung",
              postalCode: 63000,
            },
          ],
        },
        {
          districtNameTh: "บ้านตาก",
          districtNameEn: "Ban Tak",
          subdistricts: [
            {
              subdistrictNameTh: "ตากออก",
              subdistrictNameEn: "Tak Ok",
              postalCode: 63120,
            },
            {
              subdistrictNameTh: "สมอโคน",
              subdistrictNameEn: "Samo Khon",
              postalCode: 63120,
            },
            {
              subdistrictNameTh: "แม่สลิด",
              subdistrictNameEn: "Mae Salit",
              postalCode: 63120,
            },
            {
              subdistrictNameTh: "ตากตก",
              subdistrictNameEn: "Tak Tok",
              postalCode: 63120,
            },
            {
              subdistrictNameTh: "เกาะตะเภา",
              subdistrictNameEn: "Ko Taphao",
              postalCode: 63120,
            },
            {
              subdistrictNameTh: "ทุ่งกระเชาะ",
              subdistrictNameEn: "Thung Kracho",
              postalCode: 63120,
            },
            {
              subdistrictNameTh: "ท้องฟ้า",
              subdistrictNameEn: "Thong Fa",
              postalCode: 63120,
            },
          ],
        },
        {
          districtNameTh: "สามเงา",
          districtNameEn: "Sam Ngao",
          subdistricts: [
            {
              subdistrictNameTh: "สามเงา",
              subdistrictNameEn: "Sam Ngao",
              postalCode: 63130,
            },
            {
              subdistrictNameTh: "วังหมัน",
              subdistrictNameEn: "Wang Man",
              postalCode: 63130,
            },
            {
              subdistrictNameTh: "ยกกระบัตร",
              subdistrictNameEn: "Yok Krabat",
              postalCode: 63130,
            },
            {
              subdistrictNameTh: "ย่านรี",
              subdistrictNameEn: "Yan Ri",
              postalCode: 63130,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 63130,
            },
            {
              subdistrictNameTh: "วังจันทร์",
              subdistrictNameEn: "Wang Chan",
              postalCode: 63130,
            },
          ],
        },
        {
          districtNameTh: "แม่ระมาด",
          districtNameEn: "Mae Ramat",
          subdistricts: [
            {
              subdistrictNameTh: "แม่ระมาด",
              subdistrictNameEn: "Mae Ramat",
              postalCode: 63140,
            },
            {
              subdistrictNameTh: "แม่จะเรา",
              subdistrictNameEn: "Mae Charao",
              postalCode: 63140,
            },
            {
              subdistrictNameTh: "ขะเนจื้อ",
              subdistrictNameEn: "Khane Chue",
              postalCode: 63140,
            },
            {
              subdistrictNameTh: "แม่ตื่น",
              subdistrictNameEn: "Mae Tuen",
              postalCode: 63140,
            },
            {
              subdistrictNameTh: "สามหมื่น",
              subdistrictNameEn: "Sam Muen",
              postalCode: 63140,
            },
            {
              subdistrictNameTh: "พระธาตุ",
              subdistrictNameEn: "Phrathat",
              postalCode: 63140,
            },
          ],
        },
        {
          districtNameTh: "ท่าสองยาง",
          districtNameEn: "Tha Song Yang",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าสองยาง",
              subdistrictNameEn: "Tha Song Yang",
              postalCode: 63150,
            },
            {
              subdistrictNameTh: "แม่ต้าน",
              subdistrictNameEn: "Mae Tan",
              postalCode: 63150,
            },
            {
              subdistrictNameTh: "แม่สอง",
              subdistrictNameEn: "Mae Song",
              postalCode: 63150,
            },
            {
              subdistrictNameTh: "แม่หละ",
              subdistrictNameEn: "Mae La",
              postalCode: 63150,
            },
            {
              subdistrictNameTh: "แม่วะหลวง",
              subdistrictNameEn: "Mae Wa Luang",
              postalCode: 63150,
            },
            {
              subdistrictNameTh: "แม่อุสุ",
              subdistrictNameEn: "Mae Usu",
              postalCode: 63150,
            },
          ],
        },
        {
          districtNameTh: "แม่สอด",
          districtNameEn: "Mae Sot",
          subdistricts: [
            {
              subdistrictNameTh: "แม่สอด",
              subdistrictNameEn: "Mae Sot",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "แม่กุ",
              subdistrictNameEn: "Mae Ku",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "พะวอ",
              subdistrictNameEn: "Phawo",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "แม่ตาว",
              subdistrictNameEn: "Mae Tao",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "แม่กาษา",
              subdistrictNameEn: "Mae Kasa",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "ท่าสายลวด",
              subdistrictNameEn: "Tha Sai Luat",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "แม่ปะ",
              subdistrictNameEn: "Mae Pa",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "มหาวัน",
              subdistrictNameEn: "Mahawan",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "ด่านแม่ละเมา",
              subdistrictNameEn: "Dan Mae Lamao",
              postalCode: 63110,
            },
            {
              subdistrictNameTh: "พระธาตุผาแดง",
              subdistrictNameEn: "Phrathat Pha Daeng",
              postalCode: 63110,
            },
          ],
        },
        {
          districtNameTh: "พบพระ",
          districtNameEn: "Phop Phra",
          subdistricts: [
            {
              subdistrictNameTh: "พบพระ",
              subdistrictNameEn: "Phop Phra",
              postalCode: 63160,
            },
            {
              subdistrictNameTh: "ช่องแคบ",
              subdistrictNameEn: "Chong Khaep",
              postalCode: 63160,
            },
            {
              subdistrictNameTh: "คีรีราษฎร์",
              subdistrictNameEn: "Khiri Rat",
              postalCode: 63160,
            },
            {
              subdistrictNameTh: "วาเล่ย์",
              subdistrictNameEn: "Wale",
              postalCode: 63160,
            },
            {
              subdistrictNameTh: "รวมไทยพัฒนา",
              subdistrictNameEn: "Ruam Thai Phatthana",
              postalCode: 63160,
            },
          ],
        },
        {
          districtNameTh: "อุ้มผาง",
          districtNameEn: "Um Phang",
          subdistricts: [
            {
              subdistrictNameTh: "อุ้มผาง",
              subdistrictNameEn: "Um Phang",
              postalCode: 63170,
            },
            {
              subdistrictNameTh: "หนองหลวง",
              subdistrictNameEn: "Nong Luang",
              postalCode: 63170,
            },
            {
              subdistrictNameTh: "โมโกร",
              subdistrictNameEn: "Mo Kro",
              postalCode: 63170,
            },
            {
              subdistrictNameTh: "แม่จัน",
              subdistrictNameEn: "Mae Chan",
              postalCode: 63170,
            },
            {
              subdistrictNameTh: "แม่ละมุ้ง",
              subdistrictNameEn: "Mae Lamung",
              postalCode: 63170,
            },
            {
              subdistrictNameTh: "แม่กลอง",
              subdistrictNameEn: "Mae Klong",
              postalCode: 63170,
            },
          ],
        },
        {
          districtNameTh: "วังเจ้า",
          districtNameEn: "Wang Chao",
          subdistricts: [
            {
              subdistrictNameTh: "เชียงทอง",
              subdistrictNameEn: "Chiang Thong",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "นาโบสถ์",
              subdistrictNameEn: "Na Bot",
              postalCode: 63000,
            },
            {
              subdistrictNameTh: "ประดาง",
              subdistrictNameEn: "Pradang",
              postalCode: 63000,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สุโขทัย",
      stateNameEn: "Sukhothai",
      districts: [
        {
          districtNameTh: "เมืองสุโขทัย",
          districtNameEn: "Mueang Sukhothai",
          subdistricts: [
            {
              subdistrictNameTh: "ธานี",
              subdistrictNameEn: "Thani",
              postalCode: 64000,
            },
            {
              subdistrictNameTh: "บ้านสวน",
              subdistrictNameEn: "Ban Suan",
              postalCode: 64220,
            },
            {
              subdistrictNameTh: "เมืองเก่า",
              subdistrictNameEn: "Mueang Kao",
              postalCode: 64210,
            },
            {
              subdistrictNameTh: "ปากแคว",
              subdistrictNameEn: "Pak Khwae",
              postalCode: 64000,
            },
            {
              subdistrictNameTh: "ยางซ้าย",
              subdistrictNameEn: "Yang Sai",
              postalCode: 64000,
            },
            {
              subdistrictNameTh: "บ้านกล้วย",
              subdistrictNameEn: "Ban Kluai",
              postalCode: 64000,
            },
            {
              subdistrictNameTh: "บ้านหลุม",
              subdistrictNameEn: "Ban Lum",
              postalCode: 64000,
            },
            {
              subdistrictNameTh: "ตาลเตี้ย",
              subdistrictNameEn: "Tan Tia",
              postalCode: 64220,
            },
            {
              subdistrictNameTh: "ปากพระ",
              subdistrictNameEn: "Pak Phra",
              postalCode: 64000,
            },
            {
              subdistrictNameTh: "วังทองแดง",
              subdistrictNameEn: "Wang Thong Daeng",
              postalCode: 64210,
            },
          ],
        },
        {
          districtNameTh: "บ้านด่านลานหอย",
          districtNameEn: "Ban Dan Lan Hoi",
          subdistricts: [
            {
              subdistrictNameTh: "ลานหอย",
              subdistrictNameEn: "Lan Hoi",
              postalCode: 64140,
            },
            {
              subdistrictNameTh: "บ้านด่าน",
              subdistrictNameEn: "Ban Dan",
              postalCode: 64140,
            },
            {
              subdistrictNameTh: "วังตะคร้อ",
              subdistrictNameEn: "Wang Takhro",
              postalCode: 64140,
            },
            {
              subdistrictNameTh: "วังน้ำขาว",
              subdistrictNameEn: "Wang Nam Khao",
              postalCode: 64140,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 64140,
            },
            {
              subdistrictNameTh: "หนองหญ้าปล้อง",
              subdistrictNameEn: "Nong Ya Plong",
              postalCode: 64140,
            },
            {
              subdistrictNameTh: "วังลึก",
              subdistrictNameEn: "Wang Luek",
              postalCode: 64140,
            },
          ],
        },
        {
          districtNameTh: "คีรีมาศ",
          districtNameEn: "Khiri Mat",
          subdistricts: [
            {
              subdistrictNameTh: "โตนด",
              subdistrictNameEn: "Tanot",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "ทุ่งหลวง",
              subdistrictNameEn: "Thung Luang",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "บ้านป้อม",
              subdistrictNameEn: "Ban Pom",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "สามพวง",
              subdistrictNameEn: "Sam Phuang",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "ศรีคีรีมาศ",
              subdistrictNameEn: "Si Khiri Mat",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "หนองจิก",
              subdistrictNameEn: "Nong Chik",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "นาเชิงคีรี",
              subdistrictNameEn: "Na Choeng Khiri",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "หนองกระดิ่ง",
              subdistrictNameEn: "Nong Krading",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "บ้านน้ำพุ",
              subdistrictNameEn: "Ban Namphu",
              postalCode: 64160,
            },
            {
              subdistrictNameTh: "ทุ่งยางเมือง",
              subdistrictNameEn: "Thung Yang Mueang",
              postalCode: 64160,
            },
          ],
        },
        {
          districtNameTh: "กงไกรลาศ",
          districtNameEn: "Kong Krailat",
          subdistricts: [
            {
              subdistrictNameTh: "กง",
              subdistrictNameEn: "Kong",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "บ้านกร่าง",
              subdistrictNameEn: "Ban Krang",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "ไกรนอก",
              subdistrictNameEn: "Krai Nok",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "ไกรกลาง",
              subdistrictNameEn: "Krai Klang",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "ไกรใน",
              subdistrictNameEn: "Krai Nai",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "ดงเดือย",
              subdistrictNameEn: "Dong Dueai",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "ป่าแฝก",
              subdistrictNameEn: "Pa Faek",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "กกแรต",
              subdistrictNameEn: "Kok Raet",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "ท่าฉนวน",
              subdistrictNameEn: "Tha Chanuan",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "หนองตูม",
              subdistrictNameEn: "Nong Tum",
              postalCode: 64170,
            },
            {
              subdistrictNameTh: "บ้านใหม่สุขเกษม",
              subdistrictNameEn: "Ban Mai Suk Kasem",
              postalCode: 64170,
            },
          ],
        },
        {
          districtNameTh: "ศรีสัชนาลัย",
          districtNameEn: "Si Satchanalai",
          subdistricts: [
            {
              subdistrictNameTh: "หาดเสี้ยว",
              subdistrictNameEn: "Hat Siao",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "ป่างิ้ว",
              subdistrictNameEn: "Pa Ngio",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "แม่สำ",
              subdistrictNameEn: "Mae Sam",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "แม่สิน",
              subdistrictNameEn: "Mae Sin",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "บ้านตึก",
              subdistrictNameEn: "Ban Tuek",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "หนองอ้อ",
              subdistrictNameEn: "Nong O",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "ท่าชัย",
              subdistrictNameEn: "Tha Chai",
              postalCode: 64190,
            },
            {
              subdistrictNameTh: "ศรีสัชนาลัย",
              subdistrictNameEn: "Si Satchanalai",
              postalCode: 64190,
            },
            {
              subdistrictNameTh: "ดงคู่",
              subdistrictNameEn: "Dong Khu",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "บ้านแก่ง",
              subdistrictNameEn: "Ban Kaeng",
              postalCode: 64130,
            },
            {
              subdistrictNameTh: "สารจิตร",
              subdistrictNameEn: "Sarachit",
              postalCode: 64130,
            },
          ],
        },
        {
          districtNameTh: "ศรีสำโรง",
          districtNameEn: "Si Samrong",
          subdistricts: [
            {
              subdistrictNameTh: "คลองตาล",
              subdistrictNameEn: "Khlong Tan",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "วังลึก",
              subdistrictNameEn: "Wang Luek",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "สามเรือน",
              subdistrictNameEn: "Sam Ruean",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "นาขุนไกร",
              subdistrictNameEn: "Na Khun Krai",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "เกาะตาเลี้ยง",
              subdistrictNameEn: "Ko Ta Liang",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "วัดเกาะ",
              subdistrictNameEn: "Wat Ko",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "ทับผึ้ง",
              subdistrictNameEn: "Thap Phueng",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "บ้านซ่าน",
              subdistrictNameEn: "Ban San",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "วังใหญ่",
              subdistrictNameEn: "Wang Yai",
              postalCode: 64120,
            },
            {
              subdistrictNameTh: "ราวต้นจันทร์",
              subdistrictNameEn: "Rao Ton Chan",
              postalCode: 64120,
            },
          ],
        },
        {
          districtNameTh: "สวรรคโลก",
          districtNameEn: "Sawankhalok",
          subdistricts: [
            {
              subdistrictNameTh: "เมืองสวรรคโลก",
              subdistrictNameEn: "Mueang Sawankhalok",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "คลองกระจง",
              subdistrictNameEn: "Khlong Krachong",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "วังพิณพาทย์",
              subdistrictNameEn: "Wang Phin Phat",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "วังไม้ขอน",
              subdistrictNameEn: "Wang Mai Khon",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "ย่านยาว",
              subdistrictNameEn: "Yan Yao",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "นาทุ่ง",
              subdistrictNameEn: "Na Thung",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "คลองยาง",
              subdistrictNameEn: "Khlong Yang",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "เมืองบางยม",
              subdistrictNameEn: "Mueang Bang Yom",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "ท่าทอง",
              subdistrictNameEn: "Tha Thong",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "ป่ากุมเกาะ",
              subdistrictNameEn: "Pa Kum Ko",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "เมืองบางขลัง",
              subdistrictNameEn: "Mueang Bang Khlang",
              postalCode: 64110,
            },
            {
              subdistrictNameTh: "หนองกลับ",
              subdistrictNameEn: "Nong Klap",
              postalCode: 64110,
            },
          ],
        },
        {
          districtNameTh: "ศรีนคร",
          districtNameEn: "Si Nakhon",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีนคร",
              subdistrictNameEn: "Si Nakhon",
              postalCode: 64180,
            },
            {
              subdistrictNameTh: "นครเดิฐ",
              subdistrictNameEn: "Nakhon Doet",
              postalCode: 64180,
            },
            {
              subdistrictNameTh: "น้ำขุม",
              subdistrictNameEn: "Nam Khum",
              postalCode: 64180,
            },
            {
              subdistrictNameTh: "คลองมะพลับ",
              subdistrictNameEn: "Khlong Maphlap",
              postalCode: 64180,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 64180,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งเสลี่ยม",
          districtNameEn: "Thung Saliam",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านใหม่ไชยมงคล",
              subdistrictNameEn: "Ban Mai Chai Mongkhon",
              postalCode: 64230,
            },
            {
              subdistrictNameTh: "ไทยชนะศึก",
              subdistrictNameEn: "Thai Chana Suek",
              postalCode: 64150,
            },
            {
              subdistrictNameTh: "ทุ่งเสลี่ยม",
              subdistrictNameEn: "Thung Saliam",
              postalCode: 64150,
            },
            {
              subdistrictNameTh: "กลางดง",
              subdistrictNameEn: "Klang Dong",
              postalCode: 64150,
            },
            {
              subdistrictNameTh: "เขาแก้วศรีสมบูรณ์",
              subdistrictNameEn: "Khao Kaeo Si Sombun",
              postalCode: 64230,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "พิษณุโลก",
      stateNameEn: "Phitsanulok",
      districts: [
        {
          districtNameTh: "เมืองพิษณุโลก",
          districtNameEn: "Mueang Phitsanulok",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "วังน้ำคู้",
              subdistrictNameEn: "Wang Nam Khu",
              postalCode: 65230,
            },
            {
              subdistrictNameTh: "วัดจันทร์",
              subdistrictNameEn: "Wat Chan",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "วัดพริก",
              subdistrictNameEn: "Wat Phrik",
              postalCode: 65230,
            },
            {
              subdistrictNameTh: "ท่าทอง",
              subdistrictNameEn: "Tha Thong",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "ท่าโพธิ์",
              subdistrictNameEn: "Tha Pho",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "สมอแข",
              subdistrictNameEn: "Samo Khae",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "ดอนทอง",
              subdistrictNameEn: "Don Thong",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "บ้านป่า",
              subdistrictNameEn: "Ban Pa",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "ปากโทก",
              subdistrictNameEn: "Pak Thok",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "หัวรอ",
              subdistrictNameEn: "Hua Ro",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "จอมทอง",
              subdistrictNameEn: "Chom Thong",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "บ้านกร่าง",
              subdistrictNameEn: "Ban Krang",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "บ้านคลอง",
              subdistrictNameEn: "Ban Khlong",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "พลายชุมพล",
              subdistrictNameEn: "Phlai Chumphon",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "มะขามสูง",
              subdistrictNameEn: "Makham Sung",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "อรัญญิก",
              subdistrictNameEn: "Aranyik",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "บึงพระ",
              subdistrictNameEn: "Bueng Phra",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "ไผ่ขอดอน",
              subdistrictNameEn: "Phai Kho Don",
              postalCode: 65000,
            },
            {
              subdistrictNameTh: "งิ้วงาม",
              subdistrictNameEn: "Ngio Ngam",
              postalCode: 65230,
            },
          ],
        },
        {
          districtNameTh: "นครไทย",
          districtNameEn: "Nakhon Thai",
          subdistricts: [
            {
              subdistrictNameTh: "นครไทย",
              subdistrictNameEn: "Nakhon Thai",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "หนองกะท้าว",
              subdistrictNameEn: "Nong Kathao",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "บ้านแยง",
              subdistrictNameEn: "Ban Yaeng",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "เนินเพิ่ม",
              subdistrictNameEn: "Noen Phoem",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "นาบัว",
              subdistrictNameEn: "Na Bua",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "นครชุม",
              subdistrictNameEn: "Nakhon Chum",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "น้ำกุ่ม",
              subdistrictNameEn: "Nam Kum",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "ยางโกลน",
              subdistrictNameEn: "Yang Klon",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "บ่อโพธิ์",
              subdistrictNameEn: "Bo Pho",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "บ้านพร้าว",
              subdistrictNameEn: "Ban Phrao",
              postalCode: 65120,
            },
            {
              subdistrictNameTh: "ห้วยเฮี้ย",
              subdistrictNameEn: "Huai Hia",
              postalCode: 65120,
            },
          ],
        },
        {
          districtNameTh: "ชาติตระการ",
          districtNameEn: "Chat Trakan",
          subdistricts: [
            {
              subdistrictNameTh: "ป่าแดง",
              subdistrictNameEn: "Pa Daeng",
              postalCode: 65170,
            },
            {
              subdistrictNameTh: "ชาติตระการ",
              subdistrictNameEn: "Chat Trakan",
              postalCode: 65170,
            },
            {
              subdistrictNameTh: "สวนเมี่ยง",
              subdistrictNameEn: "Suan Miang",
              postalCode: 65170,
            },
            {
              subdistrictNameTh: "บ้านดง",
              subdistrictNameEn: "Ban Dong",
              postalCode: 65170,
            },
            {
              subdistrictNameTh: "บ่อภาค",
              subdistrictNameEn: "Bo Phak",
              postalCode: 65170,
            },
            {
              subdistrictNameTh: "ท่าสะแก",
              subdistrictNameEn: "Tha Sakae",
              postalCode: 65170,
            },
          ],
        },
        {
          districtNameTh: "บางระกำ",
          districtNameEn: "Bang Rakam",
          subdistricts: [
            {
              subdistrictNameTh: "บางระกำ",
              subdistrictNameEn: "Bang Rakam",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "ปลักแรด",
              subdistrictNameEn: "Plak Raet",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "พันเสา",
              subdistrictNameEn: "Phan Sao",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "วังอิทก",
              subdistrictNameEn: "Wang I Thok",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "บึงกอก",
              subdistrictNameEn: "Bueng Kok",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "หนองกุลา",
              subdistrictNameEn: "Nong Kula",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "ชุมแสงสงคราม",
              subdistrictNameEn: "Chum Saeng Songkhram",
              postalCode: 65240,
            },
            {
              subdistrictNameTh: "นิคมพัฒนา",
              subdistrictNameEn: "Nikhom Phatthana",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "บ่อทอง",
              subdistrictNameEn: "Bo Thong",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "ท่านางงาม",
              subdistrictNameEn: "Tha Nang Ngam",
              postalCode: 65140,
            },
            {
              subdistrictNameTh: "คุยม่วง",
              subdistrictNameEn: "Khui Muang",
              postalCode: 65240,
            },
          ],
        },
        {
          districtNameTh: "บางกระทุ่ม",
          districtNameEn: "Bang Krathum",
          subdistricts: [
            {
              subdistrictNameTh: "บางกระทุ่ม",
              subdistrictNameEn: "Bang Krathum",
              postalCode: 65110,
            },
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 65110,
            },
            {
              subdistrictNameTh: "โคกสลุด",
              subdistrictNameEn: "Khok Salut",
              postalCode: 65110,
            },
            {
              subdistrictNameTh: "สนามคลี",
              subdistrictNameEn: "Sanam Khli",
              postalCode: 65110,
            },
            {
              subdistrictNameTh: "ท่าตาล",
              subdistrictNameEn: "Tha Tan",
              postalCode: 65110,
            },
            {
              subdistrictNameTh: "ไผ่ล้อม",
              subdistrictNameEn: "Phai Lom",
              postalCode: 65110,
            },
            {
              subdistrictNameTh: "นครป่าหมาก",
              subdistrictNameEn: "Nakhon Pa Mak",
              postalCode: 65110,
            },
            {
              subdistrictNameTh: "เนินกุ่ม",
              subdistrictNameEn: "Noen Kum",
              postalCode: 65210,
            },
            {
              subdistrictNameTh: "วัดตายม",
              subdistrictNameEn: "Wat Ta Yom",
              postalCode: 65210,
            },
          ],
        },
        {
          districtNameTh: "พรหมพิราม",
          districtNameEn: "Phrom Phiram",
          subdistricts: [
            {
              subdistrictNameTh: "พรหมพิราม",
              subdistrictNameEn: "Phrom Phiram",
              postalCode: 65150,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 65150,
            },
            {
              subdistrictNameTh: "วงฆ้อง",
              subdistrictNameEn: "Wong Khong",
              postalCode: 65180,
            },
            {
              subdistrictNameTh: "มะตูม",
              subdistrictNameEn: "Matum",
              postalCode: 65150,
            },
            {
              subdistrictNameTh: "หอกลอง",
              subdistrictNameEn: "Ho Klong",
              postalCode: 65150,
            },
            {
              subdistrictNameTh: "ศรีภิรมย์",
              subdistrictNameEn: "Si Phirom",
              postalCode: 65180,
            },
            {
              subdistrictNameTh: "ตลุกเทียม",
              subdistrictNameEn: "Taluk Thiam",
              postalCode: 65180,
            },
            {
              subdistrictNameTh: "วังวน",
              subdistrictNameEn: "Wang Won",
              postalCode: 65150,
            },
            {
              subdistrictNameTh: "หนองแขม",
              subdistrictNameEn: "Nong Khaem",
              postalCode: 65150,
            },
            {
              subdistrictNameTh: "มะต้อง",
              subdistrictNameEn: "Matong",
              postalCode: 65180,
            },
            {
              subdistrictNameTh: "ทับยายเชียง",
              subdistrictNameEn: "Thap Yai Chiang",
              postalCode: 65150,
            },
            {
              subdistrictNameTh: "ดงประคำ",
              subdistrictNameEn: "Dong Prakham",
              postalCode: 65180,
            },
          ],
        },
        {
          districtNameTh: "วัดโบสถ์",
          districtNameEn: "Wat Bot",
          subdistricts: [
            {
              subdistrictNameTh: "วัดโบสถ์",
              subdistrictNameEn: "Wat Bot",
              postalCode: 65160,
            },
            {
              subdistrictNameTh: "ท่างาม",
              subdistrictNameEn: "Tha Ngam",
              postalCode: 65160,
            },
            {
              subdistrictNameTh: "ท้อแท้",
              subdistrictNameEn: "Tho Thae",
              postalCode: 65160,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 65160,
            },
            {
              subdistrictNameTh: "หินลาด",
              subdistrictNameEn: "Hin Lat",
              postalCode: 65160,
            },
            {
              subdistrictNameTh: "คันโช้ง",
              subdistrictNameEn: "Khan Chong",
              postalCode: 65160,
            },
          ],
        },
        {
          districtNameTh: "วังทอง",
          districtNameEn: "Wang Thong",
          subdistricts: [
            {
              subdistrictNameTh: "วังทอง",
              subdistrictNameEn: "Wang Thong",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "พันชาลี",
              subdistrictNameEn: "Phan Chali",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "แม่ระกา",
              subdistrictNameEn: "Mae Raka",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 65220,
            },
            {
              subdistrictNameTh: "วังพิกุล",
              subdistrictNameEn: "Wang Phikun",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "แก่งโสภา",
              subdistrictNameEn: "Kaeng Sopha",
              postalCode: 65220,
            },
            {
              subdistrictNameTh: "ท่าหมื่นราม",
              subdistrictNameEn: "Tha Muen Ram",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "วังนกแอ่น",
              subdistrictNameEn: "Wang Nok Aen",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "หนองพระ",
              subdistrictNameEn: "Nong Phra",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "ชัยนาม",
              subdistrictNameEn: "Chai Nam",
              postalCode: 65130,
            },
            {
              subdistrictNameTh: "ดินทอง",
              subdistrictNameEn: "Din Thong",
              postalCode: 65130,
            },
          ],
        },
        {
          districtNameTh: "เนินมะปราง",
          districtNameEn: "Noen Maprang",
          subdistricts: [
            {
              subdistrictNameTh: "ชมพู",
              subdistrictNameEn: "Chomphu",
              postalCode: 65190,
            },
            {
              subdistrictNameTh: "บ้านมุง",
              subdistrictNameEn: "Ban Mung",
              postalCode: 65190,
            },
            {
              subdistrictNameTh: "ไทรย้อย",
              subdistrictNameEn: "Sai Yoi",
              postalCode: 65190,
            },
            {
              subdistrictNameTh: "วังโพรง",
              subdistrictNameEn: "Wang Phrong",
              postalCode: 65190,
            },
            {
              subdistrictNameTh: "บ้านน้อยซุ้มขี้เหล็ก",
              subdistrictNameEn: "Ban Noi Sum Khi Lek",
              postalCode: 65190,
            },
            {
              subdistrictNameTh: "เนินมะปราง",
              subdistrictNameEn: "Noen Maprang",
              postalCode: 65190,
            },
            {
              subdistrictNameTh: "วังยาง",
              subdistrictNameEn: "Wang Yang",
              postalCode: 65190,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "พิจิตร",
      stateNameEn: "Phichit",
      districts: [
        {
          districtNameTh: "เมืองพิจิตร",
          districtNameEn: "Mueang Phichit",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ไผ่ขวาง",
              subdistrictNameEn: "Phai Khwang",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ย่านยาว",
              subdistrictNameEn: "Yan Yao",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ท่าฬ่อ",
              subdistrictNameEn: "Tha Lo",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ปากทาง",
              subdistrictNameEn: "Pak Thang",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "คลองคะเชนทร์",
              subdistrictNameEn: "Khlong Khachen",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "โรงช้าง",
              subdistrictNameEn: "Rong Chang",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "เมืองเก่า",
              subdistrictNameEn: "Mueang Kao",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ท่าหลวง",
              subdistrictNameEn: "Tha Luang",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "บ้านบุ่ง",
              subdistrictNameEn: "Ban Bung",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ฆะมัง",
              subdistrictNameEn: "Kha Mang",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ดงป่าคำ",
              subdistrictNameEn: "Dong Pa Kham",
              postalCode: 66170,
            },
            {
              subdistrictNameTh: "หัวดง",
              subdistrictNameEn: "Hua Dong",
              postalCode: 66170,
            },
            {
              subdistrictNameTh: "ป่ามะคาบ",
              subdistrictNameEn: "Pa Makhap",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "สายคำโห้",
              subdistrictNameEn: "Sai Kham Ho",
              postalCode: 66000,
            },
            {
              subdistrictNameTh: "ดงกลาง",
              subdistrictNameEn: "Dong Klang",
              postalCode: 66170,
            },
          ],
        },
        {
          districtNameTh: "วังทรายพูน",
          districtNameEn: "Wang Sai Phun",
          subdistricts: [
            {
              subdistrictNameTh: "วังทรายพูน",
              subdistrictNameEn: "Wang Sai Phun",
              postalCode: 66180,
            },
            {
              subdistrictNameTh: "หนองปลาไหล",
              subdistrictNameEn: "Nong Pla Lai",
              postalCode: 66180,
            },
            {
              subdistrictNameTh: "หนองพระ",
              subdistrictNameEn: "Nong Phra",
              postalCode: 66180,
            },
            {
              subdistrictNameTh: "หนองปล้อง",
              subdistrictNameEn: "Nong Plong",
              postalCode: 66180,
            },
          ],
        },
        {
          districtNameTh: "โพธิ์ประทับช้าง",
          districtNameEn: "Pho Prathap Chang",
          subdistricts: [
            {
              subdistrictNameTh: "โพธิ์ประทับช้าง",
              subdistrictNameEn: "Pho Prathap Chang",
              postalCode: 66190,
            },
            {
              subdistrictNameTh: "ไผ่ท่าโพ",
              subdistrictNameEn: "Phai Tha Pho",
              postalCode: 66190,
            },
            {
              subdistrictNameTh: "วังจิก",
              subdistrictNameEn: "Wang Chik",
              postalCode: 66190,
            },
            {
              subdistrictNameTh: "ไผ่รอบ",
              subdistrictNameEn: "Phai Rop",
              postalCode: 66190,
            },
            {
              subdistrictNameTh: "ดงเสือเหลือง",
              subdistrictNameEn: "Dong Suea Lueang",
              postalCode: 66190,
            },
            {
              subdistrictNameTh: "เนินสว่าง",
              subdistrictNameEn: "Noen Sawang",
              postalCode: 66190,
            },
            {
              subdistrictNameTh: "ทุ่งใหญ่",
              subdistrictNameEn: "Thung Yai",
              postalCode: 66190,
            },
          ],
        },
        {
          districtNameTh: "ตะพานหิน",
          districtNameEn: "Taphan Hin",
          subdistricts: [
            {
              subdistrictNameTh: "ตะพานหิน",
              subdistrictNameEn: "Taphan Hin",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "งิ้วราย",
              subdistrictNameEn: "Ngio Rai",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "ห้วยเกตุ",
              subdistrictNameEn: "Huai Ket",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "ไทรโรงโขน",
              subdistrictNameEn: "Sai Rong Khon",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "หนองพยอม",
              subdistrictNameEn: "Nong Phayom",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "ทุ่งโพธิ์",
              subdistrictNameEn: "Thung Pho",
              postalCode: 66150,
            },
            {
              subdistrictNameTh: "ดงตะขบ",
              subdistrictNameEn: "Dong Takhop",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "คลองคูณ",
              subdistrictNameEn: "Khlong Khun",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "วังสำโรง",
              subdistrictNameEn: "Wang Samrong",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "วังหว้า",
              subdistrictNameEn: "Wang Wa",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "วังหลุม",
              subdistrictNameEn: "Wang Lum",
              postalCode: 66150,
            },
            {
              subdistrictNameTh: "ทับหมัน",
              subdistrictNameEn: "Thap Man",
              postalCode: 66110,
            },
            {
              subdistrictNameTh: "ไผ่หลวง",
              subdistrictNameEn: "Phai Luang",
              postalCode: 66110,
            },
          ],
        },
        {
          districtNameTh: "บางมูลนาก",
          districtNameEn: "Bang Mun Nak",
          subdistricts: [
            {
              subdistrictNameTh: "บางมูลนาก",
              subdistrictNameEn: "Bang Mun Nak",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "บางไผ่",
              subdistrictNameEn: "Bang Phai",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "หอไกร",
              subdistrictNameEn: "Ho Krai",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "เนินมะกอก",
              subdistrictNameEn: "Noen Makok",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "วังสำโรง",
              subdistrictNameEn: "Wang Samrong",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "ภูมิ",
              subdistrictNameEn: "Phum",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "วังกรด",
              subdistrictNameEn: "Wang Krot",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "ห้วยเขน",
              subdistrictNameEn: "Huai Khen",
              postalCode: 66120,
            },
            {
              subdistrictNameTh: "วังตะกู",
              subdistrictNameEn: "Wang Taku",
              postalCode: 66210,
            },
            {
              subdistrictNameTh: "ลำประดา",
              subdistrictNameEn: "Lam Prada",
              postalCode: 66120,
            },
          ],
        },
        {
          districtNameTh: "โพทะเล",
          districtNameEn: "Pho Thale",
          subdistricts: [
            {
              subdistrictNameTh: "โพทะเล",
              subdistrictNameEn: "Pho Thale",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "ท้ายน้ำ",
              subdistrictNameEn: "Thai Nam",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "ทะนง",
              subdistrictNameEn: "Tha Nong",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "ท่าบัว",
              subdistrictNameEn: "Tha Bua",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "ทุ่งน้อย",
              subdistrictNameEn: "Thung Noi",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "ท่าขมิ้น",
              subdistrictNameEn: "Tha Khamin",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "ท่าเสา",
              subdistrictNameEn: "Tha Sao",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "บางคลาน",
              subdistrictNameEn: "Bang Khlan",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "ท่านั่ง",
              subdistrictNameEn: "Tha Nang",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "บ้านน้อย",
              subdistrictNameEn: "Ban Noi",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "วัดขวาง",
              subdistrictNameEn: "Wat Khwang",
              postalCode: 66130,
            },
          ],
        },
        {
          districtNameTh: "สามง่าม",
          districtNameEn: "Sam Ngam",
          subdistricts: [
            {
              subdistrictNameTh: "สามง่าม",
              subdistrictNameEn: "Sam Ngam",
              postalCode: 66140,
            },
            {
              subdistrictNameTh: "กำแพงดิน",
              subdistrictNameEn: "Kamphaeng Din",
              postalCode: 66140,
            },
            {
              subdistrictNameTh: "รังนก",
              subdistrictNameEn: "Rang Nok",
              postalCode: 66140,
            },
            {
              subdistrictNameTh: "เนินปอ",
              subdistrictNameEn: "Noen Po",
              postalCode: 66140,
            },
            {
              subdistrictNameTh: "หนองโสน",
              subdistrictNameEn: "Nong Sano",
              postalCode: 66140,
            },
          ],
        },
        {
          districtNameTh: "ทับคล้อ",
          districtNameEn: "Tap Khlo",
          subdistricts: [
            {
              subdistrictNameTh: "ทับคล้อ",
              subdistrictNameEn: "Thap Khlo",
              postalCode: 66150,
            },
            {
              subdistrictNameTh: "เขาทราย",
              subdistrictNameEn: "Khao Sai",
              postalCode: 66230,
            },
            {
              subdistrictNameTh: "เขาเจ็ดลูก",
              subdistrictNameEn: "Khao Chet Luk",
              postalCode: 66230,
            },
            {
              subdistrictNameTh: "ท้ายทุ่ง",
              subdistrictNameEn: "Thai Thung",
              postalCode: 66150,
            },
          ],
        },
        {
          districtNameTh: "สากเหล็ก",
          districtNameEn: "Sak Lek",
          subdistricts: [
            {
              subdistrictNameTh: "สากเหล็ก",
              subdistrictNameEn: "Sak Lek",
              postalCode: 66160,
            },
            {
              subdistrictNameTh: "ท่าเยี่ยม",
              subdistrictNameEn: "Tha Yiam",
              postalCode: 66160,
            },
            {
              subdistrictNameTh: "คลองทราย",
              subdistrictNameEn: "Khlong Sai",
              postalCode: 66160,
            },
            {
              subdistrictNameTh: "หนองหญ้าไทร",
              subdistrictNameEn: "Nong Ya Sai",
              postalCode: 66160,
            },
            {
              subdistrictNameTh: "วังทับไทร",
              subdistrictNameEn: "Wang Thap Sai",
              postalCode: 66160,
            },
          ],
        },
        {
          districtNameTh: "บึงนาราง",
          districtNameEn: "Bueng Na Rang",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยแก้ว",
              subdistrictNameEn: "Huai Kaeo",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "โพธิ์ไทรงาม",
              subdistrictNameEn: "Pho Sai Ngam",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "แหลมรัง",
              subdistrictNameEn: "Laem Rang",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "บางลาย",
              subdistrictNameEn: "Bang Lai",
              postalCode: 66130,
            },
            {
              subdistrictNameTh: "บึงนาราง",
              subdistrictNameEn: "Bueng Na Rang",
              postalCode: 66130,
            },
          ],
        },
        {
          districtNameTh: "ดงเจริญ",
          districtNameEn: "Dong Charoen",
          subdistricts: [
            {
              subdistrictNameTh: "วังงิ้วใต้",
              subdistrictNameEn: "Wang Ngio Tai",
              postalCode: 66210,
            },
            {
              subdistrictNameTh: "วังงิ้ว",
              subdistrictNameEn: "Wang Ngio",
              postalCode: 66210,
            },
            {
              subdistrictNameTh: "ห้วยร่วม",
              subdistrictNameEn: "Huai Ruam",
              postalCode: 66210,
            },
            {
              subdistrictNameTh: "ห้วยพุก",
              subdistrictNameEn: "Huai Phuk",
              postalCode: 66210,
            },
            {
              subdistrictNameTh: "สำนักขุนเณร",
              subdistrictNameEn: "Samnak Khun Nen",
              postalCode: 66210,
            },
          ],
        },
        {
          districtNameTh: "วชิรบารมี",
          districtNameEn: "Wachirabarami",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 66140,
            },
            {
              subdistrictNameTh: "บึงบัว",
              subdistrictNameEn: "Bueng Bua",
              postalCode: 66140,
            },
            {
              subdistrictNameTh: "วังโมกข์",
              subdistrictNameEn: "Wang Mok",
              postalCode: 66140,
            },
            {
              subdistrictNameTh: "หนองหลุม",
              subdistrictNameEn: "Nong Lum",
              postalCode: 66220,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "เพชรบูรณ์",
      stateNameEn: "Phetchabun",
      districts: [
        {
          districtNameTh: "เมืองเพชรบูรณ์",
          districtNameEn: "Mueang Phetchabun",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "ตะเบาะ",
              subdistrictNameEn: "Tabo",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "บ้านโตก",
              subdistrictNameEn: "Ban Tok",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "สะเดียง",
              subdistrictNameEn: "Sadiang",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "ป่าเลา",
              subdistrictNameEn: "Pa Lao",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "นางั่ว",
              subdistrictNameEn: "Na Ngua",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "ท่าพล",
              subdistrictNameEn: "Tha Phon",
              postalCode: 67250,
            },
            {
              subdistrictNameTh: "ดงมูลเหล็ก",
              subdistrictNameEn: "Dong Mun Lek",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "บ้านโคก",
              subdistrictNameEn: "Ban Khok",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "ชอนไพร",
              subdistrictNameEn: "Chon Phrai",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "นาป่า",
              subdistrictNameEn: "Na Pa",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "นายม",
              subdistrictNameEn: "Na Yom",
              postalCode: 67210,
            },
            {
              subdistrictNameTh: "วังชมภู",
              subdistrictNameEn: "Wang Chomphu",
              postalCode: 67210,
            },
            {
              subdistrictNameTh: "น้ำร้อน",
              subdistrictNameEn: "Nam Ron",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "ห้วยสะแก",
              subdistrictNameEn: "Huai Sakae",
              postalCode: 67210,
            },
            {
              subdistrictNameTh: "ห้วยใหญ่",
              subdistrictNameEn: "Huai Yai",
              postalCode: 67000,
            },
            {
              subdistrictNameTh: "ระวิง",
              subdistrictNameEn: "Rawing",
              postalCode: 67210,
            },
          ],
        },
        {
          districtNameTh: "ชนแดน",
          districtNameEn: "Chon Daen",
          subdistricts: [
            {
              subdistrictNameTh: "ชนแดน",
              subdistrictNameEn: "Chon Daen",
              postalCode: 67150,
            },
            {
              subdistrictNameTh: "ดงขุย",
              subdistrictNameEn: "Dong Khu",
              postalCode: 67190,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 67150,
            },
            {
              subdistrictNameTh: "พุทธบาท",
              subdistrictNameEn: "Phutthabat",
              postalCode: 67150,
            },
            {
              subdistrictNameTh: "ลาดแค",
              subdistrictNameEn: "Lat Khae",
              postalCode: 67150,
            },
            {
              subdistrictNameTh: "บ้านกล้วย",
              subdistrictNameEn: "Ban Kluai",
              postalCode: 67190,
            },
            {
              subdistrictNameTh: "ซับพุทรา",
              subdistrictNameEn: "Sap Phutsa",
              postalCode: 67150,
            },
            {
              subdistrictNameTh: "ตะกุดไร",
              subdistrictNameEn: "Takut Lai",
              postalCode: 67190,
            },
            {
              subdistrictNameTh: "ศาลาลาย",
              subdistrictNameEn: "Sala Lai",
              postalCode: 67150,
            },
          ],
        },
        {
          districtNameTh: "หล่มสัก",
          districtNameEn: "Lom Sak",
          subdistricts: [
            {
              subdistrictNameTh: "หล่มสัก",
              subdistrictNameEn: "Lom Sak",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "วัดป่า",
              subdistrictNameEn: "Wat Pa",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ตาลเดี่ยว",
              subdistrictNameEn: "Tan Diao",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ฝายนาแซง",
              subdistrictNameEn: "Fai Na Saeng",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "หนองสว่าง",
              subdistrictNameEn: "Nong Sawang",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "น้ำเฮี้ย",
              subdistrictNameEn: "Nam Hia",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "สักหลง",
              subdistrictNameEn: "Sak Long",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ท่าอิบุญ",
              subdistrictNameEn: "Tha I Bun",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "บ้านโสก",
              subdistrictNameEn: "Ban Sok",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "บ้านติ้ว",
              subdistrictNameEn: "Ban Tio",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ห้วยไร่",
              subdistrictNameEn: "Huai Rai",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "น้ำก้อ",
              subdistrictNameEn: "Nam Ko",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ปากช่อง",
              subdistrictNameEn: "Pak Chong",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "น้ำชุน",
              subdistrictNameEn: "Nam Chun",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "หนองไขว่",
              subdistrictNameEn: "Nong Khwai",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ลานบ่า",
              subdistrictNameEn: "Lan Ba",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "บุ่งคล้า",
              subdistrictNameEn: "Bung Khla",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "บุ่งน้ำเต้า",
              subdistrictNameEn: "Bung Namtao",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ช้างตะลูด",
              subdistrictNameEn: "Chang Talut",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "ปากดุก",
              subdistrictNameEn: "Pak Duk",
              postalCode: 67110,
            },
            {
              subdistrictNameTh: "บ้านหวาย",
              subdistrictNameEn: "Ban Wai",
              postalCode: 67110,
            },
          ],
        },
        {
          districtNameTh: "หล่มเก่า",
          districtNameEn: "Lom Kao",
          subdistricts: [
            {
              subdistrictNameTh: "หล่มเก่า",
              subdistrictNameEn: "Lom Kao",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "นาซำ",
              subdistrictNameEn: "Na Sam",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "หินฮาว",
              subdistrictNameEn: "Hin Hao",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "บ้านเนิน",
              subdistrictNameEn: "Ban Noen",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "ศิลา",
              subdistrictNameEn: "Sila",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "นาแซง",
              subdistrictNameEn: "Na Saeng",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "วังบาล",
              subdistrictNameEn: "Wang Ban",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "นาเกาะ",
              subdistrictNameEn: "Na Ko",
              postalCode: 67120,
            },
            {
              subdistrictNameTh: "ตาดกลอย",
              subdistrictNameEn: "Tat Kloi",
              postalCode: 67120,
            },
          ],
        },
        {
          districtNameTh: "วิเชียรบุรี",
          districtNameEn: "Wichian Buri",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าโรง",
              subdistrictNameEn: "Tha Rong",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "สระประดู่",
              subdistrictNameEn: "Sa Pradu",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "สามแยก",
              subdistrictNameEn: "Sam Yaek",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "โคกปรง",
              subdistrictNameEn: "Khok Prong",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "น้ำร้อน",
              subdistrictNameEn: "Nam Ron",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "บ่อรัง",
              subdistrictNameEn: "Bo Rang",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "พุเตย",
              subdistrictNameEn: "Phu Toei",
              postalCode: 67180,
            },
            {
              subdistrictNameTh: "พุขาม",
              subdistrictNameEn: "Phu Kham",
              postalCode: 67180,
            },
            {
              subdistrictNameTh: "ภูน้ำหยด",
              subdistrictNameEn: "Phu Nam Yot",
              postalCode: 67180,
            },
            {
              subdistrictNameTh: "ซับสมบูรณ์",
              subdistrictNameEn: "Sap Sombun",
              postalCode: 67180,
            },
            {
              subdistrictNameTh: "บึงกระจับ",
              subdistrictNameEn: "Bueng Krachap",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "วังใหญ่",
              subdistrictNameEn: "Wang Yai",
              postalCode: 67180,
            },
            {
              subdistrictNameTh: "ยางสาว",
              subdistrictNameEn: "Yang Sao",
              postalCode: 67130,
            },
            {
              subdistrictNameTh: "ซับน้อย",
              subdistrictNameEn: "Sap Noi",
              postalCode: 67180,
            },
          ],
        },
        {
          districtNameTh: "ศรีเทพ",
          districtNameEn: "Si Thep",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีเทพ",
              subdistrictNameEn: "Si Thep",
              postalCode: 67170,
            },
            {
              subdistrictNameTh: "สระกรวด",
              subdistrictNameEn: "Sa Kruat",
              postalCode: 67170,
            },
            {
              subdistrictNameTh: "คลองกระจัง",
              subdistrictNameEn: "Khlong Krachang",
              postalCode: 67170,
            },
            {
              subdistrictNameTh: "นาสนุ่น",
              subdistrictNameEn: "Na Sanun",
              postalCode: 67170,
            },
            {
              subdistrictNameTh: "โคกสะอาด",
              subdistrictNameEn: "Khok Sa-At",
              postalCode: 67170,
            },
            {
              subdistrictNameTh: "หนองย่างทอย",
              subdistrictNameEn: "Nong Yang Thoi",
              postalCode: 67170,
            },
            {
              subdistrictNameTh: "ประดู่งาม",
              subdistrictNameEn: "Pradu Ngam",
              postalCode: 67170,
            },
          ],
        },
        {
          districtNameTh: "หนองไผ่",
          districtNameEn: "Nong Phai",
          subdistricts: [
            {
              subdistrictNameTh: "กองทูล",
              subdistrictNameEn: "Kong Thun",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "นาเฉลียง",
              subdistrictNameEn: "Na Chaliang",
              postalCode: 67220,
            },
            {
              subdistrictNameTh: "บ้านโภชน์",
              subdistrictNameEn: "Ban Phot",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "ท่าแดง",
              subdistrictNameEn: "Tha Daeng",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "เพชรละคร",
              subdistrictNameEn: "Phet Lakhon",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "บ่อไทย",
              subdistrictNameEn: "Bo Thai",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "ห้วยโป่ง",
              subdistrictNameEn: "Huai Pong",
              postalCode: 67220,
            },
            {
              subdistrictNameTh: "วังท่าดี",
              subdistrictNameEn: "Wang Tha Di",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "บัววัฒนา",
              subdistrictNameEn: "Bua Watthana",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "วังโบสถ์",
              subdistrictNameEn: "Wang Bot",
              postalCode: 67140,
            },
            {
              subdistrictNameTh: "ยางงาม",
              subdistrictNameEn: "Yang Ngam",
              postalCode: 67220,
            },
            {
              subdistrictNameTh: "ท่าด้วง",
              subdistrictNameEn: "Tha Duang",
              postalCode: 67140,
            },
          ],
        },
        {
          districtNameTh: "บึงสามพัน",
          districtNameEn: "Bueng Sam Phan",
          subdistricts: [
            {
              subdistrictNameTh: "ซับสมอทอด",
              subdistrictNameEn: "Sap Samo Thot",
              postalCode: 67160,
            },
            {
              subdistrictNameTh: "ซับไม้แดง",
              subdistrictNameEn: "Sap Mai Daeng",
              postalCode: 67160,
            },
            {
              subdistrictNameTh: "หนองแจง",
              subdistrictNameEn: "Nong Chaeng",
              postalCode: 67160,
            },
            {
              subdistrictNameTh: "กันจุ",
              subdistrictNameEn: "Kan Chu",
              postalCode: 67160,
            },
            {
              subdistrictNameTh: "วังพิกุล",
              subdistrictNameEn: "Wang Phikun",
              postalCode: 67230,
            },
            {
              subdistrictNameTh: "พญาวัง",
              subdistrictNameEn: "Phaya Wang",
              postalCode: 67160,
            },
            {
              subdistrictNameTh: "ศรีมงคล",
              subdistrictNameEn: "Si Mongkhon",
              postalCode: 67160,
            },
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 67160,
            },
            {
              subdistrictNameTh: "บึงสามพัน",
              subdistrictNameEn: "Bueng Sam Phan",
              postalCode: 67160,
            },
          ],
        },
        {
          districtNameTh: "น้ำหนาว",
          districtNameEn: "Nam Nao",
          subdistricts: [
            {
              subdistrictNameTh: "น้ำหนาว",
              subdistrictNameEn: "Nam Nao",
              postalCode: 67260,
            },
            {
              subdistrictNameTh: "หลักด่าน",
              subdistrictNameEn: "Lak Dan",
              postalCode: 67260,
            },
            {
              subdistrictNameTh: "วังกวาง",
              subdistrictNameEn: "Wang Kwang",
              postalCode: 67260,
            },
            {
              subdistrictNameTh: "โคกมน",
              subdistrictNameEn: "Khok Mon",
              postalCode: 67260,
            },
          ],
        },
        {
          districtNameTh: "วังโป่ง",
          districtNameEn: "Wang Pong",
          subdistricts: [
            {
              subdistrictNameTh: "วังโป่ง",
              subdistrictNameEn: "Wang Pong",
              postalCode: 67240,
            },
            {
              subdistrictNameTh: "ท้ายดง",
              subdistrictNameEn: "Thai Dong",
              postalCode: 67240,
            },
            {
              subdistrictNameTh: "ซับเปิบ",
              subdistrictNameEn: "Sap Poep",
              postalCode: 67240,
            },
            {
              subdistrictNameTh: "วังหิน",
              subdistrictNameEn: "Wang Hin",
              postalCode: 67240,
            },
            {
              subdistrictNameTh: "วังศาล",
              subdistrictNameEn: "Wang San",
              postalCode: 67240,
            },
          ],
        },
        {
          districtNameTh: "เขาค้อ",
          districtNameEn: "Khao Kho",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งสมอ",
              subdistrictNameEn: "Thung Samo",
              postalCode: 67270,
            },
            {
              subdistrictNameTh: "แคมป์สน",
              subdistrictNameEn: "Khaem Son",
              postalCode: 67280,
            },
            {
              subdistrictNameTh: "เขาค้อ",
              subdistrictNameEn: "Khao Kho",
              postalCode: 67270,
            },
            {
              subdistrictNameTh: "ริมสีม่วง",
              subdistrictNameEn: "Rim Si Muang",
              postalCode: 67270,
            },
            {
              subdistrictNameTh: "สะเดาะพง",
              subdistrictNameEn: "Sadao Phong",
              postalCode: 67270,
            },
            {
              subdistrictNameTh: "หนองแม่นา",
              subdistrictNameEn: "Nong Mae Na",
              postalCode: 67270,
            },
            {
              subdistrictNameTh: "เข็กน้อย",
              subdistrictNameEn: "Khek Noi",
              postalCode: 67280,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ราชบุรี",
      stateNameEn: "Ratchaburi",
      districts: [
        {
          districtNameTh: "เมืองราชบุรี",
          districtNameEn: "Mueang Ratchaburi",
          subdistricts: [
            {
              subdistrictNameTh: "หน้าเมือง",
              subdistrictNameEn: "Na Mueang",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "เจดีย์หัก",
              subdistrictNameEn: "Chedi Hak",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "ดอนตะโก",
              subdistrictNameEn: "Don Tako",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "หนองกลางนา",
              subdistrictNameEn: "Nong Klang Na",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "ห้วยไผ่",
              subdistrictNameEn: "Huai Phai",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "คุ้งน้ำวน",
              subdistrictNameEn: "Khung Nam Won",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "คุ้งกระถิน",
              subdistrictNameEn: "Khung Krathin",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "อ่างทอง",
              subdistrictNameEn: "Ang Thong",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "โคกหม้อ",
              subdistrictNameEn: "Khok Mo",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "สามเรือน",
              subdistrictNameEn: "Sam Ruean",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "พิกุลทอง",
              subdistrictNameEn: "Phikun Thong",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "น้ำพุ",
              subdistrictNameEn: "Namphu",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "ดอนแร่",
              subdistrictNameEn: "Don Rae",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "หินกอง",
              subdistrictNameEn: "Hin Kong",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "เขาแร้ง",
              subdistrictNameEn: "Khao Raeng",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "เกาะพลับพลา",
              subdistrictNameEn: "Ko Phlapphla",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "หลุมดิน",
              subdistrictNameEn: "Lum Din",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "บางป่า",
              subdistrictNameEn: "Bang Pa",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "พงสวาย",
              subdistrictNameEn: "Phong Sawai",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "คูบัว",
              subdistrictNameEn: "Khu Bua",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "ท่าราบ",
              subdistrictNameEn: "Tha Rap",
              postalCode: 70000,
            },
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 70000,
            },
          ],
        },
        {
          districtNameTh: "จอมบึง",
          districtNameEn: "Chom Bueng",
          subdistricts: [
            {
              subdistrictNameTh: "จอมบึง",
              subdistrictNameEn: "Chom Bueng",
              postalCode: 70150,
            },
            {
              subdistrictNameTh: "ปากช่อง",
              subdistrictNameEn: "Pak Chong",
              postalCode: 70150,
            },
            {
              subdistrictNameTh: "เบิกไพร",
              subdistrictNameEn: "Boek Phrai",
              postalCode: 70150,
            },
            {
              subdistrictNameTh: "ด่านทับตะโก",
              subdistrictNameEn: "Dan Thap Tako",
              postalCode: 70150,
            },
            {
              subdistrictNameTh: "แก้มอ้น",
              subdistrictNameEn: "Kaem On",
              postalCode: 70150,
            },
            {
              subdistrictNameTh: "รางบัว",
              subdistrictNameEn: "Rang Bua",
              postalCode: 70150,
            },
          ],
        },
        {
          districtNameTh: "สวนผึ้ง",
          districtNameEn: "Suan Phueng",
          subdistricts: [
            {
              subdistrictNameTh: "สวนผึ้ง",
              subdistrictNameEn: "Suan Phueng",
              postalCode: 70180,
            },
            {
              subdistrictNameTh: "ป่าหวาย",
              subdistrictNameEn: "Pa Wai",
              postalCode: 70180,
            },
            {
              subdistrictNameTh: "ท่าเคย",
              subdistrictNameEn: "Tha Khoei",
              postalCode: 70180,
            },
            {
              subdistrictNameTh: "ตะนาวศรี",
              subdistrictNameEn: "Tanao Si",
              postalCode: 70180,
            },
          ],
        },
        {
          districtNameTh: "ดำเนินสะดวก",
          districtNameEn: "Damnoen Saduak",
          subdistricts: [
            {
              subdistrictNameTh: "ดำเนินสะดวก",
              subdistrictNameEn: "Damnoen Saduak",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "ประสาทสิทธิ์",
              subdistrictNameEn: "Prasatsit",
              postalCode: 70210,
            },
            {
              subdistrictNameTh: "ศรีสุราษฎร์",
              subdistrictNameEn: "Si Surat",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "ตาหลวง",
              subdistrictNameEn: "Ta Luang",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "ดอนกรวย",
              subdistrictNameEn: "Don Kruai",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "ดอนคลัง",
              subdistrictNameEn: "Don Khlang",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "บัวงาม",
              subdistrictNameEn: "Bua Ngam",
              postalCode: 70210,
            },
            {
              subdistrictNameTh: "บ้านไร่",
              subdistrictNameEn: "Ban Rai",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "แพงพวย",
              subdistrictNameEn: "Phaeng Phuai",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "สี่หมื่น",
              subdistrictNameEn: "Si Muen",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "ท่านัด",
              subdistrictNameEn: "Tha Nat",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "ขุนพิทักษ์",
              subdistrictNameEn: "Khun Phithak",
              postalCode: 70130,
            },
            {
              subdistrictNameTh: "ดอนไผ่",
              subdistrictNameEn: "Don Phi",
              postalCode: 70130,
            },
          ],
        },
        {
          districtNameTh: "บ้านโป่ง",
          districtNameEn: "Ban Pong",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านโป่ง",
              subdistrictNameEn: "Ban Pong",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "ท่าผา",
              subdistrictNameEn: "Tha Pha",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "กรับใหญ่",
              subdistrictNameEn: "Krap Yai",
              postalCode: 70190,
            },
            {
              subdistrictNameTh: "ปากแรต",
              subdistrictNameEn: "Pak Raet",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "หนองกบ",
              subdistrictNameEn: "Nong Kop",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "หนองอ้อ",
              subdistrictNameEn: "Nong O",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "ดอนกระเบื้อง",
              subdistrictNameEn: "Don Krabueang",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "สวนกล้วย",
              subdistrictNameEn: "Suan Kluai",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "นครชุมน์",
              subdistrictNameEn: "Nakhon Chum",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "บ้านม่วง",
              subdistrictNameEn: "Ban Muang",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "คุ้งพยอม",
              subdistrictNameEn: "Khung Phayom",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "หนองปลาหมอ",
              subdistrictNameEn: "Nong Pla Mo",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "เขาขลุง",
              subdistrictNameEn: "Khao Khlung",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "เบิกไพร",
              subdistrictNameEn: "Boek Phrai",
              postalCode: 70110,
            },
            {
              subdistrictNameTh: "ลาดบัวขาว",
              subdistrictNameEn: "Lat Bua Khao",
              postalCode: 70110,
            },
          ],
        },
        {
          districtNameTh: "บางแพ",
          districtNameEn: "Bang Phae",
          subdistricts: [
            {
              subdistrictNameTh: "บางแพ",
              subdistrictNameEn: "Bang Phae",
              postalCode: 70160,
            },
            {
              subdistrictNameTh: "วังเย็น",
              subdistrictNameEn: "Wang Yen",
              postalCode: 70160,
            },
            {
              subdistrictNameTh: "หัวโพ",
              subdistrictNameEn: "Hua Pho",
              postalCode: 70160,
            },
            {
              subdistrictNameTh: "วัดแก้ว",
              subdistrictNameEn: "Wat Kaeo",
              postalCode: 70160,
            },
            {
              subdistrictNameTh: "ดอนใหญ่",
              subdistrictNameEn: "Don Yai",
              postalCode: 70160,
            },
            {
              subdistrictNameTh: "ดอนคา",
              subdistrictNameEn: "Don Kha",
              postalCode: 70160,
            },
            {
              subdistrictNameTh: "โพหัก",
              subdistrictNameEn: "Pho Hak",
              postalCode: 70160,
            },
          ],
        },
        {
          districtNameTh: "โพธาราม",
          districtNameEn: "Photharam",
          subdistricts: [
            {
              subdistrictNameTh: "โพธาราม",
              subdistrictNameEn: "Photharam",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "ดอนกระเบื้อง",
              subdistrictNameEn: "Don Krabueang",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "หนองโพ",
              subdistrictNameEn: "Nong Pho",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "บ้านเลือก",
              subdistrictNameEn: "Ban Lueak",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "คลองตาคต",
              subdistrictNameEn: "Khlong Ta Kot",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "บ้านฆ้อง",
              subdistrictNameEn: "Ban Khong",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "บ้านสิงห์",
              subdistrictNameEn: "Ban Sing",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "ดอนทราย",
              subdistrictNameEn: "Don Sai",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "เจ็ดเสมียน",
              subdistrictNameEn: "Chet Samian",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "คลองข่อย",
              subdistrictNameEn: "Khlong Khoi",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "ชำแระ",
              subdistrictNameEn: "Chamrae",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "สร้อยฟ้า",
              subdistrictNameEn: "Soi Fa",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "ท่าชุมพล",
              subdistrictNameEn: "Tha Chumphon",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "บางโตนด",
              subdistrictNameEn: "Bang Tanot",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "เตาปูน",
              subdistrictNameEn: "Tao Pun",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "นางแก้ว",
              subdistrictNameEn: "Nang Kaeo",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "ธรรมเสน",
              subdistrictNameEn: "Thammasen",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "เขาชะงุ้ม",
              subdistrictNameEn: "Khao Cha-Ngum",
              postalCode: 70120,
            },
            {
              subdistrictNameTh: "หนองกวาง",
              subdistrictNameEn: "Nong Kwang",
              postalCode: 70120,
            },
          ],
        },
        {
          districtNameTh: "ปากท่อ",
          districtNameEn: "Pak Tho",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งหลวง",
              subdistrictNameEn: "Thung Luang",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "วังมะนาว",
              subdistrictNameEn: "Wang Manao",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "ดอนทราย",
              subdistrictNameEn: "Don Sai",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "หนองกระทุ่ม",
              subdistrictNameEn: "Nong Krathum",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "ปากท่อ",
              subdistrictNameEn: "Pak Tho",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "ป่าไก่",
              subdistrictNameEn: "Pa Kai",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "วัดยางงาม",
              subdistrictNameEn: "Wat Yang Ngam",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "อ่างหิน",
              subdistrictNameEn: "Ang Hin",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "บ่อกระดาน",
              subdistrictNameEn: "Bo Kradan",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "ยางหัก",
              subdistrictNameEn: "Yang Hak",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "วันดาว",
              subdistrictNameEn: "Wan Dao",
              postalCode: 70140,
            },
            {
              subdistrictNameTh: "ห้วยยางโทน",
              subdistrictNameEn: "Huai Yang Thon",
              postalCode: 70140,
            },
          ],
        },
        {
          districtNameTh: "วัดเพลง",
          districtNameEn: "Wat Phleng",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะศาลพระ",
              subdistrictNameEn: "Ko San Phra",
              postalCode: 70170,
            },
            {
              subdistrictNameTh: "จอมประทัด",
              subdistrictNameEn: "Chom Prathat",
              postalCode: 70170,
            },
            {
              subdistrictNameTh: "วัดเพลง",
              subdistrictNameEn: "Wat Phleng",
              postalCode: 70170,
            },
          ],
        },
        {
          districtNameTh: "บ้านคา",
          districtNameEn: "Ban Kha",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านคา",
              subdistrictNameEn: "Ban Kha",
              postalCode: 70180,
            },
            {
              subdistrictNameTh: "บ้านบึง",
              subdistrictNameEn: "Ban Bueng",
              postalCode: 70180,
            },
            {
              subdistrictNameTh: "หนองพันจันทร์",
              subdistrictNameEn: "Nong Phan Chan",
              postalCode: 70180,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "กาญจนบุรี",
      stateNameEn: "Kanchanaburi",
      districts: [
        {
          districtNameTh: "เมืองกาญจนบุรี",
          districtNameEn: "Mueang Kanchanaburi",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านเหนือ",
              subdistrictNameEn: "Ban Nuea",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "บ้านใต้",
              subdistrictNameEn: "Ban Tai",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "ปากแพรก",
              subdistrictNameEn: "Pak Phraek",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "ท่ามะขาม",
              subdistrictNameEn: "Tha Makham",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "แก่งเสี้ยน",
              subdistrictNameEn: "Kaeng Sian",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 71190,
            },
            {
              subdistrictNameTh: "ลาดหญ้า",
              subdistrictNameEn: "Lat Ya",
              postalCode: 71190,
            },
            {
              subdistrictNameTh: "วังด้ง",
              subdistrictNameEn: "Wang Dong",
              postalCode: 71190,
            },
            {
              subdistrictNameTh: "ช่องสะเดา",
              subdistrictNameEn: "Chong Sadao",
              postalCode: 71190,
            },
            {
              subdistrictNameTh: "หนองหญ้า",
              subdistrictNameEn: "Nong Ya",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "เกาะสำโรง",
              subdistrictNameEn: "Ko Samrong",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "บ้านเก่า",
              subdistrictNameEn: "Ban Kao",
              postalCode: 71000,
            },
            {
              subdistrictNameTh: "วังเย็น",
              subdistrictNameEn: "Wang Yen",
              postalCode: 71000,
            },
          ],
        },
        {
          districtNameTh: "ไทรโยค",
          districtNameEn: "Sai Yok",
          subdistricts: [
            {
              subdistrictNameTh: "ลุ่มสุ่ม",
              subdistrictNameEn: "Lum Sum",
              postalCode: 71150,
            },
            {
              subdistrictNameTh: "ท่าเสา",
              subdistrictNameEn: "Tha Sao",
              postalCode: 71150,
            },
            {
              subdistrictNameTh: "สิงห์",
              subdistrictNameEn: "Sing",
              postalCode: 71150,
            },
            {
              subdistrictNameTh: "ไทรโยค",
              subdistrictNameEn: "Sai Yok",
              postalCode: 71150,
            },
            {
              subdistrictNameTh: "วังกระแจะ",
              subdistrictNameEn: "Wang Krachae",
              postalCode: 71150,
            },
            {
              subdistrictNameTh: "ศรีมงคล",
              subdistrictNameEn: "Si Mongkhon",
              postalCode: 71150,
            },
            {
              subdistrictNameTh: "บ้องตี้",
              subdistrictNameEn: "Bong Ti",
              postalCode: 71150,
            },
          ],
        },
        {
          districtNameTh: "บ่อพลอย",
          districtNameEn: "Bo Phloi",
          subdistricts: [
            {
              subdistrictNameTh: "บ่อพลอย",
              subdistrictNameEn: "Bo Phloi",
              postalCode: 71160,
            },
            {
              subdistrictNameTh: "หนองกุ่ม",
              subdistrictNameEn: "Nong Kum",
              postalCode: 71160,
            },
            {
              subdistrictNameTh: "หนองรี",
              subdistrictNameEn: "Nong Ri",
              postalCode: 71220,
            },
            {
              subdistrictNameTh: "หลุมรัง",
              subdistrictNameEn: "Lum Rang",
              postalCode: 71160,
            },
            {
              subdistrictNameTh: "ช่องด่าน",
              subdistrictNameEn: "Chong Dan",
              postalCode: 71160,
            },
            {
              subdistrictNameTh: "หนองกร่าง",
              subdistrictNameEn: "Nong Krang",
              postalCode: 71220,
            },
          ],
        },
        {
          districtNameTh: "ศรีสวัสดิ์",
          districtNameEn: "Si Sawat",
          subdistricts: [
            {
              subdistrictNameTh: "นาสวน",
              subdistrictNameEn: "Na Suan",
              postalCode: 71250,
            },
            {
              subdistrictNameTh: "ด่านแม่แฉลบ",
              subdistrictNameEn: "Dan Mae Chalaep",
              postalCode: 71250,
            },
            {
              subdistrictNameTh: "หนองเป็ด",
              subdistrictNameEn: "Nong Pet",
              postalCode: 71250,
            },
            {
              subdistrictNameTh: "ท่ากระดาน",
              subdistrictNameEn: "Tha Kradan",
              postalCode: 71250,
            },
            {
              subdistrictNameTh: "เขาโจด",
              subdistrictNameEn: "Khao Chot",
              postalCode: 71220,
            },
            {
              subdistrictNameTh: "แม่กระบุง",
              subdistrictNameEn: "Mae Krabung",
              postalCode: 71250,
            },
          ],
        },
        {
          districtNameTh: "ท่ามะกา",
          districtNameEn: "Tha Maka",
          subdistricts: [
            {
              subdistrictNameTh: "พงตึก",
              subdistrictNameEn: "Phong Tuek",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "ยางม่วง",
              subdistrictNameEn: "Yang Muang",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "ดอนชะเอม",
              subdistrictNameEn: "Don Cha-Em",
              postalCode: 71130,
            },
            {
              subdistrictNameTh: "ท่าไม้",
              subdistrictNameEn: "Tha Mai",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "ตะคร้ำเอน",
              subdistrictNameEn: "Takhram En",
              postalCode: 71130,
            },
            {
              subdistrictNameTh: "ท่ามะกา",
              subdistrictNameEn: "Tha Maka",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 71130,
            },
            {
              subdistrictNameTh: "โคกตะบอง",
              subdistrictNameEn: "Khok Tabong",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "ดอนขมิ้น",
              subdistrictNameEn: "Don Khamin",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "อุโลกสี่หมื่น",
              subdistrictNameEn: "Ulok Si Muen",
              postalCode: 71130,
            },
            {
              subdistrictNameTh: "เขาสามสิบหาบ",
              subdistrictNameEn: "Khao Sam Sip Hap",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "พระแท่น",
              subdistrictNameEn: "Phra Thaen",
              postalCode: 71130,
            },
            {
              subdistrictNameTh: "หวายเหนียว",
              subdistrictNameEn: "Wai Niao",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "แสนตอ",
              subdistrictNameEn: "Saen To",
              postalCode: 71130,
            },
            {
              subdistrictNameTh: "สนามแย้",
              subdistrictNameEn: "Sanam Yae",
              postalCode: 70190,
            },
            {
              subdistrictNameTh: "ท่าเสา",
              subdistrictNameEn: "Tha Sao",
              postalCode: 71120,
            },
            {
              subdistrictNameTh: "หนองลาน",
              subdistrictNameEn: "Nong Lan",
              postalCode: 71130,
            },
          ],
        },
        {
          districtNameTh: "ท่าม่วง",
          districtNameEn: "Tha Muang",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าม่วง",
              subdistrictNameEn: "Tha Muang",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "วังขนาย",
              subdistrictNameEn: "Wang Khanai",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "วังศาลา",
              subdistrictNameEn: "Wang Sala",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "ท่าล้อ",
              subdistrictNameEn: "Tha Lo",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "หนองขาว",
              subdistrictNameEn: "Nong Khao",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "ทุ่งทอง",
              subdistrictNameEn: "Thung Thong",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "เขาน้อย",
              subdistrictNameEn: "Khao Noi",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "ม่วงชุม",
              subdistrictNameEn: "Muang Chum",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "พังตรุ",
              subdistrictNameEn: "Phang Tru",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "ท่าตะคร้อ",
              subdistrictNameEn: "Tha Takhro",
              postalCode: 71130,
            },
            {
              subdistrictNameTh: "รางสาลี่",
              subdistrictNameEn: "Rang Sali",
              postalCode: 71110,
            },
            {
              subdistrictNameTh: "หนองตากยา",
              subdistrictNameEn: "Nong Tak Ya",
              postalCode: 71110,
            },
          ],
        },
        {
          districtNameTh: "ทองผาภูมิ",
          districtNameEn: "Thong Pha Phum",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าขนุน",
              subdistrictNameEn: "Tha Khanun",
              postalCode: 71180,
            },
            {
              subdistrictNameTh: "ปิล๊อก",
              subdistrictNameEn: "Pi Lok",
              postalCode: 71180,
            },
            {
              subdistrictNameTh: "หินดาด",
              subdistrictNameEn: "Hin Dat",
              postalCode: 71180,
            },
            {
              subdistrictNameTh: "ลิ่นถิ่น",
              subdistrictNameEn: "Lin Thin",
              postalCode: 71180,
            },
            {
              subdistrictNameTh: "ชะแล",
              subdistrictNameEn: "Chalae",
              postalCode: 71180,
            },
            {
              subdistrictNameTh: "ห้วยเขย่ง",
              subdistrictNameEn: "Huai Khayeng",
              postalCode: 71180,
            },
            {
              subdistrictNameTh: "สหกรณ์นิคม",
              subdistrictNameEn: "Sahakon Nikhom",
              postalCode: 71180,
            },
          ],
        },
        {
          districtNameTh: "สังขละบุรี",
          districtNameEn: "Sangkhla Buri",
          subdistricts: [
            {
              subdistrictNameTh: "หนองลู",
              subdistrictNameEn: "Nong Lu",
              postalCode: 71240,
            },
            {
              subdistrictNameTh: "ปรังเผล",
              subdistrictNameEn: "Prang Phle",
              postalCode: 71240,
            },
            {
              subdistrictNameTh: "ไล่โว่",
              subdistrictNameEn: "Lai Wo",
              postalCode: 71240,
            },
          ],
        },
        {
          districtNameTh: "พนมทวน",
          districtNameEn: "Phanom Thuan",
          subdistricts: [
            {
              subdistrictNameTh: "พนมทวน",
              subdistrictNameEn: "Phanom Thuan",
              postalCode: 71140,
            },
            {
              subdistrictNameTh: "หนองโรง",
              subdistrictNameEn: "Nong Rong",
              postalCode: 71140,
            },
            {
              subdistrictNameTh: "ทุ่งสมอ",
              subdistrictNameEn: "Thung Samo",
              postalCode: 71140,
            },
            {
              subdistrictNameTh: "ดอนเจดีย์",
              subdistrictNameEn: "Don Chedi",
              postalCode: 71140,
            },
            {
              subdistrictNameTh: "พังตรุ",
              subdistrictNameEn: "Phang Tru",
              postalCode: 71140,
            },
            {
              subdistrictNameTh: "รางหวาย",
              subdistrictNameEn: "Rang Wai",
              postalCode: 71170,
            },
            {
              subdistrictNameTh: "หนองสาหร่าย",
              subdistrictNameEn: "Nong Sarai",
              postalCode: 71140,
            },
            {
              subdistrictNameTh: "ดอนตาเพชร",
              subdistrictNameEn: "Don Ta Phet",
              postalCode: 71140,
            },
          ],
        },
        {
          districtNameTh: "เลาขวัญ",
          districtNameEn: "Lao Khwan",
          subdistricts: [
            {
              subdistrictNameTh: "เลาขวัญ",
              subdistrictNameEn: "Lao Khwan",
              postalCode: 71210,
            },
            {
              subdistrictNameTh: "หนองโสน",
              subdistrictNameEn: "Nong Sano",
              postalCode: 71210,
            },
            {
              subdistrictNameTh: "หนองประดู่",
              subdistrictNameEn: "Nong Pradu",
              postalCode: 71210,
            },
            {
              subdistrictNameTh: "หนองปลิง",
              subdistrictNameEn: "Nong Pling",
              postalCode: 71210,
            },
            {
              subdistrictNameTh: "หนองนกแก้ว",
              subdistrictNameEn: "Nong Nok Kaeo",
              postalCode: 71210,
            },
            {
              subdistrictNameTh: "ทุ่งกระบ่ำ",
              subdistrictNameEn: "Thung Krabam",
              postalCode: 71210,
            },
            {
              subdistrictNameTh: "หนองฝ้าย",
              subdistrictNameEn: "Nong Fai",
              postalCode: 71210,
            },
          ],
        },
        {
          districtNameTh: "ด่านมะขามเตี้ย",
          districtNameEn: "Dan Makham Tia",
          subdistricts: [
            {
              subdistrictNameTh: "ด่านมะขามเตี้ย",
              subdistrictNameEn: "Dan Makham Tia",
              postalCode: 71260,
            },
            {
              subdistrictNameTh: "กลอนโด",
              subdistrictNameEn: "Klon Do",
              postalCode: 71260,
            },
            {
              subdistrictNameTh: "จรเข้เผือก",
              subdistrictNameEn: "Chorakhe Phueak",
              postalCode: 71260,
            },
            {
              subdistrictNameTh: "หนองไผ่",
              subdistrictNameEn: "Nong Phai",
              postalCode: 71260,
            },
          ],
        },
        {
          districtNameTh: "หนองปรือ",
          districtNameEn: "Nong Prue",
          subdistricts: [
            {
              subdistrictNameTh: "หนองปรือ",
              subdistrictNameEn: "Nong Prue",
              postalCode: 71220,
            },
            {
              subdistrictNameTh: "หนองปลาไหล",
              subdistrictNameEn: "Nong Pla Lai",
              postalCode: 71220,
            },
            {
              subdistrictNameTh: "สมเด็จเจริญ",
              subdistrictNameEn: "Somdet Charoen",
              postalCode: 71220,
            },
          ],
        },
        {
          districtNameTh: "ห้วยกระเจา",
          districtNameEn: "Huai Krachao",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยกระเจา",
              subdistrictNameEn: "Huai Krachao",
              postalCode: 71170,
            },
            {
              subdistrictNameTh: "วังไผ่",
              subdistrictNameEn: "Wang Phai",
              postalCode: 71170,
            },
            {
              subdistrictNameTh: "ดอนแสลบ",
              subdistrictNameEn: "Don Salaep",
              postalCode: 71170,
            },
            {
              subdistrictNameTh: "สระลงเรือ",
              subdistrictNameEn: "Sa Long Ruea",
              postalCode: 71170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สุพรรณบุรี",
      stateNameEn: "Suphan Buri",
      districts: [
        {
          districtNameTh: "เมืองสุพรรณบุรี",
          districtNameEn: "Mueang Saphan Buri",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าพี่เลี้ยง",
              subdistrictNameEn: "Tha Phi Liang",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "รั้วใหญ่",
              subdistrictNameEn: "Rua Yai",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "ทับตีเหล็ก",
              subdistrictNameEn: "Thap Ti Lek",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "ท่าระหัด",
              subdistrictNameEn: "Tha Rahat",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "ไผ่ขวาง",
              subdistrictNameEn: "Phai Khwang",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "โคกโคเฒ่า",
              subdistrictNameEn: "Khok Kho Thao",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "ดอนตาล",
              subdistrictNameEn: "Don Tan",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "ดอนมะสังข์",
              subdistrictNameEn: "Don Masang",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "พิหารแดง",
              subdistrictNameEn: "Phihan Daeng",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "ดอนกำยาน",
              subdistrictNameEn: "Don Kamyan",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "ดอนโพธิ์ทอง",
              subdistrictNameEn: "Don Pho Thong",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "บ้านโพธิ์",
              subdistrictNameEn: "Ban Pho",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 72230,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 72230,
            },
            {
              subdistrictNameTh: "บางกุ้ง",
              subdistrictNameEn: "Bang Kung",
              postalCode: 72210,
            },
            {
              subdistrictNameTh: "ศาลาขาว",
              subdistrictNameEn: "Sala Khao",
              postalCode: 72210,
            },
            {
              subdistrictNameTh: "สวนแตง",
              subdistrictNameEn: "Suan Taeng",
              postalCode: 72210,
            },
            {
              subdistrictNameTh: "สนามชัย",
              subdistrictNameEn: "Sanam Chai",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "โพธิ์พระยา",
              subdistrictNameEn: "Pho Phraya",
              postalCode: 72000,
            },
            {
              subdistrictNameTh: "สนามคลี",
              subdistrictNameEn: "Sanam Khli",
              postalCode: 72230,
            },
          ],
        },
        {
          districtNameTh: "เดิมบางนางบวช",
          districtNameEn: "Doem Bang Nang Buat",
          subdistricts: [
            {
              subdistrictNameTh: "เขาพระ",
              subdistrictNameEn: "Khao Phra",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "เดิมบาง",
              subdistrictNameEn: "Doem Bang",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "นางบวช",
              subdistrictNameEn: "Nang Buat",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "เขาดิน",
              subdistrictNameEn: "Khao Din",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "ทุ่งคลี",
              subdistrictNameEn: "Thung Khli",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "โคกช้าง",
              subdistrictNameEn: "Khok Chang",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "หัวเขา",
              subdistrictNameEn: "Hua Khao",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "หัวนา",
              subdistrictNameEn: "Hua Na",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "บ่อกรุ",
              subdistrictNameEn: "Bo Kru",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "วังศรีราช",
              subdistrictNameEn: "Wang Si Rat",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "ป่าสะแก",
              subdistrictNameEn: "Pa Sakae",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "ยางนอน",
              subdistrictNameEn: "Yang Non",
              postalCode: 72120,
            },
            {
              subdistrictNameTh: "หนองกระทุ่ม",
              subdistrictNameEn: "Nong Krathum",
              postalCode: 72120,
            },
          ],
        },
        {
          districtNameTh: "ด่านช้าง",
          districtNameEn: "Dan Chang",
          subdistricts: [
            {
              subdistrictNameTh: "หนองมะค่าโมง",
              subdistrictNameEn: "Nong Makha Mong",
              postalCode: 72180,
            },
            {
              subdistrictNameTh: "ด่านช้าง",
              subdistrictNameEn: "Dan Chang",
              postalCode: 72180,
            },
            {
              subdistrictNameTh: "ห้วยขมิ้น",
              subdistrictNameEn: "Huai Khamin",
              postalCode: 72180,
            },
            {
              subdistrictNameTh: "องค์พระ",
              subdistrictNameEn: "Ong Phra",
              postalCode: 72180,
            },
            {
              subdistrictNameTh: "วังคัน",
              subdistrictNameEn: "Wang Khan",
              postalCode: 72180,
            },
            {
              subdistrictNameTh: "นิคมกระเสียว",
              subdistrictNameEn: "Nikhom Krasiao",
              postalCode: 72180,
            },
            {
              subdistrictNameTh: "วังยาว",
              subdistrictNameEn: "Wang Yao",
              postalCode: 72180,
            },
          ],
        },
        {
          districtNameTh: "บางปลาม้า",
          districtNameEn: "Bang Pla Ma",
          subdistricts: [
            {
              subdistrictNameTh: "โคกคราม",
              subdistrictNameEn: "Khok Khram",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "บางปลาม้า",
              subdistrictNameEn: "Bang Pla Ma",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "ตะค่า",
              subdistrictNameEn: "Takha",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "บางใหญ่",
              subdistrictNameEn: "Bang Yai",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "กฤษณา",
              subdistrictNameEn: "Kritsana",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "สาลี",
              subdistrictNameEn: "Sali",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "ไผ่กองดิน",
              subdistrictNameEn: "Phai Kong Din",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "องครักษ์",
              subdistrictNameEn: "Ongkharak",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "จรเข้ใหญ่",
              subdistrictNameEn: "Chorakhe Yai",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "บ้านแหลม",
              subdistrictNameEn: "Ban Laem",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "มะขามล้ม",
              subdistrictNameEn: "Makham Lom",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "วังน้ำเย็น",
              subdistrictNameEn: "Wang Nam Yen",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "วัดโบสถ์",
              subdistrictNameEn: "Wat Bot",
              postalCode: 72150,
            },
            {
              subdistrictNameTh: "วัดดาว",
              subdistrictNameEn: "Wat Dao",
              postalCode: 72150,
            },
          ],
        },
        {
          districtNameTh: "ศรีประจันต์",
          districtNameEn: "Si Prachan",
          subdistricts: [
            {
              subdistrictNameTh: "ศรีประจันต์",
              subdistrictNameEn: "Si Prachan",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "บ้านกร่าง",
              subdistrictNameEn: "Ban Krang",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "มดแดง",
              subdistrictNameEn: "Mot Daeng",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "บางงาม",
              subdistrictNameEn: "Bang Ngam",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "ดอนปรู",
              subdistrictNameEn: "Don Pru",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "ปลายนา",
              subdistrictNameEn: "Plai Na",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "วังหว้า",
              subdistrictNameEn: "Wang Wa",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "วังน้ำซับ",
              subdistrictNameEn: "Wang Nam Sap",
              postalCode: 72140,
            },
            {
              subdistrictNameTh: "วังยาง",
              subdistrictNameEn: "Wang Yang",
              postalCode: 72140,
            },
          ],
        },
        {
          districtNameTh: "ดอนเจดีย์",
          districtNameEn: "Don Chedi",
          subdistricts: [
            {
              subdistrictNameTh: "ดอนเจดีย์",
              subdistrictNameEn: "Don Chedi",
              postalCode: 72170,
            },
            {
              subdistrictNameTh: "หนองสาหร่าย",
              subdistrictNameEn: "Nong Sarai",
              postalCode: 72170,
            },
            {
              subdistrictNameTh: "ไร่รถ",
              subdistrictNameEn: "Rai Rot",
              postalCode: 72170,
            },
            {
              subdistrictNameTh: "สระกระโจม",
              subdistrictNameEn: "Sa Krachom",
              postalCode: 72250,
            },
            {
              subdistrictNameTh: "ทะเลบก",
              subdistrictNameEn: "Thale Bok",
              postalCode: 72250,
            },
          ],
        },
        {
          districtNameTh: "สองพี่น้อง",
          districtNameEn: "Song Phi Nong",
          subdistricts: [
            {
              subdistrictNameTh: "สองพี่น้อง",
              subdistrictNameEn: "Song Phi Nong",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "บางเลน",
              subdistrictNameEn: "Bang Len",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "บางตาเถร",
              subdistrictNameEn: "Bang Ta Then",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "บางตะเคียน",
              subdistrictNameEn: "Bang Takhian",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "บ้านกุ่ม",
              subdistrictNameEn: "Ban Kum",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "หัวโพธิ์",
              subdistrictNameEn: "Hua Pho",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "บางพลับ",
              subdistrictNameEn: "Bang Phlap",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "เนินพระปรางค์",
              subdistrictNameEn: "Noen Phraprang",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "บ้านช้าง",
              subdistrictNameEn: "Ban Chang",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "ต้นตาล",
              subdistrictNameEn: "Ton Tan",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "ศรีสำราญ",
              subdistrictNameEn: "Si Samran",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "ทุ่งคอก",
              subdistrictNameEn: "Thung Khok",
              postalCode: 72190,
            },
            {
              subdistrictNameTh: "หนองบ่อ",
              subdistrictNameEn: "Nong Bo",
              postalCode: 72110,
            },
            {
              subdistrictNameTh: "บ่อสุพรรณ",
              subdistrictNameEn: "Bo Suphan",
              postalCode: 72190,
            },
            {
              subdistrictNameTh: "ดอนมะนาว",
              subdistrictNameEn: "Don Manao",
              postalCode: 72110,
            },
          ],
        },
        {
          districtNameTh: "สามชุก",
          districtNameEn: "Sam Chuk",
          subdistricts: [
            {
              subdistrictNameTh: "ย่านยาว",
              subdistrictNameEn: "Yan Yao",
              postalCode: 72130,
            },
            {
              subdistrictNameTh: "วังลึก",
              subdistrictNameEn: "Wang Luek",
              postalCode: 72130,
            },
            {
              subdistrictNameTh: "สามชุก",
              subdistrictNameEn: "Sam Chuk",
              postalCode: 72130,
            },
            {
              subdistrictNameTh: "หนองผักนาก",
              subdistrictNameEn: "Nong Phak Nak",
              postalCode: 72130,
            },
            {
              subdistrictNameTh: "บ้านสระ",
              subdistrictNameEn: "Ban Sa",
              postalCode: 72130,
            },
            {
              subdistrictNameTh: "หนองสะเดา",
              subdistrictNameEn: "Nong Sadao",
              postalCode: 72130,
            },
            {
              subdistrictNameTh: "กระเสียว",
              subdistrictNameEn: "Krasiao",
              postalCode: 72130,
            },
          ],
        },
        {
          districtNameTh: "อู่ทอง",
          districtNameEn: "U Thong",
          subdistricts: [
            {
              subdistrictNameTh: "อู่ทอง",
              subdistrictNameEn: "U Thong",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "สระยายโสม",
              subdistrictNameEn: "Sa Yai Som",
              postalCode: 72220,
            },
            {
              subdistrictNameTh: "จรเข้สามพัน",
              subdistrictNameEn: "Chorakhe Sam Phan",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "บ้านดอน",
              subdistrictNameEn: "Ban Don",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "ยุ้งทะลาย",
              subdistrictNameEn: "Yung Thalai",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "ดอนมะเกลือ",
              subdistrictNameEn: "Don Makluea",
              postalCode: 72220,
            },
            {
              subdistrictNameTh: "หนองโอ่ง",
              subdistrictNameEn: "Nong Ong",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "ดอนคา",
              subdistrictNameEn: "Don Kha",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "พลับพลาไชย",
              subdistrictNameEn: "Phlapphla Chai",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "บ้านโข้ง",
              subdistrictNameEn: "Ban Khong",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "เจดีย์",
              subdistrictNameEn: "Chedi",
              postalCode: 72160,
            },
            {
              subdistrictNameTh: "สระพังลาน",
              subdistrictNameEn: "Sa Phang Lan",
              postalCode: 72220,
            },
            {
              subdistrictNameTh: "กระจัน",
              subdistrictNameEn: "Krachan",
              postalCode: 72160,
            },
          ],
        },
        {
          districtNameTh: "หนองหญ้าไซ",
          districtNameEn: "Nong Ya Sai",
          subdistricts: [
            {
              subdistrictNameTh: "หนองหญ้าไซ",
              subdistrictNameEn: "Nong Ya Sai",
              postalCode: 72240,
            },
            {
              subdistrictNameTh: "หนองราชวัตร",
              subdistrictNameEn: "Nong Ratchawat",
              postalCode: 72240,
            },
            {
              subdistrictNameTh: "หนองโพธิ์",
              subdistrictNameEn: "Nong Pho",
              postalCode: 72240,
            },
            {
              subdistrictNameTh: "แจงงาม",
              subdistrictNameEn: "Chaeng Ngam",
              postalCode: 72240,
            },
            {
              subdistrictNameTh: "หนองขาม",
              subdistrictNameEn: "Nong Kham",
              postalCode: 72240,
            },
            {
              subdistrictNameTh: "ทัพหลวง",
              subdistrictNameEn: "Thap Luang",
              postalCode: 72240,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นครปฐม",
      stateNameEn: "Nakhon Pathom",
      districts: [
        {
          districtNameTh: "เมืองนครปฐม",
          districtNameEn: "Mueang Nakhon Pathom",
          subdistricts: [
            {
              subdistrictNameTh: "พระปฐมเจดีย์",
              subdistrictNameEn: "Phra Pathom Chedi",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "บางแขม",
              subdistrictNameEn: "Bang Khaem",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "พระประโทน",
              subdistrictNameEn: "Phra Prathon",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ธรรมศาลา",
              subdistrictNameEn: "Thammasala",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ตาก้อง",
              subdistrictNameEn: "Ta Kong",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "มาบแค",
              subdistrictNameEn: "Map Khae",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "สนามจันทร์",
              subdistrictNameEn: "Sanam Chan",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ดอนยายหอม",
              subdistrictNameEn: "Don Yai Hom",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ถนนขาด",
              subdistrictNameEn: "Thanon Khat",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "บ่อพลับ",
              subdistrictNameEn: "Bo Phlap",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "นครปฐม",
              subdistrictNameEn: "Nakhon Pathom",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "วังตะกู",
              subdistrictNameEn: "Wang Taku",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "หนองปากโลง",
              subdistrictNameEn: "Nong Pak Long",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "สามควายเผือก",
              subdistrictNameEn: "Sam Khwai Phueak",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ทุ่งน้อย",
              subdistrictNameEn: "Thung Noi",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "หนองดินแดง",
              subdistrictNameEn: "Nong Din Daeng",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "วังเย็น",
              subdistrictNameEn: "Wang Yen",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "โพรงมะเดื่อ",
              subdistrictNameEn: "Phrong Maduea",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ลำพยา",
              subdistrictNameEn: "Lam Phaya",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "สระกะเทียม",
              subdistrictNameEn: "Sa Kathiam",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "สวนป่าน",
              subdistrictNameEn: "Suan Pan",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ห้วยจรเข้",
              subdistrictNameEn: "Huai Chorakhe",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "ทัพหลวง",
              subdistrictNameEn: "Thap Luang",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "หนองงูเหลือม",
              subdistrictNameEn: "Nong Ngu Lueam",
              postalCode: 73000,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 73000,
            },
          ],
        },
        {
          districtNameTh: "กำแพงแสน",
          districtNameEn: "Kamphaeng Saen",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งกระพังโหม",
              subdistrictNameEn: "Thung Kraphang Hom",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "กระตีบ",
              subdistrictNameEn: "Kratip",
              postalCode: 73180,
            },
            {
              subdistrictNameTh: "ทุ่งลูกนก",
              subdistrictNameEn: "Thung Luk Nok",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "ห้วยขวาง",
              subdistrictNameEn: "Huai Khwang",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "ทุ่งขวาง",
              subdistrictNameEn: "Thung Khwang",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "สระสี่มุม",
              subdistrictNameEn: "Sa Si Mum",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "ทุ่งบัว",
              subdistrictNameEn: "Thung Bua",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "ดอนข่อย",
              subdistrictNameEn: "Don Khoi",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "สระพัฒนา",
              subdistrictNameEn: "Sa Phatthana",
              postalCode: 73180,
            },
            {
              subdistrictNameTh: "ห้วยหมอนทอง",
              subdistrictNameEn: "Huai Mon Thong",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "ห้วยม่วง",
              subdistrictNameEn: "Huai Muang",
              postalCode: 73180,
            },
            {
              subdistrictNameTh: "กำแพงแสน",
              subdistrictNameEn: "Kamphaeng Saen",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "รางพิกุล",
              subdistrictNameEn: "Rang Phikun",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "หนองกระทุ่ม",
              subdistrictNameEn: "Nong Krathum",
              postalCode: 73140,
            },
            {
              subdistrictNameTh: "วังน้ำเขียว",
              subdistrictNameEn: "Wang Nam Khiao",
              postalCode: 73140,
            },
          ],
        },
        {
          districtNameTh: "นครชัยศรี",
          districtNameEn: "Nakhon Chai Si",
          subdistricts: [
            {
              subdistrictNameTh: "นครชัยศรี",
              subdistrictNameEn: "Nakhon Chai Si",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "บางกระเบา",
              subdistrictNameEn: "Bang Krabao",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "วัดแค",
              subdistrictNameEn: "Wat Khae",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ท่าตำหนัก",
              subdistrictNameEn: "Tha Tamnak",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ท่ากระชับ",
              subdistrictNameEn: "Tha Krachap",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ขุนแก้ว",
              subdistrictNameEn: "Khun Kaeo",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ท่าพระยา",
              subdistrictNameEn: "Tha Phraya",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "พะเนียด",
              subdistrictNameEn: "Phaniat",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "บางระกำ",
              subdistrictNameEn: "Bang Rakam",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "โคกพระเจดีย์",
              subdistrictNameEn: "Khok Phra Chedi",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ศรีษะทอง",
              subdistrictNameEn: "Sisa Thong",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "แหลมบัว",
              subdistrictNameEn: "Laem Bua",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ศรีมหาโพธิ์",
              subdistrictNameEn: "Si Mahapho",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "สัมปทวน",
              subdistrictNameEn: "Sampathuan",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "วัดสำโรง",
              subdistrictNameEn: "Wat Samrong",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ดอนแฝก",
              subdistrictNameEn: "Don Faek",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ห้วยพลู",
              subdistrictNameEn: "Huai Phlu",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "วัดละมุด",
              subdistrictNameEn: "Wat Lamut",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "บางพระ",
              subdistrictNameEn: "Bang Phra",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "บางแก้วฟ้า",
              subdistrictNameEn: "Bang Kaeo Fa",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ลานตากฟ้า",
              subdistrictNameEn: "Lan Tak Fa",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "งิ้วราย",
              subdistrictNameEn: "Ngio Rai",
              postalCode: 73120,
            },
            {
              subdistrictNameTh: "ไทยาวาส",
              subdistrictNameEn: "Thaiyawat",
              postalCode: 73120,
            },
          ],
        },
        {
          districtNameTh: "ดอนตูม",
          districtNameEn: "Don Tum",
          subdistricts: [
            {
              subdistrictNameTh: "สามง่าม",
              subdistrictNameEn: "Sam Ngam",
              postalCode: 73150,
            },
            {
              subdistrictNameTh: "ห้วยพระ",
              subdistrictNameEn: "Huai Phra",
              postalCode: 73150,
            },
            {
              subdistrictNameTh: "ลำเหย",
              subdistrictNameEn: "Lam Hoei",
              postalCode: 73150,
            },
            {
              subdistrictNameTh: "ดอนพุทรา",
              subdistrictNameEn: "Don Phutsa",
              postalCode: 73150,
            },
            {
              subdistrictNameTh: "บ้านหลวง",
              subdistrictNameEn: "Ban Luang",
              postalCode: 73150,
            },
            {
              subdistrictNameTh: "ดอนรวก",
              subdistrictNameEn: "Don Ruak",
              postalCode: 73150,
            },
            {
              subdistrictNameTh: "ห้วยด้วน",
              subdistrictNameEn: "Huai Duan",
              postalCode: 73150,
            },
            {
              subdistrictNameTh: "ลำลูกบัว",
              subdistrictNameEn: "Lam Luk Bua",
              postalCode: 73150,
            },
          ],
        },
        {
          districtNameTh: "บางเลน",
          districtNameEn: "Bang Len",
          subdistricts: [
            {
              subdistrictNameTh: "บางเลน",
              subdistrictNameEn: "Bang Len",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "บางปลา",
              subdistrictNameEn: "Bang Pla",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "บางหลวง",
              subdistrictNameEn: "Bang Luang",
              postalCode: 73190,
            },
            {
              subdistrictNameTh: "บางภาษี",
              subdistrictNameEn: "Bang Phasi",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "บางระกำ",
              subdistrictNameEn: "Bang Rakam",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "บางไทรป่า",
              subdistrictNameEn: "Bang Sai Pa",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "หินมูล",
              subdistrictNameEn: "Hin Mun",
              postalCode: 73190,
            },
            {
              subdistrictNameTh: "ไทรงาม",
              subdistrictNameEn: "Sai Ngam",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "ดอนตูม",
              subdistrictNameEn: "Don Tum",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "นิลเพชร",
              subdistrictNameEn: "Nin Phet",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "บัวปากท่า",
              subdistrictNameEn: "Bua Pak Tha",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "คลองนกกระทุง",
              subdistrictNameEn: "Khlong Nok Krathung",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "นราภิรมย์",
              subdistrictNameEn: "Nara Phirom",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "ลำพญา",
              subdistrictNameEn: "Lam Phaya",
              postalCode: 73130,
            },
            {
              subdistrictNameTh: "ไผ่หูช้าง",
              subdistrictNameEn: "Phai Hu Chang",
              postalCode: 73130,
            },
          ],
        },
        {
          districtNameTh: "สามพราน",
          districtNameEn: "Sam Phran",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "ทรงคนอง",
              subdistrictNameEn: "Song Khanong",
              postalCode: 73210,
            },
            {
              subdistrictNameTh: "หอมเกร็ด",
              subdistrictNameEn: "Hom Kret",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "บางกระทึก",
              subdistrictNameEn: "Bang Krathuek",
              postalCode: 73210,
            },
            {
              subdistrictNameTh: "บางเตย",
              subdistrictNameEn: "Bang Toei",
              postalCode: 73210,
            },
            {
              subdistrictNameTh: "สามพราน",
              subdistrictNameEn: "Sam Phran",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "บางช้าง",
              subdistrictNameEn: "Bang Chang",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "ไร่ขิง",
              subdistrictNameEn: "Rai Khing",
              postalCode: 73210,
            },
            {
              subdistrictNameTh: "ท่าตลาด",
              subdistrictNameEn: "Tha Talat",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "กระทุ่มล้ม",
              subdistrictNameEn: "Krathum Lom",
              postalCode: 73220,
            },
            {
              subdistrictNameTh: "คลองใหม่",
              subdistrictNameEn: "Khlong Mai",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "ตลาดจินดา",
              subdistrictNameEn: "Talat Chinda",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "คลองจินดา",
              subdistrictNameEn: "Khlong Chinda",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "ยายชา",
              subdistrictNameEn: "Yai Cha",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 73110,
            },
            {
              subdistrictNameTh: "อ้อมใหญ่",
              subdistrictNameEn: "Om Yai",
              postalCode: 73160,
            },
          ],
        },
        {
          districtNameTh: "พุทธมณฑล",
          districtNameEn: "Phutthamonthon",
          subdistricts: [
            {
              subdistrictNameTh: "ศาลายา",
              subdistrictNameEn: "Sala Ya",
              postalCode: 73170,
            },
            {
              subdistrictNameTh: "คลองโยง",
              subdistrictNameEn: "Khlong Yong",
              postalCode: 73170,
            },
            {
              subdistrictNameTh: "มหาสวัสดิ์",
              subdistrictNameEn: "Mahasawat",
              postalCode: 73170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สมุทรสาคร",
      stateNameEn: "Samut Sakhon",
      districts: [
        {
          districtNameTh: "เมืองสมุทรสาคร",
          districtNameEn: "Mueang Samut Sakhon",
          subdistricts: [
            {
              subdistrictNameTh: "มหาชัย",
              subdistrictNameEn: "Mahachai",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "ท่าฉลอม",
              subdistrictNameEn: "Tha Chalom",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "โกรกกราก",
              subdistrictNameEn: "Krok Krak",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "บ้านบ่อ",
              subdistrictNameEn: "Ban Bo",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "บางโทรัด",
              subdistrictNameEn: "Bang Thorat",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "กาหลง",
              subdistrictNameEn: "Kalong",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "นาโคก",
              subdistrictNameEn: "Na Khok",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "ท่าจีน",
              subdistrictNameEn: "Tha Chin",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "นาดี",
              subdistrictNameEn: "Na Di",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "ท่าทราย",
              subdistrictNameEn: "Tha Sai",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "คอกกระบือ",
              subdistrictNameEn: "Khok Krabue",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "บางน้ำจืด",
              subdistrictNameEn: "Bang Nam Chuet",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "พันท้ายนรสิงห์",
              subdistrictNameEn: "Phanthai Norasing",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "โคกขาม",
              subdistrictNameEn: "Khok Kham",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "บ้านเกาะ",
              subdistrictNameEn: "Ban Ko",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "บางกระเจ้า",
              subdistrictNameEn: "Bang Krachao",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "บางหญ้าแพรก",
              subdistrictNameEn: "Bang Ya Phraek",
              postalCode: 74000,
            },
            {
              subdistrictNameTh: "ชัยมงคล",
              subdistrictNameEn: "Chai Mongkhon",
              postalCode: 74000,
            },
          ],
        },
        {
          districtNameTh: "กระทุ่มแบน",
          districtNameEn: "Krathum Baen",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาดกระทุ่มแบน",
              subdistrictNameEn: "Talat Krathum Baen",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "อ้อมน้อย",
              subdistrictNameEn: "Om Noi",
              postalCode: 74130,
            },
            {
              subdistrictNameTh: "ท่าไม้",
              subdistrictNameEn: "Tha Mai",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "สวนหลวง",
              subdistrictNameEn: "Suan Luang",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "บางยาง",
              subdistrictNameEn: "Bang Yang",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "คลองมะเดื่อ",
              subdistrictNameEn: "Khlong Maduea",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "หนองนกไข่",
              subdistrictNameEn: "Nong Nok Khai",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "ดอนไก่ดี",
              subdistrictNameEn: "Don Kai Di",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "แคราย",
              subdistrictNameEn: "Khae Rai",
              postalCode: 74110,
            },
            {
              subdistrictNameTh: "ท่าเสา",
              subdistrictNameEn: "Tha Sao",
              postalCode: 74110,
            },
          ],
        },
        {
          districtNameTh: "บ้านแพ้ว",
          districtNameEn: "Ban Phaeo",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านแพ้ว",
              subdistrictNameEn: "Ban Phaeo",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "หลักสาม",
              subdistrictNameEn: "Lak Sam",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "ยกกระบัตร",
              subdistrictNameEn: "Yok Krabat",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "โรงเข้",
              subdistrictNameEn: "Rong Khe",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "หนองสองห้อง",
              subdistrictNameEn: "Nong Song Hong",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "หลักสอง",
              subdistrictNameEn: "Lak Song",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "เจ็ดริ้ว",
              subdistrictNameEn: "Chet Rio",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "คลองตัน",
              subdistrictNameEn: "Khlong Tan",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "อำแพง",
              subdistrictNameEn: "Amphaeng",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "สวนส้ม",
              subdistrictNameEn: "Suan Som",
              postalCode: 74120,
            },
            {
              subdistrictNameTh: "เกษตรพัฒนา",
              subdistrictNameEn: "Kaset Phatthana",
              postalCode: 74120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สมุทรสงคราม",
      stateNameEn: "Samut Songkhram",
      districts: [
        {
          districtNameTh: "เมืองสมุทรสงคราม",
          districtNameEn: "Mueang Samut Songkhram",
          subdistricts: [
            {
              subdistrictNameTh: "แม่กลอง",
              subdistrictNameEn: "Mae Klong",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "บางขันแตก",
              subdistrictNameEn: "Bang Khan Taek",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "ลาดใหญ่",
              subdistrictNameEn: "Lat Yai",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "บ้านปรก",
              subdistrictNameEn: "Ban Prok",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "ท้ายหาด",
              subdistrictNameEn: "Thai Hat",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "แหลมใหญ่",
              subdistrictNameEn: "Laem Yai",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "คลองเขิน",
              subdistrictNameEn: "Khlong Khoen",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "คลองโคน",
              subdistrictNameEn: "Khlong Khon",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "นางตะเคียน",
              subdistrictNameEn: "Nang Takhian",
              postalCode: 75000,
            },
            {
              subdistrictNameTh: "บางจะเกร็ง",
              subdistrictNameEn: "Bang Chakreng",
              postalCode: 75000,
            },
          ],
        },
        {
          districtNameTh: "บางคนที",
          districtNameEn: "Bang Khonthi",
          subdistricts: [
            {
              subdistrictNameTh: "กระดังงา",
              subdistrictNameEn: "Kradang Nga",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บางสะแก",
              subdistrictNameEn: "Bang Sakae",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บางยี่รงค์",
              subdistrictNameEn: "Bang Yi Rong",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "โรงหีบ",
              subdistrictNameEn: "Rong Hip",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บางคนที",
              subdistrictNameEn: "Bang Khonthi",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "ดอนมะโนรา",
              subdistrictNameEn: "Don Manora",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บางพรม",
              subdistrictNameEn: "Bang Phrom",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บางกุ้ง",
              subdistrictNameEn: "Bang Kung",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "จอมปลวก",
              subdistrictNameEn: "Chom Pluak",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บางนกแขวก",
              subdistrictNameEn: "Bang Nok Khwaek",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "ยายแพง",
              subdistrictNameEn: "Yai Phaeng",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บางกระบือ",
              subdistrictNameEn: "Bang Krabue",
              postalCode: 75120,
            },
            {
              subdistrictNameTh: "บ้านปราโมทย์",
              subdistrictNameEn: "Ban Pramot",
              postalCode: 75120,
            },
          ],
        },
        {
          districtNameTh: "อัมพวา",
          districtNameEn: "Amphawa",
          subdistricts: [
            {
              subdistrictNameTh: "อัมพวา",
              subdistrictNameEn: "Amphawa",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "สวนหลวง",
              subdistrictNameEn: "Suan Luang",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "ท่าคา",
              subdistrictNameEn: "Tha Kha",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "วัดประดู่",
              subdistrictNameEn: "Wat Pradu",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "เหมืองใหม่",
              subdistrictNameEn: "Mueang Mai",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "บางช้าง",
              subdistrictNameEn: "Bang Chang",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "แควอ้อม",
              subdistrictNameEn: "Khwae Om",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "ปลายโพงพาง",
              subdistrictNameEn: "Plai Phongphang",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "บางแค",
              subdistrictNameEn: "Bang Khae",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "แพรกหนามแดง",
              subdistrictNameEn: "Phraek Nam Daeng",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "ยี่สาร",
              subdistrictNameEn: "Yi San",
              postalCode: 75110,
            },
            {
              subdistrictNameTh: "บางนางลี่",
              subdistrictNameEn: "Bang Nang Li",
              postalCode: 75110,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "เพชรบุรี",
      stateNameEn: "Phetchaburi",
      districts: [
        {
          districtNameTh: "เมืองเพชรบุรี",
          districtNameEn: "Mueang Phetchaburi",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าราบ",
              subdistrictNameEn: "Tha Rap",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "คลองกระแชง",
              subdistrictNameEn: "Khlong Krachaeng",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "บางจาน",
              subdistrictNameEn: "Bang Chan",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "นาพันสาม",
              subdistrictNameEn: "Na Phan Sam",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "ธงชัย",
              subdistrictNameEn: "Thong Chai",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "บ้านกุ่ม",
              subdistrictNameEn: "Ban Kum",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "หนองโสน",
              subdistrictNameEn: "Nong Sano",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "ไร่ส้ม",
              subdistrictNameEn: "Rai Som",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "เวียงคอย",
              subdistrictNameEn: "Wiang Khoi",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "บางจาก",
              subdistrictNameEn: "Bang Chak",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "บ้านหม้อ",
              subdistrictNameEn: "Ban Mo",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "ต้นมะม่วง",
              subdistrictNameEn: "Ton Mamuang",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "ช่องสะแก",
              subdistrictNameEn: "Chong Sakae",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "นาวุ้ง",
              subdistrictNameEn: "Na Wung",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "สำมะโรง",
              subdistrictNameEn: "Sam Marong",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "โพพระ",
              subdistrictNameEn: "Pho Phra",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "หาดเจ้าสำราญ",
              subdistrictNameEn: "Hat Chao Samran",
              postalCode: 76100,
            },
            {
              subdistrictNameTh: "หัวสะพาน",
              subdistrictNameEn: "Hua Saphan",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "ต้นมะพร้าว",
              subdistrictNameEn: "Ton Maphrao",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "วังตะโก",
              subdistrictNameEn: "Wang Tako",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "โพไร่หวาน",
              subdistrictNameEn: "Pho Rai Wan",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "ดอนยาง",
              subdistrictNameEn: "Don Yang",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "หนองขนาน",
              subdistrictNameEn: "Nong Khanan",
              postalCode: 76000,
            },
            {
              subdistrictNameTh: "หนองพลับ",
              subdistrictNameEn: "Nong Phlap",
              postalCode: 76000,
            },
          ],
        },
        {
          districtNameTh: "เขาย้อย",
          districtNameEn: "Khao Yoi",
          subdistricts: [
            {
              subdistrictNameTh: "เขาย้อย",
              subdistrictNameEn: "Khao Yoi",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "สระพัง",
              subdistrictNameEn: "Sa Phang",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "บางเค็ม",
              subdistrictNameEn: "Bang Khem",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "ทับคาง",
              subdistrictNameEn: "Thap Khang",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "หนองปลาไหล",
              subdistrictNameEn: "Nong Pla Lai",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "หนองปรง",
              subdistrictNameEn: "Nong Prong",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "หนองชุมพล",
              subdistrictNameEn: "Nong Chumphon",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "ห้วยโรง",
              subdistrictNameEn: "Huai Rong",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "ห้วยท่าช้าง",
              subdistrictNameEn: "Huai Tha Chang",
              postalCode: 76140,
            },
            {
              subdistrictNameTh: "หนองชุมพลเหนือ",
              subdistrictNameEn: "Nong Chumphon Nuea",
              postalCode: 76140,
            },
          ],
        },
        {
          districtNameTh: "หนองหญ้าปล้อง",
          districtNameEn: "Nong Ya Plong",
          subdistricts: [
            {
              subdistrictNameTh: "หนองหญ้าปล้อง",
              subdistrictNameEn: "Nong Ya Plong",
              postalCode: 76160,
            },
            {
              subdistrictNameTh: "ยางน้ำกลัดเหนือ",
              subdistrictNameEn: "Yang Nam Klat Nuea",
              postalCode: 76160,
            },
            {
              subdistrictNameTh: "ยางน้ำกลัดใต้",
              subdistrictNameEn: "Yang Nam Klat Tai",
              postalCode: 76160,
            },
            {
              subdistrictNameTh: "ท่าตะคร้อ",
              subdistrictNameEn: "Tha Takhro",
              postalCode: 76160,
            },
          ],
        },
        {
          districtNameTh: "ชะอำ",
          districtNameEn: "Cha-Am",
          subdistricts: [
            {
              subdistrictNameTh: "ชะอำ",
              subdistrictNameEn: "Cha-Am",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "บางเก่า",
              subdistrictNameEn: "Bang Kao",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "นายาง",
              subdistrictNameEn: "Na Yang",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "เขาใหญ่",
              subdistrictNameEn: "Khao Yai",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "หนองศาลา",
              subdistrictNameEn: "Nong Sala",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "ห้วยทรายเหนือ",
              subdistrictNameEn: "Huai Sai Nuea",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "ไร่ใหม่พัฒนา",
              subdistrictNameEn: "Rai Mai Phatthana",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "สามพระยา",
              subdistrictNameEn: "Sam Phraya",
              postalCode: 76120,
            },
            {
              subdistrictNameTh: "ดอนขุนห้วย",
              subdistrictNameEn: "Don Khun Huai",
              postalCode: 76120,
            },
          ],
        },
        {
          districtNameTh: "ท่ายาง",
          districtNameEn: "Tha Yang",
          subdistricts: [
            {
              subdistrictNameTh: "ท่ายาง",
              subdistrictNameEn: "Tha Yang",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "ท่าคอย",
              subdistrictNameEn: "Tha Khoi",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "ยางหย่อง",
              subdistrictNameEn: "Yang Yong",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "หนองจอก",
              subdistrictNameEn: "Nong Chok",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "มาบปลาเค้า",
              subdistrictNameEn: "Map Pla Khao",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "ท่าไม้รวก",
              subdistrictNameEn: "Tha Mai Ruak",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "วังไคร้",
              subdistrictNameEn: "Wang Khrai",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "กลัดหลวง",
              subdistrictNameEn: "Klat Luang",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "ปึกเตียน",
              subdistrictNameEn: "Puek Tian",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "เขากระปุก",
              subdistrictNameEn: "Khao Krapuk",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "ท่าแลง",
              subdistrictNameEn: "Tha Laeng",
              postalCode: 76130,
            },
            {
              subdistrictNameTh: "บ้านในดง",
              subdistrictNameEn: "Ban Nai Dong",
              postalCode: 76130,
            },
          ],
        },
        {
          districtNameTh: "บ้านลาด",
          districtNameEn: "Ban Lat",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านลาด",
              subdistrictNameEn: "Ban Lat",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "บ้านหาด",
              subdistrictNameEn: "Ban Hat",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "บ้านทาน",
              subdistrictNameEn: "Ban Than",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ตำหรุ",
              subdistrictNameEn: "Tamru",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "สมอพลือ",
              subdistrictNameEn: "Samo Phlue",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ไร่มะขาม",
              subdistrictNameEn: "Rai Makham",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ท่าเสน",
              subdistrictNameEn: "Tha Sen",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "หนองกระเจ็ด",
              subdistrictNameEn: "Nong Krachet",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "หนองกะปุ",
              subdistrictNameEn: "Nong Kapu",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ลาดโพธิ์",
              subdistrictNameEn: "Lat Pho",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "สะพานไกร",
              subdistrictNameEn: "Saphan Krai",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ไร่โคก",
              subdistrictNameEn: "Rai Khok",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "โรงเข้",
              subdistrictNameEn: "Rong Khe",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ไร่สะท้อน",
              subdistrictNameEn: "Rai Sathon",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ห้วยข้อง",
              subdistrictNameEn: "Huai Khong",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ถ้ำรงค์",
              subdistrictNameEn: "Tham Rong",
              postalCode: 76150,
            },
            {
              subdistrictNameTh: "ห้วยลึก",
              subdistrictNameEn: "Huai Luek",
              postalCode: 76150,
            },
          ],
        },
        {
          districtNameTh: "บ้านแหลม",
          districtNameEn: "Ban Laem",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านแหลม",
              subdistrictNameEn: "Ban Laem",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "บางขุนไทร",
              subdistrictNameEn: "Bang Khun Sai",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "ปากทะเล",
              subdistrictNameEn: "Pak Thale",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "แหลมผักเบี้ย",
              subdistrictNameEn: "Laem Phak Bia",
              postalCode: 76100,
            },
            {
              subdistrictNameTh: "บางตะบูน",
              subdistrictNameEn: "Bang Tabun",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "บางตะบูนออก",
              subdistrictNameEn: "Bang Tabun Ok",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "บางครก",
              subdistrictNameEn: "Bang Khrok",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "ท่าแร้ง",
              subdistrictNameEn: "Tha Raeng",
              postalCode: 76110,
            },
            {
              subdistrictNameTh: "ท่าแร้งออก",
              subdistrictNameEn: "Tha Raeng Ok",
              postalCode: 76110,
            },
          ],
        },
        {
          districtNameTh: "แก่งกระจาน",
          districtNameEn: "Kaeng Krachan",
          subdistricts: [
            {
              subdistrictNameTh: "แก่งกระจาน",
              subdistrictNameEn: "Kaeng Krachan",
              postalCode: 76170,
            },
            {
              subdistrictNameTh: "สองพี่น้อง",
              subdistrictNameEn: "Song Phi Nong",
              postalCode: 76170,
            },
            {
              subdistrictNameTh: "วังจันทร์",
              subdistrictNameEn: "Wang Chan",
              postalCode: 76170,
            },
            {
              subdistrictNameTh: "ป่าเด็ง",
              subdistrictNameEn: "Pa Deng",
              postalCode: 76170,
            },
            {
              subdistrictNameTh: "พุสวรรค์",
              subdistrictNameEn: "Phu Sawan",
              postalCode: 76170,
            },
            {
              subdistrictNameTh: "ห้วยแม่เพรียง",
              subdistrictNameEn: "Huai Mae Phriang",
              postalCode: 76170,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ประจวบคีรีขันธ์",
      stateNameEn: "Prachuap Khiri Khan",
      districts: [
        {
          districtNameTh: "เมืองประจวบคีรีขันธ์",
          districtNameEn: "Mueang Prachuap Khiri Khan",
          subdistricts: [
            {
              subdistrictNameTh: "ประจวบคีรีขันธ์",
              subdistrictNameEn: "Prachuap Khiri Khan",
              postalCode: 77000,
            },
            {
              subdistrictNameTh: "เกาะหลัก",
              subdistrictNameEn: "Ko Lak",
              postalCode: 77000,
            },
            {
              subdistrictNameTh: "คลองวาฬ",
              subdistrictNameEn: "Khlong Wan",
              postalCode: 77000,
            },
            {
              subdistrictNameTh: "ห้วยทราย",
              subdistrictNameEn: "Huai Sai",
              postalCode: 77000,
            },
            {
              subdistrictNameTh: "อ่าวน้อย",
              subdistrictNameEn: "Ao Noi",
              postalCode: 77000,
            },
            {
              subdistrictNameTh: "บ่อนอก",
              subdistrictNameEn: "Bo Nok",
              postalCode: 77210,
            },
          ],
        },
        {
          districtNameTh: "กุยบุรี",
          districtNameEn: "Kui Buri",
          subdistricts: [
            {
              subdistrictNameTh: "กุยบุรี",
              subdistrictNameEn: "Kui Buri",
              postalCode: 77150,
            },
            {
              subdistrictNameTh: "กุยเหนือ",
              subdistrictNameEn: "Kui Nuea",
              postalCode: 77150,
            },
            {
              subdistrictNameTh: "เขาแดง",
              subdistrictNameEn: "Khao Daeng",
              postalCode: 77150,
            },
            {
              subdistrictNameTh: "ดอนยายหนู",
              subdistrictNameEn: "Don Yai Nu",
              postalCode: 77150,
            },
            {
              subdistrictNameTh: "สามกระทาย",
              subdistrictNameEn: "Sam Krathai",
              postalCode: 77150,
            },
            {
              subdistrictNameTh: "หาดขาม",
              subdistrictNameEn: "Hat Kham",
              postalCode: 77150,
            },
          ],
        },
        {
          districtNameTh: "ทับสะแก",
          districtNameEn: "Thap Sakae",
          subdistricts: [
            {
              subdistrictNameTh: "ทับสะแก",
              subdistrictNameEn: "Thap Sakae",
              postalCode: 77130,
            },
            {
              subdistrictNameTh: "อ่างทอง",
              subdistrictNameEn: "Ang Thong",
              postalCode: 77130,
            },
            {
              subdistrictNameTh: "นาหูกวาง",
              subdistrictNameEn: "Na Hu Kwang",
              postalCode: 77130,
            },
            {
              subdistrictNameTh: "เขาล้าน",
              subdistrictNameEn: "Khao Lan",
              postalCode: 77130,
            },
            {
              subdistrictNameTh: "ห้วยยาง",
              subdistrictNameEn: "Huai Yang",
              postalCode: 77130,
            },
            {
              subdistrictNameTh: "แสงอรุณ",
              subdistrictNameEn: "Saeng Arun",
              postalCode: 77130,
            },
          ],
        },
        {
          districtNameTh: "บางสะพาน",
          districtNameEn: "Bang Saphan",
          subdistricts: [
            {
              subdistrictNameTh: "กำเนิดนพคุณ",
              subdistrictNameEn: "Kamnoet Nopphakhun",
              postalCode: 77140,
            },
            {
              subdistrictNameTh: "พงศ์ประศาสน์",
              subdistrictNameEn: "Phong Prasat",
              postalCode: 77140,
            },
            {
              subdistrictNameTh: "ร่อนทอง",
              subdistrictNameEn: "Ron Thong",
              postalCode: 77230,
            },
            {
              subdistrictNameTh: "ธงชัย",
              subdistrictNameEn: "Thong Chai",
              postalCode: 77190,
            },
            {
              subdistrictNameTh: "ชัยเกษม",
              subdistrictNameEn: "Chai Kasem",
              postalCode: 77190,
            },
            {
              subdistrictNameTh: "ทองมงคล",
              subdistrictNameEn: "Thong Mongkhon",
              postalCode: 77230,
            },
            {
              subdistrictNameTh: "แม่รำพึง",
              subdistrictNameEn: "Mae Ramphueng",
              postalCode: 77140,
            },
          ],
        },
        {
          districtNameTh: "บางสะพานน้อย",
          districtNameEn: "Bang Saphan Noi",
          subdistricts: [
            {
              subdistrictNameTh: "ปากแพรก",
              subdistrictNameEn: "Pak Phraek",
              postalCode: 77170,
            },
            {
              subdistrictNameTh: "บางสะพาน",
              subdistrictNameEn: "Bang Saphan",
              postalCode: 77170,
            },
            {
              subdistrictNameTh: "ทรายทอง",
              subdistrictNameEn: "Sai Thong",
              postalCode: 77170,
            },
            {
              subdistrictNameTh: "ช้างแรก",
              subdistrictNameEn: "Chang Raek",
              postalCode: 77170,
            },
            {
              subdistrictNameTh: "ไชยราช",
              subdistrictNameEn: "Chaiyarat",
              postalCode: 77170,
            },
          ],
        },
        {
          districtNameTh: "ปราณบุรี",
          districtNameEn: "Pran Buri",
          subdistricts: [
            {
              subdistrictNameTh: "ปราณบุรี",
              subdistrictNameEn: "Pran Buri",
              postalCode: 77120,
            },
            {
              subdistrictNameTh: "เขาน้อย",
              subdistrictNameEn: "Khao Noi",
              postalCode: 77120,
            },
            {
              subdistrictNameTh: "ปากน้ำปราณ",
              subdistrictNameEn: "Pak Nam Pran",
              postalCode: 77220,
            },
            {
              subdistrictNameTh: "หนองตาแต้ม",
              subdistrictNameEn: "Nong Ta Taem",
              postalCode: 77120,
            },
            {
              subdistrictNameTh: "วังก์พง",
              subdistrictNameEn: "Wang Phong",
              postalCode: 77120,
            },
            {
              subdistrictNameTh: "เขาจ้าว",
              subdistrictNameEn: "Khao Chao",
              postalCode: 77120,
            },
          ],
        },
        {
          districtNameTh: "หัวหิน",
          districtNameEn: "Hua Hin",
          subdistricts: [
            {
              subdistrictNameTh: "หัวหิน",
              subdistrictNameEn: "Hua Hin",
              postalCode: 77110,
            },
            {
              subdistrictNameTh: "หนองแก",
              subdistrictNameEn: "Nong Kae",
              postalCode: 77110,
            },
            {
              subdistrictNameTh: "หินเหล็กไฟ",
              subdistrictNameEn: "Hin Lek Fai",
              postalCode: 77110,
            },
            {
              subdistrictNameTh: "หนองพลับ",
              subdistrictNameEn: "Nong Phlap",
              postalCode: 77110,
            },
            {
              subdistrictNameTh: "ทับใต้",
              subdistrictNameEn: "Thap Tai",
              postalCode: 77110,
            },
            {
              subdistrictNameTh: "ห้วยสัตว์ใหญ่",
              subdistrictNameEn: "Huai Sat Yai",
              postalCode: 77110,
            },
            {
              subdistrictNameTh: "บึงนคร",
              subdistrictNameEn: "Bueng Nakhon",
              postalCode: 77110,
            },
          ],
        },
        {
          districtNameTh: "สามร้อยยอด",
          districtNameEn: "Sam Roi Yot",
          subdistricts: [
            {
              subdistrictNameTh: "สามร้อยยอด",
              subdistrictNameEn: "Sam Roi Yot",
              postalCode: 77120,
            },
            {
              subdistrictNameTh: "ศิลาลอย",
              subdistrictNameEn: "Sila Loi",
              postalCode: 77180,
            },
            {
              subdistrictNameTh: "ไร่เก่า",
              subdistrictNameEn: "Rai Kao",
              postalCode: 77180,
            },
            {
              subdistrictNameTh: "ศาลาลัย",
              subdistrictNameEn: "Sala Lai",
              postalCode: 77180,
            },
            {
              subdistrictNameTh: "ไร่ใหม่",
              subdistrictNameEn: "Rai Mai",
              postalCode: 77180,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นครศรีธรรมราช",
      stateNameEn: "Nakhon Si Thammarat",
      districts: [
        {
          districtNameTh: "เมืองนครศรีธรรมราช",
          districtNameEn: "Mueang Nakhon Si Thammarat",
          subdistricts: [
            {
              subdistrictNameTh: "ในเมือง",
              subdistrictNameEn: "Nai Mueang",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ท่าวัง",
              subdistrictNameEn: "Tha Wang",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "คลัง",
              subdistrictNameEn: "Khlang",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ท่าไร่",
              subdistrictNameEn: "Tha Rai",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ปากนคร",
              subdistrictNameEn: "Pak Nakhon",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "นาทราย",
              subdistrictNameEn: "Na Sai",
              postalCode: 80280,
            },
            {
              subdistrictNameTh: "กำแพงเซา",
              subdistrictNameEn: "Kamphaeng Sao",
              postalCode: 80280,
            },
            {
              subdistrictNameTh: "ไชยมนตรี",
              subdistrictNameEn: "Chai Montri",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "มะม่วงสองต้น",
              subdistrictNameEn: "Mamuang Song Ton",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "นาเคียน",
              subdistrictNameEn: "Na Khian",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ท่างิ้ว",
              subdistrictNameEn: "Tha Ngio",
              postalCode: 80280,
            },
            {
              subdistrictNameTh: "โพธิ์เสด็จ",
              subdistrictNameEn: "Pho Sadet",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "บางจาก",
              subdistrictNameEn: "Bang Chak",
              postalCode: 80330,
            },
            {
              subdistrictNameTh: "ปากพูน",
              subdistrictNameEn: "Pak Phun",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ท่าซัก",
              subdistrictNameEn: "Tha Sak",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 80290,
            },
          ],
        },
        {
          districtNameTh: "พรหมคีรี",
          districtNameEn: "Phrom Khiri",
          subdistricts: [
            {
              subdistrictNameTh: "พรหมโลก",
              subdistrictNameEn: "Phrom Lok",
              postalCode: 80320,
            },
            {
              subdistrictNameTh: "บ้านเกาะ",
              subdistrictNameEn: "Ban Ko",
              postalCode: 80320,
            },
            {
              subdistrictNameTh: "อินคีรี",
              subdistrictNameEn: "In Khiri",
              postalCode: 80320,
            },
            {
              subdistrictNameTh: "ทอนหงส์",
              subdistrictNameEn: "Thon Hong",
              postalCode: 80320,
            },
            {
              subdistrictNameTh: "นาเรียง",
              subdistrictNameEn: "Na Riang",
              postalCode: 80320,
            },
          ],
        },
        {
          districtNameTh: "ลานสกา",
          districtNameEn: "Lan Saka",
          subdistricts: [
            {
              subdistrictNameTh: "เขาแก้ว",
              subdistrictNameEn: "Khao Kaeo",
              postalCode: 80230,
            },
            {
              subdistrictNameTh: "ลานสกา",
              subdistrictNameEn: "Lan Saka",
              postalCode: 80230,
            },
            {
              subdistrictNameTh: "ท่าดี",
              subdistrictNameEn: "Tha Di",
              postalCode: 80230,
            },
            {
              subdistrictNameTh: "กำโลน",
              subdistrictNameEn: "Kamlon",
              postalCode: 80230,
            },
            {
              subdistrictNameTh: "ขุนทะเล",
              subdistrictNameEn: "Khun Thale",
              postalCode: 80230,
            },
          ],
        },
        {
          districtNameTh: "ฉวาง",
          districtNameEn: "Chawang",
          subdistricts: [
            {
              subdistrictNameTh: "ฉวาง",
              subdistrictNameEn: "Chawang",
              postalCode: 80150,
            },
            {
              subdistrictNameTh: "ละอาย",
              subdistrictNameEn: "La-Ai",
              postalCode: 80250,
            },
            {
              subdistrictNameTh: "นาแว",
              subdistrictNameEn: "Na Wae",
              postalCode: 80260,
            },
            {
              subdistrictNameTh: "ไม้เรียง",
              subdistrictNameEn: "Mai Riang",
              postalCode: 80150,
            },
            {
              subdistrictNameTh: "กะเปียด",
              subdistrictNameEn: "Kapiat",
              postalCode: 80260,
            },
            {
              subdistrictNameTh: "นากะชะ",
              subdistrictNameEn: "Na Kacha",
              postalCode: 80150,
            },
            {
              subdistrictNameTh: "ห้วยปริก",
              subdistrictNameEn: "Huai Prik",
              postalCode: 80260,
            },
            {
              subdistrictNameTh: "ไสหร้า",
              subdistrictNameEn: "Sai Ra",
              postalCode: 80150,
            },
            {
              subdistrictNameTh: "นาเขลียง",
              subdistrictNameEn: "Na Khliang",
              postalCode: 80260,
            },
            {
              subdistrictNameTh: "จันดี",
              subdistrictNameEn: "Chan Di",
              postalCode: 80250,
            },
          ],
        },
        {
          districtNameTh: "พิปูน",
          districtNameEn: "Phipun",
          subdistricts: [
            {
              subdistrictNameTh: "พิปูน",
              subdistrictNameEn: "Phipun",
              postalCode: 80270,
            },
            {
              subdistrictNameTh: "กะทูน",
              subdistrictNameEn: "Kathun",
              postalCode: 80270,
            },
            {
              subdistrictNameTh: "เขาพระ",
              subdistrictNameEn: "Khao Phra",
              postalCode: 80270,
            },
            {
              subdistrictNameTh: "ยางค้อม",
              subdistrictNameEn: "Yang Khom",
              postalCode: 80270,
            },
            {
              subdistrictNameTh: "ควนกลาง",
              subdistrictNameEn: "Khuan Klang",
              postalCode: 80270,
            },
          ],
        },
        {
          districtNameTh: "เชียรใหญ่",
          districtNameEn: "Chian Yai",
          subdistricts: [
            {
              subdistrictNameTh: "เชียรใหญ่",
              subdistrictNameEn: "Chian Yai",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "ท่าขนาน",
              subdistrictNameEn: "Tha Khanan",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "บ้านเนิน",
              subdistrictNameEn: "Ban Noen",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "ไสหมาก",
              subdistrictNameEn: "Sai Mak",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "ท้องลำเจียก",
              subdistrictNameEn: "Thong Lamchiak",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "เสือหึง",
              subdistrictNameEn: "Suea Hueng",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "การะเกด",
              subdistrictNameEn: "Karaket",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "เขาพระบาท",
              subdistrictNameEn: "Khao Phrabat",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "แม่เจ้าอยู่หัว",
              subdistrictNameEn: "Mae Chao Yu Hua",
              postalCode: 80190,
            },
          ],
        },
        {
          districtNameTh: "ชะอวด",
          districtNameEn: "Cha-Uat",
          subdistricts: [
            {
              subdistrictNameTh: "ชะอวด",
              subdistrictNameEn: "Cha-Uat",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "ท่าเสม็ด",
              subdistrictNameEn: "Tha Samet",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "ท่าประจะ",
              subdistrictNameEn: "Tha Pracha",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "เคร็ง",
              subdistrictNameEn: "Khreng",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "วังอ่าง",
              subdistrictNameEn: "Wang Ang",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "บ้านตูล",
              subdistrictNameEn: "Ban Tun",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "ขอนหาด",
              subdistrictNameEn: "Khon Hat",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "เกาะขันธ์",
              subdistrictNameEn: "Ko Khan",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "ควนหนองหงษ์",
              subdistrictNameEn: "Khuan Nong Hong",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "เขาพระทอง",
              subdistrictNameEn: "Khao Phra Thong",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "นางหลง",
              subdistrictNameEn: "Nang Long",
              postalCode: 80180,
            },
          ],
        },
        {
          districtNameTh: "ท่าศาลา",
          districtNameEn: "Tha Sala",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าศาลา",
              subdistrictNameEn: "Tha Sala",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "กลาย",
              subdistrictNameEn: "Klai",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "ท่าขึ้น",
              subdistrictNameEn: "Tha Khuen",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "หัวตะพาน",
              subdistrictNameEn: "Hua Taphan",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "สระแก้ว",
              subdistrictNameEn: "Sa Kaeo",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "โมคลาน",
              subdistrictNameEn: "Mo Khlan",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "ไทยบุรี",
              subdistrictNameEn: "Thai Buri",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "ดอนตะโก",
              subdistrictNameEn: "Don Tako",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "โพธิ์ทอง",
              subdistrictNameEn: "Pho Thong",
              postalCode: 80160,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งสง",
          districtNameEn: "Thung Song",
          subdistricts: [
            {
              subdistrictNameTh: "ปากแพรก",
              subdistrictNameEn: "Pak Phraek",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "ชะมาย",
              subdistrictNameEn: "Chamai",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "หนองหงส์",
              subdistrictNameEn: "Nong Hong",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "ควนกรด",
              subdistrictNameEn: "Khuan Krot",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "นาไม้ไผ่",
              subdistrictNameEn: "Na Mai Phai",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "นาหลวงเสน",
              subdistrictNameEn: "Na Luang Sen",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "เขาโร",
              subdistrictNameEn: "Khao Ro",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "กะปาง",
              subdistrictNameEn: "Kapang",
              postalCode: 80310,
            },
            {
              subdistrictNameTh: "ที่วัง",
              subdistrictNameEn: "Thi Wang",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "น้ำตก",
              subdistrictNameEn: "Namtok",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "ถ้ำใหญ่",
              subdistrictNameEn: "Tham Yai",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 80110,
            },
            {
              subdistrictNameTh: "เขาขาว",
              subdistrictNameEn: "Khao Khao",
              postalCode: 80110,
            },
          ],
        },
        {
          districtNameTh: "นาบอน",
          districtNameEn: "Na Bon",
          subdistricts: [
            {
              subdistrictNameTh: "นาบอน",
              subdistrictNameEn: "Na Bon",
              postalCode: 80220,
            },
            {
              subdistrictNameTh: "ทุ่งสง",
              subdistrictNameEn: "Thung Song",
              postalCode: 80220,
            },
            {
              subdistrictNameTh: "แก้วแสน",
              subdistrictNameEn: "Kaeo Saen",
              postalCode: 80220,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งใหญ่",
          districtNameEn: "Thung Yai",
          subdistricts: [
            {
              subdistrictNameTh: "ท่ายาง",
              subdistrictNameEn: "Tha Yang",
              postalCode: 80240,
            },
            {
              subdistrictNameTh: "ทุ่งสัง",
              subdistrictNameEn: "Thung Sang",
              postalCode: 80240,
            },
            {
              subdistrictNameTh: "ทุ่งใหญ่",
              subdistrictNameEn: "Thung Yai",
              postalCode: 80240,
            },
            {
              subdistrictNameTh: "กุแหระ",
              subdistrictNameEn: "Kurae",
              postalCode: 80240,
            },
            {
              subdistrictNameTh: "ปริก",
              subdistrictNameEn: "Prik",
              postalCode: 80240,
            },
            {
              subdistrictNameTh: "บางรูป",
              subdistrictNameEn: "Bang Rup",
              postalCode: 80240,
            },
            {
              subdistrictNameTh: "กรุงหยัน",
              subdistrictNameEn: "Krung Yan",
              postalCode: 80240,
            },
          ],
        },
        {
          districtNameTh: "ปากพนัง",
          districtNameEn: "Pak Phanang",
          subdistricts: [
            {
              subdistrictNameTh: "ปากพนัง",
              subdistrictNameEn: "Pak Phanang",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "คลองน้อย",
              subdistrictNameEn: "Khlong Noi",
              postalCode: 80330,
            },
            {
              subdistrictNameTh: "ป่าระกำ",
              subdistrictNameEn: "Pa Rakam",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "ชะเมา",
              subdistrictNameEn: "Chamao",
              postalCode: 80330,
            },
            {
              subdistrictNameTh: "คลองกระบือ",
              subdistrictNameEn: "Khlong Krabue",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "เกาะทวด",
              subdistrictNameEn: "Ko Thuat",
              postalCode: 80330,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "หูล่อง",
              subdistrictNameEn: "Hu Long",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "แหลมตะลุมพุก",
              subdistrictNameEn: "Laem Talumphuk",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "ปากพนังฝั่งตะวันตก",
              subdistrictNameEn: "Pak Phanang Fang Tawan Tok",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "บางศาลา",
              subdistrictNameEn: "Bang Sala",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "บางพระ",
              subdistrictNameEn: "Bang Phra",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "บางตะพง",
              subdistrictNameEn: "Bang Taphong",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "ปากพนังฝั่งตะวันออก",
              subdistrictNameEn: "Pak Phanang Fang Tawan Ok",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "บ้านเพิง",
              subdistrictNameEn: "Ban Phoeng",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "ท่าพยา",
              subdistrictNameEn: "Tha Phaya",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "ปากแพรก",
              subdistrictNameEn: "Pak Phraek",
              postalCode: 80140,
            },
            {
              subdistrictNameTh: "ขนาบนาก",
              subdistrictNameEn: "Khanap Nak",
              postalCode: 80140,
            },
          ],
        },
        {
          districtNameTh: "ร่อนพิบูลย์",
          districtNameEn: "Ron Phibun",
          subdistricts: [
            {
              subdistrictNameTh: "ร่อนพิบูลย์",
              subdistrictNameEn: "Ron Phibun",
              postalCode: 80130,
            },
            {
              subdistrictNameTh: "หินตก",
              subdistrictNameEn: "Hin Tok",
              postalCode: 80350,
            },
            {
              subdistrictNameTh: "เสาธง",
              subdistrictNameEn: "Sao Thong",
              postalCode: 80350,
            },
            {
              subdistrictNameTh: "ควนเกย",
              subdistrictNameEn: "Khuan Koei",
              postalCode: 80130,
            },
            {
              subdistrictNameTh: "ควนพัง",
              subdistrictNameEn: "Khuan Phang",
              postalCode: 80130,
            },
            {
              subdistrictNameTh: "ควนชุม",
              subdistrictNameEn: "Khuan Chum",
              postalCode: 80130,
            },
          ],
        },
        {
          districtNameTh: "สิชล",
          districtNameEn: "Sichon",
          subdistricts: [
            {
              subdistrictNameTh: "สิชล",
              subdistrictNameEn: "Sichon",
              postalCode: 80120,
            },
            {
              subdistrictNameTh: "ทุ่งปรัง",
              subdistrictNameEn: "Thung Prang",
              postalCode: 80120,
            },
            {
              subdistrictNameTh: "ฉลอง",
              subdistrictNameEn: "Chalong",
              postalCode: 80120,
            },
            {
              subdistrictNameTh: "เสาเภา",
              subdistrictNameEn: "Sao Phao",
              postalCode: 80340,
            },
            {
              subdistrictNameTh: "เปลี่ยน",
              subdistrictNameEn: "Plian",
              postalCode: 80120,
            },
            {
              subdistrictNameTh: "สี่ขีด",
              subdistrictNameEn: "Si Khit",
              postalCode: 80120,
            },
            {
              subdistrictNameTh: "เทพราช",
              subdistrictNameEn: "Theppharat",
              postalCode: 80340,
            },
            {
              subdistrictNameTh: "เขาน้อย",
              subdistrictNameEn: "Khao Noi",
              postalCode: 80120,
            },
            {
              subdistrictNameTh: "ทุ่งใส",
              subdistrictNameEn: "Thung Sai",
              postalCode: 80120,
            },
          ],
        },
        {
          districtNameTh: "ขนอม",
          districtNameEn: "Khanom",
          subdistricts: [
            {
              subdistrictNameTh: "ขนอม",
              subdistrictNameEn: "Khanom",
              postalCode: 80210,
            },
            {
              subdistrictNameTh: "ควนทอง",
              subdistrictNameEn: "Khuan Thong",
              postalCode: 80210,
            },
            {
              subdistrictNameTh: "ท้องเนียน",
              subdistrictNameEn: "Thong Nian",
              postalCode: 80210,
            },
          ],
        },
        {
          districtNameTh: "หัวไทร",
          districtNameEn: "Hua Sai",
          subdistricts: [
            {
              subdistrictNameTh: "หัวไทร",
              subdistrictNameEn: "Hua Sai",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "หน้าสตน",
              subdistrictNameEn: "Na Saton",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "ทรายขาว",
              subdistrictNameEn: "Sai Khao",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "แหลม",
              subdistrictNameEn: "Laem",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "เขาพังไกร",
              subdistrictNameEn: "Khao Phang Krai",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "บ้านราม",
              subdistrictNameEn: "Ban Ram",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "บางนบ",
              subdistrictNameEn: "Bang Nop",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "ท่าซอม",
              subdistrictNameEn: "Tha Som",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "ควนชะลิก",
              subdistrictNameEn: "Khuan Chalik",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "รามแก้ว",
              subdistrictNameEn: "Ram Kaeo",
              postalCode: 80170,
            },
            {
              subdistrictNameTh: "เกาะเพชร",
              subdistrictNameEn: "Ko Phet",
              postalCode: 80170,
            },
          ],
        },
        {
          districtNameTh: "บางขัน",
          districtNameEn: "Bang Khan",
          subdistricts: [
            {
              subdistrictNameTh: "บางขัน",
              subdistrictNameEn: "Bang Khan",
              postalCode: 80360,
            },
            {
              subdistrictNameTh: "บ้านลำนาว",
              subdistrictNameEn: "Ban Lamnao",
              postalCode: 80360,
            },
            {
              subdistrictNameTh: "วังหิน",
              subdistrictNameEn: "Wang Hin",
              postalCode: 80360,
            },
            {
              subdistrictNameTh: "บ้านนิคม",
              subdistrictNameEn: "Ban Nikhom",
              postalCode: 80360,
            },
          ],
        },
        {
          districtNameTh: "ถ้ำพรรณรา",
          districtNameEn: "Tham Phannara",
          subdistricts: [
            {
              subdistrictNameTh: "ถ้ำพรรณรา",
              subdistrictNameEn: "Tham Phannara",
              postalCode: 80260,
            },
            {
              subdistrictNameTh: "คลองเส",
              subdistrictNameEn: "Khlong Se",
              postalCode: 80260,
            },
            {
              subdistrictNameTh: "ดุสิต",
              subdistrictNameEn: "Dusit",
              postalCode: 80260,
            },
          ],
        },
        {
          districtNameTh: "จุฬาภรณ์",
          districtNameEn: "Chulabhorn",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านควนมุด",
              subdistrictNameEn: "Ban Khuan Mut",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "บ้านชะอวด",
              subdistrictNameEn: "Ban Cha-Uat",
              postalCode: 80180,
            },
            {
              subdistrictNameTh: "ควนหนองคว้า",
              subdistrictNameEn: "Khuan Nong Kwa",
              postalCode: 80130,
            },
            {
              subdistrictNameTh: "ทุ่งโพธิ์",
              subdistrictNameEn: "Thung Pho",
              postalCode: 80130,
            },
            {
              subdistrictNameTh: "นาหมอบุญ",
              subdistrictNameEn: "Na Mo Bun",
              postalCode: 80130,
            },
            {
              subdistrictNameTh: "สามตำบล",
              subdistrictNameEn: "Sam Tambon",
              postalCode: 80130,
            },
          ],
        },
        {
          districtNameTh: "พระพรหม",
          districtNameEn: "Phra Phrom",
          subdistricts: [
            {
              subdistrictNameTh: "นาพรุ",
              subdistrictNameEn: "Na Phru",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "นาสาร",
              subdistrictNameEn: "Na San",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ท้ายสำเภา",
              subdistrictNameEn: "Thai Samphao",
              postalCode: 80000,
            },
            {
              subdistrictNameTh: "ช้างซ้าย",
              subdistrictNameEn: "Chang Sai",
              postalCode: 80000,
            },
          ],
        },
        {
          districtNameTh: "นบพิตำ",
          districtNameEn: "Nopphitam",
          subdistricts: [
            {
              subdistrictNameTh: "นบพิตำ",
              subdistrictNameEn: "Nopphitam",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "กรุงชิง",
              subdistrictNameEn: "Krung Ching",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "กะหรอ",
              subdistrictNameEn: "Karo",
              postalCode: 80160,
            },
            {
              subdistrictNameTh: "นาเหรง",
              subdistrictNameEn: "Na Reng",
              postalCode: 80160,
            },
          ],
        },
        {
          districtNameTh: "ช้างกลาง",
          districtNameEn: "Chang Klang",
          subdistricts: [
            {
              subdistrictNameTh: "ช้างกลาง",
              subdistrictNameEn: "Chang Klang",
              postalCode: 80250,
            },
            {
              subdistrictNameTh: "หลักช้าง",
              subdistrictNameEn: "Lak Chang",
              postalCode: 80250,
            },
            {
              subdistrictNameTh: "สวนขัน",
              subdistrictNameEn: "Suan Khan",
              postalCode: 80250,
            },
          ],
        },
        {
          districtNameTh: "เฉลิมพระเกียรติ",
          districtNameEn: "Chaloem Phra Kiet",
          subdistricts: [
            {
              subdistrictNameTh: "เชียรเขา",
              subdistrictNameEn: "Chian Khao",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "ดอนตรอ",
              subdistrictNameEn: "Don Tro",
              postalCode: 80290,
            },
            {
              subdistrictNameTh: "สวนหลวง",
              subdistrictNameEn: "Suan Luang",
              postalCode: 80190,
            },
            {
              subdistrictNameTh: "ทางพูน",
              subdistrictNameEn: "Thang Phun",
              postalCode: 80190,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "กระบี่",
      stateNameEn: "Krabi",
      districts: [
        {
          districtNameTh: "เมืองกระบี่",
          districtNameEn: "Mueang Krabi",
          subdistricts: [
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "กระบี่ใหญ่",
              subdistrictNameEn: "Krabi Yai",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "กระบี่น้อย",
              subdistrictNameEn: "Krabi Noi",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "เขาคราม",
              subdistrictNameEn: "Khao Khram",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "เขาทอง",
              subdistrictNameEn: "Khao Thong",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "ทับปริก",
              subdistrictNameEn: "Thap Prik",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "ไสไทย",
              subdistrictNameEn: "Sai Thai",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "อ่าวนาง",
              subdistrictNameEn: "Ao Nang",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "หนองทะเล",
              subdistrictNameEn: "Nong Thale",
              postalCode: 81000,
            },
            {
              subdistrictNameTh: "คลองประสงค์",
              subdistrictNameEn: "Khlong Prasong",
              postalCode: 81000,
            },
          ],
        },
        {
          districtNameTh: "เขาพนม",
          districtNameEn: "Khao Phanom",
          subdistricts: [
            {
              subdistrictNameTh: "เขาพนม",
              subdistrictNameEn: "Khao Phanom",
              postalCode: 81140,
            },
            {
              subdistrictNameTh: "เขาดิน",
              subdistrictNameEn: "Khao Din",
              postalCode: 81140,
            },
            {
              subdistrictNameTh: "สินปุน",
              subdistrictNameEn: "Sin Pun",
              postalCode: 80240,
            },
            {
              subdistrictNameTh: "พรุเตียว",
              subdistrictNameEn: "Phru Tiao",
              postalCode: 81140,
            },
            {
              subdistrictNameTh: "หน้าเขา",
              subdistrictNameEn: "Na Khao",
              postalCode: 81140,
            },
            {
              subdistrictNameTh: "โคกหาร",
              subdistrictNameEn: "Khok Han",
              postalCode: 80240,
            },
          ],
        },
        {
          districtNameTh: "เกาะลันตา",
          districtNameEn: "Ko Lanta",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะลันตาใหญ่",
              subdistrictNameEn: "Ko Lanta Yai",
              postalCode: 81150,
            },
            {
              subdistrictNameTh: "เกาะลันตาน้อย",
              subdistrictNameEn: "Ko Lanta Noi",
              postalCode: 81150,
            },
            {
              subdistrictNameTh: "เกาะกลาง",
              subdistrictNameEn: "Ko Klang",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "คลองยาง",
              subdistrictNameEn: "Khlong Yang",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "ศาลาด่าน",
              subdistrictNameEn: "Sala Dan",
              postalCode: 81150,
            },
          ],
        },
        {
          districtNameTh: "คลองท่อม",
          districtNameEn: "Khlong Thom",
          subdistricts: [
            {
              subdistrictNameTh: "คลองท่อมใต้",
              subdistrictNameEn: "Khlong Thom Tai",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "คลองท่อมเหนือ",
              subdistrictNameEn: "Khlong Thom Nuea",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "คลองพน",
              subdistrictNameEn: "Khlong Phon",
              postalCode: 81170,
            },
            {
              subdistrictNameTh: "ทรายขาว",
              subdistrictNameEn: "Sai Khao",
              postalCode: 81170,
            },
            {
              subdistrictNameTh: "ห้วยน้ำขาว",
              subdistrictNameEn: "Huai Nam Khao",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "พรุดินนา",
              subdistrictNameEn: "Phru Din Na",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "เพหลา",
              subdistrictNameEn: "Phela",
              postalCode: 81120,
            },
          ],
        },
        {
          districtNameTh: "อ่าวลึก",
          districtNameEn: "Ao Luek",
          subdistricts: [
            {
              subdistrictNameTh: "อ่าวลึกใต้",
              subdistrictNameEn: "Ao Luek Tai",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "แหลมสัก",
              subdistrictNameEn: "Laem Sak",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "นาเหนือ",
              subdistrictNameEn: "Na Nuea",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "คลองหิน",
              subdistrictNameEn: "Khlong Hin",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "อ่าวลึกน้อย",
              subdistrictNameEn: "Ao Luek Noi",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "อ่าวลึกเหนือ",
              subdistrictNameEn: "Ao Luek Nuea",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "เขาใหญ่",
              subdistrictNameEn: "Khao Yai",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "คลองยา",
              subdistrictNameEn: "Khlong Ya",
              postalCode: 81110,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 81110,
            },
          ],
        },
        {
          districtNameTh: "ปลายพระยา",
          districtNameEn: "Plai Phraya",
          subdistricts: [
            {
              subdistrictNameTh: "ปลายพระยา",
              subdistrictNameEn: "Plai Phraya",
              postalCode: 81160,
            },
            {
              subdistrictNameTh: "เขาเขน",
              subdistrictNameEn: "Khao Khen",
              postalCode: 81160,
            },
            {
              subdistrictNameTh: "เขาต่อ",
              subdistrictNameEn: "Khao To",
              postalCode: 81160,
            },
            {
              subdistrictNameTh: "คีรีวง",
              subdistrictNameEn: "Khiri Wong",
              postalCode: 81160,
            },
          ],
        },
        {
          districtNameTh: "ลำทับ",
          districtNameEn: "Lam Thap",
          subdistricts: [
            {
              subdistrictNameTh: "ลำทับ",
              subdistrictNameEn: "Lam Thap",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "ดินอุดม",
              subdistrictNameEn: "Din Udom",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "ทุ่งไทรทอง",
              subdistrictNameEn: "Thung Sai Thong",
              postalCode: 81120,
            },
            {
              subdistrictNameTh: "ดินแดง",
              subdistrictNameEn: "Din Daeng",
              postalCode: 81120,
            },
          ],
        },
        {
          districtNameTh: "เหนือคลอง",
          districtNameEn: "Nuea Khlong",
          subdistricts: [
            {
              subdistrictNameTh: "เหนือคลอง",
              subdistrictNameEn: "Nuea Khlong",
              postalCode: 81130,
            },
            {
              subdistrictNameTh: "เกาะศรีบอยา",
              subdistrictNameEn: "Ko Si Boya",
              postalCode: 81130,
            },
            {
              subdistrictNameTh: "คลองขนาน",
              subdistrictNameEn: "Khlong Khanan",
              postalCode: 81130,
            },
            {
              subdistrictNameTh: "คลองเขม้า",
              subdistrictNameEn: "Khlong Khamao",
              postalCode: 81130,
            },
            {
              subdistrictNameTh: "โคกยาง",
              subdistrictNameEn: "Khok Yang",
              postalCode: 81130,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 81130,
            },
            {
              subdistrictNameTh: "ปกาสัย",
              subdistrictNameEn: "Pakasai",
              postalCode: 81130,
            },
            {
              subdistrictNameTh: "ห้วยยูง",
              subdistrictNameEn: "Huai Yung",
              postalCode: 81130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "พังงา",
      stateNameEn: "Phangnga",
      districts: [
        {
          districtNameTh: "เมืองพังงา",
          districtNameEn: "Mueang Phang-Nga",
          subdistricts: [
            {
              subdistrictNameTh: "ท้ายช้าง",
              subdistrictNameEn: "Thai Chang",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "นบปริง",
              subdistrictNameEn: "Nop Pring",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "ถ้ำน้ำผุด",
              subdistrictNameEn: "Tham Nam Phut",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "บางเตย",
              subdistrictNameEn: "Bang Toei",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "ตากแดด",
              subdistrictNameEn: "Tak Daet",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "สองแพรก",
              subdistrictNameEn: "Song Phraek",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "ทุ่งคาโงก",
              subdistrictNameEn: "Thung Kha Ngok",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "เกาะปันหยี",
              subdistrictNameEn: "Ko Panyi",
              postalCode: 82000,
            },
            {
              subdistrictNameTh: "ป่ากอ",
              subdistrictNameEn: "Pa Ko",
              postalCode: 82000,
            },
          ],
        },
        {
          districtNameTh: "เกาะยาว",
          districtNameEn: "Ko Yao",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะยาวน้อย",
              subdistrictNameEn: "Ko Yao Noi",
              postalCode: 82160,
            },
            {
              subdistrictNameTh: "เกาะยาวใหญ่",
              subdistrictNameEn: "Ko Yao Yai",
              postalCode: 82160,
            },
            {
              subdistrictNameTh: "พรุใน",
              subdistrictNameEn: "Phru Nai",
              postalCode: 83000,
            },
          ],
        },
        {
          districtNameTh: "กะปง",
          districtNameEn: "Kapong",
          subdistricts: [
            {
              subdistrictNameTh: "กะปง",
              subdistrictNameEn: "Kapong",
              postalCode: 82170,
            },
            {
              subdistrictNameTh: "ท่านา",
              subdistrictNameEn: "Tha Na",
              postalCode: 82170,
            },
            {
              subdistrictNameTh: "เหมาะ",
              subdistrictNameEn: "Mo",
              postalCode: 82170,
            },
            {
              subdistrictNameTh: "เหล",
              subdistrictNameEn: "Le",
              postalCode: 82170,
            },
            {
              subdistrictNameTh: "รมณีย์",
              subdistrictNameEn: "Rommani",
              postalCode: 82170,
            },
          ],
        },
        {
          districtNameTh: "ตะกั่วทุ่ง",
          districtNameEn: "Takua Thung",
          subdistricts: [
            {
              subdistrictNameTh: "ถ้ำ",
              subdistrictNameEn: "Tham",
              postalCode: 82130,
            },
            {
              subdistrictNameTh: "กระโสม",
              subdistrictNameEn: "Krasom",
              postalCode: 82130,
            },
            {
              subdistrictNameTh: "กะไหล",
              subdistrictNameEn: "Kalai",
              postalCode: 82130,
            },
            {
              subdistrictNameTh: "ท่าอยู่",
              subdistrictNameEn: "Tha Yu",
              postalCode: 82130,
            },
            {
              subdistrictNameTh: "หล่อยูง",
              subdistrictNameEn: "Lo Yung",
              postalCode: 82140,
            },
            {
              subdistrictNameTh: "โคกกลอย",
              subdistrictNameEn: "Khok Kloi",
              postalCode: 82140,
            },
            {
              subdistrictNameTh: "คลองเคียน",
              subdistrictNameEn: "Khlong Khian",
              postalCode: 82130,
            },
          ],
        },
        {
          districtNameTh: "ตะกั่วป่า",
          districtNameEn: "Takua Pa",
          subdistricts: [
            {
              subdistrictNameTh: "ตะกั่วป่า",
              subdistrictNameEn: "Takua Pa",
              postalCode: 82110,
            },
            {
              subdistrictNameTh: "บางนายสี",
              subdistrictNameEn: "Bang Nai Si",
              postalCode: 82110,
            },
            {
              subdistrictNameTh: "บางไทร",
              subdistrictNameEn: "Bang Sai",
              postalCode: 82110,
            },
            {
              subdistrictNameTh: "บางม่วง",
              subdistrictNameEn: "Bang Muang",
              postalCode: 82110,
            },
            {
              subdistrictNameTh: "ตำตัว",
              subdistrictNameEn: "Tamtua",
              postalCode: 82110,
            },
            {
              subdistrictNameTh: "โคกเคียน",
              subdistrictNameEn: "Khok Khian",
              postalCode: 82110,
            },
            {
              subdistrictNameTh: "คึกคัก",
              subdistrictNameEn: "Khuek Khak",
              postalCode: 82190,
            },
            {
              subdistrictNameTh: "เกาะคอเขา",
              subdistrictNameEn: "Ko Kho Khao",
              postalCode: 82190,
            },
          ],
        },
        {
          districtNameTh: "คุระบุรี",
          districtNameEn: "Khura Buri",
          subdistricts: [
            {
              subdistrictNameTh: "คุระ",
              subdistrictNameEn: "Khu Ra",
              postalCode: 82150,
            },
            {
              subdistrictNameTh: "บางวัน",
              subdistrictNameEn: "Bang Wan",
              postalCode: 82150,
            },
            {
              subdistrictNameTh: "เกาะพระทอง",
              subdistrictNameEn: "Ko Phra Thong",
              postalCode: 82150,
            },
            {
              subdistrictNameTh: "แม่นางขาว",
              subdistrictNameEn: "Mae Nang Khao",
              postalCode: 82150,
            },
          ],
        },
        {
          districtNameTh: "ทับปุด",
          districtNameEn: "Thap Put",
          subdistricts: [
            {
              subdistrictNameTh: "ทับปุด",
              subdistrictNameEn: "Thap Put",
              postalCode: 82180,
            },
            {
              subdistrictNameTh: "มะรุ่ย",
              subdistrictNameEn: "Marui",
              postalCode: 82180,
            },
            {
              subdistrictNameTh: "บ่อแสน",
              subdistrictNameEn: "Bo Saen",
              postalCode: 82180,
            },
            {
              subdistrictNameTh: "ถ้ำทองหลาง",
              subdistrictNameEn: "Tham Thong Lang",
              postalCode: 82180,
            },
            {
              subdistrictNameTh: "โคกเจริญ",
              subdistrictNameEn: "Khok Charoen",
              postalCode: 82180,
            },
            {
              subdistrictNameTh: "บางเหรียง",
              subdistrictNameEn: "Bang Riang",
              postalCode: 82180,
            },
          ],
        },
        {
          districtNameTh: "ท้ายเหมือง",
          districtNameEn: "Thai Mueang",
          subdistricts: [
            {
              subdistrictNameTh: "ท้ายเหมือง",
              subdistrictNameEn: "Thai Mueang",
              postalCode: 82120,
            },
            {
              subdistrictNameTh: "นาเตย",
              subdistrictNameEn: "Na Toei",
              postalCode: 82120,
            },
            {
              subdistrictNameTh: "บางทอง",
              subdistrictNameEn: "Bang Thong",
              postalCode: 82120,
            },
            {
              subdistrictNameTh: "ทุ่งมะพร้าว",
              subdistrictNameEn: "Thung Maphrao",
              postalCode: 82120,
            },
            {
              subdistrictNameTh: "ลำภี",
              subdistrictNameEn: "Lam Phi",
              postalCode: 82120,
            },
            {
              subdistrictNameTh: "ลำแก่น",
              subdistrictNameEn: "Lam Kaen",
              postalCode: 82120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ภูเก็ต",
      stateNameEn: "Phuket",
      districts: [
        {
          districtNameTh: "เมืองภูเก็ต",
          districtNameEn: "Mueang Phuket",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาดใหญ่",
              subdistrictNameEn: "Talat Yai",
              postalCode: 83000,
            },
            {
              subdistrictNameTh: "ตลาดเหนือ",
              subdistrictNameEn: "Talat Nuea",
              postalCode: 83000,
            },
            {
              subdistrictNameTh: "เกาะแก้ว",
              subdistrictNameEn: "Ko Kaeo",
              postalCode: 83000,
            },
            {
              subdistrictNameTh: "รัษฎา",
              subdistrictNameEn: "Ratsada",
              postalCode: 83000,
            },
            {
              subdistrictNameTh: "วิชิต",
              subdistrictNameEn: "Wichit",
              postalCode: 83000,
            },
            {
              subdistrictNameTh: "ฉลอง",
              subdistrictNameEn: "Chalong",
              postalCode: 83130,
            },
            {
              subdistrictNameTh: "ราไวย์",
              subdistrictNameEn: "Rawai",
              postalCode: 83130,
            },
            {
              subdistrictNameTh: "กะรน",
              subdistrictNameEn: "Karon",
              postalCode: 83100,
            },
          ],
        },
        {
          districtNameTh: "กะทู้",
          districtNameEn: "Kathu",
          subdistricts: [
            {
              subdistrictNameTh: "กะทู้",
              subdistrictNameEn: "Kathu",
              postalCode: 83120,
            },
            {
              subdistrictNameTh: "ป่าตอง",
              subdistrictNameEn: "Patong",
              postalCode: 83150,
            },
            {
              subdistrictNameTh: "กมลา",
              subdistrictNameEn: "Kamala",
              postalCode: 83150,
            },
          ],
        },
        {
          districtNameTh: "ถลาง",
          districtNameEn: "Thalang",
          subdistricts: [
            {
              subdistrictNameTh: "เทพกระษัตรี",
              subdistrictNameEn: "Thep Krasattri",
              postalCode: 83110,
            },
            {
              subdistrictNameTh: "ศรีสุนทร",
              subdistrictNameEn: "Si Sunthon",
              postalCode: 83110,
            },
            {
              subdistrictNameTh: "เชิงทะเล",
              subdistrictNameEn: "Choeng Thale",
              postalCode: 83110,
            },
            {
              subdistrictNameTh: "ป่าคลอก",
              subdistrictNameEn: "Pa Khlok",
              postalCode: 83110,
            },
            {
              subdistrictNameTh: "ไม้ขาว",
              subdistrictNameEn: "Mai Khao",
              postalCode: 83110,
            },
            {
              subdistrictNameTh: "สาคู",
              subdistrictNameEn: "Sakhu",
              postalCode: 83110,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สุราษฎร์ธานี",
      stateNameEn: "Surat Thani",
      districts: [
        {
          districtNameTh: "เมืองสุราษฎร์ธานี",
          districtNameEn: "Mueang Surat Thani",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาด",
              subdistrictNameEn: "Talat",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "มะขามเตี้ย",
              subdistrictNameEn: "Makham Tia",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "วัดประดู่",
              subdistrictNameEn: "Wat Pradu",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "ขุนทะเล",
              subdistrictNameEn: "Khun Thale",
              postalCode: 84100,
            },
            {
              subdistrictNameTh: "บางใบไม้",
              subdistrictNameEn: "Bang Bai Mai",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "บางชนะ",
              subdistrictNameEn: "Bang Chana",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "คลองน้อย",
              subdistrictNameEn: "Khlong Noi",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "บางไทร",
              subdistrictNameEn: "Bang Sai",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "บางโพธิ์",
              subdistrictNameEn: "Bang Pho",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "บางกุ้ง",
              subdistrictNameEn: "Bang Kung",
              postalCode: 84000,
            },
            {
              subdistrictNameTh: "คลองฉนาก",
              subdistrictNameEn: "Khlong Chanak",
              postalCode: 84000,
            },
          ],
        },
        {
          districtNameTh: "กาญจนดิษฐ์",
          districtNameEn: "Kanchanadit",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าทองใหม่",
              subdistrictNameEn: "Tha Thong Mai",
              postalCode: 84290,
            },
            {
              subdistrictNameTh: "ท่าทอง",
              subdistrictNameEn: "Tha Thong",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "กะแดะ",
              subdistrictNameEn: "Kadae",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ทุ่งกง",
              subdistrictNameEn: "Thung Kong",
              postalCode: 84290,
            },
            {
              subdistrictNameTh: "กรูด",
              subdistrictNameEn: "Krut",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ช้างซ้าย",
              subdistrictNameEn: "Chang Sai",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "พลายวาส",
              subdistrictNameEn: "Phlai Wat",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ป่าร่อน",
              subdistrictNameEn: "Pa Ron",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ตะเคียนทอง",
              subdistrictNameEn: "Takhian Thong",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ช้างขวา",
              subdistrictNameEn: "Chang Khwa",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ท่าอุแท",
              subdistrictNameEn: "Tha Uthae",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ทุ่งรัง",
              subdistrictNameEn: "Thung Rang",
              postalCode: 84290,
            },
            {
              subdistrictNameTh: "คลองสระ",
              subdistrictNameEn: "Khlong Sa",
              postalCode: 84160,
            },
          ],
        },
        {
          districtNameTh: "ดอนสัก",
          districtNameEn: "Don Sak",
          subdistricts: [
            {
              subdistrictNameTh: "ดอนสัก",
              subdistrictNameEn: "Don Sak",
              postalCode: 84220,
            },
            {
              subdistrictNameTh: "ชลคราม",
              subdistrictNameEn: "Chon Khram",
              postalCode: 84160,
            },
            {
              subdistrictNameTh: "ไชยคราม",
              subdistrictNameEn: "Chai Khram",
              postalCode: 84220,
            },
            {
              subdistrictNameTh: "ปากแพรก",
              subdistrictNameEn: "Pak Phraek",
              postalCode: 84340,
            },
          ],
        },
        {
          districtNameTh: "เกาะสมุย",
          districtNameEn: "Ko Samui",
          subdistricts: [
            {
              subdistrictNameTh: "อ่างทอง",
              subdistrictNameEn: "Ang Thong",
              postalCode: 84140,
            },
            {
              subdistrictNameTh: "ลิปะน้อย",
              subdistrictNameEn: "Lipa Noi",
              postalCode: 84140,
            },
            {
              subdistrictNameTh: "ตลิ่งงาม",
              subdistrictNameEn: "Taling Ngam",
              postalCode: 84140,
            },
            {
              subdistrictNameTh: "หน้าเมือง",
              subdistrictNameEn: "Na Mueang",
              postalCode: 84140,
            },
            {
              subdistrictNameTh: "มะเร็ต",
              subdistrictNameEn: "Maret",
              postalCode: 84310,
            },
            {
              subdistrictNameTh: "บ่อผุด",
              subdistrictNameEn: "Bo Phut",
              postalCode: 84320,
            },
            {
              subdistrictNameTh: "แม่น้ำ",
              subdistrictNameEn: "Maenam",
              postalCode: 84330,
            },
          ],
        },
        {
          districtNameTh: "เกาะพะงัน",
          districtNameEn: "Ko Pha-Ngan",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะพะงัน",
              subdistrictNameEn: "Ko Pha-Ngan",
              postalCode: 84280,
            },
            {
              subdistrictNameTh: "บ้านใต้",
              subdistrictNameEn: "Ban Tai",
              postalCode: 84280,
            },
            {
              subdistrictNameTh: "เกาะเต่า",
              subdistrictNameEn: "Ko Tao",
              postalCode: 84280,
            },
          ],
        },
        {
          districtNameTh: "ไชยา",
          districtNameEn: "Chaiya",
          subdistricts: [
            {
              subdistrictNameTh: "ตลาดไชยา",
              subdistrictNameEn: "Talat Chaiya",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "พุมเรียง",
              subdistrictNameEn: "Phum Riang",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "เลม็ด",
              subdistrictNameEn: "Samet",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "เวียง",
              subdistrictNameEn: "Wiang",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "ทุ่ง",
              subdistrictNameEn: "Thung",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "ป่าเว",
              subdistrictNameEn: "Pa We",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "ตะกรบ",
              subdistrictNameEn: "Takrop",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "โมถ่าย",
              subdistrictNameEn: "Mo Thai",
              postalCode: 84110,
            },
            {
              subdistrictNameTh: "ปากหมาก",
              subdistrictNameEn: "Pak Mak",
              postalCode: 84110,
            },
          ],
        },
        {
          districtNameTh: "ท่าชนะ",
          districtNameEn: "Tha Chana",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าชนะ",
              subdistrictNameEn: "Tha Chana",
              postalCode: 84170,
            },
            {
              subdistrictNameTh: "สมอทอง",
              subdistrictNameEn: "Samo Thong",
              postalCode: 84170,
            },
            {
              subdistrictNameTh: "ประสงค์",
              subdistrictNameEn: "Prasong",
              postalCode: 84170,
            },
            {
              subdistrictNameTh: "คันธุลี",
              subdistrictNameEn: "Khan Thuli",
              postalCode: 84170,
            },
            {
              subdistrictNameTh: "วัง",
              subdistrictNameEn: "Wang",
              postalCode: 84170,
            },
            {
              subdistrictNameTh: "คลองพา",
              subdistrictNameEn: "Khlong Pha",
              postalCode: 84170,
            },
          ],
        },
        {
          districtNameTh: "คีรีรัฐนิคม",
          districtNameEn: "Khiri Rat Nikhom",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าขนอน",
              subdistrictNameEn: "Tha Khanon",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "บ้านยาง",
              subdistrictNameEn: "Ban Yang",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "น้ำหัก",
              subdistrictNameEn: "Nam Hak",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "กะเปา",
              subdistrictNameEn: "Kapao",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "ท่ากระดาน",
              subdistrictNameEn: "Tha Kradan",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "ย่านยาว",
              subdistrictNameEn: "Yan Yao",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "ถ้ำสิงขร",
              subdistrictNameEn: "Tham Singkhon",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "บ้านทำเนียบ",
              subdistrictNameEn: "Ban Thamniap",
              postalCode: 84180,
            },
          ],
        },
        {
          districtNameTh: "บ้านตาขุน",
          districtNameEn: "Ban Ta Khun",
          subdistricts: [
            {
              subdistrictNameTh: "เขาวง",
              subdistrictNameEn: "Khao Wong",
              postalCode: 84230,
            },
            {
              subdistrictNameTh: "พะแสง",
              subdistrictNameEn: "Phasaeng",
              postalCode: 84230,
            },
            {
              subdistrictNameTh: "พรุไทย",
              subdistrictNameEn: "Phru Thai",
              postalCode: 84230,
            },
            {
              subdistrictNameTh: "เขาพัง",
              subdistrictNameEn: "Khao Phang",
              postalCode: 84230,
            },
          ],
        },
        {
          districtNameTh: "พนม",
          districtNameEn: "Phanom",
          subdistricts: [
            {
              subdistrictNameTh: "พนม",
              subdistrictNameEn: "Phanom",
              postalCode: 84250,
            },
            {
              subdistrictNameTh: "ต้นยวน",
              subdistrictNameEn: "Ton Yuan",
              postalCode: 84250,
            },
            {
              subdistrictNameTh: "คลองศก",
              subdistrictNameEn: "Khlong Sok",
              postalCode: 84250,
            },
            {
              subdistrictNameTh: "พลูเถื่อน",
              subdistrictNameEn: "Phlu Thuean",
              postalCode: 84250,
            },
            {
              subdistrictNameTh: "พังกาญจน์",
              subdistrictNameEn: "Phang Kan",
              postalCode: 84250,
            },
            {
              subdistrictNameTh: "คลองชะอุ่น",
              subdistrictNameEn: "Khlong Cha-Un",
              postalCode: 84250,
            },
          ],
        },
        {
          districtNameTh: "ท่าฉาง",
          districtNameEn: "Tha Chang",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าฉาง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 84150,
            },
            {
              subdistrictNameTh: "ท่าเคย",
              subdistrictNameEn: "Tha Khoei",
              postalCode: 84150,
            },
            {
              subdistrictNameTh: "คลองไทร",
              subdistrictNameEn: "Khlong Sai",
              postalCode: 84150,
            },
            {
              subdistrictNameTh: "เขาถ่าน",
              subdistrictNameEn: "Khao Than",
              postalCode: 84150,
            },
            {
              subdistrictNameTh: "เสวียด",
              subdistrictNameEn: "Sawiat",
              postalCode: 84150,
            },
            {
              subdistrictNameTh: "ปากฉลุย",
              subdistrictNameEn: "Pak Chalui",
              postalCode: 84150,
            },
          ],
        },
        {
          districtNameTh: "บ้านนาสาร",
          districtNameEn: "Ban Na San",
          subdistricts: [
            {
              subdistrictNameTh: "นาสาร",
              subdistrictNameEn: "Na San ",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "พรุพี",
              subdistrictNameEn: "Phru Phi",
              postalCode: 84270,
            },
            {
              subdistrictNameTh: "ทุ่งเตา",
              subdistrictNameEn: "Thung Tao",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "ลำพูน",
              subdistrictNameEn: "Lamphun",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "ท่าชี",
              subdistrictNameEn: "Tha Chi",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "ควนศรี",
              subdistrictNameEn: "Khuan Si",
              postalCode: 84270,
            },
            {
              subdistrictNameTh: "ควนสุบรรณ",
              subdistrictNameEn: "Khuan Suban",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "คลองปราบ",
              subdistrictNameEn: "Khlong Prap",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "น้ำพุ",
              subdistrictNameEn: "Namphu",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "ทุ่งเตาใหม่",
              subdistrictNameEn: "Thung Tao Mai",
              postalCode: 84120,
            },
            {
              subdistrictNameTh: "เพิ่มพูนทรัพย์",
              subdistrictNameEn: "Phoem Phun Sap",
              postalCode: 84120,
            },
          ],
        },
        {
          districtNameTh: "บ้านนาเดิม",
          districtNameEn: "Ban Na Doem",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 84240,
            },
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 84240,
            },
            {
              subdistrictNameTh: "ทรัพย์ทวี",
              subdistrictNameEn: "Sap Thawi",
              postalCode: 84240,
            },
            {
              subdistrictNameTh: "นาใต้",
              subdistrictNameEn: "Na Tai",
              postalCode: 84240,
            },
          ],
        },
        {
          districtNameTh: "เคียนซา",
          districtNameEn: "Khian Sa",
          subdistricts: [
            {
              subdistrictNameTh: "เคียนซา",
              subdistrictNameEn: "Khian Sa",
              postalCode: 84260,
            },
            {
              subdistrictNameTh: "พ่วงพรมคร",
              subdistrictNameEn: "Phuang Phromkhon",
              postalCode: 84210,
            },
            {
              subdistrictNameTh: "เขาตอก",
              subdistrictNameEn: "Khao Tok",
              postalCode: 84260,
            },
            {
              subdistrictNameTh: "อรัญคามวารี",
              subdistrictNameEn: "Aran Kham Wari",
              postalCode: 84260,
            },
            {
              subdistrictNameTh: "บ้านเสด็จ",
              subdistrictNameEn: "Ban Sadet",
              postalCode: 84260,
            },
          ],
        },
        {
          districtNameTh: "เวียงสระ",
          districtNameEn: "Wiang Sa",
          subdistricts: [
            {
              subdistrictNameTh: "เวียงสระ",
              subdistrictNameEn: "Wiang Sa",
              postalCode: 84190,
            },
            {
              subdistrictNameTh: "บ้านส้อง",
              subdistrictNameEn: "Ban Song",
              postalCode: 84190,
            },
            {
              subdistrictNameTh: "คลองฉนวน",
              subdistrictNameEn: "Khlong Chanuan",
              postalCode: 84190,
            },
            {
              subdistrictNameTh: "ทุ่งหลวง",
              subdistrictNameEn: "Thung Luang",
              postalCode: 84190,
            },
            {
              subdistrictNameTh: "เขานิพันธ์",
              subdistrictNameEn: "Khao Niphan",
              postalCode: 84190,
            },
          ],
        },
        {
          districtNameTh: "พระแสง",
          districtNameEn: "Phrasaeng",
          subdistricts: [
            {
              subdistrictNameTh: "อิปัน",
              subdistrictNameEn: "I Pan",
              postalCode: 84210,
            },
            {
              subdistrictNameTh: "สินปุน",
              subdistrictNameEn: "Sin Pun",
              postalCode: 84210,
            },
            {
              subdistrictNameTh: "บางสวรรค์",
              subdistrictNameEn: "Bang Sawan",
              postalCode: 84210,
            },
            {
              subdistrictNameTh: "ไทรขึง",
              subdistrictNameEn: "Sai Khueng",
              postalCode: 84210,
            },
            {
              subdistrictNameTh: "สินเจริญ",
              subdistrictNameEn: "Sin Charoen",
              postalCode: 84210,
            },
            {
              subdistrictNameTh: "ไทรโสภา",
              subdistrictNameEn: "Sai Sopha",
              postalCode: 84210,
            },
            {
              subdistrictNameTh: "สาคู",
              subdistrictNameEn: "Sakhu",
              postalCode: 84210,
            },
          ],
        },
        {
          districtNameTh: "พุนพิน",
          districtNameEn: "Phunphin",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "ท่าสะท้อน",
              subdistrictNameEn: "Tha Sathon",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "ลีเล็ด",
              subdistrictNameEn: "Li Let",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "บางมะเดื่อ",
              subdistrictNameEn: "Bang Maduea",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "บางเดือน",
              subdistrictNameEn: "Bang Duean",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "ท่าโรงช้าง",
              subdistrictNameEn: "Tha Rong Chang",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "กรูด",
              subdistrictNameEn: "Krut",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "พุนพิน",
              subdistrictNameEn: "Phunphin",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "บางงอน",
              subdistrictNameEn: "Bang Ngon",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "ศรีวิชัย",
              subdistrictNameEn: "Si Wichai",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "น้ำรอบ",
              subdistrictNameEn: "Nam Rop",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "มะลวน",
              subdistrictNameEn: "Maluan",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "หัวเตย",
              subdistrictNameEn: "Hua Toei",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "หนองไทร",
              subdistrictNameEn: "Nong Sai",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "เขาหัวควาย",
              subdistrictNameEn: "Khao Hua Khwai",
              postalCode: 84130,
            },
            {
              subdistrictNameTh: "ตะปาน",
              subdistrictNameEn: "Tapan",
              postalCode: 84130,
            },
          ],
        },
        {
          districtNameTh: "ชัยบุรี",
          districtNameEn: "Chai Buri",
          subdistricts: [
            {
              subdistrictNameTh: "สองแพรก",
              subdistrictNameEn: "Song Phraek",
              postalCode: 84350,
            },
            {
              subdistrictNameTh: "ชัยบุรี",
              subdistrictNameEn: "Chai Buri",
              postalCode: 84350,
            },
            {
              subdistrictNameTh: "คลองน้อย",
              subdistrictNameEn: "Khlong Noi",
              postalCode: 84350,
            },
            {
              subdistrictNameTh: "ไทรทอง",
              subdistrictNameEn: "Sai Thong",
              postalCode: 84350,
            },
          ],
        },
        {
          districtNameTh: "วิภาวดี",
          districtNameEn: "Chai Buri",
          subdistricts: [
            {
              subdistrictNameTh: "ตะกุกใต้",
              subdistrictNameEn: "Takuk Tai",
              postalCode: 84180,
            },
            {
              subdistrictNameTh: "ตะกุกเหนือ",
              subdistrictNameEn: "Takuk Nuea",
              postalCode: 84180,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ระนอง",
      stateNameEn: "Ranong",
      districts: [
        {
          districtNameTh: "เมืองระนอง",
          districtNameEn: "Mueang Ranong",
          subdistricts: [
            {
              subdistrictNameTh: "เขานิเวศน์",
              subdistrictNameEn: "Khao Niwet",
              postalCode: 85000,
            },
            {
              subdistrictNameTh: "ราชกรูด",
              subdistrictNameEn: "Ratchakrut",
              postalCode: 85000,
            },
            {
              subdistrictNameTh: "หงาว",
              subdistrictNameEn: "Ngao",
              postalCode: 85000,
            },
            {
              subdistrictNameTh: "บางริ้น",
              subdistrictNameEn: "Bang Rin",
              postalCode: 85000,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 85000,
            },
            {
              subdistrictNameTh: "บางนอน",
              subdistrictNameEn: "Bang Non",
              postalCode: 85000,
            },
            {
              subdistrictNameTh: "หาดส้มแป้น",
              subdistrictNameEn: "Hat Som Paen",
              postalCode: 85000,
            },
            {
              subdistrictNameTh: "ทรายแดง",
              subdistrictNameEn: "Sai Daeng",
              postalCode: 85130,
            },
            {
              subdistrictNameTh: "เกาะพยาม",
              subdistrictNameEn: "Ko Phayam",
              postalCode: 85000,
            },
          ],
        },
        {
          districtNameTh: "ละอุ่น",
          districtNameEn: "La-Un",
          subdistricts: [
            {
              subdistrictNameTh: "ละอุ่นใต้",
              subdistrictNameEn: "La-Un Tai",
              postalCode: 85130,
            },
            {
              subdistrictNameTh: "ละอุ่นเหนือ",
              subdistrictNameEn: "La-Un Nuea",
              postalCode: 85130,
            },
            {
              subdistrictNameTh: "บางพระใต้",
              subdistrictNameEn: "Bang Phra Tai",
              postalCode: 85130,
            },
            {
              subdistrictNameTh: "บางพระเหนือ",
              subdistrictNameEn: "Bang Phra Nuea",
              postalCode: 85130,
            },
            {
              subdistrictNameTh: "บางแก้ว",
              subdistrictNameEn: "Bang Kaeo",
              postalCode: 85130,
            },
            {
              subdistrictNameTh: "ในวงเหนือ",
              subdistrictNameEn: "Nai Wong Nuea",
              postalCode: 85130,
            },
            {
              subdistrictNameTh: "ในวงใต้",
              subdistrictNameEn: "Nai Wong Tai",
              postalCode: 85130,
            },
          ],
        },
        {
          districtNameTh: "กะเปอร์",
          districtNameEn: "Papoe",
          subdistricts: [
            {
              subdistrictNameTh: "ม่วงกลวง",
              subdistrictNameEn: "Muang Kluang",
              postalCode: 85120,
            },
            {
              subdistrictNameTh: "กะเปอร์",
              subdistrictNameEn: "Kapoe",
              postalCode: 85120,
            },
            {
              subdistrictNameTh: "เชี่ยวเหลียง",
              subdistrictNameEn: "Chiao Liang",
              postalCode: 85120,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 85120,
            },
            {
              subdistrictNameTh: "บางหิน",
              subdistrictNameEn: "Bang Hin",
              postalCode: 85120,
            },
          ],
        },
        {
          districtNameTh: "กระบุรี",
          districtNameEn: "Kra Buri",
          subdistricts: [
            {
              subdistrictNameTh: "น้ำจืด",
              subdistrictNameEn: "Nam Chuet",
              postalCode: 85110,
            },
            {
              subdistrictNameTh: "น้ำจืดน้อย",
              subdistrictNameEn: "Nam Chuet Noi",
              postalCode: 85110,
            },
            {
              subdistrictNameTh: "มะมุ",
              subdistrictNameEn: "Mamu",
              postalCode: 85110,
            },
            {
              subdistrictNameTh: "ปากจั่น",
              subdistrictNameEn: "Pak Chan",
              postalCode: 85110,
            },
            {
              subdistrictNameTh: "ลำเลียง",
              subdistrictNameEn: "Lamliang",
              postalCode: 85110,
            },
            {
              subdistrictNameTh: "จ.ป.ร.",
              subdistrictNameEn: "Cho Po Ro",
              postalCode: 85110,
            },
            {
              subdistrictNameTh: "บางใหญ่",
              subdistrictNameEn: "Bang Yai",
              postalCode: 85110,
            },
          ],
        },
        {
          districtNameTh: "สุขสำราญ",
          districtNameEn: "Suk Samran",
          subdistricts: [
            {
              subdistrictNameTh: "นาคา",
              subdistrictNameEn: "Nakha",
              postalCode: 85120,
            },
            {
              subdistrictNameTh: "กำพวน",
              subdistrictNameEn: "Kamphuan",
              postalCode: 85120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ชุมพร",
      stateNameEn: "Chumphon",
      districts: [
        {
          districtNameTh: "เมืองชุมพร",
          districtNameEn: "Mueang Chumphon",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าตะเภา",
              subdistrictNameEn: "Tha Taphao",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 86120,
            },
            {
              subdistrictNameTh: "ท่ายาง",
              subdistrictNameEn: "Tha Yang",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "บางหมาก",
              subdistrictNameEn: "Bang Mak",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "นาทุ่ง",
              subdistrictNameEn: "Na Thung",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "นาชะอัง",
              subdistrictNameEn: "Na Cha-Ang",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "ตากแดด",
              subdistrictNameEn: "Tak Daet",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "บางลึก",
              subdistrictNameEn: "Bang Luek",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "หาดพันไกร",
              subdistrictNameEn: "Hat Phan Krai",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "วังไผ่",
              subdistrictNameEn: "Wang Phai",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "วังใหม่",
              subdistrictNameEn: "Wang Mai",
              postalCode: 86190,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 86190,
            },
            {
              subdistrictNameTh: "ขุนกระทิง",
              subdistrictNameEn: "Khun Krathing",
              postalCode: 86000,
            },
            {
              subdistrictNameTh: "ทุ่งคา",
              subdistrictNameEn: "Thung Kha",
              postalCode: 86100,
            },
            {
              subdistrictNameTh: "วิสัยเหนือ",
              subdistrictNameEn: "Wisai Nuea",
              postalCode: 86100,
            },
            {
              subdistrictNameTh: "หาดทรายรี",
              subdistrictNameEn: "Hat Sai Ri",
              postalCode: 86120,
            },
            {
              subdistrictNameTh: "ถ้ำสิงห์",
              subdistrictNameEn: "Tham Sing",
              postalCode: 86100,
            },
          ],
        },
        {
          districtNameTh: "ท่าแซะ",
          districtNameEn: "Tha Sae",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าแซะ",
              subdistrictNameEn: "Tha Sae",
              postalCode: 86140,
            },
            {
              subdistrictNameTh: "คุริง",
              subdistrictNameEn: "Khu Ring",
              postalCode: 86140,
            },
            {
              subdistrictNameTh: "สลุย",
              subdistrictNameEn: "Salui",
              postalCode: 86140,
            },
            {
              subdistrictNameTh: "นากระตาม",
              subdistrictNameEn: "Na Kratam",
              postalCode: 86140,
            },
            {
              subdistrictNameTh: "รับร่อ",
              subdistrictNameEn: "Rap Ro",
              postalCode: 86190,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 86140,
            },
            {
              subdistrictNameTh: "หงษ์เจริญ",
              subdistrictNameEn: "Hong Charoen",
              postalCode: 86140,
            },
            {
              subdistrictNameTh: "หินแก้ว",
              subdistrictNameEn: "Hin Kaeo",
              postalCode: 86190,
            },
            {
              subdistrictNameTh: "ทรัพย์อนันต์",
              subdistrictNameEn: "Sap Anan",
              postalCode: 86140,
            },
            {
              subdistrictNameTh: "สองพี่น้อง",
              subdistrictNameEn: "Song Phi Nong",
              postalCode: 86140,
            },
          ],
        },
        {
          districtNameTh: "ปะทิว",
          districtNameEn: "Pathio",
          subdistricts: [
            {
              subdistrictNameTh: "บางสน",
              subdistrictNameEn: "Bang Son",
              postalCode: 86160,
            },
            {
              subdistrictNameTh: "ทะเลทรัพย์",
              subdistrictNameEn: "Thale Sap",
              postalCode: 86160,
            },
            {
              subdistrictNameTh: "สะพลี",
              subdistrictNameEn: "Saphli",
              postalCode: 86230,
            },
            {
              subdistrictNameTh: "ชุมโค",
              subdistrictNameEn: "Chum Kho",
              postalCode: 86160,
            },
            {
              subdistrictNameTh: "ดอนยาง",
              subdistrictNameEn: "Don Yang",
              postalCode: 86210,
            },
            {
              subdistrictNameTh: "ปากคลอง",
              subdistrictNameEn: "Pak Khlong",
              postalCode: 86210,
            },
            {
              subdistrictNameTh: "เขาไชยราช",
              subdistrictNameEn: "Khao Chaiyarat",
              postalCode: 86210,
            },
          ],
        },
        {
          districtNameTh: "หลังสวน",
          districtNameEn: "Lang Suan",
          subdistricts: [
            {
              subdistrictNameTh: "หลังสวน",
              subdistrictNameEn: "Lang Suan",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "ขันเงิน",
              subdistrictNameEn: "Khan Ngoen",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "ท่ามะพลา",
              subdistrictNameEn: "Tha Maphla",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "นาขา",
              subdistrictNameEn: "Nakha",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "นาพญา",
              subdistrictNameEn: "Na Phaya",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "บ้านควน",
              subdistrictNameEn: "Ban Khuan",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "บางมะพร้าว",
              subdistrictNameEn: "Bang Maphrao",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "บางน้ำจืด",
              subdistrictNameEn: "Bang Nam Chuet",
              postalCode: 86150,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 86150,
            },
            {
              subdistrictNameTh: "พ้อแดง",
              subdistrictNameEn: "Pho Daeng",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "แหลมทราย",
              subdistrictNameEn: "Laem Sai",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "วังตะกอ",
              subdistrictNameEn: "Wang Tako",
              postalCode: 86110,
            },
            {
              subdistrictNameTh: "หาดยาย",
              subdistrictNameEn: "Hat Yai",
              postalCode: 86110,
            },
          ],
        },
        {
          districtNameTh: "ละแม",
          districtNameEn: "Lamae",
          subdistricts: [
            {
              subdistrictNameTh: "ละแม",
              subdistrictNameEn: "Lamae",
              postalCode: 86170,
            },
            {
              subdistrictNameTh: "ทุ่งหลวง",
              subdistrictNameEn: "Thung Luang",
              postalCode: 86170,
            },
            {
              subdistrictNameTh: "สวนแตง",
              subdistrictNameEn: "Suan Taeng",
              postalCode: 86170,
            },
            {
              subdistrictNameTh: "ทุ่งคาวัด",
              subdistrictNameEn: "Thung Kha Wat",
              postalCode: 86170,
            },
          ],
        },
        {
          districtNameTh: "พะโต๊ะ",
          districtNameEn: "Phato",
          subdistricts: [
            {
              subdistrictNameTh: "พะโต๊ะ",
              subdistrictNameEn: "Phato",
              postalCode: 86180,
            },
            {
              subdistrictNameTh: "ปากทรง",
              subdistrictNameEn: "Pak Song",
              postalCode: 86180,
            },
            {
              subdistrictNameTh: "ปังหวาน",
              subdistrictNameEn: "Pang Wan",
              postalCode: 86180,
            },
            {
              subdistrictNameTh: "พระรักษ์",
              subdistrictNameEn: "Phrarak",
              postalCode: 86180,
            },
          ],
        },
        {
          districtNameTh: "สวี",
          districtNameEn: "Sawi",
          subdistricts: [
            {
              subdistrictNameTh: "นาโพธิ์",
              subdistrictNameEn: "Na Pho",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "สวี",
              subdistrictNameEn: "Sawi",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "ทุ่งระยะ",
              subdistrictNameEn: "Thung Raya",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "ท่าหิน",
              subdistrictNameEn: "Tha Hin",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "ปากแพรก",
              subdistrictNameEn: "Pak Phraek",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "ด่านสวี",
              subdistrictNameEn: "Dan Sawi",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "ครน",
              subdistrictNameEn: "Khron",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "วิสัยใต้",
              subdistrictNameEn: "Wisai Tai",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "นาสัก",
              subdistrictNameEn: "Na Sak",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "เขาทะลุ",
              subdistrictNameEn: "Khao Thalu",
              postalCode: 86130,
            },
            {
              subdistrictNameTh: "เขาค่าย",
              subdistrictNameEn: "Khao Khai",
              postalCode: 86130,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งตะโก",
          districtNameEn: "Thung Tako",
          subdistricts: [
            {
              subdistrictNameTh: "ปากตะโก",
              subdistrictNameEn: "Pak Tako",
              postalCode: 86220,
            },
            {
              subdistrictNameTh: "ทุ่งตะไคร",
              subdistrictNameEn: "Thung Takhrai",
              postalCode: 86220,
            },
            {
              subdistrictNameTh: "ตะโก",
              subdistrictNameEn: "Tako",
              postalCode: 86220,
            },
            {
              subdistrictNameTh: "ช่องไม้แก้ว",
              subdistrictNameEn: "Chong Mai Kaeo",
              postalCode: 86220,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สงขลา",
      stateNameEn: "Songkhla",
      districts: [
        {
          districtNameTh: "เมืองสงขลา",
          districtNameEn: "Muaeng Songkhla",
          subdistricts: [
            {
              subdistrictNameTh: "บ่อยาง",
              subdistrictNameEn: "Bo Yang",
              postalCode: 90000,
            },
            {
              subdistrictNameTh: "เขารูปช้าง",
              subdistrictNameEn: "Khao Rupchang",
              postalCode: 90000,
            },
            {
              subdistrictNameTh: "เกาะแต้ว",
              subdistrictNameEn: "Ko Taeo",
              postalCode: 90000,
            },
            {
              subdistrictNameTh: "พะวง",
              subdistrictNameEn: "Pha Wong",
              postalCode: 90100,
            },
            {
              subdistrictNameTh: "ทุ่งหวัง",
              subdistrictNameEn: "Thung Wang",
              postalCode: 90000,
            },
            {
              subdistrictNameTh: "เกาะยอ",
              subdistrictNameEn: "Ko Yo",
              postalCode: 90100,
            },
          ],
        },
        {
          districtNameTh: "สทิงพระ",
          districtNameEn: "Sathing Phra",
          subdistricts: [
            {
              subdistrictNameTh: "จะทิ้งพระ",
              subdistrictNameEn: "Cha Thing Phra",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "กระดังงา",
              subdistrictNameEn: "Kradang Nga",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "สนามชัย",
              subdistrictNameEn: "Sanam Chai",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "ดีหลวง",
              subdistrictNameEn: "Di Luang",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "ชุมพล",
              subdistrictNameEn: "Chum Phon",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "คลองรี",
              subdistrictNameEn: "Khlong Re",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "คูขุด",
              subdistrictNameEn: "Khu Khut",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "ท่าหิน",
              subdistrictNameEn: "Tha Hin",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "วัดจันทร์",
              subdistrictNameEn: "Wat Chan",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "บ่อแดง",
              subdistrictNameEn: "Bodaeng",
              postalCode: 90190,
            },
            {
              subdistrictNameTh: "บ่อดาน",
              subdistrictNameEn: "Bodan",
              postalCode: 90190,
            },
          ],
        },
        {
          districtNameTh: "จะนะ",
          districtNameEn: "Chana",
          subdistricts: [
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "ป่าชิง",
              subdistrictNameEn: "Pa Ching",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "สะพานไม้แก่น",
              subdistrictNameEn: "Saphan Mai Kaen",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "สะกอม",
              subdistrictNameEn: "Sa Kom",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "นาหว้า",
              subdistrictNameEn: "Na Wa",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "นาทับ",
              subdistrictNameEn: "Na Thap",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "น้ำขาว",
              subdistrictNameEn: "Nam Khao",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "ขุนตัดหวาย",
              subdistrictNameEn: "Khun Tat Wai",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "ท่าหมอไทร",
              subdistrictNameEn: "Tha Mo Sai",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "จะโหนง",
              subdistrictNameEn: "Cha Nong",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "คู",
              subdistrictNameEn: "Khu",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "แค",
              subdistrictNameEn: "Khae",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "คลองเปียะ",
              subdistrictNameEn: "Khlong Pia",
              postalCode: 90130,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 90130,
            },
          ],
        },
        {
          districtNameTh: "นาทวี",
          districtNameEn: "Na Thawi",
          subdistricts: [
            {
              subdistrictNameTh: "นาทวี",
              subdistrictNameEn: "Na Thawi",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "ฉาง",
              subdistrictNameEn: "Chang",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "นาหมอศรี",
              subdistrictNameEn: "Na Mosri",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "คลองทราย",
              subdistrictNameEn: "Khlong Sai",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "ปลักหนู",
              subdistrictNameEn: "Plak Nu",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "ท่าประดู่",
              subdistrictNameEn: "Tha Pradu",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "สะท้อน",
              subdistrictNameEn: "Sa Thon",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "ทับช้าง",
              subdistrictNameEn: "Thap Chang",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "ประกอบ",
              subdistrictNameEn: "Prakop",
              postalCode: 90160,
            },
            {
              subdistrictNameTh: "คลองกวาง",
              subdistrictNameEn: "Khlong Kwang",
              postalCode: 90160,
            },
          ],
        },
        {
          districtNameTh: "เทพา",
          districtNameEn: "Thepha",
          subdistricts: [
            {
              subdistrictNameTh: "เทพา",
              subdistrictNameEn: "Thepha",
              postalCode: 90150,
            },
            {
              subdistrictNameTh: "ปากบาง",
              subdistrictNameEn: "Pak Bang",
              postalCode: 90150,
            },
            {
              subdistrictNameTh: "เกาะสะบ้า",
              subdistrictNameEn: "Ko Saba",
              postalCode: 90150,
            },
            {
              subdistrictNameTh: "ลำไพล",
              subdistrictNameEn: "Lam Phli",
              postalCode: 90260,
            },
            {
              subdistrictNameTh: "ท่าม่วง",
              subdistrictNameEn: "Tha Muang",
              postalCode: 90260,
            },
            {
              subdistrictNameTh: "วังใหญ่",
              subdistrictNameEn: "Wang Yai",
              postalCode: 90260,
            },
            {
              subdistrictNameTh: "สะกอม",
              subdistrictNameEn: "Sa Kom",
              postalCode: 90150,
            },
          ],
        },
        {
          districtNameTh: "สะบ้าย้อย",
          districtNameEn: "Saba Yoi",
          subdistricts: [
            {
              subdistrictNameTh: "สะบ้าย้อย",
              subdistrictNameEn: "Sa Ba Yoi",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "ทุ่งพอ",
              subdistrictNameEn: "Thung Pho",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "เปียน",
              subdistrictNameEn: "Pian",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "บ้านโหนด",
              subdistrictNameEn: "Ban Not",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "จะแหน",
              subdistrictNameEn: "Cha Nae",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "คูหา",
              subdistrictNameEn: "Khu Ha",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "เขาแดง",
              subdistrictNameEn: "Khao Daeng",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "บาโหย",
              subdistrictNameEn: "Ba Hoi",
              postalCode: 90210,
            },
            {
              subdistrictNameTh: "ธารคีรี",
              subdistrictNameEn: "Than Khiri",
              postalCode: 90210,
            },
          ],
        },
        {
          districtNameTh: "ระโนด",
          districtNameEn: "Ranot",
          subdistricts: [
            {
              subdistrictNameTh: "ระโนด",
              subdistrictNameEn: "Ra Not",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "คลองแดน",
              subdistrictNameEn: "Khlong Daen",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "ตะเครียะ",
              subdistrictNameEn: "Takhria",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "ท่าบอน",
              subdistrictNameEn: "Tha Bon",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "บ้านใหม่",
              subdistrictNameEn: "Ban Mai",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "บ่อตรุ",
              subdistrictNameEn: "Botru",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "ปากแตระ",
              subdistrictNameEn: "Pak Trae",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "พังยาง",
              subdistrictNameEn: "Phang Yang",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "ระวะ",
              subdistrictNameEn: "Ra Wa",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "วัดสน",
              subdistrictNameEn: "Wat Son",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "บ้านขาว",
              subdistrictNameEn: "Ban Khao",
              postalCode: 90140,
            },
            {
              subdistrictNameTh: "แดนสงวน",
              subdistrictNameEn: "Daen Sa Nguan",
              postalCode: 90140,
            },
          ],
        },
        {
          districtNameTh: "กระแสสินธุ์",
          districtNameEn: "Krasae Sin",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะใหญ่",
              subdistrictNameEn: "Koyai",
              postalCode: 90270,
            },
            {
              subdistrictNameTh: "โรง",
              subdistrictNameEn: "Rong",
              postalCode: 90270,
            },
            {
              subdistrictNameTh: "เชิงแส",
              subdistrictNameEn: "Choeng Sae",
              postalCode: 90270,
            },
            {
              subdistrictNameTh: "กระแสสินธุ์",
              subdistrictNameEn: "Krasae Sin",
              postalCode: 90270,
            },
          ],
        },
        {
          districtNameTh: "รัตภูมิ",
          districtNameEn: "Rattaphum",
          subdistricts: [
            {
              subdistrictNameTh: "กำแพงเพชร",
              subdistrictNameEn: "Kamphaeng Phet",
              postalCode: 90180,
            },
            {
              subdistrictNameTh: "ท่าชะมวง",
              subdistrictNameEn: "Tha Cha Muang",
              postalCode: 90180,
            },
            {
              subdistrictNameTh: "คูหาใต้",
              subdistrictNameEn: "Khu Ha Tai",
              postalCode: 90180,
            },
            {
              subdistrictNameTh: "ควนรู",
              subdistrictNameEn: "Khuan Ru",
              postalCode: 90180,
            },
            {
              subdistrictNameTh: "เขาพระ",
              subdistrictNameEn: "Khao Phra",
              postalCode: 90180,
            },
          ],
        },
        {
          districtNameTh: "สะเดา",
          districtNameEn: "Sadao",
          subdistricts: [
            {
              subdistrictNameTh: "สะเดา",
              subdistrictNameEn: "Sadao",
              postalCode: 90120,
            },
            {
              subdistrictNameTh: "ปริก",
              subdistrictNameEn: "Prik",
              postalCode: 90120,
            },
            {
              subdistrictNameTh: "พังลา",
              subdistrictNameEn: "Phang La",
              postalCode: 90170,
            },
            {
              subdistrictNameTh: "สำนักแต้ว",
              subdistrictNameEn: "Samnak Taeo",
              postalCode: 90120,
            },
            {
              subdistrictNameTh: "ทุ่งหมอ",
              subdistrictNameEn: "Thung Mo",
              postalCode: 90240,
            },
            {
              subdistrictNameTh: "ท่าโพธิ์",
              subdistrictNameEn: "Tha Pho",
              postalCode: 90170,
            },
            {
              subdistrictNameTh: "ปาดังเบซาร์",
              subdistrictNameEn: "Pa Dangbesa",
              postalCode: 90240,
            },
            {
              subdistrictNameTh: "สำนักขาม",
              subdistrictNameEn: "Samnak Kham",
              postalCode: 90320,
            },
            {
              subdistrictNameTh: "เขามีเกียรติ",
              subdistrictNameEn: "Khao Mikiat",
              postalCode: 90170,
            },
          ],
        },
        {
          districtNameTh: "หาดใหญ่",
          districtNameEn: "Hat Yai",
          subdistricts: [
            {
              subdistrictNameTh: "หาดใหญ่",
              subdistrictNameEn: "Hat Yai",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "ควนลัง",
              subdistrictNameEn: "Khuan Lang",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "คูเต่า",
              subdistrictNameEn: "Khu Tao",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "คอหงส์",
              subdistrictNameEn: "Kho Hong",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "คลองแห",
              subdistrictNameEn: "Khlong Hae",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "คลองอู่ตะเภา",
              subdistrictNameEn: "Khlong U Ta Phao",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "ฉลุง",
              subdistrictNameEn: "Chalung",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "ทุ่งใหญ่",
              subdistrictNameEn: "Thung Yai",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "ทุ่งตำเสา",
              subdistrictNameEn: "Thung Tam Sao",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "น้ำน้อย",
              subdistrictNameEn: "Nam Noi",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "บ้านพรุ",
              subdistrictNameEn: "Ban Phru",
              postalCode: 90250,
            },
            {
              subdistrictNameTh: "พะตง",
              subdistrictNameEn: "Pha Tong",
              postalCode: 90230,
            },
          ],
        },
        {
          districtNameTh: "นาหม่อม",
          districtNameEn: "Na Mom",
          subdistricts: [
            {
              subdistrictNameTh: "นาหม่อม",
              subdistrictNameEn: "Na Mom",
              postalCode: 90310,
            },
            {
              subdistrictNameTh: "พิจิตร",
              subdistrictNameEn: "Phichit",
              postalCode: 90310,
            },
            {
              subdistrictNameTh: "ทุ่งขมิ้น",
              subdistrictNameEn: "Thung Khamin",
              postalCode: 90310,
            },
            {
              subdistrictNameTh: "คลองหรัง",
              subdistrictNameEn: "Khlong Rang",
              postalCode: 90310,
            },
          ],
        },
        {
          districtNameTh: "ควนเนียง",
          districtNameEn: "Khuan Niang",
          subdistricts: [
            {
              subdistrictNameTh: "รัตภูมิ",
              subdistrictNameEn: "Ratta Phum",
              postalCode: 90220,
            },
            {
              subdistrictNameTh: "ควนโส",
              subdistrictNameEn: "Khuan So",
              postalCode: 90220,
            },
            {
              subdistrictNameTh: "ห้วยลึก",
              subdistrictNameEn: "Huai Luek",
              postalCode: 90220,
            },
            {
              subdistrictNameTh: "บางเหรียง",
              subdistrictNameEn: "Bang Riang",
              postalCode: 90220,
            },
          ],
        },
        {
          districtNameTh: "บางกล่ำ",
          districtNameEn: "Bang Klam",
          subdistricts: [
            {
              subdistrictNameTh: "บางกล่ำ",
              subdistrictNameEn: "Bang Klam",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "ท่าช้าง",
              subdistrictNameEn: "Tha Chang",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "แม่ทอม",
              subdistrictNameEn: "Mae Thom",
              postalCode: 90110,
            },
            {
              subdistrictNameTh: "บ้านหาร",
              subdistrictNameEn: "Ban Han",
              postalCode: 90110,
            },
          ],
        },
        {
          districtNameTh: "สิงหนคร",
          districtNameEn: "Singhanakhon",
          subdistricts: [
            {
              subdistrictNameTh: "ชิงโค",
              subdistrictNameEn: "Ching Kho",
              postalCode: 90280,
            },
            {
              subdistrictNameTh: "สทิงหม้อ",
              subdistrictNameEn: "Sathing Mo",
              postalCode: 90280,
            },
            {
              subdistrictNameTh: "ทำนบ",
              subdistrictNameEn: "Tham Nop",
              postalCode: 90280,
            },
            {
              subdistrictNameTh: "รำแดง",
              subdistrictNameEn: "Ram Daeng",
              postalCode: 90330,
            },
            {
              subdistrictNameTh: "วัดขนุน",
              subdistrictNameEn: "Wat Khanun",
              postalCode: 90330,
            },
            {
              subdistrictNameTh: "ชะแล้",
              subdistrictNameEn: "Chalae",
              postalCode: 90330,
            },
            {
              subdistrictNameTh: "ปากรอ",
              subdistrictNameEn: "Pak Ro",
              postalCode: 90330,
            },
            {
              subdistrictNameTh: "ป่าขาด",
              subdistrictNameEn: "Pa Khat",
              postalCode: 90330,
            },
            {
              subdistrictNameTh: "หัวเขา",
              subdistrictNameEn: "Hua Khao",
              postalCode: 90280,
            },
            {
              subdistrictNameTh: "บางเขียด",
              subdistrictNameEn: "Bang Khiat",
              postalCode: 90330,
            },
            {
              subdistrictNameTh: "ม่วงงาม",
              subdistrictNameEn: "Muang Ngam",
              postalCode: 90330,
            },
          ],
        },
        {
          districtNameTh: "คลองหอยโข่ง",
          districtNameEn: "Khlong Hoi Khong",
          subdistricts: [
            {
              subdistrictNameTh: "คลองหอยโข่ง",
              subdistrictNameEn: "Khlong Hoi Khong",
              postalCode: 90230,
            },
            {
              subdistrictNameTh: "ทุ่งลาน",
              subdistrictNameEn: "Thung Lan",
              postalCode: 90230,
            },
            {
              subdistrictNameTh: "โคกม่วง",
              subdistrictNameEn: "Khok Muang",
              postalCode: 90230,
            },
            {
              subdistrictNameTh: "คลองหลา",
              subdistrictNameEn: "Khlong La",
              postalCode: 90115,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "สตูล",
      stateNameEn: "Satun",
      districts: [
        {
          districtNameTh: "เมืองสตูล",
          districtNameEn: "Mueang Satun",
          subdistricts: [
            {
              subdistrictNameTh: "พิมาน",
              subdistrictNameEn: "Phiman",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "คลองขุด",
              subdistrictNameEn: "Khlong Khut",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "ควนขัน",
              subdistrictNameEn: "Khuan Khan",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "บ้านควน",
              subdistrictNameEn: "Ban Khuan",
              postalCode: 91140,
            },
            {
              subdistrictNameTh: "ฉลุง",
              subdistrictNameEn: "Chalung",
              postalCode: 91140,
            },
            {
              subdistrictNameTh: "เกาะสาหร่าย",
              subdistrictNameEn: "Ko Sarai",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "ตันหยงโป",
              subdistrictNameEn: "Tanyong Po",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "เจ๊ะบิลัง",
              subdistrictNameEn: "Che Bi Lang",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "ตำมะลัง",
              subdistrictNameEn: "Tam Malang",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "ปูยู",
              subdistrictNameEn: "Puyu",
              postalCode: 91000,
            },
            {
              subdistrictNameTh: "ควนโพธิ์",
              subdistrictNameEn: "Khuan Pho",
              postalCode: 91140,
            },
            {
              subdistrictNameTh: "เกตรี",
              subdistrictNameEn: "Ketari",
              postalCode: 91140,
            },
          ],
        },
        {
          districtNameTh: "ควนโดน",
          districtNameEn: "Khuan Don",
          subdistricts: [
            {
              subdistrictNameTh: "ควนโดน",
              subdistrictNameEn: "Khuan Don",
              postalCode: 91160,
            },
            {
              subdistrictNameTh: "ควนสตอ",
              subdistrictNameEn: "Khuan Sato",
              postalCode: 91160,
            },
            {
              subdistrictNameTh: "ย่านซื่อ",
              subdistrictNameEn: "Yan Sue",
              postalCode: 91160,
            },
            {
              subdistrictNameTh: "วังประจัน",
              subdistrictNameEn: "Wang Prachan",
              postalCode: 91160,
            },
          ],
        },
        {
          districtNameTh: "ควนกาหลง",
          districtNameEn: "Khuan Kalong",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งนุ้ย",
              subdistrictNameEn: "Thung Nui",
              postalCode: 91130,
            },
            {
              subdistrictNameTh: "ควนกาหลง",
              subdistrictNameEn: "Khuan Kalong",
              postalCode: 91130,
            },
            {
              subdistrictNameTh: "อุใดเจริญ",
              subdistrictNameEn: "Udai Charoen",
              postalCode: 91130,
            },
          ],
        },
        {
          districtNameTh: "ท่าแพ",
          districtNameEn: "Tha Phae",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าแพ",
              subdistrictNameEn: "Tha Phae",
              postalCode: 91150,
            },
            {
              subdistrictNameTh: "แป-ระ",
              subdistrictNameEn: "Pae-Ra",
              postalCode: 91150,
            },
            {
              subdistrictNameTh: "สาคร",
              subdistrictNameEn: "Sakhon",
              postalCode: 91150,
            },
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 91150,
            },
          ],
        },
        {
          districtNameTh: "ละงู",
          districtNameEn: "La-Ngu",
          subdistricts: [
            {
              subdistrictNameTh: "กำแพง",
              subdistrictNameEn: "Kamphaeng",
              postalCode: 91110,
            },
            {
              subdistrictNameTh: "ละงู",
              subdistrictNameEn: "La-Ngu",
              postalCode: 91110,
            },
            {
              subdistrictNameTh: "เขาขาว",
              subdistrictNameEn: "Khao Khao",
              postalCode: 91110,
            },
            {
              subdistrictNameTh: "ปากน้ำ",
              subdistrictNameEn: "Pak Nam",
              postalCode: 91110,
            },
            {
              subdistrictNameTh: "น้ำผุด",
              subdistrictNameEn: "Nam Phut",
              postalCode: 91110,
            },
            {
              subdistrictNameTh: "แหลมสน",
              subdistrictNameEn: "Laem Son",
              postalCode: 91110,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งหว้า",
          districtNameEn: "Thung Wa",
          subdistricts: [
            {
              subdistrictNameTh: "ทุ่งหว้า",
              subdistrictNameEn: "Thung Wa",
              postalCode: 91120,
            },
            {
              subdistrictNameTh: "นาทอน",
              subdistrictNameEn: "Na Thon",
              postalCode: 91120,
            },
            {
              subdistrictNameTh: "ขอนคลาน",
              subdistrictNameEn: "Khon Khlan",
              postalCode: 91120,
            },
            {
              subdistrictNameTh: "ทุ่งบุหลัง",
              subdistrictNameEn: "Thung Bu Lang",
              postalCode: 91120,
            },
            {
              subdistrictNameTh: "ป่าแก่บ่อหิน",
              subdistrictNameEn: "Pa Kae Bo Hin",
              postalCode: 91120,
            },
          ],
        },
        {
          districtNameTh: "มะนัง",
          districtNameEn: "Manang",
          subdistricts: [
            {
              subdistrictNameTh: "ปาล์มพัฒนา",
              subdistrictNameEn: "Pam Phatthana",
              postalCode: 91130,
            },
            {
              subdistrictNameTh: "นิคมพัฒนา",
              subdistrictNameEn: "Nikhom Phatthana",
              postalCode: 91130,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ตรัง",
      stateNameEn: "Trang",
      districts: [
        {
          districtNameTh: "เมืองตรัง",
          districtNameEn: "Mueang Trang",
          subdistricts: [
            {
              subdistrictNameTh: "ทับเที่ยง",
              subdistrictNameEn: "Thap Thiang",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "นาพละ",
              subdistrictNameEn: "Na Phala",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "บ้านควน",
              subdistrictNameEn: "Ban Khuan",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "นาบินหลา",
              subdistrictNameEn: "Na Bin La",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "ควนปริง",
              subdistrictNameEn: "Khuan Pring",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "นาโยงใต้",
              subdistrictNameEn: "Na Yong Tai",
              postalCode: 92170,
            },
            {
              subdistrictNameTh: "บางรัก",
              subdistrictNameEn: "Bang Rak",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "โคกหล่อ",
              subdistrictNameEn: "Khok Lo",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "นาโต๊ะหมิง",
              subdistrictNameEn: "Na To Ming",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "หนองตรุด",
              subdistrictNameEn: "Nong Trut",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "น้ำผุด",
              subdistrictNameEn: "Nam Phut",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "นาตาล่วง",
              subdistrictNameEn: "Na Ta Luang",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "บ้านโพธิ์",
              subdistrictNameEn: "Ban Pho",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "นาท่ามเหนือ",
              subdistrictNameEn: "Na Tham Nuea",
              postalCode: 92190,
            },
            {
              subdistrictNameTh: "นาท่ามใต้",
              subdistrictNameEn: "Na Tham Tai",
              postalCode: 92190,
            },
          ],
        },
        {
          districtNameTh: "กันตัง",
          districtNameEn: "Kantang",
          subdistricts: [
            {
              subdistrictNameTh: "กันตัง",
              subdistrictNameEn: "Kantang",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "ควนธานี",
              subdistrictNameEn: "Khuan Thani",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "บางหมาก",
              subdistrictNameEn: "Bang Mak",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "บางเป้า",
              subdistrictNameEn: "Bang Pao",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "วังวน",
              subdistrictNameEn: "Wang Won",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "กันตังใต้",
              subdistrictNameEn: "Kantang Tai",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "โคกยาง",
              subdistrictNameEn: "Khok Yang",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "คลองลุ",
              subdistrictNameEn: "Khlong Lu",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "ย่านซื่อ",
              subdistrictNameEn: "Yan Sue",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "บ่อน้ำร้อน",
              subdistrictNameEn: "Bo Nam Ron",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "บางสัก",
              subdistrictNameEn: "Bang Sak",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "นาเกลือ",
              subdistrictNameEn: "Na Kluea",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "เกาะลิบง",
              subdistrictNameEn: "Ko Libong",
              postalCode: 92110,
            },
            {
              subdistrictNameTh: "คลองชีล้อม",
              subdistrictNameEn: "Khlong Chi Lom",
              postalCode: 92110,
            },
          ],
        },
        {
          districtNameTh: "ย่านตาขาว",
          districtNameEn: "Yan Ta Khao",
          subdistricts: [
            {
              subdistrictNameTh: "ย่านตาขาว",
              subdistrictNameEn: "Yan Ta Khao",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "หนองบ่อ",
              subdistrictNameEn: "Nong Bo",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "นาชุมเห็ด",
              subdistrictNameEn: "Na Chum Het",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "ในควน",
              subdistrictNameEn: "Nai Khuan",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "โพรงจระเข้",
              subdistrictNameEn: "Phrong Chorakhe",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "ทุ่งกระบือ",
              subdistrictNameEn: "Thung Krabue",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "ทุ่งค่าย",
              subdistrictNameEn: "Thung Khai",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "เกาะเปียะ",
              subdistrictNameEn: "Ko Pia",
              postalCode: 92140,
            },
          ],
        },
        {
          districtNameTh: "ปะเหลียน",
          districtNameEn: "Palian",
          subdistricts: [
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 92120,
            },
            {
              subdistrictNameTh: "ทุ่งยาว",
              subdistrictNameEn: "Thung Yao",
              postalCode: 92180,
            },
            {
              subdistrictNameTh: "ปะเหลียน",
              subdistrictNameEn: "Palian",
              postalCode: 92180,
            },
            {
              subdistrictNameTh: "บางด้วน",
              subdistrictNameEn: "Bang Duan",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "สุโสะ",
              subdistrictNameEn: "Suso",
              postalCode: 92120,
            },
            {
              subdistrictNameTh: "ลิพัง",
              subdistrictNameEn: "Li Phang",
              postalCode: 92180,
            },
            {
              subdistrictNameTh: "เกาะสุกร",
              subdistrictNameEn: "Ko Sukon",
              postalCode: 92120,
            },
            {
              subdistrictNameTh: "ท่าพญา",
              subdistrictNameEn: "Tha Phaya",
              postalCode: 92140,
            },
            {
              subdistrictNameTh: "แหลมสอม",
              subdistrictNameEn: "Laem Som",
              postalCode: 92180,
            },
          ],
        },
        {
          districtNameTh: "สิเกา",
          districtNameEn: "Sikao",
          subdistricts: [
            {
              subdistrictNameTh: "บ่อหิน",
              subdistrictNameEn: "Bo Hin",
              postalCode: 92150,
            },
            {
              subdistrictNameTh: "เขาไม้แก้ว",
              subdistrictNameEn: "Khao Mai Kaeo",
              postalCode: 92150,
            },
            {
              subdistrictNameTh: "กะลาเส",
              subdistrictNameEn: "Kalase",
              postalCode: 92150,
            },
            {
              subdistrictNameTh: "ไม้ฝาด",
              subdistrictNameEn: "Mai Fat",
              postalCode: 92150,
            },
            {
              subdistrictNameTh: "นาเมืองเพชร",
              subdistrictNameEn: "Na Mueang Phet",
              postalCode: 92000,
            },
          ],
        },
        {
          districtNameTh: "ห้วยยอด",
          districtNameEn: "Huai Yot",
          subdistricts: [
            {
              subdistrictNameTh: "ห้วยยอด",
              subdistrictNameEn: "Huai Yot",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "หนองช้างแล่น",
              subdistrictNameEn: "Nong Chang Laen",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "บางดี",
              subdistrictNameEn: "Bang Di",
              postalCode: 92210,
            },
            {
              subdistrictNameTh: "บางกุ้ง",
              subdistrictNameEn: "Bang Kung",
              postalCode: 92210,
            },
            {
              subdistrictNameTh: "เขากอบ",
              subdistrictNameEn: "Khao Kop",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "เขาขาว",
              subdistrictNameEn: "Khao Khao",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "เขาปูน",
              subdistrictNameEn: "Khao Pun",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "ปากแจ่ม",
              subdistrictNameEn: "Pak Chaem",
              postalCode: 92190,
            },
            {
              subdistrictNameTh: "ปากคม",
              subdistrictNameEn: "Pak Khom",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "ท่างิ้ว",
              subdistrictNameEn: "Tha Ngio",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "ลำภูรา",
              subdistrictNameEn: "Lamphura",
              postalCode: 92190,
            },
            {
              subdistrictNameTh: "นาวง",
              subdistrictNameEn: "Na Wong",
              postalCode: 92210,
            },
            {
              subdistrictNameTh: "ห้วยนาง",
              subdistrictNameEn: "Huai Nang",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "ในเตา",
              subdistrictNameEn: "Nai Tao",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "ทุ่งต่อ",
              subdistrictNameEn: "Thung To",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "วังคีรี",
              subdistrictNameEn: "Wang Khiri",
              postalCode: 92210,
            },
          ],
        },
        {
          districtNameTh: "วังวิเศษ",
          districtNameEn: "Wang Wiset",
          subdistricts: [
            {
              subdistrictNameTh: "เขาวิเศษ",
              subdistrictNameEn: "Khao Wiset",
              postalCode: 92220,
            },
            {
              subdistrictNameTh: "วังมะปราง",
              subdistrictNameEn: "Wang Maprang",
              postalCode: 92220,
            },
            {
              subdistrictNameTh: "อ่าวตง",
              subdistrictNameEn: "Ao Tong",
              postalCode: 92220,
            },
            {
              subdistrictNameTh: "ท่าสะบ้า",
              subdistrictNameEn: "Tha Saba",
              postalCode: 92000,
            },
            {
              subdistrictNameTh: "วังมะปรางเหนือ",
              subdistrictNameEn: "Wang Maprang Nuea",
              postalCode: 92220,
            },
          ],
        },
        {
          districtNameTh: "นาโยง",
          districtNameEn: "Na Yong",
          subdistricts: [
            {
              subdistrictNameTh: "นาโยงเหนือ",
              subdistrictNameEn: "Na Yong Nuea",
              postalCode: 92170,
            },
            {
              subdistrictNameTh: "ช่อง",
              subdistrictNameEn: "Chong",
              postalCode: 92170,
            },
            {
              subdistrictNameTh: "ละมอ",
              subdistrictNameEn: "Lamo",
              postalCode: 92170,
            },
            {
              subdistrictNameTh: "โคกสะบ้า",
              subdistrictNameEn: "Khok Saba",
              postalCode: 92170,
            },
            {
              subdistrictNameTh: "นาหมื่นศรี",
              subdistrictNameEn: "Na Muen Si",
              postalCode: 92170,
            },
            {
              subdistrictNameTh: "นาข้าวเสีย",
              subdistrictNameEn: "Na Khao Sia",
              postalCode: 92170,
            },
          ],
        },
        {
          districtNameTh: "รัษฎา",
          districtNameEn: "Ratsada",
          subdistricts: [
            {
              subdistrictNameTh: "ควนเมา",
              subdistrictNameEn: "Khuan Mao",
              postalCode: 92160,
            },
            {
              subdistrictNameTh: "คลองปาง",
              subdistrictNameEn: "Khlong Pang",
              postalCode: 92160,
            },
            {
              subdistrictNameTh: "หนองบัว",
              subdistrictNameEn: "Nong Bua",
              postalCode: 92160,
            },
            {
              subdistrictNameTh: "หนองปรือ",
              subdistrictNameEn: "Nong Prue",
              postalCode: 92130,
            },
            {
              subdistrictNameTh: "เขาไพร",
              subdistrictNameEn: "Khao Phrai",
              postalCode: 92160,
            },
          ],
        },
        {
          districtNameTh: "หาดสำราญ",
          districtNameEn: "Hat Samran",
          subdistricts: [
            {
              subdistrictNameTh: "หาดสำราญ",
              subdistrictNameEn: "Hat Samran",
              postalCode: 92120,
            },
            {
              subdistrictNameTh: "บ้าหวี",
              subdistrictNameEn: "Ba Wi",
              postalCode: 92120,
            },
            {
              subdistrictNameTh: "ตะเสะ",
              subdistrictNameEn: "Tase",
              postalCode: 92120,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "พัทลุง",
      stateNameEn: "Phatthalung",
      districts: [
        {
          districtNameTh: "เมืองพัทลุง",
          districtNameEn: "Mueang Phatthalung",
          subdistricts: [
            {
              subdistrictNameTh: "คูหาสวรรค์",
              subdistrictNameEn: "Khu Ha Sa Wan",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "เขาเจียก",
              subdistrictNameEn: "Khao Chiak",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ท่ามิหรำ",
              subdistrictNameEn: "Tha Mi Ram",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "โคกชะงาย",
              subdistrictNameEn: "Khok Cha Ngai",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "นาท่อม",
              subdistrictNameEn: "Na Thom",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ปรางหมู่",
              subdistrictNameEn: "Prang Mu",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ท่าแค",
              subdistrictNameEn: "Tha Khae",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ลำปำ",
              subdistrictNameEn: "Lam Pam",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ตำนาน",
              subdistrictNameEn: "Tamnan",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ควนมะพร้าว",
              subdistrictNameEn: "Khuan Ma Phrao",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ร่มเมือง",
              subdistrictNameEn: "Rom Mueang",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ชัยบุรี",
              subdistrictNameEn: "Chai Buri",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "นาโหนด",
              subdistrictNameEn: "Na Not",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "พญาขัน",
              subdistrictNameEn: "Phaya Khan",
              postalCode: 93000,
            },
          ],
        },
        {
          districtNameTh: "กงหรา",
          districtNameEn: "Kong Ra",
          subdistricts: [
            {
              subdistrictNameTh: "กงหรา",
              subdistrictNameEn: "Kong Ra",
              postalCode: 93180,
            },
            {
              subdistrictNameTh: "ชะรัด",
              subdistrictNameEn: "Charat",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "คลองเฉลิม",
              subdistrictNameEn: "Khlong Cha Loem",
              postalCode: 93180,
            },
            {
              subdistrictNameTh: "คลองทรายขาว",
              subdistrictNameEn: "Khlong Sai Khao",
              postalCode: 93180,
            },
            {
              subdistrictNameTh: "สมหวัง",
              subdistrictNameEn: "Som Wang",
              postalCode: 93000,
            },
          ],
        },
        {
          districtNameTh: "เขาชัยสน",
          districtNameEn: "Khao Chaison",
          subdistricts: [
            {
              subdistrictNameTh: "เขาชัยสน",
              subdistrictNameEn: "Khao Chaison",
              postalCode: 93130,
            },
            {
              subdistrictNameTh: "ควนขนุน",
              subdistrictNameEn: "Khuan Kha Nun",
              postalCode: 93130,
            },
            {
              subdistrictNameTh: "จองถนน",
              subdistrictNameEn: "Chong Tha Non",
              postalCode: 93130,
            },
            {
              subdistrictNameTh: "หานโพธิ์",
              subdistrictNameEn: "Han Pho",
              postalCode: 93130,
            },
            {
              subdistrictNameTh: "โคกม่วง",
              subdistrictNameEn: "Khok Muang",
              postalCode: 93130,
            },
          ],
        },
        {
          districtNameTh: "ตะโหมด",
          districtNameEn: "Tamot",
          subdistricts: [
            {
              subdistrictNameTh: "แม่ขรี",
              subdistrictNameEn: "Mae Khri",
              postalCode: 93160,
            },
            {
              subdistrictNameTh: "ตะโหมด",
              subdistrictNameEn: "Tanot",
              postalCode: 93160,
            },
            {
              subdistrictNameTh: "คลองใหญ่",
              subdistrictNameEn: "Khlong Yai",
              postalCode: 93160,
            },
          ],
        },
        {
          districtNameTh: "ควนขนุน",
          districtNameEn: "Khuan Khanun",
          subdistricts: [
            {
              subdistrictNameTh: "ควนขนุน",
              subdistrictNameEn: "Khuan Kha Nun",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "ทะเลน้อย",
              subdistrictNameEn: "Thale Noi",
              postalCode: 93150,
            },
            {
              subdistrictNameTh: "นาขยาด",
              subdistrictNameEn: "Nakhayat",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "พนมวังก์",
              subdistrictNameEn: "Phanom Wang",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "แหลมโตนด",
              subdistrictNameEn: "Laem Tanot",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "ปันแต",
              subdistrictNameEn: "Pantae",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "โตนดด้วน",
              subdistrictNameEn: "Tanot Duan",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "ดอนทราย",
              subdistrictNameEn: "Don Sai",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "มะกอกเหนือ",
              subdistrictNameEn: "Makok Nuea",
              postalCode: 93150,
            },
            {
              subdistrictNameTh: "พนางตุง",
              subdistrictNameEn: "Phanang Tung",
              postalCode: 93150,
            },
            {
              subdistrictNameTh: "ชะมวง",
              subdistrictNameEn: "Chamuang",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "แพรกหา",
              subdistrictNameEn: "Phraek Ha",
              postalCode: 93110,
            },
          ],
        },
        {
          districtNameTh: "ปากพะยูน",
          districtNameEn: "Pak Phayun",
          subdistricts: [
            {
              subdistrictNameTh: "ปากพะยูน",
              subdistrictNameEn: "Pak Pha Yun",
              postalCode: 93120,
            },
            {
              subdistrictNameTh: "ดอนประดู่",
              subdistrictNameEn: "Don Pradu",
              postalCode: 93120,
            },
            {
              subdistrictNameTh: "เกาะนางคำ",
              subdistrictNameEn: "Ko Nangkham",
              postalCode: 93120,
            },
            {
              subdistrictNameTh: "เกาะหมาก",
              subdistrictNameEn: "Ko Mak",
              postalCode: 93120,
            },
            {
              subdistrictNameTh: "ฝาละมี",
              subdistrictNameEn: "Fa La Mi",
              postalCode: 93120,
            },
            {
              subdistrictNameTh: "หารเทา",
              subdistrictNameEn: "Han Thao",
              postalCode: 93120,
            },
            {
              subdistrictNameTh: "ดอนทราย",
              subdistrictNameEn: "Don Sai",
              postalCode: 93120,
            },
          ],
        },
        {
          districtNameTh: "ศรีบรรพต",
          districtNameEn: "Si Banphot",
          subdistricts: [
            {
              subdistrictNameTh: "เขาย่า",
              subdistrictNameEn: "Khao Ya",
              postalCode: 93190,
            },
            {
              subdistrictNameTh: "เขาปู่",
              subdistrictNameEn: "Khao Pu",
              postalCode: 93190,
            },
            {
              subdistrictNameTh: "ตะแพน",
              subdistrictNameEn: "Tapaen",
              postalCode: 93190,
            },
          ],
        },
        {
          districtNameTh: "ป่าบอน",
          districtNameEn: "Pa Bon",
          subdistricts: [
            {
              subdistrictNameTh: "ป่าบอน",
              subdistrictNameEn: "Pa Bon",
              postalCode: 93170,
            },
            {
              subdistrictNameTh: "โคกทราย",
              subdistrictNameEn: "Khok Sai",
              postalCode: 93170,
            },
            {
              subdistrictNameTh: "หนองธง",
              subdistrictNameEn: "Nong Thong",
              postalCode: 93170,
            },
            {
              subdistrictNameTh: "ทุ่งนารี",
              subdistrictNameEn: "Thung Nari",
              postalCode: 93170,
            },
            {
              subdistrictNameTh: "วังใหม่",
              subdistrictNameEn: "Wang Mai",
              postalCode: 93170,
            },
          ],
        },
        {
          districtNameTh: "บางแก้ว",
          districtNameEn: "Bang Kaeo",
          subdistricts: [
            {
              subdistrictNameTh: "ท่ามะเดื่อ",
              subdistrictNameEn: "Tha Maduea",
              postalCode: 93140,
            },
            {
              subdistrictNameTh: "นาปะขอ",
              subdistrictNameEn: "Na Pakho",
              postalCode: 93140,
            },
            {
              subdistrictNameTh: "โคกสัก",
              subdistrictNameEn: "Khok Sak",
              postalCode: 93140,
            },
          ],
        },
        {
          districtNameTh: "ป่าพะยอม",
          districtNameEn: "Pa Phayom",
          subdistricts: [
            {
              subdistrictNameTh: "ป่าพะยอม",
              subdistrictNameEn: "Pa Phayom",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "ลานข่อย",
              subdistrictNameEn: "Lan Khoi",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "เกาะเต่า",
              subdistrictNameEn: "Ko Tao",
              postalCode: 93110,
            },
            {
              subdistrictNameTh: "บ้านพร้าว",
              subdistrictNameEn: "Ban Phrao",
              postalCode: 93110,
            },
          ],
        },
        {
          districtNameTh: "ศรีนครินทร์",
          districtNameEn: "Srinagarindra",
          subdistricts: [
            {
              subdistrictNameTh: "ชุมพล",
              subdistrictNameEn: "Chum Phon",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "บ้านนา",
              subdistrictNameEn: "Ban Na",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "อ่างทอง",
              subdistrictNameEn: "Ang Thong",
              postalCode: 93000,
            },
            {
              subdistrictNameTh: "ลำสินธุ์",
              subdistrictNameEn: "Lam Sin",
              postalCode: 93000,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ปัตตานี",
      stateNameEn: "Pattani",
      districts: [
        {
          districtNameTh: "เมืองปัตตานี",
          districtNameEn: "Mueang Pattani",
          subdistricts: [
            {
              subdistrictNameTh: "สะบารัง",
              subdistrictNameEn: "Sabarang",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "อาเนาะรู",
              subdistrictNameEn: "A Noru",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "จะบังติกอ",
              subdistrictNameEn: "Chabang Tiko",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "บานา",
              subdistrictNameEn: "Bana",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "ตันหยงลุโละ",
              subdistrictNameEn: "Tanyong Lulo",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "คลองมานิง",
              subdistrictNameEn: "Khlong Maning",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "กะมิยอ",
              subdistrictNameEn: "Kami Yo",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "บาราโหม",
              subdistrictNameEn: "Bara Hom",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "ปะกาฮะรัง",
              subdistrictNameEn: "Paka Harang",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "รูสะมิแล",
              subdistrictNameEn: "Ru Samilae",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "ตะลุโบะ",
              subdistrictNameEn: "Talu Bo",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "บาราเฮาะ",
              subdistrictNameEn: "Bara Ho",
              postalCode: 94000,
            },
            {
              subdistrictNameTh: "ปุยุด",
              subdistrictNameEn: "Pu Yut",
              postalCode: 94000,
            },
          ],
        },
        {
          districtNameTh: "โคกโพธิ์",
          districtNameEn: "Khok Pho",
          subdistricts: [
            {
              subdistrictNameTh: "โคกโพธิ์",
              subdistrictNameEn: "Khok Pho",
              postalCode: 94120,
            },
            {
              subdistrictNameTh: "มะกรูด",
              subdistrictNameEn: "Makrut",
              postalCode: 94120,
            },
            {
              subdistrictNameTh: "บางโกระ",
              subdistrictNameEn: "Bang Kro",
              postalCode: 94120,
            },
            {
              subdistrictNameTh: "ป่าบอน",
              subdistrictNameEn: "Pa Bon",
              postalCode: 94120,
            },
            {
              subdistrictNameTh: "ทรายขาว",
              subdistrictNameEn: "Sai Khao",
              postalCode: 94120,
            },
            {
              subdistrictNameTh: "นาประดู่",
              subdistrictNameEn: "Na Pradu",
              postalCode: 94180,
            },
            {
              subdistrictNameTh: "ปากล่อ",
              subdistrictNameEn: "Pak Lo",
              postalCode: 94180,
            },
            {
              subdistrictNameTh: "ทุ่งพลา",
              subdistrictNameEn: "Thung Phla",
              postalCode: 94180,
            },
            {
              subdistrictNameTh: "ท่าเรือ",
              subdistrictNameEn: "Tha Ruea",
              postalCode: 94120,
            },
            {
              subdistrictNameTh: "นาเกตุ",
              subdistrictNameEn: "Na Ket",
              postalCode: 94120,
            },
            {
              subdistrictNameTh: "ควนโนรี",
              subdistrictNameEn: "Khuan Nori",
              postalCode: 94180,
            },
            {
              subdistrictNameTh: "ช้างให้ตก",
              subdistrictNameEn: "Chang Hai Tok",
              postalCode: 94120,
            },
          ],
        },
        {
          districtNameTh: "หนองจิก",
          districtNameEn: "Nong Chik",
          subdistricts: [
            {
              subdistrictNameTh: "เกาะเปาะ",
              subdistrictNameEn: "Ko Po",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "คอลอตันหยง",
              subdistrictNameEn: "Kholo Tanyong",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "ดอนรัก",
              subdistrictNameEn: "Don Rak",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "ดาโต๊ะ",
              subdistrictNameEn: "Dato",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "ตุยง",
              subdistrictNameEn: "Tuyong",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "ท่ากำชำ",
              subdistrictNameEn: "Tha Kamcham",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "บ่อทอง",
              subdistrictNameEn: "Bo Thong",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "บางเขา",
              subdistrictNameEn: "Bang Khao",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "บางตาวา",
              subdistrictNameEn: "Bang Tawa",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "ปุโละปุโย",
              subdistrictNameEn: "Pulo Puyo",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "ยาบี",
              subdistrictNameEn: "Ya Bi",
              postalCode: 94170,
            },
            {
              subdistrictNameTh: "ลิปะสะโง",
              subdistrictNameEn: "Lipa Sa-Ngo",
              postalCode: 94170,
            },
          ],
        },
        {
          districtNameTh: "ปะนาเระ",
          districtNameEn: "Panare",
          subdistricts: [
            {
              subdistrictNameTh: "ปะนาเระ",
              subdistrictNameEn: "Panare",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "ท่าข้าม",
              subdistrictNameEn: "Tha Kham",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "บ้านนอก",
              subdistrictNameEn: "Ban Nok",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "ดอน",
              subdistrictNameEn: "Don",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "ควน",
              subdistrictNameEn: "Khuan",
              postalCode: 94190,
            },
            {
              subdistrictNameTh: "ท่าน้ำ",
              subdistrictNameEn: "Tha Nam",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "คอกกระบือ",
              subdistrictNameEn: "Khok Krabue",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "พ่อมิ่ง",
              subdistrictNameEn: "Pho Ming",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "บ้านกลาง",
              subdistrictNameEn: "Ban Klang",
              postalCode: 94130,
            },
            {
              subdistrictNameTh: "บ้านน้ำบ่อ",
              subdistrictNameEn: "Ban Nam Bo",
              postalCode: 94130,
            },
          ],
        },
        {
          districtNameTh: "มายอ",
          districtNameEn: "Mayo",
          subdistricts: [
            {
              subdistrictNameTh: "มายอ",
              subdistrictNameEn: "Mayo",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "ถนน",
              subdistrictNameEn: "Thanon",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "ตรัง",
              subdistrictNameEn: "Trang",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "กระหวะ",
              subdistrictNameEn: "Krawa",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "ลุโบะยิไร",
              subdistrictNameEn: "Lubo Yiri",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "ลางา",
              subdistrictNameEn: "La-Nga",
              postalCode: 94190,
            },
            {
              subdistrictNameTh: "กระเสาะ",
              subdistrictNameEn: "Kraso",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "เกาะจัน",
              subdistrictNameEn: "Ko Chan",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "ปะโด",
              subdistrictNameEn: "Podo",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "สาคอบน",
              subdistrictNameEn: "Sakho Bon",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "สาคอใต้",
              subdistrictNameEn: "Sakho Tai",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "สะกำ",
              subdistrictNameEn: "Sakam",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "ปานัน",
              subdistrictNameEn: "Pa Nan",
              postalCode: 94140,
            },
          ],
        },
        {
          districtNameTh: "ทุ่งยางแดง",
          districtNameEn: "Thung Yang Daeng",
          subdistricts: [
            {
              subdistrictNameTh: "ตะโละแมะนา",
              subdistrictNameEn: "Talo Maena",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "พิเทน",
              subdistrictNameEn: "Phithen",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "น้ำดำ",
              subdistrictNameEn: "Nam Dam",
              postalCode: 94140,
            },
            {
              subdistrictNameTh: "ปากู",
              subdistrictNameEn: "Pa Ku",
              postalCode: 94140,
            },
          ],
        },
        {
          districtNameTh: "สายบุรี",
          districtNameEn: "Sai Buri",
          subdistricts: [
            {
              subdistrictNameTh: "ตะลุบัน",
              subdistrictNameEn: "Talu Ban",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "ตะบิ้ง",
              subdistrictNameEn: "Tabing",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "ปะเสยะวอ",
              subdistrictNameEn: "Pase Yawo",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "บางเก่า",
              subdistrictNameEn: "Bang Kao",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "บือเระ",
              subdistrictNameEn: "Buere",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "เตราะบอน",
              subdistrictNameEn: "Tro Bon",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "กะดุนง",
              subdistrictNameEn: "Kadunong",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "ละหาร",
              subdistrictNameEn: "Lahan",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "มะนังดาลำ",
              subdistrictNameEn: "Manang Dalam",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "แป้น",
              subdistrictNameEn: "Paen",
              postalCode: 94110,
            },
            {
              subdistrictNameTh: "ทุ่งคล้า",
              subdistrictNameEn: "Thung Khla",
              postalCode: 94190,
            },
          ],
        },
        {
          districtNameTh: "ไม้แก่น",
          districtNameEn: "Mai Kaen",
          subdistricts: [
            {
              subdistrictNameTh: "ไทรทอง",
              subdistrictNameEn: "Sai Thong",
              postalCode: 94220,
            },
            {
              subdistrictNameTh: "ไม้แก่น",
              subdistrictNameEn: "Mai Kaen",
              postalCode: 94220,
            },
            {
              subdistrictNameTh: "ตะโละไกรทอง",
              subdistrictNameEn: "Talo Krai Thong",
              postalCode: 94220,
            },
            {
              subdistrictNameTh: "ดอนทราย",
              subdistrictNameEn: "Don Sai",
              postalCode: 94220,
            },
          ],
        },
        {
          districtNameTh: "ยะหริ่ง",
          districtNameEn: "Yaring",
          subdistricts: [
            {
              subdistrictNameTh: "ตะโละ",
              subdistrictNameEn: "Talo",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ตะโละกาโปร์",
              subdistrictNameEn: "Talo Kapo",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ตันหยงดาลอ",
              subdistrictNameEn: "Tanyong Dalo",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ตันหยงจึงงา",
              subdistrictNameEn: "Tanyong Chueng Nga",
              postalCode: 94190,
            },
            {
              subdistrictNameTh: "ตอหลัง",
              subdistrictNameEn: "To Lang",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ตาแกะ",
              subdistrictNameEn: "Ta Kae",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ตาลีอายร์",
              subdistrictNameEn: "Tali-Ai",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ยามู",
              subdistrictNameEn: "Ya Mu",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "บางปู",
              subdistrictNameEn: "Bang Pu",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "หนองแรต",
              subdistrictNameEn: "Nong Raet",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ปิยามุมัง",
              subdistrictNameEn: "Piya Mumang",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ปุลากง",
              subdistrictNameEn: "Pulakong",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "บาโลย",
              subdistrictNameEn: "Baloi",
              postalCode: 94190,
            },
            {
              subdistrictNameTh: "สาบัน",
              subdistrictNameEn: "Saban",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "มะนังยง",
              subdistrictNameEn: "Manang Yong",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "ราตาปันยัง",
              subdistrictNameEn: "Rata Panyang",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "จะรัง",
              subdistrictNameEn: "Charang",
              postalCode: 94150,
            },
            {
              subdistrictNameTh: "แหลมโพธิ์",
              subdistrictNameEn: "Laem Pho",
              postalCode: 94150,
            },
          ],
        },
        {
          districtNameTh: "ยะรัง",
          districtNameEn: "Yarang",
          subdistricts: [
            {
              subdistrictNameTh: "ยะรัง",
              subdistrictNameEn: "Yarang",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "สะดาวา",
              subdistrictNameEn: "Sadawa",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "ประจัน",
              subdistrictNameEn: "Prachan",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "สะนอ",
              subdistrictNameEn: "Sano",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "ระแว้ง",
              subdistrictNameEn: "Rawaeng",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "ปิตูมุดี",
              subdistrictNameEn: "Pitu Mudi",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "วัด",
              subdistrictNameEn: "Wat",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "กระโด",
              subdistrictNameEn: "Krado",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "คลองใหม่",
              subdistrictNameEn: "Khlong Mai",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "เมาะมาวี",
              subdistrictNameEn: "Mo Mawi",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "กอลำ",
              subdistrictNameEn: "Ko Lam",
              postalCode: 94160,
            },
            {
              subdistrictNameTh: "เขาตูม",
              subdistrictNameEn: "Khao Tum",
              postalCode: 94160,
            },
          ],
        },
        {
          districtNameTh: "กะพ้อ",
          districtNameEn: "Kapho",
          subdistricts: [
            {
              subdistrictNameTh: "กะรุบี",
              subdistrictNameEn: "Karubi",
              postalCode: 94230,
            },
            {
              subdistrictNameTh: "ตะโละดือรามัน",
              subdistrictNameEn: "Talo Due Raman",
              postalCode: 94230,
            },
            {
              subdistrictNameTh: "ปล่องหอย",
              subdistrictNameEn: "Plong Hoi",
              postalCode: 94230,
            },
          ],
        },
        {
          districtNameTh: "แม่ลาน",
          districtNameEn: "Mae Lan",
          subdistricts: [
            {
              subdistrictNameTh: "แม่ลาน",
              subdistrictNameEn: "Mae Lan",
              postalCode: 94180,
            },
            {
              subdistrictNameTh: "ม่วงเตี้ย",
              subdistrictNameEn: "Muang Tia",
              postalCode: 94180,
            },
            {
              subdistrictNameTh: "ป่าไร่",
              subdistrictNameEn: "Pa Rai",
              postalCode: 94180,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "ยะลา",
      stateNameEn: "Yala",
      districts: [
        {
          districtNameTh: "เมืองยะลา",
          districtNameEn: "Mueang Yala",
          subdistricts: [
            {
              subdistrictNameTh: "สะเตง",
              subdistrictNameEn: "Sateng",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "บุดี",
              subdistrictNameEn: "Bu Di",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ยุโป",
              subdistrictNameEn: "Yopo",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ลิดล",
              subdistrictNameEn: "Li Don",
              postalCode: 95160,
            },
            {
              subdistrictNameTh: "ยะลา",
              subdistrictNameEn: "Yala",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ท่าสาป",
              subdistrictNameEn: "Tha Sap",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ลำใหม่",
              subdistrictNameEn: "Lam Mai",
              postalCode: 95160,
            },
            {
              subdistrictNameTh: "หน้าถ้ำ",
              subdistrictNameEn: "Na Tham",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ลำพะยา",
              subdistrictNameEn: "Lam Phaya",
              postalCode: 95160,
            },
            {
              subdistrictNameTh: "เปาะเส้ง",
              subdistrictNameEn: "Po Seng",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "พร่อน",
              subdistrictNameEn: "Phron",
              postalCode: 95160,
            },
            {
              subdistrictNameTh: "บันนังสาเรง",
              subdistrictNameEn: "Bannang Sareng",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "สะเตงนอก",
              subdistrictNameEn: "Sateng Nok",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ตาเซะ",
              subdistrictNameEn: "Tase",
              postalCode: 95000,
            },
          ],
        },
        {
          districtNameTh: "เบตง",
          districtNameEn: "Betong",
          subdistricts: [
            {
              subdistrictNameTh: "เบตง",
              subdistrictNameEn: "Betong",
              postalCode: 95110,
            },
            {
              subdistrictNameTh: "ยะรม",
              subdistrictNameEn: "Yarom",
              postalCode: 95110,
            },
            {
              subdistrictNameTh: "ตาเนาะแมเราะ",
              subdistrictNameEn: "Tano Maero",
              postalCode: 95110,
            },
            {
              subdistrictNameTh: "อัยเยอร์เวง",
              subdistrictNameEn: "Aiyoe Weng",
              postalCode: 95110,
            },
            {
              subdistrictNameTh: "ธารน้ำทิพย์",
              subdistrictNameEn: "Than Nam Thip",
              postalCode: 95110,
            },
          ],
        },
        {
          districtNameTh: "บันนังสตา",
          districtNameEn: "Bannang Sata",
          subdistricts: [
            {
              subdistrictNameTh: "บันนังสตา",
              subdistrictNameEn: "Bannang Sata",
              postalCode: 95130,
            },
            {
              subdistrictNameTh: "บาเจาะ",
              subdistrictNameEn: "Bacho",
              postalCode: 95130,
            },
            {
              subdistrictNameTh: "ตาเนาะปูเต๊ะ",
              subdistrictNameEn: "Tano Pute",
              postalCode: 95130,
            },
            {
              subdistrictNameTh: "ถ้ำทะลุ",
              subdistrictNameEn: "Tham Thalu",
              postalCode: 95130,
            },
            {
              subdistrictNameTh: "ตลิ่งชัน",
              subdistrictNameEn: "Taling Chan",
              postalCode: 95130,
            },
            {
              subdistrictNameTh: "เขื่อนบางลาง",
              subdistrictNameEn: "Khuean Bang Lang",
              postalCode: 95130,
            },
          ],
        },
        {
          districtNameTh: "ธารโต",
          districtNameEn: "Than To",
          subdistricts: [
            {
              subdistrictNameTh: "ธารโต",
              subdistrictNameEn: "Than To",
              postalCode: 95150,
            },
            {
              subdistrictNameTh: "บ้านแหร",
              subdistrictNameEn: "Ban Rae",
              postalCode: 95150,
            },
            {
              subdistrictNameTh: "แม่หวาด",
              subdistrictNameEn: "Mae Wat",
              postalCode: 95170,
            },
            {
              subdistrictNameTh: "คีรีเขต",
              subdistrictNameEn: "Khiri Khet",
              postalCode: 95150,
            },
          ],
        },
        {
          districtNameTh: "ยะหา",
          districtNameEn: "Yaha",
          subdistricts: [
            {
              subdistrictNameTh: "ยะหา",
              subdistrictNameEn: "Yaha",
              postalCode: 95120,
            },
            {
              subdistrictNameTh: "ละแอ",
              subdistrictNameEn: "La-Ae",
              postalCode: 95120,
            },
            {
              subdistrictNameTh: "ปะแต",
              subdistrictNameEn: "Patae",
              postalCode: 95120,
            },
            {
              subdistrictNameTh: "บาโร๊ะ",
              subdistrictNameEn: "Baro",
              postalCode: 95120,
            },
            {
              subdistrictNameTh: "ตาชี",
              subdistrictNameEn: "Ta Chi",
              postalCode: 95120,
            },
            {
              subdistrictNameTh: "บาโงยซิแน",
              subdistrictNameEn: "Ba-Ngoi Sinae",
              postalCode: 95120,
            },
            {
              subdistrictNameTh: "กาตอง",
              subdistrictNameEn: "Katong",
              postalCode: 95120,
            },
          ],
        },
        {
          districtNameTh: "รามัน",
          districtNameEn: "Raman",
          subdistricts: [
            {
              subdistrictNameTh: "กายูบอเกาะ",
              subdistrictNameEn: "Kayu Boko",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "กาลูปัง",
              subdistrictNameEn: "Kalupang",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "กาลอ",
              subdistrictNameEn: "Kalo",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "กอตอตือร๊ะ",
              subdistrictNameEn: "Koto Tuera",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "โกตาบารู",
              subdistrictNameEn: "Kota Baru",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "เกะรอ",
              subdistrictNameEn: "Ke Ro",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "จะกว๊ะ",
              subdistrictNameEn: "Cha Kwua",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "ท่าธง",
              subdistrictNameEn: "Tha Thong",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "เนินงาม",
              subdistrictNameEn: "Noen Ngam",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "บาลอ",
              subdistrictNameEn: "Balo",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "บาโงย",
              subdistrictNameEn: "Ba-Ngoi",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "บือมัง",
              subdistrictNameEn: "Buemang",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "ยะต๊ะ",
              subdistrictNameEn: "Yata",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "วังพญา",
              subdistrictNameEn: "Wang Phaya",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "อาซ่อง",
              subdistrictNameEn: "A Song",
              postalCode: 95140,
            },
            {
              subdistrictNameTh: "ตะโล๊ะหะลอ",
              subdistrictNameEn: "Talo Halo",
              postalCode: 95140,
            },
          ],
        },
        {
          districtNameTh: "กาบัง",
          districtNameEn: "Kabang",
          subdistricts: [
            {
              subdistrictNameTh: "กาบัง",
              subdistrictNameEn: "Kabang",
              postalCode: 95120,
            },
            {
              subdistrictNameTh: "บาละ",
              subdistrictNameEn: "Bala",
              postalCode: 95120,
            },
          ],
        },
        {
          districtNameTh: "กรงปินัง",
          districtNameEn: "Krong Pinang",
          subdistricts: [
            {
              subdistrictNameTh: "กรงปินัง",
              subdistrictNameEn: "Krong Pinang",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "สะเอะ",
              subdistrictNameEn: "Sa-Ae",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ห้วยกระทิง",
              subdistrictNameEn: "Huai Krathing",
              postalCode: 95000,
            },
            {
              subdistrictNameTh: "ปุโรง",
              subdistrictNameEn: "Pu Rong",
              postalCode: 95000,
            },
          ],
        },
      ],
    },
    {
      stateNameTh: "นราธิวาส",
      stateNameEn: "Narathiwat",
      districts: [
        {
          districtNameTh: "เมืองนราธิวาส",
          districtNameEn: "Mueang Narathiwat",
          subdistricts: [
            {
              subdistrictNameTh: "บางนาค",
              subdistrictNameEn: "Bang Nak",
              postalCode: 96000,
            },
            {
              subdistrictNameTh: "ลำภู",
              subdistrictNameEn: "Lamphu",
              postalCode: 96000,
            },
            {
              subdistrictNameTh: "มะนังตายอ",
              subdistrictNameEn: "Manang Tayo",
              postalCode: 96000,
            },
            {
              subdistrictNameTh: "บางปอ",
              subdistrictNameEn: "Bang Po",
              postalCode: 96000,
            },
            {
              subdistrictNameTh: "กะลุวอ",
              subdistrictNameEn: "Kaluwo",
              postalCode: 96000,
            },
            {
              subdistrictNameTh: "กะลุวอเหนือ",
              subdistrictNameEn: "Kaluwo Nuea",
              postalCode: 96000,
            },
            {
              subdistrictNameTh: "โคกเคียน",
              subdistrictNameEn: "Khok Khian",
              postalCode: 96000,
            },
          ],
        },
        {
          districtNameTh: "ตากใบ",
          districtNameEn: "Tak Bai",
          subdistricts: [
            {
              subdistrictNameTh: "เจ๊ะเห",
              subdistrictNameEn: "Che He",
              postalCode: 96110,
            },
            {
              subdistrictNameTh: "ไพรวัน",
              subdistrictNameEn: "Phrai Wan",
              postalCode: 96110,
            },
            {
              subdistrictNameTh: "พร่อน",
              subdistrictNameEn: "Phron",
              postalCode: 96110,
            },
            {
              subdistrictNameTh: "ศาลาใหม่",
              subdistrictNameEn: "Sala Mai",
              postalCode: 96110,
            },
            {
              subdistrictNameTh: "บางขุนทอง",
              subdistrictNameEn: "Bang Khun Thong",
              postalCode: 96110,
            },
            {
              subdistrictNameTh: "เกาะสะท้อน",
              subdistrictNameEn: "Ko Sathon",
              postalCode: 96110,
            },
            {
              subdistrictNameTh: "นานาค",
              subdistrictNameEn: "Na Nak",
              postalCode: 96110,
            },
            {
              subdistrictNameTh: "โฆษิต",
              subdistrictNameEn: "Khosit",
              postalCode: 96110,
            },
          ],
        },
        {
          districtNameTh: "บาเจาะ",
          districtNameEn: "Tak Bai",
          subdistricts: [
            {
              subdistrictNameTh: "บาเจาะ",
              subdistrictNameEn: "Bacho",
              postalCode: 96170,
            },
            {
              subdistrictNameTh: "ลุโบะสาวอ",
              subdistrictNameEn: "Lubo Sawo",
              postalCode: 96170,
            },
            {
              subdistrictNameTh: "กาเยาะมาตี",
              subdistrictNameEn: "Kayo Mati",
              postalCode: 96170,
            },
            {
              subdistrictNameTh: "ปะลุกาสาเมาะ",
              subdistrictNameEn: "Paluka Samo",
              postalCode: 96170,
            },
            {
              subdistrictNameTh: "บาเระเหนือ",
              subdistrictNameEn: "Bare Nuea",
              postalCode: 96170,
            },
            {
              subdistrictNameTh: "บาเระใต้",
              subdistrictNameEn: "Bare Tai",
              postalCode: 96170,
            },
          ],
        },
        {
          districtNameTh: "ยี่งอ",
          districtNameEn: "Yi-Ngo",
          subdistricts: [
            {
              subdistrictNameTh: "ยี่งอ",
              subdistrictNameEn: "Yi-Ngo",
              postalCode: 96180,
            },
            {
              subdistrictNameTh: "ละหาร",
              subdistrictNameEn: "Lahan",
              postalCode: 96180,
            },
            {
              subdistrictNameTh: "จอเบาะ",
              subdistrictNameEn: "Cho Bo",
              postalCode: 96180,
            },
            {
              subdistrictNameTh: "ลุโบะบายะ",
              subdistrictNameEn: "Lubo Baya",
              postalCode: 96180,
            },
            {
              subdistrictNameTh: "ลุโบะบือซา",
              subdistrictNameEn: "Lubo Buesa",
              postalCode: 96180,
            },
            {
              subdistrictNameTh: "ตะปอเยาะ",
              subdistrictNameEn: "Tapo Yo",
              postalCode: 96180,
            },
          ],
        },
        {
          districtNameTh: "ระแงะ",
          districtNameEn: "Ra-Ngae",
          subdistricts: [
            {
              subdistrictNameTh: "ตันหยงมัส",
              subdistrictNameEn: "Tanyong Mat",
              postalCode: 96130,
            },
            {
              subdistrictNameTh: "ตันหยงลิมอ",
              subdistrictNameEn: "Tanyong Limo",
              postalCode: 96130,
            },
            {
              subdistrictNameTh: "บองอ",
              subdistrictNameEn: "Bo-Ngo",
              postalCode: 96220,
            },
            {
              subdistrictNameTh: "กาลิซา",
              subdistrictNameEn: "Kalisa",
              postalCode: 96130,
            },
            {
              subdistrictNameTh: "บาโงสะโต",
              subdistrictNameEn: "Ba-Ngo Sato",
              postalCode: 96130,
            },
            {
              subdistrictNameTh: "เฉลิม",
              subdistrictNameEn: "Chaloem",
              postalCode: 96130,
            },
            {
              subdistrictNameTh: "มะรือโบตก",
              subdistrictNameEn: "Marue Bo Tok",
              postalCode: 96130,
            },
          ],
        },
        {
          districtNameTh: "รือเสาะ",
          districtNameEn: "Rueso",
          subdistricts: [
            {
              subdistrictNameTh: "รือเสาะ",
              subdistrictNameEn: "Rueso",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "สาวอ",
              subdistrictNameEn: "Sawo",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "เรียง",
              subdistrictNameEn: "Riang",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "สามัคคี",
              subdistrictNameEn: "Samakkhi",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "บาตง",
              subdistrictNameEn: "Batong",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "ลาโละ",
              subdistrictNameEn: "La Lo",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "รือเสาะออก",
              subdistrictNameEn: "Rueso Ok",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "โคกสะตอ",
              subdistrictNameEn: "Khok Sato",
              postalCode: 96150,
            },
            {
              subdistrictNameTh: "สุวารี",
              subdistrictNameEn: "Suwari",
              postalCode: 96150,
            },
          ],
        },
        {
          districtNameTh: "ศรีสาคร",
          districtNameEn: "Si Sakhon",
          subdistricts: [
            {
              subdistrictNameTh: "ซากอ",
              subdistrictNameEn: "Sa Ko",
              postalCode: 96210,
            },
            {
              subdistrictNameTh: "ตะมะยูง",
              subdistrictNameEn: "Tama Yung",
              postalCode: 96210,
            },
            {
              subdistrictNameTh: "ศรีสาคร",
              subdistrictNameEn: "Si Sakhon",
              postalCode: 96210,
            },
            {
              subdistrictNameTh: "เชิงคีรี",
              subdistrictNameEn: "Choeng Khiri",
              postalCode: 96210,
            },
            {
              subdistrictNameTh: "กาหลง",
              subdistrictNameEn: "Kalong",
              postalCode: 96210,
            },
            {
              subdistrictNameTh: "ศรีบรรพต",
              subdistrictNameEn: "Si Banphot",
              postalCode: 96210,
            },
          ],
        },
        {
          districtNameTh: "แว้ง",
          districtNameEn: "Waeng",
          subdistricts: [
            {
              subdistrictNameTh: "แว้ง",
              subdistrictNameEn: "Waeng",
              postalCode: 96160,
            },
            {
              subdistrictNameTh: "กายูคละ",
              subdistrictNameEn: "Kayu Khla",
              postalCode: 96160,
            },
            {
              subdistrictNameTh: "ฆอเลาะ",
              subdistrictNameEn: "Kho Lo",
              postalCode: 96160,
            },
            {
              subdistrictNameTh: "โละจูด",
              subdistrictNameEn: "Lo Chut",
              postalCode: 96160,
            },
            {
              subdistrictNameTh: "แม่ดง",
              subdistrictNameEn: "Mae Dong",
              postalCode: 96160,
            },
            {
              subdistrictNameTh: "เอราวัณ",
              subdistrictNameEn: "Erawan",
              postalCode: 96160,
            },
          ],
        },
        {
          districtNameTh: "สุคิริน",
          districtNameEn: "Sukhirin",
          subdistricts: [
            {
              subdistrictNameTh: "มาโมง",
              subdistrictNameEn: "Ma Mong",
              postalCode: 96190,
            },
            {
              subdistrictNameTh: "สุคิริน",
              subdistrictNameEn: "Sukhirin",
              postalCode: 96190,
            },
            {
              subdistrictNameTh: "เกียร์",
              subdistrictNameEn: "Kia",
              postalCode: 96190,
            },
            {
              subdistrictNameTh: "ภูเขาทอง",
              subdistrictNameEn: "Phu Khao Thong",
              postalCode: 96190,
            },
            {
              subdistrictNameTh: "ร่มไทร",
              subdistrictNameEn: "Rom Sai",
              postalCode: 96190,
            },
          ],
        },
        {
          districtNameTh: "สุไหงโก-ลก",
          districtNameEn: "Su-Ngai Kolok",
          subdistricts: [
            {
              subdistrictNameTh: "สุไหงโก-ลก",
              subdistrictNameEn: "Su-Ngai Ko-Lok",
              postalCode: 96120,
            },
            {
              subdistrictNameTh: "ปาเสมัส",
              subdistrictNameEn: "Pa Semat",
              postalCode: 96120,
            },
            {
              subdistrictNameTh: "มูโนะ",
              subdistrictNameEn: "Mu No",
              postalCode: 96120,
            },
            {
              subdistrictNameTh: "ปูโยะ",
              subdistrictNameEn: "Puyo",
              postalCode: 96120,
            },
          ],
        },
        {
          districtNameTh: "สุไหงปาดี",
          districtNameEn: "Su-Ngai Padi",
          subdistricts: [
            {
              subdistrictNameTh: "ปะลุรู",
              subdistrictNameEn: "Paluru",
              postalCode: 96140,
            },
            {
              subdistrictNameTh: "สุไหงปาดี",
              subdistrictNameEn: "Su-Ngai Padi",
              postalCode: 96140,
            },
            {
              subdistrictNameTh: "โต๊ะเด็ง",
              subdistrictNameEn: "To Deng",
              postalCode: 96140,
            },
            {
              subdistrictNameTh: "สากอ",
              subdistrictNameEn: "Sako",
              postalCode: 96140,
            },
            {
              subdistrictNameTh: "ริโก๋",
              subdistrictNameEn: "Ri Ko",
              postalCode: 96140,
            },
            {
              subdistrictNameTh: "กาวะ",
              subdistrictNameEn: "Kawa",
              postalCode: 96140,
            },
          ],
        },
        {
          districtNameTh: "จะแนะ",
          districtNameEn: "Chanae",
          subdistricts: [
            {
              subdistrictNameTh: "จะแนะ",
              subdistrictNameEn: "Chanae",
              postalCode: 96220,
            },
            {
              subdistrictNameTh: "ดุซงญอ",
              subdistrictNameEn: "Du Song Yo",
              postalCode: 96220,
            },
            {
              subdistrictNameTh: "ผดุงมาตร",
              subdistrictNameEn: "Phadung Mat",
              postalCode: 96220,
            },
            {
              subdistrictNameTh: "ช้างเผือก",
              subdistrictNameEn: "Chang Phueak",
              postalCode: 96220,
            },
          ],
        },
        {
          districtNameTh: "เจาะไอร้อง",
          districtNameEn: "Cho-Airong",
          subdistricts: [
            {
              subdistrictNameTh: "จวบ",
              subdistrictNameEn: "Chuap",
              postalCode: 96130,
            },
            {
              subdistrictNameTh: "บูกิต",
              subdistrictNameEn: "Bu Kit",
              postalCode: 96130,
            },
            {
              subdistrictNameTh: "มะรือโบออก",
              subdistrictNameEn: "Marue Bo Ok",
              postalCode: 96130,
            },
          ],
        },
      ],
    },
  ],
};
