import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/UserContext";

const ProtectedRoutes = ({ children }: { children: any }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoutes;
