import { MenuData } from "../components/input/CustomSelect";
import { EmissionGroup } from "../models/EmissionGroup";
import AddBusinessTravel from "../pages/emission/popup-add-emission/step2/emission-group/AddBusinessTravel";
import AddCapitalGoods from "../pages/emission/popup-add-emission/step2/emission-group/AddCapitalGoods";
import AddEmployeeCommuting from "../pages/emission/popup-add-emission/step2/emission-group/AddEmployeeCommuting";
import AddEnergy from "../pages/emission/popup-add-emission/step2/emission-group/AddEnergy";
import AddFertilizer from "../pages/emission/popup-add-emission/step2/emission-group/AddFertilizer";
import AddFireExtinguishing from "../pages/emission/popup-add-emission/step2/emission-group/AddFireExtinguishing";
import AddFuel from "../pages/emission/popup-add-emission/step2/emission-group/AddFuel";
import AddOtherFugitive from "../pages/emission/popup-add-emission/step2/emission-group/AddOtherFugitive";
import AddOutsourcingTransport from "../pages/emission/popup-add-emission/step2/emission-group/AddOutsourcingTransport";
import AddOutsourcingWasteDisposal from "../pages/emission/popup-add-emission/step2/emission-group/AddOutsourcingWasteDisposal";
import AddPurchases from "../pages/emission/popup-add-emission/step2/emission-group/AddPurchases";
import AddRefrigerants from "../pages/emission/popup-add-emission/step2/emission-group/AddRefrigerants";
import AddSepticTank from "../pages/emission/popup-add-emission/step2/emission-group/AddSepticTank";
import AddWastewater from "../pages/emission/popup-add-emission/step2/emission-group/AddWastewater";
import AddWater from "../pages/emission/popup-add-emission/step2/emission-group/AddWater";

export const DROPDOWN_EMISSION_GROUP: EmissionGroup[] = [
  {
    _id: "fuel",
    emissionGroup: "Fuel",
    groupTh: "Fuel",
    groupEn: "Fuel",
    description: "Fuel-Description",
    attributeName: "Fuel",
    component: <AddFuel />,
  },
  {
    _id: "energy",
    emissionGroup: "Energy",
    groupTh: "Energy",
    groupEn: "Energy",

    description: "Energy-Description",
    attributeName: "Energy-Source",
    component: <AddEnergy />,
  },
  {
    _id: "wastewater",
    emissionGroup: "Wastewater",
    groupTh: "Wastewater",
    groupEn: "Wastewater",
    description: "Wastewater-Description",
    attributeName: "Wastewater",
    component: <AddWastewater />,
    canQuickUpdateAmount: false,
  },
  {
    _id: "refrigerants",
    emissionGroup: "Refrigerants",
    groupTh: "Refrigerants",
    groupEn: "Refrigerants",
    description: "Refrigerants-Description",
    attributeName: "Refrigerants",
    component: <AddRefrigerants />,
  },
  {
    _id: "waste-disposal",
    emissionGroup: "Waste Disposal",
    groupTh: "Waste-Disposal",
    groupEn: "Waste-Disposal",
    description: "Waste-Disposal",
    attributeName: "Waste-Disposal",
    component: <AddOutsourcingWasteDisposal />,
  },
  {
    _id: "septic-tank",
    emissionGroup: "Septic Tank",
    groupTh: "Septic-Tank",
    groupEn: "Septic-Tank",
    description: "Septic-Tank-Description",
    attributeName: "Septic-Tank",
    component: <AddSepticTank />,
    canQuickUpdateAmount: false,
  },
  {
    _id: "fertilizer",
    emissionGroup: "Fertilizer",
    groupTh: "Fertilizer",
    groupEn: "Fertilizer",
    description: "Fertilize",
    attributeName: "Fertilize",
    component: <AddFertilizer />,
    disabled: false,
  },
  {
    _id: "business-travel",
    emissionGroup: "Business Travel",
    groupTh: "Business-Travel",
    groupEn: "Business-Travel",
    description: "Business-Travel-Description",
    attributeName: "Business-Travel",
    component: <AddBusinessTravel />,
    canQuickUpdateAmount: false,
  },
  {
    _id: "employee-commuting",
    emissionGroup: "Employee Commuting",
    groupTh: "Employee-Commuting",
    groupEn: "Employee-Commuting",
    description: "Employee-Commuting-Description",
    attributeName: "Employee-Commuting",
    component: <AddEmployeeCommuting />,
    canQuickUpdateAmount: false,
  },
  {
    _id: "fire-extinguishing",
    emissionGroup: "Fire Extinguishing",
    groupTh: "Fire-Extinguishing",
    groupEn: "Fire-Extinguishing",
    description: "Fire-Extinguisher",
    attributeName: "Fire-Extinguishing",
    component: <AddFireExtinguishing />,
  },
  {
    _id: "other-fugitive-emissions",
    emissionGroup: "Other Fugitive Emissions",
    groupTh: "Other-Fugitive-Emission",
    groupEn: "Other-Fugitive-Emission",
    description: "Other-Fugitive-Emission",
    attributeName: "Fugitive-Sources",
    component: <AddOtherFugitive />,
  },
  {
    _id: "purchases",
    emissionGroup: "Purchases",
    groupTh: "Purchases",
    groupEn: "Purchases",
    description: "Purchases-Description",
    attributeName: "Purchases",
    component: <AddPurchases />,
  },
  {
    _id: "water",
    emissionGroup: "Water",
    groupTh: "Water",
    groupEn: "Water",
    description: "Purchase-Water",
    attributeName: "Purchase-Water",
    component: <AddWater />,
  },
  {
    _id: "outsourcing-transport",
    emissionGroup: "Outsourcing Transport",
    groupTh: "Outsourcing-Transport",
    groupEn: "Outsourcing-Transport",
    description: "OtherFugitiveEmission-Description",
    attributeName: "Outsourcing-Transport-Source",
    component: <AddOutsourcingTransport />,
    canQuickUpdateAmount: false,
  },
  {
    _id: "capital-goods",
    emissionGroup: "Capital Goods",
    groupTh: "Capital-Goods",
    groupEn: "Capital-Goods",
    description: "Capital-Goods-Description-1",
    attributeName: "Capital-Goods",
    component: <AddCapitalGoods />,
  },
];

export const ENERGY_USAGE_BOUNDARY: MenuData[] = [
  {
    value: "inbound-usage",
    nameEN: "Inbound Usage",
    nameTH: "การใช้งานขาเข้า",
  },
  {
    value: "resell-to-outbound",
    nameEN: "Resell to outbound",
    nameTH: "ขายต่อไปยังต่างประเทศ",
  },
];

export const WASTEWATER_INDUSTRY_TYPE: MenuData[] = [
  {
    value: "Alcohol Distilling",
    nameEN: "Alcohol Distilling",
    nameTH: "การกลั่นแอลกอฮอล์",
  },
  {
    value: "Beer",
    nameEN: "Beer",
    nameTH: "เบียร์",
  },
  {
    value: "Coffee",
    nameEN: "Coffee",
    nameTH: "กาแฟ",
  },
  {
    value: "Dairy Products",
    nameEN: "Dairy Products",
    nameTH: "ผลิตภัณฑ์นม",
  },
  {
    value: "Seafood Processing",
    nameEN: "Seafood Processing",
    nameTH: "การแปรรูปอาหารทะเล",
  },
  {
    value: "Meat Processing",
    nameEN: "Meat Processing",
    nameTH: "การแปรรูปเนื้อสัตว์",
  },
  {
    value: "Organic Chemistry",
    nameEN: "Organic Chemistry",
    nameTH: "เคมีอินทรีย์",
  },
  {
    value: "Oil Refinery",
    nameEN: "Oil Refinery",
    nameTH: "โรงกลั่นน้ำมัน",
  },
  {
    value: "Plastic and Polyethylene Resin",
    nameEN: "Plastic and Polyethylene Resin",
    nameTH: "พลาสติกและเรซินโพลีเอทิลีน",
  },
  {
    value: "Paper and Pulp",
    nameEN: "Paper and Pulp",
    nameTH: "กระดาษและเยื่อกระดาษ",
  },
  {
    value: "Flour Products",
    nameEN: "Flour Products",
    nameTH: "ผลิตภัณฑ์แป้ง",
  },
  {
    value: "Fruit Juice",
    nameEN: "Fruit Juice",
    nameTH: "น้ำผลไม้",
  },
  {
    value: "Wine and Vinegar",
    nameEN: "Wine and Vinegar",
    nameTH: "ไวน์และน้ำส้มสายชู",
  },
];

export const PURCHASE_TYPE: MenuData[] = [
  {
    value: "Purchased goods and services",
    nameEN: "Purchased goods and services",
    nameTH: "สินค้าซื้อและบริการ",
  },
  {
    value: "Capital goods",
    nameEN: "Capital goods",
    nameTH: "สินค้าทุน",
  },
];

export const WASTE_SOURCE_CATEGORY: MenuData[] = [
  {
    value: "Self Operate",
    nameEN: "Self Operate",
    nameTH: "ดำเนินการเอง",
  },
  {
    value: "Outsourcing : Waste Generated in Operations",
    nameEN: "Outsourcing : Waste Generated in Operations",
    nameTH: "การจ้างงานภายนอก: ของเสียที่เกิดจากการดำเนินงาน",
  },
  {
    value: "Outsourcing : End of Life Treatment of Sold Product",
    nameEN: "Outsourcing : End of Life Treatment of Sold Product",
    nameTH:
      "การจ้างงานภายนอก: การจัดการเมื่อหมดอายุการใช้งานของผลิตภัณฑ์ที่ขาย",
  },
];

export const TRIP_TYPE: MenuData[] = [
  {
    value: "One-way",
    nameEN: "One-way",
    nameTH: "เที่ยวเดียว",
  },
  {
    value: "Round trip",
    nameEN: "Round trip",
    nameTH: "ไปกลับ",
  },
];

export const SEPTIC_TANK_TYPE: MenuData[] = [
  {
    value: "Standard",
    nameEN: "Standard",
    nameTH: "มาตรฐาน",
  },
];

export const GOOD_TYPE: MenuData[] = [
  {
    value: "Goods Purchased",
    nameEN: "Goods Purchased",
    nameTH: "สินค้าที่ซื้อ",
  },
  {
    value: "Product Sold",
    nameEN: "Product Sold",
    nameTH: "สินค้าที่ขาย",
  },
  {
    value: "Waste",
    nameEN: "Waste",
    nameTH: "ของเสีย",
  },
];

export const FERTILIZER_TYPE: MenuData[] = [
  {
    value: "Standard",
    nameEN: "Standard",
    nameTH: "มาตรฐาน",
  },
];

export const INDUSTRY_TYPE: MenuData[] = [
  {
    nameTH: "อุตสาหกรรม",
    nameEN: "Industry",
    value: "Industry",
  },
  {
    nameTH: "ครัวเรือน",
    nameEN: "Household",
    value: "Household",
  },
  {
    nameTH: "เกษตรกรรม",
    nameEN: "Agriculture",
    value: "Agriculture",
  },
  {
    nameTH: "ป่าไม้",
    nameEN: "Forestry",
    value: "Forestry",
  },
];
