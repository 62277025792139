import { Box } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { OrganizationProvider } from "../../contexts/OrganizationContext";
import { TgoGenerateReport } from "./tgo-cfo/TgoGenerateReport";
import { useReport } from "../../contexts/ReportContext";
import { SummaryGenerateReport } from "./summary/SummaryGenerateReport";
import { CustomGridToolbarQuickFilter } from "../../components/datagrid/CustomGridToolbarQuickFilter";

export const QuickSearchToolbar = () => {
  const { reportDataTableType } = useReport();

  return (
    <Box
      marginBottom={"40px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={4}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <CustomGridToolbarQuickFilter />
      <GridToolbarContainer
        sx={{
          padding: 0,
          width: { xs: "100%", sm: "auto" },
        }}
      >
        {reportDataTableType === "tgo-cfo" ? (
          <OrganizationProvider>
            <TgoGenerateReport />
          </OrganizationProvider>
        ) : (
          <OrganizationProvider>
            <SummaryGenerateReport />
          </OrganizationProvider>
        )}
      </GridToolbarContainer>
    </Box>
  );
};
