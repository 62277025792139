import { Box, FormHelperText } from "@mui/material";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  error?: FieldError;
}

export const CustomFormHelperText = (props: Props) => {
  return props.error ? (
    <Box display={"flex"} flexDirection={"row"}>
      <img
        src="/img/tick-circle.svg"
        style={{
          marginRight: "8px",
        }}
        alt=""
      />
      <FormHelperText>{props.error.message?.toString()}</FormHelperText>
    </Box>
  ) : null;
};
