export const Logo = (props: any) => {
    const { type, ...other } = props;
    let textOpacity = 1;
    if (type === "small") {
        textOpacity = 0;
    }
    return (
        <svg
            width="144"
            height="69"
            viewBox="0 0 144 69"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transition: ".3s" }}
            {...other}
        >
            <g clipPath="url(#clip0_4262_220501)">
                <path
                    d="M112.065 13.6035H114.255C114.868 13.6035 115.367 13.1048 115.367 12.4913V10.792C115.367 10.1784 114.868 9.67969 114.255 9.67969H112.065C111.452 9.67969 110.953 10.1784 110.953 10.792V12.4913C110.953 13.1048 111.452 13.6035 112.065 13.6035Z"
                    fill="parent"
                />
                <path
                    d="M106.517 16.3928V14.7994C106.517 14.2301 106.054 13.7666 105.484 13.7666H103.957C103.388 13.7666 102.924 13.3032 102.924 12.7338V8.23172C102.924 7.66234 102.461 7.1989 101.892 7.1989H96.2684C95.2532 7.1989 95.368 6.45297 95.368 6.26759V1.41683C95.368 0.635586 94.7324 0 93.9511 0H74.8703C60.3224 0 48.5332 11.7892 48.5332 26.3371V44.9501C48.5332 45.7313 49.1688 46.3669 49.95 46.3669H69.0353C82.462 46.3669 93.5318 36.3211 95.1561 23.3357C95.2002 22.1705 94.3484 22.0646 94.11 22.0646H94.0791H94.0482H88.5089C88.3236 22.0646 88.147 21.9851 88.0278 21.8394C87.8954 21.6717 87.7498 21.4025 87.7144 21.0052V17.3992C87.7144 17.1873 87.6306 17.0019 87.5026 16.8519C87.3481 16.6709 87.123 16.5517 86.867 16.5517H82.1972C81.7293 16.5517 81.3498 16.9313 81.3498 17.3992V20.8287C81.3498 21.2966 80.9702 21.6761 80.5023 21.6761H79.646C79.1782 21.6761 78.7986 22.0557 78.7986 22.5236V23.6977C78.7986 24.1655 79.1782 24.5451 79.646 24.5451H81.182C81.6499 24.5451 82.0295 24.1655 82.0295 23.6977V23.0577C82.0295 22.5898 82.4091 22.2102 82.8769 22.2102H85.9092C85.9533 22.2146 87.5291 22.1352 87.4055 22.9782C85.9887 31.8632 78.3175 38.6604 69.0353 38.6604H57.4667C56.7914 38.6604 56.2441 38.1131 56.2441 37.4378V26.3371C56.2441 16.0486 64.5818 7.7109 74.8703 7.7109H87.6571L93.982 7.76828C94.3616 7.76828 94.8118 7.87421 94.8118 8.90703V13.3782C94.8118 13.8681 95.0325 14.411 95.8447 14.411H101.366C101.936 14.411 102.399 14.8745 102.399 15.4439V16.3928C102.399 16.9622 102.863 17.4257 103.432 17.4257H105.484C106.054 17.4257 106.517 16.9622 106.517 16.3928Z"
                    fill="parent"
                />
                <path
                    d="M106.336 7.26466H109.144C109.691 7.26466 110.137 6.82328 110.137 6.27156V3.99846C110.137 3.45115 109.691 3.00977 109.144 3.00977H106.336C105.789 3.00977 105.348 3.45556 105.348 3.99846V6.27156C105.348 6.81887 105.793 7.26466 106.336 7.26466Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M8.05568 55.2741C8.95168 55.2741 9.72851 55.4948 10.3817 55.9362C11.035 56.3776 11.5205 56.9867 11.8339 57.7547H16.1109C15.7004 55.8479 14.8 54.3384 13.4052 53.2173C12.0104 52.1006 10.2493 51.54 8.1263 51.54C6.50202 51.54 5.07637 51.8976 3.84934 52.617C2.6223 53.3365 1.66892 54.3384 1.00244 55.6272C0.331542 56.9205 -0.00390625 58.3902 -0.00390625 60.0454C-0.00390625 61.7006 0.331542 63.1704 1.00244 64.4636C1.66892 65.7569 2.6223 66.7588 3.84934 67.4782C5.07637 68.1933 6.50202 68.5508 8.1263 68.5508C10.2537 68.5508 12.0149 67.9902 13.4052 66.8603C14.8 65.7348 15.7004 64.2253 16.1109 62.3362H11.8339C11.5205 63.0909 11.035 63.6912 10.3817 64.1326C9.72851 64.574 8.95168 64.7947 8.05568 64.7947C6.87278 64.7947 5.9194 64.3665 5.19554 63.5058C4.47168 62.6451 4.10975 61.4931 4.10975 60.0454C4.10975 58.5977 4.47168 57.4369 5.19554 56.5718C5.9194 55.7067 6.87278 55.2741 8.05568 55.2741Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M23.2958 51.8223L17.1738 68.4093H21.4287L22.466 65.3594H28.7291L29.7708 68.4093H34.0698L27.9523 51.8223H23.2958ZM23.5076 62.2874L25.6086 56.1434L27.6875 62.2874H23.5076Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M47.6251 60.0893C48.296 59.2375 48.627 58.2091 48.627 56.9953C48.627 55.9713 48.3975 55.062 47.9429 54.2675C47.4883 53.4731 46.7997 52.8463 45.8861 52.3873C44.9724 51.9326 43.8557 51.7031 42.5316 51.7031H35.6328V68.4093H39.6714V62.102H40.5939L44.2088 68.4093H48.6756L44.7076 61.8416C45.9832 61.5282 46.9542 60.9456 47.6251 60.0937M43.9528 58.8049C43.5732 59.158 42.9862 59.3346 42.1829 59.3346H39.6803V55.1062H42.1829C42.9729 55.1062 43.5556 55.296 43.944 55.6755C44.328 56.0507 44.5222 56.5715 44.5222 57.2336C44.5222 57.8957 44.3324 58.4518 43.9528 58.8049Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M60.6761 59.8073C61.5412 59.6043 62.243 59.1673 62.777 58.4964C63.3111 57.8299 63.5803 56.9869 63.5803 55.9806C63.5803 54.6079 63.1169 53.553 62.1856 52.8159C61.2543 52.0744 59.9434 51.708 58.2397 51.708H50.6523V68.4142H58.425C60.2038 68.4142 61.5721 68.0126 62.5254 67.2093C63.4788 66.4059 63.9555 65.2892 63.9555 63.8548C63.9555 62.8793 63.6598 62.0186 63.0683 61.2815C62.4769 60.54 61.678 60.0545 60.6717 59.8161M54.6954 54.9477H57.2951C58.7296 54.9477 59.4446 55.548 59.4446 56.7441C59.4446 57.9403 58.7428 58.5406 57.3437 58.5406H54.6954V54.9477ZM57.5776 65.1304H54.6954V61.3742H57.5555C58.2794 61.3742 58.8399 61.5419 59.2328 61.8818C59.6256 62.2217 59.8242 62.6895 59.8242 63.2898C59.8242 64.5212 59.0739 65.1348 57.5776 65.1348"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M78.3187 52.5414C77.0343 51.8087 75.6042 51.4424 74.0285 51.4424C72.4528 51.4424 71.0492 51.8087 69.7648 52.5414C68.4804 53.2741 67.4652 54.2893 66.7149 55.5913C65.9645 56.889 65.5938 58.3588 65.5938 59.9963C65.5938 61.6338 65.9689 63.108 66.7149 64.4145C67.4608 65.721 68.4804 66.7406 69.7648 67.4733C71.0492 68.206 72.4704 68.5723 74.0285 68.5723C75.5866 68.5723 77.0078 68.206 78.2922 67.4733C79.5766 66.7406 80.5918 65.721 81.3422 64.4145C82.0925 63.108 82.4633 61.6338 82.4633 59.9963C82.4633 58.3588 82.0925 56.889 81.351 55.5913C80.6095 54.2937 79.5987 53.2741 78.3143 52.5414M77.1667 63.492C76.3766 64.3571 75.3306 64.7941 74.0241 64.7941C72.7176 64.7941 71.6495 64.3616 70.8682 63.492C70.087 62.6269 69.6986 61.4617 69.6986 59.9963C69.6986 58.5309 70.087 57.3436 70.8682 56.4873C71.6495 55.6311 72.7 55.1985 74.0241 55.1985C75.3482 55.1985 76.3987 55.6311 77.18 56.4962C77.9612 57.3613 78.3496 58.5265 78.3496 59.9919C78.3496 61.4573 77.9568 62.6225 77.1667 63.4876"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M95.533 62.0535L88.7755 51.7031H84.7324V68.4093H88.7755V58.1561L95.533 68.4093H99.5716V51.7031H95.533V62.0535Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M112.065 13.6035H114.255C114.868 13.6035 115.367 13.1048 115.367 12.4913V10.792C115.367 10.1784 114.868 9.67969 114.255 9.67969H112.065C111.452 9.67969 110.953 10.1784 110.953 10.792V12.4913C110.953 13.1048 111.452 13.6035 112.065 13.6035Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M106.517 16.3928V14.7994C106.517 14.2301 106.054 13.7666 105.484 13.7666H103.957C103.388 13.7666 102.924 13.3032 102.924 12.7338V8.23172C102.924 7.66234 102.461 7.1989 101.892 7.1989H96.2684C95.2532 7.1989 95.368 6.45297 95.368 6.26759V1.41683C95.368 0.635586 94.7324 0 93.9511 0H74.8703C60.3224 0 48.5332 11.7892 48.5332 26.3371V44.9501C48.5332 45.7313 49.1688 46.3669 49.95 46.3669H69.0353C82.462 46.3669 93.5318 36.3211 95.1561 23.3357C95.2002 22.1705 94.3484 22.0646 94.11 22.0646H94.0791H94.0482H88.5089C88.3236 22.0646 88.147 21.9851 88.0278 21.8394C87.8954 21.6717 87.7498 21.4025 87.7144 21.0052V17.3992C87.7144 17.1873 87.6306 17.0019 87.5026 16.8519C87.3481 16.6709 87.123 16.5517 86.867 16.5517H82.1972C81.7293 16.5517 81.3498 16.9313 81.3498 17.3992V20.8287C81.3498 21.2966 80.9702 21.6761 80.5023 21.6761H79.646C79.1782 21.6761 78.7986 22.0557 78.7986 22.5236V23.6977C78.7986 24.1655 79.1782 24.5451 79.646 24.5451H81.182C81.6499 24.5451 82.0295 24.1655 82.0295 23.6977V23.0577C82.0295 22.5898 82.4091 22.2102 82.8769 22.2102H85.9092C85.9533 22.2146 87.5291 22.1352 87.4055 22.9782C85.9887 31.8632 78.3175 38.6604 69.0353 38.6604H57.4667C56.7914 38.6604 56.2441 38.1131 56.2441 37.4378V26.3371C56.2441 16.0486 64.5818 7.7109 74.8703 7.7109H87.6571L93.982 7.76828C94.3616 7.76828 94.8118 7.87421 94.8118 8.90703V13.3782C94.8118 13.8681 95.0325 14.411 95.8447 14.411H101.366C101.936 14.411 102.399 14.8745 102.399 15.4439V16.3928C102.399 16.9622 102.863 17.4257 103.432 17.4257H105.484C106.054 17.4257 106.517 16.9622 106.517 16.3928Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M106.336 7.26466H109.144C109.691 7.26466 110.137 6.82328 110.137 6.27156V3.99846C110.137 3.45115 109.691 3.00977 109.144 3.00977H106.336C105.789 3.00977 105.348 3.45556 105.348 3.99846V6.27156C105.348 6.81887 105.793 7.26466 106.336 7.26466Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M107.048 54.1616C107.949 53.6187 108.964 53.345 110.063 53.345C111.524 53.345 112.76 53.6893 113.74 54.369C114.72 55.0488 115.43 55.9757 115.854 57.1232L115.907 57.2689H118.109L118.017 56.982C117.469 55.243 116.512 53.857 115.174 52.8683C113.832 51.8752 112.12 51.3721 110.081 51.3721C108.518 51.3721 107.106 51.7428 105.879 52.4799C104.652 53.217 103.685 54.2587 103.005 55.5828C102.33 56.8981 101.99 58.4209 101.99 60.1026C101.99 61.7842 102.335 63.2805 103.005 64.587C103.681 65.9023 104.647 66.9439 105.874 67.6899C107.101 68.4314 108.518 68.811 110.076 68.811C112.115 68.811 113.828 68.3078 115.17 67.3147C116.507 66.3216 117.465 64.9357 118.012 63.201L118.105 62.9141H115.903L115.85 63.0598C115.426 64.2074 114.715 65.1343 113.731 65.814C112.751 66.4937 111.515 66.838 110.054 66.838C108.951 66.838 107.936 66.5643 107.04 66.0215C106.139 65.4786 105.424 64.6929 104.912 63.6866C104.396 62.6758 104.131 61.4752 104.131 60.1114C104.131 58.7475 104.391 57.5426 104.912 56.5274C105.424 55.5166 106.139 54.7266 107.04 54.1837"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M125.577 51.9062L119.266 68.6257H121.512L123.026 64.5606H130.305L131.792 68.6257H134.061L127.749 51.9062H125.577ZM129.642 62.7995H123.657L126.672 54.6781L129.642 62.7995Z"
                    fill="parent"
                />
                <path
                    style={{ transition: ".3s" }}
                    opacity={textOpacity}
                    d="M137.953 66.8648V51.5312H135.834V68.6303H144V66.8648H137.953Z"
                    fill="parent"
                />
            </g>
            <defs>
                <clipPath id="clip0_4262_220501">
                    <rect width="144" height="68.7934" fill="parent" />
                </clipPath>
            </defs>
        </svg>
    );
};
