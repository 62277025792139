import { Box, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import { CustomPagination } from "../../components/datagrid/CustomPagination";
import { ReportDataTableType, useReport } from "../../contexts/ReportContext";
import { useAuth } from "../../contexts/UserContext";
import { ReportHistory } from "../../models/TGOReport";
import { ReportService } from "../../service/reportService";
import { createExcelDownloadLink } from "../import-data/ImportData";
import { useAccount } from "../../contexts/AccountContext";
import dayjs, { Dayjs } from "dayjs";
import i18n from "../../i18n";

interface Props {
  reportDataTableType: ReportDataTableType;
}

export const ReportDataTable = (props: Props) => {
  const { t } = useTranslation(["common", "input", "report"], {
    nsMode: "fallback",
  });

  const apiRef = useGridApiRef();

  const { tgoReport, setTgoReport, reportDataTableType } = useReport();

  const { isAdmin } = useAuth();

  const { user } = useAccount();

  const downloadReport = async (id: string) => {
    const result = await ReportService.donwloadReport(
      id,
      reportDataTableType === "summary" ? "summary" : "tgo"
    );
    if (result) {
      createExcelDownloadLink(result.filename, result.excelBase64);

      const temp = [...tgoReport];

      const index = tgoReport.findIndex((e) => e._id === id);

      temp[index].downloadCount = temp[index].downloadCount + 1;

      temp[index].lastDownloadedBy.displayName = user?.displayName ?? "";

      setTgoReport([...temp]);
    }
  };

  const columns: GridColDef<ReportHistory>[] = [
    {
      field: "id",
      headerName: t("ID"),
      minWidth: 80,
      disableColumnMenu: true,
      valueFormatter: (value) =>
        reportDataTableType === "summary" ? `RES-${value}` : `RET-${value}`,
    },
    {
      field: "reportStartDate",
      headerName: t("Report-Period"),
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (value: any, row) => {
        try {
          const start: Dayjs = dayjs(value.substring(0, 10));

          const end: Dayjs = dayjs(row.reportEndDate.substring(0, 10));

          if (start.isSame(end, "month")) {
            return start.locale(i18n.language).format("MMM YYYY");
          } else {
            return `${start.locale(i18n.language).format("MMM YYYY")} -\n${end
              .locale(i18n.language)
              .format("MMM YYYY")}`;
          }
        } catch (error) {
          return "";
        }
      },
      renderCell: (params) => {
        return (
          <Typography variant="text1" sx={{ whiteSpace: "pre-line" }}>
            {params.formattedValue}
          </Typography>
        );
      },
      minWidth: 120,
    },
    {
      field: "reportName",
      headerName: t("Reporting-Name"),
      disableColumnMenu: true,
      flex: 1,
      minWidth: 160,
    },

    {
      field: "reportType",
      headerName: t("Reporting-Type"),
      disableColumnMenu: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "downloadCount",
      headerName: t("Download-Count"),
      disableColumnMenu: true,
      type: "string",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "lastDownloadedBy",
      headerName: t("Last-Downloaded-By"),
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (value, row) => row.lastDownloadedBy.displayName,
      minWidth: 120,
    },
    {
      field: "createdBy",
      headerName: t("Created-By"),
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (value, row) => row.createdBy.displayName,
      minWidth: 120,
    },
    {
      field: "",
      disableColumnMenu: true,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <IconButton
            id={params.row._id}
            key={params.row._id}
            type="button"
            disabled={!isAdmin()}
            sx={{
              backgroundColor: "var(--green)",
            }}
            onClick={() => downloadReport(params.row._id)}
          >
            <img src="/img/document-download.svg" alt="" />
          </IconButton>
        );
      },
    },
  ];

  const { setReportDataTableType } = useReport();

  useEffect(() => {
    setReportDataTableType(props.reportDataTableType);
  }, []);

  return (
    <Box width={"100%"} height={"100%"} maxHeight={window.innerHeight - 53}>
      <DataGrid
        rows={tgoReport}
        columns={columns}
        rowHeight={60}
        disableRowSelectionOnClick
        apiRef={apiRef}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        slots={{
          toolbar: QuickSearchToolbar,
          pagination: CustomPagination,
        }}
        localeText={{
          toolbarExport: t("Export"),
          toolbarQuickFilterPlaceholder: `${t("search")}...`,
          noRowsLabel: t("noRowsLabel"),
          noResultsOverlayLabel: t("noResultsOverlayLabel"),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              variant: "outlined",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        sx={tableStyle()}
      />
    </Box>
  );
};
