import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AddEmissionContext } from "../../../../../contexts/AddEmissionContext";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { TRIP_TYPE } from "../../../../../constants/dropdown";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { NUMBER_REGEX } from "../../../../../constants/form";

const AddBusinessTravel = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { stepTwoForm } = AddEmissionContext();

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required
          name="amount"
          disabled
          label={t("input-amount-of-distance")}
          info={t("Distance-Description")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          name="aux1"
          required
          label={t("trip-type")}
          info={t("trip-type")}
          control={stepTwoForm.control}
          listMenuItem={TRIP_TYPE}
          placeholder={t("Select-Param", {
            param: t("trip-type"),
          })}
          rules={{
            required: t("form-reqired-error", {
              param: t("trip-type"),
            }),
          }}
          defaultValue={null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required
          label={t("Distance-(One-way)")}
          info={t("Distance-(One-way)")}
          control={stepTwoForm.control}
          placeholder={t("Distance-(One-way)")}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Distance-(One-way)"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required
          label={t("Amount-Of-Employees")}
          info={t("Amount-Of-Employees-Description")}
          control={stepTwoForm.control}
          placeholder={t("Amount-Of-Employees")}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Amount-Of-Employees"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>
    </>
  );
};

export default AddBusinessTravel;
