import { Box, Button, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import UsersService from "../../service/usersService";
import alertService from "../../components/alert/alertService";

export type ResetPasswordFormValues = {
  currentpassword: string;
  password: string;
  repassword: string;
};

const ResetPassword = () => {
  const { t } = useTranslation(["common", "account"], { nsMode: "fallback" });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid },
  } = useForm<ResetPasswordFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });

  const onSubmit = async (values: ResetPasswordFormValues) => {
    const result = await UsersService.resetPassword(
      values.currentpassword,
      values.repassword
    );

    if (result) {
      reset();
      alertService.mixin(t("Reset-Password-Success"));
    }
  };

  return (
    <Paper sx={{ padding: "20px" }} elevation={0} variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={"40px"}
        >
          <Typography variant="h1">{t("Reset-Password")}</Typography>

          <Box
            flex={1}
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Grid2
              container
              rowSpacing={"20px"}
              columnSpacing={{ xs: "0px", md: "40px" }}
              sx={{
                width: "100%",
              }}
            >
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  required
                  type="password"
                  label={t("your-password")}
                  placeholder={t("your-password")}
                  name="currentpassword"
                  control={control}
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("your-password"),
                    }),
                  }}
                  defaultValue=""
                />
              </Grid2>
              <Grid2
                xs={12}
                md={6}
                display={{ xs: "none", md: "grid" }}
              ></Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  required
                  type="password"
                  label={t("Your-New-Password")}
                  placeholder={t("Your-New-Password")}
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("Password-must-be-at-least-6-characters"),
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{6,20}$/,
                      message: t("password-fails-to-match-pattern"),
                    },
                  }}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  required
                  type="password"
                  label={t("Confirm-Your-New-Password")}
                  placeholder={t("Confirm-Your-New-Password")}
                  name="repassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("Password-must-be-at-least-6-characters"),
                    validate: () => {
                      if (watch("password") !== watch("repassword")) {
                        return t("password-and-repassword-not-equal");
                      }
                      return true;
                    },
                  }}
                />
              </Grid2>
            </Grid2>
          </Box>

          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!isValid}
          >
            {t("Reset-Password")}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default ResetPassword;
