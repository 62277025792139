import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import ImportFileService, {
  ImportActivitiesHistoryForDataTable,
} from "../service/importFileService";

type ImportFileState = {
  rows: ImportActivitiesHistoryForDataTable[];
  setRows: Dispatch<SetStateAction<ImportActivitiesHistoryForDataTable[]>>;
  downloadData: () => Promise<void>;
};

export const Context = createContext<ImportFileState>({} as ImportFileState);

export const ImportFileProvider = ({ children }: { children: ReactNode }) => {
  const [rows, setRows] = useState<ImportActivitiesHistoryForDataTable[]>([]);

  const downloadData = async () => {
    const result = await ImportFileService.getImportHistories();

    if (result) {
      setRows(result.importActivitiesHistory);
    }
  };

  useEffect(() => {
    downloadData();
  }, []);

  return (
    <Context.Provider value={{ rows, setRows, downloadData }}>
      {children}
    </Context.Provider>
  );
};

export const ImportFileContext = () => useContext(Context);
