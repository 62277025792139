import dayjs from "dayjs";

// Helper function to get the start of a month
function getStartOfMonth(date: dayjs.Dayjs): dayjs.Dayjs {
  return date.startOf("month");
}

// Main function to generate the date list
export function generateDateList(startDateStr: number, endDateStr: number): number[] {
  const startDate = dayjs(startDateStr);
  const endDate = dayjs(endDateStr);

  if (startDate.isSame(endDate, "day")) {
    return [startDate.valueOf()];
  }

  const dateList: number[] = [startDate.valueOf()];
  let current = startDate.startOf("month").add(1, "month");

  while (current.isBefore(endDate, "month")) {
    dateList.push(getStartOfMonth(current).valueOf());
    current = current.add(1, "month");
  }

  dateList.push(endDate.valueOf());
  return dateList;
}
