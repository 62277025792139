import { Container, Box, Typography, Link } from "@mui/material";
import { ReactNode } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LoginLayout.css";
import { LanguageButton } from "../components/languageButton/LanguageButton";
import { t } from "i18next";

interface LayoutProps {
  children: ReactNode;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
};

const LoginLayout = ({ children }: LayoutProps) => {
  return (
    <Container
      style={{
        display: "flex",
        minHeight: "100vh",
        padding: 0,
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 5,
          display: { xs: "none", md: "block" },
          backgroundImage: "url('/img/login/BGImageAuth.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        padding={"60px"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"end"}
          width={"100%"}
          height={"100%"}
          maxWidth={"400px"}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={"end"}
            sx={{
              width: "100%",
              maxWidth: "260px",
              color: "var(--white)",
            }}
          >
            <Slider {...settings}>
              <Box>
                <Typography variant="h1">
                  Emission Management Platform
                </Typography>
              </Box>
              <Box>
                <Typography variant="h1">
                  All-in-One Platform: Measure, Reduce, Offset, Report
                </Typography>
              </Box>
              <Box>
                <Typography variant="h1">
                  Follow GHG Protocol and Comply with ISO 14064 standard
                </Typography>
              </Box>
            </Slider>
            <Box marginTop={"60px"}>
              <Typography variant="h3">{t("Powered-By")}</Typography>
              <img src="/img/Logo-locarb.svg" alt="logo" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              marginTop={"20px"}
              rowGap="8px"
            >
              <Typography variant="h3">{t("Contact-Us")}</Typography>

              <Link
                href="mailto:contact@locarb.green"
                variant="body1"
                color="inherit"
                underline="none"
                target="_blank"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  columnGap={"8px"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M17 21.2769H7C3.35 21.2769 1.25 19.1769 1.25 15.5269V8.52686C1.25 4.87686 3.35 2.77686 7 2.77686H17C20.65 2.77686 22.75 4.87686 22.75 8.52686V15.5269C22.75 19.1769 20.65 21.2769 17 21.2769ZM7 4.27686C4.14 4.27686 2.75 5.66686 2.75 8.52686V15.5269C2.75 18.3869 4.14 19.7769 7 19.7769H17C19.86 19.7769 21.25 18.3869 21.25 15.5269V8.52686C21.25 5.66686 19.86 4.27686 17 4.27686H7Z"
                      fill="white"
                    />
                    <path
                      d="M11.9998 12.8968C11.1598 12.8968 10.3098 12.6368 9.65978 12.1068L6.52978 9.60683C6.20978 9.34683 6.14978 8.87682 6.40978 8.55682C6.66978 8.23682 7.13978 8.17683 7.45978 8.43683L10.5898 10.9368C11.3498 11.5468 12.6398 11.5468 13.3998 10.9368L16.5298 8.43683C16.8498 8.17683 17.3298 8.22682 17.5798 8.55682C17.8398 8.87682 17.7898 9.35683 17.4598 9.60683L14.3298 12.1068C13.6898 12.6368 12.8398 12.8968 11.9998 12.8968Z"
                      fill="white"
                    />
                  </svg>
                  {/* contact@locarb.green */}
                  carboncal-support@locarb.green
                </Box>
              </Link>

              <Link
                href="https://locarb.green/"
                variant="body1"
                color="inherit"
                underline="none"
                target="_blank"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  columnGap={"8px"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M12 22.7769C6.07 22.7769 1.25 17.9569 1.25 12.0269C1.25 6.09686 6.07 1.27686 12 1.27686C17.93 1.27686 22.75 6.09686 22.75 12.0269C22.75 17.9569 17.93 22.7769 12 22.7769ZM12 2.77686C6.9 2.77686 2.75 6.92686 2.75 12.0269C2.75 17.1269 6.9 21.2769 12 21.2769C17.1 21.2769 21.25 17.1269 21.25 12.0269C21.25 6.92686 17.1 2.77686 12 2.77686Z"
                      fill="white"
                    />
                    <path
                      d="M9.00023 21.7769H8.00023C7.59023 21.7769 7.25023 21.4369 7.25023 21.0269C7.25023 20.6169 7.57023 20.2869 7.98023 20.2769C6.41023 14.9169 6.41023 9.13686 7.98023 3.77686C7.57023 3.76686 7.25023 3.43686 7.25023 3.02686C7.25023 2.61686 7.59023 2.27686 8.00023 2.27686H9.00023C9.24023 2.27686 9.47023 2.39686 9.61023 2.58686C9.75023 2.78686 9.79023 3.03686 9.71023 3.26686C7.83023 8.91686 7.83023 15.1369 9.71023 20.7969C9.79023 21.0269 9.75023 21.2769 9.61023 21.4769C9.47023 21.6569 9.24023 21.7769 9.00023 21.7769Z"
                      fill="white"
                    />
                    <path
                      d="M15.0004 21.777C14.9204 21.777 14.8404 21.767 14.7604 21.737C14.3704 21.607 14.1504 21.177 14.2904 20.787C16.1704 15.137 16.1704 8.91704 14.2904 3.25704C14.1604 2.86704 14.3704 2.43704 14.7604 2.30704C15.1604 2.17704 15.5804 2.38704 15.7104 2.77704C17.7004 8.73704 17.7004 15.297 15.7104 21.247C15.6104 21.577 15.3104 21.777 15.0004 21.777Z"
                      fill="white"
                    />
                    <path
                      d="M12 17.2267C9.21 17.2267 6.43 16.8367 3.75 16.0467C3.74 16.4467 3.41 16.7767 3 16.7767C2.59 16.7767 2.25 16.4367 2.25 16.0267V15.0267C2.25 14.7867 2.37 14.5567 2.56 14.4167C2.76 14.2767 3.01 14.2367 3.24 14.3167C8.89 16.1967 15.12 16.1967 20.77 14.3167C21 14.2367 21.25 14.2767 21.45 14.4167C21.65 14.5567 21.76 14.7867 21.76 15.0267V16.0267C21.76 16.4367 21.42 16.7767 21.01 16.7767C20.6 16.7767 20.27 16.4567 20.26 16.0467C17.57 16.8367 14.79 17.2267 12 17.2267Z"
                      fill="white"
                    />
                    <path
                      d="M20.9998 9.77672C20.9198 9.77672 20.8398 9.76672 20.7598 9.73672C15.1098 7.85672 8.87978 7.85672 3.22978 9.73672C2.82978 9.86672 2.40978 9.65672 2.27978 9.26672C2.15978 8.86672 2.36978 8.44672 2.75978 8.31672C8.71978 6.32672 15.2798 6.32672 21.2298 8.31672C21.6198 8.44672 21.8398 8.87672 21.6998 9.26672C21.6098 9.57672 21.3098 9.77672 20.9998 9.77672Z"
                      fill="white"
                    />
                  </svg>
                  https://locarb.green/
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 7,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "100vh",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: "60px 60px 0px 60px",
          }}
        >
          {/* Language */}
          <Box
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
            }}
          >
            <LanguageButton />
          </Box>

          {children}
        </Box>

        <footer>
          <Box textAlign={"center"} paddingBottom={"60px"}>
            <Typography
              textAlign={"center"}
              variant="text1"
              color={"var(--gray1)"}
            >
              {t("copyright")}
            </Typography>
          </Box>
        </footer>
      </Box>
    </Container>
  );
};

export default LoginLayout;
