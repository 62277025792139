const NumberFormatterUtils = {
  numberFormat: (value: number): string => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(value);
  },
};

export default NumberFormatterUtils;
