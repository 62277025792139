import { BorderBottom, BorderTop } from "@mui/icons-material";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export interface TabMenuData {
  name: string;
  pathname: string;
  disabled?: boolean;
}

type TabMenuDataType = "main" | "sub";

interface Props {
  tabMenuData: TabMenuData[];
  type?: TabMenuDataType;
}

export const TabMenu = (props: Props) => {
  const { pathname } = useLocation();

  const { t } = useTranslation(["common", "input", "account", "organization"], {
    nsMode: "fallback",
  });

  const firstItemStyle = {
    borderRadius: "50px 0px 0px 50px !important",
    border: props.type === "sub" ? "1px solid var(--gray5)" : undefined,
    borderWidth: props.type === "sub" ? "1px 0px 1px 1px" : undefined,
  };

  const midItemStyle = {
    borderRadius: "0 !important",
    border: props.type === "sub" ? "1px solid var(--gray5)" : undefined,
    borderWidth: props.type === "sub" ? "1px 0px 1px 0px" : undefined,
  };

  const lastItemStyle = {
    borderRadius: "0px 50px 50px 0px !important",
    border: props.type === "sub" ? "1px solid var(--gray5)" : undefined,
    borderWidth: props.type === "sub" ? "1px 1px 1px 0px" : undefined,
  };

  return (
    <>
      <Box
        sx={{
          padding:
            props.type === "main" || props.type === undefined
              ? "24px 40px"
              : "0px 0px 24px",
          backgroundColor:
            props.type === "main" || props.type === undefined
              ? "#F2F2F2"
              : "transparent",
        }}
      >
        <Grid
          container
          display={"flex"}
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
        >
          {props.tabMenuData.map((e, index) => (
            <Grid flex="1" key={e.name}>
              {e.disabled ? (
                <Button
                  type="button"
                  variant="contained"
                  color={pathname === e.pathname ? "green" : "white"}
                  disableElevation
                  disabled={e.disabled}
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "9px 0px",
                    borderRadius: {
                      xs:
                        index === 0
                          ? "50px 50px 0px 0px"
                          : index === props.tabMenuData.length - 1
                          ? "0px 0px 50px 50px"
                          : 0,
                      sm:
                        index === 0
                          ? firstItemStyle.borderRadius
                          : index === props.tabMenuData.length - 1
                          ? lastItemStyle.borderRadius
                          : midItemStyle.borderRadius,
                    },
                    border:
                      index === 0
                        ? firstItemStyle.border
                        : index === props.tabMenuData.length - 1
                        ? lastItemStyle.border
                        : midItemStyle.border,
                    borderWidth:
                      index === 0
                        ? firstItemStyle.borderWidth
                        : index === props.tabMenuData.length - 1
                        ? lastItemStyle.borderWidth
                        : midItemStyle.borderWidth,
                  }}
                >
                  <Typography
                    fontWeight={500}
                    alignContent={"center"}
                    variant="h3"
                  >
                    {t(e.name)}
                  </Typography>
                </Button>
              ) : (
                <Link to={e.pathname}>
                  <Button
                    type="button"
                    variant="contained"
                    color={pathname === e.pathname ? "green" : "white"}
                    disableElevation
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: "9px 0px",
                      borderRadius: {
                        xs:
                          index === 0
                            ? "50px 50px 0px 0px"
                            : index === props.tabMenuData.length - 1
                            ? "0px 0px 50px 50px"
                            : 0,
                        sm:
                          index === 0
                            ? firstItemStyle.borderRadius
                            : index === props.tabMenuData.length - 1
                            ? lastItemStyle.borderRadius
                            : midItemStyle.borderRadius,
                      },
                      border:
                        index === 0
                          ? firstItemStyle.border
                          : index === props.tabMenuData.length - 1
                          ? lastItemStyle.border
                          : midItemStyle.border,
                      borderWidth:
                        index === 0
                          ? firstItemStyle.borderWidth
                          : index === props.tabMenuData.length - 1
                          ? lastItemStyle.borderWidth
                          : midItemStyle.borderWidth,
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      alignContent={"center"}
                      variant="h3"
                    >
                      {t(e.name)}
                    </Typography>
                  </Button>
                </Link>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
