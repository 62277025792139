import { Card, CardContent } from "@mui/material";
import OverviewCardDetail from "./OverviewCardDetail";

interface Props {
  title: string;
  info: string;
  text1: string;
  text2?: string;
  icon?: string;
}

const OverviewCard = (props: Props) => {
  return (
    <Card variant="outlined" sx={{ height: "100%", borderRadius: "8px" }}>
      <CardContent sx={{ margin: "8px" }}>
        <OverviewCardDetail
          title={props.title}
          info={props.info}
          text1={props.text1}
          text2={props.text2}
          icon={props.icon}
        />
      </CardContent>
    </Card>
  );
};

export default OverviewCard;
