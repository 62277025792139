import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReportService } from "../service/reportService";
import { ReportHistory } from "../models/TGOReport";

export type ReportDataTableType = "tgo-cfo" | "summary";

export interface ReportState {
  reportDataTableType: ReportDataTableType | undefined;
  setReportDataTableType: Dispatch<
    SetStateAction<ReportDataTableType | undefined>
  >;
  tgoReport: ReportHistory[];
  setTgoReport: Dispatch<SetStateAction<ReportHistory[]>>;
  getReport: () => void;
}

export const ReportContext = createContext<ReportState | undefined>(undefined);

export const useReport = () => {
  const context = useContext(ReportContext);
  if (context === undefined) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};

export function ReportProvider({ children }: { children: ReactNode }) {
  const [reportDataTableType, setReportDataTableType] =
    useState<ReportDataTableType>();

  const [tgoReport, setTgoReport] = useState<ReportHistory[]>([]);

  const getReport = async () => {
    if (reportDataTableType) {
      const result = await ReportService.getReport(
        reportDataTableType === "summary" ? "summary" : "tgo"
      );

      if (result) {
        setTgoReport(result);
      }
    }
  };

  useEffect(() => {
    getReport();
  }, [reportDataTableType]);

  const context = {
    reportDataTableType,
    setReportDataTableType,
    tgoReport,
    setTgoReport,
    getReport,
  };

  return (
    <ReportContext.Provider value={context}>{children}</ReportContext.Provider>
  );
}
