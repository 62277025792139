import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomGridToolbarQuickFilter } from "../../components/datagrid/CustomGridToolbarQuickFilter";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { MultipleAutoComplete } from "../../components/input/MutipleAutoComplete";
import { useForm } from "react-hook-form";

export const QuickSearchToolbar = () => {
  const { control } = useForm();

  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"40px"}
      marginBottom={"40px"}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"self-end"}
        gap={4}
      >
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={"12px"}
          flex={1}
          alignItems={"self-end"}
          width={{ xs: "100%", sm: "auto" }}
        >
          <CustomGridToolbarQuickFilter />

          <GridToolbarContainer
            sx={{ width: "100%", height: "40px", maxWidth: "400px" }}
          >
            <MultipleAutoComplete
              required
              showLabelInput={false}
              name={"subOrganization"}
              control={control}
              rules={{
                required: t("form-reqired-error", {
                  param: t("Sub-Organizations"),
                }),
              }}
              label={t("Sub-Organizations")}
              info={t("Sub-Organizations")}
              placeholder={t("Select-Param", {
                param: t("Sub-Organizations"),
              })}
              listMenuItem={[
                {
                  nameEN: "SME A-Bank",
                  nameTH: "SME A-Bank",
                  value: "SME A-Bank",
                },
                {
                  nameEN: "SME B-Bank",
                  nameTH: "SME B-Bank",
                  value: "SME B-Bank",
                },
                {
                  nameEN: "SME C-Bank",
                  nameTH: "SME C-Bank",
                  value: "SME C-Bank",
                },
                {
                  nameEN: "SME D-Bank",
                  nameTH: "SME D-Bank",
                  value: "SME D-Bank",
                },
              ]}
              defaultValue={["SME D-Bank", "SME A-Bank"]}
            />
          </GridToolbarContainer>
        </Box>

        <GridToolbarContainer
          sx={{
            padding: 0,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<img src="/img/add-circle.svg" alt="add-circle" />}
            onClick={() => alert("open add")}
          >
            {t("Add-Eco-Efficiency")}
          </Button>
        </GridToolbarContainer>
      </Box>
    </Box>
  );
};
