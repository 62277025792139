import { Avatar, Box, Typography } from "@mui/material";
import InfoPopper from "../popper/InfoPopper";

interface Props {
    title: string;
    info?: string;
    text1: string;
    text2?: string;
    icon?: string;
}

const OverviewCardDetail = (props: Props) => {
    return (
        <Box display={"flex"} flexDirection={"row"} gap={"2px"} justifyContent={"space-between"}>
            <Box>
                <Typography variant="body1" noWrap>
                    {props.title}
                    {props.info && <InfoPopper text={props.info} />}
                </Typography>
                <Typography variant="h2">{props.text1}</Typography>
                {props.text2 && <Typography variant="body1">{props.text2}</Typography>}
            </Box>
            {props.icon && <Avatar src={props.icon} sx={{ width: "33px", height: "33px" }} />}
        </Box>
    );
};

export default OverviewCardDetail
