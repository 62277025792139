import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { SEPTIC_TANK_TYPE } from "../../../../../constants/dropdown";
import { AddEmissionContext } from "../../../../../contexts/AddEmissionContext";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { NUMBER_REGEX } from "../../../../../constants/form";

const AddSepticTank = () => {
  const { t } = useTranslation(["common", "overview", "input"], {
    nsMode: "fallback",
  });

  const { stepTwoForm } = AddEmissionContext();

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required
          name="amount"
          disabled
          label={t("amount-of-population-load")}
          info={t("amount-of-population-load")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <CustomAutoComplete
          required
          label={t("Septic-Tank-Type")}
          info={t("Septic-Tank-Type")}
          control={stepTwoForm.control}
          name="aux1"
          listMenuItem={SEPTIC_TANK_TYPE}
          placeholder={t("Select-Param", {
            param: t("Septic-Tank-Type"),
          })}
          rules={{
            required: t("form-reqired-error", {
              param: t("Septic-Tank-Type"),
            }),
          }}
          defaultValue={null}
        />
      </Grid> */}

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required
          label={t("Person-On-site")}
          info={t("Amount-of-Persons-Description")}
          control={stepTwoForm.control}
          placeholder={t("Person-On-site")}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Person-On-site"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required
          label={t("On-site-Schedule")}
          info={t("On-site-Schedule")}
          control={stepTwoForm.control}
          placeholder={t("On-site-Schedule")}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("On-site-Schedule"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux4"
          required
          label={t("Leave-day")}
          info={t("Leave-day")}
          control={stepTwoForm.control}
          placeholder={t("Leave-day")}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Leave-day"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid> */}
    </>
  );
};

export default AddSepticTank;
