import { Box, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import OverviewCard from "../../../components/dashboard/OverviewCard";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DashbroadContext } from "../../../contexts/DashbroadContext";
import NumberFormatterUtils from "../../../utils/numberFormatterUtils";

const Others = () => {
    const context = useContext(DashbroadContext);
    const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });

    const scope = "Outside-of-Scope";

    const scope1 = context.filteredEmissions.filter((item) => item.scope === scope);
    const emissionSum = context.filteredEmissions.map((item) => item.value).reduce((a, b) => a + b, 0);
    const scope1Sum = scope1.map((item) => item.value).reduce((a, b) => a + b, 0);

    return (
        <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
            {/* Overview Card */}
            <Grid container spacing={"20px"}>
                <Grid xs={12} sm="auto">
                    <OverviewCard
                        title={t("Outside-of-Scope")}
                        info={t("Outside-of-Scope.Summary-Description")}
                        text1={`${emissionSum === 0 ? 0 : NumberFormatterUtils.numberFormat((scope1Sum / emissionSum) * 100)} %`}
                        text2={`${NumberFormatterUtils.numberFormat(scope1Sum)} / ${NumberFormatterUtils.numberFormat(emissionSum)} tCO2e`}
                        icon="/img/other.svg"
                    />
                </Grid>
                <Grid xs={12} sm>
                    <Card variant="outlined" sx={{ height: "100%", borderRadius: "8px" }}>
                        <CardContent sx={{ margin: "8px", px: { sm: "40px" } }}>
                            <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
                                <Typography variant="h2">{t("Select-Category")}</Typography>
                                <Box>
                                    <FormControl sx={{ width: "100%", maxWidth: "260px" }} size="small">
                                        <InputLabel>{t("Category")}</InputLabel>
                                        <Select label={t("Category")}>
                                            <MenuItem>{t("All")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Others;
