import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AddEmissionContext } from "../../../../../contexts/AddEmissionContext";
import { AmountInput } from "./InputAmount";

const AddEnergy = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { stepTwoForm } = AddEmissionContext();

  return (
    <>
      <Grid item xs={12} md={6}>
        <AmountInput
          label={t("Amount-of-Energy")}
          info={t("Amount-of-Energy")}
          control={stepTwoForm.control}
        />
      </Grid>
    </>
  );
};

export default AddEnergy;
