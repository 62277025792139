import { Box, FormControl, Grid, IconButton, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import InfoPopper from "../../../../components/popper/InfoPopper";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CustomInput from "../../../../components/input/CustomInput";
import FileUtils, { FilePreview } from "../../../../utils/fileUtlis";
import alertService from "../../../../components/alert/alertService";
import { MAX_UPLOAD_FILE_SIZE } from "../../../../constants/form";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../contexts/AddEmissionContext";

const AddEmissionSectionTwo: React.FC = () => {
  const { t } = useTranslation(["common", "overview"], {
    nsMode: "fallback",
  });

  const [files, setFiles] = useState<FilePreview[]>([]);

  const { stepTwoForm, emissionMode } = AddEmissionContext();

  const previewFile = () => {
    window.open(files[0].preview);
  };

  const deleteFile = () => {
    setFiles([]);
    stepTwoForm.setValue("attachment", "");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropRejected(fileRejections, event) {
      if (fileRejections[0].errors[0].code === "file-too-large") {
        return alertService.error(
          t("maximum-file-size", {
            size: (MAX_UPLOAD_FILE_SIZE / 1e6).toFixed(0),
          })
        );
      } else {
        return alertService.error(t("Invalid-File-Type"));
      }
    },
    async onDrop(acceptedFiles, fileRejections, event) {
      const filePreviews = acceptedFiles.map((file) => ({
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
      }));

      setFiles(filePreviews);

      await FileUtils.getBase64(acceptedFiles[0])
        .then((res) => {
          stepTwoForm.setValue("attachment", res as string);
        })
        .catch((err) => {
          setFiles([]);
          stepTwoForm.setValue("attachment", "");
        });
    },
    maxFiles: 1,
    maxSize: MAX_UPLOAD_FILE_SIZE,
    multiple: false,
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
  });

  useEffect(() => {
    try {
      const attachment = stepTwoForm.getValues("attachment") ?? "";

      if (attachment !== "") {
        const file: File = FileUtils.base64ToFile(attachment);

        const filePreviews = {
          name: file.name,
          size: file.size,
          preview: URL.createObjectURL(file),
        };

        setFiles([filePreviews]);
      }
    } catch (error) {
      alertService.error(t("Cannot-Preview-Evidence"));
    }
  }, []);

  return (
    <>
      {emissionMode === EmissionMode.AddEmission ? (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="remark"
                defaultValue=""
                control={stepTwoForm.control}
                rules={{
                  required: false,
                  maxLength: 255,
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    label={t("Remark")}
                    info={t("Remark")}
                    placeholder={t("Remark")}
                    value={value}
                    handleOnChange={onChange}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Controller
            name="attachment"
            defaultValue=""
            control={stepTwoForm.control}
            render={({ field: { onChange, value } }) => (
              <input value={value} onChange={onChange} type="hidden" />
            )}
          />
          <Grid item xs={12} minHeight={"110px"}>
            <Typography
              variant="h3"
              color={"var(--dark)"}
              sx={{
                marginBottom: "8px",
              }}
            >
              {t("Evidence-File")}

              <InfoPopper text={t("Attachment-Description")} />
            </Typography>

            {files.length > 0 ? (
              <Box
                display={"flex"}
                columnGap={"14px"}
                alignItems={"center"}
                sx={{
                  border: "1px solid var(--gray4)",
                  padding: "8px",
                  borderRadius: "8px",
                }}
                width={{ xs: "auto", md: "40%" }}
              >
                {files.map((file, index) => (
                  <Typography
                    variant="text1"
                    flex={1}
                    style={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                    key={file.name}
                  >
                    {file.name}
                  </Typography>
                ))}
                <IconButton
                  color="info"
                  aria-label="previewFile"
                  onClick={previewFile}
                  sx={{
                    backgroundColor: "var(--Scope-1-Refrigerant)",
                    "&:hover": {
                      backgroundColor: "var(--Scope-1-Refrigerant)",
                      opacity: 0.75,
                    },
                  }}
                >
                  <img src="/img/eye.svg" alt="preview" />
                </IconButton>
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={deleteFile}
                  sx={{
                    backgroundColor: "var(--status-rejected-failed-bg)",
                    "&:hover": {
                      backgroundColor: "var(--status-rejected-failed-bg)",
                      opacity: 0.75,
                    },
                  }}
                >
                  <img src="/img/delete.svg" alt="icon-delete" />
                </IconButton>
              </Box>
            ) : (
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed grey",
                  borderRadius: "8px",
                  padding: "16px",
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                <input {...getInputProps()} />

                <Box color={"var(--gray4)"}>
                  <Typography variant="body1">
                    <Trans
                      t={t}
                      i18nKey="drag-drop-or-browse"
                      components={{
                        span: <span style={{ color: "var(--green)" }} />,
                      }}
                    />
                  </Typography>
                  <Typography variant="body1">
                    <Trans
                      t={t}
                      i18nKey="maximum-file-size"
                      values={{ size: (MAX_UPLOAD_FILE_SIZE / 1e6).toFixed(0) }}
                    />
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default AddEmissionSectionTwo;
