export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  try {
    const split = name.split(" ");
    let children;
    if (split.length > 1) {
      children = `${split[0][0]}${split[1][0]}`;
    } else {
      children = `${split[0][0]}${split[0][1]}`;
    }
    return {
      sx: {
        color: "var(--gray4)",
        bgcolor: "var(--gray3)",
      },
      children: children,
    };
  } catch (error) {
    return {
      sx: {
        color: "var(--gray4)",
        bgcolor: "var(--gray3)",
      },
      children: "Avatar",
    };
  }
}
