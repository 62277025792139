import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Scopes } from "../../models/Activities";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

interface Props {
  text: string;
  scope: Scopes;
  unit: string;
}

export default function TablePopper(props: Props) {
  const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography marginRight={1} variant="text1">
        {props.text} {props.unit}
      </Typography>
      <img src="/img/arrow-circle-up.svg" alt="tooltip down" />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={props.text === "0.00" ? false : open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        // disableRestoreFocus
        disableAutoFocus
      >
        <Box display="flex" flexDirection="column" padding={1}>
          {props.scope.s0 ? (
            <Typography
              display={"flex"}
              flexDirection={"row"}
              columnGap={2}
              justifyContent={"space-between"}
              variant="text1"
            >
              <span>{t("Outside-of-Scope")}:</span>
              <span>
                {`${NumberFormatterUtils.numberFormat(props.scope.s0)} ${
                  props.unit
                }`}
              </span>
            </Typography>
          ) : null}
          {props.scope.s1 ? (
            <Typography
              display={"flex"}
              flexDirection={"row"}
              columnGap={2}
              justifyContent={"space-between"}
              variant="text1"
            >
              <span>{`${t("Scope")} 1 `}:</span>
              <span>
                {`${NumberFormatterUtils.numberFormat(props.scope.s1)} ${
                  props.unit
                }`}
              </span>
            </Typography>
          ) : null}
          {props.scope.s2 ? (
            <Typography
              display={"flex"}
              flexDirection={"row"}
              columnGap={2}
              justifyContent={"space-between"}
              variant="text1"
            >
              <span>{`${t("Scope")} 2 `}:</span>
              <span>
                {`${NumberFormatterUtils.numberFormat(props.scope.s2)} ${
                  props.unit
                }`}
              </span>
            </Typography>
          ) : null}
          {process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true" &&
          props.scope.s3 ? (
            <Typography
              display={"flex"}
              flexDirection={"row"}
              columnGap={2}
              justifyContent={"space-between"}
              variant="text1"
            >
              <Box>{`${t("Scope")} 3 `}:</Box>
              <Box>
                {`${NumberFormatterUtils.numberFormat(props.scope.s3)} ${
                  props.unit
                }`}
              </Box>
            </Typography>
          ) : null}
        </Box>
      </Popover>
    </Box>
  );
}
