import { Popper, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

interface Props {
  text: string;
}

const InfoPopper = (props: Props) => {
  const [infoAnchorEl, setInfoAnchorEl] = useState<null | SVGSVGElement>(null);
  const openInfo = Boolean(infoAnchorEl);

  const onInfoMouseEnter = (event: React.MouseEvent<SVGSVGElement>) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const onInfoMouseLeave = () => {
    setInfoAnchorEl(null);
  };

  return (
    <span>
      <InfoIcon
        color="disabled"
        sx={{ height: "11px" }}
        onMouseEnter={onInfoMouseEnter}
        onMouseLeave={onInfoMouseLeave}
      />
      <Popper
        open={openInfo}
        anchorEl={infoAnchorEl}
        placement="top-start"
        sx={{
          zIndex: 2000,
        }}
      >
        <Box display={"flex"} marginBottom={"7px"}>
          <Box
            width={"40px"}
            bgcolor={"var(--green)"}
            alignContent={"center"}
            textAlign={"center"}
            borderRadius={"4px 0 0 4px"}
          >
            <InfoIcon color="white" sx={{ height: "11px" }} />
          </Box>
          <Box
            bgcolor={"var(--white)"}
            p={"10px 16px 10px 16px"}
            borderRadius={"0 4px 4px 0"}
            maxWidth={"260px"}
          >
            {props.text}
          </Box>
        </Box>
        <img
          style={{ position: "absolute", bottom: 0, left: "5px" }}
          src="/img/tooltip-down.svg"
          alt="tooltip down"
          height={"8px"}
          width={"14px"}
        />
      </Popper>
    </span>
  );
};

export default InfoPopper;
