import { MenuData } from "../components/input/CustomSelect";

const ArrayUtils = {
  filterArrayUniqueByKey: (key: string, array: any[]): any[] => {
    const arrayUniqueByKey = new Map(
      array.map((item) => [item[key], item])
    ).values();
    return Array.from(arrayUniqueByKey);
  },
  convertArrayToMenuData: (
    valueKey: string,
    nameENKey: string,
    nameTHKey: string,
    array: any[]
  ): MenuData[] => {
    const menus: MenuData[] = [];

    array.forEach((i) => {
      menus.push({
        nameTH: i[nameTHKey],
        nameEN: i[nameENKey],
        value: i[valueKey],
      });
    });

    return menus;
  },
  arraysEqual: (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((obj, index) => {
      return JSON.stringify(obj) === JSON.stringify(arr2[index]);
    });
  },
};

export default ArrayUtils;
