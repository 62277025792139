import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomPagination } from "../../components/datagrid/CustomPagination";
import {
  tableStyle,
  VisuallyHiddenInput,
} from "../emission/data-table/EmissionDataTableStyle";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import dayjs, { Dayjs } from "dayjs";
import FileUtils from "../../utils/fileUtlis";
import { MAX_UPLOAD_FILE_SIZE } from "../../constants/form";
import alertService from "../../components/alert/alertService";
import ActivitiesService from "../../service/activitiesService";

export const EcoEfficiencyDataGrid = () => {
  const { t, i18n } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  // popper
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openPopper = Boolean(anchorEl);

  const handleOnClickPopper = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    // setRowSelect(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (event?: any) => {
    setAnchorEl(null);
    // if (event.target.id === "review-approval") {
    //   setOpenReviewApproval(true);
    //   setOpenReviewApprovalType("approval");
    // } else if (event.target.id === "view") {
    //   setOpenReviewApproval(true);
    //   setOpenReviewApprovalType("view");
    // } else if (event.target.id === "deleted") {
    //   deleteActivities();
    // }
  };

  const [rows, setRows] = useState<any[]>([
    {
      id: 1,
      startDate: dayjs("2023-01-01T00:00:00.000Z".substring(0, 10)),
      endDate: dayjs("2023-01-01T00:00:00.000Z".substring(0, 10)),
      suborganizationName: "SME A-Bank",
      siteName: "กรุงเทพฯ",
      productValueIndicator: `loan`,
      productValueIndicatorAmount: 100.0,
      productValueIndicatorUnit: "Bath",
      environmentalImpactIndicators: "scope-1",
      environmentalImpactIndicatorsAmount: 9999.0,
      environmentalImpactIndicatorsUnit: "tC02e",
      ecoEfficiency: "122",
      ecoEfficiencyUnit: "Baht / tC02e",
    },
    {
      id: 2,
      startDate: dayjs("2023-01-01T00:00:00.000Z".substring(0, 10)),
      endDate: dayjs("2023-01-01T00:00:00.000Z".substring(0, 10)),
      suborganizationName: "SME B-Bank",
      siteName: "กรุงเทพฯ",
      productValueIndicator: `loan`,
      productValueIndicatorAmount: 100.0,
      productValueIndicatorUnit: "Bath",
      environmentalImpactIndicators: "scope-1",
      environmentalImpactIndicatorsAmount: 9999.0,
      environmentalImpactIndicatorsUnit: "tC02e",
      ecoEfficiency: "122",
      ecoEfficiencyUnit: "Baht / tC02e",
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      minWidth: 80,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `EE-${value}`,
    },
    {
      field: "startDate",
      headerName: t("Date-of-Activity"),
      disableColumnMenu: true,
      minWidth: 100,
      type: "date",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueFormatter: (value, row) => {
        const start: Dayjs = value;

        const end: Dayjs = row.endDate;

        if (start.isSame(end, "month")) {
          return start.locale(i18n.language).format("MMM YYYY");
        } else {
          return `${start.locale(i18n.language).format("MMM YYYY")} -\n${end
            .locale(i18n.language)
            .format("MMM YYYY")}`;
        }
      },
      renderCell: (params) => {
        return (
          <Typography variant="text1" sx={{ whiteSpace: "pre-line" }}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "suborganizationName",
      headerName: t("Sub-Organizations"),
      headerAlign: "left",
      minWidth: 138,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "siteName",
      headerName: t("Site"),
      headerAlign: "left",
      minWidth: 80,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "productValueIndicator",
      headerName: t("Table-Header-Product-Value-Indicator"),
      headerAlign: "left",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "productValueIndicatorAmount",
      headerName: t("Amount"),
      headerAlign: "right",
      minWidth: 130,
      disableColumnMenu: true,
      type: "number",
    },
    {
      field: "productValueIndicatorUnit",
      headerName: t("Unit"),
      headerAlign: "left",
      minWidth: 50,
      disableColumnMenu: true,
    },
    {
      field: "environmentalImpactIndicators",
      headerName: t("Environmental-Impact-Indicators"),
      headerAlign: "left",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "environmentalImpactIndicatorsAmount",
      headerName: t("Environmental-Impact-Indicators"),
      headerAlign: "right",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      type: "number",
    },
    {
      field: "environmentalImpactIndicatorsUnit",
      headerName: t("Environmental-Impact-Indicators"),
      headerAlign: "left",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "ecoEfficiency",
      headerName: t("Eco-Efficiency"),
      headerAlign: "right",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      type: "number",
    },
    {
      field: "ecoEfficiencyUnit",
      headerName: t("Unit"),
      headerAlign: "left",
      minWidth: 110,
      disableColumnMenu: true,
    },
    {
      field: "attachment",
      headerName: t("Files"),
      sortable: false,
      width: 80,
      align: "center",
      headerAlign: "center",
      getApplyQuickFilterFn: undefined,
      disableColumnMenu: true,
      // valueFormatter: (params: string) => {
      //   try {
      //     const fileName = params.split(":")[0] ?? "";

      //     return fileName;
      //   } catch (error) {
      //     return "";
      //   }
      // },
      renderCell: (params) => {
        const value = params.value ?? "";
        return (
          <>
            {value !== "" ? (
              <IconButton
                component="label"
                key={params.row.id + "-icon-file-saved"}
                onClick={() => {
                  window.open(
                    URL.createObjectURL(FileUtils.base64ToFile(value))
                  );
                }}
              >
                <img src="/img/icon-file-saved.svg" alt="icon-file" />
              </IconButton>
            ) : (
              <IconButton
                component="label"
                key={params.row.id + "-icon-file-unsave"}
              >
                <img src="/img/icon-file-unsave.svg" alt="icon-file" />
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*,application/pdf"
                  key={params.row.id + "-visually-hidden-input"}
                  name={params.row.id + "-visually-hidden-input"}
                  onChange={async (event) => {
                    const file: any = event.currentTarget.files;

                    if (file[0]) {
                      if (file[0].size > MAX_UPLOAD_FILE_SIZE) {
                        await alertService.info(
                          t("Maximum-file-size-exceeded", {
                            size: (MAX_UPLOAD_FILE_SIZE / 1e6).toFixed(0),
                          })
                        );

                        event.target.value = "";
                      } else {
                        FileUtils.getBase64(file[0])
                          .then(async (res) => {
                            // const result =
                            //   await ActivitiesService.editattachment(
                            //     params.row._id,
                            //     res as string,
                            //     year.year()
                            //   );
                            // if (result) {
                            //   alertService.mixin(t("Upload-Evidence-Success"));
                            //   // setUpActivitiesListData(result);
                            // }
                          })
                          .catch((err) => {
                            event.target.value = ""; // clear the file input
                            alertService.error(t("Invalid-File-Type"));
                          });
                      }
                    }
                  }}
                />
              </IconButton>
            )}
          </>
        );
      },
    },
    {
      field: "",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      width: 50,
      getApplyQuickFilterFn: undefined,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key={`${params.row.id}-more`}
            icon={<img src="/img/more.svg" alt="icon-more" />}
            label="more"
            id="basic-button"
            aria-controls={openPopper ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openPopper ? "true" : undefined}
            onClick={(event) => handleOnClickPopper(event, params.row)}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      width={"100%"}
      height={"100%"}
      maxHeight={window.innerHeight - 53 > 440 ? window.innerHeight - 53 : 440}
    >
      <Menu
        anchorEl={anchorEl}
        open={openPopper}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose} id="edit">
          {t("Edit")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="duplicate">
          {t("Duplicate")}
        </MenuItem>

        <Divider />
        <MenuItem
          id="deleted"
          onClick={handleClose}
          sx={{
            color: "var(--red)",
          }}
        >
          {t("Delete")}
        </MenuItem>
      </Menu>

      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={60}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        slots={{
          toolbar: QuickSearchToolbar,
          pagination: CustomPagination,
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: `${t("search")}...`,
          noRowsLabel: t("noRowsLabel"),
          noResultsOverlayLabel: t("noResultsOverlayLabel"),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              variant: "outlined",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        sx={tableStyle()}
      />
    </Box>
  );
};
