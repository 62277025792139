import { useContext } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import OrganizationProfile from "./OrganizationProfile";
import SubOrganizationProfile from "./SubOrganizationProfile";
import { SiteProfile } from "./SiteProfile";

const BusinessProfile = () => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw Error();
  }

  if (
    context.selectedSubOrganization === undefined &&
    context.selectedSite === undefined
  ) {
    return <OrganizationProfile />;
  } else {
    return context.selectedSite !== undefined ? (
      <SiteProfile mode="edit" />
    ) : (
      <SubOrganizationProfile mode="edit" />
    );
  }
};

export default BusinessProfile;
