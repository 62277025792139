// src/utils/secureLocalStorage.tsx
import CryptoJS from "crypto-js";

const secureLocalStorage = {
  setItem: (key: string, value: string) => {
    const encryptedValue = CryptoJS.AES.encrypt(
      value,
      `${process.env.REACT_APP_SECURE_LOCAL_STORAGE_SECRET_KEY}`
    ).toString();
    localStorage.setItem(key, encryptedValue);
  },
  getItem: (key: string): string | null => {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;
    try {
      const bytes = CryptoJS.AES.decrypt(
        encryptedValue,
        `${process.env.REACT_APP_SECURE_LOCAL_STORAGE_SECRET_KEY}`
      );
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedValue;
    } catch (e) {
      console.error("Error decrypting local storage item", e);
      return null;
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
};

export default secureLocalStorage;
