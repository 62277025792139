import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/modal/CustomModal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { InputReactHookForm } from "../../../components/input/InputReactHookForm";
import { CustomAutoComplete } from "../../../components/input/CustomAutoComplete";
import { AccessControlContext } from "../../../contexts/AccessControlContext";
import { CustomSelect2 } from "../../../components/input/CustomSelect2";
import UsersService from "../../../service/usersService";
import alertService from "../../../components/alert/alertService";
import { useAuth } from "../../../contexts/UserContext";

type InviteFormValues = {
  email: string;
  role: string;
  subOrganizations: string;
};

export const InviteMember = () => {
  const { t } = useTranslation(["common", "input", "account", "organization"], {
    nsMode: "fallback",
  });

  const { subOrganizations, buildAllRoleMenuList, setUpAdminAndUsersData } =
    AccessControlContext();

  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const { handleSubmit, reset, control, setValue, watch, clearErrors } =
    useForm<InviteFormValues>({
      mode: "all",
      reValidateMode: "onChange",
    });

  const onSubmit = async (data: InviteFormValues) => {
    let formData: any = {
      email: data.email,
      role: data.role,
    };

    if (data.subOrganizations !== "") {
      formData.suborganizationId = data.subOrganizations;
    }

    const result = await UsersService.invite(formData);

    if (result) {
      onClose();
      alertService.mixin(t("Successfully"));
      setUpAdminAndUsersData(result);
    }
  };

  const { isAdmin } = useAuth();

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        startIcon={<img src="/img/add-circle.svg" alt="add-circle" />}
        onClick={() => setOpen(true)}
        disabled={!isAdmin()}
      >
        {t("Invite-New-Member")}
      </Button>

      <CustomModal
        header={<Typography variant="h1"> {t("Invite-New-Member")}</Typography>}
        body={
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                <InputReactHookForm
                  required
                  rules={{
                    required: t("Must-be-a-valid-email"),
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  }}
                  name={"email"}
                  control={control}
                  label={t("Email")}
                  info={t("Email")}
                  placeholder={t("Email")}
                  defaultValue={""}
                />

                <CustomSelect2
                  required
                  rules={{
                    required: t("Select-Role"),
                    onChange(event) {
                      if (event.target.value === "admin") {
                        setValue("subOrganizations", "");
                        clearErrors("subOrganizations");
                      }
                    },
                  }}
                  control={control}
                  info={t("Role")}
                  label={t("Role")}
                  listMenuItem={buildAllRoleMenuList().splice(
                    1,
                    buildAllRoleMenuList().length
                  )}
                  name="role"
                  placeholder={t("Select-Role")}
                  defaultValue={""}
                />

                {watch("role") === "admin" ? (
                  <Box height={"77.13px"}></Box>
                ) : (
                  <CustomAutoComplete
                    required
                    rules={{
                      required:
                        watch("role") !== "admin"
                          ? t("Please-Select-Organization")
                          : undefined,
                    }}
                    name="subOrganizations"
                    control={control}
                    label={t("Sub-Organizations")}
                    info={t("Sub-Organizations")}
                    placeholder={t("Select-Param", {
                      param: t("Sub-Organizations"),
                    })}
                    listMenuItem={subOrganizations.map((e) => {
                      return { nameEN: e.name, nameTH: e.name, value: e.id };
                    })}
                  />
                )}

                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  {t("Invite")}
                </Button>
              </Box>
            </form>
          </>
        }
        onClose={onClose}
        open={open}
        maxWidth={"426px"}
      />
    </>
  );
};
