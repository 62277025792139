import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { MenuData } from "./CustomSelect";
import LabelInput from "./LabelInput";
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { useEffect, useState } from "react";
import { CustomFormHelperText } from "./CustomFormHelperText";

interface Props {
  name: string;
  control: Control<any, any>;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  label: string;
  info: string;
  placeholder: string;
  listMenuItem: MenuData[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: any;
}

export const SelectCheckmarks = (props: Props) => {
  const [inputValue, setInputValue] = useState<string[]>([]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setInputValue(value);
  };

  useEffect(() => {
    setInputValue([...props.defaultValue]);
  }, []);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => {
        return (
          <>
            <FormControl fullWidth>
              <Box
                sx={{
                  marginBottom: "8px",
                }}
              >
                <LabelInput
                  label={props.label}
                  info={props.info}
                  required={props.required}
                />
              </Box>
              <Select
                id={props.name}
                key={props.name}
                multiple
                value={inputValue}
                onChange={(event) => {
                  onChange(event);
                  handleChange(event);
                }}
                renderValue={(selected) => {
                  return props.listMenuItem
                    .filter((option) => selected.includes(option.value))
                    .map((option) => option.nameEN)
                    .join(", ");
                }}
                error={error ? true : false}
              >
                {props.listMenuItem.map((i) => {
                  return (
                    <MenuItem
                      key={i.value}
                      value={i.value}
                      sx={{
                        "&.Mui-selected:not(.Mui-disabled)": {
                          backgroundColor: "transparent",
                          color: "var(--white)",
                          ".MuiListItemText-primary": {
                            color: "var(--dark)",
                          },
                          "&.MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "var(--green3)",
                          },
                        },
                      }}
                    >
                      <Checkbox
                        checked={inputValue.includes(i.value) ? true : false}
                      />
                      <ListItemText primary={i.nameEN} color="var(--dark)" />
                    </MenuItem>
                  );
                })}
              </Select>

              <CustomFormHelperText error={error} />
            </FormControl>
          </>
        );
      }}
    />
  );
};
