export const REPORT_GROUP = [
  {
    scope: "Scope-1",
    id: "Stationary-Combustion",
    title: "Stationary-Combustion",
    colors: [
      "var(--Scope-1-Stationary-Combustion-25)",
      "var(--Scope-1-Stationary-Combustion)",
    ],
  },
  {
    scope: "Scope-1",
    id: "Mobile-Combustion-On-road",
    title: "Mobile-Combustion-On-road",
    colors: [
      "var(--Scope-1-Mobile-Combustion-On-road-25)",
      "var(--Scope-1-Mobile-Combustion-On-road)",
    ],
  },
  {
    scope: "Scope-1",
    id: "Mobile-Combustion-Off-Road",
    title: "Mobile-Combustion-Off-Road",
    colors: [
      "var(--Scope-1-Mobile-Combustion-Off-Road-25)",
      "var(--Scope-1-Mobile-Combustion-Off-Road)",
    ],
  },
  {
    scope: "Scope-1",
    // id: "CH4-Wastewater-Treatment",
    id: "CH4-from-Wastewater-Treatment",
    title: "CH4-Wastewater-Treatment",
    colors: [
      "var(--Scope-1-CH4-Wastewater-Treatment-25)",
      "var(--Scope-1-CH4-Wastewater-Treatment)",
    ],
  },
  {
    scope: "Scope-1",
    id: "Waste-Disposal",
    title: "Waste-Disposal",
    colors: [
      "var(--Scope-1-Waste-Disposal-25)",
      "var(--Scope-1-Waste-Disposal)",
    ],
  },
  {
    scope: "Scope-1",
    id: "Refrigerant",
    title: "Refrigerant",
    colors: ["var(--Scope-1-Refrigerant-25)", "var(--Scope-1-Refrigerant)"],
  },
  {
    scope: "Scope-1",
    id: "CH4-from-septic-tank",
    title: "CH4-Septic-Tank",
    colors: [
      "var(--Scope-1-CH4-Septic-Tank-25)",
      "var(--Scope-1-CH4-Septic-Tank)",
    ],
  },
  {
    scope: "Scope-1",
    id: "Fire-Extinguisher",
    title: "Fire-Extinguisher",
    colors: [
      "var(--Scope-1-Fire-Extinguisher-25)",
      "var(--Scope-1-Fire-Extinguisher)",
    ],
  },

  {
    scope: "Scope-1",
    id: "Fertilizer",
    title: "Fertilizer",
    colors: ["var(--Scope-1-Fertilizer-25)", "var(--Scope-1-Fertilizer)"],
  },
  {
    scope: "Scope-1",
    id: "Other-Fugitive-Emission",
    title: "Other-Fugitive-Emission",
    colors: [
      "var(--Scope-1-Other-Fugitive-Emission-25)",
      "var(--Scope-1-Other-Fugitive-Emission)",
    ],
  },
  // {
  //   scope: "Scope-1",
  //   id: "CH4-from-Wastewater-Treatment",
  //   title: "CH4-Wastewater-Treatment",
  //   colors: [
  //     "var(--Scope-1-CH4-Wastewater-Treatment-25)",
  //     "var(--Scope-1-CH4-Wastewater-Treatment)",
  //   ],
  // },

  // {
  //   scope: "Scope-2",
  //   id: "Energy-indirect-GHG-emissions",
  //   title: "Energy-indirect-GHG-emissions",
  //   colors: [
  //     "var(--Scope-2-Energy-indirect-GHG-emissions-25)",
  //     "var(--Scope-2-Energy-indirect-GHG-emissions)",
  //   ],
  // },
  {
    scope: "Scope-2",
    id: "Electricity",
    title: "Electricity",
    colors: [
      "var(--Scope-2-Energy-indirect-GHG-emissions-25)",
      "var(--Scope-2-Energy-indirect-GHG-emissions)",
    ],
  },
  {
    scope: "Scope-2",
    id: "Steam",
    title: "Steam",
    colors: ["var(--Scope-2-Out-of-Scope-25)", "var(--Scope-2-Out-of-Scope)"],
  },
  // {
  //   scope: "Scope-2",
  //   id: "Out-of-Scope",
  //   title: "Out-of-Scope",
  //   colors: ["var(--Scope-2-Out-of-Scope-25)", "var(--Scope-2-Out-of-Scope)"],
  // },
  {
    scope: "Scope-3",
    id: "upstream1",
    title: "Purchased-Goods-And-Services",
    colors: ["var(--Scope-3-upstream1-25)", "var(--Scope-3-upstream1)"],
  },
  {
    scope: "Scope-3",
    id: "upstream2",
    title: "Capital-Goods",
    colors: ["var(--Scope-3-upstream2-25)", "var(--Scope-3-upstream2)"],
  },
  {
    scope: "Scope-3",
    id: "upstream3",
    title: "Fuel-And-Energy-Related-Activities",
    colors: ["var(--Scope-3-upstream3-25)", "var(--Scope-3-upstream3)"],
  },
  {
    scope: "Scope-3",
    id: "upstream4",
    title: "Upstream-Transportation-And-Distribution",
    colors: ["var(--Scope-3-upstream4-25)", "var(--Scope-3-upstream4)"],
  },
  {
    scope: "Scope-3",
    id: "upstream5",
    title: "Waste-Generated-In-Operations",
    colors: ["var(--Scope-3-upstream5-25)", "var(--Scope-3-upstream5)"],
  },
  {
    scope: "Scope-3",
    id: "upstream6",
    title: "Business-Travel",
    colors: ["var(--Scope-3-upstream6-25)", "var(--Scope-3-upstream6)"],
  },
  {
    scope: "Scope-3",
    id: "upstream7",
    title: "Employee-Commuting",
    colors: ["var(--Scope-3-upstream7-25)", "var(--Scope-3-upstream7)"],
  },
  {
    scope: "Scope-3",
    id: "upstream8",
    title: "Upstream-Leased-Assets",
    colors: ["var(--Scope-3-upstream8-25)", "var(--Scope-3-upstream8)"],
  },
  {
    scope: "Scope-3",
    id: "downstream9",
    title: "Downstream-Transportation-And-Distribution",
    colors: ["var(--Scope-3-downstream9-25)", "var(--Scope-3-downstream9)"],
  },
  {
    scope: "Scope-3",
    id: "downstream10",
    title: "Processing-Of-Sold-Products",
    colors: ["var(--Scope-3-downstream10-25)", "var(--Scope-3-downstream10)"],
  },
  {
    scope: "Scope-3",
    id: "downstream11",
    title: "Use-Of-Sold-Products",
    colors: ["var(--Scope-3-downstream11-25)", "var(--Scope-3-downstream11)"],
  },
  {
    scope: "Scope-3",
    id: "downstream12",
    title: "End-Of-Life-Treatment-Of-Sold-Products",
    colors: ["var(--Scope-3-downstream12-25)", "var(--Scope-3-downstream12)"],
  },
  {
    scope: "Scope-3",
    id: "downstream13",
    title: "Downstream-Leased-Assets",
    colors: ["var(--Scope-3-downstream13-25)", "var(--Scope-3-downstream13)"],
  },
  {
    scope: "Scope-3",
    id: "downstream14",
    title: "Franchises",
    colors: ["var(--Scope-3-downstream14-25)", "var(--Scope-3-downstream14)"],
  },
  {
    scope: "Scope-3",
    id: "downstream15",
    title: "Investments",
    colors: ["var(--Scope-3-downstream15-25)", "var(--Scope-3-downstream15)"],
  },
  {
    scope: "Outside-of-Scope",
    id: "Out-of-Scope",
    title: "Out-of-Scope",
    colors: [
      "var(--Outside-of-Scope-Out-of-Scope-25)",
      "var(--Outside-of-Scope-Out-of-Scope)",
    ],
  },
];
