import { Box, Card, CardContent, Typography } from "@mui/material";
import { ChartsItemContentProps, PieValueType } from "@mui/x-charts";
import { TFunction } from "i18next";
import Series from "../../models/Series";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

const PieTooltip = (props: ChartsItemContentProps, t: TFunction<[string, string], undefined>) => {
    const index = props.itemData.dataIndex ?? 0;
    const series: Series[] = [];
    let totalValue = 0;
    props.series.data.forEach((item) => {
        const seriesItem = item as PieValueType;
        series.push({ data: seriesItem.value, label: seriesItem.label, color: seriesItem.color });
        totalValue += seriesItem.value;
    });
    const item = series[index];
    const label = item.label ? t(item.label) : "";
    return (
        <Card sx={{ bgcolor: "var(--white)", borderRadius: "8px" }}>
            <CardContent sx={{ display: "flex", gap: "12px" }}>
                <Box width={"24px"} height={"24px"} bgcolor={item.color} />
                <Box>
                    <Typography variant="h3" width={"100%"}>{`${label} ${item.stack ?? ""}`}</Typography>
                    <Box display={"flex"} gap={"12px"}>
                        <Typography>{`${NumberFormatterUtils.numberFormat((item.data / totalValue) * 100)}%`}</Typography>
                        <Typography>{`${NumberFormatterUtils.numberFormat(item.data)} tCO2e`}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default PieTooltip;
