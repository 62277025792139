export interface FilePreview {
  name: string;
  size: number;
  preview: string;
}

const FileUtils = {
  getBase64: (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64 = reader.result as string;

        base64 = base64.replace("data", file.name);

        resolve(base64);
      };
      reader.onerror = reject;
    });
  },
  base64ToFile: (text: string): File => {
    const raw: string = text;

    const temp = raw.split(";")[0];

    const base64 = raw.split(",")[1];

    const filename = temp.split(":")[0];

    const mimeType = temp.split(":")[1];

    const byteString = atob(base64);

    const byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return new File([byteArray], filename, { type: mimeType });
  },
};

export default FileUtils;
