import { Box, Card } from "@mui/material";
import Layout from "../../Layout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { OrganizationProvider } from "../../contexts/OrganizationContext";
import SubOrganizations from "./SubOrganizations";
import { Outlet } from "react-router-dom";
import { TabMenu, TabMenuData } from "../../components/tabMenu/TabMenu";

const BusinessStructure = () => {
  const tabMenuData: TabMenuData[] = [
    { pathname: "/setting/business-structure/profile", name: "Profile" },
    {
      pathname: "/setting/business-structure/asset",
      name: "Asset",
    },
  ];

  return (
    <Layout>
      <OrganizationProvider>
        <Box
          height={{ lg: "100%" }}
          boxSizing={"border-box"}
          pt={"8px"}
          bgcolor={"var(--white)"}
        >
          <Grid2 container p={"40px"} gap={"40px"} height={"100%"}>
            <Grid2
              xs={12}
              lg={4}
              width={"100%"}
              maxWidth={{ lg: "338px" }}
              height={{
                lg: "100%",
              }}
              overflow={"auto"}
            >
              <SubOrganizations />
            </Grid2>
            <Grid2
              xs
              height={{
                lg: "100%",
              }}
            >
              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  p: "20px",
                  height: { lg: "100%" },
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  overflow: "auto",
                }}
              >
                <TabMenu tabMenuData={tabMenuData} type="sub" />
                <Box
                  display={"flex"}
                  flex={1}
                  sx={{
                    overflow: "auto",
                  }}
                >
                  <Outlet />
                </Box>
              </Card>
            </Grid2>
          </Grid2>
        </Box>
      </OrganizationProvider>
    </Layout>
  );
};

export default BusinessStructure;
