import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AddEmissionContext } from "../../../../../contexts/AddEmissionContext";
import { AmountInput } from "./InputAmount";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { PURCHASE_TYPE } from "../../../../../constants/dropdown";

const AddPurchases = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { stepTwoForm } = AddEmissionContext();

  return (
    <>
      <Grid item xs={12} md={6}>
        <AmountInput
          label={t("Amount-of-Purchases")}
          info={t("Amount-of-Purchases-Description")}
          control={stepTwoForm.control}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <CustomAutoComplete
          name="aux1"
          required
          label={t("purchase-type")}
          info={t("purchase-type")}
          control={stepTwoForm.control}
          listMenuItem={PURCHASE_TYPE}
          placeholder={t("Select-Param", {
            param: t("purchase-type"),
          })}
          rules={{
            required: t("form-reqired-error", {
              param: t("purchase-type"),
            }),
          }}
          defaultValue={null}
        />
      </Grid> */}
    </>
  );
};

export default AddPurchases;
