import { useMediaQuery, useTheme } from "@mui/material";
import {
  AxisConfig,
  BarChart,
  BarSeriesType,
  CardinalDirections,
  ChartsAxisContentProps,
  ChartsText,
  axisClasses,
  chartsGridClasses,
} from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { useTranslation } from "react-i18next";
import BarChartTooltip from "./BarChartTooltip";

interface Props {
  layout?: "horizontal" | "vertical" | undefined;
  series: MakeOptional<BarSeriesType, "type">[];
  seriesLabel: string;
  labels: string[];
  left?: number;
  total?: number;
}

const StyledBarChart = (props: Props) => {
  const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });

  const left = props.left ?? 100;
  const axisLabelHeight = 40;

  let margin: Partial<CardinalDirections<number>> = {
    top: 40,
    bottom: 30,
    left: left,
    right: 10,
  };
  let xAxis: MakeOptional<AxisConfig, "id">[] = [
    {
      data: props.labels,
      scaleType: "band",
      disableTicks: true,
      categoryGapRatio: 0.4,
      barGapRatio: 0,
      valueFormatter: (value, context) => t(value),
    } as AxisConfig<"band">,
  ];
  let yAxis: MakeOptional<AxisConfig, "id">[] = [{ label: props.seriesLabel }];
  if (props.layout === "horizontal") {
    const temp = xAxis;
    xAxis = yAxis;
    yAxis = temp;
    margin = { top: 10, bottom: 40, left: left + 20, right: 30 };
  }

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  let width: number | undefined = 980;
  if (lg) {
    width = undefined;
  }

  const tooltip = (chartsAxisContentProps: ChartsAxisContentProps) =>
    BarChartTooltip(chartsAxisContentProps, t, props.total);

  const height: number = props.labels.length * 20;

  const chartHeight = height > 290 ? height : 290;

  return (
    <BarChart
      layout={props.layout}
      series={props.series}
      height={chartHeight}
      width={width}
      xAxis={xAxis}
      yAxis={yAxis}
      margin={margin}
      slotProps={{
        legend: {
          hidden: true,
        },
      }}
      slots={{
        axisLabel(axisLabelProps, deprecatedLegacyContext) {
          return (
            <text
              x={
                props.layout === "vertical" || props.layout === undefined
                  ? -50
                  : "50%"
              }
              y={
                props.layout === "vertical" || props.layout === undefined
                  ? 15
                  : 40
              }
            >
              <tspan> {axisLabelProps.text}</tspan>
              {/* <tspan> tCO2e</tspan> */}
            </text>
          );
        },
        axisTickLabel(labelProps, deprecatedLegacyContext) {
          if (!props.labels.includes(labelProps.text)) {
            return <ChartsText {...labelProps} />;
          }
          if (props.layout === "vertical" || props.layout === undefined) {
            return <ChartsText {...labelProps} textAnchor="bottom" />;
          }

          let x = left * -1 - 20;

          return (
            <g
              transform={`translate(0, ${
                (Number(labelProps.y) || 0) - axisLabelHeight / 2
              } )`}
            >
              <switch>
                <foreignObject
                  x={x}
                  width={left}
                  height={axisLabelHeight}
                  textAnchor={"end"}
                  style={{
                    textWrap: "nowrap",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      width: "100%",
                      height: "100%",
                      // textAlign: "end",
                      fontSize: labelProps.style?.fontSize,
                      alignContent: "center",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "start",
                    }}
                  >
                    {labelProps.text}
                  </p>
                </foreignObject>
              </switch>
            </g>
          );
        },
        axisContent: tooltip,
      }}
      grid={{
        vertical: true,
      }}
      sx={{
        [`& .${axisClasses.directionY} .${axisClasses.line}`]: {
          display: "none",
        },
        [`& .${axisClasses.directionX} .${axisClasses.line}`]: {
          display: "none",
        },
        [`& .${axisClasses.tick}`]: {
          display: "none",
        },
        [`& .${chartsGridClasses.line}`]: {
          strokeDasharray: "4px 4px",
          stroke: "#E5E6EB",
          strokeWidth: "1px",
        },
        "& .MuiBarElement-root": {
          style: "stroke-width:1;stroke:white",
        },
        flexShrink: 0,
      }}
    />
  );
};

export default StyledBarChart;
