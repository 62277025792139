import { TabMenu, TabMenuData } from "../../components/tabMenu/TabMenu";

const tabMenuData: TabMenuData[] = [
  { pathname: "/report/tgo-cfo", name: "Tgo-Cfo" },
  {
    pathname: "/report/summary",
    name: "Summary",
  },
];

export const ReportMenu = () => {
  return <TabMenu tabMenuData={tabMenuData} />;
};
