import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  header?: any;
  body: any;
  open: boolean;
  onClose: () => void;
  maxWidth?: string;
}

const CustomModal = (props: Props) => {
  const handleClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }

    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={handleClose} disableEscapeKeyDown>
      <Box
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        borderRadius={"8px"}
        p={"40px"}
        sx={{ transform: "translate(-50%, -50%)", background: "white" }}
        width="80dvw"
        maxWidth={props.maxWidth ?? "auto"}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <Box flex={1}>{props.header}</Box>

            <Box
              onClick={props.onClose}
              sx={{
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </Box>
          </Box>

          <Box maxHeight={"70dvh"} flex={1} overflow={"auto"}>
            {props.body}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
