import { t } from "i18next";
import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import { SubOrganizations } from "../models/SubOrganization";
import { OrganizationsRef } from "../models/User";

const OrganizationService = {
  getSubOrganizationList: async (): Promise<SubOrganizations[]> => {
    try {
      const response = await apiClient.get("/v1/suborganizations");

      if (response.status === 200) {
        const result: SubOrganizations[] =
          response.data.results.suborganizations;

        return result;
      }
    } catch (error: any) {
      handleLoginError(error);
    }

    return [];
  },
  updateOrganizations: async (
    name: string,
    taxId: string,
    businessType: string
  ): Promise<OrganizationsRef | null> => {
    try {
      const body: Object = {
        name: name,
        taxId: taxId,
        businessType: businessType,
      };

      const response = await apiClient.patch("/v1/organizations", body);

      return response.data;
    } catch (error: any) {
      handleLoginError(error);
    }

    return null;
  },
};

const handleLoginError = async (error: any) => {
  const status: number = error.response.status;

  if (status === 500) {
    await alertService.error(`${t("Internal-Server-Error")}`);
    return;
  }

  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;

    switch (errorMessage) {
      case "FORBIDDEN_ACCESS_ERROR":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      default:
        alertService.error(t("error-get-sub-organization"), t("Error-Message"));
        break;
    }
  } else {
    return alertService.error(t("cannot-connect-to-server"));
  }
};

export default OrganizationService;
