import { Typography, FormControl, Select, MenuItem } from "@mui/material";
import LabelInput from "./LabelInput";
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { MenuData } from "./CustomSelect";
import i18n from "../../i18n";
import { CustomFormHelperText } from "./CustomFormHelperText";

interface Props {
  name: string;
  control: Control<any, any>;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  label: string;
  info: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  listMenuItem: MenuData[];
}

export const CustomSelect2 = (props: Props) => {
  return (
    <>
      <FormControl fullWidth>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={props.defaultValue}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => {
            return (
              <>
                {/* {JSON.stringify(value)} */}
                <LabelInput
                  label={props.label}
                  info={props.info}
                  required={props.required}
                />
                <Select
                  id={props.name}
                  key={props.name}
                  fullWidth
                  displayEmpty
                  size="small"
                  value={value}
                  error={error ? true : false}
                  disabled={props.disabled}
                  onChange={onChange}
                  onBlur={(event) => {
                    try {
                      const { value } = event.target;

                      event.target.value = value.trim();

                      onChange(event);
                    } catch (error) {}
                  }}
                  placeholder={props.placeholder}
                  sx={{
                    margin: "8px 0",
                  }}
                >
                  {props.placeholder && (
                    <MenuItem disabled value="">
                      <span
                        style={{
                          color: "var(--gray4)",
                        }}
                      >
                        {props.placeholder}
                      </span>
                    </MenuItem>
                  )}
                  {props.listMenuItem.map((m) => (
                    <MenuItem
                      key={`${m.value}`}
                      value={m.value}
                      disabled={m.disabled}
                    >
                      {i18n.language === "th" ? m.nameTH : m.nameEN}
                    </MenuItem>
                  ))}
                </Select>
                <CustomFormHelperText error={error} />
              </>
            );
          }}
        />
      </FormControl>
    </>
  );
};
