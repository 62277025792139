import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { WASTEWATER_INDUSTRY_TYPE } from "../../../../../constants/dropdown";
import { AddEmissionContext } from "../../../../../contexts/AddEmissionContext";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { NUMBER_REGEX } from "../../../../../constants/form";

const AddWastewater = () => {
  const { t } = useTranslation(["common", "overview", "input"], {
    nsMode: "fallback",
  });

  const { stepTwoForm } = AddEmissionContext();

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required
          name="amount"
          disabled
          label={t("amount-of-organic-loading")}
          info={t("amount-of-organic-loading")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux4"
          required
          label={t("Amount-of-Sludge")}
          info={t("Amount-of-Sludge-Description")}
          control={stepTwoForm.control}
          placeholder={t("Select-Param", {
            param: t("Amount-of-Sludge"),
          })}
          endIcon={
            <Typography
              variant="text1"
              color={"var(--dark)"}
              style={{
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              kgCOD
            </Typography>
          }
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Amount-of-Sludge"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <CustomAutoComplete
          required
          label={t("Wastewater-Industry-Type")}
          info={t("Wastewater-Industry-Type")}
          control={stepTwoForm.control}
          name="aux1"
          listMenuItem={WASTEWATER_INDUSTRY_TYPE}
          placeholder={t("Select-Param", {
            param: t("Wastewater-Industry-Type"),
          })}
          rules={{
            required: t("form-reqired-error", {
              param: t("Wastewater-Industry-Type"),
            }),
          }}
          defaultValue={null}
        />
      </Grid> */}

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required
          label={t("BOD-COD-Value")}
          info={t("BOD-Value-Description")}
          control={stepTwoForm.control}
          placeholder={t("Select-Param", {
            param: t("BOD-COD-Value"),
          })}
          endIcon={
            <Typography
              variant="text1"
              color={"var(--dark)"}
              style={{
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              kgCH4/m3
            </Typography>
          }
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("BOD-COD-Value"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required
          label={t("Amount-of-Wastewater")}
          info={t("Amount-of-Wastewater")}
          control={stepTwoForm.control}
          placeholder={t("Select-Param", {
            param: t("Amount-of-Wastewater"),
          })}
          endIcon={
            <Typography
              variant="text1"
              color={"var(--dark)"}
              style={{
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              m3
            </Typography>
          }
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Amount-of-Wastewater"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux5"
          required
          label={t("Amount-of-Methane-Recovery")}
          info={t("Amount-of-Methane-Recovery")}
          control={stepTwoForm.control}
          placeholder={t("Select-Param", {
            param: t("Amount-of-Methane-Recovery"),
          })}
          endIcon={
            <Typography
              variant="text1"
              color={"var(--dark)"}
              style={{
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              kgCH₄
            </Typography>
          }
          defaultValue={0}
          rules={{
            required: t("form-reqired-error", {
              param: t("Amount-of-Methane-Recovery"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid> */}
    </>
  );
};

export default AddWastewater;
