import { Box, Typography } from "@mui/material";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

interface Props {
  title: string;
  percent: number;
  value?: number;
  color: string;
}

const PieDetail = (props: Props) => {
  return (
    <Box minHeight={"26px"} display={"flex"} alignItems={"center"} gap={"4px"}>
      <Box
        borderRadius={"4px"}
        width={"12px"}
        bgcolor={props.color}
        height={"100%"}
      />
      <Box flexGrow={1}>
        <Box display={"flex"} gap={"16px"}>
          <Typography variant="text1" flexGrow={1}>
            {props.title}
          </Typography>
          <Typography variant="text1" flexShrink={0}>
            {NumberFormatterUtils.numberFormat(props.percent)}%
          </Typography>
          {props.value !== undefined && (
            <Typography variant="text1" flexShrink={0} color={"var(--gray4)"}>
              {NumberFormatterUtils.numberFormat(props.value)} tCO2e
            </Typography>
          )}
        </Box>
        <Box
          width={`${props.percent}%`}
          borderBottom={`2px ${props.color} solid`}
        />
      </Box>
    </Box>
  );
};

export default PieDetail;
