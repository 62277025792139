import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { GOOD_TYPE } from "../../../../../constants/dropdown";
import { AddEmissionContext } from "../../../../../contexts/AddEmissionContext";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { NUMBER_REGEX } from "../../../../../constants/form";

const AddOutsourcingTransport = () => {
  const { t } = useTranslation(["common", "overview", "input"], {
    nsMode: "fallback",
  });

  const { stepTwoForm } = AddEmissionContext();

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required
          name="amount"
          disabled
          label={t("input-amount-of-distance")}
          info={t("input-amount-of-distance")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          name="aux1"
          required
          label={t("Goods-Type")}
          info={t("Goods-Type-Description")}
          control={stepTwoForm.control}
          listMenuItem={GOOD_TYPE}
          placeholder={t("Select-Param", {
            param: t("Goods-Type"),
          })}
          rules={{
            required: t("form-reqired-error", {
              param: t("Goods-Type"),
            }),
          }}
          defaultValue={null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required
          label={t("Distance-One-way-km")}
          info={t("Distance-One-way-km")}
          control={stepTwoForm.control}
          placeholder={t("Distance-One-way-km")}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Distance-One-way-km"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required
          label={t("Loading-tonne")}
          info={t("Loading-tonne")}
          control={stepTwoForm.control}
          placeholder={t("Loading-tonne")}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Loading-tonne"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>
    </>
  );
};

export default AddOutsourcingTransport;
