import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AddEmissionContext } from "../../../../../contexts/AddEmissionContext";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { FERTILIZER_TYPE } from "../../../../../constants/dropdown";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { NUMBER_REGEX } from "../../../../../constants/form";
import { AmountInput } from "./InputAmount";

const AddFertilizer = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { stepTwoForm } = AddEmissionContext();

  return (
    <>
      <Grid item xs={12} md={6}>
        {/* <InputReactHookForm
          required
          name="amount"
          disabled
          label={t("Amount-of-Key-Substance")}
          info={t("Amount-of-Key-Substance")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        /> */}
        <AmountInput
          label={t("Amount-of-Key-Substance")}
          info={t("Amount-of-Key-Substance")}
          control={stepTwoForm.control}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <CustomAutoComplete
          name="aux1"
          required
          label={t("Fertilizer-Type")}
          info={t("Fertilizer-Type-Description")}
          control={stepTwoForm.control}
          listMenuItem={FERTILIZER_TYPE}
          placeholder={t("Select-Param", {
            param: t("Fertilizer-Type"),
          })}
          rules={{
            required: t("form-reqired-error", {
              param: t("Fertilizer-Type"),
            }),
          }}
          defaultValue={null}
        />
      </Grid> */}

      {/* <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required
          label={t("Amount-of-Fertilizer") + " (kg)"}
          info={t("Amount-of-Fertilizer") + " (kg)"}
          control={stepTwoForm.control}
          placeholder={t("Amount-of-Fertilizer") + " (kg)"}
          defaultValue={""}
          rules={{
            required: t("form-reqired-error", {
              param: t("Amount-of-Fertilizer"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required
          label={t("Fertilizer-Nitrogen")}
          info={t("Fertilizer-Nitrogen")}
          control={stepTwoForm.control}
          placeholder={t("Fertilizer-Nitrogen")}
          rules={{
            required: t("form-reqired-error", {
              param: t("Fertilizer-Nitrogen"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux4"
          required
          label={t("Fertilizer-Phosphorus")}
          info={t("Fertilizer-Phosphorus")}
          control={stepTwoForm.control}
          placeholder={t("Fertilizer-Phosphorus")}
          rules={{
            required: t("form-reqired-error", {
              param: t("Fertilizer-Phosphorus"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux5"
          required
          label={t("Fertilizer-Potassium")}
          info={t("Fertilizer-Potassium")}
          control={stepTwoForm.control}
          placeholder={t("Fertilizer-Potassium")}
          rules={{
            required: t("form-reqired-error", {
              param: t("Fertilizer-Potassium"),
            }),
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
          }}
          defaultValue={""}
        />
      </Grid> */}
    </>
  );
};

export default AddFertilizer;
